import { useEffect,useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { history } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { studentActions,homeworkActions,gradeattendenceActions } from '_store';
import Table from "react-bootstrap/Table";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

export function DailyReport() {
  const dispatch = useDispatch();
  const { user:authUser } = useSelector((x) => x.auth);
  const { student } = useSelector((x) => x.student);
  const { gradeattendence } = useSelector((x) => x.gradeattendence);
  const [activeIndex, setActiveIndex] = useState(null);
  const [homework, setHomework] = useState(null);
  const [attendance, setAttendance] = useState(null);
  const { studentId,date } = useParams();
  const handleCollapsibleClick = (index,inputDate) => {
    setActiveIndex(index === activeIndex ? null : index);
    const classId = student.Class.id;
    const studentId = authUser.user.student_id;
    const date1 = new Date(inputDate);
    const year = date1.getFullYear();
    const month = String(date1.getMonth() + 1).padStart(2, '0');
    const day = String(date1.getDate()).padStart(2, '0');

    var date = `${year}-${month}-${day}`;
    dispatch(gradeattendenceActions.getGradeAttendanceByStudent({studentId,classId,date}));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        
          const userId = studentId;
          await dispatch(studentActions.getStudentById({ userId }));
        
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    fetchData();
  }, [authUser, dispatch]);





const getLinkAccordingType = (type, category, homework_id, program_id) => {

  if(type == 'Library'){
   var link =  `/HomeWorkVocabularyType/${homework_id}/${program_id}`;
  }
  if(category == 'Free Response'){
    var link =  `/HomeWork/free-response/${homework_id}`;
  }

  if(category == 'Read Out Loud'){
    var link =  `/HomeWork/read-out-loud/${homework_id}`;
  }
 
console.log(link,'get link');
  return link;

};





useEffect(() => {
  const fetchData = async () => {
    try {
      const resultRes = await axios.get(
        `https://api.webtracktechnologies.com.au:8002/datareports/student/${studentId}?date=${date}`,
      );
     
      if(resultRes && resultRes.data){
        console.log(resultRes, 'get result');
        setHomework(resultRes.data.data.homeworkdata);
        setAttendance(resultRes.data);
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [studentId, date]);
console.log(homework,'get attendance');
    return (
      <div className="container">
      <div className="heading">{(student && student.Class ) ? student.Class.class_name: ''}</div>
     

      <div className="heading main">Date : {date}</div>

      <div className="collapse-container">


        <div className="collapse-body">
   
          {(homework) ? homework.map((work, index) => (
            <div key={index}>

                {work.date}
    
             
           
                {(work.homeworkArray) ? work.homeworkArray.map((home, index) => (
                <>
                {(home.homework) ? home.homework.map((homeW, index1) => (
                <>
                {(homeW.homework_email_text || homeW.homework_text) ? 
                <>
                <p>
                <span className="bold-txt">{(home.type) ? home.type : homeW.type}</span>{' '}
                <strong>Posted by</strong> {(homeW && homeW.Teacher) ? homeW.Teacher.first_name : ''}
              </p>
              {(home.type == 'Speaking' || home.type == 'Reading' || home.type == 'Writing' || home.type == 'Listening' || home.type == 'Vocabulary' || home.type == 'Grammar') ? 
              <>
              <a href={getLinkAccordingType((home.type) ? home.type : homeW.type,homeW.category,homeW.id,homeW.program_id)}> 
             {/* {(home.type == 'Speaking') ? <p>S{index1 + 1}</p> : ''} */}<p>
                <span className="user">{homeW.homework_email_text}</span>
              </p>
              </a></> :
            
              <p>
              <a href={getLinkAccordingType((home.type) ? home.type : homeW.type,homeW.category,homeW.id,homeW.program_id)}>  {homeW.homework_email_text || homeW.homework_text}</a>. Posted by: {(homeW && homeW.Teacher) ?  homeW.Teacher.first_name : ''}
              </p> }
              
               </>: ''}
                
    
             

               </>
               )) : ''}

           </>
)) :''}

         

            </div>
          )) : '<h5>No Homework Found.</h5>'}

{(attendance && attendance.data) ?
                   <>
  
                 <div className='attendence-show'>Attendance : {(attendance.data.attendance == "P") ? 'Present' : 'Absent'}</div>
                  <Table id="scoreTable" striped bordered hover>
                  <tr>
                    <td></td>
                    <th>Grades</th>
                    <th>Class Avg.</th>
                    <th>Homework</th>
                    <th>Quiz</th>
                </tr>
                {(attendance.data.txtread || attendance.data.reading_avg || attendance.data.txtHWRead || attendance.data.txtQZRead) ? 
                  <tr>
                      <th>Reading</th>
                      <td>{attendance.data.txtread}</td>
                      <td>{attendance.data.reading_avg}</td>
                      <td>{attendance.data.txtHWRead}</td>
                      <td>{attendance.data.txtQZRead}</td>
                  </tr>
                  :''}
                  {(attendance.data.txtlist || attendance.data.listening_avg || attendance.data.txtHWList || attendance.data.txtQZList) ? 
    <tr>
        <th>Listening</th>
        <td>{attendance.data.txtlist}</td>
        <td>{attendance.data.listening_avg}</td>
        <td>{attendance.data.txtHWList }</td>
        <td>{attendance.data.txtQZList}</td>
    </tr>
    :''}
     {(attendance.data.txtspek || attendance.data.speaking_avg || attendance.data.txtHWSpk || attendance.data.txtQZSpk) ? 
    <tr>
        <th>Speaking</th>
        <td>{attendance.data.txtspek}</td>
        <td>{attendance.data.speaking_avg}</td>
        <td>{attendance.data.txtHWSpk}</td>
        <td>{attendance.data.txtQZSpk}</td>
    </tr>
    :''}
    {(attendance.data.txtwrit || attendance.data.writing_avg || attendance.data.txtHWWrt || attendance.data.txtQZWrt) ? 
    <tr>
        <th>Writing</th>
        <td>{attendance.data.txtwrit}</td>
        <td>{attendance.data.writing_avg}</td>
        <td>{attendance.data.txtHWWrt}</td>
        <td>{attendance.data.txtQZWrt}</td>
    </tr>
    :''}
    {(attendance.data.txtgramer || attendance.data.grammer_avg || attendance.data.txtHWGram || attendance.data.txtQZGram) ? 
    <tr>
        <th>Grammar</th>
        <td>{attendance.data.txtgramer}</td>
        <td>{attendance.data.grammer_avg}</td>
        <td>{attendance.data.txtHWGram}</td>
        <td>{attendance.data.txtQZGram}</td>
    </tr>
    :''}
    {(attendance.data.txtvocab || attendance.data.vocab_avg || attendance.data.txtHWVocab || attendance.data.txtQZVocab) ? 
    <tr>
        <th>Vocabulary</th>
        <td>{attendance.data.txtvocab}</td>
        <td>{attendance.data.vocab_avg}</td>
        <td>{attendance.data.txtHWVocab}</td>
        <td>{attendance.data.txtQZVocab}</td>
    </tr>
    :''}
    {(attendance.data.speaking_avg || attendance.data.writing_avg || attendance.data.grammer_avg || attendance.data.vocab_avg) ? 
    <tr>
        <th>My Avg</th>
        <td>{attendance.data.speaking_avg}</td>
        <td>{attendance.data.writing_avg}</td>
        <td>{attendance.data.grammer_avg}</td>
        <td>{attendance.data.vocab_avg}</td>
    </tr>
    :''}
    </Table>
    <div className="">
    <span>A+= 81-100</span> <span>A= 61-80</span> <span>B+= 41-60</span>{" "}
    <span>B= 21-40</span> <span>C+= 0-20</span> <span>O=Pass</span>{" "}
    <span>X=Fail</span>{" "}
  </div>
    </> 
                 : ''}
        </div>
      </div>
    </div>

      
    )
}
