import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { history } from "_helpers";
import { signupActions, studentActions } from "_store";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
export { Profile };

function Profile() {
  const dispatch = useDispatch();
  const authUser = useSelector(x => x.auth.user);
  const signupUser = useSelector((x) => x.signup.user);
  const signupError = useSelector((x) => x.signup.error);
  const [inputValue, setInputValue] = useState("");
  const [inputMsg, setInputMessage] = useState("");
  const [inputShow, setInputShow] = useState();
  const [file, setFile] = useState();
  const { student }  = useSelector(x => x.student);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    phone_number: "",
    mobile_number: "",
    father_mobile_number: "",
    father_email: "",
  });
console.log(student,'get student');
  useEffect(() => {
    if(authUser && authUser.user.student_id){
      var userId = authUser.user.student_id;
      dispatch(studentActions.getStudentById({userId}));
    }
   
  }, [authUser]);
  useEffect(() => {
    // redirect to home if already logged in
    // if (signupUser) history.navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    middle_name: Yup.string().required("Middle Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zipcode: Yup.string().required("Zipcode is required"),
    phone_number: Yup.string().required("Phone Number is required"),
    mobile_number: Yup.string().required("Mobile Number is required"),

  });
  useEffect(() => {
    if (student) {
      setFormData({
        first_name: student.firstName || "",
        middle_name: student.middleName || "",
        last_name: student.lastName || "",
        address: student.address || "",
        city: student.city || "",
        state: student.state || "",
        country: student.country || "",
        zipcode: student.zipcode || "",
        phone_number: student.phone_number || "",
        mobile_number: student.mobile_number || "",
        father_mobile_number: student.parent_mobile_number || "",
        father_email: student.parent_email || "",
      });
    }
  }, [student]);
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  console.log(errors, "get error");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleFileChange = async (e) => {
    if (!e.target.files[0]) {
      toast.error('No file selected.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
 setFile(e.target.files[0]);
  }
  async function onSubmit() {
   // console.log(formData, "ggggg");return false;
    var username = inputValue;
    const formData1 = new FormData();
    if(authUser.user.type == 'user'){
      var userid = authUser.user.id;
      var type = 'student';
    }else{
      var userid = authUser.user.id;
      var type = 'teacher';
    }
			  
			formData1.append("type", type);
      formData1.append("id", userid);
			formData1.append("firstName", formData.first_name);
			formData1.append("middleName", formData.middle_name);
			formData1.append("lastName", formData.last_name);
      formData1.append("city", formData.city);
      formData1.append("state", formData.state);
      formData1.append("country", formData.country);
      formData1.append("zipcode", formData.zipcode);
      formData1.append("address", formData.address);
      formData1.append("parent_email", formData.father_email);
      formData1.append("parent_mobile_number", formData.father_mobile_number);
      formData1.append("mobile_number", formData.mobile_number);
      formData1.append("phone_number", formData.phone_number);
      formData1.append("file",file);
			  const res = await axios.put(
				"https://api.webtracktechnologies.com.au:8002/user/update/"+userid,
				formData1,
				{
				  headers: {
					'Authorization': `Bearer ${localStorage.getItem('token')}`,
					'Content-Type': 'application/json', // Make sure to set the content type based on your form data
				  },
				}
			  );
			  if(res){
          toast.success('Profile updated successfully.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
				return false;
			  }
  }

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  function makealphanumeric(value) {
    console.log("Captcha value:", value);
  }


  // function handleInputChange(event) {
  //   const inputRegex = /^[a-zA-Z0-9]+$/; // regular expression to match alphanumeric input
  //   const inputValue = event.target.value;
 
  //   if (inputRegex.test(inputValue)) {console.log('valid print here');
  //     setInputValue(inputValue);
  //     setInputMessage('');
  //     setInputShow();
  //   }else{console.log('not valid print here');
  //     setInputMessage('ID should be alphanumeric.');
  //     console.log(inputMsg,'not valid print here');
  //     setInputShow(true);
  //   }
  // }
  return (
    <section className="custom-sign profile">
      <div className="container-fluid">
        <div className="row">
        
          <div className="my-profile-form">
            <h3>My Profile</h3>
            
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-4">  <label>First Name</label></div>
              <div className="col-md-8">
              
                <input
                  type="text"
                  {...register("first_name")}
                  className={`form-control ${errors.first_name ? "is-invalid" : ""}`}
                  name="first_name"
                  placeholder="First Name"
                  value={formData.first_name}
                  aria-label="First name"
                   onChange={handleInputChange}
                />
                </div>
             
           
             <div className="col-md-4">  <label>Middle Name</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("middle_name")}
                  className={`form-control ${errors.middle_name ? "is-invalid" : ""}`}
                  name="middle_name"
                  value={formData.middle_name}
                  placeholder="Middle Name"
                  aria-label="Middle name"
                  onChange={handleInputChange}
                />
                </div>
              
             
                <div className="col-md-4">  <label>Last Name</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("last_name")}
                  className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
                  name="last_name"
                  value={formData.last_name}
                  placeholder="Last Name"
                  aria-label="Last name"
                  onChange={handleInputChange}
                />
                </div>
                <div className="col-md-4">  <label>Email</label></div>
                <div className="col-md-8">
                  {student && student.email}
                </div>
                <div className="col-md-4">  <label>ID</label></div>
                <div className="col-md-8">
                  {student && student.USERID}
                </div>
                <div className="col-md-4">  <label>Address</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("address")}
                  className={`form-control ${errors.address ? "is-invalid" : ""}`}
                  name="address"
                  value={formData.address}
                  placeholder="Address"
                  onChange={handleInputChange}
                  aria-label="Address"
                />
                </div>
                <div className="col-md-4">  <label>City</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("city")}
                  className={`form-control ${errors.city ? "is-invalid" : ""}`}
                  name="city"
                  value={formData.city}
                  placeholder="City"
                  onChange={handleInputChange}
                  aria-label="City"
                />
                </div>
                <div className="col-md-4">  <label>State</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("state")}
                  className={`form-control ${errors.state ? "is-invalid" : ""}`}
                  name="state"
                  value={formData.state}
                  placeholder="State"
                  onChange={handleInputChange}
                  aria-label="State"
                />
                </div>
                <div className="col-md-4">  <label>Country</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("country")}
                  className={`form-control ${errors.country ? "is-invalid" : ""}`}
                  name="country"
                  value={formData.country}
                  placeholder="Country"
                  onChange={handleInputChange}
                  aria-label="Country"
                />
                </div>
                <div className="col-md-4">  <label>Zipcode</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("zipcode")}
                  className={`form-control ${errors.zipcode ? "is-invalid" : ""}`}
                  name="zipcode"
                  value={formData.zipcode}
                  placeholder="Zipcode"
                  onChange={handleInputChange}
                  aria-label="Zipcode"
                />
                </div>
                <div className="col-md-4">  <label>Phone Number</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("phone_number")}
                  className={`form-control ${errors.phone_number ? "is-invalid" : ""}`}
                  name="phone_number"
                  value={formData.phone_number}
                  placeholder="Phone Number"
                  onChange={handleInputChange}
                  aria-label="Phone Number"
                />
                </div>
                <div className="col-md-4">  <label>Mobile Number</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("mobile_number")}
                  className={`form-control ${errors.mobile_number ? "is-invalid" : ""}`}
                  name="mobile_number"
                  value={formData.mobile_number}
                  placeholder="Mobile Number"
                  onChange={handleInputChange}
                  aria-label="Mobile Number"
                />
                </div>
                <div className="col-md-4">  <label>Father's Mobile Number</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  {...register("father_mobile_number")}
                  className={`form-control ${errors.father_mobile_number ? "is-invalid" : ""}`}
                  name="father_mobile_number"
                  value={formData.father_mobile_number}
                  placeholder="Father's Mobile Number"
                  onChange={handleInputChange}
                  aria-label="Father's Mobile Number"
                />
                </div>
                <div className="col-md-4">  <label>Parent's Email</label></div>
                <div className="col-md-8">
                <input
                  type="text"
                  className={`form-control`}
                  name="father_email"
                  value={formData.parent_email}
                  placeholder="Father's Email"
                  onChange={handleInputChange}
                  aria-label="Father's Email"
                />
                </div>
                <div className="col-md-4">  <label>Class</label></div>
                <div className="col-md-8">
                  {student && student.Class.id}
                </div>
                <div className="col-md-4">  <label>Level</label></div>
                <div className="col-md-8">
                  {student && student.level}
                </div>
                <div className="col-md-4">  <label>Joining Date</label></div>
                <div className="col-md-8">
                  
                </div>
                <div className="col-md-4">  <label> Plan Expire Date</label></div>
                <div className="col-md-8">
                </div>
                <div className="col-md-4">  <label>LIKE ID</label></div>
                <div className="col-md-8">
              
                </div>
                <div className="col-md-4">  <label>My LIKE Stars</label></div>
                <div className="col-md-8">
                  
                </div>
                <div className="col-md-4">  <label>Profile Picture</label></div>
                <div className="col-md-8">
                <input
                  type="file"
                  className={`form-control`}
                  name="profile_picture"
                  value={student && student.image}
                />
                </div>
                <div className="signin-form-btn">
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  <input
                    className="custom-button"
                    type="submit"
                    defaultValue="SIGN UP"
                  />
                </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
