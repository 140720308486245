import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { studyActions } from '_store';
import { useParams } from "react-router-dom";
import { history } from '_helpers';
import axios from "axios";

export { ViewTestDetails };

function ViewTestDetails() {
  const dispatch = useDispatch();
  const { passage } = useSelector((x) => x.passage);
  const [showTestDialog, setShowTestDialog] = useState(false);
  const [testText, setTestText] = useState('');
  const [testLevelText, setTestLevel] = useState('');
  const { user: authUser } = useSelector((x) => x.auth);
  const [selectedTest, setSelectedTest] = useState(null);
  const { record_id, level } = useParams();
  const [type, setType] = useState('');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

console.log(authUser,'get class', passage);
  useEffect(() => {
    var test_id = record_id;
    if(authUser.user.student_id){
      const student_id = authUser.user.student_id;
      dispatch(studyActions.assignedProgramQuestionList({test_id, student_id}));
    }else{
      dispatch(studyActions.assignedProgramQuestionList({test_id}));
    }
   
    setType('Reading');
 }, []);

 const addNewTest = () => {
  
  setShowTestDialog(true);
};
const handleTestCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowTestDialog(false);
};
const handleTestTextChange = (e) => {
  setTestText(e.target.value);
};

const saveTest = async () => {
  if (selectedTest) {
    // Update existing test
    const { id } = selectedTest;
    const studytest_id = id;
    const test_title = testText;
    const level = testLevelText;
    const teacher_id = authUser.user.teacher_id;
  return dispatch(studyActions.editStudyTest({ studytest_id, test_title, level, teacher_id }));
  } else{
  var test_title = testText;
  var level = testLevelText;
  var teacher_id = authUser.user.teacher_id;
  console.log(test_title,level,teacher_id,'get all values here');
  return dispatch(studyActions.createStudyTest({test_title,level,teacher_id}));
  }
};

const handleTestLevelChange = (e) => {
  setTestLevel(e.target.value);
};
const deleteStudyTest = async (id) => {
  const confirmed = window.confirm('Are you sure you want to delete this item?');

  if (confirmed) {
    var studytest_id = id;
    var res = dispatch(studyActions.deleteStudyTest({studytest_id}));
    if(res){
      window.location.reload();
    }
  }
};

const editTestClick = (id) => {
  // Find the study test with the specified id from the passage data
  if(passage && passage.data){
    const selectedStudyTest = passage.data.find(test => test.id === id);
    if (selectedStudyTest) {
      // Set the values for editing
      setTestText(selectedStudyTest.test_title);
      setTestLevel(selectedStudyTest.level);
      setSelectedTest(selectedStudyTest);
      setShowTestDialog(true);
    }
  }

};

function numberToWord(number) {
  // Convert the number to words using a library or custom logic
  // For simplicity, let's assume a simple conversion for numbers 0-99
  const units = ['', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE'];
  const teens = ['TEN', 'ELEVEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN'];
  const tens = ['', '', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

  if (number === 0) {
    return 'ZERO';
  }

  // Function to convert a two-digit number to words
  const twoDigitToWord = (num) => {
    if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else {
      return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ` ${units[num % 10]}` : '');
    }
  };

  // Handle numbers up to 99
  if (number < 100) {
    return twoDigitToWord(number);
  } else {
    return 'OUT OF RANGE';
  }
}

const handleInputChange = (value) => {
  if (typeof value !== 'string') {
    return '';
  }

  // Convert every 7 characters to '*'
  const convertedValue = value.substring(0, 7) + '*'.repeat(Math.max(value.length - 7, 0));

  return convertedValue;
};

const testCompleted = () => {
 // Convert every 7 characters to '*'
 alert('Test is already completed.');

 };
    return (

      <section className="custom-sign-assign-question">
      <div className="container">
  <div className="row">
    <h2>{(passage && passage.data) ? passage.data.test_title : ''}</h2>

    <div className="level-box">
    {(passage && passage.data && passage.data.programs && passage.data.programs["Read out Loud"]) ? (
  <div className="content-div" id="reading">
    <h4>Reading: Text Read out Loud</h4>
    <ul>
      {passage.data.programs["Read out Loud"].length > 0 ? (
        passage.data.programs["Read out Loud"].map((pass, index) => (
          <li key={index}>
            {index + 1}. {pass.test_completed && pass.test_completed.length > 0 ? (
              <a href="javascript:void(0)" className='grey' onClick={() => testCompleted()}>
                {handleInputChange(pass.categorytitle)}
              </a>
            ) : (
              <a href={`/study/study-test-read-out-loud-quiz/${record_id}/${pass.id}/ROL`}>
                {handleInputChange(pass.categorytitle)}
              </a>
            )}
          </li>
        ))
      ) : (
        <h5>No Records Found.</h5>
      )}
    </ul>
  </div>
) : (
  ''
)}

        {(passage && passage.data && passage.data.programs && passage.data.programs["Reading Multiple Choice"]) ?
        <div className="content-div" id="listing">
          <h4>Reading: Multiple Choice Quiz</h4>
          <ul>
          {(passage && passage.data && passage.data.programs && passage.data.programs["Reading Multiple Choice"]) ? passage.data.programs["Reading Multiple Choice"].map((pass, index) => (
            <li>{index+1}. 
             {pass.test_completed && pass.test_completed.length > 0 ? <a href="javascript:void(0)" className='grey' onClick={()=>testCompleted()}>{handleInputChange(pass.categorytitle)}</a>:
            <a href={`/study/study-test-reading-quiz/${record_id}/${pass.id}/RMC`}>{handleInputChange(pass.categorytitle)}</a>
          }</li>
            )) : <h5>No Records Found.</h5>}
          </ul>
        </div>
        :''}
         {(passage && passage.data && passage.data.programs && passage.data.programs["Listening Multiple Choice"]) ?
        <div className="content-div" id="listing">
          <h4>Listening Multiple Choice</h4>
          <ul>
          {(passage && passage.data && passage.data.programs && passage.data.programs["Listening Multiple Choice"]) ? passage.data.programs["Listening Multiple Choice"].map((pass, index) => (
            <li>{index+1}.
             {pass.test_completed && pass.test_completed.length > 0 ? <a href="javascript:void(0)" className='grey' onClick={()=>testCompleted()}>{handleInputChange(pass.categorytitle)}</a>:
             <a href={`/study/study-test-listening-quiz/${record_id}/${pass.id}/LMC`}>{handleInputChange(pass.categorytitle)}</a>
             }</li>
            )) : <h5>No Records Found.</h5>}
          </ul>
        </div>
        :''}
    </div>
  </div>
</div>

    </section>
      
    )
}
