import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'homework';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const homeworkActions = { ...slice.actions, ...extraActions };
export const homeworkReducer = slice.reducer;

// implementation

function createInitialState() {
    const storedHomework = localStorage.getItem('homework');
    const user = JSON.parse(storedHomework) || null;
    return {
        user,
        error: null,
    };
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        getHomeWorkById: getHomeWorkById(),
        assignHomework: assignHomework(),
        getHomeWorkByDate: getHomeWorkByDate(),
        getHomeWorkByRecordId:getHomeWorkByRecordId(),
        getHomeWorkByProgramId:getHomeWorkByProgramId(),
        getProgramByProgramId:getProgramByProgramId(),
        //savevocabresult:savevocabresult()
    };    

    function getHomeWorkById() {
        return createAsyncThunk(
            `${name}/homework`,
            async ({ class_id , type,startdate, enddate, student_id}) => await fetchWrapper.get(`${baseUrl}/homework/class/`+class_id+'?type='+type+'&startdate='+startdate+'&enddate='+enddate+'&student_id='+student_id)
        );
    }

    function assignHomework() {
        return createAsyncThunk(
            `${name}/homework`,
            //async ({teacher_id,class_id,homework_email_text,category, type, homework_text}) => await fetchWrapper.post(`${baseUrl}/homework/create`,{teacher_id,class_id,homework_email_text,category, type, homework_text})
           async ({teacher_id,class_id,homework_email_text,category, type, homework_text, date}) => await fetchWrapper.post(`${baseUrl}/homework/create`,{teacher_id,class_id,homework_email_text,category, type, homework_text, date})
        );
    }

    function getHomeWorkByDate() {
        return createAsyncThunk(
            `${name}/homework`,
            async ({ class_id, date }) => await fetchWrapper.get(`${baseUrl}/homework/classday?class_Id=`+class_id+'&date='+date)
        );
    }

    function getHomeWorkByRecordId() {
        return createAsyncThunk(
            `${name}/homework`,
            async ({ homework_id, rawdata }) => await fetchWrapper.get(`${baseUrl}/homework/single/`+homework_id+'?rawdata='+rawdata)
        );
    }

    function getHomeWorkByProgramId() {
        return createAsyncThunk(
            `${name}/homework`,
            async ({ homework_id, rawdata, program_id, student_id }) => await fetchWrapper.get(`${baseUrl}/homework/singlehomework/`+homework_id+'?rawdata='+rawdata+'&program_id='+program_id+'&student_id='+student_id)
        );
    }
    function getProgramByProgramId() {
        return createAsyncThunk(
            `${name}/homework`,
            async ({program_id,student_id}) => await fetchWrapper.get(`${baseUrl}/homework/programs/`+program_id+'?student_id='+student_id)
        );
    }
    
    // function savevocabresult() {
    //     return createAsyncThunk(
    //         `${name}/homework`,
    //         async (result) => await fetchWrapper.post(`${baseUrl}/homework/save-vocab-result`,result)
    //     );
    // }

}

function createExtraReducers() {
    return {
        ...getHomeWorkById(),
        ...assignHomework(),
        ...getHomeWorkByDate(),
        ...getHomeWorkByRecordId(),
        ...getHomeWorkByProgramId(),
        ...getProgramByProgramId(),
        //...savevocabresult()
    };

    function getHomeWorkById() {
        var { pending, fulfilled, rejected } = extraActions.getHomeWorkById;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const homework = action.payload;
                console.log(homework,'I am in redux');
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
               // localStorage.setItem('homework', JSON.stringify(homework));
                state.homework = homework;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function assignHomework() {
        var { pending, fulfilled, rejected } = extraActions.assignHomework;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const homework = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
               // localStorage.setItem('homework', JSON.stringify(homework));
                state.homework = homework;
                toast.success('Homework assigned successfully.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getHomeWorkByDate() {
        var { pending, fulfilled, rejected } = extraActions.getHomeWorkByDate;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const homework = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
               // localStorage.setItem('homework', JSON.stringify(homework));
                state.homework = homework;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getHomeWorkByRecordId() {
        var { pending, fulfilled, rejected } = extraActions.getHomeWorkByRecordId;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const homework = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
               // localStorage.setItem('homework', JSON.stringify(homework));
                state.homework = homework;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getHomeWorkByProgramId() {
        var { pending, fulfilled, rejected } = extraActions.getHomeWorkByProgramId;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const homework = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
               // localStorage.setItem('homework', JSON.stringify(homework));
                state.homework = homework;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getProgramByProgramId() {
        var { pending, fulfilled, rejected } = extraActions.getProgramByProgramId;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const homework = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
               // localStorage.setItem('homework', JSON.stringify(homework));
               state.homework = homework;


            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    //  function savevocabresult() {
    //     var { pending, fulfilled, rejected } = extraActions.savevocabresult;
    //     return {
    //         [pending]: (state) => {
    //             state.error = null;
    //         },
    //         [fulfilled]: (state, action) => {
    //             const homework = action.payload;
    //           //console.log(user,'get register user');  return false;
    //             // store user details and jwt token in local storage to keep user logged in between page refreshes
    //             // localStorage.setItem('homework', JSON.stringify(homework));
    //             state.homework = homework;

    //         },
    //         [rejected]: (state, action) => {
    //             console.log(action.error,'get er');
    //             state.error = action.error;
    //             toast.error(action.error, {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //                 });
    //         }
    //     };
    // }

}
