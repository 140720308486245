import React, { useEffect, useRef, useState } from "react";

export { RecorderList };
const settings = {
  bars: 7,
  spacing: 3,
  width: 5,
  height: 100
};
function RecorderList() {
    const [audio] = useState(new Audio('https://likestudy.org/Mp3Recording/SampleMicTestFile/Test.mp3'));
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlayingRecording, setIsPlayingRecording] = useState(false);
    const [volume, setVolume] = useState(50);
    const [volumeLevel, setVolumeLevel] = useState(0);
    const refs = useRef([]);
    const volumemeter = useRef(0);
    const volumeRefs = useRef(new Array(settings.bars));
    const togglePlay = () => {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    };
  
    const handleVolumeChange = (newVolume) => {
      const scaledVolume = newVolume / 100; // Convert percentage to fraction
      audio.volume = scaledVolume;
      setVolume(newVolume);
    };

    const [isRecording, setIsRecording] = useState(false);
    const audioRef = useRef(new Audio());
    const mediaRecorderRef = useRef(null);
    const chunksRef = useRef([]);
  
    // const startRecording = async () => {
    //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //   chunksRef.current = []; // Clear existing chunks
  
    //   mediaRecorderRef.current = new MediaRecorder(stream);
      
    //   mediaRecorderRef.current.ondataavailable = (e) => {
    //     if (e.data.size > 0) {
    //       chunksRef.current.push(e.data);
    //     }
    //   };
  
    //   mediaRecorderRef.current.onstop = () => {
    //     if (chunksRef.current.length > 0) {
    //       const blob = new Blob(chunksRef.current, { type: 'audio/wav' });
    //       audioRef.current.src = URL.createObjectURL(blob);
    //     }
    //   };
  
    //   mediaRecorderRef.current.start();
    //   setIsRecording(true);

    // };

    const startRecording = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        chunksRef.current = []; // Clear existing chunks
      
        mediaRecorderRef.current = new MediaRecorder(stream);
          
        mediaRecorderRef.current.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunksRef.current.push(e.data);
          }
        };
      
        mediaRecorderRef.current.onstop = () => {
          if (chunksRef.current.length > 0) {
            const blob = new Blob(chunksRef.current, { type: 'audio/wav' });
            audioRef.current.src = URL.createObjectURL(blob);
          }
        };
      
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (error) {
        console.error('Error accessing media devices.', error);
        alert('Please connect your microphone.');
      }
    };
    
  
    const stopRecording = () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      setIsRecording(false);
    };

  const togglePlayback = () => {
    if (audioRef.current) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    setIsPlayingRecording(!isPlayingRecording);
  };

  const handleAudioEnded = () => {
    setIsPlayingRecording(false);
  };



  const getMedia = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
        analyser.smoothingTimeConstant = 0.4;
        analyser.fftSize = 1024;
        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = () => {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          var values = 0;
          var length = array.length;
          for (var i = 0; i < length; i++) {
            values += array[i];
          }
          volumemeter.current = values / length;
        };
      })
      .catch(function(err) {
        /* handle the error */
      });
  };

  useEffect(getMedia, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      volumeRefs.current.unshift(volumemeter.current);
      volumeRefs.current.pop();
      if(refs.current.length > 0){
        //console.log(refs.current,'get reference');return false;
        for (let i = 0; i < refs.current.length; i++) {
if(refs.current[i] != '' && refs.current[i] != null){

  refs.current[i].style.transform = `scaleY(${volumeRefs.current[i] /
    100})`;
}
        }
      }
    }, 20);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const createElements = () => {
    let elements = [];
    refs.current = [];
    for (let i = 0; i < settings.bars; i++) {
      elements.push(
        <div
          ref={ref => refs.current.push(ref)}
          key={`vu-${i}`}
          style={{
            position: "absolute",
            background: "#5ac63d",
            borderRadius: settings.width + "px",
            width: settings.width + "px",
            height: Math.sin((i / settings.bars) * 4) * settings.height + "px",
            left: i * (settings.width + settings.spacing) + "px",
            top:
            settings.height / 2 -
            Math.sin((i / settings.bars) * 4) * settings.height / 2 +
            "px"
          }}
        />
      );
    }
    return elements;
  };

  return (
    <section>
    <div className="test">
      <h2>Check Microphone &amp; Volume</h2>
      <div className="row mt-4">
        <div className="col-md-4 block">
          <h3>Volume Test</h3>
          <i className="fa fa-volume-up big" aria-hidden="true" />
             <div className="volume-control-container">
      {/* <div className="volume-icon">
         <i className="fa fa-volume-down"></i>
     </div> */}
     <div className="volume-control">
      <div className="volume-bars">
        {[...Array(10).keys()].map((index) => (
          <div
            key={index}
            className={`volume-bar ${index < volume / 10 ? 'active' : ''}`}
            onMouseEnter={() => handleVolumeChange((index + 1) * 10)}
          />
        ))}
      </div>
    </div>

    </div> 
          <button className="test-btn" onClick={togglePlay}>
          {isPlaying ? 'Pause ' : 'Play' } 
          {
           isPlaying ? <i className="fa fa-pause" aria-hidden="true" /> :  <i className="fa fa-play" aria-hidden="true" />
          }
          </button>
        </div>
        <div className="col-md-8 block">
          <h3>Microphone Test</h3>
          <div className="microphone-container">
      <div className="microphone-icon">
      <i className="fa fa-microphone big" aria-hidden="true" />
      {
        (isRecording ) ? 
        <div className="micro-sound-meter" style={{ position: "relative" }}>{createElements()}</div>: ''
      }
     
      </div>
     
    </div>
        
          <div className="volume-level" style={{ width: `${volumeLevel}px` }}></div>
          {  
            (!isRecording ) ? 
            <button className="test-btn" onClick={startRecording} disabled={isRecording}>
            Record <i className="fa fa-circle" aria-hidden="true" />
          </button>
          :
          <button className="test-btn" onClick={stopRecording} disabled={!isRecording}>
          Stop <i className="fa fa-stop" aria-hidden="true" />
        </button>

          }
<audio className="hide" ref={audioRef} controls onEnded={handleAudioEnded} />
        <button className="test-btn"  onClick={togglePlayback} >
        {isPlayingRecording ? 'Pause' : 'Play'} 
      </button> 

          

        </div>
       
      </div>
      <div className="row">
      <div className="col-md-6">
      <i className="fas fa-sync-alt" onClick={()=>window.location.reload()}></i>
        </div>
        <div className="col-md-6">
        <a href="/my-recording" className="next-btn">
        Next
      </a>
        </div>
      </div>

    </div>
  </section>

  );
}
