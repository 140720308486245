import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fileActions } from '_store';
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export { GradeList };

function GradeList() {
  const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [studentId, setInput1] = useState('');
    const [studentName, setInput2] = useState('');
    const [className, setSelectedOption] = useState('');
    const { files } = useSelector((x) => x.files);
    const [pageLink, setPageLink] = useState('');
    const [type, setType] = useState('');
    const { user: authUser } = useSelector((x) => x.auth);
    console.log(authUser,'get class');
    // Check if there are any files with type "Test"
  const isTestTypePresent = files && files.data.some(file => file.type === "Test");
    useEffect(() => {
      dispatch(fileActions.getAudioFiles());
 
   }, []);
  // console.log(files);return false;
    const handleSearch = () => {
      // You can perform your search logic here
      console.log('Start Date:', startDate);
      console.log('End Date:', endDate);
      console.log('Input 1:', studentId);
      console.log('Input 2:', studentName);
      console.log('Selected Option:', className);
      var start_date = startDate;
      var end_date = endDate;
      var studentId = studentId;
      var studentName = studentName;
      var className = className;

    return dispatch(fileActions.searchAudioFile({start_date,end_date,studentId,studentName,className}));
    };

    const getFailPassResult = (result) => {
    var getResult = JSON.parse(result);
    console.log(getResult.message.AAResult[0].analysis,'get result');
    if(getResult){
      if(!getResult.message.AAResult[0].analysis){
        return 'Fail';
      }else{
      if((getResult.message.AAResult[0].analysis in getResult.message)) {
        if(getResult.message.AAResult[0].analysis[0].score[0] > 0.5) {
            return 'Pass';
          }else{
            return "Fail";
          }
      }else{
        return 'Fail';
      }
    }
   
    }else{
      return 'Result Not Available';
    }
      

    };


    const handleDownload = (fileUrl) => {
      const fileName = 'recording.mp3'; // Replace with your desired file name
  
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    };

    const getPageLink = (pageType, subType, resultRecordId) => {
  
      if(pageType == "Test" && subType == "Reading Multiple Choice"){
        var link = '/Homework/VocabularyQuiz/'+resultRecordId;
      }else if(pageType == "Read Out Loud" || pageType == "Like Recorder"){
        var link = '/ReadOutLoudGrader/'+resultRecordId;
      }else if(pageType == "Test" && subType == "Listening Multiple Choice"){
        var link = '/Homework/VocabularyQuiz/'+resultRecordId;
      }else if(pageType == "Test" && subType == "Read Out Loud"){
        var link = '/ReadOutLoudGrader/'+resultRecordId;
      }else if(pageType == "Speaking Respond In English" || pageType == "Sentence Read Out Loud" || pageType == "Vocabulary Read out Loud"){
        var link = '/ReadOutLoudGrader/'+resultRecordId;
      }else if(pageType == "Sentence Unscramble"  || pageType == "Listen Vocab" || pageType == "Textbook Vocab" ){
        var link = '/Homework/VocabularyQuiz/'+resultRecordId;
      }else if(pageType == "Listening Sentence Dictation"){
        var link = '/Homework/VocabularyQuiz/'+resultRecordId;
      }else if(pageType == "Listening Fill in the Blanks"){
        var link = '/Homework/VocabularyQuiz/'+resultRecordId;
      }else if(pageType == "Grammar MCQ"){
        var link = '/Homework/VocabularyQuiz/'+resultRecordId;
      }else if(pageType == "Reading Comprehension" || pageType == "Listening Comprehension"){
        var link = '/Homework/VocabularyQuiz/'+resultRecordId;
      }else {
      var link = '/speaking-grade/'+resultRecordId;
      }
      return link;
    };
    const getType = (pageType, subType) => {
  
      if(pageType == "Test" && subType == "Reading Multiple Choice"){
        var type = 'R';
      }else if(pageType == "Read Out Loud" || pageType == "Like Recorder"){
        var type = 'RS';
      }else if(pageType == "Test" && subType == "Listening Multiple Choice"){
        var type = 'L';
      }else if(pageType == "Test" && subType == "Read Out Loud"){
        var type = 'RS';
      }else if(pageType == "Speaking Respond In English" || pageType == "Sentence Read Out Loud" || pageType == "Vocabulary Read out Loud"){
        var type = 'S';
      }else if(pageType == "Sentence Unscramble"  || pageType == "Listen Vocab" || pageType == "Textbook Vocab" ){
        var type = 'S';
      }else if(pageType == "Listening Sentence Dictation"){
        var type = 'L';
      }else if(pageType == "Listening Fill in the Blanks"){
        var type = 'L';
      }else if(pageType == "Grammar MCQ"){
        var type = 'G';
      }else if(pageType == "Reading Comprehension" || pageType == "Listening Comprehension"){
        var type = 'R';
      }else {
      var type = '';
      }
      return type;
    };
    return (
      <section className="custom-sign">
			<h2 className="main-heading">Like Study Grade List</h2>
          <div className="container-fluid">
		  <h4 className="sub-heading">Like Study Grade List</h4>
		  
		  <h5 className="head-heading">Search</h5>
		  <div className="container-head">
          <div className='row'>
          <div className='col-md-3'>
          <label>From</label>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className='col-md-3'>
          <label>To</label>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>

      
            <div className='col-md-3'>
            <label>ID</label>
            <input type="text" value={studentId} onChange={(e) => setInput1(e.target.value)} />
            </div>
           
            <div className='col-md-3'>
            <label>Name</label>
            <input type="text" value={studentName} onChange={(e) => setInput2(e.target.value)} />
            
            </div>
       </div>
            
            <div className='row'>
            <div className='col-md-3'>
            <label>Class</label>
            <select value={className} onChange={(e) => setSelectedOption(e.target.value)}>
                <option value="">Select...</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
            </select>
            </div>
            <div className='col-md-3'>
			<p>Rater: {authUser.user.name}</p>
              </div>
            <div className='col-md-3'>
            <button onClick={handleSearch}>Search</button>
            </div>
			<div className='col-md-3'>
           
            </div>
          
            </div>
			</div>
          
            <div className='col-md-12'>
            <Table striped bordered hover>
      <thead>
        <tr>
          <th>S.NO.</th>
          <th>Results</th>
       
          {!isTestTypePresent && <th>Redo</th>}
          <th>Student Name</th>
          <th>Class</th>
          <th>Title</th>
          <th>Type</th>
          <th>Created Date</th>
          <th>Download</th>
        </tr>
      </thead>
      <tbody>
      {(files) ? files.data.map((file, index) => (
        
        <tr>
          <td>{index+1}</td>
           {/* <td>{(!file.result) ? '' : getFailPassResult(file.result)}</td>  */}
          
          <td></td>
          {!isTestTypePresent && <td></td>}

          
          
          <td>{(file.Student) ? file.Student.firstName : ''} {(file.Student) ? file.Student.lastName:''}</td>
          <td>{(file.Class != null)? file.Class.class_name :''}</td>
          {/* {/ <td><a href={`/speaking-grade/${file.id}`}>{file.audio}</a></td> /} */}
          <td>
        
  <a href={getPageLink(file.type,file.sub_type,file.id)}>View File</a>

           </td>
          <td>{getType(file.type,file.sub_type)} </td>
          <td>{file.createdAt}</td>
          <td>  <button
                  className="btn btn-sm btn-success"
                  onClick={()=>handleDownload(file.audio)}
                >
                  <i className="fa fa-download"></i>
                </button></td>
        </tr>
      )) : 'No Records Found.'}
       
      </tbody>
    </Table>
            </div>
            
           

          </div>
        </section>
      
    )
}
