import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  studentActions,
  audioActions,
  myrecordingActions,
  teacherActions,
} from "_store";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Table } from "react-bootstrap";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
const settings = {
  bars: 7,
  spacing: 3,
  width: 5,
  height: 100
};
export { MyRecording };
function MyRecording() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { audio } = useSelector((x) => x.audio);
  const { student } = useSelector((x) => x.student);
  const { myrecordings } = useSelector((x) => x.myrecordings);
  const [textValue, setTextValue] = useState("");
  const initialMinutes = 2;
  const initialSeconds = initialMinutes * 60;
  const [teacherResult, setTeacherResult] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(initialSeconds);
  const audioRef = useRef(null);
  const [audioFile, setAudioFile] = useState("");
  const [filePath, setFilePath] = useState("");
  const [audioQuality, setAudioQuality] = useState("");
  const [recordId, setRecordId] = useState("");
  const recorderControls = useAudioRecorder();
  const [showDialog, setShowDialog] = useState(false);
  const { student_teacher_result } = useSelector(
    (x) => x.student_teacher_result
  );
  const [apiResult, setApiResult] = useState(null);
  const [error, setError] = useState(false);
  const [contentValue, setContent] = useState(0);
  const [fluencyValue, setFluency] = useState(0);
  const refs = useRef([]);
  const volumemeter = useRef(0);
  const volumeRefs = useRef(new Array(settings.bars));
  const [imageSource, setImageSource] = useState('/images/record.png');
  const handleAudioAnalysis = async () => {
    console.log(filePath, "get file");
    var file = filePath;
    var studentId = authUser.user.student_id;
    return dispatch(audioActions.audiorecording({ recordId, file }));
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const addAudioElement = async (blob) => {
    const url = URL.createObjectURL(blob);
    //alert("lalit");

    blobToBase64(blob).then((res) => {
      // do what you wanna do
      console.log(res, "blob"); // res is base64 now
    });
    //
    const formData = new FormData();
    formData.append("audio", blob);
    formData.append("user", authUser.user.student_id);
    formData.append("class_id", student.class_name);
    formData.append("type",'Like Recorder');
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/audio-recording",
      formData
    );

    setAudioFile(response.data.data);
    setFilePath(response.data.filepath);
    setRecordId(response.data.audio_record.id);

    //  const audio1 = document.createElement("audio");
    //   audio1.src = url;
    //   audio1.controls = true;
  };

  const handleTextareaChange = (event) => {
    setTextValue(event.target.value);
  };

  const saveRecordingTopic = async () => {
    var topic_name = textValue;
    var user = authUser.user.student_id;
    if (textValue) {
      setError(false);
      return dispatch(myrecordingActions.myrecording({ topic_name, user }));
    } else {
      setError(true);
    }
  };

  const editRecordingTopic = async (id) => {
    var record_id = id;
    var topic_name = textValue;
    return dispatch(
      myrecordingActions.editmyrecording({ record_id, topic_name })
    );
  };

  useEffect(() => {
    if (recorderControls.isRecording) {
      const interval = setInterval(() => {
        if (totalSeconds > 0) {
          setTotalSeconds(totalSeconds - 1);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [recorderControls.isRecording, totalSeconds]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    if (audio) {  console.log(audio.data.result.message.AAResult[0].analysis[0]
      .speakingSummary[0].spkTimeSec[0] );
      if (
        audio.data.result.message.AAResult[0].audioQuality[0].$.status == "18"
      ) {
        setAudioQuality("Audio Quality is soft and noisy.");
      } else if (
        audio.data.result.message.AAResult[0].audioQuality[0].$.status == "8"
      ) {
        setAudioQuality("Audio Quality is soft.");
      } else if (
        audio.data.result.message.AAResult[0].audioQuality[0].$.status == "0"
      ) {
        setAudioQuality("Audio Quality is good.");
      } else if (
        audio.data.result.message.AAResult[0].audioQuality[0].$.status == "10"
      ) {
        setAudioQuality("Audio Quality is noisy.");
      }
    }
  }, [audio]);
  useEffect(() => {
    var userId = authUser.user.student_id;
    var file = filePath;
    dispatch(studentActions.getStudentById({ userId }));
    var student_id = authUser.user.student_id;
    var type= 'Like Recorder';
    dispatch(teacherActions.teacherResult({ student_id ,type }));
    $('.audio-recorder-mic').attr('src',imageSource);
    // const elementImg = document.querySelector('.audio-recorder-mic');
    // console.log(elementImg,'anj');
    // if (elementImg) {
    //   elementImg.innerHTML = `<img src="${imageSource}" alt="Sample" />`;
    // }
  }, []);

  useEffect(() => {
    if (student_teacher_result && student_teacher_result.data[0]) {
      var teacherjsonResult = JSON.parse(
        student_teacher_result.data[0].teacher_result
      );
      var jsonResult = JSON.parse(student_teacher_result.data[0].result);

      setTeacherResult(teacherjsonResult);
      setApiResult(jsonResult);
      if (apiResult) {
        console.log(apiResult.message.AAResult[0].audioQuality[0], "jsob");

        if (apiResult.message.AAResult[0].audioQuality[0].$.status == "18") {
          setAudioQuality("Audio Quality is soft and noisy.");
        } else if (
          apiResult.message.AAResult[0].audioQuality[0].$.status == "8"
        ) {
          setAudioQuality("Audio Quality is soft.");
        } else if (
          apiResult.message.AAResult[0].audioQuality[0].$.status == "0"
        ) {
          setAudioQuality("Audio Quality is good.");
        } else if (
          apiResult.message.AAResult[0].audioQuality[0].$.status == "10"
        ) {
          setAudioQuality("Audio Quality is noisy.");
        }else{
          setAudioQuality("Audio Quality is bad.");
        }
      }
    }
  }, [student_teacher_result]);
  useEffect(() => {
    if (recordId) {
      var file = filePath;
      var type = 'Like Recorder';
      var sub_type = 'Free Response';
     dispatch(audioActions.audiorecording({ recordId, file, type,sub_type }));
    }
  }, [recordId]);
  const startRecording = () => {
    if(student && student && student.apilimit > 0){
      recorderControls.startRecording;
    } else{
      setShowDialog(true);
      //alert('');
     // 
    } 
  }

  const handleCancel = () => {
    // Hide the dialog when cancel action is clicked
    setShowDialog(false);
    window.location.reload();
  };
  const getContentAverageScore = (content) => {
    var value = 0;
    if (content <= 5) {
      
      value = 0;
    } else if (content >= 6 && content <= 10) {
      value = 0.7;
    } else if (content >= 11 && content <= 20) {
      value = 1.5;
    } else if (content >= 21 && content <= 40) {

      value = 2.5;
    } else if (content >= 41 && content <= 80) {

      value = 3.3;
    }else if (content >= 81 && content <= 100) {

      value = 3.7;
    }else if (content >= 101) {

      value = 4;
    }
    //setContent(value);
    return value;
   
  };
  const getFluencyAverageScore = (fluency) => {
    console.log(fluency, "get fluency");
    var value = 0;
    if(Math.round(fluency) <= 30){
      value = 0;
    }else if(Math.round(fluency) >= 30 && Math.round(fluency) <= 50){
      value = 1;
    }
    else if(Math.round(fluency) >= 51 && Math.round(fluency) <= 80){
      value = 2;
    }
    else if(Math.round(fluency) >= 81 && Math.round(fluency) <= 120){
      value = 3;
    }
    else if(Math.round(fluency) >= 121 && Math.round(fluency) <= 150){
      value = 4;
    } else if(Math.round(fluency) >= 151 && Math.round(fluency) <= 180){
      value = 4;
    } else if(Math.round(fluency) >= 180){
      value = 4;
    }
   // setFluency(value);
   return value;
  };
  const getFluencyRemarks = (fluency) => {
    console.log(fluency, "get fluency");
    var text = 'There is no recording or your recording is extremely short.';
    if(fluency == 0){
      text = 'There is no recording or your recording is extremely short.';
    }else if(fluency == 1){
      text = 'Your speech is slow.';
    }
    else if(fluency == 2){
      text = 'Your speech is relatively slow.';
    }
    else if(fluency == 3){
      text = 'Your speech is relatively fast.';
    }
    else if(fluency == 4){
      text = 'Your speech is fast.';
    } 
   // setFluency(value);
   return text;
  };
  const getTotalAverageScore = (content,fluency) => {
    if (!isNaN(content) && !isNaN(fluency)) {
      const avg = (content + fluency) / 2;
      return avg;
    }
  };
  const getContentRemarks = (content) => {
    console.log(content, "get fluency");
    var text = 'There is no recording or your content score is equal to or less than 20%.';
    if(content == 0){
      text = 'There is no recording or your content score is equal to or less than 20%.';
    }else if(content == 0.7){
      text = 'Your content score is 21-40%.';
    }
    else if(content == 1.5){
      text = 'Your content score is 41-60%.';
    }
    else if(content == 2.5 || content == 3.3){
      text = 'Your content score is 61-80%.';
    }
    else if(content == 3.7 || content == 4){
      text = 'Your content score is 81-100%.';
    } 
   // setFluency(value);
   return text;
  };

  const getTotalRemarks = (total) => {
    console.log(total, "get fluency");
    var text = 'Nothing is recorded or no effort taken at all.';
    if(total == 0){
      text = 'Nothing is recorded or no effort taken at all.';
    }else if(total > 0 && total <= 1){
      text = 'Your speech is hardly recognizable. There are a lot of mistakes and errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with too many unnecessary pauses.';
    }
    else if(total > 1 && total <=2){
      text = 'Your speech is intelligible, but it requires much effort for one to understand. There are many noticeable mistakes and consistent errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with many unnecessary pauses.';
    }
    else if(total > 2 && total <= 3){
      text = 'Though it may require some effort, your speech is intelligible, showing relatively high oral proficiency and skills. It contains some errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with some unnecessary pauses.';
    }
    else if(total > 3 && total == 4){
      text = 'Your speech is native like. It is highly intelligible, showing high oral proficiency and skills. Your pronunciation, grammar, vocabulary use, fluency, and style are natural, accurate, and highly proficient.';
    } 
   // setFluency(value);
   return text;
  };
  const getMedia = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
        analyser.smoothingTimeConstant = 0.4;
        analyser.fftSize = 1024;
        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = () => {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          var values = 0;
          var length = array.length;
          for (var i = 0; i < length; i++) {
            values += array[i];
          }
          volumemeter.current = values / length;
        };
      })
      .catch(function(err) {
        /* handle the error */
      });
  };

  useEffect(getMedia, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      volumeRefs.current.unshift(volumemeter.current);
      volumeRefs.current.pop();
      if(refs.current.length > 0){
        //console.log(refs.current,'get reference');return false;
        for (let i = 0; i < refs.current.length; i++) {
if(refs.current[i] != '' && refs.current[i] != null){

  refs.current[i].style.transform = `scaleY(${volumeRefs.current[i] /
    100})`;
}
        }
      }
      
    }, 20);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const createElements = () => {
    let elements = [];
  

    for (let i = 0; i < settings.bars; i++) {
      elements.push(
        <div
          ref={ref => refs.current.push(ref)}
          key={`vu-${i}`}
          style={{
            position: "absolute",
            background: "#5ac63d",
            borderRadius: settings.width + "px",
            width: settings.width + "px",
            height: Math.sin((i / settings.bars) * 4) * settings.height + "px",
            left: i * (settings.width + settings.spacing) + "px",
            top:
            settings.height / 2 -
            Math.sin((i / settings.bars) * 4) * settings.height / 2 +
            "px"
          }}
        />
      );
    }
    return elements;
  };
  useEffect(() => {
      if(recorderControls.stopRecording){
        $('.audio-recorder-mic').attr('src',imageSource);
      }
  }, [recorderControls.stopRecording]);
  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  return (
    <section>
      {!audio ? (
        <div className="test">
          <h2>Free response</h2>
          <p>Your Topic</p>
          <textarea
            id="w3review"
            name="topic_name"
            rows={10}
            cols={150}
            value={textValue}
            onChange={handleTextareaChange}
            style={{ overflow: "auto" }}
            required
          />
          {error ? "Topic Name is required." : ""}
          {!myrecordings ? (
            <button className="test-btn mt-4" onClick={saveRecordingTopic}>
              Save
            </button>
          ) : (
            <button
              className="test-btn mt-4"
              onClick={() => editRecordingTopic(myrecordings.data.id)}
            >
              Edit
            </button>
          )}
           <Modal show={showDialog} onHide={handleCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>LIKE RECORDER MONTHLY LIMIT REACHED</Modal.Title>
                </Modal.Header>
                <Modal.Body>You have reached the maximum number. Please contact the administrator.</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
                
        
          {myrecordings ? (
            <div className="col-md-12 block">
              <h4>{myrecordings.data.topic_name}</h4>
            </div>
          ) : (
            ""
          )}
          <div className="mt-4">
            <div className="block my-recording-micro">
              <i className="fa fa-microphone big" aria-hidden="true" />
              {
        (recorderControls.isRecording ) ? 
        <div className="micro-sound-meter" style={{ position: "relative" }}>{createElements()}</div>: ''
      }
              <p>Countdown Timer</p>

             
              {recorderControls.isRecording ? (

                (!showDialog) ?
                <>
                <p>
                {recorderControls.isRecording
                  ? formatTime(totalSeconds)
                  : "02:00"}
              </p>
                <button
                  onClick={recorderControls.stopRecording}
                  className="test-btn stop"
                >
                  Stop
                </button>
                </>
                :
                ''
              ) : (
                (!showDialog) ? 
                <>
                <button
                  className="test-btn"
                  onClick={() => startRecording()}
                >
  
               
                 <AudioRecorder
                 onRecordingComplete={(blob) => addAudioElement(blob)}
                 recorderControls={recorderControls}
                 audioTrackConstraints={{
                   noiseSuppression: true,
                   echoCancellation: true,
                 }}
                 downloadOnSavePress={isIOS ? false : true}
                 downloadFileExtension="mp3"
                 showVisualizer={true}
               />
                 </button>
                  </>
                : ''
              )}

              {audioFile ? (
                <>
                  <audio className="hide" ref={audioRef} controls>
                    <source src={audioFile} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  {/* <button onClick={handleAudioAnalysis}>Submit</button> */}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="container">
        <div className="row audio-result">
          <div className="col-lg-12">
            {audio ? (
              <>
                <audio ref={audioRef} controls>
                  <source src={audioFile} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
                <button
                  onClick={()=>window.location.reload()}
                  className="test-btn"
                >
                  Record Again
                </button>
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th colSpan={5} style={{ textAlign: "center" }}>
                        LIKE Recorder Free Response Score Report
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{authUser.user.name}</td>
                      <td rowspan="5" className="result">
  { 
    getTotalAverageScore(
      getContentAverageScore(
        audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0]
      ), 
      getFluencyAverageScore(
        audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0]
      )
    ) >= 3 ? (
      <h4 style={{ color: "green" }}>Pass</h4>
    ) : (
      <h4 style={{ color: "red" }}>Fail</h4>
    )
  }
</td>

                    </tr>
                    <tr>
                      <td>Test Date</td>
                      <td>{new Date().toLocaleString('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short'
})}</td>
                    </tr>
                    <tr>
                      <td>Audio Quality</td>

                      <td>{audioQuality}</td>
                    </tr>
                    <tr>
                      <td>Total Recording Time</td>
                      <td>
  {
    audio?.data?.result?.message?.AAResult?.[0]?.audioLenSec?.[0]?._ 
    ? parseInt(audio.data.result.message.AAResult[0].audioLenSec[0]._) 
    : 0
  }{" "}
  Seconds
</td>
                    </tr>
                    <tr>
                      <td>Total Speaking Time</td>
                      <td>
  {audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.spkTimeSec?.[0] 
    ? audio.data.result.message.AAResult[0].analysis[0].speakingSummary[0].spkTimeSec[0] 
    : 0
  }{" "}
  Seconds
</td>

                    </tr>
                    <tr>
                      <td></td>
                      <td>Scores</td>
                      <td colSpan={5}>Comments</td>
                    </tr>
                    <tr>
                      <td>Content</td>
                      <td>
                        {getContentAverageScore(
        audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0]
      )}
                        /4
                      </td>
                      <td colSpan={5}>
                        {
                          getContentRemarks(
                            getContentAverageScore(
                              audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0]
                            )
                          )
                          
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Fluency</td>
                      <td>
                        {getFluencyAverageScore(
                          audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0]
                        )}
                        /4
                      </td>
                      <td colSpan={5}>
                        {getFluencyRemarks(getFluencyAverageScore(
                          audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0]
                        ))}
                      </td>
                    </tr>

                    <tr>
                      <td>Total</td>
                      <td>
                        {
                         getTotalAverageScore(getContentAverageScore(
                          audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0]
                        ), getFluencyAverageScore(
                          audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0]
                        ))
                        }/4
                      </td>
                      <td colSpan={5}>{getTotalRemarks(getTotalAverageScore(getContentAverageScore(
        audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0]
      ), getFluencyAverageScore(
        audio?.data?.result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0]
                        )))}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
