import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reportsActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";

export { VocabularyScoreReport };

function VocabularyScoreReport() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { student_id, school , type } = useParams();
  const date = new Date().toISOString().slice(0, 10);
  const { reports } = useSelector((x) => x.reports);
  const [classAverageData, setClassAverageData] = useState([]);

  useEffect(() => {
    var studentId = student_id;
    dispatch(reportsActions.getVocabularyReport({studentId,date, school, type}));
  }, []);
  // useEffect(() => {
  //   if(!reports){
  //     window.location.href='/vocabulary-score-report/'+student_id+'/'+school+'/'+type+'/'+date;
  //   }
  
  // }, [!reports]);
  const getSubType = (type) => {
    if (!type) {
      throw new Error("Type cannot be empty.");
    }
  
    var [school, subType] = type.split('-');
  
    const subTypeMapping = {
      'ETK': 'Eng-Kor',
      'ETE': 'Eng-Eng'
      // Add more mappings if needed
    };

    if(school == 'Korean'){
      school = 'ESL/EFL';
    }else{
      school = 'US';
    }
    return school+' '+subTypeMapping[subType] || subType;
  };

  const getSchool = (type,subtype) => {
    if (!type) {
      throw new Error("Type cannot be empty.");
    }
  
    var [school, subType] = type.split('-');
  if(subtype == 'school'){
    return school;
  }else{
    return subType;
  }

  };
  
  console.log(reports,'get reports');
  return (
    <div className="vocab-report-container">
  <div className="border-red">
    <h1>Vocabulary Score Report</h1>
    <div className="student-detail">
      <h3>
        Date: <span>{date}</span>
      </h3>
      <table>
        <tbody>
          <tr>
            <th>Student Name</th>
            <td>{((reports && reports.data) ? reports.data.student.koreanName:'')}</td>
          </tr>
          <tr>
            <th>Class</th>
            <td>{((reports && reports.data) ? reports.data.student.Class.class_name:'')}</td>
          </tr>
          <tr>
            <th>Level</th>
            <td>{((reports && reports.data) ? reports.data.student.level:'')}</td>
          </tr>
          <tr>
            <th>School</th>
            <td>{((reports && reports.data) ? reports.data.student.koreanName:'')}</td>
          </tr>
          <tr>
            <th>Year</th>
            <td>{((reports && reports.data) ? reports.data.student.school_year:'')}</td>
          </tr>
        </tbody>
      </table>
    </div>
    {(reports && reports.data && reports.data.vocabulary_data) ? 
    <>
    <div className="score-table">
      <table>
        <thead>
          <tr>
            <th />
            <th>Score Gained</th>
            <th># of Questions </th> 
           <th>Correct Answers</th>
          </tr>
        </thead>
        <tbody>

          {reports.data.vocabulary_data.map((vocab, index) => (
          <tr>
         
            <td>{vocab.Name} ({getSubType(vocab.type)})</td>
            <td>{vocab.studentscore}</td>
            <td>{vocab.total_questions}</td>
            <td>{vocab.correct_answered}</td>
          </tr>
          ))}

{/* {reports.data.vocabulary_data.Korean_ETK.map((vocab, index) => (
          <tr>
     
            <td>{vocab.Name} ({getSubType(vocab.type)})</td>
            <td>{vocab.studentscore}</td>
            <td>{vocab.total_questions}</td>
            <td>{vocab.correct_answered}</td>
          </tr>
          ))}

{reports.data.vocabulary_data.Korean_ETE.map((vocab, index) => (
          <tr>
  
            <td>{vocab.Name} ({getSubType(vocab.type)})</td>
            <td>{vocab.studentscore}</td>
            <td>{vocab.total_questions}</td>
            <td>{vocab.correct_answered}</td>
          </tr>
          ))}
      
         {reports.data.vocabulary_data.US_ETK.map((vocab, index) => (
          <tr>
  
            <td>{vocab.Name} ({getSubType(vocab.type)})</td>
            <td>{vocab.studentscore}</td>
            <td>{vocab.total_questions}</td>
            <td>{vocab.correct_answered}</td>
          </tr>
          ))} */}
        </tbody>
      </table>
    </div>
    <table>
      <thead>
        <tr>
          <th />
          <th>Class Average</th>
        </tr>
      </thead>
      <tbody>
      {reports.data.class_vocabulary_data.map((vocab, index) => (
          <tr>
            <td>{vocab.Name}</td>
            <td>{vocab.classscore.toFixed(2)}%</td>
          </tr>
          ))}
      </tbody>
    </table>
    </>
    :''}



    <div className="footer">
      <img src="/images/LIKEstudylogo.png" alt="logo" />
      <h4>053)424-2244 / 0909</h4>
      <h5>We Support Your Future!</h5>
    </div>
  </div>
  {(reports && reports.data && reports.data.previous_result) ? 
  <div className="border-black">
  <div className="row">
  {reports.data.previous_result.map((vocab, index) => (
    <a href={`/vocabulary-score-report/${student_id}/${getSchool(vocab.type, 'school')}/${getSchool(vocab.type, 'type')}/${vocab.date}`}>
      <div className="bottom-section">
      <div className="top-block">
        <p>{getSubType(vocab.type)}</p>
        <p>Date: {vocab.date}</p>
      </div>
      <div className="bottom-block">
        <p>Your current level is grade 1-2</p>
        <p>{getSubType(vocab.type)}</p>
        <p>{vocab.average}%</p>
      </div>
    </div>
    </a>
  ))}

  </div>
</div>
:''}
</div>


  );
}

