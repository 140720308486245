import { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    libraryActions
  } from "_store";
  import { useParams } from "react-router-dom";
  import { Table } from "react-bootstrap";
  import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
  import axios from "axios";
export const ListenSentenceDictation = () => {
  const dispatch = useDispatch();
  const { homework_id, program_id } = useParams();
  const { library } = useSelector((x) => x.library);
  const { user: authUser } = useSelector((x) => x.auth);
  const [wordsArray, setwords] = useState([]);
  const sentencesList = [
    { correct: 'Hi I am there', scrambled: 'iH I ma ereht' },
    { correct: 'React is awesome', scrambled: 'ctaRe si emosewa' },
    { correct: 'Coding is fun', scrambled: 'Cidnog si nfu' },
    { correct: 'Learning new things', scrambled: 'Lanernig wen gsthni' },
    { correct: 'Building cool projects', scrambled: 'Bnidlug noclo stjecrop' },
  ];
  const [sentences, setSentences] = useState([]);
 

  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const currentSentence = sentences[currentSentenceIndex];
  const [audio] = useState(new Audio()); // Initialize audio element
  // const wordsArray1 = currentSentence.scrambled.split(' ');

  // const initialLinkList = wordsArray1.map((word, index) => ({
  //   text: `${word} `,
  //   transform: index % 2 === 0 ? 10 : -10,
  // }));

  const [linkList, setLinkList] = useState([]);
  const [inputText, setInputText] = useState('');
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [showSentenceList, setShowSentenceList] = useState(true);
  const [showSummary, setShowSummary] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);

  const getNextTransform = () => {
    if (linkList.length === 0) {
      return 10; // Start with 10 if the list is empty
    }
    const lastTransform = linkList[linkList.length - 1].transform;
    return lastTransform === 10 ? -10 : 10;
  };
console.log(sentences[currentSentenceIndex],'get sentenece');
  useEffect(() => {
    // Clean up audio element when component unmounts
    return () => {
      audio.pause();
      audio.src = '';
    };
  }, [audio, currentSentence]);

  useEffect(() => {
    console.log(currentSentence,'current url');
    if(currentSentence){
    // Play sound effect when component mounts or when currentSentence changes
    audio.src = currentSentence.audioUrl; // Set audio source to sentence-specific URL
    audio.play();
    }

  }, [audio, currentSentence]);

  useEffect(() => {
    // This effect should only run once during mount
    dispatch(libraryActions.getSentencesForLibrary({program_id}));
  }, []); // Empty dependency array
  const playAudio = (index) => {
    audio.src = sentences[index].audioUrl || '';
    audio.play();
  };
  useEffect(() => {
    if (library && library.data) {
  
   //const sentenceQuestions = library.data.words.map(word => word.sentence_question);
  
  // Create the sentences array using only the sentence_question in the correct variable
  const sentences = library.data.words.map(word => ({ correct: word.sentence_question, scrambled: scrambleSentence(word.sentence_question),audioUrl:word.Bookwordaudio[0].filelink }));
  console.log(sentences,'get me');
  setSentences(sentences);

   // Initialize linkList with the first sentence
   const initialLinkList = sentences[0]?.scrambled.split(' ').map((word, index) => ({
    text: `${word} `,
    transform: index % 2 === 0 ? 10 : -10,
  })) || [];
  setLinkList(initialLinkList);
  
  
    }
  }, [library]);
console.log(sentences,'get sen');
const scrambleSentence = (sentence) => {
  // Split the sentence into an array of words
  const words = sentence.split(' ');

  // Unarrange the words by shuffling them
  for (let i = words.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [words[i], words[j]] = [words[j], words[i]];
  }
  setwords(words);
 
  const array = words.map((word, index) => ({
      text: `${word} `,
      transform: null,
    }));
    console.log(array,'get words');
    setLinkList(array);
  // Join the words back into a sentence
  const unarrangedSentence = words.join(' ');

  return unarrangedSentence;
};
  const handleLinkClick = (index) => {
  
    setInputText((prevText) => prevText + linkList[index].text);
    setLinkList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleWordClick = (word) => {
    const nextTransform = getNextTransform();
    setLinkList((prevList) => [
      ...prevList,
      { text: `${word} `, transform: nextTransform },
    ]);
    setInputText((prevText) => prevText.replace(word, '').trim());
  };

  const handleNextSentence = async() => {
    const correctedSentence = currentSentence.correct.trim();
    if (inputText.trim() === correctedSentence) {
      setCorrectCount((prevCount) => prevCount + 1);
    } else {
      setIncorrectCount((prevCount) => prevCount + 1);
    }

    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      inputText.trim(), // Save user's answer
    ]);

    if (currentSentenceIndex === sentences.length - 1) {
      setShowSummary(true);
      const result = []
      {
        userAnswers.map((answer, index) => (
          result.push({ 'scrambled': sentences[index].scrambled, 'correct': sentences[index].correct, 'your_answer': answer })
        ))
      }
      const scoreprecentage=((correctCount / sentences.length) * 100);
      const option = {
       // category: location.state.vocabulary.id,
        total_score:scoreprecentage,
        Studentid: authUser.user.student_id,
        type: "Listening: Sentence Dictation",
        sub_type: "Listening: Sentence Dictation",
        homework_id:homework_id,
        result: JSON.stringify(result)
      }
      console.log(option, "opt")
      const response = await axios.post(
        "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
        option,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            // Add other headers if needed
          },
        }
      );
      console.log(response);
    } else {
      setCurrentSentenceIndex((prevIndex) => prevIndex + 1);
      setInputText('');
      const nextWordsArray = sentences[currentSentenceIndex + 1]?.scrambled.split(' ') || [];
      setLinkList(nextWordsArray.map((word, index) => ({
        text: `${word} `,
        transform: index % 2 === 0 ? 10 : -10,
      })));
      console.log(sentences[currentSentenceIndex + 1]?.audioUrl,'get auiod here');
           // Play sound effect for the next sentence
           audio.src = sentences[currentSentenceIndex + 1]?.audioUrl || '';
           audio.play();
    }
  };

  const handleBackSentence = () => {
    if (currentSentenceIndex > 0) {
      setCurrentSentenceIndex((prevIndex) => prevIndex - 1);
      setInputText('');
      const prevWordsArray = sentences[currentSentenceIndex - 1].scrambled.split(' ');
      setLinkList(prevWordsArray.map((word, index) => ({
        text: `${word} `,
        transform: index % 2 === 0 ? 10 : -10,
      })));
       // Play sound effect for the previous sentence
       audio.src = sentences[currentSentenceIndex - 1]?.audioUrl || '';
       audio.play();
    }
  };

  const handleStart = () => {
    setShowSentenceList(false);
  };

  const renderSentenceList = () => (
    <div>
      {/* <p style={{ color: '#fff' }}>List of Sentences:</p> */}
      {/* <ul style={{ listStyle: 'none', padding: 0}}> */}
        {sentences.map((sentence, index) => (
          <p key={index}  onClick={() => playAudio(index)}>{`${index + 1}. ${sentence.correct}`}</p>
        ))}
      {/* </ul> */}
      <button onClick={handleStart}>Start</button>
    </div>
  );

  const renderScrambledSentence = () => (
    <div className="answer-section">
      <p className='correct-answer'>{`${currentSentenceIndex + 1} out of ${sentences.length}`}</p>
      <p className='remaining-sentence'>{`Correct: ${correctCount}`}</p>
      <p className='hear-section'><i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i></p>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {linkList.map((link, index) => (
          <li
            key={index}
            style={{ display: 'inline', marginRight: '5px' }}
            onClick={() => handleLinkClick(index)}
          >
            <a style={{ transform: `rotate(${link.transform}deg)`, display: 'inline-block' }}>
              {link.text}
            </a>
          </li>
        ))}
      </ul>
      <div>
        {inputText.split(' ').map((word, index) => (
          <span
            key={index}
            onClick={() => handleWordClick(word)}
            style={{ cursor: 'pointer', marginRight: '5px' }}
          >
            {word}
          </span>
        ))}
      </div>
      {currentSentenceIndex > 0 && (
        <button onClick={handleBackSentence}>Back</button>
      )}
      {currentSentenceIndex === sentences.length - 1 && (
        <button onClick={handleNextSentence}>Finish</button>
      )}
      {currentSentenceIndex < sentences.length - 1 && (
        <button onClick={handleNextSentence}>Next</button>
      )}
    </div>
  );
console.log(userAnswers,'get answer');
  const renderSummary = () => {
    const percentage = ((correctCount / sentences.length) * 100);
    const remaining=100-percentage;
  const data = [{ name: 'Your score', score: percentage, total: remaining  }];
   return(
     <div>
        <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
  <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart>
      <span className='score'>
    <div className="score-wrap">
        <span className="stars-active" style={{ width:`${percentage}%`}}>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
<span className="stars-inactive">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
</div>
</span>
<Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{correctCount} / {sentences.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>
          <div className="quiz-results">
          
            <Table striped bordered responsive>
              <thead>
                <tr>
                   <th>O X</th>
                  <th></th>
                  <th>Sentence Scramble</th>
                  <th>Correct Answer</th>
                  <th>Your Answer</th>
        
                </tr>
              </thead>
              <tbody>
                {userAnswers.map((answer, index) => (
                  <tr key={index}>
                    <th>{(answer == sentences[index].correct) ? 'O' : 'X'}</th>
                    <td>{index + 1}</td>
                    <td>{sentences[index].scrambled}</td>
                    <td>{sentences[index].correct}</td>
                    <td> {answer}</td>
                    
                  </tr>

                ))}
              </tbody>
            </Table>
          </div>
          {/* <ul style={{ listStyle: 'none', padding: 0 }}> */}
  {/* {userAnswers.map((answer, index) => (
    <li key={index}>
      {console.log(sentences[index])}
      {`Question ${index + 1}: Your Answer - ${answer}, Correct Answer - ${sentences[index].correct},Scrambled - ${sentences[index].scrambled}`}
    </li>
  ))}
</ul> */}
    </div>)
  } ;

  return (
    <section>
    <div className="test sentence">
    <h1 className="sentence-title">Listening: Sentence Dictation</h1>
      {showSentenceList ? (
        renderSentenceList()
      ) : showSummary ? (
        renderSummary()
      ) : (
        renderScrambledSentence()
      )}
    </div>
    </section>
  );
};
