import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quizcategoryActions ,quizresultActions} from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

export { Quizsection };

function Quizsection() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [category, setcategory] = useState("");
  const { quizcategory, quizcategoryLoading } = useSelector((x) => x.quizcategory);
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);

  const student=JSON.parse(localStorage.getItem('user'));
  const vocbsetting=location.state.vocabsettings;
  const vocabcategory=location.state?location.state.vocabulary:''
    const getStampFromScore = (score) => {
    let stampText = '/images/tryagain1.png';
    
    if (score >= 0 && score <= 40) {
      stampText = '/images/tryagain1.png';
    }  else if (score > 40 && score <= 60) {
      stampText = '/images/greatjob1.png';
    } else if (score > 60 && score <= 75) {
      stampText = '/images/amazing1.png';
    } else if (score > 75 && score <= 100) {
      stampText = '/images/excellent1.png';
    }
    
    return stampText;
    };


  useEffect(() => {
  if (!location?.state?.vocabulary) {
    window.location.href = '/';
    return;
  }

  setcategory(location.state.vocabulary.filename);

  if (student?.user?.student_id) {
    dispatch(
      quizcategoryActions.getquizcategory({
        student: student.user.student_id,
        category: location.state.vocabulary.id,
      })
    );
  }
}, [dispatch, location.state, student?.user?.student_id]);

useEffect(() => {
  if (quizcategory && !quizcategoryLoading) {
    console.log(quizcategory, "catgq");
  }
}, [quizcategory, quizcategoryLoading]);

  const renderQuizContent = () => {
    if(quizcategory){
    const results=  quizcategory[0][0].results;
    let cumulativeCount = 0;
    
    // Use map to generate an array of <td> elements
    const quizElements = quizcategory.map((question, index) => {
      console.log(question,"quest--");
      const checkresult=results.filter(item=>item.setindex==index);
     var currentresult='';
     var percentage = 0;
       if(checkresult.length > 0 ){
         currentresult=checkresult[0];
         percentage = ((currentresult.score / currentresult.totalquestion) * 100);

       }
      cumulativeCount += question.length;
      return (
        <div className='col-md-2 ' key={index}>
        <div className='quiz-set' style={{ height: currentresult ? 'auto' : '94%' }}>
          <Link to={{ pathname: '/testquiz' }} state={{ "setindex":index,"vocabulary": vocabcategory,"questions":question ,"vocbsetting":vocbsetting ,"currentresult":currentresult}}>
          <img src="/images/text_book_vac_one.png" />
          {currentresult?<>
           <img
           style={{ width:'135px',left:'15%',bottom:'50px' }}
                src={getStampFromScore(percentage)}
                alt="Uploaded File"
               class="img-fluid book-cert recording-list"
             />
            
          
          <div className="rating " style={{ color: "#01b7ff8c"}}>
            <i className={`fa fa-star  ${percentage > 20 ? 'active' : ''}`} aria-hidden="true"></i>
             <i className={`fa fa-star  ${percentage > 40 ? 'active' : ''}`}aria-hidden="true"></i>
             <i className={`fa fa-star ${percentage > 55 ? 'active' : ''}`}aria-hidden="true"></i>
             <i className={`fa fa-star ${percentage > 70 ? 'active' : ''}`}aria-hidden="true"></i>
             <i className={`fa fa-star ${percentage > 85 ? 'active' : ''}`}aria-hidden="true"></i>
          </div>
          </>:''
        }
          <h5 className='question-set'>{cumulativeCount - question.length + 1}-{cumulativeCount}</h5>
          </Link>
          </div>
        </div>
      );
    });
  
    // Return the generated elements within a container (e.g., a <table>)
    return (
      <div className='row quiz-section-set'>
     
          {quizElements}
      
      </div>
    );
    }
  };
  




  return (
    <div className="container">
        <div className='quiz-set-page'>
     <h1>VOCABULARY CHALLENGES</h1>
      <h2>{category}</h2>
     {renderQuizContent()}
     </div>
        
      
   </div>
  );
}
