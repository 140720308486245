import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'myvocab';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const myvocabActions = { ...slice.actions, ...extraActions };
export const myvocabReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        createMyOwnVocab: createMyOwnVocab(),
        getAllOwnVocab: getAllOwnVocab(),
        getOwnVocabById: getOwnVocabById(),
        getAllOwnVocabByStudentId: getAllOwnVocabByStudentId(),
       // editMyOwnVocab:editMyOwnVocab(),
        getSingleOwnVocabById:getSingleOwnVocabById(),
        //deleteMyOwnVocab: deleteMyOwnVocab(),
    };    


    function createMyOwnVocab() {
        return createAsyncThunk(
            `${name}/myvocab`,
            async ({vocabname, teacher_id, date, words, student_id, class_id, type}) => await fetchWrapper.post(`${baseUrl}/myvocab/create`,{vocabname, teacher_id, date, words, student_id, class_id, type })
        );
    }

    function getAllOwnVocab() {
        return createAsyncThunk(
            `${name}/myvocab`,
            async ({teacher_id}) => await fetchWrapper.get(`${baseUrl}/myvocab/teacher/`+teacher_id)
        );
    }

    function getOwnVocabById() {
        return createAsyncThunk(
            `${name}/myvocab`,
            async ({id}) => await fetchWrapper.get(`${baseUrl}/myvocab/wordsoptions/`+id)
        );
    }

    function getAllOwnVocabByStudentId() {
        return createAsyncThunk(
            `${name}/myvocab`,
            async ({student_id}) => await fetchWrapper.get(`${baseUrl}/myvocab/student/`+student_id)
        );
    }

    // function editMyOwnVocab() {
    //     return createAsyncThunk(
    //         `${name}/myvocab`,
    //         async ({vocab_id, vocabname, teacher_id, words, student_id}) => await fetchWrapper.put(`${baseUrl}/myvocab/edit/${vocab_id}`,{vocabname, teacher_id, words, student_id })
    //     );
    // }

    function getSingleOwnVocabById() {
        return createAsyncThunk(
            `${name}/myvocab`,
            async ({vocab_id, teacher_id, student_id}) => await fetchWrapper.get(`${baseUrl}/myvocab/single/`+vocab_id+'?teacher_id='+teacher_id+'&student_id='+student_id)
        );
    }

    // function deleteMyOwnVocab() {
    //     return createAsyncThunk(
    //         `${name}/myvocab`,
    //         async ({vocab_id}) => await fetchWrapper.delete(`${baseUrl}/myvocab/delete/${vocab_id}`)
    //     );
    // }


}

function createExtraReducers() {
    return {
        ...createMyOwnVocab(),
        ...getAllOwnVocab(),
        ...getOwnVocabById(),
        ...getAllOwnVocabByStudentId(),
        //...editMyOwnVocab(),
        //...deleteMyOwnVocab(),
        ...getSingleOwnVocabById(),
    };

    function createMyOwnVocab() {
        var { pending, fulfilled, rejected } = extraActions.createMyOwnVocab;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const myvocab = action.payload;

                state.myvocab = myvocab;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }
    
    function getAllOwnVocab() {
        var { pending, fulfilled, rejected } = extraActions.getAllOwnVocab;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const myvocab = action.payload;

                state.myvocab = myvocab;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }
    function getOwnVocabById() {
        var { pending, fulfilled, rejected } = extraActions.getOwnVocabById;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const myvocab = action.payload;

                state.myvocab = myvocab;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getAllOwnVocabByStudentId() {
        var { pending, fulfilled, rejected } = extraActions.getAllOwnVocabByStudentId;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const myvocab = action.payload;

                state.myvocab = myvocab;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    // function editMyOwnVocab() {
    //     var { pending, fulfilled, rejected } = extraActions.editMyOwnVocab;
    //     return {
    //         [pending]: (state) => {
    //             state.error = null;
    //         },
    //         [fulfilled]: (state, action) => {
    //             const myvocab = action.payload;

    //             state.myvocab = myvocab;

    //         },
    //         [rejected]: (state, action) => {
    //             console.log(action.error,'get er');
    //             state.error = action.error;
    //             toast.error(action.error.message, {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //                 });
    //         }
    //     };
    // }

    function getSingleOwnVocabById() {
        var { pending, fulfilled, rejected } = extraActions.getSingleOwnVocabById;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const myvocab = action.payload;

                state.myvocab = myvocab;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    // function deleteMyOwnVocab() {
    //     var { pending, fulfilled, rejected } = extraActions.deleteMyOwnVocab;
    //     return {
    //         [pending]: (state) => {
    //             state.error = null;
    //         },
    //         [fulfilled]: (state, action) => {
    //             // const myvocab = action.payload;

    //             // state.myvocab = myvocab;

    //         },
    //         [rejected]: (state, action) => {
    //             console.log(action.error,'get er');
    //             state.error = action.error;
    //             toast.error(action.error.message, {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //                 });
    //         }
    //     };
    // }


}
