import { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    studentActions,
    audioActions,
    homeworkActions,
    readingActions,
    libraryActions
  } from "_store";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import $ from 'jquery';
const settings = {
    bars: 7,
    spacing: 3,
    width: 5,
    height: 100
  };
  const SentenceList = ({ sentences }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handlePrevious = () => {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, sentences.length - 1));
    };
  
    return (
      <div>
        <p className='sentence-read'>{(sentences[currentIndex]) ? sentences[currentIndex].word : ''}</p>
        <div className='sentence-read-btn'>
          <button onClick={handlePrevious} disabled={currentIndex === 0}>
            Previous
          </button>
          <button onClick={handleNext} disabled={currentIndex === sentences.length - 1}>
            Next
          </button>
        </div>
      </div>
    );
  };
  
  export function SpeakingRespondInEnglish() {
    // Replace this with your actual list of words
    const wordList = ['declare', 'demand', 'jabber', 'recess', 'smash', 'stink bug', 'survey', 'toughest', 'weird', 'whisper'];
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((x) => x.auth);
    const { homework_id, program_id } = useParams();
    const { homework } = useSelector((x) => x.homework);
    const { reading } = useSelector((x) => x.reading);
    const { student } = useSelector((x) => x.student);
    const { myrecordings } = useSelector((x) => x.myrecordings);
    const { library } = useSelector((x) => x.library);
    const [textValue, setTextValue] = useState("");
    const initialMinutes = 2;
    const initialSeconds = initialMinutes * 60;
    const [teacherResult, setTeacherResult] = useState(null);
    const [totalSeconds, setTotalSeconds] = useState(initialSeconds);
    const audioRef = useRef(null);
    const [audioFile, setAudioFile] = useState("");
    const [filePath, setFilePath] = useState("");
    const [audioQuality, setAudioQuality] = useState("");
    const [recordId, setRecordId] = useState("");
    const recorderControls = useAudioRecorder();
    const [showDialog, setShowDialog] = useState(false);
    const refs = useRef([]);
    const volumemeter = useRef(0);
    const volumeRefs = useRef(new Array(settings.bars));
    const [imageSource, setImageSource] = useState('/images/record.png');
    const [sentences, setSentences] = useState([]);
    const [sentencesForAudio, setSentencesForAudio] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const [sentencesForXml, setSentencesForXml] = useState([]);
    console.log(sentencesForXml,'get sentence');

    useEffect(() => {
      if (recordId) {
        var file = filePath;
        var text = (sentencesForXml) ? sentencesForXml.join(' ') :'';
        var type = 'Speaking Respond In English';
        dispatch(readingActions.readingaudiorecording({ recordId,text, file, type,homework_id }));

      }
    }, [recordId]);
    useEffect(() => {
      if (reading) {
          window.location.href = '/ReadOutLoudGrader/'+recordId;
      }
    }, [reading]);
    console.log(reading,'get user');
    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
  
    const addAudioElement = async (blob) => {
      const url = URL.createObjectURL(blob);
      //alert("lalit");
  
      blobToBase64(blob).then((res) => {
        // do what you wanna do
        console.log(res, "blob"); // res is base64 now
      });
      //
      const formData = new FormData();
      formData.append("audio", blob);
      formData.append("user", authUser.user.student_id);
      if(homework_id){
        formData.append("homework_id", homework_id);
      }
    
      var text = (homework && homework.data) ? homework.data.homework_text :'';
      formData.append("text", text);
      formData.append("type", 'Speaking Respond In English');
      formData.append("sub_type", 'Speaking Respond In English');
      formData.append("program_id", program_id);
      const response = await axios.post(
        "https://api.webtracktechnologies.com.au:8002/audio-recording",
        formData
      );
      setAudioFile(response.data.data);
      setFilePath(response.data.filepath);
      setRecordId(response.data.audio_record.id);
      //  const audio1 = document.createElement("audio");
      //   audio1.src = url;
      //   audio1.controls = true;
    };
  
    const handleTextareaChange = (event) => {
      setTextValue(event.target.value);
    };
  
    useEffect(() => {
      if (recorderControls.isRecording) {
        const interval = setInterval(() => {
          if (totalSeconds > 0) {
            setTotalSeconds(totalSeconds - 1);
          }
        }, 1000);
  
        return () => clearInterval(interval);
      }
    }, [recorderControls.isRecording, totalSeconds]);
  
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };
  
    useEffect(() => {
      if (library && library.data) {
        const sentenceQuestions1 = library.data.words.map(word => ({word: [word.question,word.answer],audio: word.question_audio, answer_audio: word.answer_audio}));
        setSentencesForAudio(sentenceQuestions1);
        const sentenceQuestions = library.data.words.map(word => ({word: word.question,audio: word.question_audio, answer_audio: word.answer_audio}));
        setSentences(sentenceQuestions);
        const sentences = library.data.words.map(word =>word.question);
        setSentencesForXml(sentences);
      }
    }, [library]);

    useEffect(() => {
      dispatch(homeworkActions.getHomeWorkByRecordId({ homework_id }));
      var type = 'SRIE';
      dispatch(libraryActions.getSentencesForLibrary({program_id, type}));
      $('.audio-recorder-mic').attr('src',imageSource);
    }, []);
  
  
    const startRecording = () => {
      
        recorderControls.startRecording;
      
    }

    const getMedia = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();
          const microphone = audioContext.createMediaStreamSource(stream);
          const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
          analyser.smoothingTimeConstant = 0.4;
          analyser.fftSize = 1024;
          microphone.connect(analyser);
          analyser.connect(javascriptNode);
          javascriptNode.connect(audioContext.destination);
          javascriptNode.onaudioprocess = () => {
            var array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            var values = 0;
            var length = array.length;
            for (var i = 0; i < length; i++) {
              values += array[i];
            }
            volumemeter.current = values / length;
          };
        })
        .catch(function(err) {
          /* handle the error */
        });
    };
  
    useEffect(getMedia, []);
    useEffect(() => {
      const intervalId = setInterval(() => {
        volumeRefs.current.unshift(volumemeter.current);
        volumeRefs.current.pop();
        if(refs.current.length > 0){
          //console.log(refs.current,'get reference');return false;
          for (let i = 0; i < refs.current.length; i++) {
  if(refs.current[i] != '' && refs.current[i] != null){
  
    refs.current[i].style.transform = `scaleY(${volumeRefs.current[i] /
      100})`;
  }
          }
        }
      }, 20);
      return () => {
        clearInterval(intervalId);
      };
    }, []);
  
    const createElements = () => {
      let elements = [];
  
      for (let i = 0; i < settings.bars; i++) {
        elements.push(
          <div
            ref={ref => refs.current.push(ref)}
            key={`vu-${i}`}
            style={{
              position: "absolute",
              background: "#5ac63d",
              borderRadius: settings.width + "px",
              width: settings.width + "px",
              height: Math.sin((i / settings.bars) * 4) * settings.height + "px",
              left: i * (settings.width + settings.spacing) + "px",
              top:
              settings.height / 2 -
              Math.sin((i / settings.bars) * 4) * settings.height / 2 +
              "px"
            }}
          />
        );
      }
      return elements;
    };
    useEffect(() => {
      if(recorderControls.stopRecording){
        $('.audio-recorder-mic').attr('src',imageSource);
      }
  }, [recorderControls.stopRecording]); 
  const handleListenClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handlePlayAll = () => {
    // Play audio files for all sentences
    sentencesForAudio.forEach((word, index) => {
      // First, play the question audio
    setTimeout(() => {
      playAudio(index, 'question');
    }, index * 10000); // Play question audio with delay

    // Then, play the answer audio after the question
    setTimeout(() => {
      playAudio(index, 'answer');
    }, index * 10000 + 5000); // Play answer audio 5 seconds after the question
    });
  };

  const playAudio = (index, type) => {
    const audioElement = document.getElementById(`audio-${index}-${type}`);
    console.log(audioElement,'get elemt');
    if(audioElement){
      audioElement.play();
    }

    setHighlightedIndex(`${index}-${type}`); // Highlight the currently playing word
  };

  var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
 // console.log(sentencesForAudio);return false;
    return (
      <section>
        <div className="test">
          <h1>Speaking: Respond In English</h1>
          <SentenceList sentences={sentences} />
          <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Listen</Modal.Title>
        </Modal.Header>
        <Modal.Body className='read-out-loud-listen'>
        <ul>
  {sentencesForAudio.map((word, index) => (
    <>
      <li 
        key={`${index}-question`}
        attr-audio={word.audio} 
        onClick={() => playAudio(index, 'question')} 
        style={{ fontWeight: `${index}-question` === highlightedIndex ? 'bold' : 'normal' }}
      >
        {word.word[0]} {/* Display the question */}
        <audio id={`audio-${index}-question`} src={word.audio} />
      </li>
      <li 
      key={`${index}-answer`}
        attr-audio={word.answer_audio} 
        onClick={() => playAudio(index, 'answer')} 
        style={{ fontWeight: `${index}-answer` === highlightedIndex ? 'bold' : 'normal' }}
      >
        {word.word[1]} {/* Display the answer */}
        <audio id={`audio-${index}-answer`} src={word.answer_audio} />
      </li>
    </>
  ))}
</ul>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePlayAll}>
            Play All
          </Button>
        </Modal.Footer>
      </Modal>
          <div className="mt-4">
              <div className="block my-recording-micro">
                <i className="fa fa-microphone big" aria-hidden="true" />
                {
          (recorderControls.isRecording ) ? 
          <div className="micro-sound-meter" style={{ position: "relative" }}>{createElements()}</div>: ''
        }
                <p>Countdown Timer</p>
  
               
                {recorderControls.isRecording ? (
  
                  (!showDialog) ?
                  <>
                  <p>
                  {recorderControls.isRecording
                    ? formatTime(totalSeconds)
                    : "02:00"}
                </p>
                  <button
                    onClick={recorderControls.stopRecording}
                    className="test-btn stop"
                  >
                    Stop
                  </button>
                 
                  </>
                  :
                  ''
                ) : (
                  (!showDialog) ? 
                  <>
                  <button
                    className="test-btn"
                    onClick={() => startRecording()}
                  >
                    
                    <AudioRecorder
                      onRecordingComplete={(blob) => addAudioElement(blob)}
                      recorderControls={recorderControls}
                      audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                      }}
                      downloadOnSavePress={isIOS ? false : true}
                      downloadFileExtension="mp3"
                      showVisualizer={true}
                    />
                  </button>
                   <button onClick={() => handleListenClick()}>Listen</button>
                   </>
                  : ''
                )}
  
                {audioFile ? (
                  <>
                    <audio className="hide" ref={audioRef} controls>
                      <source src={audioFile} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                   
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
        </div>
      </section>
    );
  };
  
  export default SpeakingRespondInEnglish;
  

