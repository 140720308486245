import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { studyActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
export { StudyTestForListeningMultipleChoice };

function StudyTestForListeningMultipleChoice() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { record_id, typeOfPage, test_id } = useParams();
  const [myState, setMyState] = useState('initialValue');
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);
  const { passage } = useSelector((x) => x.passage);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [quizCategory, setquizCategory] = useState();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizalready, setQuizAlready] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const [timer, setTimer] = useState(null);
  const [day, setDay] = useState(1);
  const [resultData, setResult] = useState([]);
  const [audio] = useState(new Audio()); // Initialize audio element
  const [currentGroup, setCurrentGroup] = useState(1);
  const [totalGroups, setTotalGroups] = useState(1);
  const [questionsPerGroup, setquestionsPerGroup] = useState(1);
  const [groupText, setGroupText] = useState('');
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [allQuestions, setAllQuestions] = useState();
  const [audioSrc, setAudio] = useState();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(36);
  const audioRef = useRef(null);
  const [questionAnswered, setQuestionAnswered] = useState(0);
  const [correctAnswered, setCorrectAnswered] = useState(0);
  const [viewText1, setViewText] = useState(false);


  const student = JSON.parse(localStorage.getItem('user'));
  //console.log(passage,'get lib',quizData,audio,student);
  useEffect(() => {
    var program_id = record_id;
    var type = '';
    if (typeOfPage === 'RMC') {
      type = 'Reading Multiple Choice';
    } else if (typeOfPage === 'LMC') {
      type = 'Listening Multiple Choice';
    } else if (typeOfPage === 'ROL') {
      type = 'Read out Loud';
    }
    dispatch(studyActions.programQuestionInfo({ program_id, type }));
  }, [record_id, typeOfPage, dispatch]);

  const handleOptionSelect = (selected) => {
    setSelectedOption(selected);
    //handleNextQuestion(selected);
    setUserAnswers(prevAnswers => [...prevAnswers, selected]);
  };

  const handleSubmit = (selected) => {
    //setSelectedOption(selected);
    handleNextQuestion(selected);
    setQuestionAnswered(prevCount => prevCount + 1);
    //setUserAnswers(prevAnswers => [...prevAnswers, selected]);
  };


  const handleNextQuestion = (selectedOption) => {
    // if (timer) {
    //   clearTimeout(timer);
    // }

    if (selectedOption === quizData[currentQuestion].answer) {
      setScore(score + 1);
      setCorrectAnswered(correctAnswered + 1);
    }

    setSelectedOption(null);

    if (currentQuestion + 1 < quizData.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      console.log("quiz comp");
               // Move to the next group if available
    if (currentGroup < totalGroups) {
      const nextGroupIndex = currentGroup;
      const nextGroupData = passage.data.data[nextGroupIndex].quiz_questions;
      setGroupText(passage.data.data[nextGroupIndex].text);
     
      setQuizData(nextGroupData);
      setquestionsPerGroup(nextGroupData.length);
      setCurrentGroup(currentGroup + 1);
      setCurrentQuestion(0); // Start from the first question of the new group
      setQuizCompleted(false);
      if (selectedOption === quizData[currentQuestion].answer) {
        setScore(score + 1);
        setCorrectAnswered(correctAnswered + 1);
      }
      const audioSrc1 = (passage && passage.data) ? passage.data.data[nextGroupIndex].text_audio : '';
      setAudio(audioSrc1);
    } else {
      // Quiz is completed when all groups and questions are done
      console.log("Quiz completed");
      setQuizCompleted(true);
      return;
    }
    }

    // if (currentQuestion === quizData.length - 1) {
    //   console.log('Setting quizCompleted to true');
    //   setQuizCompleted(true);
    // }

    // setTimeRemaining(location.state.vocbsetting.time);
  };



  const handleKeyPress = (event) => {
    const selectedNumber = Number(event.key);
    if (!isNaN(selectedNumber) && selectedNumber > 0 && selectedNumber <= quizData[currentQuestion].options.length) {
      const selectedOption = quizData[currentQuestion].options[selectedNumber - 1];
      handleOptionSelect(selectedOption);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      // Update seconds
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        // If seconds reach 0, update minutes and reset seconds
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          // If both minutes and seconds reach 0, clear the interval
          clearInterval(interval);
          handleTimeExpired();
          // You can add logic here to handle what happens when the timer reaches 0
          console.log('Timer expired!');
        }
      }
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [minutes, seconds]);
// Format the time values to ensure they have leading zeros
const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  useEffect(() => {
    try {
      if (quizData.length === 0) {
        // if (!location.state) {
        //   window.location.href = '/';
        // }
        if(passage && passage.data){
          var totalGroups = passage.data.data.length;
           // Set the totalGroups state
          setTotalGroups(totalGroups);
          const groupData = passage.data.data;

          // Set quizData for Group 1 initially
          setQuizData(groupData[0].quiz_questions);
          setquestionsPerGroup(groupData[0].quiz_questions.length); // Add this line to set questionsPerGroup
          setGroupText(groupData[0].text);
          // Count total questions for both groups
  const totalQuestions = groupData.reduce((total, group) => total + group.quiz_questions.length, 0);
  
  setTotalQuestions(totalQuestions);
  // Concatenate all quiz_questions arrays into a single array
  const allQuestions = [].concat(...groupData.map(group => group.quiz_questions));
  
  setAllQuestions(allQuestions);
                    // Add the following lines to load and play the audio
        const audioSrc1 = (passage && passage.data) ? groupData[0].text_audio : '';
        // audio.src = audioSrc;
        // audio.load();
        // audio.play();
        setAudio(audioSrc1);
        }
        
        setquizCategory("Listening Multiple Choice");

      }


    }
    catch (error) {
      console.error("An error occurred:", error);
    }
  }, [passage]);



  useEffect(() => {

    window.addEventListener('keydown', handleKeyPress);


    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (quizCompleted === true) {

          const result = []
          {
            allQuestions.map((question, index) => (
              result.push({ question: question.question, answer: question.answer, options:question.options, useranswer: (index < userAnswers.length) ? userAnswers[index] : "Not answered" })
            ))
          }
    

          if (student) {
             const scoreprecentage=((score / allQuestions.length) * 100);
            const option = {
             // category: location.state.vocabulary.id,
             total_score:correctAnswered,
             Studentid: student.user.student_id,
             type: "Test",
             test_id:test_id,
             program_id:record_id,
             result: JSON.stringify(result),
             sub_type:"Listening Multiple Choice",
             total_questions:allQuestions.length,
             question_answered: questionAnswered,
             percentage: scoreprecentage,
             correct_answered: correctAnswered,
            }
            var resultOpt = {
              result:JSON.stringify(result)
            }
            const resultRes = await axios.post(
              "https://api.webtracktechnologies.com.au:8002/library/grammer/checkresult",
              resultOpt,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  // Add other headers if needed
                },
              }
            );
            if(resultRes){

              setResult(resultRes);
              const response = await axios.post(
                "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
                option,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                  },
                }
              );
              //console.log(response);
            }
     
            //dispatch(homeworkActions.savevocabresult(option));
          }
    
        }
      } catch (error) {
        console.error('Error fetching homework data:', error);
      }
    };
  
    fetchData();
  }, [dispatch, quizCompleted, quizCategory, score, quizData, userAnswers]);
  
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  const handleSpeakerClick = () => {
    console.log(audioRef.current,'audio get');
    // Play the audio when the speaker icon is clicked
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  const renderOptions = () => {
    const currentQuizData = quizData[currentQuestion];
    
    return (
      <>
        {currentQuizData.audio.length > 0 && (
          <div key={currentQuestion}>
            {/* Display speaker icon for the first audio */}
            <span
              role="img"
              aria-label="speaker"
              style={{ fontSize: '40px', cursor: 'pointer' }}
              onClick={handleSpeakerClick}
            >
              🔊  &nbsp;<h6 style={{ fontSize: '18px', color: '#0f59a8' }}>{currentQuizData.question}</h6>
            </span>
            <p></p>
            <audio ref={audioRef} style={{ display: 'none' }}>
              <source src={currentQuizData.audio[0]} type="audio/mp3" />
              Your browser does not support the audio tag.
            </audio>
          </div>
        )}

        {/* Display options */}
        {currentQuizData.options.map((option, index) => (
          <div key={index} className='quiz-options'>
            <label className='option-index'>{index + 1}</label>
            <label className="container" htmlFor={option}>
              {option}
              <input
                type="radio"
                id={option}
                name="options"
                value={option}
                checked={selectedOption === option}
                onChange={() => handleOptionSelect(option)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        ))}
        
        {/* Submit button */}
        <button className='quiz-button' onClick={() => handleSubmit(selectedOption)}>Submit</button>
      </>
    );
  };

  // const renderOptions = () => (
  //   <>
  //     {/* <p>{quizData[currentQuestion].question}</p> */}
  //     {(quizData[currentQuestion].audio.length > 0) ? quizData[currentQuestion].audio.map((audioUrl, index) => (
  //       <div key={index}>
  //         {index === 0 ? (
  //           // Display speaker icon for the first audio
  //           <>
  //           <span role="img" aria-label="speaker" style={{ fontSize: '40px',cursor: 'pointer' }} onClick={handleSpeakerClick}>
  //           🔊  &nbsp;<h6 style={{ fontSize: '18px',color:'#0f59a8' }}>{quizData[currentQuestion].question}</h6>
  //         </span>
  //         <p></p>
  //         <audio ref={audioRef}  style={{ display: 'none' }}  controls>
  //           <source src={audioUrl}type="audio/mp3" />
  //           Your browser does not support the audio tag.
  //         </audio>
  //         </>
  //         ) : (
  //           // Display regular audio element for subsequent audios
  //           <audio controls>
  //             <source src={audioUrl} type="audio/mp3" />
  //             Your browser does not support the audio tag.
  //           </audio>
  //         )}
  //       </div>
  //     )) :<h6 style={{ fontSize: '18px',color:'#0f59a8' }}>{quizData[currentQuestion].question}</h6>}
  //     {quizData[currentQuestion].options.map((option, index) => (
  //       <div key={index} className='quiz-options'>
  //         <label className='option-index'>{index + 1}</label>
  //         <label className="container" htmlFor={option}>
  //           {option}
  //           <input
  //             type="radio"
  //             id={option}
  //             name="options"
  //             value={option}
  //             checked={selectedOption === option}
  //             onChange={() => handleOptionSelect(option)}
  //           />
  //           <span className="checkmark"></span>
  //         </label>
  //       </div>
  //     ))}
  //      <button className='quiz-button' onClick={() => handleSubmit(selectedOption)}>Submit</button>
  //   </>
  // );
  

  const handleRetakeQuiz = () => {

    const shuffledQuizData = shuffleArray([...quizData]);

    setQuizCompleted(false);
    setQuizAlready(false)
    setCurrentQuestion(0);
    setSelectedOption(null);
    setScore(0);
  //  setQuizData(shuffledQuizData);
//   var totalGroups = library.data.length;
//   // Set the totalGroups state
//  setTotalGroups(totalGroups);
//  setCurrentGroup(1);
//   const groupData = library.data;

//        // Set quizData for Group 1 initially
//        setQuizData(groupData[0].quiz_questions);
//        setquestionsPerGroup(groupData[0].quiz_questions.length); // Add this line to set questionsPerGroup
//        setGroupText(groupData[0].text);
//        // Count total questions for both groups
// const totalQuestions = groupData.reduce((total, group) => total + group.quiz_questions.length, 0);

// setTotalQuestions(totalQuestions);
// // Concatenate all quiz_questions arrays into a single array
// const allQuestions = [].concat(...groupData.map(group => group.quiz_questions));

// setAllQuestions(allQuestions);
// const audioSrc = (library && library.data) ? library.data[0].text_audio : '';
// // audio.src = audioSrc;
// // audio.load();
// // audio.play();
// setAudio(audioSrc);
  };
  const viewText = () => {
    setViewText(true);
  }
  const handleTimeExpired =  () => {
    setQuizCompleted(true);
  };
  console.log(audioSrc,'get audio src');
  const renderQuizContent = () => {
    if (currentQuestion < quizData.length && quizCompleted === false ) {
      const totalTime = 10;
      const remainingPercentage = (timeRemaining / totalTime) * 100;
      const precent = (100 - ((currentQuestion - score) / quizData.length * 100)).toFixed(2);

      return (
      //   <div className="stopwatch-container">
      //   <h2>{quizCategory}</h2>
      //   <Dots totalGroups={totalGroups} currentGroup={currentGroup} currentQuestion={currentQuestion} questionsPerGroup={questionsPerGroup} />
      //   <div className="row">
      //     <div className="col-md-10">
      //       <div className="question-box">
      //         {/* <p>{readingPassage}</p> */}
      //         <audio controls autoPlay>
      //             <source src={audioSrc} type="audio/mp3" />
      //             Your browser does not support the audio element.
      //           </audio> 
      //         <h6>{quizData[currentQuestion].question}</h6>
             
      //         <div className="question-area">
      //         <div class="quiz-question-area-part-one">
      //         {(passage && passage.data) ? 
      //         <audio controls autoPlay>
      //             <source src={audioSrc} type="audio/mp3" />
      //             Your browser does not support the audio element.
      //           </audio> 
    
      //           : ''}
              
      //       </div>
      //       <div class="quiz-question-area-part-two">
      //       {renderOptions()}
      //       </div>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="col-md-2">
      //       <div className="stopwatch-box">
      //         <h6 className="g-bg">Questions Answered</h6>
      //         <p className="result">{score} / {totalQuestions}</p>
      //         <h6 className="b-bg">Stopwatch</h6>
      //         <div className="watch">
      //           <p className="hr">
      //             <span>00</span>
      //             <span className="title">HR</span>
      //           </p>
      //           <p className="min">
      //             <span>{formattedMinutes}</span>
      //             <span className="title">MIN</span>
      //           </p>
      //           <p className="sec">
      //             <span>{formattedSeconds}</span>
      //             <span className="title">SEC</span>
      //           </p>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div class="quizs-container" > 
        <div className="quiz-content">
          <div className="quiz-header">
            <div className="category-head">
              <h4>{quizCategory}</h4>
              <Dots totalGroups={totalGroups} currentGroup={currentGroup} currentQuestion={currentQuestion} questionsPerGroup={questionsPerGroup} />
            </div>

            {/*{location.state.vocbsetting.istime === 1 && (
              <ProgressBar stripped style={{
                width: "100%",
                height: "1.3rem",
                fontSize: "0.95rem"
              }}
                animated now={remainingPercentage} label={`${timeRemaining} seconds`} />
            )}*/}
              </div>
            <div class="quiz-body">
            <div className="quiz-progress">
              <p>Question {currentQuestion + 1} of {quizData.length}</p>
              {/* <p>Percentage: {(currentQuestion === 0) ? 100 : ((score /quizData.length ) * 100).toFixed(2)}%</p> */}
              <p>Percentage: {precent} %</p>


            </div>
          
          <div className="quiz-question-area">
          <div class="quiz-question-area-part-one">
           
          {(student.user.teacher_id && !viewText1) ?  <a href="javascript:void(0)" onClick={viewText}>View Text</a> : ''}
             {(viewText1) ? <p style={{ fontSize: '16px'}}>{ groupText}</p> : ''}
              {(passage && passage.data) ? 
              <>
             
              <audio key={audioSrc}  controls autoPlay>
                  <source src={audioSrc} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio> 
                </>
                : ''}
              
            </div>
            <div class="quiz-question-area-part-two">
            {renderOptions()}
            </div>
          
          </div>
        </div>
      </div>
        </div>
      );
    }
     else {
      const percentage = ((score / totalQuestions) * 100);
   const remaining=100-percentage;
 const data = [{ name: 'Your score', score: percentage, total: 100  }];
      return (

        <div>
          <h2>Quiz Result!</h2>


          {/*<BarChart
            xAxis={[{ scaleType: 'band', data: ['Your score'] }]}
            series={[{ data: [percentage], color: "#3a58e9b8", stack: 'total' }, { data: [100 - percentage], color: "#992525", stack: 'total' }]}
            width={500}
            height={300}

          />*/} 

        <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
  <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart>

           <span className='score'>
    <div className="score-wrap">
        <span className="stars-active" style={{ width:`${percentage}%`}}>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
<span className="stars-inactive">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
</div>
</span>
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {totalQuestions}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Question</th>
                  <th></th>
                  <th>O X</th>
                </tr>
              </thead>
              <tbody>
                {(resultData && resultData.data) ? resultData.data.data.questions.map((question, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{question.question}</td>
                    <td>
                        {question.combined_result.map((option, index) => (
                          <div key={index} className='quiz-options'>
                            <label className='option-index'>{index + 1}</label>
                            <label className="container" htmlFor={option}>
                              {ReactHtmlParser(option)}
                              <input
                                type="radio"
                                id={option}
                                name="options"
                                value={option}
                                checked={selectedOption === option}
                                onChange={() => handleOptionSelect(option)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        ))}
                    </td>
                    <th>{((question.answer == userAnswers[index]) || userAnswers[index] == "Not answered") ? 'O' : 'X'}</th>
                  </tr>
                )) : ''}
              </tbody>
            </Table>
          </div>
          <a href={`/study/view-test-details/${test_id}`} className='quiz-button'>Back To Test</a>
          
        </div>
      );
    }
  };



  return (
    <div className="quiz-container">
      <div className='quizs-container'> {renderQuizContent()} </div>
    </div>
  );
}
const Dots = ({ totalGroups, currentGroup,currentQuestion, questionsPerGroup }) => {
  const dots = [];

  for (let group = 1; group <= totalGroups; group++) {
    for (let question = 0; question < questionsPerGroup; question++) {
      const isCurrentQuestion = group === currentGroup && question === currentQuestion;
     // console.log(isCurrentQuestion,'isCurrentQuestion',currentQuestion,group,currentGroup,question);
      dots.push(
        <div
          key={`${group}-${question}`}
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: isCurrentQuestion ? '#007bff' :  '#ccc',
            margin: '0 5px',
          }}
        />
      );
    }

    // Add space between groups
    if (group < totalGroups) {
      dots.push(<div key={`space-${group}`} style={{ width: '20px', margin: '0' }} />);
    }

  }
  //console.log('total grop',totalGroups, questionsPerGroup);
  return (
    <div style={{ display: 'flex', justifyContent: 'center',marginBottom: '20px' }}>
      {dots}
    </div>
  );
};
