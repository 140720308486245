import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { studyActions } from '_store';

import { history } from '_helpers';


export { StudyTest };

function StudyTest() {
  const dispatch = useDispatch();
  const { passage } = useSelector((x) => x.passage);
  const [showTestDialog, setShowTestDialog] = useState(false);
  const [testText, setTestText] = useState('');
  const [testLevelText, setTestLevel] = useState(1);
  const { user: authUser } = useSelector((x) => x.auth);
  const [selectedTest, setSelectedTest] = useState(null);

console.log(authUser,'get class', passage);
  useEffect(() => {
    dispatch(studyActions.studyTestList());

 }, []);

 const addNewTest = () => {
  
  setShowTestDialog(true);
};
const handleTestCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowTestDialog(false);
};
const handleTestTextChange = (e) => {
  setTestText(e.target.value);
};

const saveTest = async () => {
  if (selectedTest) {
    // Update existing test
    const { id } = selectedTest;
    const studytest_id = id;
    const test_title = testText;
    const level = testLevelText;
    const teacher_id = authUser.user.teacher_id;
  return dispatch(studyActions.editStudyTest({ studytest_id, test_title, level, teacher_id }));
  } else{
  var test_title = testText;
  var level = testLevelText;
  var teacher_id = authUser.user.teacher_id;
  console.log(test_title,level,teacher_id,'get all values here');
  return dispatch(studyActions.createStudyTest({test_title,level,teacher_id}));
  }
};

const handleTestLevelChange = (e) => {
  setTestLevel(e.target.value);
};
const deleteStudyTest = async (id) => {
  const confirmed = window.confirm('Are you sure you want to delete this item?');

  if (confirmed) {
    var studytest_id = id;
    var res = dispatch(studyActions.deleteStudyTest({studytest_id}));
    if(res){
      window.location.reload();
    }
  }
};

const editTestClick = (id) => {
  // Find the study test with the specified id from the passage data
  if(passage && passage.data){
    const selectedStudyTest = passage.data.find(test => test.id === id);
    if (selectedStudyTest) {
      // Set the values for editing
      setTestText(selectedStudyTest.test_title);
      setTestLevel(selectedStudyTest.level);
      setSelectedTest(selectedStudyTest);
      setShowTestDialog(true);
    }
  }

};


    return (

      <section className="custom-sign">
      <div className="container-fluid">
      <div className="card-header">
			<p className="header-title">Study Tests</p>
      <button type="button" className="btn btn-success" onClick={()=>addNewTest()}>Add New Test</button>
		  </div>
          <div className='card-body'>
          <table className="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>S No.</th>
                        {/* <th>Schedule</th> */}
                    
                        <th>Test Title</th>
                        <th>Level</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {(passage && passage.data) ? passage.data.map((pass, index) => (
                      <tr>
                        <td contentEditable="false">{index + 1}</td>
                       
                        <td contentEditable="false">{pass.test_title}</td>
                        
                        {/* <td row="2" contentEditable="false">{classN.schedule}</td> */}
                        <td contentEditable="false">{pass.level}</td>
                         <td>
                          <span className="table-remove">
                          <td><a href="javascript:void(0)" onClick={() => editTestClick(pass.id)}>Edit</a></td>
                            <td><a href="javascript:void(0)" onClick={() => deleteStudyTest(pass.id)}>Delete</a></td>
                            <td><a href={`/study/assign-questions/${pass.id}/1/Test`}>Add/Edit Questions</a></td>
                            <td><a href={`/classroom/assign-study-test/${pass.id}`}>Assign Test</a> </td>
                            <td><a href={`/study/view-test-details/${pass.id}`}>Run the Test</a> </td>
                            </span>
                        </td>
                      </tr>
                      )) : <h5>No Records Found.</h5>}
                    </tbody>
                  </table>
                  <Modal show={showTestDialog} onHide={handleTestCancel}>
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={(e)=>handleSubmit(e,type)}>

      <div>
      <label>Test Title</label>
        <input className="form-control" type="text" value={testText} onChange={handleTestTextChange} placeholder="Test Title" name="search"/>

      </div>

      <div>
        <label>Level</label>
        <select className="form-control" id="selLevel" name="selLevel" value={testLevelText} onChange={handleTestLevelChange}>
                        <option value="1">Level 1</option>
                        <option value="2">Level 2</option>
                        <option value="3">Level 3</option>
                        <option value="4">Level 4</option>
                        <option value="5">Level 5</option>
                        <option value="6">Level 6</option>
                        <option value="7">Level 7</option>
                        <option value="8">Level 8</option>
                        <option value="9">Level 9</option>
                    </select>
      </div>

      <button type="submit" onClick={()=>saveTest()}>Save</button>
    </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleTestCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
              </Modal>
          </div>

      </div>
    </section>
      
    )
}
