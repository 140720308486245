import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fileActions, singlefileActions, audioActions } from "_store";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import axios from "axios";
export { SpeakingGrade };

function SpeakingGrade() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [result, setResult] = useState("");
  //const { teacherResult } = useSelector((x) => x.teacherResult);
  const { user: authUser } = useSelector((x) => x.auth);
  const [selectedOption, setSelectedOption] = useState("");
  const { files } = useSelector((x) => x.files);
  const { file } = useSelector((x) => x.file);
  const { audio } = useSelector((x) => x.audio);
  const [audioQuality, setAudioQuality] = useState("");
  const audioRef = useRef(null);
  const [textValue, setTextValue] = useState("");
  const [fileInput, setFile] = useState(null);
  const [myArray, setMyArray] = useState([]);
  const [teacherResult, setTeacherResult] = useState(null);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const [activeColumns, setActiveColumns] = useState(Array(6).fill(null)); // Assuming 3 groups with 2 columns each

  const numberOfRows = 10;
 

  const isActive = (rowIndex, cellIndex) => {
    // Calculate the group and column indices based on rowIndex and cellIndex
    const groupIndex = Math.floor(rowIndex / 2);
    const columnIndex = cellIndex;

    // Check if the current column is the same as the active column for the group
    return columnIndex === activeColumns[groupIndex];
  };

  // Create a 6x2 grid dynamically
  const rows = Array(6).fill(null);
  const cols = Array(2).fill(null);

  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleSave = async () => {
    // Implement your save logic here
    // You can use 'textValue' and 'file' to save the data
    if (myArray.length > 0) {
      setError(false);
      const formData = new FormData();
      formData.append("result_array", JSON.stringify(myArray));
      formData.append("comment", textValue);
      formData.append("recordId", id);
      formData.append("file", fileInput);
      const response = await axios.post(
        "https://api.webtracktechnologies.com.au:8002/audio/teacher-result",
        formData
      );
      setTeacherResult(response.data.data);
    } else {
      setError(true);
    }
  };
  // Use the useParams hook to get the route parameters

  console.log(file, "get file");
  const handleSearch = () => {
    // You can perform your search logic here
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
    console.log("Input 1:", input1);
    console.log("Input 2:", input2);
    console.log("Selected Option:", selectedOption);
  };
  const findCorrespondingTdInNextRow = (clickedTd) => {
    // Get the index of the clicked td within its parent tr
    const index = Array.from(clickedTd.parentElement.children).indexOf(clickedTd);
  
    // Find the parent tr and then find its next sibling
    const nextTr = clickedTd.parentElement.nextElementSibling;
  
    if (nextTr) {
      // Find the td element in the next tr at the same index
      const correspondingTd = nextTr.children[index];
      return correspondingTd;
    }
  
    return null; // If there is no next tr
  };
  const handleClick = (newValue, event, rowIndex, cellIndex) => {
   // Calculate the group and column indices based on rowIndex and cellIndex
   const groupIndex = Math.floor(rowIndex / 2);
   const columnIndex = cellIndex;

   // Clone the activeColumns array and update the active column for the clicked group
   const newActiveColumns = [...activeColumns];
   newActiveColumns[groupIndex] = columnIndex;

   setActiveColumns(newActiveColumns);
    setMyArray((prevValues) => {
     
      const [variable, value] = newValue.split(":");
      const updatedValues = prevValues.map((existingValue) => {
        const [existingVariable] = existingValue.split(":");
        return existingVariable === variable ? newValue : existingValue;
      });
      if (
        !updatedValues.some((existingValue) =>
          existingValue.startsWith(variable)
        )
      ) {
        return [...prevValues, newValue];
      }
      return updatedValues;
    });
    // let table = document.getElementById("scoreTable"); // Replace 'your-table-id' with the actual ID of your table
    // let rows = table.getElementsByTagName("tr");

    // for (let i = 0; i < rows.length; i++) {
    //   let cells = rows[i].getElementsByTagName("td");
    //   let rowspanValue = cells[columnIndex].getAttribute("rowspan");

    //   if (rowspanValue && parseInt(rowspanValue) === rowspan) {
    //     cells[columnIndex].classList.add("active"); // Add a CSS class to highlight the cell
    //   }
    // }
    // const td = event.target;
    // const correspondingTd = findCorrespondingTdInNextRow(td);
    // console.log(correspondingTd,'g');
    // const parentRow = td.parentElement;

    // Remove 'active' class from all cells in the same row
    // const cellsInRow = parentRow.querySelectorAll('td');
    // cellsInRow.forEach(cell => {
    //   cell.classList.remove('active');
    // });
    // if (correspondingTd) {
    //   // Do something with the corresponding td
    //   correspondingTd.classList.remove('active');
    // }
    
    // console.log(td.next(),'get next');
    // td.classList.add('active');
  };

  useEffect(() => {
    dispatch(fileActions.getAudioFiles());
    dispatch(singlefileActions.getSingleFile({ id }));
  }, []);
  useEffect(() => {
    if (file && file.data) {
      try {
        var jsonResult = JSON.parse(file.data.result);
        var teacherjsonResult = JSON.parse(file.data.teacher_result);
        console.log(jsonResult, "json", teacherjsonResult);
        setResult(jsonResult);
        setTeacherResult(teacherjsonResult);
  
        // Safely access audioQuality information with optional chaining
        const audioQualityStatus = jsonResult?.message?.AAResult?.[0]?.audioQuality?.[0]?.$?.status;
        
        if (audioQualityStatus === "18") {
          setAudioQuality("soft and noisy");
        } else if (audioQualityStatus === "8") {
          setAudioQuality("soft");
        } else if (audioQualityStatus === "0") {
          setAudioQuality("good");
        } else if (audioQualityStatus === "10") {
          setAudioQuality("noisy");
        }
      } catch (error) {
        console.error("Error parsing file data or accessing nested properties:", error);
      }
    }
  }, [file]);
  
  const StringContainsWord = (text, word) => {
    const containsWord = text.toLowerCase().includes(word.toLowerCase());
    if (containsWord) {
      return true;
    } else {
      return false;
    }
  };

  const handleDownload = (fileUrl) => {
    const fileName = "audio_file.mp3"; // Replace with your desired file name

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  };

  const getContentAverageScore = (content) => {
    var value = 0;
    if (content <= 5) {
      value = 0;
    } else if (content >= 6 && content <= 10) {
      value = 0.7;
    } else if (content >= 11 && content <= 20) {
      value = 1.5;
    } else if (content >= 21 && content <= 40) {
      value = 2.5;
    } else if (content >= 41 && content <= 80) {
      value = 3.3;
    } else if (content >= 81 && content <= 100) {
      value = 3.7;
    } else if (content >= 101) {
      value = 4;
    }
    //setContent(value);
    return value;
  };

  const getFluencyAverageScore = (fluency) => {
    console.log(fluency, "get fluency");
    var value = 0;
    if (Math.round(fluency) <= 30) {
      value = 0;
    } else if (Math.round(fluency) >= 30 && Math.round(fluency) <= 50) {
      value = 1;
    } else if (Math.round(fluency) >= 51 && Math.round(fluency) <= 80) {
      value = 2;
    } else if (Math.round(fluency) >= 81 && Math.round(fluency) <= 120) {
      value = 3;
    } else if (Math.round(fluency) >= 121 && Math.round(fluency) <= 150) {
      value = 4;
    } else if (Math.round(fluency) >= 151 && Math.round(fluency) <= 180) {
      value = 4;
    } else if (Math.round(fluency) >= 180) {
      value = 4;
    }
    // setFluency(value);
    return value;
  };

  const getTotalAverageScore = (...values) => {
    let validVariables = values.filter((value) => !isNaN(value));
    console.log(validVariables, "length");
    if (validVariables.length === 0) {
      console.log("No valid variables");
      return null; // Handle the case where there are no valid variables
    } else {
      const avg =
        validVariables.reduce((sum, value) => sum + value, 0) /
        validVariables.length;

      return Math.round(avg);
    }
  };

  const getFluencyRemarks = (fluency) => {
    console.log(fluency, "get fluency");
    var text = "There is no recording or your recording is extremely short.";
    if (fluency == 0) {
      text = "There is no recording or your recording is extremely short.";
    } else if (fluency == 1) {
      text = "Your speech is slow.";
    } else if (fluency == 2) {
      text = "Your speech is relatively slow.";
    } else if (fluency == 3) {
      text = "Your speech is relatively fast.";
    } else if (fluency == 4) {
      text = "Your speech is fast.";
    }
    // setFluency(value);
    return text;
  };

  const getContentRemarks = (content) => {
    console.log(content, "get fluency");
    var text =
      "There is no recording or your content score is equal to or less than 20%.";
    if (content == 0) {
      text =
        "There is no recording or your content score is equal to or less than 20%.";
    } else if (content == 1) {
      text = "Your content score is 21-40%.";
    } else if (content == 2) {
      text = "Your content score is 41-60%.";
    } else if (content == 3) {
      text = "Your content score is 61-80%.";
    } else if (content == 4) {
      text = "Your content score is 81-100%.";
    }
    // setFluency(value);
    return text;
  };
  const getTotalRemarks = (total) => {
    console.log(total, "get fluency");
    var text = "Nothing is recorded or no effort taken at all.";
    if (total == 0) {
      text = "Nothing is recorded or no effort taken at all.";
    } else if (total >= 0 && total <= 1) {
      text =
        "Your speech is hardly recognizable. There are a lot of mistakes and errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with too many unnecessary pauses.";
    } else if (total >= 1 && total <= 2) {
      text =
        "Your speech is intelligible, but it requires much effort for one to understand. There are many noticeable mistakes and consistent errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with many unnecessary pauses.";
    } else if (total >= 2 && total <= 3) {
      text =
        "Though it may require some effort, your speech is intelligible, showing relatively high oral proficiency and skills. It contains some errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with some unnecessary pauses.";
    } else if (total >= 3 && total == 4) {
      text =
        "Your speech is native like. It is highly intelligible, showing high oral proficiency and skills. Your pronunciation, grammar, vocabulary use, fluency, and style are natural, accurate, and highly proficient.";
    }
    // setFluency(value);
    return text;
  };
  const getPGRemarks = (val) => {
    //  console.log(total, "get fluency");
    var text =
      "There is no recording or your accuracy score is equal to or less than 20%.";
    if (val == 0) {
      text =
        "There is no recording or your accuracy score is equal to or less than 20%.";
    } else if (val > 0 && val <= 1) {
      text = "Your accuracy score is 21-40%.";
    } else if (val > 1 && val <= 2) {
      text = "Your accuracy score is 41-60%.";
    } else if (val > 2 && val <= 3) {
      text = "Your accuracy score is 61-80%.";
    } else if (val > 3 && val == 4) {
      text = "Your accuracy score is 81-100%.";
    }
    // setFluency(value);
    return text;
  };
  return (
    <section className="custom-grade">
      <div className="container-fluid">
        <h2 className="heading">Like Study Grade List</h2>
        <h4 className="title">
          Title: {file && file.data ? file.data.topic_name : ""} Review
          Sentences
        </h4>
        <h4 className="title">
          Date:{" "}
          {file && file.data
            ? new Date(file.data.createdAt).toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : ""}{" "}
        </h4>

        <div className="row">
          <div className="col-md-6">
            <div className="audio">
              {file && file.data.audio ? (
                <>
                  <audio ref={audioRef} controls>
                    <source src={file.data.audio} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                </>
              ) : (
                ""
              )}
            </div>
            <div>
              <textarea
                value={textValue}
                onChange={handleTextChange}
                rows="4"
                cols="50"
                placeholder="Enter Comments here..."
              />
              <input type="file" onChange={handleFileChange} />
              {teacherResult && teacherResult.file ? (
                <div>
                  <button
                    type="button"
                    onClick={() => handleDownload(teacherResult.file)}
                  >
                    <i className="fa fa-download"></i>Download
                  </button>
                </div>
              ) : (
                ""
              )}

              <button onClick={handleSave}>Save</button>
            </div>
          </div>
          {error ? (
            <div className="col-md-12">
              <h6 style={{ color: "red" }}>Select Scores From the table.</h6>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-6">
            {result ? (
              <Table id="scoreTable" striped bordered hover>
                <tr>
                  <td>Scoring Criteria</td>
                  <td colspan="8">Socres & Results</td>
                </tr>
                <tr>
                  <td>Audio Quality</td>
                  <td
                    className={
                      (teacherResult &&
                        teacherResult.audio_quality == "Audio Quality Good") ||
                      (!teacherResult &&
                        StringContainsWord("Audio Quality Good", audioQuality))
                        ? "active"
                        : ""
                    }
                    onClick={(e) =>
                      handleClick("audio_quality:Audio Quality Good", e)
                    }
                  >
                    Audio Quality Good
                  </td>
                  <td
                    onClick={(e) =>
                      handleClick("audio_quality:No Recording", e)
                    }
                    className={
                      (teacherResult &&
                        teacherResult.audio_quality == "No Recording") ||
                      (!teacherResult &&
                        StringContainsWord("No Recording", audioQuality))
                        ? "active"
                        : ""
                    }
                  >
                    No Recording
                  </td>
                  <td
                    onClick={(e) => handleClick("audio_quality:Too Soft", e)}
                    className={
                      (teacherResult &&
                        teacherResult.audio_quality == "Too Soft") ||
                      (!teacherResult &&
                        StringContainsWord("Too Soft", audioQuality))
                        ? "active"
                        : ""
                    }
                  >
                    Too Soft
                  </td>
                  <td
                    onClick={(e) => handleClick("audio_quality:Too Loud", e)}
                    className={
                      (teacherResult &&
                        teacherResult.audio_quality == "Too Loud") ||
                      (!teacherResult &&
                        StringContainsWord("Too Loud", audioQuality))
                        ? "active"
                        : ""
                    }
                  >
                    Too Loud
                  </td>
                  <td
                    onClick={(e) => handleClick("audio_quality:Too Noisy", e)}
                    className={
                      (teacherResult &&
                        teacherResult.audio_quality == "Too Noisy") ||
                      (!teacherResult &&
                        StringContainsWord("Too Noisy", audioQuality))
                        ? "active"
                        : ""
                    }
                  >
                    Too Noisy
                  </td>
                  <td
                    onClick={(e) =>
                      handleClick("audio_quality:Beginning Cut-Off", e)
                    }
                    className={
                      teacherResult &&
                      teacherResult.audio_quality == "Beginning Cut-Off"
                        ? "active"
                        : ""
                    }
                  >
                    Beginning Cut-Off
                  </td>
                  <td
                    onClick={(e) => handleClick("audio_quality:End Cut-Off", e)}
                    className={
                      teacherResult &&
                      teacherResult.audio_quality == "End Cut-Off"
                        ? "active"
                        : ""
                    }
                  >
                    End Cut-Off
                  </td>
                </tr>
                <tr>
  <td rowspan="2"> Audio Recording (total recording time)</td>
  
  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:0:Extremely Short or No Response(<5sec)",
        e,
        0, 0
      )
    }
    className={`${
      isActive(0, 0) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) < 5) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Extremely Short or No Response(<5sec)")
        ? "active"
        : ""
    }`}
  >
    0
  </td>

  <td
    onClick={(e) =>
      handleClick("audio_recording:1:Very Short(<15sec)", e, 0, 1)
    }
    className={`${
      isActive(0, 1) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) ===
          15) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Very Short(<15sec)")
        ? "active"
        : ""
    }`}
  >
    1
  </td>

  <td
    onClick={(e) =>
      handleClick("audio_recording:2:Short(<25sec)", e, 0, 2)
    }
    className={`${
      isActive(0, 2) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) ===
          25) ||
      (teacherResult &&
        teacherResult.audio_recordingwords === "Short(<25sec)")
        ? "active"
        : ""
    }`}
  >
    2
  </td>

  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:3:Relatively Short(26<39sec)",
        e,
        0, 3
      )
    }
    className={`${
      isActive(0, 3) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) ===
          39) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Relatively Short(26<39sec)")
        ? "active"
        : ""
    }`}
  >
    3
  </td>

  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:4:Appropriate(40sec<x<60sec)",
        e,
        0, 4
      )
    }
    className={`${
      isActive(0, 4) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) >
          40 &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) <
          60) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Appropriate(40sec<x<60sec)")
        ? "active"
        : ""
    }`}
  >
    4
  </td>

  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:4:Relatively Long(1-1 min. 10 sec)",
        e,
        0, 5
      )
    }
    className={`${
      isActive(0, 5) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) ===
          60) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Relatively Long(1-1 min. 10 sec)")
        ? "active"
        : ""
    }`}
  >
    4
  </td>

  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:4:Long(over 1 min. 10 sec)",
        e,
        0, 6
      )
    }
    className={`${
      isActive(0, 6) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) >
          60) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Long(over 1 min. 10 sec)")
        ? "active"
        : ""
    }`}
  >
    4
  </td>
</tr>

<tr>
  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:0:Extremely Short or No Response(<5sec)",
        e,
        1, 0
      )
    }
    className={`${
      isActive(1, 0) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) < 5) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Extremely Short or No Response(<5sec)")
        ? "active"
        : ""
    }`}
  >
    Extremely Short or No Response(&lt;5sec)
  </td>

  <td
    onClick={(e) =>
      handleClick("audio_recording:1:Very Short(<15sec)", e, 1, 1)
    }
    className={`${
      isActive(1, 1) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) ===
          15) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Very Short(<15sec)")
        ? "active"
        : ""
    }`}
  >
    Very Short(&lt;15sec)
  </td>

  <td
    onClick={(e) =>
      handleClick("audio_recording:2:Short(<25sec)", e, 1, 2)
    }
    className={`${
      isActive(1, 2) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) ===
          25) ||
      (teacherResult &&
        teacherResult.audio_recordingwords === "Short(<25sec)")
        ? "active"
        : ""
    }`}
  >
    Short(&lt;25sec)
  </td>

  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:3:Relatively Short(26<39sec)",
        e,
        1, 3
      )
    }
    className={`${
      isActive(1, 3) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) ===
          39) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Relatively Short(26<39sec)")
        ? "active"
        : ""
    }`}
  >
    Relatively Short(26&lt;39sec)
  </td>

  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:4:Appropriate(40sec<x<60sec)",
        e,
        1, 4
      )
    }
    className={`${
      isActive(1, 4) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) >
          40 &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) < 60) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Appropriate(40sec<x<60sec)")
        ? "active"
        : ""
    }`}
  >
    Appropriate(40sec&lt;x&lt;60sec)
  </td>

  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:4:Relatively Long(1-1 min. 10 sec)",
        e,
        1, 5
      )
    }
    className={`${
      isActive(1, 5) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) ===
          60) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Relatively Long(1-1 min. 10 sec)")
        ? "active"
        : ""
    }`}
  >
    Relatively Long(1-1 min. 10 sec)
  </td>

  <td
    onClick={(e) =>
      handleClick(
        "audio_recording:4:Long(over 1 min. 10 sec)",
        e,
        1, 6
      )
    }
    className={`${
      isActive(1, 6) ? "active " : ""
    }${
      (!teacherResult &&
        parseInt(result?.message?.AAResult?.[0]?.audioLenSec?.[0] || 0) >
          60) ||
      (teacherResult &&
        teacherResult.audio_recordingwords ===
          "Long(over 1 min. 10 sec)")
        ? "active"
        : ""
    }`}
  >
    Long(over 1 min. 10 sec)
  </td>
</tr>

<tr>
  <td rowspan="2">Fluency</td>
  <td
    className={`${
      isActive(2, 0) ? "active " : ""
    }${
      (!teacherResult &&
        result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] < 30) ||
      (teacherResult && teacherResult.fluency === 0)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("fluency:0:Extremely Short or No Response", e, 2, 0)
    }
  >
    0
  </td>
  <td
    className={`${
      isActive(2, 1) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluency === 1) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 31 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 50)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("fluency:1:Too Slow", e, 2, 1)}
  >
    1
  </td>
  <td
    className={`${
      isActive(2, 2) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluency === 2) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 51 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 80)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("fluency:2:Too Slow", e, 2, 2)}
  >
    2
  </td>
  <td
    className={`${
      isActive(2, 3) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluency === 3) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 81 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 120)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("fluency:3:Relatively Slow(81-120wpm)", e, 2, 3)
    }
  >
    3
  </td>
  <td
    className={`${
      isActive(2, 4) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluency === 4) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 121 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 150)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("fluency:4:Appropriate(121-150wpm)", e, 2, 4)
    }
  >
    4
  </td>
  <td
    className={`${
      isActive(2, 5) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluency === 5) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 151 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 180)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("fluency:5:Relatively Fast(151-180wpm)", e, 2, 5)
    }
  >
    5
  </td>
  <td
    className={`${
      isActive(2, 6) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluency === 6) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 180)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("fluency:6:Fast(180wpm)", e, 2, 6)}
  >
    6
  </td>
</tr>

<tr>
  <td
    className={`${
      isActive(3, 0) ? "active " : ""
    }${
      (!teacherResult &&
        result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] < 30) ||
      (teacherResult && teacherResult.fluencywords === "Extremely Short or No Response")
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("fluency:0:Extremely Short or No Response", e, 3, 0)
    }
  >
    Extremely Short or No Response(&lt;30wpm)
  </td>
  <td
    className={`${
      isActive(3, 1) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluencywords === "Too Slow(31<50wpm)") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 31 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 50)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("fluency:1:Too Slow(31<50wpm)", e, 3, 1)}
  >
    Too Slow(31&lt;50wpm)
  </td>
  <td
    className={`${
      isActive(3, 2) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluencywords === "Too Slow(51<80wpm)") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 51 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 80)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("fluency:2:Too Slow(51<80wpm)", e, 3, 2)}
  >
    Too Slow(51&lt;80wpm)
  </td>
  <td
    className={`${
      isActive(3, 3) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluencywords === "Relatively Slow(81-120wpm)") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 81 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 120)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("fluency:3:Relatively Slow(81-120wpm)", e, 3, 3)
    }
  >
    Relatively Slow(81-120wpm)
  </td>
  <td
    className={`${
      isActive(3, 4) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluencywords === "Appropriate(121-150wpm)") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 121 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 150)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("fluency:4:Appropriate(121-150wpm)", e, 3, 4)
    }
  >
    Appropriate(121-150wpm)
  </td>
  <td
    className={`${
      isActive(3, 5) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluencywords === "Relatively Fast(151-180wpm)") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 151 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] <= 180)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("fluency:5:Relatively Fast(151-180wpm)", e, 3, 5)
    }
  >
    Relatively Fast(151-180wpm&lt;)
  </td>
  <td
    className={`${
      isActive(3, 6) ? "active " : ""
    }${
      (teacherResult && teacherResult.fluencywords === "Fast(180wpm)") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] >= 180)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("fluency:6:Fast(180wpm)", e, 3, 6)}
  >
    Fast(180wpm)
  </td>
</tr>

<tr>
  <td rowspan="2">Content</td>
  <td
    className={`${
      isActive(4, 0) ? "active " : ""
    }${
      (!teacherResult && result) ||
      (teacherResult && teacherResult.Content === 0) ||
      result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 5
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Content:0:5 Words or Less", e, 4, 0)}
  >
    0
  </td>
  <td
    className={`${
      isActive(4, 1) ? "active " : ""
    }${
      (teacherResult && teacherResult.Content === 0.7) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 6 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 10)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Content:0.7:6-10 Words", e, 4, 1)}
  >
    0.7
  </td>
  <td
    className={`${
      isActive(4, 2) ? "active " : ""
    }${
      (teacherResult && teacherResult.Content === 1.5) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 11 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 20)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Content:1.5:11-20 Words", e, 4, 2)}
  >
    1.5
  </td>
  <td
    className={`${
      isActive(4, 3) ? "active " : ""
    }${
      (teacherResult && teacherResult.Content === 2.5) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 21 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 40)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Content:2.5:21-40 Words", e, 4, 3)}
  >
    2.5
  </td>
  <td
    className={`${
      isActive(4, 4) ? "active " : ""
    }${
      (teacherResult && teacherResult.Content === 3.3) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 41 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 80)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Content:3.3:41-80 Words", e, 4, 4)}
  >
    3.3
  </td>
  <td
    className={`${
      isActive(4, 5) ? "active " : ""
    }${
      (teacherResult && teacherResult.Content === 3.7) ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 81 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 100)
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Content:3.7:81-100 Words", e, 4, 5)}
  >
    3.7
  </td>
  <td
    className={`${
      isActive(4, 6) ? "active " : ""
    }${
      (teacherResult && teacherResult.Content === 4) ||
      result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 101
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Content:4:101 Words or more", e, 4, 6)}
  >
    4
  </td>
</tr>

<tr>
  <td
    className={`${
      isActive(5, 0) ? "active " : ""
    }${
      (!teacherResult && result) ||
      (teacherResult && teacherResult.Contentwords === "5 Words or Less") ||
      result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 5
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Content:0:5 Words or Less", e, 5, 0)}
  >
    5 Words or Less
  </td>
  <td
    onClick={(e) => handleClick("Content:0.7:6-10 Words", e, 5, 1)}
    className={`${
      isActive(5, 1) ? "active " : ""
    }${
      (teacherResult && teacherResult.Contentwords === "6-10 Words") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 6 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 10)
        ? "active"
        : ""
    }`}
  >
    6-10 Words
  </td>
  <td
    onClick={(e) => handleClick("Content:1.5:11-20 Words", e, 5, 2)}
    className={`${
      isActive(5, 2) ? "active " : ""
    }${
      (teacherResult && teacherResult.Contentwords === "11-20 Words") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 11 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 20)
        ? "active"
        : ""
    }`}
  >
    11-20 Words
  </td>
  <td
    onClick={(e) => handleClick("Content:2.5:21-40 Words", e, 5, 3)}
    className={`${
      isActive(5, 3) ? "active " : ""
    }${
      (teacherResult && teacherResult.Contentwords === "21-40 Words") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 21 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 40)
        ? "active"
        : ""
    }`}
  >
    21-40 Words
  </td>
  <td
    onClick={(e) => handleClick("Content:3.3:41-80 Words", e, 5, 4)}
    className={`${
      isActive(5, 4) ? "active " : ""
    }${
      (teacherResult && teacherResult.Contentwords === "41-80 Words") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 41 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 80)
        ? "active"
        : ""
    }`}
  >
    41-80 Words
  </td>
  <td
    onClick={(e) => handleClick("Content:3.7:81-100 Words", e, 5, 5)}
    className={`${
      isActive(5, 5) ? "active " : ""
    }${
      (teacherResult && teacherResult.Contentwords === "81-100 Words") ||
      (result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 81 &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] <= 100)
        ? "active"
        : ""
    }`}
  >
    81-100 Words
  </td>
  <td
    onClick={(e) => handleClick("Content:4:101 Words or more", e, 5, 6)}
    className={`${
      isActive(5, 6) ? "active " : ""
    }${
      (teacherResult && teacherResult.Contentwords === "101 Words or more") ||
      result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 101
        ? "active"
        : ""
    }`}
  >
    101 Words or more
  </td>
</tr>

<tr>
  <td rowspan="2">Vocabulary</td>
  <td
    className={`${
      isActive(6, 0) ? "active " : ""
    }${
      (!teacherResult &&
        result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 0) ||
      (teacherResult &&
        teacherResult.Vocabularywords === "Nothing said or one word")
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Vocabulary:0:Nothing said or one word", e, 6, 0)
    }
  >
    0
  </td>
  <td
    className={`${
      isActive(6, 1) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 1") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 1)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Vocabulary:1:Vocab Level 1", e, 6, 1)
    }
  >
    1
  </td>
  <td
    className={`${
      isActive(6, 2) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 2") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 2)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Vocabulary:2:Vocab Level 2", e, 6, 2)
    }
  >
    2
  </td>
  <td
    className={`${
      isActive(6, 3) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 3") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 3)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Vocabulary:3:Vocab Level 3", e, 6, 3)
    }
  >
    3
  </td>
  <td
    className={`${
      isActive(6, 4) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 4") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 4)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Vocabulary:4:Vocab Level 4", e, 6, 4)
    }
  >
    4
  </td>
  <td
    className={`${
      isActive(6, 5) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 5") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 5)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Vocabulary:5:Vocab Level 5", e, 6, 5)
    }
  >
    5
  </td>
  <td
    className={`${
      isActive(6, 6) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 6 or higher") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 6)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Vocabulary:6:Vocab Level 6 or higher", e, 6, 6)
    }
  >
    6 or higher
  </td>
</tr>

<tr>
  <td
    onClick={(e) =>
      handleClick("Vocabulary:0:Nothing said or one word", e, 7, 0)
    }
    className={`${
      isActive(7, 0) ? "active " : ""
    }${
      (!teacherResult &&
        result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 0) ||
      (teacherResult &&
        teacherResult.Vocabularywords === "Nothing said or one word")
        ? "active"
        : ""
    }`}
  >
    Nothing said or one word
  </td>
  <td
    onClick={(e) =>
      handleClick("Vocabulary:1:Vocab Level 1", e, 7, 1)
    }
    className={`${
      isActive(7, 1) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 1") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 1)
        ? "active"
        : ""
    }`}
  >
    Vocab Level 1
  </td>
  <td
    onClick={(e) =>
      handleClick("Vocabulary:2:Vocab Level 2", e, 7, 2)
    }
    className={`${
      isActive(7, 2) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 2") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 2)
        ? "active"
        : ""
    }`}
  >
    Vocab Level 2
  </td>
  <td
    onClick={(e) =>
      handleClick("Vocabulary:3:Vocab Level 3", e, 7, 3)
    }
    className={`${
      isActive(7, 3) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 3") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 3)
        ? "active"
        : ""
    }`}
  >
    Vocab Level 3
  </td>
  <td
    onClick={(e) =>
      handleClick("Vocabulary:4:Vocab Level 4", e, 7, 4)
    }
    className={`${
      isActive(7, 4) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 4") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 4)
        ? "active"
        : ""
    }`}
  >
    Vocab Level 4
  </td>
  <td
    onClick={(e) =>
      handleClick("Vocabulary:5:Vocab Level 5", e, 7, 5)
    }
    className={`${
      isActive(7, 5) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 5") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] === 5)
        ? "active"
        : ""
    }`}
  >
    Vocab Level 5
  </td>
  <td
    onClick={(e) =>
      handleClick("Vocabulary:6:Vocab Level 6 or higher", e, 7, 6)
    }
    className={`${
      isActive(7, 6) ? "active " : ""
    }${
      (teacherResult && teacherResult.Vocabularywords === "Vocab Level 6 or higher") ||
      (result &&
        result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] >= 6)
        ? "active"
        : ""
    }`}
  >
    Vocab Level 6 or higher
  </td>
</tr>

<tr>
  <td rowspan="2">Pronunciation</td>
  <td
    className={`${
      isActive(8, 0) ? "active " : ""
    }${
      (!teacherResult && result) ||
      (teacherResult && teacherResult.Pronunciation === 0)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Pronunciation:0:No Recording or less than 20%", e, 8, 0)
    }
  >
    0
  </td>
  <td
    className={`${
      isActive(8, 1) ? "active " : ""
    }${
      teacherResult && teacherResult.Pronunciation === 0.7
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Pronunciation:0.7:21% or higher", e, 8, 1)
    }
  >
    0.7
  </td>
  <td
    className={`${
      isActive(8, 2) ? "active " : ""
    }${
      teacherResult && teacherResult.Pronunciation === 1.5
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Pronunciation:1.5:41% or higher", e, 8, 2)
    }
  >
    1.5
  </td>
  <td
    className={`${
      isActive(8, 3) ? "active " : ""
    }${
      teacherResult && teacherResult.Pronunciation === 2.5
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Pronunciation:2.5:61% or higher", e, 8, 3)
    }
  >
    2.5
  </td>
  <td
    className={`${
      isActive(8, 4) ? "active " : ""
    }${
      teacherResult && teacherResult.Pronunciation === 3.3
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Pronunciation:3.3:81% or higher", e, 8, 4)
    }
  >
    3.3
  </td>
  <td
    className={`${
      isActive(8, 5) ? "active " : ""
    }${
      teacherResult && teacherResult.Pronunciation === 3.7
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Pronunciation:3.7:91% or higher", e, 8, 5)
    }
  >
    3.7
  </td>
  <td
    className={`${
      isActive(8, 6) ? "active " : ""
    }${
      teacherResult && teacherResult.Pronunciation === 4
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Pronunciation:4:99% or higher", e, 8, 6)
    }
  >
    4
  </td>
</tr>

<tr>
  <td
    onClick={(e) =>
      handleClick("Pronunciation:0:No Recording or less than 20%", e, 9, 0)
    }
    className={`${
      isActive(9, 0) ? "active " : ""
    }${
      (!teacherResult && result) ||
      (teacherResult &&
        teacherResult.Pronunciationwords === "No Recording or less than 20%")
        ? "active"
        : ""
    }`}
  >
    No Recording or less than 20%
  </td>
  <td
    onClick={(e) =>
      handleClick("Pronunciation:0.7:21% or higher", e, 9, 1)
    }
    className={`${
      isActive(9, 1) ? "active " : ""
    }${
      teacherResult &&
      teacherResult.Pronunciationwords === "21% or higher"
        ? "active"
        : ""
    }`}
  >
    21% or higher
  </td>
  <td
    onClick={(e) =>
      handleClick("Pronunciation:1.5:41% or higher", e, 9, 2)
    }
    className={`${
      isActive(9, 2) ? "active " : ""
    }${
      teacherResult &&
      teacherResult.Pronunciationwords === "41% or higher"
        ? "active"
        : ""
    }`}
  >
    41% or higher
  </td>
  <td
    onClick={(e) =>
      handleClick("Pronunciation:2.5:61% or higher", e, 9, 3)
    }
    className={`${
      isActive(9, 3) ? "active " : ""
    }${
      teacherResult &&
      teacherResult.Pronunciationwords === "61% or higher"
        ? "active"
        : ""
    }`}
  >
    61% or higher
  </td>
  <td
    onClick={(e) =>
      handleClick("Pronunciation:3.3:81% or higher", e, 9, 4)
    }
    className={`${
      isActive(9, 4) ? "active " : ""
    }${
      teacherResult &&
      teacherResult.Pronunciationwords === "81% or higher"
        ? "active"
        : ""
    }`}
  >
    81% or higher
  </td>
  <td
    onClick={(e) =>
      handleClick("Pronunciation:3.7:91% or higher", e, 9, 5)
    }
    className={`${
      isActive(9, 5) ? "active " : ""
    }${
      teacherResult &&
      teacherResult.Pronunciationwords === "91% or higher"
        ? "active"
        : ""
    }`}
  >
    91% or higher
  </td>
  <td
    onClick={(e) =>
      handleClick("Pronunciation:4:99% or higher", e, 9, 6)
    }
    className={`${
      isActive(9, 6) ? "active " : ""
    }${
      teacherResult &&
      teacherResult.Pronunciationwords === "99% or higher"
        ? "active"
        : ""
    }`}
  >
    99% or higher
  </td>
</tr>

<tr>
  <td rowspan="2">Grammar</td>
  <td
    className={`${
      isActive(10, 0) ? "active " : ""
    }${
      (!teacherResult && result) ||
      (teacherResult && teacherResult.Grammar === 0)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Grammar:0:No Recording or less than 20%", e, 10, 0)
    }
  >
    0
  </td>
  <td
    className={`${
      isActive(10, 1) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammar === 0.7
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Grammar:0.7:21% or higher", e, 10, 1)}
  >
    0.7
  </td>
  <td
    className={`${
      isActive(10, 2) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammar === 1.5
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Grammar:1.5:41% or higher", e, 10, 2)}
  >
    1.5
  </td>
  <td
    className={`${
      isActive(10, 3) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammar === 2.5
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Grammar:2.5:61% or higher", e, 10, 3)}
  >
    2.5
  </td>
  <td
    className={`${
      isActive(10, 4) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammar === 3.3
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Grammar:3.3:81% or higher", e, 10, 4)}
  >
    3.3
  </td>
  <td
    className={`${
      isActive(10, 5) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammar === 3.7
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Grammar:3.7:91% or higher", e, 10, 5)}
  >
    3.7
  </td>
  <td
    className={`${
      isActive(10, 6) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammar === 4
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Grammar:4:99% or higher", e, 10, 6)}
  >
    4
  </td>
</tr>

<tr>
  <td
    onClick={(e) =>
      handleClick("Grammar:0:No Recording or less than 20%", e, 11, 0)
    }
    className={`${
      isActive(11, 0) ? "active " : ""
    }${
      (!teacherResult && result) ||
      (teacherResult && teacherResult.Grammarwords === "No Recording or less than 20%")
        ? "active"
        : ""
    }`}
  >
    No Recording or less than 20%
  </td>
  <td
    onClick={(e) => handleClick("Grammar:0.7:21% or higher", e, 11, 1)}
    className={`${
      isActive(11, 1) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammarwords === "21% or higher"
        ? "active"
        : ""
    }`}
  >
    21% or higher
  </td>
  <td
    onClick={(e) => handleClick("Grammar:1.5:41% or higher", e, 11, 2)}
    className={`${
      isActive(11, 2) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammarwords === "41% or higher"
        ? "active"
        : ""
    }`}
  >
    41% or higher
  </td>
  <td
    onClick={(e) => handleClick("Grammar:2.5:61% or higher", e, 11, 3)}
    className={`${
      isActive(11, 3) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammarwords === "61% or higher"
        ? "active"
        : ""
    }`}
  >
    61% or higher
  </td>
  <td
    onClick={(e) => handleClick("Grammar:3.3:81% or higher", e, 11, 4)}
    className={`${
      isActive(11, 4) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammarwords === "81% or higher"
        ? "active"
        : ""
    }`}
  >
    81% or higher
  </td>
  <td
    onClick={(e) => handleClick("Grammar:3.7:91% or higher", e, 11, 5)}
    className={`${
      isActive(11, 5) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammarwords === "91% or higher"
        ? "active"
        : ""
    }`}
  >
    91% or higher
  </td>
  <td
    onClick={(e) => handleClick("Grammar:4:99% or higher", e, 11, 6)}
    className={`${
      isActive(11, 6) ? "active " : ""
    }${
      teacherResult && teacherResult.Grammarwords === "99% or higher"
        ? "active"
        : ""
    }`}
  >
    99% or higher
  </td>
</tr>

<tr>
  <td rowspan="2">Style</td>
  <td
    className={`${
      isActive(12, 0) ? "active " : ""
    }${
      (!teacherResult && result) ||
      (teacherResult && teacherResult.Style === 0)
        ? "active"
        : ""
    }`}
    onClick={(e) =>
      handleClick("Style:0:No Recording or less than 20%", e, 12, 0)
    }
  >
    0
  </td>
  <td
    className={`${
      isActive(12, 1) ? "active " : ""
    }${
      teacherResult && teacherResult.Style === 0.7
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Style:0.7:21% or higher", e, 12, 1)}
  >
    0.7
  </td>
  <td
    className={`${
      isActive(12, 2) ? "active " : ""
    }${
      teacherResult && teacherResult.Style === 1.5
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Style:1.5:41% or higher", e, 12, 2)}
  >
    1.5
  </td>
  <td
    className={`${
      isActive(12, 3) ? "active " : ""
    }${
      teacherResult && teacherResult.Style === 2.5
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Style:2.5:61% or higher", e, 12, 3)}
  >
    2.5
  </td>
  <td
    className={`${
      isActive(12, 4) ? "active " : ""
    }${
      teacherResult && teacherResult.Style === 3.3
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Style:3.3:81% or higher", e, 12, 4)}
  >
    3.3
  </td>
  <td
    className={`${
      isActive(12, 5) ? "active " : ""
    }${
      teacherResult && teacherResult.Style === 3.7
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Style:3.7:91% or higher", e, 12, 5)}
  >
    3.7
  </td>
  <td
    className={`${
      isActive(12, 6) ? "active " : ""
    }${
      teacherResult && teacherResult.Style === 4
        ? "active"
        : ""
    }`}
    onClick={(e) => handleClick("Style:4:99% or higher", e, 12, 6)}
  >
    4
  </td>
</tr>

<tr>
  <td
    onClick={(e) =>
      handleClick("Style:0:No Recording or less than 20%", e, 13, 0)
    }
    className={`${
      isActive(13, 0) ? "active " : ""
    }${
      (!teacherResult && result) ||
      (teacherResult && teacherResult.Stylewords === "No Recording or less than 20%")
        ? "active"
        : ""
    }`}
  >
    No Recording or less than 20%
  </td>
  <td
    onClick={(e) => handleClick("Style:0.7:21% or higher", e, 13, 1)}
    className={`${
      isActive(13, 1) ? "active " : ""
    }${
      teacherResult && teacherResult.Stylewords === "21% or higher"
        ? "active"
        : ""
    }`}
  >
    21% or higher
  </td>
  <td
    onClick={(e) => handleClick("Style:1.5:41% or higher", e, 13, 2)}
    className={`${
      isActive(13, 2) ? "active " : ""
    }${
      teacherResult && teacherResult.Stylewords === "41% or higher"
        ? "active"
        : ""
    }`}
  >
    41% or higher
  </td>
  <td
    onClick={(e) => handleClick("Style:2.5:61% or higher", e, 13, 3)}
    className={`${
      isActive(13, 3) ? "active " : ""
    }${
      teacherResult && teacherResult.Stylewords === "61% or higher"
        ? "active"
        : ""
    }`}
  >
    61% or higher
  </td>
  <td
    onClick={(e) => handleClick("Style:3.3:81% or higher", e, 13, 4)}
    className={`${
      isActive(13, 4) ? "active " : ""
    }${
      teacherResult && teacherResult.Stylewords === "81% or higher"
        ? "active"
        : ""
    }`}
  >
    81% or higher
  </td>
  <td
    onClick={(e) => handleClick("Style:3.7:91% or higher", e, 13, 5)}
    className={`${
      isActive(13, 5) ? "active " : ""
    }${
      teacherResult && teacherResult.Stylewords === "91% or higher"
        ? "active"
        : ""
    }`}
  >
    91% or higher
  </td>
  <td
    onClick={(e) => handleClick("Style:4:99% or higher", e, 13, 6)}
    className={`${
      isActive(13, 6) ? "active " : ""
    }${
      teacherResult && teacherResult.Stylewords === "99% or higher"
        ? "active"
        : ""
    }`}
  >
    99% or higher
  </td>
</tr>

              </Table>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {result || teacherResult ? (
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th colSpan={5} style={{ textAlign: "center" }}>
                      LIKE Recorder Free Response Score Report
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{authUser.user.name}</td>
                    <td>
  {(() => {
    // Safely access nested properties
    const nWord = result.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0];
    const wpm = result.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0];

    // Check if nWord and wpm are defined before calculating scores
    if (nWord === undefined || wpm === undefined) {
      return <h4 style={{ color: "red" }}>Data not available</h4>; // handle the absence of data
    }

    const totalAverageScore = getTotalAverageScore(
      getContentAverageScore(nWord),
      getFluencyAverageScore(wpm),
      teacherResult?.Pronunciation,
      teacherResult?.Vocabulary,
      teacherResult?.Grammar,
      teacherResult?.Style
    );

    const passingScore = totalAverageScore >= 3;
    const resultText = passingScore ? "Pass" : "Fail";
    const resultColor = passingScore ? "green" : "red";

    return <h4 style={{ color: resultColor }}>{resultText}</h4>;
  })()}
</td>


                  </tr>
                  <tr>
                    <td>Test Date</td>
                    <td>{new Date().toDateString()}</td>
                  </tr>
                  <tr>
                    <td>Audio Quality</td>

                    <td>
  Audio Quality is {teacherResult?.audio_quality || audioQuality}
</td>

                  </tr>
                  <tr>
  <td>Total Recording Time</td>
  <td>
    {`0.${parseInt(result.message?.AAResult?.[0]?.audioLenSec?.[0] || 0, 10)} Seconds`}
  </td>
</tr>

<tr>
  <td>Total Speaking Time</td>
  <td>
    {`0.${parseInt(result.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.spkTimeSec?.[0] || 0, 10)} Seconds`}
  </td>
</tr>


                  <tr>
                    <td></td>
                    <td>Scores</td>
                    <td colSpan={5}>Comments</td>
                  </tr>
                  <tr>
  <td>Content</td>
  <td>
    {(() => {
      const contentScore = teacherResult?.Content
        ? getContentAverageScore(teacherResult.Content)
        : getContentAverageScore(
            result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] || 0
          );
      return `${contentScore}/4`;
    })()}
  </td>
  <td colSpan={5}>
    {(() => {
      const contentScore = teacherResult?.Content
        ? getContentAverageScore(teacherResult.Content)
        : getContentAverageScore(
            result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] || 0
          );
      return getContentRemarks(contentScore);
    })()}
  </td>
</tr>

<tr>
  <td>Fluency</td>
  <td>
    {(() => {
      const fluencyScore = teacherResult && teacherResult.fluency
        ? getFluencyAverageScore(teacherResult.fluency)
        : getFluencyAverageScore(
            result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]
          );
      return `${fluencyScore}/4`;
    })()}
  </td>
  <td colSpan={5}>
    {(() => {
      const fluencyScore = teacherResult && teacherResult.fluency
        ? getFluencyAverageScore(teacherResult.fluency)
        : getFluencyAverageScore(
            result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]
          );
      return getFluencyRemarks(fluencyScore);
    })()}
  </td>
</tr>

                  {teacherResult ? (
                    <>
                      <tr>
                        <td>Pronunciation</td>
                        <td>
                          {teacherResult && teacherResult.Pronunciation
                            ? teacherResult.Pronunciation
                            : "0"}
                          /4
                        </td>
                        <td colSpan={5}>
                          {teacherResult && teacherResult.Pronunciation
                            ? getPGRemarks(teacherResult.Pronunciation)
                            : "0%"}
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                  {teacherResult ? (
                    <>
                      <tr>
                        <td>Vocabulary Use</td>
                        <td>
                          {teacherResult && teacherResult.Vocabulary
                            ? teacherResult.Vocabulary
                            : "0.00"}
                          /4
                        </td>
                        <td colSpan={5}>
                          The level of vocabulary used in your speech is
                          advanced{" "}
                          {teacherResult && teacherResult.Vocabularywords
                            ? teacherResult.Vocabularywords
                            : ""}
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                  {teacherResult ? (
                    <>
                      <tr>
                        <td>Grammer</td>
                        <td>
                          {teacherResult && teacherResult.Grammar
                            ? teacherResult.Grammar
                            : "0"}
                          /4
                        </td>
                        <td colSpan={5}>
                          {teacherResult && teacherResult.Grammar
                            ? getPGRemarks(teacherResult.Grammar)
                            : "0%"}
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                  {teacherResult ? (
                    <>
                      <tr>
                        <td>Style</td>
                        <td>
                          {teacherResult && teacherResult.Style
                            ? teacherResult.Style
                            : "0.00"}
                          /4
                        </td>
                        <td colSpan={5}>
                          {teacherResult && teacherResult.Stylewords
                            ? teacherResult.Stylewords
                            : ""}
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                  <tr>
  <td>Total</td>
  <td>
    {(() => {
      const contentScore = teacherResult
        ? getContentAverageScore(teacherResult.Content)
        : getContentAverageScore(
            result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] || 0
          );

      const fluencyScore = teacherResult
        ? getFluencyAverageScore(teacherResult.fluency)
        : getFluencyAverageScore(
            result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] || 0
          );

      const totalAverageScore = teacherResult
        ? getTotalAverageScore(contentScore, fluencyScore, teacherResult.Pronunciation, teacherResult.Vocabulary, teacherResult.Grammar, teacherResult.Style)
        : getTotalAverageScore(contentScore, fluencyScore);

      return `${totalAverageScore}/4`;
    })()}
  </td>
  <td colSpan={5}>
    {(() => {
      const contentScore = teacherResult
        ? getContentAverageScore(teacherResult.Content)
        : getContentAverageScore(
            result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.nWord?.[0] || 0
          );

      const fluencyScore = teacherResult
        ? getFluencyAverageScore(teacherResult.fluency)
        : getFluencyAverageScore(
            result?.message?.AAResult?.[0]?.analysis?.[0]?.speakingSummary?.[0]?.wpm?.[0] || 0
          );

      return getTotalRemarks(contentScore, fluencyScore);
    })()}
  </td>
</tr>

                </tbody>
              </Table>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
