import React, { useEffect, useState, useRef } from 'react';
import { gsap, TweenMax } from 'gsap';
import { DrawSVGPlugin } from 'gsap/all';
import Winwheel from 'winwheel';
import confetti from 'canvas-confetti';
import { useTimer } from 'react-timer-hook';
import { useSelector, useDispatch } from 'react-redux';
import { classesActions, gamesActions } from '_store';
import { useParams } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import  ConfettiNew from './ConfettiNew';
gsap.registerPlugin(DrawSVGPlugin);

// gsap.to("#canvas", {
//   drawSVGPlugin: "#canvas", // start the animation when .element scrolls into view
//   x: 100,
//   duration: 2
// });
window.TweenMax = TweenMax;
console.log(DrawSVGPlugin,'get gsap');
const students = [
  { firstName: "Changgyun", lastName: "Kim", englishName: "Charlie" },
  { firstName: "Jueun", lastName: "Kim", englishName: "Carole" },
  { firstName: "Jua", lastName: "Lim", englishName: "Khatia" },
  { firstName: "Jihyo", lastName: "Kim", englishName: "Elena" },
  { firstName: "Haeun", lastName: "Nam", englishName: "Olivia" }
];

const colors = ['#ee1c24', '#3cb878', '#f6989d', '#f26522', '#fff200', '#00aef0', '#e70697', '#a1b7d1', '#ff6e73', '#8ee35d', '#e35db6', '#6e98e0', '#f6989d', '#f26522'];

const WheelOfAttendance = () => {
  const [wheel, setWheel] = useState();
  const { classes } = useSelector((x) => x.classes);
  
  const [soundOn, setSoundOn] = useState(true);
  const [effectOn, setEffectOn] = useState(true);
  const [names, setNames] = useState((classes && classes.data && classes.data.Students) ? classes.data.Students.map((student, index) => ({
    fillStyle: colors[index % colors.length],
    text: student.englishName || student.firstName,
    id: index
  })) : []);
  const dispatch = useDispatch();
  const { classId } = useParams();
  const [wheelSpinning, setWheelSpinning] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const audioRef = useRef(new Audio('/images/applause_yells.wav'));
  const audioClapRef = useRef(new Audio('/images/applause_yells.wav'));
  const [showModal, setModalShow] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState();
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [removeChecked, setRemoveChecked] = useState(false);
  const [timerEnded, setTimerEnded] = useState(false);
//console.log(wheel,'get classes data');return false;
const [points, setPoints] = useState([]);
const cancelModal = () => {
  setModalShow(false);
};

  useEffect(() => {
    var class_id = classId;
    dispatch(classesActions.getAttendanceStudentByClassId({ class_id }));
  }, [dispatch, classId]);
 // console.log(classes,'get classes');return false;
  useEffect(() => {
    if (classes && classes.data && classes.data.Students.length > 0) {
      var names1 = classes.data.Students.map((student, index) => ({
        fillStyle: colors[index % colors.length],
        text: (student.englishName) ? student.englishName : student.firstName ,
        id: student.id,
        index: index + 1  // Start index from 1
      }));
      setNames(names1); 

    }
  }, [classes]);

 console.log(points,'get names');
  useEffect(() => {
    if (names.length > 0) {
      //console.log("Names array:", names); // Debug log
      const segments = names
  .filter(name => name.text !== null && name.text !== undefined)
  .map((name, index) => ({
    fillStyle: name.fillStyle,
    text: name.text,
    id: name.id,
    segmentIndex: index + 1
  }));


    //   const segments = [
     const wheelInstance = new Winwheel({
        'outerRadius': 200,
        'innerRadius': 75,
        'textFontSize': 15.5,
        'textOrientation': 'vertical',
        'textAlignment': 'outer',
        'numSegments': segments.length,//validSegments.length,
        'segments': segments,
        'pins':true,
        'pins': {
            'number': segments.length,
        }

      });
     // console.log(wheelInstance,'wheelInstance');
        // Remove null segments
      wheelInstance.segments = wheelInstance.segments.filter(segment => segment !== null);
      var segmentsArray = [];
      wheelInstance.segments.forEach((segment, index) => {

          segmentsArray[index + 1] = segment;

      });
      //console.log(segmentsArray,'segment');
      wheelInstance.segments = segmentsArray;
      wheelInstance.numSegments = wheelInstance.segments.length;
      setWheel(wheelInstance);
    }
  }, [classes,names]);


  const playSound = () => {
    const audio = audioRef.current;
    //audio.pause();
    audio.currentTime = 0;
    audio.play().catch(error => console.log("Error playing sound:", error));
  };

  const playSoundClap = () => {
    const audioClap = audioClapRef.current;
    audioClap.pause();
    audioClap.currentTime = 0;
    audioClap.play();
  };

  const stopSoundClap = () => {
    audioClapRef.current.pause();
  };

  const alertPrize = (indicatedSegment) => {
    
    if(soundOn){    
      playSoundClap();
    }

    // if(effectOn){
    //   confetti({particleCount: 10000, // Increase particle count
    //     spread: 10000, // Increase spread
    //     origin: { y: 0.9, x: 0.5 }});
    // }
    setSelectedSegment(indicatedSegment.text);
    setModalShow(true);
    //alert(indicatedSegment.text + ' is selected');
    if(removeChecked){
      //console.log(selectedId,'selected',names);return false;
      //if (selectedId !== null) {
        const updatedNames = names.filter((name, index) => name.id !== indicatedSegment.id);
        console.log(updatedNames,'updatedNames');
        setNames(updatedNames);
      //}
    }
      
  };

  const startSpin = () => {
    if (!wheelSpinning && wheel) {
    

      
        const wheelInstance = new Winwheel({
          'outerRadius': 200,
          'innerRadius': 75,
          'textFontSize': 15.5,
          'textOrientation': 'vertical',
          'textAlignment': 'outer',
          'numSegments': names.length,//validSegments.length,
          'segments': names,
          'animation': {
            'type': 'spinOngoing',
            'duration': 3600,
            'spins': 9999,
            'callbackFinished': () => { alert('callback') },
            'callbackSound': playSound,
            'soundTrigger': 'pin'
          },
          'pins': {
            'number': names.length,
            'fillStyle': 'silver',
            'outerRadius': 4
          }
        });
        try {
          wheelInstance.startAnimation();
          setWheel(wheelInstance);
        setWheelSpinning(true);
      } catch (error) {
        console.error("Error starting animation:", error);
      }
    } else {
      stopWheel();
      setWheelSpinning(false);
    }
  };

  const stopWheel = () => {
    if (wheel) {
      wheel.stopAnimation(true);
      let wheelPrize = wheel.getIndicatedSegment();
      console.log(wheelPrize.id,'selected');
      setSelectedId(wheelPrize.id);
      alertPrize(wheelPrize);
      
    }
  };

  const shuffleNames = () => {
    const shuffled = [...names].sort(() => Math.random() - 0.5);
    setNames(shuffled);
  };
  
  
  const removeSelected = () => {
    setRemoveChecked(true);
    
  };
  const initialTime = new Date();
  initialTime.setSeconds(initialTime.getSeconds() + 60); // 10 seconds from now
  const [expiryTimestamp, setExpiryTimestamp] = useState(initialTime);
  const {
    seconds,
    minutes,
    hours,
    start,
    pause,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => {
    if(isCountdownActive){
      setTimerEnded(true);
      setIsCountdownActive(false);
      alert('Timer has ended!');
    }
      
    }});

  const startCountdown = () => {
    if (!isCountdownActive) {
      // Calculate the expiry time based on user input
      const hrs = document.getElementById('hrss').value;
      const mins = document.getElementById('minss').value;
      const secs = document.getElementById('secss').value;

      const expiryTimestamp = new Date();
      expiryTimestamp.setHours(expiryTimestamp.getHours() + parseInt(hrs));
      expiryTimestamp.setMinutes(expiryTimestamp.getMinutes() + parseInt(mins));
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + parseInt(secs));
      restart(expiryTimestamp);
      setIsCountdownActive(true);
      setTimerEnded(false);
    } else {
      // If countdown is already active, pause it
      pause();
      setIsCountdownActive(false);
    }
  };


  // const namemodified = () => {
  //   const nameList = document.querySelector('.names-list');
  //   const newNames = Array.from(nameList.children).map((child, index) => ({
  //     fillStyle: colors[index % colors.length],
  //     text: child.textContent,
  //     id: child.id
  //   }));
  //   setNames(newNames);
  // };
  const debounceTimeout = useRef(null);
  const nameListRef = useRef(null);

  const debounce = (func, delay) => {
    return (...args) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const namemodified = () => {
    if (nameListRef.current) {
      const newNames = Array.from(nameListRef.current.children)
        .map((child, index) => ({
          fillStyle: colors[index % colors.length],
          text: child.textContent.trim(),
          id: child.getAttribute('data-id') || `name-${index}`, // Assign unique id if not present
        }))
        .filter(name => name.text); // Filter out empty strings
      setNames(newNames);
    }
  };

  const debouncedNamemodified = debounce(namemodified, 500);

  useEffect(() => {
    if (nameListRef.current) {
      const handleInput = () => {
        debouncedNamemodified();
      };

      const currentRef = nameListRef.current;
      currentRef.addEventListener('input', handleInput);

      return () => {
        currentRef.removeEventListener('input', handleInput);
      };
    }
  }, [debouncedNamemodified]);

  useEffect(() => {
    if (nameListRef.current) {
      const currentRef = nameListRef.current;
      // Clear the content and re-render based on the names state
      currentRef.innerHTML = '';
      names.forEach((name) => {
        const div = document.createElement('div');
        div.setAttribute('data-id', name.id);
        div.textContent = name.text;
        currentRef.appendChild(div);
      });
    }
  }, [names]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newDiv = document.createElement('div');
      newDiv.setAttribute('data-id', `name-${Date.now()}`);
      newDiv.textContent = '';
      nameListRef.current.appendChild(newDiv);

      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(newDiv, 0);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };
  return (
    <div className="wheel-game-container">
      <div className="title">
        Wheel of Attendance
        <h2>{(classes && classes.data) ? classes.data.Class : ''}</h2>
      </div>
      <div className="row">
      <div className="col-md-6">
      <div className="wheel-wrapper">
        <canvas id="canvas" width="434" height="434" onClick={startSpin}></canvas>
      </div>
      </div>
      <div className='col-md-6'>
        <div className='row wheel-game-name'>
        <div className='col-md-5'>
        <div className="controls">
        <button onClick={shuffleNames}>Shuffle</button>
        <label>
          Remove
          <input type="checkbox" id="chkremove" onChange={removeSelected} />
        </label>
        {/* <div contentEditable="true" className="names-list" onKeyUp={namemodified}>
          {names.map((name, index) => (
            <div key={index}>{name.text}</div>
          ))}
        </div> */}
          <div
      className="names-list"
      contentEditable="true"
      ref={nameListRef}
      onKeyDown={handleKeyDown}
      suppressContentEditableWarning={true}
      style={{ whiteSpace: 'pre-wrap' }}
    ></div>

            </div>
            {(!isCountdownActive) ?          <div className="timer">
              <div className='input-container'>
              <input type="number" id="hrss" defaultValue="0" /> <label for="hrss">Hrs</label>
              </div>
              <div className='input-container'>
              <input type="number" id="minss" defaultValue="1" /> <label for="minss">Mins</label>
              </div>
              <div className='input-container'>
              <input type="number" id="secss" defaultValue="0" /> <label for="secss">Secs</label>
              </div>
        
        
        
        <button id="start-countdown" onClick={startCountdown}>{!isCountdownActive ? 'Start Countdown' : 'Stop Countdown'}</button>
      </div>: <div className="timer">
            <span className="hours">{hours.toString().padStart(2, '0')}</span>:<span className="minutes">{minutes.toString().padStart(2, '0')}</span>:<span className="seconds">{seconds.toString().padStart(2, '0')}</span>
            <button id="start-countdown" onClick={startCountdown}>Stop Countdown</button>
          </div>}
   
           
        </div>
       
      </div>
      </div>

        <Modal show={showModal } onHide={cancelModal}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <h4>{selectedSegment} is selected.</h4>
        </Modal.Body>
        <Modal.Footer>
         
         <Button variant="secondary" onClick={cancelModal}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
      </div>
      <div className='row'>
        {(effectOn && showModal) ? 
      <div className='col-md-12'>
            <ConfettiNew />
          </div>
          : ''}
      </div>
    </div>
  );
};

export {WheelOfAttendance}
