import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { history } from '_helpers';
import { Nav, PrivateRoute, Footer } from '_components';
import { Home, Dashboard } from 'home';
import { Login, Profile, Settings } from 'login';
import { Register } from 'register';
import { VideoChat } from 'videochat';
import { VerifyEmail,EmailVerified } from 'verifyemail';
import { RecorderList } from 'likerecorder';
import {  EngdosQuizPage ,Quizsection ,Quiztestpage ,MyEngdosQuizPage} from 'test';
import {  BoxGame , WheelOfAttendance, Spinner, Game } from 'games';
import { ChallengeList } from 'challenge';
import { MyRecording, MyRecordingSpeakingGrade, MyRecordingList } from 'likerecorder';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClassRoom, SingleClassroom, SpeakingHomeWork, WritingHomeWork, HomeWorkMail, HomeWorkMailMonthlyStat, MyOwnVocabList, MyOwnVocab,MyOwnVocabQuestions,EditOwnVocabList } from 'teacher/classroom';
import { GradeList } from 'teacher/gradelist';
import { SpeakingGrade } from 'teacher/gradelist';
import { MyOwnVocabQuizList, MyOwnVocabQuiz, MyOwnVocabQuizQuestions } from 'student/MyOwnVocab';
import { LibraryHomework, LibraryHomeworkBookList , HomeWorkVocabularyTypeByProgram, StudentLibraryHomework} from 'library';
import {StudentStudy, Study,VocabularyQuestion,StudyForReadOutLoud, StudyForReadingMultipleChoice, StudyForListeningMultipleChoice, StudyForSentenceUnscramble, StudyForVocabularyListening,
     StudyForVocabularyReadOutLoud,StudyForWritingTranslationKor2Eng,
      StudyForGrammarFillInBlank,StudyForWritingTranslationEng2Kor,
      StudyForVocabularyWritingKor2Eng,StudyForVocabularyWritingEng2Kor,StudyForVocabularyWritingEng2Eng,
      StudyForSpeakingSentenceTranslation,StudyForSpeakingSentenceReadOutLoud,StudyForGrammarFillInBlankWrite,StudyReadOutLoudGrader, TestResult, LevelTestScoreReport, VocabularyScoreReport, MilestoneTestReport } from 'study';
import { ListeningComprehensionQuestion,ReadingComprehensionQuestion, GrammarQuiz, FillInTheBlank, Scram, Scramble,ListenSentenceDictation,SentenceUnscramble,SpeakingRespondInEnglish,SentenceReadOutLoud, VocabQuizResult,StudentHomework, ReadOutLoud, HomeWorkVocabularyType, ReadOutLoudGrader,
     FreeResponse, TextBookVocabQuiz,TextBookVocabReadOutLoud, ListeningVocabQuiz, VocabularyWritePage, VocabularyListenWritePage,ReadOutLoudByProgram, TextBookVocabReadOutLoudByProgram } from 'student/HomeWork';
import { StudyTestForReadOutLoud, StudyTestForListeningMultipleChoice, StudyTestForReadingMultipleChoice, ViewTestDetails, AssignQuestions, StudentStudyTest,VocabularyDiagnostic, StudyTestAchievement, StudyTest, AssignStudyTest, VocabularyDiagnosticTest, VocabularyDiagnosticResult } from 'study/test';
import { DailyReport, TestResultReport } from 'pages'; // New public page
export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();
     // List of routes that should not display Nav and Footer
     const noHeaderFooterRoutes = ['/daily-report/:studentId/:date','/test-result-report/:studentId','/HomeWorkMail/MonthlyStats/:studentId/:token'];
    return (
        <div className="app-container bg-light">
             {!noHeaderFooterRoutes.includes(location.pathname) && (
                <header className="custom-header py-xl-3 py-2">
                    <div className="container">
                        <Nav />
                    </div>
                </header>
            )}
            {/* <header className="custom-header py-xl-3 py-2">

  <div className="container">

            <Nav />
            </div>
            </header> */}
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            />
                <Routes>
                <Route path="/daily-report/:studentId/:date" element={<DailyReport />} />
                <Route path="/test-result-report/:studentId" element={<TestResultReport />} />
                <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Home />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/video-call"
                        element={
                            
                                <VideoChat />
                        }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Register />} />
                    <Route path="/email-verified" element={<EmailVerified />} />
                    <Route path="/verify-email" element={<VerifyEmail />} />
                    <Route path="/verify-email/:token" element={<VerifyEmail />} />
                    <Route path="/recorder-list" element={<PrivateRoute><RecorderList /></PrivateRoute>} />
                    <Route path="/my-recording" element={<PrivateRoute><MyRecording /></PrivateRoute>} />
                    {/* <Route path="*" element={<Navigate to="/" />} /> */}
                    <Route path="/classroom" element={<PrivateRoute><ClassRoom /></PrivateRoute>} />
                    <Route path="/classroom/grade-list" element={<PrivateRoute><GradeList /></PrivateRoute>} />
                    <Route path="/speaking-grade/:id" element={<PrivateRoute><SpeakingGrade /></PrivateRoute>} />
                    <Route path="/myrecording/speaking-grade/:id" element={<PrivateRoute><MyRecordingSpeakingGrade /></PrivateRoute>} />
                    <Route path="/recordings-list" element={<PrivateRoute><MyRecordingList /></PrivateRoute>} />
                    <Route path="/challenge" element={<PrivateRoute><ChallengeList /></PrivateRoute>} />
                    <Route path="/vocabularyQuiz" element={<PrivateRoute><EngdosQuizPage /></PrivateRoute>} />
                    <Route path="/myvocabularyQuiz" element={<PrivateRoute><MyEngdosQuizPage /></PrivateRoute>} />
                    <Route path="/vocabularyWrite/:type/:homework_id/:program_id" element={<PrivateRoute><VocabularyWritePage /></PrivateRoute>} />
                    <Route path="/vocabularyListenWrite/:type/:homework_id/:program_id" element={<PrivateRoute><VocabularyListenWritePage /></PrivateRoute>} />
                      <Route path="/quiz" element={<PrivateRoute><Quizsection /></PrivateRoute>} />
                          <Route path="/testquiz" element={<PrivateRoute><Quiztestpage /></PrivateRoute>} />
                    <Route path="/classroom/grade-attendees/:classid" element={<PrivateRoute><SingleClassroom /></PrivateRoute>} />
                    <Route path="/classroom/SpeakingHWList/:classid/:type" element={<PrivateRoute><SpeakingHomeWork /></PrivateRoute>} />

                    <Route path="/classroom/WritingHWList/:classid/:type" element={<PrivateRoute><WritingHomeWork /></PrivateRoute>} />
                    <Route path="/HomeWorkMail/:classid/:token" element={<PrivateRoute><HomeWorkMail /></PrivateRoute>} />
                    <Route path="/HomeWork/read-out-loud-by-hw/:homework_id" element={<PrivateRoute><ReadOutLoud /></PrivateRoute>} />
                    <Route path="/HomeWork/read-out-loud/:homework_id/:program_id" element={<PrivateRoute><ReadOutLoud /></PrivateRoute>} />
                    <Route path="/HomeWork/read-out-loud-by-program/:program_id" element={<PrivateRoute><ReadOutLoudByProgram /></PrivateRoute>} />
                    <Route path="/HomeWorkVocabularyType/:homework_id/:program_id/:student_id" element={<PrivateRoute><HomeWorkVocabularyType /></PrivateRoute>} />
                    <Route path="/HomeWorkVocabularyType/:homework_id/:program_id" element={<PrivateRoute><HomeWorkVocabularyType /></PrivateRoute>} />
                    <Route path="/ReadOutLoudGrader/:recordId" element={<PrivateRoute><ReadOutLoudGrader /></PrivateRoute>} />
                    <Route path="/HomeWorkMail/MonthlyStats/:studentId/:token"
                     element={<HomeWorkMailMonthlyStat />} />
                    <Route path="/HomeWork/free-response/:homework_id"
                     element={<PrivateRoute><FreeResponse /></PrivateRoute>} />
                    <Route path="/homework"
                     element={<PrivateRoute><LibraryHomework /></PrivateRoute>} />
                     <Route path="/student-homework"
                     element={<PrivateRoute><StudentLibraryHomework /></PrivateRoute>} />
                     <Route path="/homework/:class_id/:date"
                     element={<PrivateRoute><LibraryHomework /></PrivateRoute>} />
                     <Route path="/homework/BookList/:book_id/:category/:student_id/:class_id"
                     element={<PrivateRoute><LibraryHomeworkBookList /></PrivateRoute>} />
                     <Route path="/homework/Teacher-BookList/:book_id/:class_id/:date/:category"
                     element={<PrivateRoute><LibraryHomeworkBookList /></PrivateRoute>} />
                    <Route path="/homework/BookList/:book_id/:student_id/:class_id/:date/:category"
                     element={<PrivateRoute><LibraryHomeworkBookList /></PrivateRoute>} />
                     <Route path="/homework/BookList/:book_id/:student_id/:category"
                     element={<PrivateRoute><LibraryHomeworkBookList /></PrivateRoute>} />
                    <Route path="/homework/:class_id/:student_id/:date"
                    element={<PrivateRoute><LibraryHomework /></PrivateRoute>}  />
                    <Route path="/vocabularyQuiz/:homework_id/:program_id"
                     element={<PrivateRoute><TextBookVocabQuiz /></PrivateRoute>} />
                      <Route path="/vocabularyQuiz/:program_id"
                     element={<PrivateRoute><TextBookVocabQuiz /></PrivateRoute>} />
                    <Route path="/listeningvocabularyQuiz/:homework_id/:program_id"
                     element={ <PrivateRoute><ListeningVocabQuiz /></PrivateRoute>} />
                     <Route path="/listeningvocabularyQuiz/:program_id"
                     element={ <PrivateRoute><ListeningVocabQuiz /></PrivateRoute>} />
                     <Route path="/classroom/wheel-of-attendance/:classId" element={<PrivateRoute><WheelOfAttendance /></PrivateRoute>} />
                     <Route path="/classroom/games/:classId" element={<PrivateRoute><Game /></PrivateRoute>} />
                    <Route
                        path="/student-classroom"
                        element={
                            <PrivateRoute>
                                <StudentHomework />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/textbookvocabReadoutloud/:homework_id/:program_id"
                     element={<PrivateRoute><TextBookVocabReadOutLoud /></PrivateRoute>} />
                      <Route path="/textbookvocabReadoutloudByProgram/:program_id"
                     element={<PrivateRoute><TextBookVocabReadOutLoudByProgram /></PrivateRoute>} />
                     <Route path="/Homework/VocabularyQuiz/:record_id"
                     element={<PrivateRoute><VocabQuizResult /></PrivateRoute>} /> 
                     <Route path="/sentenceReadoutloud/:homework_id/:program_id"
                     element={<PrivateRoute><SentenceReadOutLoud /></PrivateRoute>} />
                      <Route path="/sentenceReadoutloud/:program_id"
                     element={<PrivateRoute><SentenceReadOutLoud /></PrivateRoute>} />
                     <Route path="/speaking-respond-in-english/:homework_id/:program_id"
                     element={<PrivateRoute><SpeakingRespondInEnglish /></PrivateRoute>} />
                     <Route path="/speaking-respond-in-english/:program_id"
                     element={<PrivateRoute><SpeakingRespondInEnglish /></PrivateRoute>} />
                       <Route path="/sentence-unscramble/:homework_id/:program_id"
                     element={<PrivateRoute><SentenceUnscramble /></PrivateRoute>} />
                      <Route path="/sentence-unscramble/:program_id"
                     element={<PrivateRoute><SentenceUnscramble /></PrivateRoute>} />
                     <Route path="/listening-sentence-dictation/:homework_id/:program_id"
                     element={<PrivateRoute><ListenSentenceDictation /></PrivateRoute>} />
                      <Route path="/listening-sentence-dictation/:program_id"
                     element={<PrivateRoute><ListenSentenceDictation /></PrivateRoute>} />
                      <Route path="/scramble/:homework_id/:program_id"
                     element={<PrivateRoute><Scramble /></PrivateRoute>} />
                     <Route path="/scramble/:program_id"
                     element={<PrivateRoute><Scramble /></PrivateRoute>} />
                     <Route path="/scram/:homework_id/:program_id"
                     element={<PrivateRoute><Scram /></PrivateRoute>} />
                     <Route path="/scram/:program_id"
                     element={<PrivateRoute><Scram /></PrivateRoute>} />
                      <Route path="/listening-fill-in-the-blanks/:homework_id/:program_id"
                     element={<PrivateRoute><FillInTheBlank /></PrivateRoute>} />
                     <Route path="/listening-fill-in-the-blanks/:program_id"
                     element={<PrivateRoute><FillInTheBlank /></PrivateRoute>} />
                      <Route path="/grammar-quiz/:homework_id/:program_id"
                     element={<PrivateRoute><GrammarQuiz /></PrivateRoute>} />
                     <Route path="/grammar-quiz/:program_id"
                     element={<PrivateRoute><GrammarQuiz /></PrivateRoute>} />
                      <Route path="/reading-comprehension-question/:homework_id/:program_id"
                     element={<PrivateRoute><ReadingComprehensionQuestion /></PrivateRoute>} />
                     <Route path="/reading-comprehension-question/:program_id"
                     element={<PrivateRoute><ReadingComprehensionQuestion /></PrivateRoute>} />
                      <Route path="/listening-comprehension-question/:homework_id/:program_id"
                     element={<PrivateRoute><ListeningComprehensionQuestion /></PrivateRoute>} />
                      <Route path="/listening-comprehension-question/:program_id"
                     element={<PrivateRoute><ListeningComprehensionQuestion /></PrivateRoute>} />
                     <Route path="/classroom/study-test"
                     element={<PrivateRoute><StudyTest /></PrivateRoute>} />
                     <Route path="/classroom/test-achievement-new/:classid"
                     element={<PrivateRoute><StudyTestAchievement /></PrivateRoute>} />
                      <Route path="/classroom/assign-study-test/:record_id"
                     element={<PrivateRoute><AssignStudyTest /></PrivateRoute>} />
                      <Route path="/dictionary/vocabulary-diagnostic"
                     element={<PrivateRoute><VocabularyDiagnostic /></PrivateRoute>} />
                      <Route path="/study/studyTests"
                     element={<PrivateRoute><StudentStudyTest /></PrivateRoute>} />
                     <Route path="/study/assign-questions/:record_id/:level/:pageType"
                     element={<PrivateRoute><AssignQuestions /></PrivateRoute>} />
                      <Route path="/study/view-test-details/:record_id"
                     element={<PrivateRoute><ViewTestDetails /></PrivateRoute>} />
                     <Route path="/study/study-test-reading-quiz/:test_id/:record_id/:typeOfPage"
                     element={<PrivateRoute><StudyTestForReadingMultipleChoice /></PrivateRoute>} />
                     <Route path="/study/study-test-listening-quiz/:test_id/:record_id/:typeOfPage"
                     element={<PrivateRoute><StudyTestForListeningMultipleChoice /></PrivateRoute>} />
                      <Route path="/study/study-test-read-out-loud-quiz/:test_id/:record_id/:typeOfPage"
                     element={<PrivateRoute><StudyTestForReadOutLoud /></PrivateRoute>} />
                      <Route path="/study/:level"
                     element={<PrivateRoute><Study /></PrivateRoute>} />
                    <Route path="/vocabulary-question/:record_id/:q_start"
                     element={<PrivateRoute><VocabularyQuestion /></PrivateRoute>} />
                      <Route path="/study/read-out-loud/:record_id/:typeOfPage"
                     element={<PrivateRoute><StudyForReadOutLoud /></PrivateRoute>} />
                      <Route path="/study/reading-multiple-choice/:record_id/:typeOfPage"
                     element={<PrivateRoute><StudyForReadingMultipleChoice /></PrivateRoute>} />
                      <Route path="/study/listening-multiple-choice/:record_id/:typeOfPage"
                     element={<PrivateRoute><StudyForListeningMultipleChoice /></PrivateRoute>} />
                      <Route path="/study-sentence-unscramble/:program_id/:type/:q_start"
                     element={<PrivateRoute><StudyForSentenceUnscramble /></PrivateRoute>} />
                      <Route path="/vocabulary-listening-question/:type/:record_id/:q_start"
                     element={<PrivateRoute><StudyForVocabularyListening /></PrivateRoute>} />
                      <Route path="/vocabulary-listening-read/:record_id/:q_start/:pageType"
                     element={<PrivateRoute><StudyForVocabularyReadOutLoud /></PrivateRoute>} />
                     <Route path="/study-fill-in-the-blanks/:program_id/:type/:q_start"
                     element={<PrivateRoute><StudyForGrammarFillInBlank /></PrivateRoute>} />
                     <Route path="/study/WritingTranslationEnglish2Korean/:program_id/:type/:q_start"
                     element={<PrivateRoute><StudyForWritingTranslationEng2Kor /></PrivateRoute>} />
                     <Route path="/study/WritingTranslationKorean2English/:program_id/:type/:q_start"
                     element={<PrivateRoute><StudyForWritingTranslationKor2Eng /></PrivateRoute>} />
                      <Route path="/study/speaking-sentence-translation/:program_id/:pageType/:q_start"
                     element={<PrivateRoute><StudyForSpeakingSentenceTranslation /></PrivateRoute>} />
                     <Route path="/study/speaking-sentence-read/:program_id/:level/:pageType"
                     element={<PrivateRoute><StudyForSpeakingSentenceReadOutLoud /></PrivateRoute>} />
                      <Route path="/study-fill-in-the-blanks-write/:program_id/:type/:q_start"
                     element={<PrivateRoute><StudyForGrammarFillInBlankWrite /></PrivateRoute>} />
                      <Route path="/StudyReadOutLoudGrader/:recordId/:pageType" element={<PrivateRoute><StudyReadOutLoudGrader /></PrivateRoute>} />
                      <Route path="/TestResult" element={<PrivateRoute><TestResult /></PrivateRoute>} />
                      <Route path="/LevelTestScoreReport" element={<PrivateRoute><LevelTestScoreReport /></PrivateRoute>} />
                      <Route path="/HomeWorkVocabularyType/:program_id" element={<PrivateRoute><HomeWorkVocabularyTypeByProgram /></PrivateRoute>} />
                      <Route path="/study/:level/:student_id/:class_id/:date"
                     element={<PrivateRoute><StudentStudy /></PrivateRoute>} />
                     <Route path="/classroom/MyOwnVocabList/:classid/:type" element={<PrivateRoute><MyOwnVocabList /></PrivateRoute>} />
                     <Route path="/classroom/MyOwnVocab/:classid/:type" element={<PrivateRoute><MyOwnVocab /></PrivateRoute>} />
                     <Route path="/dictionary/MyOwnVocab/:id" element={<PrivateRoute><MyOwnVocabQuestions /></PrivateRoute>} />
                     <Route path="/dictionary/MyOwnVocabQuizList" element={<PrivateRoute><MyOwnVocabQuizList /></PrivateRoute>} />
                     <Route path="/dictionary/MyOwnVocabQuiz" element={<PrivateRoute><MyOwnVocabQuiz /></PrivateRoute>} />
                     <Route path="/dictionary/MyOwnVocabQuizs/:id" element={<PrivateRoute><MyOwnVocabQuizQuestions /></PrivateRoute>} />
                     <Route path="/dictionary/EditOwnVocabList/:vocab_id" element={<PrivateRoute><EditOwnVocabList /></PrivateRoute>} />
                     <Route path="/games/giftbox" element={<PrivateRoute><BoxGame /></PrivateRoute>} />
                     <Route path="/classroom/score-board-with-wheel/:classId" element={<PrivateRoute><Spinner /></PrivateRoute>} />
                     <Route path="/vocabulary-score-report/:student_id/:school/:type/:date" element={<PrivateRoute><VocabularyScoreReport /></PrivateRoute>} />
                     <Route path="/milestone-score-report/:student_id" element={<PrivateRoute><MilestoneTestReport /></PrivateRoute>} />
                     <Route path="/vocabulary-diagnostic-test/:school/:type/:retest" element={<PrivateRoute><VocabularyDiagnosticTest /></PrivateRoute>} />
                     <Route path="/dictionary/VocabularyDiagnosticResult/:school/:type/:retest" element={<PrivateRoute><VocabularyDiagnosticResult /></PrivateRoute>} />
                     <Route path="/study/VocabularyWritingKorean2English/:program_id/:type/:q_start"
                     element={<PrivateRoute><StudyForVocabularyWritingKor2Eng /></PrivateRoute>} />
                     <Route path="/study/VocabularyWritingEnglish2Korean/:program_id/:type/:q_start"
                     element={<PrivateRoute><StudyForVocabularyWritingEng2Kor /></PrivateRoute>} />
                     <Route path="/study/VocabularyWritingEnglish2English/:program_id/:type/:q_start"
                     element={<PrivateRoute><StudyForVocabularyWritingEng2Eng /></PrivateRoute>} />
                     <Route path="/profile"
                     element={<PrivateRoute><Profile /></PrivateRoute>} /> 
                     <Route path="/settings"
                     element={<PrivateRoute><Settings /></PrivateRoute>} />
                    </Routes>
                    
            {/* <Footer /> */}
             {/* Conditionally render Footer based on the route */}
             {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
        </div>
    );
}
