import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'dictionary';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const dictionaryActions = { ...slice.actions, ...extraActions };
export const dictionaryReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        getDictionaryVocab : getDictionaryVocab(),
        searchDictionaryWord: searchDictionaryWord(),
    };    

    function getDictionaryVocab() {
        return createAsyncThunk(
            `${name}/dictionary`,
            async () => await fetchWrapper.get(`${baseUrl}/dictionary/vocablist`)
        );
    }

    function searchDictionaryWord() {
        return createAsyncThunk(
            `${name}/dictionary`,
            async ({words}) => await fetchWrapper.post(`${baseUrl}/dictionary/wordmeaning`,{ words })
        );
    }


}

function createExtraReducers() {
    return {
        ...getDictionaryVocab(),
        ...searchDictionaryWord(),
    };

    function getDictionaryVocab() {
        var { pending, fulfilled, rejected } = extraActions.getDictionaryVocab;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const dictionary = action.payload;

                state.dictionary = dictionary;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function searchDictionaryWord() {
        var { pending, fulfilled, rejected } = extraActions.searchDictionaryWord;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const dictionary = action.payload;

                state.dictionary = dictionary;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }


}
