import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'gradeattendence';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const gradeattendenceActions = { ...slice.actions, ...extraActions };
export const gradeattendenceReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        createGradeAttendence: createGradeAttendence(),
        getGradeAttendanceByStudent:getGradeAttendanceByStudent(),
        getGradeAttendanceByTeacher:getGradeAttendanceByTeacher(),
    };    

    function createGradeAttendence() {
        return createAsyncThunk(
            `${name}/gradeattendence`,
            async ({ data,reading_avg,listening_avg,speaking_avg,writing_avg,grammer_avg,vocab_avg,teacher_id,hdnClassIds,hdnAttendeDate,WhichColumn }) => 
            await fetchWrapper.post(`${baseUrl}/attendence/create`, { data,reading_avg,listening_avg,speaking_avg,writing_avg,grammer_avg,vocab_avg,teacher_id,hdnClassIds,hdnAttendeDate,WhichColumn })
        );
    }

    function getGradeAttendanceByStudent() {
        return createAsyncThunk(
            `${name}/gradeattendence`,
            async ({ studentId,classId,date }) => await fetchWrapper.get(`${baseUrl}/attendence/student?studentId=`+studentId+'&classId='+classId+'&date='+date)
        );
    }

    function getGradeAttendanceByTeacher() {
        return createAsyncThunk(
            `${name}/gradeattendence`,
            async ({ date,studentId,month }) => await fetchWrapper.get(`${baseUrl}/attendence/teacher?studentId=`+studentId+'&date='+date+'&month=true')
        );
    }


}

function createExtraReducers() {
    return {
        ...createGradeAttendence(),
        ...getGradeAttendanceByStudent(),
        ...getGradeAttendanceByTeacher(),
    };

    function createGradeAttendence() {
        var { pending, fulfilled, rejected } = extraActions.createGradeAttendence;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const gradeattendence = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                state.gradeattendence = gradeattendence;
                toast.success('Grade Saved Successfully.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getGradeAttendanceByStudent() {
        var { pending, fulfilled, rejected } = extraActions.getGradeAttendanceByStudent;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const gradeattendence = action.payload;
                state.gradeattendence = gradeattendence;
            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getGradeAttendanceByTeacher() {
        var { pending, fulfilled, rejected } = extraActions.getGradeAttendanceByTeacher;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const gradeattendence = action.payload;
                state.gradeattendence = gradeattendence;
            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }


}
