import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { signupActions } from '_store';

export { EmailVerified };

function EmailVerified() {
    const dispatch = useDispatch();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    useEffect(() => {
        // redirect to home if already logged in
     //  dispatch(signupActions.verifyemail({token}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <section className="signin-section signup-section">
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 px-0">
        <div className="signin-section-wrap">
          <div className="col-lg-6 col-md-12">
            <div className="sign-in-img">
              <img src="images/sign-up-img.webp" alt="sign-in-img" />
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>
  <div className="signin-form">
    <div className="signin-form-wrap">
      <div className="form-box">
        <h3>Email Verified</h3>
        <p className="custom-para">
          Your Email has been verified successfully.
        </p>
      
      </div>
    </div>
  </div>
</section>

    )
}
