import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export { Home };

function Home() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector(x => x.auth);

    useEffect(() => {
        
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
<>
  <section className="custom-banner">
    <div
      id="carouselExampleCaptions"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={0}
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        />
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={1}
          aria-label="Slide 2"
        />
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to={2}
          aria-label="Slide 3"
        />
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src="images/banner.webp"
            className="d-block w-100 banner-img"
            alt="banner"
          />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="carousel-caption banner-content d-none d-md-block">
                  <h2>
                    likestudy the first all in one likestudy platform
                  </h2>
                  <p className="custom-para">
                    likestudy is one of the secrets of professional success,
                    and we just made it easier. Revolutionize the way you
                    network with our all in one likestudy platform. Increase
                    connections, meet new business contacts and receive weekly
                    personalized connection recommendations.
                  </p>
                  <div className="banner-btn">
                    <a className="custom-button" href="javascript:void(0)">
                      CREATE A FREE PROFILE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="images/banner.webp"
            className="d-block w-100 banner-img"
            alt="banner"
          />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="carousel-caption banner-content d-none d-md-block">
                  <h2>
                    likestudy the first all in one likestudy platform
                  </h2>
                  <p className="custom-para">
                    likestudy is one of the secrets of professional success,
                    and we just made it easier. Revolutionize the way you
                    network with our all in one likestudy platform. Increase
                    connections, meet new business contacts and receive weekly
                    personalized connection recommendations.
                  </p>
                  <div className="banner-btn">
                    <a className="custom-button" href="javascript:void(0)">
                      CREATE A FREE PROFILE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="images/banner.webp"
            className="d-block w-100 banner-img"
            alt="banner"
          />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="carousel-caption banner-content d-none d-md-block">
                  <h2>
                    likestudy the first all in one likestudy platform
                  </h2>
                  <p className="custom-para">
                    likestudy is one of the secrets of professional success,
                    and we just made it easier. Revolutionize the way you
                    network with our all in one likestudy platform. Increase
                    connections, meet new business contacts and receive weekly
                    personalized connection recommendations.
                  </p>
                  <div className="banner-btn">
                    <a className="custom-button" href="javascript:void(0)">
                      CREATE A FREE PROFILE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item active">
          <img
            src="images/banner.webp"
            className="d-block w-100 banner-img"
            alt="banner"
          />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="carousel-caption banner-content d-none d-md-block">
                  <h2>
                    likestudy the first all in one likestudy platform
                  </h2>
                  <p className="custom-para">
                  Like Study is an immersive learning experience that provides comprehensive, English content for Pre-K to College.
                  </p>
                  <div className="banner-btn">
                    <a className="custom-button" href="javascript:void(0)">
                      CREATE A FREE PROFILE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
      </button>*/}
    </div>
  </section>

  {/*Blogs*/}
  <section className="event leads blog">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-sm-6  align-self-center">
          <div className="custom-heading line">
            <h3>Latest From Blogs</h3>
          </div>
        </div>
        <div className="col-md-6 col-sm-6  align-self-center">
          <div className="home-section-btn">
            <a className="custom-button" href="javascript:void(0)">
              View All
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-12 align-self-center">
          <div
            id="blogs-carousel"
            className="owl-carousel owl-theme event-carousel"
          >
            <div className="item">
              <a className="event-box" href="#">
                <div className="event-box-content">
                  <div className="event-img">
                    <img src="images/lead-1.webp" alt="lead-1" />
                  </div>
                </div>
              </a>
              <div className="blog-content">
                <h3>Following Up After A likestudy Event</h3>
                <p className="custom-para">
                  Growing an email list isn’t always easy, but it can be done by
                  implementing the right strategies. By learning how to do this,
                  you’re going…
                </p>
                <a href="#">Learn More</a>
              </div>
            </div>
            <div className="item">
              <a className="event-box" href="#">
                <div className="event-box-content">
                  <div className="event-img">
                    <img src="images/lead-2.webp" alt="lead-2" />
                  </div>
                </div>
              </a>
              <div className="blog-content">
                <h3>10 Proven Ways To Grow Your Email List</h3>
                <p className="custom-para">
                  Growing an email list isn’t always easy, but it can be done by
                  implementing the right strategies. By learning how to do this,
                  you’re going…
                </p>
                <a href="#">Learn More</a>
              </div>
            </div>
            <div className="item">
              <a className="event-box" href="#">
                <div className="event-box-content">
                  <div className="event-img">
                    <img src="images/deal-1.webp" alt="deal-1" />
                  </div>
                </div>
              </a>
              <div className="blog-content">
                <h3>Following Up After A likestudy Event</h3>
                <p className="custom-para">
                  Growing an email list isn’t always easy, but it can be done by
                  implementing the right strategies. By learning how to do this,
                  you’re going…
                </p>
                <a href="#">Learn More</a>
              </div>
            </div>
            <div className="item">
              <a className="event-box" href="#">
                <div className="event-box-content">
                  <div className="event-img">
                    <img src="images/lead-1.webp" alt="lead-1" />
                  </div>
                </div>
              </a>
              <div className="blog-content">
                <h3>10 Proven Ways To Grow Your Email List</h3>
                <p className="custom-para">
                  Growing an email list isn’t always easy, but it can be done by
                  implementing the right strategies. By learning how to do this,
                  you’re going…
                </p>
                <a href="#">Learn More</a>
              </div>
            </div>
            <div className="item">
              <a className="event-box" href="#">
                <div className="event-box-content">
                  <div className="event-img">
                    <img src="images/lead-1.webp" alt="lead-1" />
                  </div>
                </div>
              </a>
              <div className="blog-content">
                <h3>Following Up After A likestudy Event</h3>
                <p className="custom-para">
                  Growing an email list isn’t always easy, but it can be done by
                  implementing the right strategies. By learning how to do this,
                  you’re going…
                </p>
                <a href="#">Learn More</a>
              </div>
            </div>
            <div className="item">
              <a className="event-box" href="#">
                <div className="event-box-content">
                  <div className="event-img">
                    <img src="images/lead-2.webp" alt="lead-2" />
                  </div>
                </div>
              </a>
              <div className="blog-content">
                <h3>10 Proven Ways To Grow Your Email List</h3>
                <p className="custom-para">
                  Growing an email list isn’t always easy, but it can be done by
                  implementing the right strategies. By learning how to do this,
                  you’re going…
                </p>
                <a href="#">Learn More</a>
              </div>
            </div>
            <div className="item">
              <a className="event-box" href="#">
                <div className="event-box-content">
                  <div className="event-img">
                    <img src="images/deal-1.webp" alt="deal-1" />
                  </div>
                </div>
              </a>
              <div className="blog-content">
                <h3>Following Up After A likestudy Event</h3>
                <p className="custom-para">
                  Growing an email list isn’t always easy, but it can be done by
                  implementing the right strategies. By learning how to do this,
                  you’re going…
                </p>
                <a href="#">Learn More</a>
              </div>
            </div>
            <div className="item">
              <a className="event-box" href="#">
                <div className="event-box-content">
                  <div className="event-img">
                    <img src="images/lead-1.webp" alt="lead-1" />
                  </div>
                </div>
              </a>
              <div className="blog-content">
                <h3>10 Proven Ways To Grow Your Email List</h3>
                <p className="custom-para">
                  Growing an email list isn’t always easy, but it can be done by
                  implementing the right strategies. By learning how to do this,
                  you’re going…
                </p>
                <a href="#">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Faq*/}
  <section className="faq">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2>FAQ</h2>
          <div className="faq-accordion">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What industries can I expect to meet professionals from?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="custom-para">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What if i don’t have time to attend pre-scheduled events?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="custom-para">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Who are the memberships designed for?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="custom-para">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="newsletter">
            <h3>
              Subscribe to get information, lastest news and other interesting
              offers about NetWork-Afterwork
            </h3>
            <form>
              <div className="mb-3 newsletter-mail">
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Email Address"
                />
                <div className="newsletter-btn">
                  <input
                    className="custom-button"
                    type="button"
                    defaultValue="SUBMIT"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

    );
}
