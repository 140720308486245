import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { classesActions } from '_store';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export { ClassRoom };

function ClassRoom() {
  const dispatch = useDispatch();
  const { classes } = useSelector((x) => x.classes);
  const [arrayOfObjects, setArrayOfObjects] = useState(null);
  const [checkedStates, setCheckedStates] = useState({});
  const [Classes, setClasses] = useState();

  useEffect(() => {
    if (classes && classes.data) {
      const initialCheckedStates = {};
      classes.data.forEach(classd => {
        initialCheckedStates[classd?.id] = classd?.auto_homework;
      });
      setCheckedStates(initialCheckedStates);

      const sortedClasses = [...classes.data].sort((a, b) => {
        if (a?.class_name < b?.class_name) return -1;
        if (a?.class_name > b?.class_name) return 1;
        return 0;
      });
      setClasses(sortedClasses);
    }
  }, [classes]);
console.log('get class', classes);
  useEffect(() => {
    dispatch(classesActions.getClasses());

 }, []);

 const handleConvert = (inputString) => {
  try {
    const parsedArray = JSON.parse(inputString);
    console.log(parsedArray);
    return parsedArray;
  //   if (Array.isArray(parsedArray)) {
  //     setArrayOfObjects(parsedArray);
  //   } else {
  //     throw new Error('Input is not a valid array.');
  //   }
  // } catch (error) {
  //   console.error(error);
  //   setArrayOfObjects(null);
  }
  catch (error) {
     console.error(error);
    }
};
const convertTime = (time) => {
  const [hours, minutes] = time.split(':');
  const date = new Date(2023, 0, 1, hours, minutes); // Assuming year 2023, month 0 (January)
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  
  return date.toLocaleTimeString('en-US', options)
};

const handleSearch = async (e) => {

var query = e.target.value;
return dispatch(classesActions.searchClasses({query}));
};
const handleAutoHomework = async (e,classId, currentCheckedState) => {
  const newCheckedState = !currentCheckedState;
    setCheckedStates(prevState => ({
      ...prevState,
      [classId]: newCheckedState
    }));
  var auto_homework = e;
  const response = await axios.get(
    "https://api.webtracktechnologies.com.au:8002/class/homeworkstatus/"+classId+'?auto_homework='+auto_homework,
    {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json', // Make sure to set the content type based on your form data
    },
    }
  );

  if(response){
    if(auto_homework == true){
      toast.success('Auto Homework assignment enabled successfully.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
     // alert('Auto Homework assignment enabled successfully.');
    }else{
      toast.error('Auto Homework assignment disabled successfully.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
 
  }
  };


  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str) => {
    if (!str) return ''; // Return an empty string if str is undefined, null, or empty
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

    return (

      <section className="custom-sign">
      <div className="container-fluid">
      <div className="card-header">
			<p className="header-title">Classrooms</p>
			<input type="text" onChange={(e)=>handleSearch(e)} placeholder="Search" />
			<select>
				<option value="">A-Z</option>
				<option value="">Z-A</option>
			</select>
		  </div>
      {(Classes) ? Classes.map((classd, index) => (
     <a target={(classd?.class_name === 'unassigned students') ? '_blank' : ''} href={(classd?.class_name === 'unassigned students') ? `/classroom/test-achievement-new/${classd?.id}`: `/classroom/grade-attendees/${classd?.id}`}>
     <div className="card">
      <>
      <label class="switch">
      <input
      type="checkbox"
      checked={!!checkedStates[classd?.id]}
      onChange={() => handleAutoHomework(!classd?.auto_homework, classd?.id,checkedStates[classd?.id])} // Use onChange instead of onClick
    />
  <span class="slider round"></span>
</label>
      </>
    
        <img src="/images/Imgclass_22.png" alt="Card" />
        <div className="card-body">
          <h5 className="card-title">{capitalizeFirstLetter(classd?.class_name)}</h5>
          {(classd?.class_name === 'unassigned students') ? 
          <>
          <div className="card-body-img"> 

          <img src="images/testicn.png" /> 
          </div>
             <p className="card-text"></p>
             </>
          :  
          <>
          <div className="card-body-img"> 
            <a href={`/classroom/score-board-with-wheel/${classd?.id}`} target="_blank"><img src="images/spinico.png" /> </a>
            <a href={`${classd?.gmeet_link}`} target="_blank">
            <img src="images/googlemeet.png" />
            </a>
            <a href={`${classd?.gdoc_link}`} target="_blank">
            <img src="images/googledoc.png" />
            </a>
            <a href={`/classroom/games/${classd?.id}`} target="_blank"><img src="images/gameslogo.png" /></a>
            </div>    
            
          <p className="card-text">{
            (handleConvert(classd?.schedule) && handleConvert(classd?.schedule).length > 0) ? 
            handleConvert(classd?.schedule).map((schedule, index) => (
              <p>{schedule?.day} - {convertTime(schedule?.startTime)},</p>
              )):''}</p>   
              </>         
      }
           

         
        </div>
      </div>
     </a>
 
      )): ''}
      </div>
    </section>
      
    )
}
