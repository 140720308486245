import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quizActions, quizresultActions,libraryActions , homeworkActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import { BarChart } from '@mui/x-charts/BarChart';
import { useParams } from "react-router-dom";
import axios from "axios";
export { ListeningVocabQuiz };

function ListeningVocabQuiz() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homework_id,program_id } = useParams();
  const [myState, setMyState] = useState('initialValue');
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);
  const { library } = useSelector((x) => x.library);
  const { homework } = useSelector((x) => x.homework);
  const { student } = useSelector((x) => x.student);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [quizCategory, setquizCategory] = useState();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizalready, setQuizAlready] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const [timer, setTimer] = useState(null);
  const [day, setDay] = useState(1);
//console.log(library,'get lib',quizData);
const [hasPlayed, setHasPlayed] = useState(false);
const audioRef = useRef(null);
const [correctAnswered, setCorrectAnswered] = useState(0);
  const [questionAnswered, setQuestionAnswered] = useState(0);
const handlePlay = () => {
  const audio = audioRef.current;

  if (audio && !hasPlayed) {
    audio.play().catch(error => {
      console.error('Error playing audio:', error);
    });
    setHasPlayed(true);
  }
};
// useEffect(() => {
//   const audio = audioRef.current;
// alert('me');
//   if (audio) {
//     // Play the audio once on page load
//     audio.play().catch(error => {
//       console.error('Error playing audio:', error);
//     });
//   }

//   // Clean up the audio object when the component unmounts
//   return () => {
//     if (audio) {
//       audio.pause();
//       audio.currentTime = 0;
//     }
//   };
// }, []); 
// useEffect(() => {
//   const audio = audioRef.current;
// alert('get');
//   if (audio && !hasPlayed) {
//     // Play the audio once on page load
//     handlePlay();
//   }

//   // Clean up the audio object when the component unmounts
//   return () => {
//     if (audio) {
//       audio.pause();
//       audio.currentTime = 0;
//     }
//   };
// }, [hasPlayed]);

  const student1 = JSON.parse(localStorage.getItem('user'));

 useEffect(() => {
 
    dispatch(libraryActions.listenVocabQuiz({program_id}));
    dispatch(homeworkActions.getHomeWorkByRecordId({ homework_id }));
  }, []);

  const handleOptionSelect = (selected) => {
    setSelectedOption(selected);
    handleNextQuestion(selected);
    setQuestionAnswered(prevCount => prevCount + 1);
    setUserAnswers(prevAnswers => [...prevAnswers, selected]);
  };


  const handleNextQuestion = (selectedOption) => {
    // if (timer) {
    //   clearTimeout(timer);
    // }

    if (selectedOption === quizData[currentQuestion].answer) {
      setScore(score + 1);
      setCorrectAnswered(correctAnswered + 1);
    }

    setSelectedOption(null);

    if (currentQuestion + 1 < quizData.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      console.log("quiz comp");
    }

    if (currentQuestion === quizData.length - 1) {
      console.log('Setting quizCompleted to true');
      setQuizCompleted(true);
    }

    // setTimeRemaining(location.state.vocbsetting.time);
  };



  const handleKeyPress = (event) => {
    const selectedNumber = Number(event.key);
    if (!isNaN(selectedNumber) && selectedNumber > 0 && selectedNumber <= quizData[currentQuestion].options.length) {
      const selectedOption = quizData[currentQuestion].options[selectedNumber - 1];
      handleOptionSelect(selectedOption);
    }
  };

  // useEffect(() => {
  //   let timer;
  //   // console.log(location.state.vocbsetting)
  //   if (timeRemaining > 0 && currentQuestion < quizData.length && location.state.vocbsetting.istime == 1) {
  //     timer = setTimeout(() => {
  //       handleNextQuestion(null);
  //     }, timeRemaining * 1000);

  //     const countdownInterval = setInterval(() => {
  //       setTimeRemaining((prevTime) => prevTime - 1);
  //     }, 1000);

  //     setTimer(timer);

  //     return () => {
  //       if (timer) {
  //         clearTimeout(timer);
  //       }
  //       clearInterval(countdownInterval);
  //     };
  //   }
  // }, [timeRemaining, currentQuestion, quizData]);





  // useEffect(() => {
  //   try {
  //     if (quizData.length === 0) {
  //       // if (!location.state) {
  //       //   window.location.href = '/';
  //       // }
  //       if(library && library.data){
  //         setQuizData(library.data);
  //       }
        
  //       setquizCategory("asas");

  //     }


  //   }
  //   catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // }, [dispatch]);


  useEffect(() => {
    try {
      if (quizData.length === 0) {
        // if (!location.state) {
        //   window.location.href = '/';
        // }
        if(library && library.data){
          setQuizData(library.data);
        }
        
        setquizCategory("LISTENING: VOCABULARY DICTATION");

      }


    }
    catch (error) {
      console.error("An error occurred:", error);
    }
  }, [library]);

  useEffect(() => {
    if(homework && homework.data){
      var getDay = homework.data.day;
      getDay.split('-');
      setDay(getDay[0]);
    }
   
  }, [homework]);

  useEffect(() => {

    window.addEventListener('keydown', handleKeyPress);


    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (quizCompleted === true) {
          console.log(quizCompleted, "quizcompleted");
          const result = []
          {
            quizData.map((question, index) => (
              result.push({ word: question.word, answer: question.answer, useranswer: (index < userAnswers.length) ? userAnswers[index] : "Not answered" })
            ))
          }
    
          console.log(student, "student");
          if (student1) {
            const scoreprecentage=((score / quizData.length) * 100);
            const option = {
             // category: location.state.vocabulary.id,
              total_score:correctAnswered,
              Studentid: student1.user.student_id,
              Classid:student.Class.id,
              type: "Listen Vocab",
              sub_type: "Listen Vocab",
              homework_id:homework_id,
              result: JSON.stringify(result),
              total_questions:quizData.length,
             question_answered: questionAnswered,
             percentage: scoreprecentage,
             correct_answered: correctAnswered,
             ...(homework_id ? { homework_id } : {}),  // Include homework_id only if it exists
             ...(program_id ? { program_id } : {}),    // Include program_id only if it exists

            }
            console.log(option, "opt")
            const response = await axios.post(
              "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
              option,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  // Add other headers if needed
                },
              }
            );
            console.log(response);
            //dispatch(homeworkActions.savevocabresult(option));
          }
    
        }
      } catch (error) {
        console.error('Error fetching homework data:', error);
      }
    };
  
    fetchData();
  }, [dispatch, quizCompleted, quizCategory, score, quizData, userAnswers]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }


  const renderOptions = () => {
    return quizData[currentQuestion].options.map((option, index) => (
      <div key={index} className='quiz-options'>
        <label className='option-index'>{index + 1}</label>
        <label className="container" htmlFor={option}>
          {option}
          <input
            type="radio"
            id={option}
            name="options"
            value={option}
            checked={selectedOption === option}
            onChange={() => handleOptionSelect(option)}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    ));
  };

  const handleRetakeQuiz = () => {

    const shuffledQuizData = shuffleArray([...quizData]);

    setQuizCompleted(false);
    setQuizAlready(false)
    setCurrentQuestion(0);
    setSelectedOption(null);
    setScore(0);
    setQuizData(shuffledQuizData);
  };

  const renderQuizContent = () => {
    if (currentQuestion < quizData.length && quizCompleted === false ) {
      const totalTime = 10;
      const remainingPercentage = (timeRemaining / totalTime) * 100;
      const precent = (100 - ((currentQuestion - score) / quizData.length * 100)).toFixed(2);
      // const audio = new Audio(quizData[currentQuestion].audiofile);
    
      // // Play the audio when the component mounts
      // audio.play();
      return (

        <div className="quiz-content">
          <div className="quiz-header">
            <div className="category-head">
              <h4>{quizCategory}</h4>
              <h5>{'1-'+day}</h5>
            </div>

            {/*{location.state.vocbsetting.istime === 1 && (
              <ProgressBar stripped style={{
                width: "100%",
                height: "1.3rem",
                fontSize: "0.95rem"
              }}
                animated now={remainingPercentage} label={`${timeRemaining} seconds`} />
            )}*/}
            <div className="quiz-progress">
              <p>Question {currentQuestion + 1} of {quizData.length}</p>
              {/* <p>Percentage: {(currentQuestion === 0) ? 100 : ((score /quizData.length ) * 100).toFixed(2)}%</p> */}
              <p>Percentage: {precent} %</p>


            </div>
          </div>
          <div className="quiz-question-area">
            <h5>Click the word that you hear</h5>
            <div class="quiz-question-area-part-one">
            {/* <audio controls autoPlay>
                  <source src={quizData[currentQuestion].audiofile} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>  */}
          <audio ref={audioRef} src={quizData[currentQuestion].audiofile} controls autoPlay />
          <button onClick={handlePlay}>
        {hasPlayed ? <i className="fa fa-speaker" aria-hidden="true" /> : <i className="fa fa-speaker" aria-hidden="true" />}
      </button>
      </div>
            {/* <h3 style={{ textTransform: "none" }}>{quizData[currentQuestion].word}</h3> */}
            <div class="quiz-question-area-part-two">
            {renderOptions()}
            </div>
          </div>
        </div>
      );
    }
     else {
      const percentage = ((score / quizData.length) * 100);

      return (

        <div>
          <h2>Quiz Result!</h2>


          {/*<BarChart
            xAxis={[{ scaleType: 'band', data: ['Your score'] }]}
            series={[{ data: [percentage], color: "#3a58e9b8", stack: 'total' }, { data: [100 - percentage], color: "#992525", stack: 'total' }]}
            width={500}
            height={300}

          />*/} 
          <span className={(percentage > 20) ? 'fa fa-star checked' : 'fa fa-star'}></span>
          <span className={(percentage>40)?'fa fa-star checked' :'fa fa-star'}></span>
          <span className={(percentage>60)?'fa fa-star checked' :'fa fa-star'}></span>
          <span className={(percentage>80)?'fa fa-star checked' :'fa fa-star'}></span>
          <span className={(percentage>95)?'fa fa-star checked' :'fa fa-star'}></span>
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {quizData.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Word</th>
                  <th>Correct Answer</th>
                  <th>User Answer</th>
                  <th>O X</th>
                </tr>
              </thead>
              <tbody>
                {quizData.map((question, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{question.word}</td>
                    <td> {question.answer}</td>
                    <td>{index < userAnswers.length ? userAnswers[index] : "Not answered"}</td>
                    <th>{(question.answer == userAnswers[index]) ? 'O' : 'X'}</th>
                  </tr>

                ))}
              </tbody>
            </Table>
          </div>

          <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
        </div>
      );
    }
  };



  return (
    <div className="quiz-container">
      <div className='quizs-container'> {renderQuizContent()} </div>
    </div>
  );
}

