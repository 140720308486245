import { useEffect, useState,useRef } from 'react';
import { NavLink, useLocation  } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { authActions, studentActions } from '_store';

export { Nav };

function Nav() {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    const location = useLocation();
    const { student }  = useSelector(x => x.student);
    

    useEffect(() => {
      if(authUser && authUser.user.student_id){
        var userId = authUser.user.student_id;
        dispatch(studentActions.getStudentById({userId}));
      }
     
    }, [authUser]);
    // only show nav when logged in
    //if (!authUser) return null;
    console.log(authUser,'het me');
    return (
    //     <nav className="navbar navbar-expand navbar-dark bg-dark">
    //     <div className="navbar-nav">
    //         <NavLink to="/" className="nav-item nav-link">Home</NavLink>
    //         <button onClick={logout} className="btn btn-link nav-item nav-link">Logout</button>
    //     </div>
    // </nav>
    <nav className="navbar navbar-expand-lg py-0">
    <a className="navbar-brand ms-0 py-0" href="#"><img className="logo" src="https://likestudy.webtracktechnologies.com.au/images/LIKEstudylogo.png" /></a>
    <div className="navbar-header me-0">
      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="sr-only">Toggle navigation</span>
        <span className="icon-bar" />
        <span className="icon-bar" />
        <span className="icon-bar" />
      </button>
    </div>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className={location.pathname === '/study/1' ? 'nav-link active' : 'nav-link'} aria-current="page" href="/study/1">Study</a>
        </li>
        <li className="nav-item">
          <a className={location.pathname === '/challenge' ? 'nav-link active' : 'nav-link'} href="/challenge">Challenge</a>
        </li>
        <li className="nav-item">
          
          <a className={location.pathname === '/dictionary/vocabulary-diagnostic' ? 'nav-link active' : 'nav-link'}  href="/dictionary/vocabulary-diagnostic">Test</a>
          
        </li>
        <li className="nav-item">
          {(authUser && authUser.user.type == 'teacher') ? <a className={(location.pathname === '/homework' || location.pathname === '/student-homework')  ? 'nav-link active' : 'nav-link'} href="/homework">Library</a> 
          
        : <a className={(location.pathname === '/homework' || location.pathname === '/student-homework')  ? 'nav-link active' : 'nav-link'} href="/student-homework">Library</a>}
          
        </li>
         {student && (student.giftboxes > 0 || student.giftboxesm > 0 || student.giftboxesl > 0) && (
            <li className="nav-item">
              <a className={location.pathname === '/games/giftbox' ? 'nav-link active shakeicon' : 'nav-link shakeicon'} href="/games/giftbox"><img src='/images/gfimg.png' width={50} height={45} /></a>
            </li>
          )}
        <li className="nav-item sub-nav">
          <a className={(location.pathname === '/student-classroom' || location.pathname === '/recordings-list') ? 'nav-link main active' : 'nav-link main'} href="#">My Page</a>
          <ul className="sub-menu">
            <li className="nav-item">
              <a className="nav-link" href="/student-classroom">Homework</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/recordings-list">LIKE Recorder</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">LIKE Writer</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/dictionary/MyOwnVocabQuizList">My Vocab</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/profile">Profile</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/settings">Settings</a>
            </li>
          </ul>
        </li>
        {(authUser) ? (authUser.user.type == 'teacher') ? 
        <>
         <li className="nav-item">
          <a className={location.pathname === '/classroom' ? 'nav-link active' : 'nav-link'} href="/classroom">Classroom</a>
        </li>
        <li className="nav-item">
          <a className={location.pathname === '/classroom/grade-list' ? 'nav-link active' : 'nav-link'} href="/classroom/grade-list">Grading</a>
        </li></> : '' : ''}
       
        <li className="nav-item">
          <a className="nav-link" href="#"><img className="nav-img" src="https://likestudy.webtracktechnologies.com.au/images/googlemeet.png" /></a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#"><img className="nav-img" src="https://likestudy.webtracktechnologies.com.au/images/googledoc.png" /></a>
        </li>
      </ul>
      {(authUser && authUser.user.type !== 'teacher' && (student)) ? 
      <div class="header-text">
	{/* <p class="txt">Like test prep</p> */}
      <p class="txt">{(student) ? student.Class.class_name : ''} <br /><span className='display-level'>Lv.{(student) ? student.level : ''}</span> </p>
     <p class="txt">${(student) ? student.coins : 0}</p>
     <p class="txt">{(student) ? student.score : 0}</p>
      <p class="txt">{(student) ? student.firstName+' '+student.koreanName : ''}</p>
    </div> : ''}
      
    </div>
    <div className="header-btn">
    {!authUser ? 
    <>
    <a className="custom-button" href="/signup">
    Sign Up
  </a>
  <a className="custom-button-alt" href="/login">
    Login
  </a>
    </>
    
    : 
    <a className="custom-button-alt" onClick={logout} href="javascript:void(0)">
    Logout
  </a>
    }
    
  </div>
  </nav>

  
    );
}