import { useEffect, useState,useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { studyActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
export { StudyTestForReadingMultipleChoice };

function StudyTestForReadingMultipleChoice() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { record_id, typeOfPage, test_id } = useParams();
  const [myState, setMyState] = useState('initialValue');
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);
  const { passage } = useSelector((x) => x.passage);
  const { homework } = useSelector((x) => x.homework);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [quizCategory, setquizCategory] = useState();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizalready, setQuizAlready] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const [timer, setTimer] = useState(null);
  const [day, setDay] = useState(1);
  const [resultData, setResult] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(1);
  const [totalGroups, setTotalGroups] = useState(1);
  const [questionsPerGroup, setquestionsPerGroup] = useState(1);
  const [groupText, setGroupText] = useState('');
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [allQuestions, setAllQuestions] = useState();
  const [questionAnswered, setQuestionAnswered] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const intervalRef = useRef();
  const [inactive, setInactive] = useState(false);
  const [correctAnswered, setCorrectAnswered] = useState(0);
//console.log(passage,'get lib',quizData);

  const student = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    let timer;
    
    const resetTimer = () => {
      clearTimeout(timer);
      // Start the timer again
      timer = setTimeout(() => {
        setInactive(true);
        // Pause the stopwatch
        clearInterval(intervalRef.current);
        setIsRunning(false);
      }, 120000); // 2 minutes (120000 milliseconds)
    };
  
    const handleActivity = () => {
      if (inactive) {
        setInactive(false);
        // Resume the stopwatch
        start();
      }
      resetTimer();
    };
  
    // Add event listeners to monitor user activity
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);
  
    // Start the initial timer
    resetTimer();
  
    // Clean up event listeners
    return () => {
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      clearTimeout(timer);
    };
  }, [inactive]);
  useEffect(() => {
    var program_id = record_id;
    var type = '';
    if (typeOfPage === 'RMC') {
      type = 'Reading Multiple Choice';
    } else if (typeOfPage === 'LMC') {
      type = 'Listening Multiple Choice';
    } else if (typeOfPage === 'ROL') {
      type = 'Read out Loud';
    }
    dispatch(studyActions.programQuestionInfo({ program_id, type }));
  }, [record_id, typeOfPage, dispatch]);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // Update seconds
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     } else {
  //       // If seconds reach 0, update minutes and reset seconds
  //       if (minutes > 0) {
  //         setMinutes(minutes - 1);
  //         setSeconds(59);
  //       } else {
  //         // If both minutes and seconds reach 0, clear the interval
  //         clearInterval(interval);
  //         handleTimeExpired();
  //         // You can add logic here to handle what happens when the timer reaches 0
  //         console.log('Timer expired!');
  //       }
  //     }
  //   }, 1000);

  //   // Cleanup the interval on component unmount
  //   return () => clearInterval(interval);
  // }, [minutes, seconds]);
  const handleOptionSelect = (selected) => {
   
    setSelectedOption(selected);
    //handleNextQuestion(selected);
   // setCurrentQuestion(selected);
    setUserAnswers(prevAnswers => [...prevAnswers, selected]);
  };
  const formatTime = (time) => {

    const hours = Math.floor(time / 3600000); // 1 hour = 3600000 milliseconds
    const minutes = Math.floor((time % 3600000) / 60000); // 1 minute = 60000 milliseconds
    const seconds = Math.floor((time % 60000) / 1000); // 1 second = 1000 milliseconds
    const milliseconds = Math.floor((time % 1000) / 10);
  
    return {
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0')
    };
  };
  useEffect(() => {
    start(); // Start the stopwatch when the component mounts
    // Clean-up function to clear the interval when the component unmounts
    return () => clearInterval(intervalRef.current);
  }, []); // Empty dependency array ensures this effect runs only once

  const start = () => {
    if (!isRunning) {
      setIsRunning(true);
      const currentTime = Date.now();
      const timeElapsed = currentTime - time;
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1000); // Increment by 1 second (1000 milliseconds)
      }, 1000);
    }
  };
  const { hours, minutes, seconds } =  formatTime(time);
  const handleSubmit = (selected) => {
    //setSelectedOption(selected);
    handleNextQuestion(selected);
    setQuestionAnswered(prevCount => prevCount + 1);
    //setUserAnswers(prevAnswers => [...prevAnswers, selected]);
  };


  const handleNextQuestion = (selectedOption) => {
    // if (timer) {
    //   clearTimeout(timer);
    // }

    if (selectedOption === quizData[currentQuestion].answer) {
     
      setScore(score + 1);
      setCorrectAnswered(correctAnswered + 1);
    }

    setSelectedOption(null);

    if (currentQuestion + 1 < quizData.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
    
          // Move to the next group if available
    if (currentGroup < totalGroups) {
      const nextGroupIndex = currentGroup;
      const nextGroupData = passage.data.data[nextGroupIndex].quiz_questions;
      setGroupText(passage.data.data[nextGroupIndex].text);
     
      setQuizData(nextGroupData);
      setquestionsPerGroup(nextGroupData.length);
      setCurrentGroup(currentGroup + 1);
      setCurrentQuestion(0); // Start from the first question of the new group
      setQuizCompleted(false);
      if (selectedOption === quizData[currentQuestion].answer) {
        setScore(score + 1);
        setCorrectAnswered(correctAnswered + 1);
      }
    } else {
      // Quiz is completed when all groups and questions are done
      console.log("Quiz completed");
      setQuizCompleted(true);
      return;
    }
    }


// // Set currentQuestion to the new value
// setCurrentQuestion(currentQuestion + 1);

    // setTimeRemaining(location.state.vocbsetting.time);
  };



  const handleKeyPress = (event) => {
    const selectedNumber = Number(event.key);
    if (!isNaN(selectedNumber) && selectedNumber > 0 && selectedNumber <= quizData[currentQuestion].options.length) {
      const selectedOption = quizData[currentQuestion].options[selectedNumber - 1];
      handleOptionSelect(selectedOption);
    }
  };


// Format the time values to ensure they have leading zeros
const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  useEffect(() => {
    try {
      if (quizData.length === 0) {
        // if (!location.state) {
        //   window.location.href = '/';
        // }
        if(passage && passage.data){
          var totalGroups = passage.data.data.length;
           // Set the totalGroups state
          setTotalGroups(totalGroups);
          // Assuming library.data is an array of groups
        const groupData = passage.data.data;

        // Set quizData for Group 1 initially
        setQuizData(groupData[0].quiz_questions);
        setquestionsPerGroup(groupData[0].quiz_questions.length); // Add this line to set questionsPerGroup
        setGroupText(groupData[0].text);
        // Count total questions for both groups
const totalQuestions = groupData.reduce((total, group) => total + group.quiz_questions.length, 0);

setTotalQuestions(totalQuestions);
// Concatenate all quiz_questions arrays into a single array
const allQuestions = [].concat(...groupData.map(group => group.quiz_questions));

setAllQuestions(allQuestions);
         // setQuizData(library.data.quiz_questions);
        }
        
        setquizCategory("Reading Multiple Choice");

      }


    }
    catch (error) {
      console.error("An error occurred:", error);
    }
  }, [passage]);



  useEffect(() => {

    window.addEventListener('keydown', handleKeyPress);


    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (quizCompleted === true) {

          const result = []
          {
            allQuestions.map((question, index) => (
              result.push({ question: question.question, answer: question.answer, options:question.options, useranswer: (index < userAnswers.length) ? userAnswers[index] : "Not answered" })
            ))
          }
    

          if (student) {
             const scoreprecentage=((score / allQuestions.length) * 100);
            const option = {
             // category: location.state.vocabulary.id,
             total_score:correctAnswered,
             Studentid: student.user.student_id,
             type: "Test",
             test_id:test_id,
             program_id:record_id,
             result: JSON.stringify(result),
             sub_type:"Reading Multiple Choice",
             total_questions:allQuestions.length,
             question_answered: questionAnswered,
             percentage: scoreprecentage,
             correct_answered: correctAnswered,

            }
            var resultOpt = {
              result:JSON.stringify(result)
            }
            const resultRes = await axios.post(
              "https://api.webtracktechnologies.com.au:8002/library/grammer/checkresult",
              resultOpt,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  // Add other headers if needed
                },
              }
            );
            if(resultRes){

              setResult(resultRes);
              const response = await axios.post(
                "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
                option,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                  },
                }
              );
              console.log(response);
            }
     
            //dispatch(homeworkActions.savevocabresult(option));
          }
    
        }
      } catch (error) {
        console.error('Error fetching homework data:', error);
      }
    };
  
    fetchData();
  }, [dispatch, quizCompleted, quizCategory, score, quizData, userAnswers]);
  console.log(userAnswers,'userAnswers',resultData);
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }


  const renderOptions = () => (
    <>
      {/* <p>{quizData[currentQuestion].question}</p> */}
      {quizData[currentQuestion].options.map((option, index) => (
        <div key={index} className='study-test-option quiz-options'>
          <label className='option-index'>{index + 1}</label>
          <label className={`container ${selectedOption === option ? 'selected' : ''}`} htmlFor={option}>
            {option}
            <input
              type="radio"
              id={option}
              name="options"
              value={option}
              checked={selectedOption === option}
              onChange={() => handleOptionSelect(option)}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      ))}
        <button className='quiz-button' onClick={() => handleSubmit(selectedOption)}>Submit</button>
    </>
  );
  
  // const handleTimeExpired =  () => {
  //   setQuizCompleted(true);
  // };
  const handleRetakeQuiz = () => {

    const shuffledQuizData = shuffleArray([...quizData]);

    setQuizCompleted(false);
    setQuizAlready(false)
    setCurrentQuestion(0);
    setSelectedOption(null);
    setScore(0);
    //setQuizData(shuffledQuizData);
   // setQuizData(library.data[currentGroup - 1].quiz_questions);
   //var totalGroups = library.data.length;
   // Set the totalGroups state
//   setTotalGroups(totalGroups);
//   setCurrentGroup(1);
//    const groupData = library.data;

//         // Set quizData for Group 1 initially
//         setQuizData(groupData[0].quiz_questions);
//         setquestionsPerGroup(groupData[0].quiz_questions.length); // Add this line to set questionsPerGroup
//         setGroupText(groupData[0].text);
//         // Count total questions for both groups
// const totalQuestions = groupData.reduce((total, group) => total + group.quiz_questions.length, 0);

// setTotalQuestions(totalQuestions);
// // Concatenate all quiz_questions arrays into a single array
// const allQuestions = [].concat(...groupData.map(group => group.quiz_questions));

// setAllQuestions(allQuestions);
  };
var passageLong = (quizData.length > 0 && quizData[currentQuestion].passage) ? quizData[currentQuestion].passage.replace(/\r\n/g, '<br/>') : '';
  const renderQuizContent = () => {
   
    if (currentQuestion < quizData.length && quizCompleted === false ) {
      const totalTime = 10;
      const remainingPercentage = (timeRemaining / totalTime) * 100;
      const precent = (100 - ((currentQuestion - score) / quizData.length * 100)).toFixed(2);
      return (
        <div className="stopwatch-container">
        <h2>{quizCategory}</h2>
        <Dots totalGroups={totalGroups} currentGroup={currentGroup} currentQuestion={currentQuestion} questionsPerGroup={questionsPerGroup} />
        <div className="row">
        {(myState !== 'incorrectAnswer') ?
          <>
        <div className="col-md-5">

    <div className="question-box">

      <p dangerouslySetInnerHTML={{ __html: passageLong }} />
    </div>
  </div>
          <div className="col-md-5">
           
              <h6>{quizData[currentQuestion].question}</h6>
            
              <div className="study-test question-area">
              {renderOptions()}
               
              </div>
          </div>
          </> 
          :''}
          {/* <div className="col-md-2">
        <div className="stopwatch-box">
          <h6 className="g-bg">Questions Answered</h6>
          <p className="result">{questionAnswered}</p>
          <h6 className="b-bg">Study Time</h6>
          {(!inactive) ?     
          
          <div className="watch">
            <p className="hr">
              <span>{hours}</span>
              <span className="title">HR</span>
            </p>
            <p className="min">
              <span>{minutes}</span>
              <span className="title">MIN</span>
            </p>
            <p className="sec">
              <span>{seconds}</span>
              <span className="title">SEC</span>
            </p>
          </div> : <div className="paused-text">PAUSED</div>}
      
        </div>
        <div className="stopwatch-box">
          <h6 className="g-bg">My Score</h6>
          <p className="result">{score}</p>
        </div>
      </div> */}
        </div>
      </div>
      );
    }
    if(quizCompleted === true) {
      const percentage = ((score / totalQuestions) * 100);
   const remaining=100-percentage;
 const data = [{ name: 'Your score', score: percentage, total: 100  }];
      return (

        <div>
          <h2>Quiz Result!</h2>


          {/*<BarChart
            xAxis={[{ scaleType: 'band', data: ['Your score'] }]}
            series={[{ data: [percentage], color: "#3a58e9b8", stack: 'total' }, { data: [100 - percentage], color: "#992525", stack: 'total' }]}
            width={500}
            height={300}

          />*/} 

        <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
  <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart>

           <span className='score'>
    <div className="score-wrap">
        <span className="stars-active" style={{ width:`${percentage}%`}}>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
<span className="stars-inactive">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
</div>
</span>
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {totalQuestions}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Question</th>
                  <th></th>
                  <th>O X</th>
                </tr>
              </thead>
              <tbody>
                {(resultData && resultData.data) ? resultData.data.data.questions.map((question, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{question.question}</td>
                    <td>
                        {question.combined_result.map((option, index) => (
                          <div key={index} className='quiz-options'>
                            <label className='option-index'>{index + 1}</label>
                            <label className="container" htmlFor={option}>
                              {ReactHtmlParser(option)}
                              <input
                                type="radio"
                                id={option}
                                name="options"
                                value={option}
                                checked={selectedOption === option}
                                onChange={() => handleOptionSelect(option)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        ))}
                    </td>
                    <th>{((question.answer == userAnswers[index]) || userAnswers[index] == "Not answered") ? 'O' : 'X'}</th>
                  </tr>
                )) : ''}
              </tbody>
            </Table>
          </div>

          <a href={`/study/view-test-details/${test_id}`} className='quiz-button'>Back To Test</a>
        </div>
      );
    }
  };



  return (
    <div className="study-test-container quiz-container">
      <div className='quizs-container'> 
     
      {renderQuizContent()}
    

       </div>

    </div>
  );
}
const Dots = ({ totalGroups, currentGroup,currentQuestion, questionsPerGroup }) => {
  const dots = [];

  for (let group = 1; group <= totalGroups; group++) {
    for (let question = 0; question < questionsPerGroup; question++) {
      const isCurrentQuestion = group === currentGroup && question === currentQuestion;
     // console.log(isCurrentQuestion,'isCurrentQuestion',currentQuestion,group,currentGroup,question);
      dots.push(
        <div
          key={`${group}-${question}`}
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: isCurrentQuestion ? '#007bff' :  '#ccc',
            margin: '0 5px',
          }}
        />
      );
    }

    // Add space between groups
    if (group < totalGroups) {
      dots.push(<div key={`space-${group}`} style={{ width: '20px', margin: '0' }} />);
    }

  }
  //console.log('total grop',totalGroups, questionsPerGroup);
  return (
    <div style={{ display: 'flex', justifyContent: 'center',marginBottom: '20px' }}>
      {dots}
    </div>
  );
};
