import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { studyActions } from "_store";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { Table } from "react-bootstrap";

const ScrambleSentences = ({
  sentences,
  sentencesForHint,
  program_id,
  type,
  q_start,
  passage,
}) => {
  const [displayedSentenceIndices, setDisplayedSentenceIndices] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(null);
  const [scrambledSentence, setScrambledSentence] = useState("");
  const [showTextArea, setShowTextArea] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [showNextButton, setShowNextButton] = useState(false);
  const [showFinishButton, setShowFinishButton] = useState(false);
  const [remainingSentence, setRemainingSentence] = useState(1);
  const [linkList, setLinkList] = useState("");
  const [initialTransformSet, setInitialTransformSet] = useState(false);
  const [inputText, setInputText] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [answerDisplayHint, setAnswerDisplayHint] = useState(false);
  const [heartIconIndex, setHeartIconIndex] = useState(0);
  const audioRef = useRef();
  const [audioSrc, setAudio] = useState();
  const [categoryTypeAnswers, setCategoryTypeAnswers] = useState([]);
  const [incorrectAnswer, setInCorrectAnswer] = useState(0);
  // useEffect(() => {
  //   // Determine the initial transform values when linkList changes
  //   const wordsArray = scrambledSentence.split(' ');
  //   console.log(wordsArray,'get words');
  //   wordsArray.map((word, index) => ({
  //     text: `${word} `,
  //     transform: index % 2 === 0 ? 10 : -10,
  //   }));
  //   setLinkList(wordsArray);
  // }, [scrambledSentence]);

  const student = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (linkList.length > 0 && !initialTransformSet) {
      const updatedList = linkList.map((link, index) => {
        return {
          ...link,
          transform: index % 2 === 0 ? 10 : -10,
        };
      });
      setLinkList(updatedList);
      setScrambledSentence(updatedList);
      setInitialTransformSet(true);
    }
  }, [linkList, initialTransformSet]);

  const getNextTransform = () => {
    if (linkList.length === 0) {
      return 10; // Start with 10 if the list is empty
    }
    const lastTransform = linkList[linkList.length - 1].transform;
    return lastTransform === 10 ? -10 : 10;
  };

  const handleLinkClick = (index) => {
    //prevText + ' ' + Object.values(linkList[index]).filter(value => typeof value === 'string').join('')
    setInputText(
      (prevText) =>
        prevText +
        " " +
        Object.values(linkList[index])
          .filter((value) => typeof value === "string")
          .join("")
    );
    setLinkList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleWordClick = (word) => {
    const nextTransform = getNextTransform();
    setLinkList((prevList) => [
      ...prevList,
      { text: word, transform: nextTransform },
    ]);
    setInputText((prevText) => prevText.replace(word, "").trim());
  };

  const startScramble = () => {
    const remainingIndices = sentences
      .map((_, index) => index)
      .filter((index) => !displayedSentenceIndices.includes(index));

    if (remainingIndices.length > 0) {
      const randomIndex =
        remainingIndices[Math.floor(Math.random() * remainingIndices.length)];
      setDisplayedSentenceIndices([...displayedSentenceIndices, randomIndex]);
      setCurrentSentenceIndex(randomIndex);
      const randomSentence = sentences[randomIndex];
console.log(randomSentence,'get sentence info');
      const audioSrc =
        passage && passage.data
          ? passage.data[randomIndex].audio_file_link
          : "";

      setAudio(audioSrc);
      setScrambledSentence(scrambleSentence(randomSentence));
      const wordsArray = scrambleSentence(randomSentence).split(" ");
      wordsArray.map((word, index) => ({
        text: `${word} `,
        transform: null,
      }));
      console.log(wordsArray, "get array", randomSentence);
      setLinkList(wordsArray);
      setShowTextArea(true);
      setShowNextButton(true);
      setShowFinishButton(false);
    } else {
      setShowNextButton(false);
      setShowFinishButton(true);
    }
  };

  const scrambleSentence = (sentence) => {
    // Split the sentence into an array of words
    const words = sentence.split(" ");

    // Unarrange the words by shuffling them
    for (let i = words.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [words[i], words[j]] = [words[j], words[i]];
    }

    // Join the words back into a sentence
    const unarrangedSentence = words.join(" ");

    return unarrangedSentence;
  };

  const handleNext = () => {
    console.log(inputText.length, "inputText");
    const userAnswer = {
      question: scrambledSentence,
      answer: sentences[currentSentenceIndex],
      useranswer: inputText.length > 0 ? inputText.trim() : "",
    };
    setHeartIconIndex(heartIconIndex + 1);
    setUserAnswers((prevAnswers) => [...prevAnswers, userAnswer]);
    const categoryType = sentencesForHint[currentSentenceIndex].categorytype;
    if (inputText.length > 0) {
      if (inputText.trim() == sentences[currentSentenceIndex]) {
        setCorrectAnswer(correctAnswer + 1);
        setCategoryTypeAnswers({
          [categoryType]: {
            correct:correctAnswer + 1,
            incorrect: incorrectAnswer,
          },
      });
       // console.log(sentencesForHint[currentSentenceIndex],'get for category type');return false;
      }else{
        setInCorrectAnswer(incorrectAnswer + 1);
        setCategoryTypeAnswers({
          [categoryType]: {
            correct: correctAnswer,
            incorrect: incorrectAnswer + 1,
          },
        });
      }
    } else {
      setCorrectAnswer(correctAnswer);
      
    }

    const remainingIndices = sentences
      .map((_, index) => index)
      .filter((index) => !displayedSentenceIndices.includes(index));
    //setRemainingSentence(remainingIndices.length);
    if (remainingIndices.length > 0) {
      setRemainingSentence(remainingSentence + 1);
    }

    // console.log(remainingIndices.length);
    if (remainingIndices.length > 0) {
      const randomIndex =
        remainingIndices[Math.floor(Math.random() * remainingIndices.length)];
      setDisplayedSentenceIndices([...displayedSentenceIndices, randomIndex]);
      setCurrentSentenceIndex(randomIndex);
      const randomSentence = sentences[randomIndex];
      setAudio(passage.data[randomIndex].audio_file_link);
      setScrambledSentence(scrambleSentence(randomSentence));
      const wordsArray = scrambleSentence(randomSentence).split(" ");
      wordsArray.map((word, index) => ({
        text: `${word} `,
        transform: null,
      }));
      //console.log(wordsArray,'get array',randomSentence);
      setLinkList(wordsArray);
      setUserInput("");
      setInputText("");
      setShowNextButton(true);
      setShowFinishButton(false);
    } else {
      setShowNextButton(false);
      setShowFinishButton(true);
    }
  };
console.log(categoryTypeAnswers,'get categoryTypeAnswers',incorrectAnswer, correctAnswer);
  // const handleInputChange = (event) => {alert(event.target.value);
  //   setUserInput(event.target.value);
  //   const nextTransform = getNextTransform();
  //   setLinkList((prevList) => [
  //     ...prevList,
  //     { text: event.target.value, transform: nextTransform },
  //   ]);
  //  // setShowNextButton(event.target.value === sentences[currentSentenceIndex]);
  // };
  const handleInputChange = (event) => {
    event.preventDefault(); // Prevent default behavior of contentEditable div
    const newText = event.target.innerText; // Extract updated text
    setInputText(newText); // Update inputText state
  };

  const handleWordClick1 = (word) => {
    // Find the index of the clicked word in the inputText
    const wordIndex = inputText.indexOf(word);
    if (wordIndex !== -1) {
      // Extract the word from the inputText
      const remainingText =
        inputText.slice(0, wordIndex) +
        inputText.slice(wordIndex + word.length);
      setInputText(remainingText.trim()); // Update inputText without the clicked word

      // Add the clicked word back to the linkList
      const nextTransform = getNextTransform();
      setLinkList((prevList) => [
        ...prevList,
        { text: word, transform: nextTransform },
      ]);
    }
  };

  const handleFinish = async () => {
    // Handle finish button click
    console.log("Finished!");
    setShowResult(true);
    var q_end = parseInt(q_start) + 4;
    const scoreprecentage = (correctAnswer / sentences.length) * 100;
    if (type == "Listening") {
      var sub_type = "Listening: Sentence Unscramble";
    } else {
      var sub_type = "Grammar: Sentence Unscramble (English)";
    }
    const option = {
      // category: location.state.vocabulary.id,
      total_score: correctAnswer,
      Studentid: student.user.student_id,
      Classid: student.user.Student.class_name,
      type: "Study",
      program_id: program_id,
      result: JSON.stringify(userAnswers),
      sub_type: sub_type,
      vocab_q_start: q_start,
      vocab_q_end: q_end,
      total_questions: sentences.length,
      percentage: scoreprecentage,
      correct_answered: correctAnswer,
      category_result:JSON.stringify(categoryTypeAnswers),
    };
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
      option,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          // Add other headers if needed
        },
      }
    );
  };

  const renderQuestion = (question) => {
    if (Array.isArray(question)) {
      // If question is an array, transform it into a string based on the transformation values
      return question
        .map((wordObj) => {
          const wordArray = Object.values(wordObj).slice(0, -1); // Get the values of the object excluding the 'transform' property
          const transformedWord = wordArray
            .map((word, index) => {
              const transformValue = wordObj[`transform${index}`] || 0; // Get the transformation value for the word
              return word + (transformValue > 0 ? " " : ""); // Add a space before the word if the transformation value is positive
            })
            .join("");
          return transformedWord;
        })
        .join(" ");
    } else {
      return question; // If question is a string, return it as is
    }
  };
  const handleHeartClick = (index) => {
    const allHeartIcons = document.querySelectorAll(".hear-section .fa-heart");

    // Find the next heart icon that is visible and has no 'display: none' style
    const nextVisibleHeartIcon = Array.from(allHeartIcons).find(
      (heartIcon, index) => {
        return (
          index >= currentSentenceIndex && heartIcon.style.display !== "none"
        );
      }
    );

    // If a visible heart icon without 'display: none' style is found, hide it
    if (nextVisibleHeartIcon) {
      nextVisibleHeartIcon.style.display = "none";
    }
    setAnswerDisplayHint(true);
    setHeartIconIndex(heartIconIndex + 1);
    // Recursive function to toggle answer display hint three times
    const blink = (count) => {
      if (count === 4) {
        // After blinking three times, hide the answer display section
        setTimeout(() => {
          setAnswerDisplayHint(false);
          setHeartIconIndex(null); // Hide the heart icon
        }, 1000);

        return;
      }
      // Toggle answer display hint
      setTimeout(() => {
        setAnswerDisplayHint((prev) => !prev);
        blink(count + 1); // Call the function recursively for the next blink
      }, 1000); // Toggle every half second (for a blink effect)
    };

    // Start blinking
    blink(0);
    //setHeartIconIndex(index);
  };
  const handleSpeakerClick = () => {
    // Play the audio when the speaker icon is clicked
    // Update audioRef with the current audioSrc
    audioRef.current.src = audioSrc;

    // Play the audio when the speaker icon is clicked
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  console.log(sentences[currentSentenceIndex], "get",sentencesForHint);
  const percentage = (correctAnswer / sentences.length) * 100;
  const remaining = 100 - percentage;
  const data = [{ name: "Your score", score: percentage, total: 100 }];
  return (
    <div className="sentences-list">
      {!showTextArea && !showResult
        ? sentences.map((sentence, index) => (
            <p key={index}>{`${index + 1}. ${sentence}`}</p>
          ))
        : ""}
      {!showTextArea && !showResult ? (
        <button className="btn btn-primary" onClick={startScramble}>
          Start
        </button>
      ) : (
        ""
      )}

      {showTextArea && !showResult && (
        <div className="answer-section">
          <p className="correct-answer">Correct Answers : {correctAnswer}</p>
          <p className="remaining-sentence">
            {remainingSentence} of {sentences.length}
          </p>
          <br />
          <p className="hear-section">
            <i
              className="fa fa-heart"
              data-index={heartIconIndex}
              onClick={() => handleHeartClick(heartIconIndex)}
            ></i>
            &nbsp;
            <i
              className="fa fa-heart"
              data-index={heartIconIndex}
              onClick={() => handleHeartClick(heartIconIndex)}
            ></i>
            &nbsp;
            <i
              className="fa fa-heart"
              data-index={heartIconIndex}
              onClick={() => handleHeartClick(heartIconIndex)}
            ></i>
            &nbsp;
            <i
              className="fa fa-heart"
              data-index={heartIconIndex}
              onClick={() => handleHeartClick(heartIconIndex)}
            ></i>
            &nbsp;
            <i
              className="fa fa-heart"
              data-index={heartIconIndex}
              onClick={() => handleHeartClick(heartIconIndex)}
            ></i>
          </p>
          {answerDisplayHint ? (
            <p
              className="answer-display-section"
              style={{ "text-align": "left", color: "green" }}
            >
              {sentencesForHint[currentSentenceIndex].korean}{" "}
              {sentencesForHint[currentSentenceIndex].english}
            </p>
          ) : (
            ""
          )}
          {type == "Listening" ? (
            <>
              <span
                role="img"
                aria-label="speaker"
                style={{ fontSize: "40px", cursor: "pointer" }}
                onClick={handleSpeakerClick}
              >
                🔊
              </span>
              <audio
                ref={audioRef}
                style={{ display: "none" }}
                autoPlay
                controls
              >
                <source src={audioSrc} type="audio/mp3" />
                Your browser does not support the audio tag.
              </audio>
            </>
          ) : (
            ""
          )}

          <ul style={{ display: "inline", listStyle: "none", padding: 0 }}>
            {linkList.map((link, index) => (
              <li
                key={index}
                style={{ display: "inline", marginRight: "5px" }}
                onClick={() => handleLinkClick(index)}
              >
                <a
                  href="javascript:void(0)"
                  style={{
                    transform: `rotate(${link.transform}deg)`,
                    display: "inline-block",
                  }}
                >
                  {Object.values(link)
                    .filter((value) => typeof value === "string")
                    .join("")}
                </a>
              </li>
            ))}
          </ul>

          <div
            contentEditable="false"
            onBlur={handleInputChange}
            style={{
              border: "1px solid black",
              padding: "5px",
              minHeight: "50px",
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }}
          >
            {typeof inputText === "string" &&
              inputText.split(" ").map((word, index) => (
                <span key={index} onClick={() => handleWordClick1(word)}>
                  {word}&nbsp;
                </span>
              ))}
          </div>

          {/* <textarea value={inputText} onClick={handleInputChange} /> */}
          {showNextButton && <button onClick={handleNext}>Next</button>}
          {showFinishButton && <button onClick={handleFinish}>Finish</button>}
        </div>
      )}
      {showResult ? (
        <div>
          <h2>Quiz Result!</h2>

          {/*<BarChart
            xAxis={[{ scaleType: 'band', data: ['Your score'] }]}
            series={[{ data: [percentage], color: "#3a58e9b8", stack: 'total' }, { data: [100 - percentage], color: "#992525", stack: 'total' }]}
            width={500}
            height={300}

          />*/}

          <BarChart width={500} height={300} data={data}>
            <XAxis dataKey="name" />
            <YAxis domain={[0, 100]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200} />
            <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200} />
          </BarChart>

          <span className="score">
            <div className="score-wrap">
              <span
                className="stars-active"
                style={{ width: `${percentage}%` }}
              >
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
              </span>
              <span className="stars-inactive">
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i>
              </span>
            </div>
          </span>
          <Table style={{ margin: "20px", width: "95%" }}>
            <thead>
              <th>Your Raw Score</th>
              <th>
                {correctAnswer} / {sentences.length}
              </th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{percentage.toFixed(2)}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>OX</th>
                  <th></th>
                  <th>Sentence Scramble</th>
                  <th>Correct Answer</th>
                  <th>Your Answer</th>
                </tr>
              </thead>
              <tbody>
                {userAnswers.length > 0
                  ? userAnswers.map((answer, index) => (
                      <tr key={index}>
                        <td>
                          {answer.answer == answer.useranswer ? "O" : "X"}
                        </td>
                        <td>{index + 1}</td>
                        <td>{renderQuestion(answer.question)}</td>
                        {/* <td>{(index == 0) ? Object.values(answer.question).filter(value => typeof value === 'string').join('') : answer.question}</td> */}
                        <td>{answer.answer}</td>
                        <td>{answer.useranswer}</td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export function StudyForSentenceUnscramble() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { program_id, type, q_start } = useParams();
  const { passage } = useSelector((x) => x.passage);

  const sentences =
    passage && passage.data ? passage.data.map((word) => word.questions) : [];
  const sentencesForAnswer =
    passage && passage.data
      ? passage.data.map((word) => ({
          english: word.questions,
          korean: word.korean_grammer,
          categorytype: word.categorytype,
        }))
      : [];

  // useEffect(() => {
  //   if (passage && passage.data) {

  //  const sentenceQuestions = passage.data.map(word => word.questions);
  //  setSentences(sentenceQuestions);
  //   }
  // }, [passage]);
  console.log(passage, "get user", sentences);

  useEffect(() => {
    dispatch(
      studyActions.getSubprogramsForStudyGrammar({ program_id, q_start })
    );
  }, []);

  // Function to scramble the sentence
  const scrambleSentence = (sentence) => {
    const words = sentence.split(" ");
    const scrambledWords = words.map((word) => {
      const characters = word.split("");
      characters.sort(() => Math.random() - 0.5);
      return characters.join("");
    });
    return scrambledWords.join(" ");
  };

  return (
    <section>
      <div className="test">
        {(type == 'Listening') ? <h1 className="sentence-title">
          Listening: Sentence Unscramble (English)
        </h1> : <h1 className="sentence-title">
          Grammar: Sentence Unscramble (English)
        </h1>}
        
        <ScrambleSentences
          sentences={sentences}
          sentencesForHint={sentencesForAnswer}
          program_id={program_id}
          type={type}
          q_start={q_start}
          passage={passage}
        />
        <div className="mt-4"></div>
      </div>
    </section>
  );
}

export default StudyForSentenceUnscramble;
