import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}

function createReducers() {
    return {
        logout
    };

    function logout(state) {
        try {
            console.log('Attempting to log out...');
            state.user = null;  // Mutate the state to remove the user
            state.student = null;
            state.password = null;
            state.bookHomework = null;
            state.classes = null;
            state.email = null;
            state.homework = null;
            state.library = null;
            state.passage = null;
            localStorage.removeItem('user');  // Remove user data from localStorage
            localStorage.removeItem('student');  // Remove user data from localStorage
            localStorage.removeItem('password');  // Remove user data from localStorage
            localStorage.removeItem('bookHomework');  // Remove user data from localStorage
            localStorage.removeItem('classes');  // Remove user data from localStorage
            localStorage.removeItem('email');  // Remove user data from localStorage
            localStorage.removeItem('homework');  // Remove user data from localStorage
            localStorage.removeItem('library');  // Remove user data from localStorage
            localStorage.removeItem('passage');  // Remove user data from localStorage
            localStorage.removeItem('token'); 
            window.location.href = '/login';  // Redirect to the login page
            return;
        } catch (error) {
            // Log the error to the console, but do not display it on the screen
            console.error('An error occurred during logout:', error);
        }
    //    state.user = null;
    //     localStorage.removeItem('user');
    //     //const { from } = history.location.state || { from: { pathname: '/homework' } };
    //     window.location.href = '/login';
    //     return false;
       
    }
}

function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        login: login(),
        changepassword : changepassword(),
    };    

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }) => await fetchWrapper.post(`${baseUrl}/auth/login`, { email, password })
        );
    }

    function changepassword() {
        return createAsyncThunk(
            `${name}/auth`,
            async ({ userid, newPassword, oldPassword }) => await fetchWrapper.post(`${baseUrl}/auth/changepassword`, { userid, newPassword, oldPassword })
        );
    }
}

function createExtraReducers() {
    return {
        ...login(),
        ...changepassword(),
    };

    function login() {
        var { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
                console.log(user,'get logged user');
                localStorage.setItem('token', user.token);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
               
                state.user = user;
                // toast.success('You are logged in successfully.', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     });
                // get return url from location state or default to home page
                // const { from } = history.location.state || { from: { pathname: '/homework' } };
                // history.navigate(from);

            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function changepassword() {
        var { pending, fulfilled, rejected } = extraActions.changepassword;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
              
        
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

  
}
