import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { vocabularyquizActions, quizsettingActions } from "_store";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCog } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'



export { ChallengeList };
function ChallengeList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSettings, setShowSettings] = useState(false);
  const [questions, setQuestions] = useState("20");
  const [ifTime, setIfTime] = useState("yes");
  const [time, setTime] = useState("15");
  const [firstsettings, setfirstsettings] = useState({"item":0});
  const [quizselected, setquizselected] = useState("");
  const { vocabularyquiz, vocabularyquizLoading } = useSelector((x) => x.vocabularyquiz);
  const { quizsetting, quizsettingLoading } = useSelector((x) => x.quizsetting);
  const user = JSON.parse(localStorage.getItem('user'));

  const handleSaveSettings =async () => {

    if (user) {
      const stdid = user.user.student_id;
      if (stdid) {
        const formData = {
          questions, ifTime, time
        };
        formData.student_id = stdid;
        formData.vocabulary_id = quizselected;
        console.log(formData)
        setQuestions("");
        setIfTime("no");
        setTime("");
        if (questions) {
         const savesetting=await dispatch(quizsettingActions.savequizsetting(formData));
         if(firstsettings.item!=0){
          handleGoToQuizOptions(firstsettings.item)
         }
        }

      }else{
        console.log('not student');
      }

    }


    setShowSettings(false);
  };


  const handlequizsetting = (id) => {
    

    setQuestions("20");
    setIfTime("yes");
    setTime("15");

    setquizselected(id);
    setShowSettings(true);
    if (user && user.user.student_id) {
      const studentId = user.user.student_id;
      console.log(studentId, id, "id")
      dispatch(quizsettingActions.getquizsetting({ student: studentId, category: id }));
      // console.log(quizsetting,"q")
      if (quizsetting) {


      }
    }
  };





  useEffect(() => {
    if (!vocabularyquiz) {
        if (user && user.user.student_id) {
            const studentId = user.user.student_id;
            dispatch(vocabularyquizActions.getVocabularyQuiz(studentId));
       }else{
         dispatch(vocabularyquizActions.getVocabularyQuiz());  
       }
      
    }

  }, [dispatch, vocabularyquiz]);

  useEffect(() => {
    if (quizsetting) {
      console.log(quizsetting)
      setQuestions(quizsetting.questions);
      setIfTime((quizsetting.istime == 1) ? "yes" : "no");
      setTime(quizsetting.time)

    }else{
      console.log("nosettings")
    }

  }, [dispatch, quizsetting]);

  const handleGoToQuizOptions = async (item) => {

    const studentId = user?.user?.student_id;

    if (studentId) {
      const quizSettingsResponse = await dispatch(
        quizsettingActions.getquizsetting({ student: studentId, category: item.id })
      );

      if (quizSettingsResponse && quizSettingsResponse.payload && quizSettingsResponse.payload.questions) {

        console.log("Navigate to the quiz");
        navigate(`/quiz`, { state: { vocabulary: item, vocabsettings: quizSettingsResponse.payload } });

        console.log(quizSettingsResponse, "qif")
      } else {
        console.log(quizSettingsResponse, "qe")
        setfirstsettings({"item":item});
        setquizselected(item.id);
        setQuestions("20");
        setIfTime("yes");
        setTime("15");
        setShowSettings(true);
      }
    }
  };


  const handleQuizSettings = (item) => {
    console.log(item, "item")
  };
var i =1;
var j=1;
  return (
    <section>
      <div className="test">
        <h2>Challenge</h2>

        <Table >
          <thead>
            <tr>
              <th colSpan={5} style={{ textAlign: "center" }}>
                Korean School
              </th>
            </tr>
          </thead>
          <tbody>
            {vocabularyquiz &&
              vocabularyquiz.data.map((item,index) =>
                item.school === "Korean School" ? (
                  <tr key={item.id}>
                    <th>{i++}.</th>
                    <td>{item.filename}</td>
                    <td>
                      <div>
                      <Link to={{ pathname: '/vocabularyQuiz' }} state={{ "vocabulary": item }}>
                        Full Test
                      </Link>
                      


                      <button className="settings-button quiz-st-btn" onClick={() => handleGoToQuizOptions(item)}>
                        Quiz
                      </button>
                      
                        {item.ChallengeMyVocab.length > 0 ? (
                           <Link 
                             to={{ pathname: '/myvocabularyQuiz' }} 
                             state={{ vocabulary: item }}
                             className="settings-button quiz-st-btn"
                           >
                             My Vocab
                           </Link>
                         ):(  <button className="settings-button quiz-st-btn" style={{width:'57px'}}> </button>
                           )}

                      <button className="settings-button quiz-st-btn" onClick={() => handlequizsetting(item.id)}>
                        <FaCog className="settings-icon" />
                      </button>
                      </div>
                    </td>
                  </tr>
                ) : null
              )}

          </tbody>
        </Table>

        <Table >
          <thead>
            <tr>
              <th colSpan={5} style={{ textAlign: "center" }}>
                US School
              </th>
            </tr>
          </thead>
          <tbody>
            {vocabularyquiz &&
              vocabularyquiz.data.map((item,index) =>
                item.school === "US School" ? (
                  <tr key={item.id}>
                    <th>{j++}.</th>
                    <td>{item.filename}</td>
                    <td>
                      <div>

                      <Link to={{ pathname: '/vocabularyQuiz' }} state={{ "vocabulary": item }}>
                        Full Test
                      </Link>



                      <button className="settings-button quiz-st-btn" onClick={() => handleGoToQuizOptions(item)}>
                        Quiz
                      </button>
                       
                          {item.ChallengeMyVocab.length > 0 ? (
                           <Link 
                             to={{ pathname: '/myvocabularyQuiz' }} 
                             state={{ vocabulary: item }}
                             className="settings-button quiz-st-btn"
                           >
                             My Vocab
                           </Link>
                         ):(  <button className="settings-button quiz-st-btn" style={{width:'57px'}}> </button>
                           )}
                       
                      <button className="settings-button quiz-st-btn" onClick={() => handlequizsetting(item.id)}>
                        <FaCog className="settings-icon" />
                      </button>

                      </div>
                    </td>
                  </tr>
                ) : null
              )}

          </tbody>
        </Table>
        <Modal show={showSettings} onHide={() => setShowSettings(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Quiz Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Number of questions</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the number of questions"
                  value={questions}
                  onChange={(e) => setQuestions(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Timer  on/off</Form.Label>
                <Form.Check
                  type="radio"
                  label="on"
                  name="ifTime"
                  id="ifTimeYes"
                  value="yes"
                  checked={ifTime === "yes"}
                  onChange={(e) => setIfTime(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  label="off"
                  name="ifTime"
                  id="ifTimeNo"
                  value="no"
                  checked={ifTime === "no"}
                  onChange={(e) => setIfTime(e.target.value)}
                />
              </Form.Group>
              {ifTime === "yes" && (
                <Form.Group>
                  <Form.Label>Enter Time Value</Form.Label>
                  <Form.Control
                    type="number"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </Form.Group>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSettings(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveSettings}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </section>

  );
}
