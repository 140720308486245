import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { dictionaryActions, myvocabActions } from "_store";
import { history } from '_helpers';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

export { EditOwnVocabList };

function EditOwnVocabList() {
  const dispatch = useDispatch();
  const { myvocab } = useSelector((x) => x.myvocab);
  const { vocab_id} = useParams();
  const [inputValue, setInputValue] = useState("");
  const today = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState(today);
  const [textareaValue, setTextareaValue] = useState("");
  const { dictionary } = useSelector((x) => x.dictionary);
  const { user: authUser } = useSelector((x) => x.auth);
  const [clickedWord, setClickedWord] = useState("");
  const [showWrongWord, setShowWrongWord] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [correctValue, setCorrectValue] = useState("");
  const [koreanWordValue, setKoreanWordValue] = useState("");
  const [flag, setFlag] = useState(true);
  const [showFlashCard, setShowFlashCard] = useState(false);
  const [showOnlyWord, setShowOnlyWord] = useState(false);
  const [index, setIndex] = useState(0);
  const [columnValue, setColumnValue] = useState(0);
  const [optionSetting, setOptionSetting] = useState("ETE");
  

  const handlePrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const handleNext = () => {
    if (myvocab && myvocab.data) {
      if (index < myvocab.data.words.length - 1) {
        setIndex(index + 1);
      }
    }
  };

  const handleRead = () => {
    const synth = window.speechSynthesis;
    if (myvocab && myvocab.data) {
      const utterance = new SpeechSynthesisUtterance(
        myvocab.data.words[index].word + myvocab.data.words[index].wordmean
      );
      synth.speak(utterance);
    }
  };
  console.log(dictionary, "get dictionary");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  const handleSearch = () => {
    // Handle form submission here
    console.log("Input value:", inputValue);
    console.log("Selected date:", selectedDate);
   
      if (textareaValue) {
        const text = textareaValue;
        const wordsTo = text.split(", ");

        const smallCapsWords = wordsTo.map((word) => {
          const lowercaseWord = word.toLowerCase();
          return lowercaseWord;
        });
        const caps = smallCapsWords.join(", ");

        const words = caps.split(",").map((word) => word.trim());

        dispatch(dictionaryActions.searchDictionaryWord({ words }));
      } else {
        alert("Add words for search.");
      }
    
  };
  const sendWrongAnswerToAdmin = (word) => {
    // Handle form submission here
    setClickedWord(word);
    setShowWrongWord(true);
    setFlag(false);
  };
  const handleWrongWordCancel = () => {
    // Handle form submission here
    setShowWrongWord(false);
  };

  const handleWrongAnswerSubmit = async () => {
    // Handle form submission here
    console.log("Input value:", correctValue);

    const matchingObject =
    myvocab && myvocab.data
        ? myvocab.data.words.find((item) => item.word === clickedWord)
        : {};
    //console.log('Selected date:', matchingObject);return false;
    const option = {
      word: clickedWord,
      newwordmeaning: correctValue,
      newkoreanword: koreanWordValue,
      oldkoreanword: matchingObject.koreanword,
      oldwordmeaning: matchingObject.meaning,
      teacher_id:authUser.user.teacher_id,
    };
    // console.log(option, "opt")
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/dictonary/reportword",
      option,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          // Add other headers if needed
        },
      }
    );

    if (response) {
      toast.success(
        "Submitted meaning/korean word successfully.It will be review and update by admin",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      setShowWrongWord(false);
    }
  };
  const handleSubmit = async () => {
    // Handle form submission here
    console.log("Input value:", inputValue);
    console.log("Selected date:", selectedDate);
    if (inputValue) {
    if (myvocab && myvocab.data) {
      const words =
      myvocab && myvocab.data ? JSON.stringify(myvocab.data.words) : [];
      var vocabname = inputValue;
      if(authUser.user.teacher_id){
        var teacher_id = authUser.user.teacher_id;
      }else{
        var student_id = authUser.user.student_id;
      }
      const option = {
        words: words,
        vocabname: vocabname,
        teacher_id:teacher_id,
        student_id:student_id,
        vocab_id:vocab_id
      }
      const response1 = await axios.put(
        "https://api.webtracktechnologies.com.au:8002/myvocab/edit/"+vocab_id,
        option,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            // Add other headers if needed
          },
        }
      );
      if(response1){
        toast.success('Vocab List updated successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

        window.location.href='/dictionary/MyOwnVocabQuizList';
      }

    } else {
      alert("No words to create new vocab.");
    }
  }else {
    alert("Please enter the vocab name.");
  }
  };
  const getRadioValue = (text) => {
    // Handle form submission here
    setRadioValue(text);
  };
  const getRadioValueForWords = (text) => {
    // Handle form submission here
  if(text == 'English to English'){
      setColumnValue(1);
  }else if(text == 'Korean to English'){
    setColumnValue(2);
  }else{
    setColumnValue(0);
  }
  };
  

  //console.log('get class', classes);
  useEffect(() => {
    if(authUser.user.teacher_id){
      var teacher_id = authUser.user.teacher_id;
      dispatch(myvocabActions.getSingleOwnVocabById({vocab_id,teacher_id}));
    }else{
      var student_id = authUser.user.student_id;
      dispatch(myvocabActions.getSingleOwnVocabById({vocab_id,student_id}));
    }

  }, [vocab_id]);

  useEffect(() => {
    if(myvocab && myvocab.data){
      setInputValue(myvocab.data.vocabname);
      const wordString = myvocab.data.words.map(item => item.word).join(',');
      setTextareaValue(wordString);

      if(myvocab.data.vocab_setting && myvocab.data.vocab_setting.option_setting){
        setOptionSetting(myvocab.data.vocab_setting.option_setting);
        }
       // alert(optionSetting);
        console.log(optionSetting,'get myvocab');
    }
  }, [myvocab]);
  
  const handleCorrectMeaning = (e) => {
    // Handle form submission here
    setCorrectValue(e.target.value);
  };

  const handleKoreanWord = (e) => {
    // Handle form submission here
    setKoreanWordValue(e.target.value);
  };
  const handleFlashCardCancel = () => {
    // Handle form submission here
    setShowFlashCard(false);
  };

  const handleFlashCard = () => {
    // Handle form submission here
    setShowFlashCard(true);

    
  };
  const handleWordClick = () => {
    // Handle form submission here
    setShowOnlyWord(true);
  }

  const handleWordMeaningClick = () => {
    // Handle form submission here
    setShowOnlyWord(false);
  }
  const handlePrintFlashCard = () => {

  }
  useEffect(() => {
    if (showFlashCard) {
      const timer = setTimeout(() => {
        handleNext();
      }, 10000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showFlashCard, index]);
  const handleModalClick = () => {
    handleNext();
  };
  useEffect(() => {
    if (showFlashCard && !showOnlyWord) {
      if(myvocab && myvocab.data){
        if(myvocab.data.vocab_setting && myvocab.data.vocab_setting.sound == 1 && showFlashCard){
          handleRead();
          }
      }
     
    }
  }, [showFlashCard, index, showOnlyWord,myvocab]);
  
  return (
    <section className="custom-sign own-vocab">
      <div className="container-fluid">
        <div>
          <label htmlFor="inputField">Vocab List Name:</label>
          <input
            type="text"
            id="inputField"
            value={inputValue}
            onChange={handleInputChange}
            required
          />
          <br />

          <textarea
            id="textareaField"
            value={textareaValue}
            onChange={handleTextareaChange}
            placeholder="Type in words using commas."
          />
          <br />
          <div className="button-container">
            <button onClick={handleSearch}>Search</button>
            <a href="/dictionary/MyOwnVocabQuizList"><button className="btn">Back To List</button></a>
            {
              (myvocab && myvocab.data) ? <><button className="btn" onClick={handleFlashCard}>Flash Card</button>
              {/* <button className="btn" onClick={handlePrintFlashCard}>Print Flash Card</button> */}</>
              :''
            }
            
            
              <button onClick={handleSubmit}>Save</button>

          </div>
        </div>
       
        {(myvocab && myvocab.data) ? (
          <>
           <div>
           <input type="radio" id="meaning1" name="english_korean" value="English & Korean to English" onClick={() => getRadioValueForWords('English & Korean to English')} checked/>
           <label for="meaning1">English & Korean to English</label>
       
           <input type="radio" id="korean_word1" name="english_korean" value="English to English" onClick={() => getRadioValueForWords('English to English')} />
           <label for="korean_word1">English to English</label>
       
           <input type="radio" id="meaning_and_korean1" name="english_korean" value="Korean to English" onClick={() => getRadioValueForWords('Korean to English')} />
           <label for="meaning_and_korean1">Korean to English</label>
         </div>
          <table>
            <thead>
              <tr>
                <th></th>
                {(columnValue == 1 || columnValue == 0) ? 
                <th>Word</th>
              : ''}
              {(columnValue == 2 || columnValue == 0) ? 
                <th>Korean Word</th>
                :''}
                {(columnValue == 1 || columnValue == 0 || columnValue == 2) ? 
                <th>Meaning</th>
                : ''}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(myvocab && myvocab.data)
                ? myvocab.data.words.map((diction, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      {(columnValue == 1 || columnValue == 0) ? 
                      <td>{diction.word}</td>
                      :''}
                       {(columnValue == 2 || columnValue == 0) ? 
                      <td>{diction.koreanword}</td>
                      :''}
                      {(columnValue == 1 || columnValue == 0 || columnValue == 2) ? 
                      <td>{diction.meaning}</td>
                      :''}
                      <td>
                        <i
                          class="fa fa-flag"
                          aria-hidden="true"
                          onClick={() => sendWrongAnswerToAdmin(diction.word)}
                        ></i>
                      </td>
                    </tr>
                  ))
                : "No Records Found."}
            </tbody>
          </table>
          </>
        ) : (
          ""
        )}

        <Modal show={showWrongWord} onHide={handleWrongWordCancel}>
          <Modal.Header closeButton>
            <Modal.Title>
              Report to admin for wrong meaning/korean words
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            <div>
              <label htmlFor="inputField">Word</label>
              <input type="text" id="inputField" value={clickedWord} />
            </div>

            <div>
              <input
                type="radio"
                id="meaning"
                name="meaning"
                value="Meaning"
                onClick={() => getRadioValue("Meaning")}
              />
              <label for="meaning">Meaning</label>

              <input
                type="radio"
                id="korean_word"
                name="meaning"
                value="Korean Word"
                onClick={() => getRadioValue("Korean Word")}
              />
              <label for="korean_word">Korean Word</label>

              <input
                type="radio"
                id="meaning_and_korean"
                name="meaning"
                value="Meaning and Korean Word"
                onClick={() => getRadioValue("Meaning and Korean Word")}
              />
              <label for="meaning_and_korean">Meaning and Korean Word</label>
            </div>
            <div>
              {radioValue == "Meaning" ? (
                <>
                  {" "}
                  <label htmlFor="datePicker">Correct Meaning*:</label>
                  <textarea
                    id="textareaField"
                    value={correctValue}
                    onChange={handleCorrectMeaning}
                    placeholder="Please enter correct meaning."
                    required
                  />
                </>
              ) : (
                ""
              )}

              {radioValue == "Korean Word" ? (
                <>
                  <label htmlFor="datePicker">Korean Word:</label>

                  <input
                    type="text"
                    id="inputField"
                    value={koreanWordValue}
                    onChange={handleKoreanWord}
                    required
                  />
                </>
              ) : (
                ""
              )}

              {radioValue == "Meaning and Korean Word" ? (
                <>
                  <label htmlFor="datePicker">Correct Meaning*:</label>

                  <textarea
                    id="textareaField"
                    value={correctValue}
                    onChange={handleTextareaChange}
                    placeholder="Type in words using commas."
                    required
                  />

                  <label htmlFor="datePicker">Korean Word:</label>

                  <input type="text" id="inputField" value={koreanWordValue} />
                </>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleWrongWordCancel}>
              Close
            </Button>
            <Button variant="secondary" onClick={handleWrongAnswerSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <Modal size="lg" show={showFlashCard} onHide={handleFlashCardCancel}  onClick={handleFlashCardCancel} backdrop="true">
          <Modal.Header closeButton>
            <Modal.Title>
              Flash Card
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }} onClick={handleModalClick}>
            <div>
            <div className="flash-card-image">
            {myvocab && myvocab.data && myvocab.data.words.length > 0
                  ? (myvocab.data.words[index].image) ? <img src={myvocab.data.words[index].image} className="dictionaryImg" height={100} width={100} /> : ''
                  : ""}
              </div>
              {(optionSetting == 'EKTE') ? <>
              <div className="flash-card-word">
                
                {myvocab && myvocab.data && myvocab.data.words.length > 0
                  ? myvocab.data.words[index].word
                  : ""}
              </div>
              <div className="flash-card-korword">
                
                {myvocab && myvocab.data && myvocab.data.words.length > 0
                  ? myvocab.data.words[index].koreanword
                  : ""}
              </div>
              {(!showOnlyWord) ?  <div className="flash-card-meaning">
               
               {myvocab &&  myvocab.data && myvocab.data.words.length > 0
                 ? myvocab.data.words[index].wordmean
                 : ""}
             </div> : ''}
             </> : <>
             <div className="flash-card-word">
                
                {myvocab && myvocab.data && myvocab.data.words.length > 0
                  ? myvocab.data.words[index].word
                  : ""}
              </div>
             
              {(!showOnlyWord) ?  <div className="flash-card-meaning">
               
               {myvocab &&  myvocab.data && myvocab.data.words.length > 0
                 ? myvocab.data.words[index].wordmean
                 : ""} </div>: ''}
              </>}

            </div>
          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal> */}

<Modal size="lg" show={showFlashCard} onHide={handleFlashCardCancel} onClick={handleFlashCardCancel} backdrop="true">
  <Modal.Header closeButton>
    <Modal.Title>Flash Card</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ padding: "15px" }} onClick={handleModalClick}>
    <div>
      {/* Conditional Rendering Based on Option Setting */}
      {myvocab && myvocab.data && myvocab.data.words.length > 0 && (
        <>
          {optionSetting === "EKTE" && (
            <>
              <div className="flash-card-word">
                {myvocab.data.words[index].word}
              </div>
              <div className="flash-card-image">
                {myvocab.data.words[index].image && (
                  <img
                    src={myvocab.data.words[index].image}
                    className="dictionaryImg"
                    height={100}
                    width={100}
                  />
                )}
              </div>
              <div className="flash-card-korword">
                {myvocab.data.words[index].koreanword}
              </div>
              <div className="flash-card-meaning">
                {!showOnlyWord && myvocab.data.words[index].wordmean}
              </div>
             
            </>
          )}
          {optionSetting === "ETE" && (
            <>
              <div className="flash-card-word">
                {myvocab.data.words[index].word}
              </div>
              <div className="flash-card-meaning">
                {!showOnlyWord && myvocab.data.words[index].wordmean}
              </div>
            </>
          )}
          {optionSetting === "ETK" && (
            <>
              <div className="flash-card-word">
                {myvocab.data.words[index].word}
              </div>
              <div className="flash-card-korword">
                {myvocab.data.words[index].koreanword}
              </div>
            </>
          )}
          {optionSetting === "KTE" && (
            <>
              <div className="flash-card-korword">
                {myvocab.data.words[index].koreanword}
              </div>
              <div className="flash-card-word">
                {myvocab.data.words[index].word}
              </div>
            </>
          )}
        </>
      )}
    </div>
  </Modal.Body>
  <Modal.Footer>
    {/* <Button variant="secondary" onClick={handleFlashCardCancel}>
      Close
    </Button> */}
  </Modal.Footer>
</Modal>

      </div>
    </section>
  );
}
