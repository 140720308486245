import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quizActions, quizresultActions,libraryActions , homeworkActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import axios from "axios";
export { TextBookVocabQuiz };

function TextBookVocabQuiz() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homework_id,program_id } = useParams();
  const [myState, setMyState] = useState('initialValue');
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);
  const { library } = useSelector((x) => x.library);
  const { homework } = useSelector((x) => x.homework);
  const { student } = useSelector((x) => x.student);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [quizCategory, setquizCategory] = useState();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizalready, setQuizAlready] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const [timer, setTimer] = useState(null);
  const [day, setDay] = useState(1);
  const [questionAnswered, setQuestionAnswered] = useState(0);
  const [correctAnswered, setCorrectAnswered] = useState(0);
console.log(student,'get lib',quizData);

  const student1 = JSON.parse(localStorage.getItem('user'));

 useEffect(() => {
 
    dispatch(libraryActions.textBookVocabQuiz({program_id}));
    dispatch(homeworkActions.getHomeWorkByRecordId({ homework_id }));
  }, []);

  const handleOptionSelect = (selected) => {
    setSelectedOption(selected);
    handleNextQuestion(selected);
    setQuestionAnswered(prevCount => prevCount + 1);
    setUserAnswers(prevAnswers => [...prevAnswers, selected]);
  };


  const handleNextQuestion = (selectedOption) => {
    // if (timer) {
    //   clearTimeout(timer);
    // }

    if (selectedOption === quizData[currentQuestion].answer) {
      setScore(score + 1);
      setCorrectAnswered(correctAnswered + 1);
    }

    setSelectedOption(null);

    if (currentQuestion + 1 < quizData.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      console.log("quiz comp");
    }

    if (currentQuestion === quizData.length - 1) {
      console.log('Setting quizCompleted to true');
      
      setQuizCompleted(true);
    }

    // setTimeRemaining(location.state.vocbsetting.time);
  };



  const handleKeyPress = (event) => {
    const selectedNumber = Number(event.key);
    if (!isNaN(selectedNumber) && selectedNumber > 0 && selectedNumber <= quizData[currentQuestion].options.length) {
      const selectedOption = quizData[currentQuestion].options[selectedNumber - 1];
      handleOptionSelect(selectedOption);
    }
  };
  
  useEffect(() => {
    try {
      if (quizData.length === 0) {
        // if (!location.state) {
        //   window.location.href = '/';
        // }
        if(library && library.data){
          setQuizData(library.data);
        }
        
        setquizCategory("TEXTBOOK VOCAB");

      }


    }
    catch (error) {
      console.error("An error occurred:", error);
    }
  }, [library]);

  useEffect(() => {
    if(homework && homework.data){
      var getDay = homework.data.day;
      getDay.split('-');
      setDay(getDay[0]);
    }
   
  }, [homework]);

  useEffect(() => {

    window.addEventListener('keydown', handleKeyPress);


    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (quizCompleted === true) {
          console.log(quizCompleted, "quizcompleted");
          const result = []
          {
            quizData.map((question, index) => (
              result.push({ word: question.word, answer: question.answer, useranswer: (index < userAnswers.length) ? userAnswers[index] : "Not answered" })
            ))
          }
    
          console.log(student, "student");
          if (student1) {
             const scoreprecentage=((score / quizData.length) * 100);
            const option = {
             // category: location.state.vocabulary.id,
              total_score:correctAnswered,
              Studentid: student1.user.student_id,
              Classid:student.Class.id,
              type: "Textbook Vocab",
              sub_type:"Textbook Vocab",
              result: JSON.stringify(result),
              total_questions:quizData.length,
              question_answered: questionAnswered,
              percentage: scoreprecentage,
              correct_answered: correctAnswered,
              ...(homework_id ? { homework_id } : {}),  // Include homework_id only if it exists
              ...(program_id ? { program_id } : {}),    // Include program_id only if it exists
            }
            console.log(option, "opt")
            const response = await axios.post(
              "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
              option,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  // Add other headers if needed
                },
              }
            );
            console.log(response);
            //dispatch(homeworkActions.savevocabresult(option));
          }
    
        }
      } catch (error) {
        console.error('Error fetching homework data:', error);
      }
    };
  
    fetchData();
  }, [dispatch, quizCompleted, quizCategory, score, quizData, userAnswers]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }


  const renderOptions = () => {
    return quizData[currentQuestion].options.map((option, index) => (
      <div key={index} className='quiz-options'>
        <label className='option-index'>{index + 1}</label>
        <label className="container" htmlFor={option}>
          {option}
          <input
            type="radio"
            id={option}
            name="options"
            value={option}
            checked={selectedOption === option}
            onChange={() => handleOptionSelect(option)}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    ));
  };

  const handleRetakeQuiz = () => {

    const shuffledQuizData = shuffleArray([...quizData]);

    setQuizCompleted(false);
    setQuizAlready(false)
    setCurrentQuestion(0);
    setSelectedOption(null);
    setScore(0);
    setQuizData(shuffledQuizData);
  };

  const renderQuizContent = () => {
    if (currentQuestion < quizData.length && quizCompleted === false ) {
      const totalTime = 10;
      const remainingPercentage = (timeRemaining / totalTime) * 100;
      const precent = (100 - ((currentQuestion - score) / quizData.length * 100)).toFixed(2);
      return (

        <div className="quiz-content">
          <div className="quiz-header">
            <div className="category-head">
              <h4>{quizCategory}</h4>
              <h5>{'1-'+day}</h5>
            </div>

            {/*{location.state.vocbsetting.istime === 1 && (
              <ProgressBar stripped style={{
                width: "100%",
                height: "1.3rem",
                fontSize: "0.95rem"
              }}
                animated now={remainingPercentage} label={`${timeRemaining} seconds`} />
            )}*/}
            <div className="quiz-progress">
              <p>Question {currentQuestion + 1} of {quizData.length}</p>
              {/* <p>Percentage: {(currentQuestion === 0) ? 100 : ((score /quizData.length ) * 100).toFixed(2)}%</p> */}
              <p>Percentage: {precent} %</p>


            </div>
          </div>
          <div className="quiz-question-area">
          <div class="quiz-question-area-part-one">
            <h3 style={{ textTransform: "none" }}>{quizData[currentQuestion].word}</h3>
            </div>
            <div class="quiz-question-area-part-two">
            {renderOptions()}
            </div>
          </div>
        </div>
      );
    }
     else {
      const percentage = ((score / quizData.length) * 100);
   const remaining=100-percentage;
 const data = [{ name: 'Your score', score: percentage, total: remaining  }];
      return (

        <div>
          <h2>Quiz Result!</h2>


          {/*<BarChart
            xAxis={[{ scaleType: 'band', data: ['Your score'] }]}
            series={[{ data: [percentage], color: "#3a58e9b8", stack: 'total' }, { data: [100 - percentage], color: "#992525", stack: 'total' }]}
            width={500}
            height={300}

          />*/} 

        <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
  <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart>

           <span className='score'>
    <div className="score-wrap">
        <span className="stars-active" style={{ width:`${percentage}%`}}>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
<span className="stars-inactive">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
</div>
</span>
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {quizData.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Word</th>
                  <th>Correct Answer</th>
                  <th>User Answer</th>
                  <th>O X</th>
                </tr>
              </thead>
              <tbody>
                {quizData.map((question, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{question.word}</td>
                    <td> {question.answer}</td>
                    <td>{index < userAnswers.length ? userAnswers[index] : "Not answered"}</td>
                    <th>{(question.answer == userAnswers[index]) ? 'O' : 'X'}</th>
                  </tr>

                ))}
              </tbody>
            </Table>
          </div>

          <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
        </div>
      );
    }
  };



  return (
    <div className="quiz-container">
      <div className='quizs-container'> {renderQuizContent()} </div>
    </div>
  );
}

