import { useEffect,useState,useRef } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { readingActions } from '_store';
import { useParams } from "react-router-dom";
export { ReadOutLoudGrader };

function ReadOutLoudGrader() {
  const dispatch = useDispatch();
  const { recordId,pageType } = useParams();
  const { reading } = useSelector((x) => x.reading);
  const [audioQuality, setAudioQuality] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [result, setResult] = useState('');
  const audioRef = useRef(null);
  useEffect(() => {
    dispatch(readingActions.getReadingResultByRecordId({ recordId }));
  }, []);

  useEffect(() => {
     // Check if AAResult contains errors

     if (reading && reading.data && reading.data.result) {
         // var jsonResult = JSON.parse(reading.data.result);
         var jsonResult = reading.data.result;
         setResult(jsonResult);
         if (jsonResult && jsonResult.message && jsonResult.message.AAResult && jsonResult.message.AAResult[0] &&
          jsonResult.message.AAResult[0].error && jsonResult.message.AAResult[0].error[0]) {
          
          var errorMessage = jsonResult.message.AAResult[0].error[0]["_"];
          var errorCode = jsonResult.message.AAResult[0].error[0]["$"].errcode;
      
          // toast.error(errorMessage, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          //   });
      }else if(jsonResult?.message?.error) {
        // toast.error(jsonResult.message.error[0], {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
      }else {
          console.log("Error object does not exist.");
     
        
         // console.log(jsonResult,'getf');
          setResult(jsonResult);
        if (jsonResult.message.AAResult[0].audioQuality[0].$.status == "18") {
          setAudioQuality("soft and noisy");
        } else if (
          jsonResult.message.AAResult[0].audioQuality[0].$.status == "8"
        ) {
          setAudioQuality("soft");
        } else if (
          jsonResult.message.AAResult[0].audioQuality[0].$.status == "0"
        ) {
          setAudioQuality("good");
        } else if (
          jsonResult.message.AAResult[0].audioQuality[0].$.status == "10"
        ) {
          setAudioQuality("noisy");
        }else{
          setAudioQuality("no recording");
        }
      }
      // const errors = jsonResult.message.AAResult[0].error;
      // if (errors && errors.length > 0) {
      //   // There are errors, you can access them here
      //   for (const error of errors) {
      //     console.log(error._,'errors'); // This will print the error message
      //     toast.error(error._, {
      //       position: "top-right",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "light",
      //       });
      //   }
      // } else {
      //   console.log('No errors found');
      // }
    } else {
      console.log('No AAResult found');
    }
  }, [reading]);
  const getFluencyAverageScore = (fluency) => {
    console.log(fluency * 10,'get fluency');
    let convertedWpm = fluency;//fluency * 10;
    var value = 0;
    if (Math.round(convertedWpm) <= 60) {
      value = 0;
    } else if (Math.round(convertedWpm) >= 61 && Math.round(convertedWpm) <= 90) {
      value = 1;
    } else if (Math.round(convertedWpm) >= 91 && Math.round(convertedWpm) <= 120) {
      value = 2;
    } else if (Math.round(convertedWpm) >= 121 && Math.round(convertedWpm) <= 150) {
      value = 3;
    } else if (Math.round(convertedWpm) >= 151) {
      value = 4;
    } 
   // console.log(value,'get fluency',fluency);
    return value;
  };  
  const calculatePercentage = (valueToCalculate, totalErrors) => {

    const percentage = Math.floor((valueToCalculate / totalErrors) * 100);
    return isNaN(percentage) ? 0 : percentage;
  };
  const calculateAccuracyPercentage = (reading) => {
    if((result)){

      var accuracy = result && result.message && result.message.AAResult && result.message.AAResult[0] &&
      result.message.AAResult[0].analysis && result.message.AAResult[0].analysis[0] &&
      result.message.AAResult[0].analysis[0].score;
     

    
        }
    if(accuracy){
      const sum = accuracy;      
       const percentage = Math.floor((sum ) * 100);
      // alert(percentage);
      return isNaN(percentage) ? 0 : percentage;
    }else{
      return 1;
    //   if (result && result.message && result.message.AAResult && result.message.AAResult[0] &&
    //     result.message.AAResult[0].error && result.message.AAResult[0].error[0]) {
        
    //     var errorMessage = result.message.AAResult[0].error[0]["_"];
    //     var errorCode = result.message.AAResult[0].error[0]["$"].errcode;
    
    //     //console.log("Error message:", errorMessage);
    //     toast.error(errorMessage, {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //       });
    // } else {
    //     console.log("Error object does not exist.");
    // }
    }
    
  };
  //old code
  // const calculateAccuracyPercentage = (reading) => {
  //   if((result)){

  //     var sub = result && result.message && result.message.AAResult && result.message.AAResult[0] &&
  //     result.message.AAResult[0].analysis && result.message.AAResult[0].analysis[0] &&
  //     result.message.AAResult[0].analysis[0].readingSummary && result.message.AAResult[0].analysis[0].readingSummary[0] &&
  //     result.message.AAResult[0].analysis[0].readingSummary[0].nSub && result.message.AAResult[0].analysis[0].readingSummary[0].nSub[0];
  //     var del = result && result.message && result.message.AAResult && result.message.AAResult[0] &&
  //         result.message.AAResult[0].analysis && result.message.AAResult[0].analysis[0] &&
  //         result.message.AAResult[0].analysis[0].readingSummary && result.message.AAResult[0].analysis[0].readingSummary[0] &&
  //         result.message.AAResult[0].analysis[0].readingSummary[0].nDel && result.message.AAResult[0].analysis[0].readingSummary[0].nDel[0];
  //     //var insert = result.message.AAResult[0].analysis[0].readingSummary[0].nIns[0];
  //     var insert = result && result.message && result.message.AAResult && result.message.AAResult[0] &&
  //         result.message.AAResult[0].analysis && result.message.AAResult[0].analysis[0] &&
  //         result.message.AAResult[0].analysis[0].readingSummary && result.message.AAResult[0].analysis[0].readingSummary[0] &&
  //         result.message.AAResult[0].analysis[0].readingSummary[0].nIns && result.message.AAResult[0].analysis[0].readingSummary[0].nIns[0];
  //     //var totalWords = result.message.AAResult[0].analysis[0].readingSummary[0].nRef[0];
  //     var totalWords = result && result.message && result.message.AAResult && result.message.AAResult[0] &&
  //         result.message.AAResult[0].analysis && result.message.AAResult[0].analysis[0] &&
  //         result.message.AAResult[0].analysis[0].readingSummary && result.message.AAResult[0].analysis[0].readingSummary[0] &&
  //         result.message.AAResult[0].analysis[0].readingSummary[0].nRef && result.message.AAResult[0].analysis[0].readingSummary[0].nRef[0];
    
    
  //       }
  //   if(sub && del && insert){
  //     const sum = parseInt(sub) + parseInt(del) + parseInt(insert);
  //     const percentage = Math.floor((sum / parseInt(totalWords)) * 100);
  //    //console.log(percentage,'acc');
  //     return isNaN(percentage) ? 0 : percentage;
  //   }else{
  //     if (result && result.message && result.message.AAResult && result.message.AAResult[0] &&
  //       result.message.AAResult[0].error && result.message.AAResult[0].error[0]) {
        
  //       var errorMessage = result.message.AAResult[0].error[0]["_"];
  //       var errorCode = result.message.AAResult[0].error[0]["$"].errcode;
    
  //       //console.log("Error message:", errorMessage);
  //       toast.error(errorMessage, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //         });
  //   } else {
  //       console.log("Error object does not exist.");
  //   }
  //   }
    
  // };

  const renderStarsForAccuracy = (rating) => {
    var value;
    //alert(rating);

    if (parseInt(rating) <= 10) {
      //value = 1;
      value = 5;
    } else if (parseInt(rating) < 21 && parseInt(rating) >= 11) {
      value = 1;
    } else if (parseInt(rating) < 31 && parseInt(rating) >= 21) {
      value = 2;
    }else if (parseInt(rating) < 41 && parseInt(rating) >= 31) {
      value = 2;
    } else if (parseInt(rating) < 51 && parseInt(rating) >= 41) {
      value = 3;
    } else if (parseInt(rating) < 61 && parseInt(rating) >= 51) {
      value = 3;
    }else if (parseInt(rating) < 71 && parseInt(rating) >= 61) {
      value = 3;
    }else if (parseInt(rating) < 81 && parseInt(rating) >= 71) {
      value = 4;
    } else if (parseInt(rating) < 91 && parseInt(rating) >= 81) {
      value = 4;
    }else if (parseInt(rating) <= 100 && parseInt(rating) >= 91) {
      value = 5;
    }

    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={i <= value ? 'fa fa-star active' : 'fa fa-star'}
          aria-hidden="true"
        />
      );
    }
    return stars;
  };

  const renderStarsForFluency = (rating) => {
//alert(rating);
if(rating == 1 || rating == 0){
  rating = 5;
}
    if(rating == 0){
      rating = 1;
    }else if(rating == 1){
      rating = 2;
      //rating == 5;
    }else if(rating == 2){
      rating = 3;
    }else if(rating == 3){
      rating = 4;
    }else if(rating == 4){
      rating = 5;
    }
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={i <= rating ? 'fa fa-star active' : 'fa fa-star'}
          aria-hidden="true"
        />
      );
    }
    return stars;
  };
  const calculateTotalScore = (accuracy, fluency) => {
//console.log(accuracy,fluency,'get avg');
var value;
if (parseInt(accuracy) <= 10) {
  value = 1;
} else if (parseInt(accuracy) < 21 && parseInt(accuracy) >= 11) {
  value = 1;
} else if (parseInt(accuracy) < 31 && parseInt(accuracy) >= 21) {
  value = 2;
}else if (parseInt(accuracy) < 41 && parseInt(accuracy) >= 31) {
  value = 2;
} else if (parseInt(accuracy) < 51 && parseInt(accuracy) >= 41) {
  value = 3;
} else if (parseInt(accuracy) < 61 && parseInt(accuracy) >= 51) {
  value = 3;
}else if (parseInt(accuracy) < 71 && parseInt(accuracy) >= 61) {
  value = 3;
}else if (parseInt(accuracy) < 81 && parseInt(accuracy) >= 71) {
  value = 4;
} else if (parseInt(accuracy) < 91 && parseInt(accuracy) >= 81) {
  value = 4;
}else if (parseInt(accuracy) <= 100 && parseInt(accuracy) >= 91) {
  value = 5;
}
// if (parseInt(accuracy) <= 10) {
//   value = 1;
// } else if (parseInt(accuracy) < 21 && parseInt(accuracy) >= 11) {
//   value = 2;
// } else if (parseInt(accuracy) < 41 && parseInt(accuracy) >= 21) {
//   value = 3;
// } else if (parseInt(accuracy) < 61 && parseInt(accuracy) >= 41) {
//   value = 4;
// } else if (parseInt(accuracy) <= 100 && parseInt(accuracy) >= 61) {
//   value = 5;
// }
if(fluency){
  var totalScore = parseInt((value + fluency) / 2);
}else{
  var totalScore = (value == 5) ? 4: parseInt((value));
}

// Check for NaN and return 0 if so
// uncomment this code when unknown word error removed  return isNaN(totalScore) ? 0 : totalScore;
return isNaN(totalScore) ? 4 : (totalScore == 1) ? 4 : totalScore;
  };

  const getLinkForRecord = (type,homework_id,program_id) => {
      if(type == 'Vocab Read Out Loud'){
        var link = `/textbookvocabReadoutloud/${homework_id}/${program_id}`;
      }else if(type == 'Speaking Respond In English'){
        var link = `/speaking-respond-in-english/${homework_id}/${program_id}`;
      }else if(type == 'Sentence Read Out Loud'){
        var link = `/sentenceReadoutloud/${homework_id}/${program_id}`;
      }else{
        var link = `/HomeWork/read-out-loud-by-hw/${homework_id}`;
      }

      return link;
  }
  const getLinkForRecordByProgram = (type,program_id) => {
    if(type == 'Vocab Read Out Loud'){
      var link = `/textbookvocabReadoutloudByProgram/${program_id}`;
    }else if(type == 'Speaking Respond In English'){
      var link = `/speaking-respond-in-english/${program_id}`;
    }else if(type == 'Sentence Read Out Loud'){
      var link = `/sentenceReadoutloud/${program_id}`;
    }else{
      var link = `/HomeWork/read-out-loud-by-program/${program_id}`;
    }

    return link;
}
  

    const showTotalScore = (percentage) => {
    //console.log(percentage,'get avg');
    var score = 100 - percentage;
    return score;
    };

    const showReportFunc = () => {
    setShowReport(true);
    };
    const hideReportFunc = () => {
      setShowReport(false);
      };
    console.log(reading,'get read');
    return (
        <div className="container">
        <div className="title-container">
          <h2>READ-OUT-LOUD GRADER</h2>
        </div>
        <div className="canva-section"></div>
        <div className="mp3-section">
          <h3>Mp3 Player</h3>
          <div className="player-box">
            <div className="player-controls">
            {(reading && reading.data) ? 
          <audio ref={audioRef} controls>
                 <source src={reading.data.audio} type="audio/mp3" />
                 Your browser does not support the audio element.
               </audio>
               : ''}
              </div>
            <p />
            <p className="date" />
          </div>
        </div>
        <div className="result">
          <p>Scores &amp; Results</p>
          {(reading && reading.data && reading.data.type === 'Vocab Read Out Loud') ? 

            <p>{(result && result.message && result.message.AAResult)  ? 
              calculateTotalScore(calculateAccuracyPercentage(result)) : 0} /4 &nbsp; 
              {(result && result.message && result.message.AAResult &&
              calculateTotalScore(calculateAccuracyPercentage(result))  >= 3 )
              ? 'Pass' : 'Fail'}   
            </p>
          :  <p>{(result && result.message && result.message.AAResult)  ? 
            calculateTotalScore(calculateAccuracyPercentage(result),
            getFluencyAverageScore(result &&
             result.message &&
             result.message.AAResult &&
             result.message.AAResult[0] &&
             result.message.AAResult[0].analysis &&
             result.message.AAResult[0].analysis[0] &&
             result.message.AAResult[0].analysis[0].readingSummary &&
             result.message.AAResult[0].analysis[0].readingSummary[0] &&
             result.message.AAResult[0].analysis[0].readingSummary[0].wcpm
               ? result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0]
               : 0 // Provide a default value in case data is missing
               ) ) : 0} /4 &nbsp; 
             {(result && result.message && result.message.AAResult &&
            calculateTotalScore(calculateAccuracyPercentage(result),
            getFluencyAverageScore(result &&
             result.message &&
             result.message.AAResult &&
             result.message.AAResult[0] &&
             result.message.AAResult[0].analysis &&
             result.message.AAResult[0].analysis[0] &&
             result.message.AAResult[0].analysis[0].readingSummary &&
             result.message.AAResult[0].analysis[0].readingSummary[0] &&
             result.message.AAResult[0].analysis[0].readingSummary[0].wcpm
               ? result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0]
               : 0 // Provide a default value in case data is missing
               ))  >= 3 )
             ? 'Pass' : 'Fail'}   
           </p>
        }
         
          <p>
            Accuracy
            <span className="rating">
              {/* <i className="fa fa-star active" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" /> */}
              {renderStarsForAccuracy(result && result.message && result.message.AAResult  ? calculateAccuracyPercentage(result) : 1)}
            </span>
          </p>
          {(((reading && reading.data && reading.data.type != 'Vocab Read Out Loud' && pageType !== 'VROL') ) ) ?         
           <p>
            Fluency
            <span className="rating">
              {/* <i className="fa fa-star active" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" /> */}
              {renderStarsForFluency(result && result.message && result.message.AAResult  ? 
                getFluencyAverageScore(result &&
                  result.message &&
                  result.message.AAResult &&
                  result.message.AAResult[0] &&
                  result.message.AAResult[0].analysis &&
                  result.message.AAResult[0].analysis[0] &&
                  result.message.AAResult[0].analysis[0].readingSummary &&
                  result.message.AAResult[0].analysis[0].readingSummary[0] &&
                  result.message.AAResult[0].analysis[0].readingSummary[0].wcpm
                    ? result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0]
                    : 0 // Provide a default value in case data is missing
                    ) : 1)}

            </span>
          </p>:''}
 
        </div>
        <div className="result-btn">
          {!showReport ? <a href="#" onClick={()=> showReportFunc()}className="left">
            Show Report
          </a> : <a href="#" onClick={()=> hideReportFunc()}className="left">
            Hide Report
          </a>}
          
          
          {(reading && reading.data && reading.data.type && reading.data.homework_id && reading.data.program_id) ?  
          <a href={getLinkForRecord(reading.data.type,reading.data.homework_id,reading.data.program_id)} className="right">
          Record Again
        </a>: (reading && reading.data && reading.data.type && reading.data.homework_id) ?  <a href={`/HomeWork/read-out-loud-by-hw/${reading.data.homework_id}`} className="right">
            Record Again
          </a> : (reading && reading.data && reading.data.type && !reading.data.homework_id && reading.data.program_id) ? <a href={getLinkForRecordByProgram(reading.data.type,reading.data.program_id)} className="right">
          Record Again
        </a> :''}
         
        </div>
        {/* {!showReport ? 
        <div className="result-table">
           <table>
            <tbody>
              <tr>
                <td colSpan={2}>Audio Quality</td>
                <td className={(audioQuality === 'no recording') ? 'active':''}>No Recording</td>
                <td className={(audioQuality === 'soft') ? 'active':''}>Too Soft</td>
                <td className={(audioQuality === 'loud') ? 'active':''}>Too Loud</td>
                <td className={(audioQuality === 'noisy') ? 'active':''}>Too Noisy</td>
                <td>Beginning Cut-off</td>
                <td>End Cut-off</td>
                <td className={(audioQuality === 'good') ? 'active':''}>Audio Quality Good</td>
              </tr>
              <tr>
                <td colSpan={2}>Total Speaking Time</td>
                <td>{(result) ? 
                result.message.AAResult[0].analysis[0].speakingSummary[0].spkTimeSec[0] : 0} seconds</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
             
              </tr>
              {(pageType !== 'VROL' && reading && reading.data && reading.data.type != 'Vocab Read Out Loud') ? 
              <>
              <tr>
                <td colSpan={2} rowSpan={2}>
                  Fluency
                </td>
                <td className={(result && result.message && result.message.AAResult && 
getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 0
                )  ? 'active':''  }>0</td>
                <td className={(result && result.message && result.message.AAResult && 
getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 1
                )  ? 'active':''  }>1</td>
                <td className={(result && result.message && result.message.AAResult && 
   getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 2
                )  ? 'active':''  }>2</td>
                <td className={(result && result.message && result.message.AAResult && 
  getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 3
                )  ? 'active':''  }>3</td>
                <td className={(result  && result.message && result.message.AAResult && 
getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 4
                )  ? 'active':''  }>4</td>
                <td />
                <td />
              </tr>
              <tr>
                <td className={(result && result.message && result.message.AAResult && 
 getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 0
                )  ? 'active':''  }>
                  Extremely Slow or No Response <span>(less than 60wpm)</span>
                </td>
                <td className={(result && result.message && result.message.AAResult  && 
 getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 1
                )  ? 'active':''  }>
                  Slow <span>(61-90wpm)</span>
                </td>
                <td className={(result && result.message && result.message.AAResult && 
 getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 2
                )  ? 'active':''  }>
                  Average <span>(91-120wpm)</span>
                </td>
                <td className={(result && result.message && result.message.AAResult && 
    getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 3
                )  ? 'active':''  }>
                  Relatively Fast <span>(121-150wpm)</span>
                </td>
                <td className={(result && result.message && result.message.AAResult && 
  getFluencyAverageScore(result.message.AAResult[0].analysis[0].readingSummary[0].wcpm[0])  == 4
                )  ? 'active':''  }>
                  Fast <span>(Over 151wpm)</span>
                </td>
                <td />
                <td />
              </tr>
              </>
              :''}
              <tr>
                <td colSpan={2} rowSpan={2}>
                  Accuracy
                </td>
                <td className={(result && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(result) <= 10) ? 'active':'' }>0</td>
                <td className={(result && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(result) >= 21 && calculateAccuracyPercentage(result) <= 40) ? 'active':'' }>1</td>
                <td className={(result && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(result) >= 41 && calculateAccuracyPercentage(result) <= 60) ? 'active':'' }>2</td>
                <td className={(result && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(result) >= 61 && calculateAccuracyPercentage(result) <= 80) ? 'active':'' }>3</td>
                <td className={(result && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(result) >= 81 && calculateAccuracyPercentage(result) <= 100) ? 'active':'' }>4</td>
                <td />
                <td />
              </tr>
              <tr>
                <td className={(result && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(result) <= 10) ? 'active':'' }>No Recording or less than 10%</td>
                <td className={(result && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(result) >= 21 && calculateAccuracyPercentage(result) <= 40) ? 'active':'' }>21-40%</td>
                <td className={(result && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(reading) >= 41 && calculateAccuracyPercentage(result) <= 60) ? 'active':'' }>41-60%</td>
                <td className={(result  && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(result) >= 61 && calculateAccuracyPercentage(result) <= 80) ? 'active':'' }>61-80%</td>
                <td className={(result && result.message && result.message.AAResult
                   && calculateAccuracyPercentage(result) >= 81 && calculateAccuracyPercentage(result) <= 100) ? 'active':'' }>81-100%</td>
                <td />
                <td />
              </tr>
              {(pageType !== 'VROL' && reading && reading.data && reading.data.type != 'Vocab Read Out Loud') ? 
              <>
             <tr>
  <td rowSpan={3}>Breakdown of Errors</td>
  <td>
    Substitution{" "}
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nSub?.[0] 
      ? result.message.AAResult[0].analysis[0].readingSummary[0].nSub[0] 
      : 0}
  </td>
  <td>
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nSub?.[0] 
      ? calculatePercentage(
          result.message.AAResult[0].analysis[0].readingSummary[0].nSub[0],
          result.message.AAResult[0].analysis[0].readingSummary[0].nErr?.[0] || 1 // Avoid division by 0
        )
      : 0}{" "}
    %
  </td>
  <td />
  <td />
  <td />
  <td />
  <td />
  <td />
</tr>

<tr>
  <td>
    Deletion{" "}
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nDel?.[0] 
      ? result.message.AAResult[0].analysis[0].readingSummary[0].nDel[0] 
      : 0}
  </td>
  <td>
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nDel?.[0] 
      ? calculatePercentage(
          result.message.AAResult[0].analysis[0].readingSummary[0].nDel[0],
          result.message.AAResult[0].analysis[0].readingSummary?.[0]?.nErr?.[0] || 1 // Default to 1 to avoid division by 0
        )
      : 0}{" "}
    %
  </td>
  <td />
  <td />
  <td />
  <td />
  <td />
  <td />
</tr>

<tr>
  <td>
    Insertion{" "}
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nIns?.[0] 
      ? result.message.AAResult[0].analysis[0].readingSummary[0].nIns[0] 
      : 0}
  </td>
  <td>
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nIns?.[0] 
      ? calculatePercentage(
          result.message.AAResult[0].analysis[0].readingSummary[0].nIns[0],
          result.message.AAResult[0].analysis[0].readingSummary?.[0]?.nErr?.[0] || 1 // Fallback to 1 to avoid division by 0
        )
      : 0}{" "}
    %
  </td>
  <td />
  <td />
  <td />
  <td />
  <td />
  <td />
</tr>

<tr>
  <td>Total Number of Errors</td>
  <td>
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nErr?.[0] 
      ? result.message.AAResult[0].analysis[0].readingSummary[0].nErr[0] 
      : 0}/
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nRef?.[0] 
      ? result.message.AAResult[0].analysis[0].readingSummary[0].nRef[0] 
      : 0}
  </td>
  <td>
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nErr?.[0] 
      && result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nRef?.[0] 
      ? showTotalScore(
          calculatePercentage(
            result.message.AAResult[0].analysis[0].readingSummary[0].nErr[0],
            result.message.AAResult[0].analysis[0].readingSummary[0].nRef[0] || 1 // Fallback to 1 to avoid division by 0
          )
        ) 
      : 0}{" "}
    %
  </td>
  <td />
  <td />
  <td />
  <td />
  <td />
  <td />
</tr>

<tr>
  <td colSpan={2}>Number of Words in Your Answer</td>
  <td>
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nHyp?.[0] 
      ? result.message.AAResult[0].analysis[0].readingSummary[0].nHyp[0] 
      : 0}
  </td>
  <td />
  <td />
  <td />
  <td />
  <td />
  <td />
</tr>

<tr>
  <td colSpan={2}>Number of Target Words</td>
  <td>
    {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nRef?.[0] 
      ? result.message.AAResult[0].analysis[0].readingSummary[0].nRef[0] 
      : 0}
  </td>
  <td />
  <td />
  <td />
  <td />
  <td />
  <td />
</tr>

              </>:''}
            </tbody>
          </table>


        </div>
        : ''} */}
{showReport ? 
      <div className='report-result result-table'>
        <table>
            <tbody>
              <tr>
                <td rowSpan={3}>Breakdown of Errors</td>
                <td>Substitution {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nSub?.[0] || 0}</td>

                <td>
  {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nSub?.[0] !== undefined &&
   result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nErr?.[0] !== undefined
    ? calculatePercentage(
        result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nSub?.[0] ?? 0,
        result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nErr?.[0] ?? 0
      )
    : 0} %
</td>

                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
              <td>
  Deletion {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nDel?.[0] ?? 0}
</td>

<td>
  {calculatePercentage(
    result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nDel?.[0] ?? 0,
    result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nErr?.[0] ?? 0
  )} %
</td>

                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
              <td>
  Insertion {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nIns?.[0] ?? 0}
</td>

<td>
  {calculatePercentage(
    result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nIns?.[0] ?? 0,
    result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nErr?.[0] ?? 0
  )} %
</td>

                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
              <td>Total Number of Errors</td>
<td>
  {(result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nErr?.[0] ?? 0)}/
  {(result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nRef?.[0] ?? 0)}
</td>
<td>
  {showTotalScore(
    calculatePercentage(
      result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nErr?.[0] ?? 0,
      result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nRef?.[0] ?? 0
    )
  )} %
</td>

                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
              <td colSpan={2}>Number of Words in Your Answer</td>
<td>
  {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nHyp?.[0] ?? 0}
</td>

                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
              <td colSpan={2}>Number of Target Words</td>
<td>
  {result?.message?.AAResult?.[0]?.analysis?.[0]?.readingSummary?.[0]?.nRef?.[0] ?? 0}
</td>

                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div> : ''}
       
      </div>
      
      
    )
}
