import { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    studyActions
  } from "_store";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { Table } from "react-bootstrap";

const FillInBlankSentences = ({sentencesType, sentences,sentencesForAnswer, program_id, type, q_start }) => {
  const [blanksSentences, setBlanksSentences] = useState([]);
  const [removedWords, setRemovedWords] = useState([]);
  const [filledBlanks, setFilledBlanks] = useState(new Array(sentences.length).fill(false)); // Track filled blanks
  const [makeBlanks, setMakeBlanks] = useState(false);
  const [selectedWord, setSelectedWord] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  // Define a state to store user answers
const [userAnswers, setUserAnswers] = useState([]);
const [blankSentence, setBlankSentence] = useState(''); 
const [score, setScore] = useState(0);
const [selectedWords, setSelectedWords] = useState([]); 
const [heartIconIndex, setHeartIconIndex] = useState(0);
const [userAnswerForResult, setUserAnswersForResult] = useState([]);
const [userAnswerResults, setUserAnswerResult] = useState([]);
  console.log(sentencesForAnswer,'get sentencesForAnswer');
  const student = JSON.parse(localStorage.getItem('user'));
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  
  useEffect(() => {
    const updatedBlanks = sentences.map((sentence) => {
      if (sentence) {
//         if(type == 'english'){
// // Split the sentence into words by spaces and punctuation marks
// var words = sentence.split(/\b(\s+|\W+)\b/);

// // Filter out empty strings and punctuation marks from the words
// var filteredWords = words.filter(word => {
//     // Check if the word is not empty and doesn't contain punctuation marks
//     return word.trim() !== '' && (!/[.,\/#!$%^&*;:{}=\-_`~()]/.test(word.trim()));
// });

//         }else{
         
// var words = sentence.split(/[\s.,!?]+/); // Match words with word boundaries
// console.log(words,'get korean');
//         var filteredWords = words.filter(word => word.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/)); // Filter words based on type
//         console.log(filteredWords,'get korean');
//         }

      if (type === 'english') {
        // Split the sentence into words by spaces and punctuation marks
        var words  = sentence.split(/\b(\s+|\W+)\b/);
        // Filter out empty strings and punctuation marks from the words
        var filteredWords = words.filter(word => word.trim() !== '' && !/[.,’\/#!$%^&*;:{}=\-_`~()]/.test(word.trim()));
      } else {
        // Split the sentence into words by spaces, commas, periods, etc.
        var words = sentence.split(/[\s.,!?]+/);
        // Filter out words that don't contain Korean characters
        var filteredWords = words.filter(word => word.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/));
      }

        

       // const words = sentence.match(/\b\w+\b/g); // Match words with word boundaries
        //const filteredWords = type === 'korean' ? words.filter(word => word.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/)) : words.filter(word => /^[A-Za-z'’]+$/i.test(word)); // Filter words based on type
  
        // Check if there are any words to be removed
        if (filteredWords.length >= 1) { // Add condition to check if there are more than 1 filtered words
          const removedWord = filteredWords[Math.floor(Math.random() * filteredWords.length)]; // Randomly select a word from filteredWords
          const blankIndex = words.indexOf(removedWord); // Get the index of the removed word
          if (removedWord !== undefined) {
            setRemovedWords((prev) => [...prev, removedWord]);
            words[blankIndex] = '___';
            return { ...sentence, [type]: words.join(' '), correct_word: removedWord }; // Update sentence with blanks based on type
          }
        } else {
          
          return { ...sentence, [type]: sentence, correct_word: '' }; // If there's only one word, return the sentence as it is
        }
      }
      return sentence;
    }).filter(sentence => sentence !== undefined); // Remove undefined sentences
      
    setBlanksSentences(updatedBlanks);
  
    // Populate removedWords array with only as many words as there are blanks in sentences
    const newRemovedWords = updatedBlanks.map(sentence => sentence.correct_word);
    setRemovedWords(shuffleArray([...newRemovedWords]));
  }, [sentences]);
  
  useEffect(() => {
    console.log(userAnswerResults,'get e,');
 
// Check if userAnswerResults array has all sentences from blankSentences array
if (userAnswerResults.length !== blanksSentences.length) {
  // Iterate over blankSentences array
  blanksSentences.forEach((sentence, index) => {
    // Check if the sentence is not present in userAnswerResults array
    //const sentenceExists = userAnswerResults.some(result => result.question === sentence[type]);

    // Check if the sentence does not contain a <span> tag and matches result.question
    const sentenceWithoutSpan = sentence[type].replace(/<span.*?<\/span>/g, '___'); // Remove any <span> tags
    console.log(sentenceWithoutSpan,'get me');
    const matches = userAnswerResults.some(result => result.question === sentenceWithoutSpan);
    console.log(matches,'get me');
    if (!matches) {
      // If the sentence doesn't exist and matches the result.question, add it to userAnswerResults array
      setUserAnswerResult(prevUserAnswerResults => [
        ...prevUserAnswerResults,
        { question: sentence[type], answer: sentences[index], useranswer: '' }
      ]);
    }
  });
}


  }, [userAnswerResults]);
  const startDisplayBlanks = () => {
    setMakeBlanks(true);
  };


  const handleAnswerOptionClick = (option, index) => {
    // Check if the selected word has already been clicked
    // handleWordClick(option);
    // setSelectedWords(prevSelectedWords => [...prevSelectedWords, option]);

  //   if (!selectedWords.includes(option)) {
  //     handleWordClick(option);
  //     setSelectedWords(prevSelectedWords => [...prevSelectedWords, option]);
  // }

  if (removedWords.includes(option)) {
    // Count how many times the word appears in removedWords
    const countInRemovedWords = removedWords.filter(word => word === option).length;
    // Count how many times the word appears in selectedWords
    const countInSelectedWords = selectedWords.filter(word => word === option).length;
    // Check if adding the word will exceed its count in removedWords
    if (countInSelectedWords < countInRemovedWords) {
        // Call the function to handle the word click
        handleWordClick(option);
        // Add the word to selectedWords array
        setSelectedWords(prevSelectedWords => [...prevSelectedWords, option]);
    } else {
        console.log("Selected words count exceeded the removed words count for this word.");
        // You can handle this case as per your requirement, e.g., showing an alert or taking some other action.
    }
}

  };
  
  
  

  const handleWordClick = (word) => {
    setSelectedWord(word);
  };

 
  const findFirstSentenceIndexWithoutInsertedWord = () => {
    const allBlankSentences = document.querySelectorAll('.blank-sentences p[data-opt]');
    for (let i = 0; i < allBlankSentences.length; i++) {
      const sentence = allBlankSentences[i];
      const insertedWords = sentence.querySelectorAll('.inserted-word');
      if (insertedWords.length === 0) {
        return i; // Return the index directly
      }
    }
    return -1; // Return -1 if no such sentence is found
  };

  const handleBlankClick = (index) => {

      var sentenceIndex = index;
    if (selectedWords.length > 0 && index !== null) {
      const updatedSentences = [...blanksSentences];
      const sentenceObj = updatedSentences[index];
      if (typeof sentenceObj === 'object' && sentenceObj.hasOwnProperty(type)) {
        const sentenceArray = sentenceObj[type].split(' ');
        const blankIndex = sentenceArray.indexOf('___');
        if (blankIndex !== -1) {
          const selectedWord = selectedWords.shift(); // Get the first selected word from the options
          sentenceArray[blankIndex] = `<span class="inserted-word"> ${selectedWord}</span>`; // Wrap the selected word in a span
          console.log(sentenceArray.join(' '),'get sentence array anj');
          setBlankSentence(sentenceArray.join(' '));
          updatedSentences[index] = { ...sentenceObj, [type]: sentenceArray.join(' ') };
          //console.log(index,'get index of sentence');
          const firstSentenceIndexWithoutInsertedWord = findFirstSentenceIndexWithoutInsertedWord();
          //console.log(firstSentenceIndexWithoutInsertedWord);
          setHeartIconIndex(index + 1);
          setBlanksSentences(updatedSentences);
          setSelectedWord('');


          const blankSentencesContainer = document.querySelector('.blank-sentences');
         
if (blankSentencesContainer) {
  // Add event listener to the blank sentences container
  blankSentencesContainer.addEventListener('click', (event) => {
    
    // Check if the clicked element has the class 'inserted-word'
    if (event.target.classList.contains('inserted-word')) {
      // Find the parent element with the data-index attribute
      const parentSentence = event.target.closest('[data-index]');
     
      if (parentSentence) {
        // Get the sentence index from the data-index attribute
        const sentenceIndex = parentSentence.getAttribute('data-index');
        console.log(sentenceIndex,'sdf',event.target.innerText);
        // Call the handler function with the clicked word and sentence index
        handleInsertedWordClick(event.target.innerText, parseInt(sentenceIndex),parentSentence);
      }
    }
  });
}

          const wordIndex = removedWords.indexOf(selectedWord);

          if (wordIndex !== -1) {
            // If the word is found, remove it from the array
            const updatedRemovedWords = [...removedWords];
            updatedRemovedWords.splice(wordIndex, 1);
            
            // Update the state with the new array
            setRemovedWords(updatedRemovedWords);
          }
          // Remove the selected word from the removedWords array
         // setRemovedWords(prevRemovedWords => prevRemovedWords.filter(word => word !== selectedWord));
  
          // Create an object with question and user answer
          const userAnswerObj = {
            question: blanksSentences[index][type],
            answer: sentences[index],
            useranswer: sentenceArray.join(' ') // Store the selected word with the span in user answer
          };
  
          // Push the object into the array
          setUserAnswers(prevUserAnswers => [...prevUserAnswers, userAnswerObj]);
            // Prepare an array to hold all user answers
  const allUserAnswers = [];
  
  // Iterate over all sentences

          // const userAnswerObj1 = {
          //   question: blanksSentences[index][type],
          //   answer: sentences[index],
          //   useranswer: selectedWord // Store the selected word with the span in user answer
          // };

          //    // Check if the user answer for this sentence already exists in userAnswerResult array
          //    const existingIndex = userAnswerForResult.findIndex(item => item.answer === userAnswerObj1.answer);
          //    console.log(existingIndex,'get index',userAnswerForResult);
          //    if (existingIndex !== -1) {
          //        // If exists, replace the existing entry with the updated user answer
          //        const newUserAnswerResult = [...userAnswerForResult];
          //        newUserAnswerResult[existingIndex] = userAnswerObj1;
          //        setUserAnswersForResult(newUserAnswerResult);
          //    } else {
          //        // If doesn't exist, add the new user answer to userAnswerResult array
          //        setUserAnswersForResult(prevUserAnswerResult => [...prevUserAnswerResult, userAnswerObj1]);
          //    }
  
          // Push the object into the array
         //setUserAnswersForResult(prevUserAnswers => [...prevUserAnswers, userAnswerObj1]);
          // Update filledBlanks state for the corresponding sentence index
          setFilledBlanks(prevFilledBlanks => {
            const updatedFilledBlanks = [...prevFilledBlanks];
            updatedFilledBlanks[index] = true;
            return updatedFilledBlanks;
          });

          // blanksSentences.forEach((sentence, index) => {
          //   console.log(filledBlanks[index],'get filledBlanks[index]');
            // Construct an object representing the user's answer for the current sentence
            const userAnswerObj1 = {
              question: blanksSentences[index][type],
              answer: sentences[index],
              category_type:sentencesForAnswer[index].categorytype,
              useranswer:  selectedWord// Include the selected word if the sentence is filled, otherwise include an empty string
            };
        
            const existingIndex = userAnswerForResult.findIndex(item => item.answer === userAnswerObj1.answer);
            console.log(existingIndex,'get index',userAnswerForResult);
            if (existingIndex !== -1) {
                // If exists, replace the existing entry with the updated user answer
                const newUserAnswerResult = [...userAnswerForResult];
                newUserAnswerResult[existingIndex] = userAnswerObj1;
                setUserAnswersForResult(newUserAnswerResult);
            } else {
                // If doesn't exist, add the new user answer to userAnswerResult array
                setUserAnswersForResult(prevUserAnswerResult => [...prevUserAnswerResult, userAnswerObj1]);
            }
         // });
  
        }
      }
    }
  };
  
  
  
const handleInsertedWordClick = (selectedWord, sentenceIndex,parentSentence) => {
  // Retrieve the sentence object corresponding to the clicked index
  
  const sentenceElement = parentSentence; // Assuming parentSentence is already a reference to the sentence element
  if (sentenceElement) {
    const dataIndex = sentenceElement.getAttribute('data-index');
    const dataOpt = sentenceElement.getAttribute('data-opt');
    const textContent = sentenceElement.textContent.trim();
    const insertedWord = sentenceElement.querySelector('.inserted-word').textContent.trim();
   // Extract English text by removing numbering and Korean characters
   if(type == 'english'){
    var englishText = textContent.replace(/^\d+\.\s+/, '').replace(/[^\x00-\x7F]+/g, '').replace(',   (:   ?)','').replace('.','').trim();
   
  }else{
    var englishText = textContent.replace(/^\d+\.\s+/, '').replace(/[a-zA-Z]+/g, '').replace(',   (:   ?)','').replace('.','').trim();
   }

//   if (type == 'english') {
//     var englishText = textContent.replace(/^\d+\.\s+/, '').replace(/[^\x00-\x7F]+/g, '').replace(',   (:   ?)','').trim();
// } else {
//     var englishText = textContent.replace(/^\d+\.\s+/, '').replace(/[^a-zA-Z.,?'!\s]+/g, '').trim();
// }


   
  // console.log(englishText,'anjali',textContent);return false;
    // Build the object
    const sentenceObj = {};
    let counter = 0;
    for (let i = 0; i < textContent.length; i++) {
      if (textContent.charAt(i) === ' ') {
        sentenceObj[counter] = ' ';
        counter++;
      } else {
        sentenceObj[counter] = textContent.charAt(i);
        counter++;
      }
    }
  
    // Add additional properties
    if(type == 'english'){
    sentenceObj['english'] = englishText;
    }else{
      sentenceObj['korean'] = englishText;
    }
    sentenceObj['correct_word'] = insertedWord;
  
    console.log(sentenceObj,'sdf12',parentSentence,selectedWord);
    if (typeof sentenceObj === 'object' && sentenceObj.hasOwnProperty(type)) {
    
      // Split the sentence into an array of words
      const sentenceArray = sentenceObj[type].split(' ');
      
     // var getWords = extractWords(sentenceArray);
      // Find the index of the selected word in the sentence array
      const blankIndex = sentenceArray.indexOf(selectedWord);
   
      // If the selected word is found in the sentence
      if (blankIndex !== -1) {
        // Replace the selected word with '___'
        sentenceArray[blankIndex] = '___';
       
        // Join the modified sentence array back into a string
        const updatedSentence = sentenceArray.join(' ');
       
      // Update the sentence object with the modified sentence
      blanksSentences[sentenceIndex][type] = updatedSentence;
      console.log(updatedSentence,'get array',blankIndex);
      // Update the state to reflect the modified sentence
      setBlanksSentences(prevBlanksSentences => {
        const updatedSentences = [...prevBlanksSentences];
        updatedSentences[sentenceIndex] = { ...updatedSentences[sentenceIndex], [type]: updatedSentence };
        return updatedSentences;
      });

    
        // Add the clicked word back to the removedWords array
        setRemovedWords(prevRemovedWords => [...prevRemovedWords, selectedWord]);
      }
    }
  }
};


  
  
  
    // Function to handle click on "Save" button
    const handleSaveClick = async () => {
      setSaveClicked(true); // Update state to indicate "Save" button is clicked
      const option = {
        type: type,
        blanks: JSON.stringify(userAnswerForResult)
      }
     // console.log(option, "opt")
      const response = await axios.post(
        "https://api.webtracktechnologies.com.au:8002/studytest/generateresult/grammerblanks",
        option,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            // Add other headers if needed
          },
        }
      );
      if(response){
        //console.log(response.data.data,'get response');return false;
       setScore(response.data.data.correctCount);
       //setUserAnswerResult([]);
       setUserAnswerResult(response.data.data.result);
       var q_end = parseInt(q_start) + 4;
       const scoreprecentage=((response.data.data.correctCount / sentences.length) * 100);
       if(type == 'english'){
        var sub_type = "Grammar: Fill in the Blanks (English)";
       }else{
        var sub_type = "Grammar: Fill in the Blanks (Korean)";
       }
       const option1 = {
        // category: location.state.vocabulary.id,
         total_score:response.data.data.correctCount,
         Studentid: student.user.student_id,
         Classid:student.user.Student.class_name,
         type: "Study",
         sub_type: sub_type,
         program_id:program_id,
         result: JSON.stringify(userAnswers),
         vocab_q_start: q_start,
          vocab_q_end:q_end,
          total_questions:sentences.length,
          percentage: scoreprecentage,
          correct_answered: response.data.data.correctCount,
          category_result:JSON.stringify(response.data.data.categoryresult),
       }
      // console.log(option, "opt")
       const response1 = await axios.post(
         "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
         option1,
         {
           headers: {
             Authorization: `Bearer ${localStorage.getItem('token')}`,
             // Add other headers if needed
           },
         }
       );
       if(response1){
        setShowResult(true);
       }
      }

    };

    
    
    
    console.log(userAnswerResults,'get userAnswerResults');
    const handleHeartClick = async (sentenceIndex) => {
      const allHeartIcons = document.querySelectorAll('.hear-section .fa-heart');

      // Find the next heart icon that is visible and has no 'display: none' style
      const nextVisibleHeartIcon = Array.from(allHeartIcons).find((heartIcon, index) => {
        return index >= sentenceIndex && heartIcon.style.display !== 'none';
      });
    
      // If a visible heart icon without 'display: none' style is found, hide it
      if (nextVisibleHeartIcon) {
        nextVisibleHeartIcon.style.display = 'none';
      }

      const allBlankSentences = document.querySelectorAll('.blank-sentences p[data-opt]');
      // Check if all sentences have been filled
      const allBlanksFilled = filledBlanks.every(filled => filled);
      console.log(filledBlanks.length,'sentenceIndex',allBlankSentences.length, allBlanksFilled );
      if((filledBlanks.length === allBlankSentences.length)){
        const option = {
          type: type,
          blanks: JSON.stringify(userAnswerForResult)
        }
       // console.log(option, "opt")
        const response = await axios.post(
          "https://api.webtracktechnologies.com.au:8002/studytest/generateresult/grammerblanks",
          option,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              // Add other headers if needed
            },
          }
        );
        if(response){
         setScore(response.data.data.correctCount);
         //setUserAnswerResult([]);
         setUserAnswerResult(response.data.data.result);
        
        }
      }

      else{
     if (allBlankSentences.length > sentenceIndex) {
          const currentSentence = allBlankSentences[sentenceIndex];
         // console.log(currentSentence, 'get currentSentence');
          const sentence = currentSentence.textContent; // Get the text content of the sentence
          const wordToFill = currentSentence.getAttribute('data-opt'); // Get the correct word from data-opt attribute
          const blankIndex = sentence.indexOf('___'); // Find the index of the blank
          if (blankIndex !== -1) { // If blank exists in the sentence
            // Split the sentence into parts: before, correct word, and after the blank
            const beforeBlank = sentence.slice(0, blankIndex);
            const afterBlank = sentence.slice(blankIndex + 3);
            const updatedSentence = `${beforeBlank}<span class="correct-word" style="color: red;">${wordToFill}</span>${afterBlank}`; // Wrap the correct word in a span with a class for styling
            currentSentence.innerHTML = updatedSentence; // Update the HTML content of the paragraph
      
            // Apply red color to the correct word
            const correctWordElement = currentSentence.querySelector('.correct-word');
      
            // Blink effect
            let blinkCount = 0;
            const blinkInterval = setInterval(() => {
              if (blinkCount % 2 === 0) {
                correctWordElement.style.visibility = 'hidden';
              } else {
                correctWordElement.style.visibility = 'visible';
              }
              blinkCount++;
              if (blinkCount >= 6) { // Blink 3 times (6 intervals)
                clearInterval(blinkInterval);
                correctWordElement.style.visibility = 'hidden';
                setTimeout(() => {
                  currentSentence.textContent = sentence.substring(0, blankIndex) + '___' + sentence.substring(blankIndex + 3);
                }, 500); // After the blinking ends, show blank again
              }
            }, 500); // Change visibility every 500ms
          }
        }
  
      }
    };
  
const percentage = ((score / sentences.length) * 100);
const remaining = 100-percentage;
 const data = [{ name: 'Your score', score: percentage, total: 100  }];
  return (
    <div className='sentences-list'>
{!showResult && (
 <p className='hear-section'>
{(makeBlanks) ? (
  <>
    <i className='fa fa-heart' data-index={heartIconIndex} onClick={() => handleHeartClick(heartIconIndex)}></i>&nbsp;
    <i className='fa fa-heart' data-index={heartIconIndex} onClick={() => handleHeartClick(heartIconIndex)}></i>&nbsp;
    <i className='fa fa-heart' data-index={heartIconIndex} onClick={() => handleHeartClick(heartIconIndex)}></i>&nbsp;
    <i className='fa fa-heart' data-index={heartIconIndex} onClick={() => handleHeartClick(heartIconIndex)}></i>&nbsp;
    <i className='fa fa-heart' data-index={heartIconIndex} onClick={() => handleHeartClick(heartIconIndex)}></i>
  </>
) : (
  <>
    <i className='fa fa-heart'></i>&nbsp;
    <i className='fa fa-heart'></i>&nbsp;
    <i className='fa fa-heart'></i>&nbsp;
    <i className='fa fa-heart'></i>&nbsp;
    <i className='fa fa-heart'></i>
  </>
)}
</p>
)}
           

      {(!showResult && !makeBlanks) ? (
        sentences.map((sentence, index) => (
          <>
          {(type == 'korean') ? <p key={index}>{`${index + 1}. ${sentencesType[index]} ${sentence}`}</p>:
          <p key={index}>{`${index + 1}. ${sentence} ${sentencesType[index]}`}</p>}
          </>
        ))
      ) :''}

      {(!showResult && !makeBlanks) && (
        <button className="btn btn-primary" onClick={startDisplayBlanks}>
          Start
        </button>
      )}

{(!showResult && makeBlanks) && (
  <>
        <div className="blank-sentences">
          {(userAnswerResults.length == 0) ? blanksSentences.map((sentence, index) => (
            <div key={index} className='anjali'>
              {(type == 'english') ? 
              <p data-index={index} data-opt={`${sentence.correct_word}`} dangerouslySetInnerHTML={{ __html: `${index + 1}. ${sentence[type]} ${sentencesType[index]}` }} onClick={() => handleBlankClick(index)}></p>:
              
              <p data-index={index} data-opt={`${sentence.correct_word}`} onClick={() => handleBlankClick(index)} dangerouslySetInnerHTML={{ __html: `${index + 1}. ${sentencesType[index]} ${sentence[type]}` }}></p>}
              
            </div>
          )) : userAnswerResults.map((sentence, index) => (
            <div key={index} className='lalit'>
              {(type == 'english') ? 
            
             <p dangerouslySetInnerHTML={{ __html: `${index + 1}. ${(!sentence.useranswer) ? sentence.question: sentence.useranswer} ${sentencesType[index]}`}}></p>:
              
             <p dangerouslySetInnerHTML={{ __html: `${index + 1}. ${(!sentence.useranswer) ? sentence.question: sentence.useranswer} ${sentencesType[index]}` }}></p>}
              
            </div>
          ))}
          
     
       {/* {renderBlanksSentences()} */}

        </div>
        <div className="blank-sentences-options">
         <div className='answer-option'>

         {removedWords.map((word, index) => (

           <button key={index} onClick={() => handleAnswerOptionClick(word,index)}>
             {word}
           </button>
         ))}
       </div>
       </div>
       <button className="btn btn-primary" onClick={handleSaveClick}>Submit</button>
       </>
      )}
   

      {showResult ?  <div>
          <h2>Quiz Result!</h2>

        <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
  <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart>

           <span className='score'>
    <div className="score-wrap">
        <span className="stars-active" style={{ width:`${percentage}%`}}>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
<span className="stars-inactive">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
</div>
</span>
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {sentences.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            {(userAnswerResults.length > 0) ?  <Table className='anjali' striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Question</th>
                  <th>Correct Answer</th>
                  <th>Your Answer</th>
                </tr>
              </thead>
              <tbody>
              {(userAnswerResults.length > 0) ? userAnswerResults.map((answer, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{(type == 'english') ? `${answer.question} ${sentencesType[index]}`: `${sentencesType[index]} ${answer.question}`}</td>
                    <td>
                    {answer.answer}
                    </td>
                    <td><div dangerouslySetInnerHTML={{ __html: answer.useranswer }} /></td>
                  </tr>
                )) : ''}
              </tbody>
            </Table> : ''}
            {(userAnswerResults.length == 0 && userAnswers.length > 0) ?
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Question</th>
                  <th>Correct Answer</th>
                  <th>Your Answer</th>
                </tr>
              </thead>
              <tbody>
              {(userAnswers.length > 0) ? userAnswers.map((answer, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{(type == 'english') ? `${answer.question}${sentencesType[index]}`: `${sentencesType[index]}${answer.question}`}</td>
                    <td>
                    {answer.answer}
                    </td>
                    <td><div dangerouslySetInnerHTML={{ __html: answer.useranswer }} /></td>
                  </tr>
                )) : ''}
              </tbody>
            </Table>
:''}
          </div>
        
          
        </div> : ''}
    </div>
  );
};

  export function StudyForGrammarFillInBlank() {

    const dispatch = useDispatch();
    const { user: authUser } = useSelector((x) => x.auth);
    const { program_id,type,q_start } = useParams();
    const { passage } = useSelector((x) => x.passage);
//console.log(type,'get type here');
if(type =='english'){
  var sentences = (passage && passage.data) ? passage.data.map(word => word.questions): [];
  var sentencesType = (passage && passage.data) ? passage.data.map(word => word.korean_grammer): [];
  var sentencesForAnswer =
  passage && passage.data
    ? passage.data.map((word) => ({
        english: word.questions,
        korean: word.korean_grammer,
        categorytype: word.categorytype,
      }))
    : [];
}else{
  var sentences = (passage && passage.data) ? passage.data.map(word => word.korean_grammer): [];
  var sentencesType = (passage && passage.data) ? passage.data.map(word => word.questions): [];
  var sentencesForAnswer =
  passage && passage.data
    ? passage.data.map((word) => ({
        english: word.questions,
        korean: word.korean_grammer,
        categorytype: word.categorytype,
      }))
    : [];
}
    
    useEffect(() => {
      var blank = true;
      dispatch(studyActions.getSubprogramsForStudyGrammar({ program_id,q_start, blank }));
    }, []);
  


    return (
      <section>
        <div className="test">
          <h1 className="sentence-title">Grammar: Fill in the Blanks ({type})</h1>
          <FillInBlankSentences sentencesForAnswer={sentencesForAnswer} sentencesType={sentencesType} sentences={sentences} program_id={program_id} type={type} q_start={q_start} />
          <div className="mt-4">

            </div>
        </div>
      </section>
    );
  };
  
  export default StudyForGrammarFillInBlank;