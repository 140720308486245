import { useEffect, useState , useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quizActions, quizresultActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
export { EngdosQuizPage };

function EngdosQuizPage() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [myState, setMyState] = useState('initialValue');
  const { quiz, quizLoading } = useSelector((x) => x.quiz);
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [quizCategory, setquizCategory] = useState();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizalready, setQuizAlready] = useState(false);
  const student = JSON.parse(localStorage.getItem('user'));
  const intervalRef = useRef();
  const [currentBombImage, setCurrentBombImage] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(15);
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
//     useEffect(() => {
//     const timer = setInterval(() => {
//       // Decrease time remaining by 1 second
//       setTimeRemaining(prevTime => prevTime - 1);
//     }, 1000);
  
//     // Cleanup function to clear the interval when the component unmounts or when moving to the next question
//     return () => clearInterval(timer);
//   }, [currentQuestion]); 
//  useEffect(() => {
//     if (timeRemaining === 0 && currentQuestion < quizData.length - 1) {
//       // Move to the next question if time runs out and there are more questions remaining
//       //handleNextQuestion(selectedOption);
//       if (currentQuestion + 1 < quizData.length) {
//         setCurrentQuestion(currentQuestion + 1);
//         setTimeRemaining(15);
//         //setCurrentBombImage(0);
//       } else {
//         setQuizCompleted(true);
//       }
//     }
//   }, [timeRemaining]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000); // 1 hour = 3600000 milliseconds
    const minutes = Math.floor((time % 3600000) / 60000); // 1 minute = 60000 milliseconds
    const seconds = Math.floor((time % 60000) / 1000); // 1 second = 1000 milliseconds
    const milliseconds = Math.floor((time % 1000) / 10);
  
    return {
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
      milliseconds: String(milliseconds).padStart(2, '0')
    };
  };
  // useEffect(() => {
  //   start(); // Start the stopwatch when the component mounts
  //   // Clean-up function to clear the interval when the component unmounts
  //   return () => clearInterval(intervalRef.current);
  // }, []); // Empty dependency array ensures this effect runs only once

  // const start = () => {
  //   if (!isRunning) {
  //     setIsRunning(true);
  //     setTime(0); // Start from zero milliseconds
  //     intervalRef.current = setInterval(() => {
  //       setTime((prevTime) => prevTime + 1000); // Increment by 1 second (1000 milliseconds)
  //     }, 1000);
  //   }
  // };
  // const { hours, minutes, seconds, milliseconds } = formatTime(time);

  const handleOptionSelect = (selected) => {
    setSelectedOption(selected);
    handleNextQuestion(selected);
    setUserAnswers(prevAnswers => [...prevAnswers, selected]);
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const handleNextQuestion = (selectedOption) => {

    console.log(selectedOption, "selected")
    if (selectedOption === quizData[currentQuestion].answer) {
      setScore(score + 1);
    }


    setSelectedOption(null);

    if (currentQuestion + 1 <= quizData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setTimeRemaining(15);
    } else {
      console.log("quiz comp")
    }

    if (currentQuestion == quizData.length - 1) {
      console.log('Setting quizCompleted to true');
      setQuizCompleted(true);
    }

  };

  const handleKeyPress = (event) => {
    const selectedNumber = Number(event.key);
    if (!isNaN(selectedNumber) && selectedNumber > 0 && selectedNumber <= quizData[currentQuestion].options.length) {
      const selectedOption = quizData[currentQuestion].options[selectedNumber - 1];
      handleOptionSelect(selectedOption);
    }
  };

  useEffect(() => {
    try {
      if (quizData.length === 0 && !quizLoading) {
        if (!location.state) {
          window.location.href = '/';
        }
        const categoryid = location.state.vocabulary.id;
        dispatch(quizActions.getQuizQuestions(categoryid));
      }

      if (quiz) {
        const someProp = location.state;
        // console.log(location, "sp");

        setQuizData(shuffleArray([...quiz.data]));
        setquizCategory(location.state.vocabulary.filename);

        if (currentQuestion === 0 && student) {

          console.log(student, "student");

          const categoryid = location.state.vocabulary.id;
          const stdid = student.user.student_id;

          console.log("stdid:", stdid, "categoryid:", categoryid);


          if (stdid && categoryid) {
            dispatch(quizresultActions.getquizresult({ student: stdid, category: categoryid }));
          } else {
            console.error("stdid and/or categoryid is undefined or null");
          }
        }

        // console.log(quizData, "quizdfd");
      }
    }
    catch (error) {
      console.error("An error occurred:", error);
    }
  }, [dispatch, quiz, quizLoading]);


  useEffect(() => {
    // if (quizData.length === 0 && !quizLoading) {
    //   if (!location.state) {
    //     window.location.href = '/'
    //   }
    //   const categoryid = location.state.vocabulary.id;
    //   dispatch(quizActions.getQuizQuestions(categoryid));
    // }

    if (quizresult) {
      console.log(quizresult, "qr")
      if (quizresult.id) {
        setQuizAlready(true)
      }



    }
  }, [dispatch, quizresult, quizresultLoading]);

  useEffect(() => {

    window.addEventListener('keydown', handleKeyPress);


    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (quizCompleted === true) {
      console.log(quizCompleted, "quizcompleted");
      const result = []
      {
        quizData.map((question, index) => (
          result.push({ word: question.word, answer: question.answer, useranswer: (index < userAnswers.length) ? userAnswers[index] : "Not answered" })
        ))
      }

      console.log(student, "student");
      if (student) {
        const option = {
          category: location.state.vocabulary.id,
          score,
          student: student.user.student_id,
          totalQuestions: quizData.length,
          result: JSON.stringify(result)
        }
        console.log(option, "optionnnsss")
        dispatch(quizresultActions.savequizresult(option));
      }

    }
  }, [dispatch, quizCompleted, quizCategory, score, quizData, userAnswers]);

  const renderOptions = () => {
    return quizData[currentQuestion].options.map((option, index) => (
     
					<li  key={index}><span>{index + 1}</span>
           <input
            type="checkbox"
            id={option}
            name="options"
            value={option}
            checked={selectedOption === option}
            onChange={() => handleOptionSelect(option)}
          /> <label for="option1"> {option}</label></li>
				

      // <div key={index} className='quiz-options'>
      //   <label className='option-index'>{index + 1}</label>
      //   <label className="container" htmlFor={option}>
      //     {option}
      //     <input
      //       type="radio"
      //       id={option}
      //       name="options"
      //       value={option}
      //       checked={selectedOption === option}
      //       onChange={() => handleOptionSelect(option)}
      //     />
      //     <span className="checkmark"></span>
      //   </label>
      // </div>
    ));
  };

  const handleRetakeQuiz = () => {
    const shuffledQuizData = shuffleArray([...quizData]);
 
    setQuizCompleted(false);
    setQuizAlready(false)
    setCurrentQuestion(0);
    setSelectedOption(null);
    setScore(0);
    setQuizData(shuffledQuizData);
  };

  const renderQuizContent = () => {
    // if (currentQuestion < quizData.length) {
    if (currentQuestion < quizData.length && quizCompleted == false && quizalready == false) {
      const totalTime = 15;

        var precent=100-((currentQuestion-score)/quizData.length *100);

        const remainingPercentage = (timeRemaining / totalTime) * 100;
        let bombImage = 1;
      if (timeRemaining < 10) {
          bombImage = 2;
      }
      if (timeRemaining < 2) {
          bombImage = 3;
      }
      return (
      <>
        <div class="container vocul-conatiner">
	<div class="vocul-title">
		<h2>{quizCategory}</h2>
	</div>
	<div class="vocul-row">
		<h4>Question {currentQuestion + 1}/{quizData.length}</h4>
    <h6>{currentQuestion === 0 ? "" : `${precent.toFixed(2)} %`}</h6>
		<div class="vocul-Q">
			<h3>{quizData[currentQuestion].word}</h3>
				<ul class="vocul-option">
        {renderOptions()}
				</ul>
		</div>	
	</div>

</div>
        {/* <div className='quiz-content'>
          <div className='quiz-header'>

            <div className='category-head'>
              <h4>{quizCategory}</h4>
            </div>
           
            <div className='quiz-progress'>
           
              <p>Question {currentQuestion + 1} of {quizData.length}</p>
              
              <p>Percentage: {precent.toFixed(2)} %</p>
            </div>
          </div>
          <div className='quiz-question-area'>
          <div class="quiz-question-area-part-one">
            <h3 style={{ textTransform: "none" }}>{quizData[currentQuestion].word}</h3>
            </div>
            <div class="quiz-question-area-part-two">  {renderOptions()}</div>
          
         
          </div>
        </div> */}
        </>
      );
    } else if (quizalready == true && quizresult) {
      const percentage = ((quizresult.score / quizData.length) * 100).toFixed(2);
      console.log(quizresult, "qiz")
      const preresult = JSON.parse(quizresult.result)
      return (

        <div>
          <h2>Quiz Completed!</h2>
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{quizresult.score} / {quizData.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{percentage}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Word</th>
                  <th>Correct Answer</th>
                  <th>User Answer</th>
                  <th>O X</th>
                </tr>
              </thead>
              <tbody>
                {preresult.map((question, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{question.word}</td>
                    <td> {question.answer}</td>
                    <td>{question.useranswer}</td>
                    <th>{(question.answer == question.useranswer) ? 'O' : 'X'}</th>
                  </tr>

                ))}
              </tbody>
            </Table>
          </div>

          <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
        </div>
      );

    } else {
      const percentage = ((score / quizData.length) * 100).toFixed(2);
      return (

        <div>
          <h2>Quiz Completed!</h2>
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {quizData.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{percentage}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Word</th>
                  <th>Correct Answer</th>
                  <th>User Answer</th>
                  <th>O X</th>
                </tr>
              </thead>
              <tbody>
                {quizData.map((question, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{question.word}</td>
                    <td> {question.answer}</td>
                    <td>{index < userAnswers.length ? userAnswers[index] : "Not answered"}</td>
                    <th>{(question.answer == question.useranswer) ? 'O' : 'X'}</th>
                  </tr>

                ))}
              </tbody>
            </Table>
          </div>

          <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
        </div>
      );
    }
  };


  return (
    <div className="quiz-container">
      <div className='quizs-container'> {renderQuizContent()} </div>
    </div>
  );
}
