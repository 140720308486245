import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';

// create slice

const name = 'common';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const commonActions = { ...slice.actions, ...extraActions };
export const commonReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/frontend`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        getJobTitle: getJobTitle(),
    };    

    function getJobTitle() {
        return createAsyncThunk(
            `${name}/job-title`,
            async () => await fetchWrapper.get(`${baseUrl}/job-title/all`)
        );
    }

}

function createExtraReducers() {
    return {
        ...getJobTitle(),
    };

    function getJobTitle() {
        var { pending, fulfilled, rejected } = extraActions.getJobTitle;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                state.jobtitle = action.payload;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
