import { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    studentActions,
    audioActions,
    homeworkActions,
    readingActions,
    libraryActions
  } from "_store";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import $ from 'jquery';

const ScrambleSentences = ({ sentences }) => {
    const [displayedSentenceIndices, setDisplayedSentenceIndices] = useState([]);
    const [currentSentenceIndex, setCurrentSentenceIndex] = useState(null);
    const [scrambledSentence, setScrambledSentence] = useState('');
    const [showTextArea, setShowTextArea] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [showNextButton, setShowNextButton] = useState(false);
    const [showFinishButton, setShowFinishButton] = useState(false);
  const [remainingSentence, setRemainingSentence] = useState(1);
  const [linkList, setLinkList] = useState('');

  const [inputText, setInputText] = useState([]);

  // useEffect(() => {
  //   // Determine the initial transform values when linkList changes
  //   const wordsArray = scrambledSentence.split(' ');
  //   console.log(wordsArray,'get words');
  //   wordsArray.map((word, index) => ({
  //     text: `${word} `,
  //     transform: index % 2 === 0 ? 10 : -10,
  //   }));
  //   setLinkList(wordsArray);
  // }, [scrambledSentence]);

  useEffect(() => {
    //console.log(linkList,'get link list');
    // Determine the initial transform values when linkList changes
    if(linkList.length > 0){
      const updatedList = linkList.map((link, index) => {
        return {
          ...link,
          transform: index % 2 === 0 ? 10 : -10,
        };
      });
      setLinkList(updatedList);
    }

  }, [linkList]);

  const getNextTransform = () => {
    if (linkList.length === 0) {
      return 10; // Start with 10 if the list is empty
    }
    const lastTransform = linkList[linkList.length - 1].transform;
    return lastTransform === 10 ? -10 : 10;
  };

  const handleLinkClick = (index) => {
    setInputText((prevText) => prevText + ' ' + linkList[index].text);
    setLinkList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleWordClick = (word) => {
    const nextTransform = getNextTransform();
    setLinkList((prevList) => [
      ...prevList,
      { text: word, transform: nextTransform },
    ]);
    setInputText((prevText) => prevText.replace(word, '').trim());
  };

  const startScramble = () => {
    const remainingIndices = sentences
      .map((_, index) => index)
      .filter(index => !displayedSentenceIndices.includes(index));

    if (remainingIndices.length > 0) {
      const randomIndex = remainingIndices[Math.floor(Math.random() * remainingIndices.length)];
      setDisplayedSentenceIndices([...displayedSentenceIndices, randomIndex]);
      setCurrentSentenceIndex(randomIndex);
      const randomSentence = sentences[randomIndex];
      setScrambledSentence(scrambleSentence(randomSentence));
      const wordsArray = scrambleSentence(randomSentence).split(' ');
      wordsArray.map((word, index) => ({
        text: `${word} `,
        transform: null,
      }));
      console.log(wordsArray,'get array');
      setLinkList(wordsArray);
      setShowTextArea(true);
      setShowNextButton(true);
      setShowFinishButton(false);
    } else {
      setShowNextButton(false);
      setShowFinishButton(true);
    }
  };

  const scrambleSentence = (sentence) => {
    // Split the sentence into an array of words
    const words = sentence.split(' ');

    // Unarrange the words by shuffling them
    for (let i = words.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [words[i], words[j]] = [words[j], words[i]];
    }
  
    // Join the words back into a sentence
    const unarrangedSentence = words.join(' ');
  
    return unarrangedSentence;
  };

  const handleNext = () => {

    const remainingIndices = sentences
    .map((_, index) => index)
    .filter(index => !displayedSentenceIndices.includes(index));
    setRemainingSentence(remainingIndices.length);
    console.log(remainingIndices.length);
  if (remainingIndices.length > 0) {
    const randomIndex = remainingIndices[Math.floor(Math.random() * remainingIndices.length)];
    setDisplayedSentenceIndices([...displayedSentenceIndices, randomIndex]);
    setCurrentSentenceIndex(randomIndex);
    const randomSentence = sentences[randomIndex];
    setScrambledSentence(scrambleSentence(randomSentence));
    setUserInput('');
    setShowNextButton(true);
    setShowFinishButton(false);
  } else {
    setShowNextButton(false);
    setShowFinishButton(true);
  }
  };

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
   // setShowNextButton(event.target.value === sentences[currentSentenceIndex]);
  };

  const handleFinish = () => {
    // Handle finish button click
    console.log("Finished!");
  };




  return (
    <div className='sentences-list'>

      {!showTextArea ? sentences.map((sentence, index) => (
        <p key={index}>{`${index + 1}. ${sentence}`}</p>
      )) : ''}
     {!showTextArea ? <button className="btn btn-primary" onClick={startScramble}>Start</button> : ''}

      {showTextArea && (
        <div className="answer-section">
            <p className='correct-answer'>Correct Answers : 0</p>
            <p className='remaining-sentence'>{remainingSentence} of {sentences.length}</p><br />
            <p className='hear-section'><i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i></p>

            <ul style={{ display: 'inline', listStyle: 'none', padding: 0 }}>
            {linkList.map((link, index) => (
              <li
                key={index}
                style={{ display: 'inline', marginRight: '5px' }}
                onClick={() => handleLinkClick(index)}
              >
                <a href="#" style={{ transform: `rotate(${link.transform}deg)`, display :'inline-block' }}>
                  {link.text}
                </a>
              </li>
            ))}
      </ul>
      {/* <div>
        {inputText.split(' ').map((word, index) => (
          <span
            key={index}
            onClick={() => handleWordClick(word)}
            style={{ cursor: 'pointer', marginRight: '5px' }}
          >
            {word}
          </span>
        ))}
      </div> */}
          <textarea value={userInput} onChange={handleInputChange} />
          {showNextButton && <button onClick={handleNext}>Next</button>}
          {showFinishButton && <button onClick={handleFinish}>Finish</button>}
        </div>
      )}
    </div>
  );
};

  export function SentenceUnscramble() {

    const dispatch = useDispatch();
    const { user: authUser } = useSelector((x) => x.auth);
    const { homework_id, program_id } = useParams();
    const { library } = useSelector((x) => x.library);

    const [sentences, setSentences] = useState([]);

    useEffect(() => {
      if (library && library.data) {

     const sentenceQuestions = library.data.words.map(word => word.sentence_question);
     setSentences(sentenceQuestions);
      }
    }, [library]);
    console.log(sentences,'get user');


    useEffect(() => {
      dispatch(libraryActions.getSentencesForLibrary({program_id}));
    }, []);
  
    // Function to scramble the sentence
    const scrambleSentence = (sentence) => {
        const words = sentence.split(' ');
        const scrambledWords = words.map(word => {
          const characters = word.split('');
          characters.sort(() => Math.random() - 0.5);
          return characters.join('');
        });
        return scrambledWords.join(' ');
      };

    return (
      <section>
        <div className="test">
          <h1 className="sentence-title">Sentence Unscramble</h1>
          <ScrambleSentences sentences={sentences} />
          <div className="mt-4">

            </div>
        </div>
      </section>
    );
  };
  
  export default SentenceUnscramble;
  

