import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quizcategoryActions ,quizresultActions} from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

export { Quizsection };

function Quizsection() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [category, setcategory] = useState("");
  const { quizcategory, quizcategoryLoading } = useSelector((x) => x.quizcategory);
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);

  const student=JSON.parse(localStorage.getItem('user'));
  const vocbsetting=location.state.vocabsettings;
  const vocabcategory=location.state?location.state.vocabulary:''


  useEffect( () => {
 
      if (!location.state) {
        window.location.href = '/';
      }else{
        setcategory(location.state.vocabulary.filename)
        if(student.user.student_id){
          dispatch(quizcategoryActions.getquizcategory({ student: student.user.student_id, category: location.state.vocabulary.id }));
        }
        if(quizcategory){
          console.log(quizcategory,"catgq")
        }
      }

  }, [dispatch,quizcategory,quizcategoryLoading]);

  const renderQuizContent = () => {
    if(quizcategory){
    let cumulativeCount = 0;
  
    // Use map to generate an array of <td> elements
    const quizElements = quizcategory.map((question, index) => {
      cumulativeCount += question.length;
      return (
        <div className='col-md-2 ' key={index}>
          <div className='quiz-set'>
          <Link to={{ pathname: '/testquiz' }} state={{ "setindex":index,"vocabulary": vocabcategory,"questions":question ,"vocbsetting":vocbsetting}}>
          <img src="/images/text_book_vac_one.png" />
          <h5 className='question-set'>{cumulativeCount - question.length + 1}-{cumulativeCount}</h5>
        </Link>
        </div>
        </div>
      );
    });
  
    // Return the generated elements within a container (e.g., a <table>)
    return (
      <div className='row quiz-section-set'>
     
          {quizElements}
      
      </div>
    );
    }
  };
  




  return (
    <div className="container">
        <div className='quiz-set-page'>
     <h1>VOCABULARY CHALLENGES</h1>
      <h2>{category}</h2>
     {renderQuizContent()}
     </div>
        
      
   </div>
  );
}
