import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reportsActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import axios from "axios";
export { MilestoneTestReport };

function MilestoneTestReport() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { student_id } = useParams();
  const date = new Date().toISOString().slice(0, 10);
  const { reports } = useSelector((x) => x.reports);

  useEffect(() => {
    var studentId = student_id;
    dispatch(reportsActions.getVocabularyReport({studentId,date}));
  }, []);

  return (
    <div className="milestone-report-container">
<div className="container">
  <div className="heading-main">
    <h1>
      <span className="colorone">LIKE</span>
      <span className="colortwo">STUDY</span> Milestone
    </h1>
  </div>
  <div className="milestone-month">
    <div className="month red">
      <div className="icon" style={{ top: "20%", border: "1px dashed red" }}>
        <img
          style={{ border: "4px solid red" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "red" }}>
        JAN
      </div>
    </div>
    <div className="month orange">
      <div className="icon" style={{ top: "15%", border: "1px dashed orange" }}>
        <img
          style={{ border: "4px solid orange" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "orange" }}>
        FAB
      </div>
    </div>
    <div className="month yellow">
      <div className="icon" style={{ top: "12%", border: "1px dashed yellow" }}>
        <img
          style={{ border: "4px solid yellow" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "yellow" }}>
        MAR
      </div>
    </div>
    <div className="month parrot">
      <div
        className="icon"
        style={{ top: "14%", border: "1px dashed greenyellow" }}
      >
        <img
          style={{ border: "4px solid greenyellow" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "greenyellow" }}>
        APR
      </div>
    </div>
    <div className="month green">
      <div className="icon" style={{ top: "16%", border: "1px dashed green" }}>
        <img
          style={{ border: "4px solid green" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "green" }}>
        MAY
      </div>
    </div>
    <div className="month sea-green">
      <div
        className="icon"
        style={{ top: "13%", border: "1px dashed seagreen" }}
      >
        <img
          style={{ border: "4px solid seagreen" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "seagreen" }}>
        JUN
      </div>
    </div>
    <div className="month sky-blue">
      <div
        className="icon"
        style={{ top: "18%", border: "1px dashed skyblue" }}
      >
        <img
          style={{ border: "4px solid skyblue" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "skyblue" }}>
        JUL
      </div>
    </div>
    <div className="month ry-blue">
      <div
        className="icon"
        style={{ top: "16%", border: "1px dashed royalblue" }}
      >
        <img
          style={{ border: "4px solid royalblue" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "royalblue" }}>
        AUG
      </div>
    </div>
    <div className="month blue">
      <div className="icon" style={{ top: "14%", border: "1px dashed blue" }}>
        <img
          style={{ border: "4px solid blue" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "blue" }}>
        SEP
      </div>
    </div>
    <div className="month purple">
      <div className="icon" style={{ top: "18%", border: "1px dashed purple" }}>
        <img
          style={{ border: "4px solid purple" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "purple" }}>
        OCT
      </div>
    </div>
    <div className="month violet">
      <div className="icon" style={{ top: "8%", border: "1px dashed violet" }}>
        <img
          style={{ border: "4px solid violet" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "violet" }}>
        NOV
      </div>
    </div>
    <div className="month pink">
      <div className="icon" style={{ top: "0%", border: "1px dashed pink" }}>
        <img
          style={{ border: "4px solid pink" }}
          src="images/d-board-left-i-6.webp"
        />
      </div>
      <div className="month-name" style={{ background: "pink" }}>
        DEC
      </div>
    </div>
  </div>
  <div className="date">
    <p>DATE: 2023. 11. 10.</p>
  </div>
  <div className="stu-detail">
    <table>
      <tbody>
        <tr>
          <th>Student Name:</th>
          <td>손태건</td>
        </tr>
        <tr>
          <th>English name:</th>
          <td>Nangman</td>
        </tr>
        <tr>
          <th>Class:</th>
          <td>Pre IS 3</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div className="result-detail">
    <table>
      <tbody>
        <tr>
          <th>Language Areas</th>
          <th>Skill</th>
          <th>Milestone</th>
          <th>Level</th>
          <th>Comments</th>
          <th>Date</th>
        </tr>
        <tr>
          <td>Listening</td>
          <td className="bg-fade">Dialog Listening</td>
          <td>Beginner (21-60)</td>
          <td className="bg-fade">2 </td>
          <td>
            At this level, speakers can participate in simple conversations on
            topics that are familiar or of personal interest.
          </td>
          <td>11/8/2023</td>
        </tr>
        <tr>
          <td>Writing</td>
          <td className="bg-fade">essay</td>
          <td>50 word writing</td>
          <td className="bg-fade">3</td>
          <td>5 sentences (35-50 words)</td>
          <td>11/8/2023</td>
        </tr>
        <tr>
          <td>Vocabulary</td>
          <td className="bg-fade">Vocabulary</td>
          <td>초 5-6 영한 </td>
          <td className="bg-fade">3 </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
          <td>11/8/2023</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div className="img-container">
    <img src="images/center-img.png" />
  </div>
  <div className="foot-info">
    <h3>
      {" "}
      <span>LIKE ACADEMY</span> <span>053) 424-2244 / 0909</span>{" "}
    </h3>
    <h4>We Support Your Future!</h4>
  </div>
</div>

</div>

  );
}

