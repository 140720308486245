import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'myrecording';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const myrecordingActions = { ...slice.actions, ...extraActions };
export const myrecordingReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        myrecording: myrecording(),
        editmyrecording:editmyrecording(),
    };    

    function myrecording() {
        return createAsyncThunk(
            `${name}/myrecording`,
            async ({ topic_name, user }) => await fetchWrapper.post(`${baseUrl}/myrecording/create`, { topic_name, user})
        );
    }

    function editmyrecording() {
        return createAsyncThunk(
            `${name}/myrecording`,
            async ({ record_id, topic_name }) => await fetchWrapper.post(`${baseUrl}/myrecording/edit`, { record_id, topic_name })
        );
    }

}

function createExtraReducers() {
    return {
        ...myrecording(),
        ...editmyrecording(),
    };

    function myrecording() {
        var { pending, fulfilled, rejected } = extraActions.myrecording;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const myrecording = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                state.myrecordings = myrecording;
                console.log(myrecording,'sds');
                // toast.success('Check your email to verify the account..', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     });
                // get return url from location state or default to home page
                // const { from } = { from: { pathname: '/verify-email' } };
                // history.navigate(from);
            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er123');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function editmyrecording() {
        var { pending, fulfilled, rejected } = extraActions.editmyrecording;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const myrecording = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                state.myrecordings = myrecording;
                console.log(myrecording,'sds');
                // toast.success('Check your email to verify the account..', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     });
                // get return url from location state or default to home page
                // const { from } = { from: { pathname: '/verify-email' } };
                // history.navigate(from);
            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er anj');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

}
