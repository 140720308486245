import { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    studentActions,
    audioActions,
    homeworkActions,
    readingActions,
    quizActions 
  } from "_store";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import $ from 'jquery';
import { Table } from "react-bootstrap";
const settings = {
    bars: 7,
    spacing: 3,
    width: 5,
    height: 100
  };
  const WordDisplay = ({ words, isRecording }) => {
    const [visibleWords, setVisibleWords] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allWordsDisplayed, setAllWordsDisplayed] = useState(false);
  
    useEffect(() => {
      let intervalId = null;
  
      if (isRecording && !allWordsDisplayed) {
        intervalId = setInterval(() => {
          setVisibleWords(() => {
            const nextVisibleWords = words.slice(currentIndex, currentIndex + 3);
            setHighlightedIndex(0); // Highlight the first word initially
  
            // Reset the highlight index for the next rotation
            setTimeout(() => {
              setHighlightedIndex(-1);
            }, 100);
  
            setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
  
            if (currentIndex === words.length - 1) {
              clearInterval(intervalId); // Stop word movement when all words have been displayed
              setAllWordsDisplayed(true); // Set flag to indicate all words have been displayed
            }
  
            return nextVisibleWords;
          });
        }, 3000);
      }
  
      return () => clearInterval(intervalId);
    }, [isRecording, words, currentIndex, allWordsDisplayed]);
  
    useEffect(() => {
      // Initialize the visible words when the component mounts
      setVisibleWords(words.slice(0, 3));
    }, [words]);
  
    return (
      <div>
        <ul>
          {visibleWords.map((word, index) => (
            <li
              key={index}
              style={{
                backgroundColor: index === highlightedIndex ? 'lightblue' : 'transparent',
              }}
            >
              {word.word}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  // const WordDisplay = ({ words }) => {
  //   const [visibleWords, setVisibleWords] = useState([]);
  //   const [highlightedIndex, setHighlightedIndex] = useState(-1);
  
  //   useEffect(() => {
  //     let currentIndex = 0;
  
  //     // Update visible words every 3 seconds
  //     const intervalId = setInterval(() => {
  //       setVisibleWords(() => {
  //         const nextVisibleWords = words.slice(currentIndex, currentIndex + 3);
  //         setHighlightedIndex(0); // Highlight the first word initially
  
  //         // Reset the highlight index for the next rotation
  //         setTimeout(() => {
  //           setHighlightedIndex(-1);
  //         }, 100);
  
  //         currentIndex = (currentIndex + 1) % words.length;
  //         return nextVisibleWords;
  //       });
  //     }, 3000);
  
  //     // Cleanup interval on component unmount
  //     return () => clearInterval(intervalId);
  //   }, [words]);
  
  //   useEffect(() => {
  //     // Initialize the visible words when the component mounts
  //     setVisibleWords(words.slice(0, 3));
  //   }, [words]);
  
  //   return (
  //     <div>
  //       <ul>
  //         {visibleWords.map((word, index) => (
  //           <li
  //             key={index}
  //             style={{
  //               backgroundColor: index === highlightedIndex ? 'lightblue' : 'transparent',
  //             }}
  //           >
  //             {word}
  //           </li>
  //         ))}
  //       </ul>
  //     </div>
  //   );
  // };
  
  export function StudyForVocabularyReadOutLoud() {
    // Replace this with your actual list of words
    //const wordList = ['declare', 'demand', 'jabber', 'recess', 'smash', 'stink bug', 'survey', 'toughest', 'weird', 'whisper'];
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((x) => x.auth);
    const { record_id,q_start } = useParams();
    console.log(record_id,'get r');
    const { quiz } = useSelector((x) => x.quiz);
    const { reading } = useSelector((x) => x.reading);
    const { myrecordings } = useSelector((x) => x.myrecordings);
    const [textValue, setTextValue] = useState("");
    const initialMinutes = 1;
    const initialSeconds = initialMinutes * 75;
    const [teacherResult, setTeacherResult] = useState(null);
    const [totalSeconds, setTotalSeconds] = useState(initialSeconds);
    const audioRef = useRef(null);
    const [audioFile, setAudioFile] = useState("");
    const [filePath, setFilePath] = useState("");
    const [audioQuality, setAudioQuality] = useState("");
    const [recordId, setRecordId] = useState("");
    const recorderControls = useAudioRecorder();
    const [showDialog, setShowDialog] = useState(false);
    const refs = useRef([]);
    const volumemeter = useRef(0);
    const volumeRefs = useRef(new Array(settings.bars));
    const [imageSource, setImageSource] = useState('/images/record.png');
    const [wordList, setWordList] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const { audio } = useSelector((x) => x.audio);

    useEffect(() => {
        const vocab_id = record_id;
    
        dispatch(quizActions.getVocabQuestions({vocab_id,q_start}));
      }, []);
      useEffect(() => {
        const allWords = (quiz && quiz.data) ? quiz.data.map(item => ({ word: item.word, audio: item.audio })) : [];
        console.log(allWords,'get all words');
        setWordList(allWords);
      }, [quiz]);
      

    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
  
    const addAudioElement = async (blob) => {
      const url = URL.createObjectURL(blob);
      //alert("lalit");
  
      blobToBase64(blob).then((res) => {
        // do what you wanna do
        console.log(res, "blob"); // res is base64 now
      });
      //
      const formData = new FormData();
      formData.append("audio", blob);
      formData.append("user", authUser.user.student_id);
      //formData.append("homework_id", homework_id);
      //var text = (homework && homework.data) ? homework.data.homework_text :'';
      formData.append("text", '');
      formData.append("type", 'Vocab Read Out Loud');
      const response = await axios.post(
        "https://api.webtracktechnologies.com.au:8002/audio-recording",
        formData
      );
      setAudioFile(response.data.data);
      setFilePath(response.data.filepath);
      setRecordId(response.data.audio_record.id);
      //  const audio1 = document.createElement("audio");
      //   audio1.src = url;
      //   audio1.controls = true;
    };
  
    const handleTextareaChange = (event) => {
      setTextValue(event.target.value);
    };
  
    useEffect(() => {
      if (recorderControls.isRecording) {
        const interval = setInterval(() => {
          if (totalSeconds > 0) {
            setTotalSeconds(totalSeconds - 1);
          }
        }, 1000);
  
        return () => clearInterval(interval);
      }
    }, [recorderControls.isRecording, totalSeconds]);
  
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };
  
    const handleListenClick = () => {
      setShowModal(true);
    };
    const handleCloseModal = () => {
      setShowModal(false);
    };
    const handlePlayAll = () => {
      // Play audio files for all sentences
      wordList.forEach((word, index) => {
        setTimeout(() => {
          playAudio(index);
        }, index * 1000); // Delay each audio play by 3 seconds
      });
    };
  
    const playAudio = (index) => {
      const audioElement = document.getElementById(`audio-${index}`);
      audioElement.play();
      setHighlightedIndex(index); // Highlight the currently playing word
    };

    const startRecording = () => {
      
        recorderControls.startRecording;
        setIsRecording(true);
    }

    const getMedia = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();
          const microphone = audioContext.createMediaStreamSource(stream);
          const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
          analyser.smoothingTimeConstant = 0.4;
          analyser.fftSize = 1024;
          microphone.connect(analyser);
          analyser.connect(javascriptNode);
          javascriptNode.connect(audioContext.destination);
          javascriptNode.onaudioprocess = () => {
            var array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            var values = 0;
            var length = array.length;
            for (var i = 0; i < length; i++) {
              values += array[i];
            }
            volumemeter.current = values / length;
          };
        })
        .catch(function(err) {
          /* handle the error */
        });
    };
  
    useEffect(getMedia, []);
    useEffect(() => {
      const intervalId = setInterval(() => {
        volumeRefs.current.unshift(volumemeter.current);
        volumeRefs.current.pop();
        if(refs.current.length > 0){
          //console.log(refs.current,'get reference');return false;
          for (let i = 0; i < refs.current.length; i++) {
          if(refs.current[i] != '' && refs.current[i] != null){
  
            refs.current[i].style.transform = `scaleY(${volumeRefs.current[i] /
              100})`;
          }
          }
        }
      }, 20);
      return () => {
        clearInterval(intervalId);
      };
    }, []);
  
    const createElements = () => {
      let elements = [];
  
      for (let i = 0; i < settings.bars; i++) {
        elements.push(
          <div
            ref={ref => refs.current.push(ref)}
            key={`vu-${i}`}
            style={{
              position: "absolute",
              background: "#5ac63d",
              borderRadius: settings.width + "px",
              width: settings.width + "px",
              height: Math.sin((i / settings.bars) * 4) * settings.height + "px",
              left: i * (settings.width + settings.spacing) + "px",
              top:
              settings.height / 2 -
              Math.sin((i / settings.bars) * 4) * settings.height / 2 +
              "px"
            }}
          />
        );
      }
      return elements;
    };
    useEffect(() => {
      if(recorderControls.stopRecording){
        $('.audio-recorder-mic').attr('src',imageSource);
      }
  }, [recorderControls.stopRecording]); 
  useEffect(() => {
    if (recordId) {
      const string = wordList.map(item => item.word).join(' ');
      console.log(string,'get string');
      var file = filePath;
      var text = string;//JSON.stringify(wordList);
      var type = 'Study';
      var sub_type = 'Vocabulary: Read out loud';
      var vocab_id = parseInt(record_id);
      var vocab_start = q_start;
      var vocab_end = parseInt(q_start) + 9;
      const studentInfo = JSON.parse(localStorage.getItem('user'));
      var class_id = studentInfo.user.Student.class_name;
     dispatch(readingActions.readingaudiorecording({ recordId,text, file, type,sub_type, vocab_id , vocab_start,vocab_end,class_id}));
    }
  }, [recordId]);
  useEffect(() => {
    if (reading) {
        window.location.href = '/StudyReadOutLoudGrader/'+recordId+'/VROL';
    }
  }, [reading]);
  const getContentAverageScore = (content) => {
    var value = 0;
    if (content <= 5) {
      
      value = 0;
    } else if (content >= 6 && content <= 10) {
      value = 0.7;
    } else if (content >= 11 && content <= 20) {
      value = 1.5;
    } else if (content >= 21 && content <= 40) {

      value = 2.5;
    } else if (content >= 41 && content <= 80) {

      value = 3.3;
    }else if (content >= 81 && content <= 100) {

      value = 3.7;
    }else if (content >= 101) {

      value = 4;
    }
    //setContent(value);
    return value;
   
  };
  const getFluencyAverageScore = (fluency) => {
    console.log(fluency, "get fluency");
    var value = 0;
    if(Math.round(fluency) <= 30){
      value = 0;
    }else if(Math.round(fluency) >= 30 && Math.round(fluency) <= 50){
      value = 1;
    }
    else if(Math.round(fluency) >= 51 && Math.round(fluency) <= 80){
      value = 2;
    }
    else if(Math.round(fluency) >= 81 && Math.round(fluency) <= 120){
      value = 3;
    }
    else if(Math.round(fluency) >= 121 && Math.round(fluency) <= 150){
      value = 4;
    } else if(Math.round(fluency) >= 151 && Math.round(fluency) <= 180){
      value = 4;
    } else if(Math.round(fluency) >= 180){
      value = 4;
    }
   // setFluency(value);
   return value;
  };
  const getFluencyRemarks = (fluency) => {
    console.log(fluency, "get fluency");
    var text = 'There is no recording or your recording is extremely short.';
    if(fluency == 0){
      text = 'There is no recording or your recording is extremely short.';
    }else if(fluency == 1){
      text = 'Your speech is slow.';
    }
    else if(fluency == 2){
      text = 'Your speech is relatively slow.';
    }
    else if(fluency == 3){
      text = 'Your speech is relatively fast.';
    }
    else if(fluency == 4){
      text = 'Your speech is fast.';
    } 
   // setFluency(value);
   return text;
  };
  const getTotalAverageScore = (content,fluency) => {
    if (!isNaN(content) && !isNaN(fluency)) {
      const avg = (content + fluency) / 2;
      return avg;
    }
  };
  const getContentRemarks = (content) => {
    console.log(content, "get fluency");
    var text = 'There is no recording or your content score is equal to or less than 20%.';
    if(content == 0){
      text = 'There is no recording or your content score is equal to or less than 20%.';
    }else if(content == 0.7){
      text = 'Your content score is 21-40%.';
    }
    else if(content == 1.5){
      text = 'Your content score is 41-60%.';
    }
    else if(content == 2.5 || content == 3.3){
      text = 'Your content score is 61-80%.';
    }
    else if(content == 3.7 || content == 4){
      text = 'Your content score is 81-100%.';
    } 
   // setFluency(value);
   return text;
  };

  const getTotalRemarks = (total) => {
    console.log(total, "get fluency");
    var text = 'Nothing is recorded or no effort taken at all.';
    if(total == 0){
      text = 'Nothing is recorded or no effort taken at all.';
    }else if(total > 0 && total <= 1){
      text = 'Your speech is hardly recognizable. There are a lot of mistakes and errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with too many unnecessary pauses.';
    }
    else if(total > 1 && total <=2){
      text = 'Your speech is intelligible, but it requires much effort for one to understand. There are many noticeable mistakes and consistent errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with many unnecessary pauses.';
    }
    else if(total > 2 && total <= 3){
      text = 'Though it may require some effort, your speech is intelligible, showing relatively high oral proficiency and skills. It contains some errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with some unnecessary pauses.';
    }
    else if(total > 3 && total == 4){
      text = 'Your speech is native like. It is highly intelligible, showing high oral proficiency and skills. Your pronunciation, grammar, vocabulary use, fluency, and style are natural, accurate, and highly proficient.';
    } 
   // setFluency(value);
   return text;
  };
    return (
      <section>
        <div className="test">
          <h1>VOCABULARY READ OUT LOUD</h1>
          {(!audio) ? <>
          <WordDisplay words={wordList}  isRecording={isRecording} />
          <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Listen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {wordList.map((word, index) => (
              <li key={index} attr-audio={word.audio} onClick={() => playAudio(index)} style={{ fontWeight: index === highlightedIndex ? 'bold' : 'normal' ,background: index === highlightedIndex ? 'rgb(15, 89, 168)': '#fff' , color: index === highlightedIndex ? '#fff': '#000'}}>
                {word.word}
                <audio id={`audio-${index}`} src={word.audio} />
                </li>
           
           ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePlayAll}>
            Play All
          </Button>
        </Modal.Footer>
      </Modal>
          <div className="mt-4">
              <div className="block my-recording-micro">
                <i className="fa fa-microphone big" aria-hidden="true" />
                {
          (recorderControls.isRecording ) ? 
          <div className="micro-sound-meter" style={{ position: "relative" }}>{createElements()}</div>: ''
        }
                <p>Countdown Timer</p>
  
               
                {recorderControls.isRecording ? (
  
                  (!showDialog) ?
                  <>
                  <p>
                  {recorderControls.isRecording
                    ? formatTime(totalSeconds)
                    : "01:15"}
                </p>
                  <button
                    onClick={recorderControls.stopRecording}
                    className="test-btn stop"
                  >
                    Stop
                  </button>
                 
                  </>
                  :
                  ''
                ) : (
                  (!showDialog) ? 
                  <>
                  <button
                    className="test-btn"
                    onClick={() => startRecording()}
                  >
                    
                    <AudioRecorder
                      onRecordingComplete={(blob) => addAudioElement(blob)}
                      recorderControls={recorderControls}
                      audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                      }}
                      downloadOnSavePress={true}
                      downloadFileExtension="mp3"
                      showVisualizer={true}
                    />
                  </button>
                   <button onClick={() => handleListenClick()}>Listen</button>
                   </>
                  : ''
                )}
  
                {audioFile ? (
                  <>
                    <audio className="hide" ref={audioRef} controls>
                      <source src={audioFile} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                   
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
       
        </>
        : ''}
         </div>
        <div className="container">
        <div className="row audio-result">
          <div className="col-lg-12">
            {audio ? (
              <>
                <audio ref={audioRef} controls>
                  <source src={audioFile} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
                <button
                  onClick={()=>window.location.reload()}
                  className="test-btn"
                >
                  Record Again
                </button>
                {/* <a href={`/study/view-test-details/${test_id}`} className="test-btn">
                 Back To Test
                </a> */}
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th colSpan={5} style={{ textAlign: "center" }}>
                      READ-OUT-LOUD GRADER
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{authUser.user.name}</td>
                      <td rowspan="5" className="result">
                        { getTotalAverageScore(getContentAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        ), getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        )) >= 3  ? (
                          <h4 style={{ color: "green" }}>Pass</h4>
                        ) : (
                          <h4 style={{ color: "red" }}>Fail</h4>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Test Date</td>
                      <td>{new Date().toLocaleString('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short'
})}</td>
                    </tr>
                    <tr>
                      <td>Audio Quality</td>

                      <td>{audioQuality}</td>
                    </tr>
                    <tr>
                      <td>Total Recording Time</td>
                      <td>
                        {(audio.data.result.message) ?  parseInt(
                          audio.data.result.message.AAResult[0].audioLenSec[0]._
                        ) : 0}{" "}
                        Seconds
                      </td>
                    </tr>
                    <tr>
                      <td>Total Speaking Time</td>
                      <td>
                        {(audio.data.result.message) ? 
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].spkTimeSec[0] : 0
                        }{" "}
                        Seconds
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Scores</td>
                      <td colSpan={5}>Comments</td>
                    </tr>
                    <tr>
                      <td>Content</td>
                      <td>
                        {getContentAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        )}
                        /4
                      </td>
                      <td colSpan={5}>
                        {
                          getContentRemarks(getContentAverageScore(audio.data.result.message.AAResult[0].analysis[0].speakingSummary[0].nWord[0]))
                          
                        }
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Fluency</td>
                      <td>
                        {getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        )}
                        /4
                      </td>
                      <td colSpan={5}>
                        {getFluencyRemarks(getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        ))}
                      </td>
                    </tr> */}

                    <tr>
                      <td>Total</td>
                      <td>
                        {
                         getTotalAverageScore(getContentAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        ), getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        ))
                        }/4
                      </td>
                      <td colSpan={5}>{getTotalRemarks(getTotalAverageScore(getContentAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        ), getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        )))}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      </section>
    );
  };
  
  export default StudyForVocabularyReadOutLoud;
  

