import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';

export { Footer };

function Footer() {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    // only show nav when logged in
    //if (!authUser) return null;
    
    return (
    //     <nav className="navbar navbar-expand navbar-dark bg-dark">
    //     <div className="navbar-nav">
    //         <NavLink to="/" className="nav-item nav-link">Home</NavLink>
    //         <button onClick={logout} className="btn btn-link nav-item nav-link">Logout</button>
    //     </div>
    // </nav>
<>
  {/*Footer*/}
  <footer>
        <div className="footer-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="footer-content">
                  <a className="navbar-brand ms-0 py-0" href="#"><img className="footer-logo" src="https://likestudy.webtracktechnologies.com.au/images/LIKEstudylogo.png" /></a>
                  <p style={{color: '#fff', marginTop: '25px'}}>Like Study is an immersive learning experience that provides comprehensive, English content for Pre-K to College.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="footer-content">
                  <h4>What we offer</h4>
                  <ul>
                    <li><a href="#">Membership</a></li>
                    <li><a href="#">Awards</a></li>
                    <li><a href="#">Analytics</a></li>
                    <li><a href="#">Standards</a></li>
                    <li><a href="#">Mobile apps</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="footer-content">
                  <h4>Resources</h4>
                  <ul>
                    <li><a href="#">Help centre</a></li>
                    <li><a href="#">Tell us what you think</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="#">Testimonials</a></li>
                    <li><a href="#">Contact us</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="footer-content">
                  <h4>International</h4>
                  <ul>
                    <li><a href="#">United States</a></li>
                    <li><a href="#">Korea</a></li>
                  </ul>
                  <h3>Follow Us</h3>
                  <ul className="footer-social">
                    <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="#"><i className="fab fa-twitter" /></a></li>
                    <li><a href="#"><i className="fab fa-youtube" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 align-self-center">
                  <div className="tc">
                    <ul>
                      <li><a href="#">Company</a></li>
                      <li><a href="#">Careers</a></li>
                      <li><a href="#">Terms of service</a></li>
                      <li><a href="#">Privacy policy</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 align-self-center">
                  <div className="tc-para">
                    <p className="custom-para">© 2023 LikeStudy. All rights reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
</>


  
    );
}