import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { studyActions } from '_store';

import { history } from '_helpers';


export { VocabularyDiagnostic };

function VocabularyDiagnostic() {
  const dispatch = useDispatch();
  const { passage } = useSelector((x) => x.passage);
  const [showTestDialog, setShowTestDialog] = useState(false);
  const [testText, setTestText] = useState('');
  const [testLevelText, setTestLevel] = useState('');
  const { user: authUser } = useSelector((x) => x.auth);
  const [selectedTest, setSelectedTest] = useState(null);

console.log(authUser,'get class', passage);
  useEffect(() => {
    dispatch(studyActions.studyTestList());

 }, []);

 const addNewTest = () => {
  
  setShowTestDialog(true);
};
const handleTestCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowTestDialog(false);
};
const handleTestTextChange = (e) => {
  setTestText(e.target.value);
};

const saveTest = async () => {
  if (selectedTest) {
    // Update existing test
    const { id } = selectedTest;
    const studytest_id = id;
    const test_title = testText;
    const level = testLevelText;
    const teacher_id = authUser.user.teacher_id;
  return dispatch(studyActions.editStudyTest({ studytest_id, test_title, level, teacher_id }));
  } else{
  var test_title = testText;
  var level = testLevelText;
  var teacher_id = authUser.user.teacher_id;
  console.log(test_title,level,teacher_id,'get all values here');
  return dispatch(studyActions.createStudyTest({test_title,level,teacher_id}));
  }
};

const handleTestLevelChange = (e) => {
  setTestLevel(e.target.value);
};
const deleteStudyTest = async (id) => {
  const confirmed = window.confirm('Are you sure you want to delete this item?');

  if (confirmed) {
    var studytest_id = id;
    var res = dispatch(studyActions.deleteStudyTest({studytest_id}));
    if(res){
      window.location.reload();
    }
  }
};

const editTestClick = (id) => {
  // Find the study test with the specified id from the passage data
  if(passage && passage.data){
    const selectedStudyTest = passage.data.find(test => test.id === id);
    if (selectedStudyTest) {
      // Set the values for editing
      setTestText(selectedStudyTest.test_title);
      setTestLevel(selectedStudyTest.level);
      setSelectedTest(selectedStudyTest);
      setShowTestDialog(true);
    }
  }

};


    return (

      <section className="custom-sign-vocab-diagnostic">

<div className="container">
  <div className="row">
    <h2>Vocabulary Diagnostic</h2>
    <h3>Select school and language</h3>
    <div className="col-md-6">
     <a href="/vocabulary-diagnostic-test/Korean/ETK/false"> 
     <div className="name-card">
        <h4>국내 학교 영어 
          영-한</h4>
      </div>
      </a>
    </div>
    <div className="col-md-6">
    <a href="/vocabulary-diagnostic-test/US/ETK/false"> 
      <div className="name-card">
        <h4>미국 학교 영어 
          영-한</h4>
      </div>
      </a>
    </div>
    <div className="col-md-6">
    <a href="/vocabulary-diagnostic-test/Korean/ETE/false"> 
      <div className="name-card">
        <h4>국내 학교 영어 
          영-영</h4>
      </div>
      </a>
    </div>
    <div className="col-md-6">
    <a href="/vocabulary-diagnostic-test/US/ETE/false"> 
      <div className="name-card">
        <h4>미국 학교 영어 
          영-영</h4>
      </div>
      </a>
    </div>
    <a href="/study/studyTests">View Test</a>
    <a href="/LevelTestScoreReport">Achievement Test Score</a>
    <a href="/">See All Result</a>
  </div>
</div>



    </section>
      
    )
}
