import { useEffect , useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { libraryActions , studentActions, homeworkActions} from '_store';

import { history } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";

export { StudentLibraryHomework };

function StudentLibraryHomework() {
  const dispatch = useDispatch();
  const { class_id, student_id } = useParams();
  const { library } = useSelector((x) => x.library);
  const { user: authUser } = useSelector((x) => x.auth);
  const { student } = useSelector((x) => x.student);
  const { homework } = useSelector((x) => x.homework);
  const [readingActiveImage, setReadingctiveImage] = useState([]);
  const [speakingActiveImage, setSpeakingActiveImage] = useState([]);
  const [writingActiveImage, setWritingActiveImage] = useState([]);
  const [listeningActiveImage, setListeningActiveImage] = useState([]);
  const [result, setResult] = useState();
  const [classId, setClassId] = useState();
  const date = new Date().toISOString().slice(0, 10);
 // console.log(class_id,'auth user');
console.log(authUser.user,'authuser');
 useEffect(() => {
  // if (!class_id) {
  //   dispatch(libraryActions.getLibraryHomeWorkBookList({ class_id: '' }));
  // } else {
    
  // }
  if (!class_id) {
    // If class_id is not present, exit the effect
    return;
  }
alert('yes');
  if(authUser.user.student_id){
    var teacher_id = '';
    const student_id = authUser.user.student_id;
    dispatch(libraryActions.getLibraryHomeWorkBookList({ class_id,teacher_id, date, student_id }));
  }
}, [class_id, dispatch]);

  useEffect(() => {
    if (!authUser){
			toast.error('Please login to view this page.', {
			  position: "top-right",
			  autoClose: 5000,
			  hideProgressBar: false,
			  closeOnClick: true,
			  pauseOnHover: true,
			  draggable: true,
			  progress: undefined,
			  theme: "light",
			  });
			history.navigate('/login');
		  }else{
      
      if(authUser.user.student_id){
        var userId = authUser.user.student_id;
        //var classIds = authUser.user.Student.Class.id;
        dispatch(studentActions.getStudentById({userId}));
      }
      
    
      }
  }, []);


  const getStampFromScore = (score) => {
    let stampText = '/images/tryagain1.png';
  
    if (score >= 0 && score <= 0.3) {
      stampText = '/images/tryagain1.png';
    } else if (score > 0.3 && score <= 0.6) {
      stampText = '/images/tryagain1.png';
    } else if (score > 0.6 && score <= 0.9) {
      stampText = '/images/tryagain1.png';
    } else if (score > 0.9 && score <= 1.3) {
      stampText = '/images/tryagain1.png';
    } else if (score > 1.3 && score <= 1.6) {
      stampText = '/images/tryagain1.png';
    } else if (score > 1.6 && score <= 1.9) {
      stampText = '/images/tryagain1.png';
    } else if (score > 1.9 && score <= 2.3) {
      stampText = '/images/tryagain1.png';
    } else if (score > 2.3 && score <= 2.6) {
      stampText = '/images/tryagain1.png';
    } else if (score > 2.6 && score <= 3) {
      stampText = '/images/greatjob1.png';
    } else if (score > 3 && score <= 3.3) {
      stampText = '/images/amazing1.png';
    } else if (score > 3.3 && score <= 4) {
      stampText = '/images/excellent1.png';
    }
  
    return stampText;
  };
  useEffect(() => {
 
    if(student){
       var class_id = (student.Class) ? student.Class.id : 21;
       // setClassId(class_id);
       var date = '';
       const student_id = authUser.user.student_id;
      dispatch(libraryActions.getLibraryHomeWorkBookList({class_id, student_id}));
      const type = 'Library';
    dispatch(homeworkActions.getHomeWorkById({class_id,type}));
    }
  }, [student]);
  //console.log(library,'get lib');return false;
  useEffect(() => {

		if(library && library.data && library.data['Reading'].length > 0){

			library.data['Reading'].map(item => {
        console.log(item,'get me');
        if(item.homework.length > 0){
          setReadingctiveImage((prevActiveImages) => {
            if (!prevActiveImages.includes(item.homework[0].library_id)) {
              return [...prevActiveImages, item.homework[0].library_id];
            }
            return prevActiveImages;
          });
          if(item.homework[0].Audio_Records){
            if(item.homework[0].Audio_Records['Read Out Loud']){
              var jsonResult = JSON.parse(item.homework[0].Audio_Records['Read Out Loud'].result);
              setResult(jsonResult);
            }

            if(item.homework[0].Audio_Records['Vocab Read Out Loud']){
              var jsonResult = JSON.parse(item.homework[0].Audio_Records['Vocab Read Out Loud'].result);
              setResult(jsonResult);
            }

            if(item.homework[0].Audio_Records['Sentence Read Out Loud']){
              var jsonResult = JSON.parse(item.homework[0].Audio_Records['Sentence Read Out Loud'].result);
              setResult(jsonResult);
            }
        
  
         }
       }
      

			  });

        
		}

    if(library && library.data && library.data['Speaking'].length > 0){

			library.data['Speaking'].map(item => {
        console.log(item,'get me');
        if(item.homework.length > 0){
          setSpeakingActiveImage((prevActiveImages) => {
            if (!prevActiveImages.includes(item.homework[0].library_id)) {
              return [...prevActiveImages, item.homework[0].library_id];
            }
            return prevActiveImages;
          });
          if(item.homework[0].Audio_Records){
            if(item.homework[0].Audio_Records['Read Out Loud']){
              var jsonResult = JSON.parse(item.homework[0].Audio_Records['Read Out Loud'].result);
              setResult(jsonResult);
            }

            if(item.homework[0].Audio_Records['Vocab Read Out Loud']){
              var jsonResult = JSON.parse(item.homework[0].Audio_Records['Vocab Read Out Loud'].result);
              setResult(jsonResult);
            }

            if(item.homework[0].Audio_Records['Sentence Read Out Loud']){
              var jsonResult = JSON.parse(item.homework[0].Audio_Records['Sentence Read Out Loud'].result);
              setResult(jsonResult);
            }
        
  
         }
       }
      

			  });

   
		}

    if(library && library.data && library.data['Writing'].length > 0){

			library.data['Writing'].map(item => {
        console.log(item,'get me');
        if(item.homework.length > 0){
          setWritingActiveImage((prevActiveImages) => {
            if (!prevActiveImages.includes(item.homework[0].library_id)) {
              return [...prevActiveImages, item.homework[0].library_id];
            }
            return prevActiveImages;
          });
          if(item.homework[0].Audio_Records){
            if(item.homework[0].Audio_Records['Read Out Loud']){
              var jsonResult = JSON.parse(item.homework[0].Audio_Records['Read Out Loud'].result);
              setResult(jsonResult);
            }

            if(item.homework[0].Audio_Records['Vocab Read Out Loud']){
              var jsonResult = JSON.parse(item.homework[0].Audio_Records['Vocab Read Out Loud'].result);
              setResult(jsonResult);
            }

            if(item.homework[0].Audio_Records['Sentence Read Out Loud']){
              var jsonResult = JSON.parse(item.homework[0].Audio_Records['Sentence Read Out Loud'].result);
              setResult(jsonResult);
            }
        
  
         }
       }
      });
    }

       if(library && library.data && library.data['Listening'].length > 0){

        library.data['Listening'].map(item => {
          console.log(item,'get me');
          if(item.homework.length > 0){
            setListeningActiveImage((prevActiveImages) => {
              if (!prevActiveImages.includes(item.homework[0].library_id)) {
                return [...prevActiveImages, item.homework[0].library_id];
              }
              return prevActiveImages;
            });
            if(item.homework[0].Audio_Records){
              if(item.homework[0].Audio_Records['Read Out Loud']){
                var jsonResult = JSON.parse(item.homework[0].Audio_Records['Read Out Loud'].result);
                setResult(jsonResult);
              }
  
              if(item.homework[0].Audio_Records['Vocab Read Out Loud']){
                var jsonResult = JSON.parse(item.homework[0].Audio_Records['Vocab Read Out Loud'].result);
                setResult(jsonResult);
              }
  
              if(item.homework[0].Audio_Records['Sentence Read Out Loud']){
                var jsonResult = JSON.parse(item.homework[0].Audio_Records['Sentence Read Out Loud'].result);
                setResult(jsonResult);
              }
          
    
           }
         }
      

			  });

		}

	}, [library]);
  const renderStars = (rating) => {
		console.log(rating,'get rating');

		let stars = [];
		for (let i = 1; i <= 5; i++) {
		  stars.push(
			<i
			  key={i}
			  className={i <= rating ? 'fa fa-star active' : 'fa fa-star'}
			  aria-hidden="true"
			/>
		  );
		}
		return stars;
	  };
    const renderBookList = (type) => {
      // Ensure library.data exists and is an array
      if (!library || !library.data || !library.data[type]) {
        return null; // Or render fallback UI
      }
    
      let activeImageArray; // Holds the respective activeImage array based on type
      const userId = authUser.user.student_id;
      const classIds = authUser.user.Student.Class.id;
    
      // Determine the active image array for each type
      switch (type) {
        case "Reading":
          activeImageArray = readingActiveImage;
          break;
        case "Speaking":
          activeImageArray = speakingActiveImage;
          break;
        case "Writing":
          activeImageArray = writingActiveImage;
          break;
        case "Listening":
          activeImageArray = listeningActiveImage;
          break;
        default:
          activeImageArray = [];
      }
    
      // Helper function to calculate average score across all types
      const calculateAverageScore = (audioRecords) => {
        if (!audioRecords || typeof audioRecords !== "object") return '';
  
        const scores = Object.values(audioRecords).map(
          (record) => record.calculatedscore
        );

      
    
        if (scores.length === 0) return '';
    
        const total = scores.reduce((sum, score) => sum + score, 0);
        //console.log(total,'get scores');
        return total / scores.length;
      };
    
      return (
        library.data[type].map((lib, index) => {
          const audioRecords = (lib.homework.length > 0) ? lib.homework[0].Audio_Records : {};
          const averageScore = calculateAverageScore(audioRecords);
          console.log(averageScore,'get scores');
          return (
            <a
              key={index}
              href={
                userId
                  ? `/homework/BookList/${lib.id}/${userId}/${type}`
                  : `/homework/BookList/${lib.id}/${type}/${userId}/${classIds}`
              }
            >
              <div className="books-stand">
                <h3>{lib.book_title}</h3>
                <img
                  className={`book-img ${
                    activeImageArray.includes(lib.id) ? "active" : ""
                  }`}
                  src="/images/picture_sample.png"
                  alt="Book"
                />
                {result && activeImageArray.includes(lib.id) && averageScore != '' ? (
                  <img
                    src={getStampFromScore(averageScore)}
                    alt="Stamp"
                    className="img-fluid book-cert recording-list"
                  />
                ) : (
                  ""
                )}
                <div className="rating">
                  {result && activeImageArray.includes(lib.id) ? (
                    renderStars(averageScore)
                  ) : (
                    <>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </>
                  )}
                </div>
              </div>
            </a>
          );
        })
      );
    };
    
  // const renderBookList = (type) => {
  //   // Ensure library.data is an array before trying to filter it
  //   // if (!library || !Array.isArray(library.data)) {
  //   //   return null; // or return a fallback UI like a loading spinner or a message
  //   // }
  //   let activeImageArray; // Holds the respective activeImage array based on type
  //   var userId = authUser.user.student_id;
  //   var classIds = authUser.user.Student.Class.id;
  //   // Determine the active image array for each type
  //   if (type === 'Reading') {
  //     activeImageArray = readingActiveImage;
  //   } else if (type === 'Speaking') {
  //     activeImageArray = speakingActiveImage;
  //   } else if (type === 'Writing') {
  //     activeImageArray = writingActiveImage;
  //   } else if (type === 'Listening') {
  //     activeImageArray = listeningActiveImage;
  //   }
  //   return (

  //     library && library.data[type].map((lib, index) => (
  //         <a key={index} href={(student_id) ? `/homework/BookList/${lib.id}/${student_id}/${type}` : `/homework/BookList/${lib.id}/${type}/${userId}/${classIds}`}>
  //           <div className="books-stand">
  //             <h3>{lib.book_title}</h3>
  //             <img className={`book-img ${activeImageArray.includes(lib.id) ? 'active' : ''}`} src="/images/picture_sample.png" alt="Book" />
  //             {(result && activeImageArray.includes(lib.id)) ? (
  //               <img src={getStampFromScore(2)} alt="Stamp" className="img-fluid book-cert recording-list" />
  //             ) : ''}
  //              <div class="rating">
	// 			{(result && activeImageArray.includes(lib.id)) ?
	// 		  renderStars(2) : 
	// 	<>
	// 	    <i class="fa fa-star" aria-hidden="true"></i>
	// 			<i class="fa fa-star" aria-hidden="true"></i>
	// 			<i class="fa fa-star" aria-hidden="true"></i>
	// 			<i class="fa fa-star" aria-hidden="true"></i>
	// 			<i class="fa fa-star" aria-hidden="true"></i>
	// 			</>
	// 	}

	// 		  </div>
  //           </div>
  //         </a>
  //       ))
  //   );
  // };
  
    return (

      <div class="container">
      <div class="library-title">
        <h2>LIBRARY</h2>
      </div>
      <div class="book-row">
        <div class="book-middle">

        {library && library.data['Reading'].length > 0 ? <><>Reading</>
          <div className='reading-div'>{renderBookList('Reading')}</div></> : '' }

         
{library && library.data['Listening'].length > 0 ? <><>Listening</>
        <div className='listening-div'>{renderBookList('Listening')}</div></> : '' }
        
        {library && library.data['Speaking'].length > 0 ? <><>Speaking</>
          <div className='speaking-div'>{renderBookList('Speaking')}</div></> : '' }

          {library && library.data['Writing'].length > 0 ? <><>Writing</>
            <div className='writing-div'>{renderBookList('Writing')}</div></> : '' }


     
        {/* {(library && library.data) ? library.data.map((lib, index) => (
          <a href={(student_id) ? `/homework/BookList/${lib.id}/${student_id}` :`/homework/BookList/${lib.id}` }>
            <div class="books-stand">
            <h3>{lib.book_title}</h3>
            <img class={`book-img ${activeImage.includes(lib.id) ? 'active' : ''}`} src="/images/picture_sample.png" />
           
            {(result && activeImage.includes(lib.id)) ?  <img src={getStampFromScore(calculateTotalScore( calculateAccuracyPercentage(result),
        getFluencyAverageScore(result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} alt="Uploaded File" class="img-fluid book-cert recording-list" /> : ''}
            <div class="rating">
              <i class="fa fa-star active" aria-hidden="true"></i>
              <i class="fa fa-star active" aria-hidden="true"></i>
              <i class="fa fa-star active" aria-hidden="true"></i>
              <i class="fa fa-star active" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
            </div>
          </div>
          </a>
        )) : ''} */}

        </div>	
      </div>
    
    </div>
      
      
    )
}
