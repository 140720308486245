import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quizActions, quizresultActions , studyActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import axios from "axios";
export { VocabularyQuestion };

function VocabularyQuestion() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { record_id,q_start } = useParams();
  const [myState, setMyState] = useState('initialValue');
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);
  const { quiz } = useSelector((x) => x.quiz);
  const { passage } = useSelector((x) => x.passage);
  const { homework } = useSelector((x) => x.homework);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [quizCategory, setquizCategory] = useState();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizalready, setQuizAlready] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(15);
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const [questionAnswered, setQuestionAnswered] = useState(0);
  const intervalRef = useRef();
  const [currentBombImage, setCurrentBombImage] = useState(0);
  const [resultRecordId, setResultRecordId] = useState();
  const [correctAnswered, setCorrectAnswered] = useState(0);
  const student = JSON.parse(localStorage.getItem('user'));
  const [inactive, setInactive] = useState(false);

  

  useEffect(() => {
    let timer;
    
    const resetTimer = () => {
      clearTimeout(timer);
      // Start the timer again
      timer = setTimeout(() => {
        setInactive(true);
        // Pause the stopwatch
        clearInterval(intervalRef.current);
        setIsRunning(false);
      }, 120000); // 2 minutes (120000 milliseconds)
    };
  
    const handleActivity = () => {
      if (inactive) {
        setInactive(false);
        // Resume the stopwatch
        start();
      }
      resetTimer();
    };
  
    // Add event listeners to monitor user activity
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);
  
    // Start the initial timer
    resetTimer();
  
    // Clean up event listeners
    return () => {
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      clearTimeout(timer);
    };
  }, [inactive]);
 useEffect(() => {
    const vocab_id = record_id;
    var sub_type = 'Vocabulary Quiz';
    //setShowBomb(true);
    dispatch(quizActions.getVocabQuestions({vocab_id,q_start}));
    dispatch(studyActions.getProgramResultBySubtype({vocab_id,sub_type,q_start}));
  }, []);
  //console.log(passage,'get lib');
  const handleOptionSelect = (selected) => {
    setSelectedOption(selected);
    //handleNextQuestion(selected);
    setUserAnswers(prevAnswers => [...prevAnswers, selected]);
  };


 
  const handleNextQuestion = (selectedOption) => {
    const currentQuestionData = quizData[currentQuestion];
    const correctAnswer = currentQuestionData.answer;
    const userAnswer = selectedOption;
    // Reset the time remaining for the next question
   
    if (userAnswer === correctAnswer) {
      // If user's answer is correct, proceed to the next question
      if(score < 100){
        setScore(score + 10);
      }
       if(score > 100){
        setScore(100);
      }
      setCorrectAnswered(correctAnswered + 1);
      setSelectedOption(null);
  
      if (currentQuestion + 1 < quizData.length) {
        setCurrentQuestion(currentQuestion + 1);
        //setTimeRemaining(15);
       
      } else {
        setCurrentQuestion(0);
        setQuizCompleted(false);
      }
    } else {
      // If user's answer is incorrect, show sections with correct and user's answers
      setUserAnswers(prevAnswers => [...prevAnswers, selectedOption]);
      setMyState('incorrectAnswer');
      if(score > 2){
        setScore(score - 2);
      }
           // Save the current time before stopping the stopwatch
    const currentTime = time;
    // Stop the stopwatch by clearing the interval
    clearInterval(intervalRef.current);
   // Stop the stopwatch
   setIsRunning(false);
   // Set the time to the current time so that the stopwatch resumes from where it left off
   setTime(currentTime);
    }
    
  };
  const handleTryAgainQuestion = () => {
    setMyState('');
    setIsRunning(true);
    if (currentQuestion + 1 < quizData.length) {
      setCurrentQuestion(currentQuestion + 1);

   

    } else {
      setCurrentQuestion(0);
        setQuizCompleted(false);
    }
    console.log(time,'get time');
    setTime(time); // Resume the stopwatch from the paused time
    start();
  };

//console.log(quizData,'get quiz data');
  const handleKeyPress = (event) => {
    const selectedNumber = Number(event.key);
    if (!isNaN(selectedNumber) && selectedNumber > 0 && selectedNumber <= quizData[currentQuestion].options.length) {
      const selectedOption = quizData[currentQuestion].options[selectedNumber - 1];
      handleOptionSelect(selectedOption);
    }
  };
  
  useEffect(() => {
    try {
      if (quizData.length === 0) {
        // if (!location.state) {
        //   window.location.href = '/';
        // }
        if(quiz && quiz.data){
          const shuffledQuizData = shuffleArray([...quiz.data]);
          setQuizData(shuffledQuizData);
        }
        
        setquizCategory("VOCABULARY QUIZ");

      }


    }
    catch (error) {
      console.error("An error occurred:", error);
    }
  }, [quiz]);



  useEffect(() => {

    window.addEventListener('keydown', handleKeyPress);


    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);


  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }


  const renderOptions = () => {
    return quizData[currentQuestion].options.map((option, index) => (
      <div key={index} className='quiz-options'>
        <label className='option-index'>{index + 1}</label>
        <label className={`container ${selectedOption === option ? 'selected' : ''}`} htmlFor={option}>
          {option}
          <input
            type="radio"
            id={option}
            name="options"
            value={option}
            checked={selectedOption === option}
            onChange={() => handleOptionSelect(option)}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    ));
  };

  const renderOptionsForCorrect = (value) => {
    if(value == 'correct'){
      return quizData[currentQuestion].options.map((option, index) => (
        <div key={index} className='quiz-options'>
          <label className='option-index'>{index + 1}</label>
          <label className={`container ${quizData[currentQuestion].answer === option ? 'selected' : ''}`} htmlFor={option}>
            {option}
            <input
              type="radio"
              id={option}
              name="options"
              value={option}
              checked={option === quizData[currentQuestion].answer}
              
            />
            <span className="checkmark"></span>
          </label>
        </div>
      ));
    }else{
      return quizData[currentQuestion].options.map((option, index) => (
        <div key={index} className='quiz-options'>
          <label className='option-index'>{index + 1}</label>
          <label className={`container ${selectedOption === option ? 'selected' : ''}`} htmlFor={option}>
            {option}
            <input
              type="radio"
              id={option}
              name="options"
              value={option}
              checked={selectedOption === option}
             
            />
            <span className="checkmark"></span>
          </label>
        </div>
      ));
    }
    
  };
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000); // 1 hour = 3600000 milliseconds
    const minutes = Math.floor((time % 3600000) / 60000); // 1 minute = 60000 milliseconds
    const seconds = Math.floor((time % 60000) / 1000); // 1 second = 1000 milliseconds
    const milliseconds = Math.floor((time % 1000) / 10);
  
    return {
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0')
    };
  };
  useEffect(() => {
    start(); // Start the stopwatch when the component mounts
    // Clean-up function to clear the interval when the component unmounts
    return () => clearInterval(intervalRef.current);
  }, []); // Empty dependency array ensures this effect runs only once

  const start = () => {
    if (!isRunning) {
      setIsRunning(true);
      const currentTime = Date.now();
      const timeElapsed = currentTime - time;
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1000); // Increment by 1 second (1000 milliseconds)
      }, 1000);
    }
  };
  useEffect(() => {
    if(passage && passage.data){

      //setTime(passage.data.stopwatch_time);
      if(parseInt(passage.data.total_score) > 100){
        setScore(100);
      }else{
        setScore(parseInt(passage.data.total_score));
      }
      
      setQuestionAnswered(parseInt(passage.data.question_answered));
    }
  }, [passage]); 
//  console.log(stopwatch,'get stopwatch');
  const { hours, minutes, seconds } =  formatTime(time);
 // const { hours, minutes, seconds } = (stopwatch) ? stopwatch : formatTime(time);


  const handleEnterButtonClick = async() => {
    if(selectedOption == '' || selectedOption == null) {
      alert('Please select answer.');
    }else{console.log('get here');
      setQuestionAnswered(prevCount => prevCount + 1);
      // Handle logic to move to the next question
      handleNextQuestion(selectedOption);
      //save the record
      var q_end = parseInt(q_start) + 9;
      const result = []
      {
        quizData.map((question, index) => (
          result.push({ word: question.word, answer: question.answer, useranswer: (index < userAnswers.length) ? userAnswers[index] : "Not answered" })
        ))
      }

      console.log(student, "student",time);
      if(resultRecordId){
        if (student && student.user.student_id) {
          const scoreprecentage=((score / quizData.length) * 100);
          const option = {
           // category: location.state.vocabulary.id,
            total_score:score,
            Studentid: student.user.student_id,
            Classid:student.user.Student.class_name,
            type: "Study",
            sub_type:"Vocabulary Quiz",
            question_answered:questionAnswered,
            stopwatch_time:time,
            result: JSON.stringify(result),
            record_id:resultRecordId,
            program_id:record_id,
            vocab_id:record_id,
            vocab_q_start:q_start,
            vocab_q_end:q_end,
            total_questions:quizData.length,
             percentage: scoreprecentage,
             correct_answered: correctAnswered,
          

          }
          console.log(option, "opt")
          const response = await axios.post(
            "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
            option,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                // Add other headers if needed
              },
            }
          );
          if(response){
            console.log(response.data.data.id,'get result response');
            //setResultRecordId(response.data.data.id);
          }
          
        }
      }else{
        if (student && student.user.student_id) {
          const scoreprecentage=((score / quizData.length) * 100);
          const option = {
           
           // category: location.state.vocabulary.id,
            total_score:score,
            Studentid: student.user.student_id,
            Classid:student.user.Student.class_name,
            type: "Study",
            sub_type:"Vocabulary Quiz",
            question_answered:questionAnswered,
            stopwatch_time:time,
            result: JSON.stringify(result),
            program_id:record_id,
            vocab_q_start:q_start,
            vocab_q_end:q_end,
            vocab_id:record_id,
            total_questions:quizData.length,
            percentage: scoreprecentage,
            correct_answered: correctAnswered,
          }
          console.log(option, "opt")
          const response = await axios.post(
            "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
            option,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                // Add other headers if needed
              },
            }
          );
          if(response){
            console.log(response.data.data.id,'get result response');
            setResultRecordId(response.data.data.id);
          }
          
        }
      }
    
    }
  };

  const renderQuizContent = () => {
    if (currentQuestion < quizData.length && quizCompleted === false ) {
      const totalTime = 15;
      const remainingPercentage = (timeRemaining / totalTime) * 100;
      const precent = (100 - ((currentQuestion - score) / quizData.length * 100)).toFixed(2);
      let bombImage = 1;
      if (remainingPercentage < 60) {
          bombImage = 2;
      }
      if (remainingPercentage < 20) {
          bombImage = 3;
      }
      return (
<div className="container">
  <div className="stopwatch-container">
  {/* <h4>{quizCategory}</h4> */}
  {/* <div className="progress">
  <div className="progress-bar" role="progressbar" style={{ width: `${(timeRemaining / 15) * 100}%` }}>

 
 
        <div className="time-bomb">
            <div className="image-fader">
            <img src={`/images/time_bomb_${bombImage}.png`} />
            </div>
        </div>
   
   </div>
</div>*/}
    <div className="row">

      <div className="col-md-10">
        <div className="question-box">
          {(myState !== 'incorrectAnswer') ?
          <>
           <h3 style={{ textTransform: "none" }}>{quizData[currentQuestion].word}</h3>
            <div className="question-area">
            {renderOptions()}
            <button onClick={handleEnterButtonClick}>Enter</button>
            </div>
          </> 
          :''}
       
          { (myState === 'incorrectAnswer') ?
    // Render sections for incorrect answer
   
      <div className="question-area">
        <h3>Let's try it again...</h3>
        <p>The correct answer is:</p>
        <h3 style={{ textTransform: "none" }}>{quizData[currentQuestion].word}</h3>
        {/* Render correct answer options here */}
        {renderOptionsForCorrect('correct')}
        <button onClick={handleTryAgainQuestion}>Gotcha!</button>
        <h3>You answered:</h3>
        {renderOptionsForCorrect('user')}
        <button onClick={handleTryAgainQuestion}>Gotcha!</button>
      </div>
      :''

  } 
        </div>
      </div>
      <div className="col-md-2">
        <div className="stopwatch-box">
          <h6 className="g-bg">Questions Answered</h6>
          <p className="result">{questionAnswered}</p>
          <h6 className="b-bg">Study Time</h6>
          {(!inactive) ?     
          
          <div className="watch">
            <p className="hr">
              <span>{hours}</span>
              <span className="title">HR</span>
            </p>
            <p className="min">
              <span>{minutes}</span>
              <span className="title">MIN</span>
            </p>
            <p className="sec">
              <span>{seconds}</span>
              <span className="title">SEC</span>
            </p>
          </div> : <div className="paused-text">PAUSED</div>}
      
        </div>
        <div className="stopwatch-box">
          <h6 className="g-bg">My Score</h6>
          <p className="result">{score}</p>
        </div>
      </div>
    </div>
  </div>
</div>

   
      );
    }
//      else {
//       const percentage = ((score / quizData.length) * 100);
//    const remaining=100-percentage;
//  const data = [{ name: 'Your score', score: percentage, total: remaining  }];
//       return (

//         <div>
//           <h2>Quiz Result!</h2>


//           {/*<BarChart
//             xAxis={[{ scaleType: 'band', data: ['Your score'] }]}
//             series={[{ data: [percentage], color: "#3a58e9b8", stack: 'total' }, { data: [100 - percentage], color: "#992525", stack: 'total' }]}
//             width={500}
//             height={300}

//           />*/} 

//         <BarChart width={500} height={300} data={data}>
//       <XAxis dataKey="name" />
//       <YAxis domain={[0, 100]} /> 
//       <Tooltip />
//       <Legend />
//      <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
//   <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
//     </BarChart>

//            <span className='score'>
//     <div className="score-wrap">
//         <span className="stars-active" style={{ width:`${percentage}%`}}>
//             <i className="fa fa-star" aria-hidden="true"></i>
//             <i className="fa fa-star" aria-hidden="true"></i>
//             <i className="fa fa-star" aria-hidden="true"></i>
//             <i className="fa fa-star" aria-hidden="true"></i>
//             <i className="fa fa-star" aria-hidden="true"></i>
//         </span>
// <span className="stars-inactive">
//             <i className="fa fa-star" aria-hidden="true"></i>
//             <i className="fa fa-star" aria-hidden="true"></i>
//             <i className="fa fa-star" aria-hidden="true"></i>
//             <i className="fa fa-star" aria-hidden="true"></i>
//             <i className="fa fa-star" aria-hidden="true"></i>
//         </span>
// </div>
// </span>
//           <Table style={{ margin: '20px', width: '95%' }}>
//             <thead>
//               <th>Your Raw Score</th>
//               <th>{score} / {quizData.length}</th>
//             </thead>
//             <thead>
//               <th>Your Score</th>
//               <th>{(percentage.toFixed(2))}%</th>
//             </thead>
//           </Table>

//           <div className="quiz-results">
//             <h3>Quiz Results:</h3>
//             <Table striped bordered responsive>
//               <thead>
//                 <tr>
//                   <th></th>
//                   <th>Word</th>
//                   <th>Correct Answer</th>
//                   <th>User Answer</th>
//                   <th>O X</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {quizData.map((question, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     <td>{question.word}</td>
//                     <td> {question.answer}</td>
//                     <td>{index < userAnswers.length ? userAnswers[index] : "Not answered"}</td>
//                     <th>{(question.answer == userAnswers[index]) ? 'O' : 'X'}</th>
//                   </tr>

//                 ))}
//               </tbody>
//             </Table>
//           </div>

//           <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
//         </div>
//       );
//     }
  };



  return (
    <div className="quiz-container">
      <div className='quizs-container'> {renderQuizContent()} </div>
    </div>
  );
}

