import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import { classesActions, dictionaryActions, myvocabActions } from "_store";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

export { MyOwnVocab };

function MyOwnVocab() {
  const dispatch = useDispatch();
  const { classes } = useSelector((x) => x.classes);
  const { classid, type } = useParams();
  const [inputValue, setInputValue] = useState("");
  const today = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState(today);
  const [textareaValue, setTextareaValue] = useState("");
  const { dictionary } = useSelector((x) => x.dictionary);
  const { user: authUser } = useSelector((x) => x.auth);
  const [clickedWord, setClickedWord] = useState("");
  const [showWrongWord, setShowWrongWord] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [correctValue, setCorrectValue] = useState("");
  const [koreanWordValue, setKoreanWordValue] = useState("");
  const [flag, setFlag] = useState(true);
  const [showFlashCard, setShowFlashCard] = useState(false);
  const [showOnlyWord, setShowOnlyWord] = useState(false);
  const [index, setIndex] = useState(0);
  const [columnValue, setColumnValue] = useState(0);
  

  const handlePrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const handleNext = () => {
    if (dictionary && dictionary.data) {
      if (index < dictionary.data.length - 1) {
        setIndex(index + 1);
      }
    }
  };

  const handleRead = () => {
    const synth = window.speechSynthesis;
    if (dictionary && dictionary.data) {
      const utterance = new SpeechSynthesisUtterance(
        dictionary.data[index].word + dictionary.data[index].meaning
      );
      synth.speak(utterance);
    }
  };
  console.log(dictionary, "get dictionary");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  const handleSearch = () => {
    // Handle form submission here
    console.log("Input value:", inputValue);
    console.log("Selected date:", selectedDate);
   
      if (textareaValue) {
        const text = textareaValue;
        const wordsTo = text.split(", ");

        const smallCapsWords = wordsTo.map((word) => {
          const lowercaseWord = word.toLowerCase();
          return lowercaseWord;
        });
        const caps = smallCapsWords.join(", ");

        const words = caps.split(",").map((word) => word.trim());

        dispatch(dictionaryActions.searchDictionaryWord({ words }));
      } else {
        alert("Add words for search.");
      }
    
  };
  const sendWrongAnswerToAdmin = (word) => {
    // Handle form submission here
    setClickedWord(word);
    setShowWrongWord(true);
    setFlag(false);
  };
  const handleWrongWordCancel = () => {
    // Handle form submission here
    setShowWrongWord(false);
  };

  const handleWrongAnswerSubmit = async () => {
    // Handle form submission here
    console.log("Input value:", correctValue);

    const matchingObject =
      dictionary && dictionary.data
        ? dictionary.data.find((item) => item.word === clickedWord)
        : {};
    //console.log('Selected date:', matchingObject);return false;
    const option = {
      word: clickedWord,
      newwordmeaning: correctValue,
      newkoreanword: koreanWordValue,
      oldkoreanword: matchingObject.koreanword,
      oldwordmeaning: matchingObject.meaning,
      teacher_id:authUser.user.teacher_id,
    };
    // console.log(option, "opt")
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/dictonary/reportword",
      option,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          // Add other headers if needed
        },
      }
    );

    if (response) {
      toast.success(
        "Submitted meaning/korean word successfully.It will be review and update by admin",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      setShowWrongWord(false);
    }
  };
  const handleSubmit = () => {
    // Handle form submission here
    console.log("Input value:", inputValue);
    console.log("Selected date:", selectedDate);
    if (inputValue) {
    if (dictionary && dictionary.data) {
      const words =
        dictionary && dictionary.data ? JSON.stringify(dictionary.data) : [];
      var vocabname = inputValue;
      var date = selectedDate;
      var teacher_id = authUser.user.teacher_id;
      var class_id = classid;


      dispatch(
        myvocabActions.createMyOwnVocab({ vocabname, teacher_id, date, words, class_id , type})
      ).then(() => {
        toast.success('Vocab created successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        window.location.href = '/classroom/MyOwnVocabList/'+classid+'/'+type;
      }).catch((error) => {
        console.error('Error occurred:', error);
        // Handle error here
      });
    } else {
      alert("No words to create new vocab.");
    }
  }else {
    alert("Please enter the vocab name.");
  }
  };
  const getRadioValue = (text) => {
    // Handle form submission here
    setRadioValue(text);
  };
  const getRadioValueForWords = (text) => {
    // Handle form submission here
  if(text == 'English to English'){
      setColumnValue(1);
  }else if(text == 'Korean to English'){
    setColumnValue(2);
  }else{
    setColumnValue(0);
  }
  };
  

  //console.log('get class', classes);
  useEffect(() => {
    var classId = classid;
    dispatch(classesActions.getClassById({ classId }));
  }, []);

  const handleCorrectMeaning = (e) => {
    // Handle form submission here
    setCorrectValue(e.target.value);
  };

  const handleKoreanWord = (e) => {
    // Handle form submission here
    setKoreanWordValue(e.target.value);
  };
  const handleFlashCardCancel = () => {
    // Handle form submission here
    setShowFlashCard(false);
  };

  const handleFlashCard = () => {
    // Handle form submission here
    setShowFlashCard(true);
  };
  const handleWordClick = () => {
    // Handle form submission here
    setShowOnlyWord(true);
  }

  const handleWordMeaningClick = () => {
    // Handle form submission here
    setShowOnlyWord(false);
  }
  const handlePrintFlashCard = () => {

  }
  useEffect(() => {
    if (showFlashCard) {
      const timer = setTimeout(() => {
        handleNext();
      }, 10000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showFlashCard, index]);
  const handleModalClick = () => {
    handleNext();
  };
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const content = document.getElementById('printableTable').outerHTML;
    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  const handleDownload = () => {
    const csvData = [];
    const table = document.getElementById('printableTable');
    const rows = table.querySelectorAll('tr');
    rows.forEach(row => {
      const cols = row.querySelectorAll('td, th');
      const rowData = Array.from(cols).map(col => col.innerText);
      csvData.push(rowData.join(','));
    });

    const csvContent = csvData.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'download.csv';
    link.click();
  };
  return (
    <section className="custom-sign own-vocab">
      <div className="container-fluid">
        <div className="card-header">
          <h4>{classes && classes.data ? classes.data.class_name : ""}</h4>
          <h4>My New Vocab</h4>
        </div>
        <div>
          <label htmlFor="inputField">Vocab List Name:</label>
          <input
            type="text"
            id="inputField"
            value={inputValue}
            onChange={handleInputChange}
            required
          />
          <br />
          <label htmlFor="datePicker">Date:</label>
          <input
            type="date"
            id="datePicker"
            value={selectedDate}
            onChange={(e) => handleDateChange(e.target.value)}
          />
          <br />

          <textarea
            id="textareaField"
            value={textareaValue}
            onChange={handleTextareaChange}
            placeholder="Type in words using commas."
          />
          <br />
          <div className="button-container">
            <button onClick={handleSearch}>Search</button>
            {textareaValue ? (
                <>
                {dictionary ? <>
                 <button className="btn" onClick={handlePrint}>Print</button>
                 <button className="btn" onClick={handleDownload}>Download</button>
                 <button className="btn" onClick={handleFlashCard}>Flash Card</button>
                 <button onClick={handleSubmit}>Create New Quiz</button>
                 {/* <button className="btn" onClick={handlePrintFlashCard}>Print Flash Card</button> */}
                 </> : ''}
              
              </>
            ) : (
              ""
            )}
          </div>
        </div>
       
        {dictionary ? (
          <>
           <div>
           <input type="radio" id="meaning1" name="english_korean" value="English & Korean to English" onClick={() => getRadioValueForWords('English & Korean to English')} checked/>
           <label for="meaning1">English & Korean to English</label>
       
           <input type="radio" id="korean_word1" name="english_korean" value="English to English" onClick={() => getRadioValueForWords('English to English')} />
           <label for="korean_word1">English to English</label>
       
           <input type="radio" id="meaning_and_korean1" name="english_korean" value="Korean to English" onClick={() => getRadioValueForWords('Korean to English')} />
           <label for="meaning_and_korean1">Korean to English</label>
         </div>
          <table id="printableTable">
            <thead>
              <tr>
                <th></th>
                {(columnValue == 1 || columnValue == 0) ? 
                <th>Word</th>
              : ''}
              {(columnValue == 2 || columnValue == 0) ? 
                <th>Korean Word</th>
                :''}
                {(columnValue == 1 || columnValue == 0 || columnValue == 2) ? 
                <th>Meaning</th>
                : ''}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dictionary.data
                ? dictionary.data.map((diction, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      {(columnValue == 1 || columnValue == 0) ? 
                      <td>{diction.word}</td>
                      :''}
                       {(columnValue == 2 || columnValue == 0) ? 
                      <td>{diction.koreanword}</td>
                      :''}
                      {(columnValue == 1 || columnValue == 0 || columnValue == 2) ? 
                      <td>{diction.meaning}</td>
                      :''}
                      <td>
                        <i
                          class="fa fa-flag"
                          aria-hidden="true"
                          onClick={() => sendWrongAnswerToAdmin(diction.word)}
                        ></i>
                      </td>
                    </tr>
                  ))
                : "No Records Found."}
            </tbody>
          </table>
          </>
        ) : (
          ""
        )}

        <Modal show={showWrongWord} onHide={handleWrongWordCancel}>
          <Modal.Header closeButton>
            <Modal.Title>
              Report to admin for wrong meaning/korean words
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            <div>
              <label htmlFor="inputField">Word</label>
              <input type="text" id="inputField" value={clickedWord} />
            </div>

            <div>
              <input
                type="radio"
                id="meaning"
                name="meaning"
                value="Meaning"
                onClick={() => getRadioValue("Meaning")}
              />
              <label for="meaning">Meaning</label>

              <input
                type="radio"
                id="korean_word"
                name="meaning"
                value="Korean Word"
                onClick={() => getRadioValue("Korean Word")}
              />
              <label for="korean_word">Korean Word</label>

              <input
                type="radio"
                id="meaning_and_korean"
                name="meaning"
                value="Meaning and Korean Word"
                onClick={() => getRadioValue("Meaning and Korean Word")}
              />
              <label for="meaning_and_korean">Meaning and Korean Word</label>
            </div>
            <div>
              {radioValue == "Meaning" ? (
                <>
                  {" "}
                  <label htmlFor="datePicker">Correct Meaning*:</label>
                  <textarea
                    id="textareaField"
                    value={correctValue}
                    onChange={handleCorrectMeaning}
                    placeholder="Please enter correct meaning."
                    required
                  />
                </>
              ) : (
                ""
              )}

              {radioValue == "Korean Word" ? (
                <>
                  <label htmlFor="datePicker">Korean Word:</label>

                  <input
                    type="text"
                    id="inputField"
                    value={koreanWordValue}
                    onChange={handleKoreanWord}
                    required
                  />
                </>
              ) : (
                ""
              )}

              {radioValue == "Meaning and Korean Word" ? (
                <>
                  <label htmlFor="datePicker">Correct Meaning*:</label>

                  <textarea
                    id="textareaField"
                    value={correctValue}
                    onChange={handleTextareaChange}
                    placeholder="Type in words using commas."
                    required
                  />

                  <label htmlFor="datePicker">Korean Word:</label>

                  <input type="text" id="inputField" value={koreanWordValue} />
                </>
              ) : (
                ""
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleWrongWordCancel}>
              Close
            </Button>
            <Button variant="secondary" onClick={handleWrongAnswerSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={showFlashCard} onHide={handleFlashCardCancel}  onClick={handleFlashCardCancel} backdrop="true">
          <Modal.Header closeButton>
            <Modal.Title>
              Flash Card
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }} onClick={handleModalClick}>
            <div>

            <div className="flash-card-image">
            {dictionary && dictionary.data.length > 0
                  ? (dictionary.data[index].image) ? <img src={dictionary.data[index].image} className="dictionaryImg" height={100} width={100} /> : ''
                  : ""}
              </div>
              
              <div className="flash-card-word">
                
                {dictionary && dictionary.data.length > 0
                  ? dictionary.data[index].word
                  : ""}
              </div>
              {(!showOnlyWord) ?  <div className="flash-card-meaning">
               
               {dictionary &&  dictionary.data.length > 0
                 ? dictionary.data[index].meaning
                 : ""}
             </div> : ''}
             
              {/* <div className="flash-card-button">
                <button onClick={handlePrevious}>Previous</button>
                <button onClick={handleNext}>Next</button>
                <button onClick={handleRead}>Read</button>
                {(!showOnlyWord) ? <button onClick={handleWordClick}>Word</button> : <button onClick={handleWordMeaningClick}>Word & Meaning</button>}
                
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
          {index < (dictionary && dictionary.data.length - 1) ? (
     <></>
    ) : (
      <button onClick={handleFlashCardCancel}>Close</button>
    )}
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
}
