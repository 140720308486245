import { useEffect , useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { homeworkActions } from '_store';

import { history } from '_helpers';
import { Modal, Button } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
export { HomeWorkMail };

function HomeWorkMail() {
  const dispatch = useDispatch();
  const { homework } = useSelector((x) => x.homework);
  const { classid, token } = useParams();
console.log(homework,'hom');
  useEffect(() => {
    var class_id = classid;
    var date = token;
    dispatch(homeworkActions.getHomeWorkByDate({class_id, date}));

 }, []);
    return (

        <div className="grade-table-conatiner">
            <div className='header-title'>
                <h4 className='header-title'>Classroom Homework</h4>
                <h3>Date : {new Date(atob(token)).toISOString().slice(0, 10)}</h3>
            </div>
            <div className="col-lg-12" style={{textAlign: 'justify', border: '1px solid'}}>




            {(homework && homework.data) ? homework.data.map((category, index) => (
  <div key={index}>
    {Object.keys(category).map((categoryName, index1) => (
      <div key={index1}>
        <h4>{categoryName}</h4>
        {category[categoryName].map((item, index2) => (
          <div key={index2} style={{ borderBottom: '1px solid gray' }}>
            <p style={{ whiteSpace: 'pre-wrap' }}>{item.homework_text_email}</p>
            <p>
              <a href="#" target="_blank" style={{ cursor: 'pointer' }}>
                S1 {item.homework_text} Posted by: {item.Teacher.first_name}
              </a>
              <br />
            </p>
          </div>
        ))}
      </div>
    ))}
  </div>
)) : '<p>There is no assigned homework on this date.</p>'}


                

      </div>
       
      </div>
      
      
    )
}
