import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { studyActions, studentActions, classesActions } from '_store';
import { useParams } from "react-router-dom";
import { history } from '_helpers';


export { AssignStudyTest };

function AssignStudyTest() {
  const dispatch = useDispatch();
  const { record_id } = useParams();
  const { passage } = useSelector((x) => x.passage);
  const { student } = useSelector((x) => x.student);
  const { classes } = useSelector((x) => x.classes);
  const [showTestDialog, setShowTestDialog] = useState(false);
  const [testText, setTestText] = useState('');
  const [testLevelText, setTestLevel] = useState('');
  const { user: authUser } = useSelector((x) => x.auth);
  const [selectedOption, setSelectedOption] = useState('student');
  const [studentId, setStudentId] = useState();
  const [classId, setClassId] = useState();
  
console.log(authUser,'get class', passage,classes);
  useEffect(() => {
    var studytest_id = record_id;
    dispatch(studyActions.assignTestList({studytest_id}));
    dispatch(studentActions.getStudents());
    dispatch(classesActions.getClasses());
    

 }, []);

 const addNewTest = () => {
  
  setShowTestDialog(true);
};
const handleTestCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowTestDialog(false);
};

const saveTestAssign = async () => {
    var type = selectedOption;
  var test_id = record_id;
  var student_id = studentId;
  var class_id = classId;
  var teacher_id = authUser.user.teacher_id;

  return dispatch(studyActions.createNewTestAssign({type, test_id,student_id,class_id,teacher_id}));

};

const handleStudentChange = (e) => {
  setStudentId(e.target.value);
};
const handleClassChange = (e) => {
    setClassId(e.target.value);
  };
const handleOptionChange = (value) => {

        setSelectedOption(value);
      };

      const deleteAssignTest = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this item?');

        if (confirmed) {
          var studytest_id = id;
          var res = dispatch(studyActions.deleteAssignTest({studytest_id}));
          if(res){
            window.location.reload();
          }
        }
      };
console.log(classes,'get class');
    return (

      <section className="custom-sign">
      <div className="container-fluid">
      <div className="card-header">
			<p className="header-title">Assign List</p>
      <button type="button" className="btn btn-success" onClick={()=>addNewTest()}>New Assign</button>
		  </div>
          <div className='card-body'>
          <table className="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>S No.</th>
                        {/* <th>Schedule</th> */}
                        <th>Name</th>
                        <th>Class/Student</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {(passage && passage.data) ? passage.data.assigned_data.map((pass, index) => (
                      <tr>
                        <td contentEditable="false">{index + 1}</td>
                        <td contentEditable="false">{(pass.class_id) ?  pass.Class.class_name : pass.Students.firstName+' '+pass.Students.lastName}</td>
                        {/* <td row="2" contentEditable="false">{classN.schedule}</td> */}
                        <td contentEditable="false">{pass.type}</td>
                         <td>
                          <span className="table-remove">
                        
                            <td><a href="javascript:void(0)" onClick={() => deleteAssignTest(pass.id)}>Delete</a></td>
       
                            
                            </span>
                        </td>
                      </tr>
                      )) : <h5>No Records Found.</h5>}
                    </tbody>
                  </table>
                  <Modal show={showTestDialog} onHide={handleTestCancel}>
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form>

                <div>
        <label>
          <input
            type="radio"
            name="category"
            value="student"
            onClick={()=>handleOptionChange('student')}
            checked
          />
          Student
        </label>
        <label>
          <input
            type="radio"
            value="class"
            name="category"
            checked={selectedOption === 'class'}
            onClick={()=>handleOptionChange('class')}
          />
          Class
        </label>

      </div>
{(selectedOption == 'class') ?
     <div>
     <label>Class</label>
     <select className="form-control" id="selLevel" name="selLevel" onChange={handleClassChange}>
         <option>Select Class</option>
     {(classes) ? classes.data.map(classd => ( <option value={classd.id}>  {`${classd.class_name}`}</option>)) : ''}
                 </select>
   </div>
   :  <div>
   <label>Student</label>
   <select className="form-control" id="selLevel" name="selLevel" onChange={handleStudentChange}>
       <option>Select Student</option>
   {(student) ? student.map(stu => ( <option value={stu.id}>  {`${stu.firstName} ${stu.lastName}`}</option>)) : ''}
               </select>
 </div>}
      
      

      <button type="submit" onClick={()=>saveTestAssign()}>Save</button>
    </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleTestCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
              </Modal>
          </div>

      </div>
    </section>
      
    )
}
