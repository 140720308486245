import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { Table } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { singlefileActions,homeworkActions } from '_store';
import { useParams } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

export { VocabQuizResult };

function VocabQuizResult() {
  const dispatch = useDispatch();
  const { record_id } = useParams();
  const { file } = useSelector((x) => x.file);
  const { homework } = useSelector((x) => x.homework);
  const [result, setResult] = useState();
    const [scoreprectage, setScoreprectage] = useState();
      const [score, setScore] = useState();
      const [pageLink, setPageLink] = useState();
      const [pageType, setPageType] = useState();
      const [homeworkId, setHomeworkId] = useState();
      const [programId, setProgramId] = useState();
  console.log(file,'get file',homework);
  useEffect(() => {
    var id = record_id;
    dispatch(singlefileActions.getSingleFile({id}));
  }, []);

  useEffect(() => {
    if(file && file.data){
        var jsonResult = JSON.parse(file.data.result);
        setResult(jsonResult);
        setScoreprectage(file.data.percentage);
        var calculatescore=(file.data.total_score/100)*(jsonResult.length);
        setScore(file.data.total_score);
       if(file.data.homework_id){
        var homework_id = file.data.homework_id;
        setHomeworkId(homework_id);
        dispatch(homeworkActions.getHomeWorkByRecordId({homework_id}));
       }
       setProgramId(file.data.program_id);
        setPageType(file.data.type);

       
    }
  }, [file]);

  useEffect(() => {
    if(homework && homework.data){

        if(pageType == "Textbook Vocab"){
          var link = '/vocabularyQuiz/'+homeworkId+'/'+homework.data.Programs['Textbook Vocab Quiz'].id;
        }else if(pageType == "Listening Fill in the Blanks"){
          var link = '/listening-fill-in-the-blanks/'+homeworkId+'/'+homework.data.Programs['Listening Fill in the Blanks'].id;
        }else if(pageType == "Grammar MCQ"){
          var link = '/grammar-quiz/'+homeworkId+'/'+homework.data.Programs['Grammer MCQ'].id;
        }else if(pageType == "Listening Sentence Dictation"){
          var link = '/listening-sentence-dictation/'+homeworkId+'/'+homework.data.Programs['Listening Sentence Dictation'].id;
        } else if(pageType == "Listen Vocab"){
          var link = '/listeningvocabularyQuiz/'+homeworkId+'/'+homework.data.Programs['Listening Vocabulary Dictation'].id;
        }
        setPageLink(link);
    }
  }, [homework]);

  useEffect(() => {
    if(pageType){

        if(pageType == "Textbook Vocab"){
          var link = '/vocabularyQuiz/'+programId;
        }else if(pageType == "Listening Fill in the Blanks"){
          var link = '/listening-fill-in-the-blanks/'+programId;
        }else if(pageType == "Grammar MCQ"){
          var link = '/grammar-quiz/'+programId;
        }else if(pageType == "Listening Sentence Dictation"){
          var link = '/listening-sentence-dictation/'+programId;
        } else if(pageType == "Listen Vocab"){
          var link = '/listeningvocabularyQuiz/'+programId;
        }
        setPageLink(link);
    }
  }, [pageType]);

  const handleRetakeQuiz = () => {

    window.location.href = pageLink;
  };


       
      
      const preresult = result;

      console.log(preresult,'result');

 // const data = [{ name: 'Your score', scoreprectage }];
       const percentage = scoreprectage || score;
   const remaining=100-percentage;
 const data = [{ name: 'Your score', score: percentage || score, total: remaining  }];

      return (
  <div className="quiz-container">
      <div className='quizs-container'> 
        <div>
          <h2>Quiz Completed!</h2>

     <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200} />
  <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200} />
    </BarChart>

               <span className='score'>
    <div className="score-wrap">
        <span className="stars-active" style={{ width:`${scoreprectage}%`}}>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
<span className="stars-inactive">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
</div>
</span>

          <Table style={{ margin: '20px', width: '95%' }}>
          {(pageType !== "Listening Fill in the Blanks") ? 
            <thead>
              <th>Your Raw Score</th>
              <th>{score}/{preresult?preresult.length:''}</th>
            </thead>
: ''}
            <thead>
              <th>Your Score</th>
              <th>{scoreprectage || score}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
           
            {(file && file.data && file.data.type != 'Listening Fill in the Blanks') ? 
           <>
           <h3>Quiz Results:</h3>
           <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Word</th>
                  <th>Correct Answer</th>
                  <th>User Answer</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
               {(preresult ) ? preresult.map((question, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{(question.word) ? question.word : question.question}</td>
                    <td> {question.answer}</td>
                    <td>{question.useranswer}</td>
                    <th>{(question.answer == question.useranswer) ? 'O' : 'X'}</th>
                  </tr>

                )): ''}
              </tbody>
            </Table>
            </>
            :  <><h2>Your Answer</h2>
            <p>{(preresult ) ? ReactHtmlParser(preresult[0].useranswer) : ''}</p>
            <h2>Correct Answer</h2>
            <p>{(preresult ) ? ReactHtmlParser(preresult[0].correctanswer) : ''}</p>
            </>}
          </div>

          <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
        </div>
        </div>
    </div>
      );

}
