import { useEffect , useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { libraryActions,booksActions  } from '_store';

import { history } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";

export { TeacherLibraryHomeworkBookList };

function TeacherLibraryHomeworkBookList() {
  const dispatch = useDispatch();
  const { category,book_id,  date,student_id, class_id } = useParams();
  const { library } = useSelector((x) => x.library);
  const { bookHomework } = useSelector((x) => x.bookHomework);
  const { user: authUser } = useSelector((x) => x.auth);
  const [activeImage, setActiveImage] = useState([]);
  const [activeImages, setActiveImages] = useState((library && library.data) ? Array(library.data.length).fill(''): []);
  const [result, setResult] = useState();
  const [homeworkId, setHomeworkId] = useState();
	useEffect(() => {
		if (!authUser){
			toast.error('Please login to view this page.', {
			  position: "top-right",
			  autoClose: 5000,
			  hideProgressBar: false,
			  closeOnClick: true,
			  pauseOnHover: true,
			  draggable: true,
			  progress: undefined,
			  theme: "light",
			  });
			history.navigate('/login');
		  }else{
		var id  = book_id;
	  dispatch(libraryActions.getLibraryHomeWorkBookListByRecordId({id}));
	  if(authUser.user.student_id){
		console.log(id,'get id');
		var library_id  = book_id;
		//dispatch(libraryActions.getLibraryBookList({book_id}));
		const date = '';//new Date().toISOString().slice(0, 10);
		dispatch(booksActions.getLibraryBookHomework({library_id,date,student_id,class_id}));
	  }else{
		var library_id  = book_id;
		var teacher_id = authUser.user.teacher_id;
		//dispatch(libraryActions.getLibraryBookList({book_id}));

		dispatch(booksActions.getLibraryBookHomework({library_id, teacher_id, date,student_id,class_id}));
	  }
	 
		  }
	}, []);
	console.log(class_id,'library',book_id,'bokid',student_id,'stu',date,'dat',category);
	useEffect(() => {
		//console.log(bookHomework,'get homework');
		if(bookHomework && bookHomework.data){
			// bookHomework.data.homework.map(item => {
			// 	console.log(item, 'get item');
			// 	setActiveImage(item.id);

			//   });
			if(bookHomework.data.homework.length > 0){
		
Object.keys(bookHomework.data.dayhomework).forEach((dayHome) => {
  // Access the array of homework objects for each day
  const dayHomeworkList = bookHomework.data.dayhomework[dayHome];

  // Check if any item in the day's homework list has the matching subtype
  const hasMatchingSubtype = dayHomeworkList.some(
    (homework) => homework.subtype === category
  );

  if (hasMatchingSubtype) {
    // Update activeImage only if a matching subtype is found
    setActiveImage((prevActiveImages) => {
      if (!prevActiveImages.includes(dayHome)) {
        return [...prevActiveImages, dayHome];
      }
      return prevActiveImages;
    });
  }
});

				// Object.keys(bookHomework.data.dayhomework
				// ).map((dayHome, teamIndex) => {
				// 	console.log(dayHome,'get me here');
				// 	var daySplit = dayHome.split('-');
				// 	setActiveImage((prevActiveImages) => {
				// 		if (!prevActiveImages.includes(dayHome)) {
				// 		  return [...prevActiveImages, dayHome];
				// 		}
				// 		return prevActiveImages;
				// 	  });
				// })
				//setActiveImage(bookHomework.data.homework[0].library_id);
				//setActiveImage(daySplit[1]);
				//console.log(daySplit,'daySplit');
				
				setHomeworkId(bookHomework.data.homework[0].id);
				if((bookHomework.data.homework[0].Audio_Records) && bookHomework.data.homework[0].Audio_Records['Read Out Loud']){
				var jsonResult = JSON.parse(bookHomework.data.homework[0].Audio_Records['Read Out Loud'].result);
				setResult(jsonResult);

			   }
			   if((bookHomework.data.homework[0].Audio_Records) && bookHomework.data.homework[0].Audio_Records['Vocab Read Out Loud']){
				var jsonResult = JSON.parse(bookHomework.data.homework[0].Audio_Records['Vocab Read Out Loud'].result);
				setResult(jsonResult);
			   }

			   if((bookHomework.data.homework[0].Audio_Records) && bookHomework.data.homework[0].Audio_Records['Sentence Read Out Loud']){
				var jsonResult = JSON.parse(bookHomework.data.homework[0].Audio_Records['Sentence Read Out Loud'].result);
				setResult(jsonResult);
			   }
			 }
		}

	}, [bookHomework]);
	
	const clickToAssigntoStudent = async (index,day,title,program,homework_id,dayField,programId,active) => {//alert(programId);return false;
		//console.log(index,'get index',day);
		//alert(dayField);
		if(active && authUser.user.teacher_id){
			setActiveImage((prevActiveImages) => {
				if (prevActiveImages.includes(day)) {
				  // If 'day' is already in the array, remove it
				  return prevActiveImages.filter(activeDay => activeDay !== day);
				}
			  });
			  var days = dayField+'-'+day;
			  const formData = new FormData();
			  var teacher_id = authUser.user.teacher_id;
			formData.append("teacher_id", teacher_id);
			formData.append("class_id", class_id);
			formData.append("library_id", index);
			formData.append("day", days);
			if(homeworkId){
				formData.append("homework_id", homeworkId);
			}else{
				formData.append("homework_id", homework_id);
			}
			
			  const res = await axios.post(
				"https://api.webtracktechnologies.com.au:8002/homework/unselect",
				formData,
				{
				  headers: {
					'Authorization': `Bearer ${localStorage.getItem('token')}`,
					'Content-Type': 'application/json', // Make sure to set the content type based on your form data
				  },
				}
			  );
			  if(res){
				alert('Homework unassigned successfully.');
				return false;
			  }
			}	  
			if(authUser.user.teacher_id){
		if(bookHomework.data.homework.length == 0){
				if(day == 'Monday'){
					var dayVal = 1;
				}else{
					var dayVal = 2;
				}
				setActiveImage((prevActiveImages) => {
					if (!prevActiveImages.includes(day)) {
					  return [...prevActiveImages, day];
					}
					return prevActiveImages;
				  });
				var days = dayField+'-'+day;
				var teacher_id = authUser.user.id;
				const formData = new FormData();
				formData.append("teacher_id", '');
				formData.append("class_id", class_id);
				if(student_id){
					var sid = (student_id === 'null') ? null :student_id;
					formData.append("student_id", sid);
				}
				formData.append("homework_text", title+' '+dayField);
				formData.append("type", 'Library');
				formData.append("category", 'Library');
				formData.append("library_id", index);
				formData.append("day", days);
				formData.append("program_id", program);
				formData.append("subtype", category);
				const response = await axios.post(
				  "https://api.webtracktechnologies.com.au:8002/homework/create",
				  formData,
				  {
					headers: {
					  'Authorization': `Bearer ${localStorage.getItem('token')}`,
					  'Content-Type': 'application/json', // Make sure to set the content type based on your form data
					},
				  }
				);
				console.log(response,'get anjali homework');
				if(response){
					var homeworkId = response.data.data.id;
					var programId = response.data.data.program_id;
					setHomeworkId(response.data.data.id);
					if(!student_id){
						if(!homeworkId){
							window.location.href = '/HomeWorkVocabularyType/'+programId;
						}else{
							window.location.href = '/HomeWorkVocabularyType/'+homeworkId+'/'+programId;
						}
						
					}else{
						if(!homeworkId){
							window.location.href = '/HomeWorkVocabularyType/'+programId;
						}else{
						window.location.href = '/HomeWorkVocabularyType/'+homeworkId+'/'+programId+'/'+student_id;
						}
					}
					
				}
				
				
			}else{
				var homeworkId = bookHomework.data.homework[0].id;
				setHomeworkId(bookHomework.data.homework[0].id);
				window.location.href = '/HomeWorkVocabularyType/'+programId;
			}
		}else{
			if(active){
				var homeworkIds = (homeworkId) ? homeworkId : homework_id;
				if(!student_id){
					if(!homeworkIds){
						window.location.href = '/HomeWorkVocabularyType/'+programId;
					}else{
						window.location.href = '/HomeWorkVocabularyType/'+homeworkIds+'/'+programId;
					}
					
				}else{
					if(!homeworkIds){
						window.location.href = '/HomeWorkVocabularyType/'+programId;
					}else{
						window.location.href = '/HomeWorkVocabularyType/'+homeworkIds+'/'+programId+'/'+student_id;
					}
					
				}
			}else{
				if(!student_id){
					if(!homework_id){
						window.location.href = '/HomeWorkVocabularyType/'+programId;
					}else{
						window.location.href = '/HomeWorkVocabularyType/'+homework_id+'/'+programId;
					}
					
				}else{
					if(!homework_id){
						window.location.href = '/HomeWorkVocabularyType/'+programId;
					}else{
						window.location.href = '/HomeWorkVocabularyType/'+homework_id+'/'+programId+'/'+student_id;
					}
					
				}
			}
			
		}

	  };
	const clickToAssign = async (index,day,title,program,homework_id,dayField,programId,active) => {
		console.log(program,'get index',active);
		//alert(dayField);
		setActiveImages(prevActiveImages => {
			const newActiveImages = [...prevActiveImages];
			newActiveImages[program] = day;
			return newActiveImages;
		  });
		  if(active && authUser.user.teacher_id){
			setActiveImage((prevActiveImages) => {
				if (prevActiveImages.includes(dayField+'-'+day)) {
				  // If 'day' is already in the array, remove it
				  return prevActiveImages.filter(activeDay => activeDay !== dayField+'-'+day);
				}
			  });
			  var days = dayField+'-'+day;
			  const formData = new FormData();
			  var teacher_id = authUser.user.teacher_id;
			formData.append("teacher_id", teacher_id);
			formData.append("class_id", class_id);
			formData.append("library_id", index);
			formData.append("day", days);
			if(homeworkId){
				formData.append("homework_id", homeworkId);
			}else{
				formData.append("homework_id", homework_id);
			}
			
			
			  const res = await axios.post(
				"https://api.webtracktechnologies.com.au:8002/homework/unselect",
				formData,
				{
				  headers: {
					'Authorization': `Bearer ${localStorage.getItem('token')}`,
					'Content-Type': 'application/json', // Make sure to set the content type based on your form data
				  },
				}
			  );
			  if(res){
				alert('Homework unassigned successfully.');
				console.log(res,'get unselcr');
				return false;
			  }
			  
		  }
		if(authUser.user.teacher_id){
			if(day == 'Monday'){
				var dayVal = 1;
			}else{
				var dayVal = 3;
			}
			setActiveImage((prevActiveImages) => {
				if (!prevActiveImages.includes(dayField+'-'+day)) {
				  return [...prevActiveImages, dayField+'-'+day];
				}
				return prevActiveImages;
			  });
			//setActiveImage(day);
			var days = dayField+'-'+day;
			var teacher_id = authUser.user.teacher_id;
			const formData = new FormData();
			formData.append("teacher_id", teacher_id);
			if(student_id){
				var sid = (student_id === 'null') ? null :student_id;
				formData.append("student_id", sid);
			}
			formData.append("class_id", class_id);
			formData.append("homework_text", title+' '+dayField);
			formData.append("type", 'Library');
			formData.append("category", 'Library');
			formData.append("library_id", index);
			formData.append("day", days);
			//formData.append("program_id", programId);
			formData.append("program_id", program);
			formData.append("subtype", category);
			if(date){
				formData.append("date", date);
			}
			
			const response = await axios.post(
			  "https://api.webtracktechnologies.com.au:8002/homework/create",
			  formData,
			  {
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('token')}`,
				  'Content-Type': 'application/json', // Make sure to set the content type based on your form data
				},
			  }
			);
			if(response){
				console.log(response,'get res create');
				setHomeworkId(response.data.data.id);
				alert('Homework Assigned successfully.');
			}
		}else{

			if(bookHomework.data.homework.length == 0){
				// if(day == 'Monday'){
				// 	var dayVal = 1;
				// }else{
				// 	var dayVal = 2;
				// }
				// setActiveImage((prevActiveImages) => {
				// 	if (!prevActiveImages.includes(dayField+'-'+day)) {
				// 	  return [...prevActiveImages, dayField+'-'+day];
				// 	}
				// 	return prevActiveImages;
				//   });
				// //setActiveImage(day);
				// var days = dayField+'-'+day;
				// var teacher_id = authUser.user.id;
				// const formData = new FormData();
				// formData.append("teacher_id", '');
				// formData.append("class_id", class_id);
				// if(student_id){
				// 	var sid = (student_id === 'null') ? null : student_id;
				// formData.append("student_id", sid);
				// }
				
				// formData.append("homework_text", title+' '+dayVal);
				// formData.append("type", 'Library');
				// formData.append("category", 'Library');
				// formData.append("library_id", index);
				// formData.append("day", days);
				// formData.append("program_id", program);
				// if(date){
				// 	formData.append("date", date);
				// }
				// //formData.append("program_id", programId);
				// const response = await axios.post(
				//   "https://api.webtracktechnologies.com.au:8002/homework/create",
				//   formData,
				//   {
				// 	headers: {
				// 	  'Authorization': `Bearer ${localStorage.getItem('token')}`,
				// 	  'Content-Type': 'application/json', // Make sure to set the content type based on your form data
				// 	},
				//   }
				// );
				// console.log(response,'get anjali homework');
				// if(response){
				// 	var homeworkId = response.data.data.id;
				// 	var programId = response.data.data.program_id;
				// 	setHomeworkId(response.data.data.id);
				// 	window.location.href = '/HomeWorkVocabularyType/'+homeworkId+'/'+programId;
				// }
				window.location.href = '/HomeWorkVocabularyType/'+programId;
			}else{
				var homeworkId = bookHomework.data.homework[0].id;
				setHomeworkId(bookHomework.data.homework[0].id);
				if(active){
					var homeworkIds = (homeworkId) ? homeworkId : homework_id;
					if(!student_id){
						if(!homeworkIds){
							window.location.href = '/HomeWorkVocabularyType/'+programId;
						}else{
							window.location.href = '/HomeWorkVocabularyType/'+homeworkIds+'/'+programId;
						}
						
					}else{
						if(!homeworkIds){
							window.location.href = '/HomeWorkVocabularyType/'+programId;
						}else{
							window.location.href = '/HomeWorkVocabularyType/'+homeworkIds+'/'+programId+'/'+student_id;
						}
						
					}
					
				}else{
					if(!student_id){
						if(!homework_id){
							window.location.href = '/HomeWorkVocabularyType/'+programId;
						}else{
							window.location.href = '/HomeWorkVocabularyType/'+homework_id+'/'+programId;
						}
						
					}else{
						if(!homework_id){
							window.location.href = '/HomeWorkVocabularyType/'+programId;
						}else{
							window.location.href = '/HomeWorkVocabularyType/'+homework_id+'/'+programId+'/'+student_id;
						}
						
					}
				}
				//window.location.href = '/HomeWorkVocabularyType/'+programId;
			}

		}

	  };
	  console.log(homeworkId,'get active image');

	
	  const getStampFromScore = (score) => {
		let stampText = '/images/tryagain1.png';
	  
		if (score >= 0 && score <= 0.3) {
		  stampText = '/images/tryagain1.png';
		} else if (score > 0.3 && score <= 0.6) {
		  stampText = '/images/tryagain1.png';
		} else if (score > 0.6 && score <= 0.9) {
		  stampText = '/images/tryagain1.png';
		} else if (score > 0.9 && score <= 1.3) {
		  stampText = '/images/tryagain1.png';
		} else if (score > 1.3 && score <= 1.6) {
		  stampText = '/images/tryagain1.png';
		} else if (score > 1.6 && score <= 1.9) {
		  stampText = '/images/tryagain1.png';
		} else if (score > 1.9 && score <= 2.3) {
		  stampText = '/images/tryagain1.png';
		} else if (score > 2.3 && score <= 2.6) {
		  stampText = '/images/tryagain1.png';
		} else if (score > 2.6 && score <= 3) {
		  stampText = '/images/greatjob1.png';
		} else if (score > 3 && score <= 3.3) {
		  stampText = '/images/amazing1.png';
		} else if (score > 3.3 && score <= 4) {
		  stampText = '/images/excellent1.png';
		}
	  
		return stampText;
	  };
	  const renderStars = (rating) => {
		console.log(rating,'get rating');

		let stars = [];
		for (let i = 1; i <= 5; i++) {
		  stars.push(
			<i
			  key={i}
			  className={i <= rating ? 'fa fa-star active' : 'fa fa-star'}
			  aria-hidden="true"
			/>
		  );
		}
		return stars;
	  };
	   // Helper function to calculate average score across all types
	   const calculateAverageScore = (audioRecords) => {
		if (!audioRecords || typeof audioRecords !== "object") return 0;
  
		const scores = Object.values(audioRecords).map(
		  (record) => record.calculatedscore
		);

		console.log(scores,'get scores');
	
		if (scores.length === 0) return 0;
	
		const total = scores.reduce((sum, score) => sum + score, 0);
		
		return total / scores.length;
	  };
	  let lastIndex = 0;
    return (

      <div class="container">
	<div class="library-title">
		<h4></h4>
		<h2>HOMEWORK LIST</h2>
	</div>
	<div class="book-row">
		     {/* Render days of the week */}
			 <div class="single-book-middle">
			 <div class="single-list title-single">
				<h3></h3>
			</div>
			 {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
        <div key={index} className="single-list title-single">
          <h3>{day}</h3>
        </div>
      ))}
	  </div>
		{(library && library.data) ? 
		library.data.map((book, index) => (
	<div class="single-book-middle">

			<div class="books-stand single-list">
			<h3>{Object.keys(library.data[index])}</h3>
				</div>
			<div class="books-stand single-list">
	
				
			</div>

			<div class="books-stand single-list">
			{ (Object.keys(book[Object.keys(book)[0]])) ? Object.keys(book[Object.keys(book)[0]]).map((day, j) => (
         	<>
		  {(day == "Monday") ? 
		  <div key={j}>
            {book[Object.keys(book)[0]][day].map((item, k) => {
	 lastIndex = lastIndex + 1;
	 const audioRecords = (item.homework.length > 0) ? item.homework[0].Audio_Records : {};
	 const averageScore = calculateAverageScore(audioRecords);
	 const hasStamp = averageScore > 0;

			return (
         <div key={k}>
			  <h3>{item.library.book_title} {lastIndex}</h3>
			  {(result && activeImage.includes(`${item.day}-Monday`)) ? 
			  <a href={(student_id) ? `/HomeWorkVocabularyType/${homeworkId}/${item.id}/${student_id}` : `/HomeWorkVocabularyType/${homeworkId}/${item.id}`}>
				<img
				className={`book-img ${activeImage.includes('Monday') ? 'active' : ''}`}
				src="/images/picture_sample.png"
				alt="Book Image"
			  />
			  {hasStamp && (
                      <img
                        src={getStampFromScore(averageScore)}
                        alt="Uploaded File"
                        class="img-fluid book-cert recording-list"
                      />
                    )}
			  </a>
			  :
			  ((!result && authUser.user.student_id)) ?
				<img
			  className={`book-img ${activeImage.includes(`${item.day}-Monday`) ? 'active' : ''}`}
			  onClick={() => clickToAssigntoStudent(item.library.id,'Monday',item.library.book_title,item.id,(item.homework.length > 0 ) ? item.homework[0].id: '',(item.programs) ? item.programs[0].day : item.day,(item.programs) ?  item.programs[0].id:item.id,activeImage.includes(`${item.day}-Monday`))}
			  src="/images/picture_sample.png"
			  alt="Book Image"
			/>: <img
			className={`book-img ${activeImage.includes(`${item.day}-Monday`) ? 'active' : ''}`}
			onClick={() => clickToAssign(item.library.id,'Monday',item.library.book_title,item.id,(item.homework.length > 0 ) ? item.homework[0].id: '',(item.programs) ? item.programs[0].day : item.day,(item.programs) ? item.programs[0].id :item.id,activeImage.includes(`${item.day}-Monday`))}
			src="/images/picture_sample.png"
			alt="Book Image"
		  /> }
			  
			  <div class="rating">
			  {hasStamp ? (
                    renderStars(averageScore)
                  ) : (
                    <>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </>
                  )}

			  </div>
			  </div>
			  
			 
					// ))}
					//  </>
	
            //   <div key={k}>
            //     <h3>{item.library.book_title} {item.id}</h3>
            //     <img
            //       className={`book-img ${activeImage === item.id ? 'active' : ''}`}
            //       onClick={() => clickToAssign(item.id,'Monday',item.library.book_title,item.id,item.library.classId)}
            //       src="/images/picture_sample.png"
            //       alt="Book Image"
            //     />
            //     <div class="rating">
            //       <i class="fa fa-star active" aria-hidden="true"></i>
            //       <i class="fa fa-star active" aria-hidden="true"></i>
            //       <i class="fa fa-star active" aria-hidden="true"></i>
            //       <i class="fa fa-star active" aria-hidden="true"></i>
            //       <i class="fa fa-star" aria-hidden="true"></i>
            //     </div>
            //   </div>
			
 ) }) }
			</div>
			:''}
			</> 
			  )) : ''}
			</div>
			<div class="books-stand single-list">
				
			</div>
			<div class="books-stand single-list">
			{ (Object.keys(book[Object.keys(book)[0]])) ? Object.keys(book[Object.keys(book)[0]]).map((day, j) => (
           <>
		   {(day == "Wednesday") ? 
		  <div key={j}>
            {book[Object.keys(book)[0]][day].map((item, k) => {
				lastIndex = lastIndex + 1;
				const audioRecords = (item.homework.length > 0) ? item.homework[0].Audio_Records : {};
			const averageScore = calculateAverageScore(audioRecords);
			const hasStamp = averageScore > 0;
		return (
			<div key={k}>
			<h3>{item.library.book_title} {lastIndex}</h3>
			{(result && activeImage.includes(`${item.day}-Wednesday`)) ? 
			<a href={(student_id) ? `/HomeWorkVocabularyType/${homeworkId}/${item.id}/${student_id}` : `/HomeWorkVocabularyType/${homeworkId}/${item.id}`}>
			  <img
			  className={`book-img ${activeImage.includes('Wednesday') ? 'active' : ''}`}
			  src="/images/picture_sample.png"
			  alt="Book Image"
			/>
			{hasStamp && (
				<img
				src={getStampFromScore(averageScore)}
				alt="Uploaded File"
				class="img-fluid book-cert recording-list"
				/>
			)}
			</a>
			:
			((!result && authUser.user.student_id)) ?
			  <img
			className={`book-img ${activeImage.includes(`${item.day}-Wednesday`) ? 'active' : ''}`}
			onClick={() => clickToAssigntoStudent(item.library.id,'Wednesday',item.library.book_title,item.id,(item.homework.length > 0 ) ? item.homework[0].id: '',(item.programs) ? item.programs[0].day : item.day,(item.programs) ?  item.programs[0].id:item.id,activeImage.includes(`${item.day}-Wednesday`))}
			src="/images/picture_sample.png"
			alt="Book Image"
		  />: <img
		  className={`book-img ${activeImage.includes(`${item.day}-Wednesday`) ? 'active' : ''}`}
		  onClick={() => clickToAssign(item.library.id,'Wednesday',item.library.book_title,item.id,(item.homework.length > 0 ) ? item.homework[0].id: '',(item.programs) ? item.programs[0].day : item.day,(item.programs) ? item.programs[0].id :item.id,activeImage.includes(`${item.day}-Wednesday`))}
		  src="/images/picture_sample.png"
		  alt="Book Image"
		/> }
			
			<div class="rating">
			  {hasStamp ? (
                    renderStars(averageScore)
                  ) : (
                    <>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <i class="fa fa-star" aria-hidden="true"></i>
                    </>
                  )}

			  </div>
			</div>
        //       <div key={k}>
        //         <h3>{item.library.book_title} {lastIndex}</h3>
		// 		{(result && activeImage.includes(`${item.day}-Wednesday`)) ?   <a href={`/HomeWorkVocabularyType/${homeworkId}/${item.id}`}><img
        //           className={`book-img ${activeImage.includes(`${item.day}-Wednesday`) ? 'active' : ''}`}
        //           src="/images/picture_sample.png"
        //           alt="Book Image"
        //         /> <img src={getStampFromScore(calculateTotalScore( calculateAccuracyPercentage(result),
		// 			getFluencyAverageScore(result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} 
		// 			alt="Uploaded File" class="img-fluid book-cert recording-list" /></a>: 
		// 		<img
		// 		className={`book-img ${activeImage.includes(`${item.day}-Wednesday`) ? 'active' : ''}`}
		// 		onClick={() => clickToAssign(item.library.id,'Wednesday',item.library.book_title,item.id,(item.homework.length > 0 ) ? item.homework[0].id: '',(item.programs) ? item.programs[0].day : item.day,(item.programs) ? item.programs[0].id : item.id,activeImage.includes(`${item.day}-Wednesday`))}
		// 		src="/images/picture_sample.png"
		// 		alt="Book Image"
		// 	  />}
               

		// 	   <div class="rating">
		// 		{(result) ?
		// 	  renderStars(calculateTotalScore( calculateAccuracyPercentage(result),
        // getFluencyAverageScore(result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) )) : 
		// <>
		// <i class="fa fa-star" aria-hidden="true"></i>
		// 		<i class="fa fa-star" aria-hidden="true"></i>
		// 		<i class="fa fa-star" aria-hidden="true"></i>
		// 		<i class="fa fa-star" aria-hidden="true"></i>
		// 		<i class="fa fa-star" aria-hidden="true"></i>
		// 		</>
		// }

		// 	  </div>
        //       </div>
            )})}
			</div>
			: ''}
			</>
			  )) : ''}
			</div>
			<div class="books-stand single-list">
				
			</div>
			<div class="books-stand single-list">
				
			</div>
			<div class="books-stand single-list">
				
			</div>
		</div>	
	
		)): ''}
	</div>
	
	<div class="btn-control">
		<button class="nav-button prev">Previous</button>
		<button class="nav-button next">Next</button>
	</div>

</div>
      
      
    )
}
