import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { studentActions, audioActions } from "_store";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Table } from "react-bootstrap";
import axios from "axios";
export { Dashboard };
function Dashboard() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { audio } = useSelector((x) => x.audio);
  const audioRef = useRef(null);
  const [audioFile, setAudioFile] = useState("");
  const [filePath, setFilePath] = useState("");
  const [audioQuality, setAudioQuality] = useState("");
  const recorderControls = useAudioRecorder();
  console.log(audio, "get audio from redux");

  const handleAudioAnalysis = async () => {
    console.log(filePath, "get file");
    var file = filePath;
    return dispatch(audioActions.audiorecording({ file }));
  };
  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const addAudioElement = async (blob) => {
    const url = URL.createObjectURL(blob);
    //alert("lalit");
 


    blobToBase64(blob).then((res) => {
      // do what you wanna do
      console.log(res); // res is base64 now
    });
    //
    const formData = new FormData();
    formData.append("audio", blob);
    formData.append("user", authUser.user.id);
    //alert('anjali');
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/audio-recording",
      formData
    );

    setAudioFile(response.data.data);
    setFilePath(response.data.filepath);
   const audio1 = document.createElement("audio");
    audio1.src = url;
    audio1.controls = true;
    
  };

  useEffect(() => {
    const userId = authUser.user.id;
    dispatch(studentActions.getStudentById({ userId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.user]);

  useEffect(() => {
    console.log(audio, "get audio from redux1");
    if (audio) {
      if (audio.data.message.AAResult[0].audioQuality[0].status == 10) {
        setAudioQuality("Audio Quality is noisy.");
      } else if (audio.data.message.AAResult[0].audioQuality[0].status == 8) {
        setAudioQuality("Audio Quality is soft.");
      } else if (audio.data.message.AAResult[0].audioQuality[0].status == 18) {
        setAudioQuality("Audio Quality is soft and noisy.");
      } else {
        setAudioQuality("Audio is too short.");
      }
      console.log(audioQuality, "get audio from redux123");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);
  return (
    <section className="dashboard-main-wrap">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="left-collapse-wrap">
              <div className="main-dashboard-left-wrap">
                <div className="dashboard-profile">
                  <div className="d-profile-img">
                    <img src="/images/profile-img-1.webp" alt="" />
                    {/* <img src={`${process.env.REACT_APP_API_URL}/uploads/`+authUser.user.profile_pic} alt="" /> */}
                  </div>
                  <div className="profile-name">
                    <h4>
                      {authUser.user.name} {authUser.user.lastName}
                    </h4>
                  </div>
                  <a className="custom-button" href="javascript:void(0)">
                    Edit Profile
                  </a>
                </div>
                <div className="dashboard-left-list">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Classes</a>
                    </li>
                    <li>
                      <a href="#">Students</a>
                    </li>
                    <li>
                      <a href="#">Subscription Plan</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="hamburger" id="hamburger-6">
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </div>
            </div>
          </div>
          <div className="col-lg-9 p-0">
            <div className="main-dashboard-right-wrap">
              <div className="text-center">
                <h4>Welcome to the Dashboard!</h4>
              </div>
              {/*
               <a className="custom-button" href="#">
                <AudioRecorder
                  onRecordingComplete={(blob) => addAudioElement(blob)}
                  recorderControls={recorderControls}
                  audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                  }}
                  downloadOnSavePress={true}
                  downloadFileExtension="mp3"
                  showVisualizer={true}
                />
              </a>

              {recorderControls.isRecording ? (
                <button
                  onClick={recorderControls.stopRecording}
                  className="hide"
                >
                  Stop recording
                </button>
              ) : (
                <button onClick={recorderControls.startRecording} className="">
                  Start recording
                </button>
              )}*/}
            </div>
            {/* <div>
              {audioFile ? (
                <>
                  <audio ref={audioRef} controls>
                    <source src={audioFile} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  <button onClick={handleAudioAnalysis}>Submit</button>
                </>
              ) : (
                ""
              )}

              {audio ? (
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th colSpan={5} style={{ textAlign: "center" }}>
                        LIKE Recorder Free Responsive Score Report
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{authUser.user.name}</td>
                      <td rowSpan={5} style={{ textAlign: "center" }}>
                        {audio.data.message.AAResult[0].analysis[0].score[0] >
                        0.5 ? (
                          <h4 style={{ color: "green" }}>Pass</h4>
                        ) : (
                          "Fail"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Test Date</td>
                      <td>{new Date().toDateString()}</td>
                    </tr>
                    <tr>
                      <td>Audio Quality</td>

                      <td>{audioQuality}</td>
                    </tr>
                    <tr>
                      <td>Total Recording Time</td>
                      <td>0.{
                          parseInt(audio.data.message.AAResult[0].audioLenSec[0])
                        } Seconds</td>
                    </tr>
                    <tr>
                      <td>Total Speaking Time</td>
                      <td>
                      0.{
                          parseInt(audio.data.message.AAResult[0].analysis[0]
                            .speakingSummary[0].spkTimeSec[0])
                        }{" "}
                        Seconds
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Scores</td>
                      <td colSpan={5}>Comments</td>
                    </tr>
                    <tr>
                      <td>Content</td>
                      <td></td>
                      <td colSpan={5}>Your Speech contains {
                          audio.data.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        } words or less.</td>
                    </tr>
                    <tr>
                      <td>Fluency</td>
                      <td></td>
                      <td colSpan={5}></td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>
                        {audio.data.message.AAResult[0].analysis[0].score[0]}
                      </td>
                      <td colSpan={5}></td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                ""
              )}
            </div>  */}
          </div>
        </div>
      </div>
    </section>
  );
}
