import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quizActions, quizresultActions,libraryActions , homeworkActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';
export { GrammarQuiz };

function GrammarQuiz() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homework_id,program_id } = useParams();
  const [myState, setMyState] = useState('initialValue');
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);
  const { library } = useSelector((x) => x.library);
  const { homework } = useSelector((x) => x.homework);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [quizCategory, setquizCategory] = useState();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [quizalready, setQuizAlready] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const [timer, setTimer] = useState(null);
  const [day, setDay] = useState(1);
  const [resultData, setResult] = useState([]);
  
  
console.log(library,'get lib',quizData);

  const student = JSON.parse(localStorage.getItem('user'));

 useEffect(() => {
 
    dispatch(libraryActions.getGrammarQuizForLibrary({program_id}));
    dispatch(homeworkActions.getHomeWorkByRecordId({ homework_id }));
  }, []);

  const handleOptionSelect = (selected) => {
    setSelectedOption(selected);
    handleNextQuestion(selected);
    setUserAnswers(prevAnswers => [...prevAnswers, selected]);
  };


  const handleNextQuestion = (selectedOption) => {
    // if (timer) {
    //   clearTimeout(timer);
    // }

    if (selectedOption === quizData[currentQuestion].answer) {
      setScore(score + 1);
    }

    setSelectedOption(null);

    if (currentQuestion + 1 < quizData.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      console.log("quiz comp");
    }

    if (currentQuestion === quizData.length - 1) {
      console.log('Setting quizCompleted to true');
      setQuizCompleted(true);
    }

    // setTimeRemaining(location.state.vocbsetting.time);
  };



  const handleKeyPress = (event) => {
    const selectedNumber = Number(event.key);
    if (!isNaN(selectedNumber) && selectedNumber > 0 && selectedNumber <= quizData[currentQuestion].options.length) {
      const selectedOption = quizData[currentQuestion].options[selectedNumber - 1];
      handleOptionSelect(selectedOption);
    }
  };

  // useEffect(() => {
  //   let timer;
  //   // console.log(location.state.vocbsetting)
  //   if (timeRemaining > 0 && currentQuestion < quizData.length && location.state.vocbsetting.istime == 1) {
  //     timer = setTimeout(() => {
  //       handleNextQuestion(null);
  //     }, timeRemaining * 1000);

  //     const countdownInterval = setInterval(() => {
  //       setTimeRemaining((prevTime) => prevTime - 1);
  //     }, 1000);

  //     setTimer(timer);

  //     return () => {
  //       if (timer) {
  //         clearTimeout(timer);
  //       }
  //       clearInterval(countdownInterval);
  //     };
  //   }
  // }, [timeRemaining, currentQuestion, quizData]);





  // useEffect(() => {
  //   try {
  //     if (quizData.length === 0) {
  //       // if (!location.state) {
  //       //   window.location.href = '/';
  //       // }
  //       if(library && library.data){
  //         setQuizData(library.data);
  //       }
        
  //       setquizCategory("asas");

  //     }


  //   }
  //   catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // }, [dispatch]);


  useEffect(() => {
    try {
      if (quizData.length === 0) {
        // if (!location.state) {
        //   window.location.href = '/';
        // }
        if(library && library.data){
          setQuizData(library.data);
        }
        
        setquizCategory("GRAMMAR QUIZ");

      }


    }
    catch (error) {
      console.error("An error occurred:", error);
    }
  }, [library]);

  useEffect(() => {
    if(homework && homework.data){
      var getDay = homework.data.day;
      getDay.split('-');
      setDay(getDay[0]);
    }
   
  }, [homework]);

  useEffect(() => {

    window.addEventListener('keydown', handleKeyPress);


    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (quizCompleted === true) {
          console.log(quizCompleted, "quizcompleted");
          const result = []
          {
            quizData.map((question, index) => (
              result.push({ question: question.question, answer: question.answer, options:question.options, useranswer: (index < userAnswers.length) ? userAnswers[index] : "Not answered" })
            ))
          }
    
          console.log(student, "student");
          if (student) {
             const scoreprecentage=((score / quizData.length) * 100);
            const option = {
             // category: location.state.vocabulary.id,
              total_score:scoreprecentage,
              Studentid: student.user.student_id,
              type: "Grammar MCQ",
              sub_type: "Grammar MCQ",
              homework_id:homework_id,
              result: JSON.stringify(result)
            }
            var resultOpt = {
              result:JSON.stringify(result)
            }
            const resultRes = await axios.post(
              "https://api.webtracktechnologies.com.au:8002/library/grammer/checkresult",
              resultOpt,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  // Add other headers if needed
                },
              }
            );
            if(resultRes){

              setResult(resultRes);
              const response = await axios.post(
                "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
                option,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                  },
                }
              );
              console.log(response);
            }
            console.log(option, "opt")
     
            //dispatch(homeworkActions.savevocabresult(option));
          }
    
        }
      } catch (error) {
        console.error('Error fetching homework data:', error);
      }
    };
  
    fetchData();
  }, [dispatch, quizCompleted, quizCategory, score, quizData, userAnswers]);
  
  // useEffect( () => {
  //   if (quizCompleted === true) {
  //     console.log(quizCompleted, "quizcompleted");
  //     const result = []
  //     {
  //       quizData.map((question, index) => (
  //         result.push({ word: question.word, answer: question.answer, useranswer: (index < userAnswers.length) ? userAnswers[index] : "Not answered" })
  //       ))
  //     }

  //     console.log(student, "student");
  //     if (student) {
         
  //       const option = {
  //        // category: location.state.vocabulary.id,
  //         total_score:score,
  //         Studentid: student.user.student_id,
  //         type: "Vocab",
  //         homework_id:homework_id,
  //         result: JSON.stringify(result)
  //       }
  //       console.log(option, "opt")

  //       //dispatch(homeworkActions.savevocabresult(option));
  //     }

  //   }
  // }, [dispatch, quizCompleted, quizCategory, score, quizData, userAnswers]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }


  const renderOptions = () => {
    return quizData[currentQuestion].options.map((option, index) => (
      <div key={index} className='quiz-options'>
        <label className='option-index'>{index + 1}</label>
        <label className="container" htmlFor={option}>
          {option}
          <input
            type="radio"
            id={option}
            name="options"
            value={option}
            checked={selectedOption === option}
            onChange={() => handleOptionSelect(option)}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    ));
  };
console.log(resultData,'get result fata');
  const handleRetakeQuiz = () => {

    const shuffledQuizData = shuffleArray([...quizData]);

    setQuizCompleted(false);
    setQuizAlready(false)
    setCurrentQuestion(0);
    setSelectedOption(null);
    setScore(0);
    setQuizData(shuffledQuizData);
  };

  const renderQuizContent = () => {
    if (currentQuestion < quizData.length && quizCompleted === false ) {
      const totalTime = 10;
      const remainingPercentage = (timeRemaining / totalTime) * 100;
      const precent = (100 - ((currentQuestion - score) / quizData.length * 100)).toFixed(2);
      return (
<div class="quizs-container" > 
        <div className="quiz-content">
          <div className="quiz-header">
            <div className="category-head">
              <h4>{quizCategory}</h4>
              <h5>{'7-'+day}</h5>
            </div>

            {/*{location.state.vocbsetting.istime === 1 && (
              <ProgressBar stripped style={{
                width: "100%",
                height: "1.3rem",
                fontSize: "0.95rem"
              }}
                animated now={remainingPercentage} label={`${timeRemaining} seconds`} />
            )}*/}
              </div>
            <div class="quiz-body">
            <div className="quiz-progress">
              <p>Question {currentQuestion + 1} of {quizData.length}</p>
              {/* <p>Percentage: {(currentQuestion === 0) ? 100 : ((score /quizData.length ) * 100).toFixed(2)}%</p> */}
              <p>Percentage: {precent} %</p>


            </div>
          
          <div className="quiz-question-area">
          <div class="quiz-question-area-part-one">
            <h3 style={{ textTransform: "none" }}>{quizData[currentQuestion].question}</h3>
            </div>
            <div class="quiz-question-area-part-two">
            {renderOptions()}
            </div>
          
          </div>
        </div>
      </div>
        </div>
      );
    }
     else {
      const percentage = ((score / quizData.length) * 100);
   const remaining=100-percentage;
 const data = [{ name: 'Your score', score: percentage, total: remaining  }];
      return (

        <div>
          <h2>Quiz Result!</h2>


          {/*<BarChart
            xAxis={[{ scaleType: 'band', data: ['Your score'] }]}
            series={[{ data: [percentage], color: "#3a58e9b8", stack: 'total' }, { data: [100 - percentage], color: "#992525", stack: 'total' }]}
            width={500}
            height={300}

          />*/} 

        <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
  <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart>

           <span className='score'>
    <div className="score-wrap">
        <span className="stars-active" style={{ width:`${percentage}%`}}>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
<span className="stars-inactive">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
</div>
</span>
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {quizData.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Question</th>
                  <th></th>
                  <th>O X</th>
                </tr>
              </thead>
              <tbody>
                {(resultData && resultData.data) ? resultData.data.data.questions.map((question, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{question.question}</td>
                    <td>
                        {question.combined_result.map((option, index) => (
                          <div key={index} className='quiz-options'>
                            <label className='option-index'>{index + 1}</label>
                            <label className="container" htmlFor={option}>
                              {ReactHtmlParser(option)}
                              <input
                                type="radio"
                                id={option}
                                name="options"
                                value={option}
                                checked={selectedOption === option}
                                onChange={() => handleOptionSelect(option)}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        ))}
                    </td>
                    <th>{(question.answer == userAnswers[index]) ? 'O' : 'X'}</th>
                  </tr>
                )) : ''}
              </tbody>
            </Table>
          </div>

          <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
        </div>
      );
    }
  };



  return (
    <div className="quiz-container">
      <div className='quizs-container'> {renderQuizContent()} </div>
    </div>
  );
}

