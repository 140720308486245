import { useEffect , useState} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { homeworkActions, classesActions , gradeattendenceActions,quizActions} from '_store';

import { history } from '_helpers';
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
export { SingleClassroom };

function SingleClassroom() {
  const dispatch = useDispatch();
  const studentsArr = [
  ];
 // const { classes } = useSelector((x) => x.classes);
  const [showDialog, setShowDialog] = useState(false);
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [showContentDialog, setShowContentDialog] = useState(false);
  const { classid } = useParams();
  const [getType, setType] = useState(false);
  const [getTextValue, setTextValue] = useState('');
  const { user: authUser } = useSelector((x) => x.auth);
  //const [classes, setClasses] = useState();
  const { classes } = useSelector((x) => x.classes);
  const { gradeattendence } = useSelector((x) => x.gradeattendence);
  const [showLink, setShowLink] = useState(false);
  const today = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState(today);
  const [link, setLink] = useState('');
  const [totalQZ, setTotalQZ] = useState('');
  const [monthAttr, setMonthAttr] = useState(false);
  const [base64String, setBase64String] = useState(null);
  const [studentState, setStudentState] = useState({});
  const [showMonthlyDialog, setShowMonthlyDialog] = useState(false);
  const [showPrintQuizDialog, setShowPrintQuizDialog] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [readGrade, setReadGrade] = useState('');
  const [listGrade, setListGrade] = useState('');
  const [spekGrade, setSpekGrade] = useState('');
  const [writGrade, setWritGrade] = useState('');
  const [gramGrade, setGramGrade] = useState('');
  const [vocabGrade, setVocabGrade] = useState('');
  const [txtHWRead, setTxtHWRead] = useState('');
  const [txtQZRead, setTxtQZRead] = useState('');
  const [xValue, setXValue] = useState('');
  const [yearName, setYear] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [studentGrades, setStudentGrades] = useState(((classes && classes.data) ? 
  classes.data.students : studentsArr));
  const [modalContent, setModalContent] = useState('');
  const [contentType, setContentType] = useState('');
  let readHighestGrade = ''; // Initialize with a value lower than the lowest grade
  let listenHighestGrade = ''; 
  let speakHighestGrade = ''; 
  let writeHighestGrade = ''; 
  let gramHighestGrade = ''; 
  let vocabHighestGrade = ''; 
  const grades = ['', 'A+', 'A', 'B+', 'B', 'C+'];
  const gradesOX = ['', 'O', 'X','O-red','X-red'];
  const attendanceGrade = ['', 'P', 'L','A'];
  const gradeValues = {
    'A+': 5,
    'A': 4,
    'B+': 3,
    'B': 2,
    'C+': 1
  };
  const [randomOrder, setRandomOrder] = useState(true);
  const [wordMeaningToggle, setWordMeaningToggle] = useState(true);
  const [readingVocabToggle, setReadingVocabToggle] = useState(true);
  const [readingQuizToggle, setReadingQuizToggle] = useState(true);
  const [studyVocabToggle, setStudyVocabToggle] = useState(true);
  const { quiz } = useSelector((x) => x.quiz);
  const [clickedTd, setClickedTd] = useState({});
  const [rewards, setRewards] = useState([]);
  const [clickedTd1, setClickedTd1] = useState({});
  const [commentStudentName, setCommentsStudentName] = useState('');
  const [selectedStudentForHW, setSelectedStudentForHW] = useState(null);
  const [studentTotalHW, setStudentTotalHW] = useState({});
const [studentHWGrades, setStudentHWGrades] = useState({});
  
const [classData, setClassData] = useState(((classes && classes.data) ? 
classes.data.students : studentsArr));
  //console.log(classes,'get classes');
  const getPreviousDate = (dateString) => {
    const date = new Date(dateString);  // Convert string to Date object
    date.setDate(date.getDate() - 1);   // Subtract one day
    return date.toISOString().slice(0, 10); // Format it back to "YYYY-MM-DD"
  };
//console.log(quiz,'get quiz data');
  const handleToggleRandomOrder = () => {
    setRandomOrder(!randomOrder);
  };

  const handleToggleWordMeaning = () => {
    setWordMeaningToggle(!wordMeaningToggle);
  };

  const handleReadingVocab = () => {
    setReadingVocabToggle(!readingVocabToggle);
  };
  const handleReadingQuiz = () => {
    setReadingQuizToggle(!readingQuizToggle);
  };
  const handleStudyVocab = () => {
    setStudyVocabToggle(!studyVocabToggle);
  };
  const showMonthlyStatLink = (studentId, date) => {

    const dateString = new Date(date).toISOString(); // Convert date to string
    
    const base64String = btoa(dateString); // Encode string to Base64

    return 'https://likestudy.webtracktechnologies.com.au/HomeWorkMail/MonthlyStats/'+studentId+'/'+base64String;
  };
  const convertDateToBase64 = (date) => {

    const dateString = new Date(date).toISOString(); // Convert date to string
    
    const base64String = btoa(dateString); // Encode string to Base64
    //console.log(base64String,'sdfdf');
    return base64String;
  };
  useEffect(() => {
    var class_id = classid;
    var date = selectedDate;
    var teacher_id = authUser.user.teacher_id;
    var random = randomOrder;
    var arrow = '';
    var classId = classid;
    dispatch(classesActions.getClassById({ classId, date, arrow }))
    .then((response) => {
      //console.log(response, 'get classes for end'); // Log here after data is fetched
      // if(response.payload?.data){
      //   setClasses(response.payload);
      // }
    })
    .catch((error) => {
      console.error('Error fetching class:', error);
    });
    dispatch(quizActions.getPrintQuiz({ date,class_id, teacher_id, random}));
  }, []);

//console.log(classes,'get studen');

 const handleCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowDialog(false);
};
const handleAssignCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowAssignDialog(false);
};
const handleMonthlyCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowMonthlyDialog(false);
};
const assignHomework = () => {
  // Show the dialog when delete action is clicked
  setShowDialog(true);
};

const showCommentsModal = (sFirstName, sKoreanName) => {

  setCommentsStudentName(sFirstName+' '+sKoreanName);
  // Show the dialog when delete action is clicked
  setShowCommentsDialog(true);
};

const handleCommentsCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowCommentsDialog(false);
};

const monthlyStat = () => {
  // Show the dialog when delete action is clicked
  setShowMonthlyDialog(true);

};
const handlePrintQuizCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowPrintQuizDialog(false);
};
const printQuiz = () => {
  // Show the dialog when delete action is clicked
  setShowPrintQuizDialog(true);

};

const assignHomeworkType = (type) => {
  setType(type);
  setShowAssignDialog(true);
};

const speakingHomework = (type,buttonType) => {
  setType(type);
  if(buttonType == 'Speaking HW'){
    window.location.href = '/classroom/SpeakingHWList/'+classid+'/'+type;
  }else if(buttonType == 'Writing HW'){
    window.location.href = '/classroom/WritingHWList/'+classid+'/'+type;
  }else if(buttonType == 'Vocab HW'){
    window.location.href = '/classroom/MyOwnVocabList/'+classid+'/'+type;
  }
  
};

const getText = (type,e) => {
  const text = e.target.value;
  setTextValue(e.target.value);
  switch(type) {
    case 'Reading':
      setReadingHomeworkText(text);
      break;
    case 'Listening':
      setListeningHomeworkText(text);
      break;
    case 'Speaking':
      setSpeakingHomeworkText(text);
      break;
    case 'Writing':
      setWritingHomeworkText(text);
      break;
    case 'Grammar':
      setGrammarHomeworkText(text);
      break;
    case 'Vocabulary':
      setVocabularyHomeworkText(text);
      break;
    case 'Other':
      setOtherHomeworkText(text);
      break;
    default:
      break;
  }
};

const submitHomeworkEmail = async (recordId, type) => {
  try {
    if(!recordId){
      var teacher_id = authUser.user.teacher_id;
      var class_id = classid;
      var homework_email_text = getTextValue;
      var type = type;
      var date = selectedDate;
      setShowDialog(false);
        // Await the dispatch and wait for the API call to complete
        const result = await dispatch(homeworkActions.assignHomework({ 
          teacher_id, 
          class_id, 
          homework_email_text, 
          type,
          date,
      }));
//console.log(result,'get result');

if(result.payload?.data){
      if(result.payload.data.type == 'Speaking'){
        setSpeakHWId(result.payload.data.id);
      }  
      
      if(result.payload.data.type == 'Writing'){
        setWriteHWId(result.payload.data.id);
      }  

      if(result.payload.data.type == 'Listening'){
        setListenHWId(result.payload.data.id);
      }
      
      if(result.payload.data.type == 'Reading'){
        setReadHWId(result.payload.data.id);
      } 

      if(result.payload.data.type == 'Grammar'){
        setGrammarHWId(result.payload.data.id);
      } 

      if(result.payload.data.type == 'Vocabulary'){
        setVocabHWId(result.payload.data.id);
      } 
}
      // Assuming the result contains a `success` property, you can check for success
      if (result.payload?.message) {

        
        toast.success(result.payload.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      } else {
          alert('Failed to assign homework.');  // Handle any failure case
      }
    }else{
      var homework_email_text = getTextValue;
      var homework_id = recordId;
     // addObjectToArray(recordId,homework_text);
      const formData = new FormData();
      formData.append("homework_email_text", homework_email_text);
      formData.append("type", type);
      
      const response = await axios.put(
        "https://api.webtracktechnologies.com.au:8002/homework/edit/"+homework_id,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json', // Make sure to set the content type based on your form data
          },
          }
      );

      if(response){
        setShowDialog(false);
        toast.success('Homework updated successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
      } catch (error) {
      console.error('Error assigning homework:', error);
      alert('An error occurred while assigning homework.');
      }

};

const homeworkLink = () => {
  // Show the dialog when delete action is clicked
  setShowLink(true);
  const currentDate = selectedDate; // Get the current date
  const encodedString = convertDateToBase64(currentDate);
  setLink('https://likestudy.webtracktechnologies.com.au/HomeWorkMail/'+classid+'/'+encodedString);
};
const handleDateChange = async (e) => {
  setSelectedDate(e.target.value);
  setMonthAttr(true);
  var classId = classid;
  var date = e.target.value;
 var result = await dispatch(classesActions.getClassById({ classId, date}))
  .then((response) => {
    console.log(response,'get res');
    if(response && response.payload && response.payload.data && response.payload.data.students.length > 0){
      setClassData(response.payload.data.students);
    }
    if(response && response.payload && response.payload.data && response.payload.data.homeworks.length > 0){
      const libraryHomework = response.payload.data.homeworks.filter(homework => homework.type === 'Library');
     // console.log(classes.data.homeworks,'libraryHomework',libraryHomework);
      // const links = generateLinks(libraryHomework);
      // setLibraryHomeworkText(links);
      if (libraryHomework) {
        const links = generateLinks(libraryHomework);
        setLibraryHomeworkText(links);
       // setLibraryHomeworkText(`https://likestudy.webtracktechnologies.com.au/HomeWorkVocabularyType/${libraryHomework.id}/${libraryHomework.program_id}`);
      }else{
        setLibraryHomeworkText('');
      }
    
      const readingHomework = response.payload.data.homeworks.find(homework => homework.type === 'Reading');
      //console.log(readingHomework,'get read');
      if (readingHomework) {
        setReadHWId(readingHomework.id);
        setReadingHomeworkText((readingHomework.homework_email_text) ? readingHomework.homework_email_text: readingHomework.homework_text);
      }else{
        setReadHWId('')
        setReadingHomeworkText('');
      }
  
      const listeningHomework = response.payload.data.homeworks.find(homework => homework.type === 'Listening');
      if (listeningHomework) {
        setListenHWId(listeningHomework.id);
        setListeningHomeworkText((listeningHomework.homework_email_text) ? listeningHomework.homework_email_text: listeningHomework.homework_text);
      }else{
        setListenHWId('')
        setListeningHomeworkText('');
      }
  
      const writingHomework = response.payload.data.homeworks.find(homework => homework.type === 'Writing');
      if (writingHomework) {
        setWriteHWId(writingHomework.id);
        setWritingHomeworkText((writingHomework.homework_email_text) ? writingHomework.homework_email_text: writingHomework.homework_text);
      }else{
        setWriteHWId('')
        setWritingHomeworkText('');
      }
  
      const speakingHomework = response.payload.data.homeworks.find(homework => homework.type === 'Speaking');
      if (speakingHomework) {
        setSpeakHWId(speakingHomework.id);
        setSpeakingHomeworkText((speakingHomework.homework_email_text) ? speakingHomework.homework_email_text:speakingHomework.homework_text);
      }else{
        setSpeakHWId('')
        setSpeakingHomeworkText('');
      }
  
      const grammarHomework = response.payload.data.homeworks.find(homework => homework.type === 'Grammar');
      if (grammarHomework) {
        setGrammarHWId(grammarHomework.id);
        setGrammarHomeworkText((grammarHomework.homework_email_text) ? grammarHomework.homework_email_text: grammarHomework.homework_text);
      }else{
        setGrammarHWId('')
        setGrammarHomeworkText('');
      }
  
      const vocabularyHomework = response.payload.data.homeworks.find(homework => homework.type === 'Vocabulary');
      if (vocabularyHomework) {
        setVocabHWId(vocabularyHomework.id);
        setVocabularyHomeworkText((vocabularyHomework.homework_email_text) ? vocabularyHomework.homework_email_text: vocabularyHomework.homework_text);
      }else{
        setVocabHWId('')
        setVocabularyHomeworkText('');
      }
    }else{
      setLibraryHomeworkText('');
      setReadingHomeworkText('');
      setListeningHomeworkText('');
      setWritingHomeworkText('');
      setSpeakingHomeworkText('');
      setGrammarHomeworkText('');
      setVocabularyHomeworkText('');
      setReadHWId('');
      setListenHWId('');
      setWriteHWId('');
      setSpeakHWId('');
      setGrammarHWId('');
      setVocabHWId('');
    }
  })
  .catch((error) => {
    console.error('Error fetching class:', error);
  });
  var class_id = classid;
  var teacher_id = authUser.user.teacher_id;
      var random = randomOrder;
  dispatch(quizActions.getPrintQuiz({ date,class_id, teacher_id, random}));
 //return dispatch(classesActions.getClassById({ classId, date}));
};


const copyToClipboard = () => {
  const linkElement = document.getElementById('qlink');
  const range = document.createRange();
  range.selectNode(linkElement);
  window.getSelection().addRange(range);

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    alert('Copied!');
  } catch (err) {
    console.error('Unable to copy: ', err);
  }

  window.getSelection().removeAllRanges();
};
const copyToClipboardReport = (studentId) => {
  const linkElement = document.getElementById('reportLink-'+studentId);
  const range = document.createRange();
  range.selectNode(linkElement);
  window.getSelection().addRange(range);

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    alert('Copied!');
  } catch (err) {
    console.error('Unable to copy: ', err);
  }

  window.getSelection().removeAllRanges();
};
const saveGradeAttendence = async () => {
  var teacher_id = authUser.user.teacher_id;
 var data = studentGrades;
 return dispatch(gradeattendenceActions.createGradeAttendence( {data, reading_avg:readGrade,
  listening_avg:listGrade,
  speaking_avg:spekGrade,writing_avg:writGrade,grammer_avg:gramGrade,vocab_avg:vocabGrade,
  teacher_id:teacher_id, hdnClassIds: classid,hdnAttendeDate: selectedDate,
  WhichColumn: 'All'}  ));
};


const handlePrint = (question) => {
  const newWindow = window.open('', '_blank');
  newWindow.document.write('<html><head><title>Print</title>');

  // Add CSS for page breaks
  newWindow.document.write(`
    <style>
      @media print {
        .page-break {
          page-break-after: always;
        }
      }
      table {
        border-collapse: collapse;
        border: 1px solid black;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid black;
        padding: 10px;
      }
    </style>
  `);
  
  newWindow.document.write('</head><body>');

  if (quiz && quiz.data && quiz.data.Students_Quiz) {
    quiz.data.Students_Quiz.forEach(student => {
      let hasContent = false;

      // Handle Book Quiz
      if (student.quiz.book_quiz && readingVocabToggle) {
        let i = 1;
        student.quiz.book_quiz.forEach(bookQuiz => {
          newWindow.document.write(`<h1>${bookQuiz.title} ${quiz.data.Class} ${student.koreanName} ${student.name}</h1>`);
          newWindow.document.write('<table style="border-collapse: collapse; width: 100%;">');
          newWindow.document.write('<tr><th style="border: 1px solid black; padding: 15px; width: 10%;">#</th>' +
                                   '<th style="border: 1px solid black; padding: 15px; width: 45%;">Word</th>' +
                                   '<th style="border: 1px solid black; padding: 15px; width: 45%;">Meaning</th></tr>');

          // Apply random order to bookQuiz by shuffling on each print click
          const quizData = randomOrder ? shuffleArray([...bookQuiz.quiz]) : bookQuiz.quiz;
          quizData.forEach(item => {
            newWindow.document.write('<tr>');
            newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; text-align: center;">${i++}</td>`);
            
            if (question === 'Questions') {
              if (!wordMeaningToggle) {
                newWindow.document.write(`<td style="border: 1px solid black; padding: 10px;">${item.words}</td><td style="border: 1px solid black; padding: 10px;"></td>`);
              } else {
                newWindow.document.write(`<td style="border: 1px solid black; padding: 10px;"></td><td style="border: 1px solid black; padding: 10px;">${item.meaning}</td>`);
              }
            } else if (question === 'Questions and Answers') {
              newWindow.document.write(`<td style="border: 1px solid black; padding: 10px;">${item.words}</td><td style="border: 1px solid black; padding: 10px;">${item.meaning}</td>`);
            }
            newWindow.document.write('</tr>');
          });
          newWindow.document.write('</table>');
          newWindow.document.write('<div class="page-break"></div>');
          hasContent = true;
        });
      }

      // Handle Speaking Quiz
      // if (student.quiz.Speaking_quiz && readingQuizToggle) {
      //   let i = 1;
      //   student.quiz.Speaking_quiz.forEach(speaking => {
      //     newWindow.document.write(`<h1>${speaking.title} ${quiz.data.Class} ${student.koreanName} ${student.name}</h1>`);

      //     // Apply random order to speaking quiz by shuffling on each print click
      //     const quizData1 = randomOrder ? shuffleArray([...speaking.quiz]) : speaking.quiz;
      //     quizData1.forEach(item => {
      //       newWindow.document.write(`<p>Q${i++}: ${item.sentence_question}</p>`);
      //       newWindow.document.write('<br><br>');
      //     });
      //     newWindow.document.write('<div class="page-break"></div>');
      //     hasContent = true;
      //   });
      // }

      // Handle Reading Quiz
      if (student.quiz.Reading && readingQuizToggle) {
        let i = 1;
        student.quiz.Reading.forEach(reading => {
          newWindow.document.write(`<h1>${reading.title} ${quiz.data.Class} ${student.koreanName} ${student.name}</h1>`);

          // Apply random order to reading quiz by shuffling on each print click
          const quizData2 = randomOrder ? shuffleArray([...reading.quiz]) : reading.quiz;
          quizData2.forEach(item => {
            newWindow.document.write(`<p>Q${i++}: ${item.sentence_question}</p>`);
            if (question !== 'Questions') {
              newWindow.document.write(`<p>Ans: ${item.sentence_answer}</p>`);
            }
            newWindow.document.write('<br><br>');
          });
          newWindow.document.write('<div class="page-break"></div>');
          hasContent = true;
        });
      }

      // Handle Vocabulary Quiz
      if (student.quiz.vocabulary && studyVocabToggle) {
        let i = 1;
        student.quiz.vocabulary.forEach(vocab => {
          newWindow.document.write(`<h1>${vocab.title} ${quiz.data.Class} ${student.koreanName} ${student.name}</h1>`);
          newWindow.document.write('<table style="border-collapse: collapse; width: 100%;">');
          newWindow.document.write('<tr><th style="border: 1px solid black; padding: 15px; width: 10%;">#</th>' +
                                   '<th style="border: 1px solid black; padding: 15px; width: 45%;">Word</th>' +
                                   '<th style="border: 1px solid black; padding: 15px; width: 45%;">Meaning</th></tr>');

          // Apply random order to vocabulary quiz by shuffling on each print click
          const quizData3 = randomOrder ? shuffleArray([...vocab.quiz]) : vocab.quiz;
          quizData3.forEach(item => {
            newWindow.document.write('<tr>');
            newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; text-align: center;">${i++}</td>`);
            
            if (question === 'Questions') {
              if (!wordMeaningToggle) {
                newWindow.document.write(`<td style="border: 1px solid black; padding: 10px;">${item.word}</td><td style="border: 1px solid black; padding: 10px;"></td>`);
              } else {
                newWindow.document.write(`<td style="border: 1px solid black; padding: 10px;"></td><td style="border: 1px solid black; padding: 10px;">${item.meaning}</td>`);
              }
            } else if (question === 'Questions and Answers') {
              newWindow.document.write(`<td style="border: 1px solid black; padding: 10px;">${item.word}</td><td style="border: 1px solid black; padding: 10px;">${item.meaning}</td>`);
            }
            newWindow.document.write('</tr>');
          });
          newWindow.document.write('</table>');
          newWindow.document.write('<div class="page-break"></div>');
          hasContent = true;
        });
      }

      if (!hasContent) {
        newWindow.document.write(`<p>No content available for ${student.name}.</p>`);
        newWindow.document.write('<div class="page-break"></div>');
      }
    });
  } else {
    newWindow.document.write(`<p>No data available for printing.</p>`);
  }

  newWindow.document.write('</body></html>');
  newWindow.document.close();
  newWindow.print();
};

// Helper function to shuffle array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};


// const handleInputChange = (e,type,studentId) => {
//   const { name, value } = e.target;
//   let updatedValue;
//  if(!type){
//   switch (value) {
//     case '':
//       updatedValue = 'A+';
//       break;
//     case 'A+':
//       updatedValue = 'A';
//       break;
//     case 'A':
//       updatedValue = 'B+';
//       break;
//     case 'B+':
//       updatedValue = 'B';
//       break;
//     case 'B':
//         updatedValue = 'C+';
//         break;
//         case 'C+':
//           updatedValue = '';
//           break;
//     default:
//       updatedValue = value;
//   }
// }else{
//   if(type == 'attendance'){
//     switch (value) {
//       case '':
//         updatedValue = 'P';
//         break;
//       case 'P':
//         updatedValue = 'A';
//         break;
//       case 'A':
//         updatedValue = 'L';
//         break;
//         case 'L':
//         updatedValue = '';
//         break;
//       default:
//         updatedValue = value;
//     }
//   }else{
//     switch (value) {
//       case '':
//         updatedValue = 'O';
//         break;
//       case 'O':
//         updatedValue = 'X';

//         break;
//       case 'X':
//         updatedValue = '';
//         break;
//       default:
//         updatedValue = value;
//     }
//   }
 
// }

// if(classes && classes.data.students){
//   const newArray = {};
//   classes.data.students.map((classd, index) => {

//     newArray[classd.id] = {
//       'txtread':(classd.attendances.length ? classd.attendances[0].txtread : ''),
//       'txtlist':(classd.attendances.length ? classd.attendances[0].txtlist : ''),
//       'txtspek':(classd.attendances.length ? classd.attendances[0].txtspek : ''),
//       'txtwrit':(classd.attendances.length ? classd.attendances[0].txtwrit : ''),
//       'txtgramer':(classd.attendances.length ? classd.attendances[0].txtgramer : ''),
//       'txtvocab':(classd.attendances.length ? classd.attendances[0].txtvocab : ''),
//       'txtHWRead':(classd.attendances.length ? classd.attendances[0].txtHWRead : ''),
//       'txtHWList':(classd.attendances.length ? classd.attendances[0].txtHWList : ''),
//       'txtHWSpk':(classd.attendances.length ? classd.attendances[0].txtHWSpk : ''),
//       'txtHWWrt':(classd.attendances.length ? classd.attendances[0].txtHWWrt : ''),
//       'txtHWGram':(classd.attendances.length ? classd.attendances[0].txtHWGram : ''),
//       'txtHWVocab':(classd.attendances.length ? classd.attendances[0].txtHWVocab : ''),
//       'txtQZRead':(classd.attendances.length ? classd.attendances[0].txtQZRead : ''),
//       'txtQZList':(classd.attendances.length ? classd.attendances[0].txtQZList : ''),
//       'txtQZSpk':(classd.attendances.length ? classd.attendances[0].txtQZSpk : ''),
//       'txtQZWrt':(classd.attendances.length ? classd.attendances[0].txtQZWrt : ''),
//       'txtQZGram':(classd.attendances.length ? classd.attendances[0].txtQZGram : ''),
//       'txtQZVocab':(classd.attendances.length ? classd.attendances[0].txtQZVocab : ''),
//       'attendance':(classd.attendances.length ? classd.attendances[0].attendance : ''),
//     };


//   });
// // Convert the object to an array
// const finalArray = Object.values(newArray);
//   // Now 'newArray' contains all the objects created inside the map function
//   const updatedStudentData = {
//     ...(inputValues[studentId] || {}),
//     ...newArray[studentId],
//     [name]: updatedValue
//   };

//   setInputValues(inputValues => ({
//     ...inputValues,
//     [studentId]: updatedStudentData,
//     hdnClassIds: classid,
//     hdnAttendeDate: selectedDate,
//     WhichColumn: 'All',
//   }));
// } else {
//   const updatedStudentData = {
//     ...(inputValues[studentId] || {}),
//     [name]: updatedValue
//   };

//   setInputValues(inputValues => ({
//     ...inputValues,
//     [studentId]: updatedStudentData,
//     hdnClassIds: classid,
//     hdnAttendeDate: selectedDate,
//     WhichColumn: 'All',
//   }));
// }
// // setInputValues(inputValues => ({
// //   ...inputValues,
// //   [studentId]: {
// //     ...(inputValues[studentId] || {}), // Retrieve existing student data, or create an empty object
// //     [name]: updatedValue
// //   },
// //   hdnClassIds: classid,
// //   hdnAttendeDate: selectedDate,
// //   WhichColumn: 'All',
  
// // }));
// };

// useEffect(() => {
//   if(inputValues){
    
//     for (const key in inputValues) {
//       if (typeof inputValues[key] === 'object') {
//         for (const subKey in inputValues[key]) {
//           if (subKey.includes('txtread')) {
//         const grade = inputValues[key][subKey];
//         if (!readHighestGrade || gradeValues[grade] > gradeValues[readHighestGrade]) {
//           readHighestGrade = grade;
//           setReadGrade(readHighestGrade);
//         }
//       }
    
  
//       if (subKey.startsWith('txtlist')) {
//         const grade = inputValues[key][subKey];
//         if (!listenHighestGrade || gradeValues[grade] > gradeValues[listenHighestGrade]) {
//           listenHighestGrade = grade;
//           setListGrade(listenHighestGrade);
//         }
//       }
//       if (subKey.startsWith('txtspek')) {
//         const grade = inputValues[key][subKey];
//         if (!speakHighestGrade || gradeValues[grade] > gradeValues[speakHighestGrade]) {
//           speakHighestGrade = grade;
//           setSpekGrade(speakHighestGrade);
//         }
//       }
//       if (subKey.startsWith('txtwrit')) {
//         const grade = inputValues[key][subKey];
//         if (!writeHighestGrade || gradeValues[grade] > gradeValues[writeHighestGrade]) {
//           writeHighestGrade = grade;
//           setWritGrade(writeHighestGrade);
//         }
//       }
//       if (subKey.startsWith('txtgramer')) {
//         const grade = inputValues[key][subKey];
//         if (!gramHighestGrade || gradeValues[grade] > gradeValues[gramHighestGrade]) {
//           gramHighestGrade = grade;
//           setGramGrade(gramHighestGrade);
//         }
//       }
//       if (subKey.startsWith('txtvocab')) {
//         const grade = inputValues[key][subKey];
//         if (!vocabHighestGrade || gradeValues[grade] > gradeValues[vocabHighestGrade]) {
//           vocabHighestGrade = grade;
//           setVocabGrade(vocabHighestGrade);
//         }
//       }
//     }
//   }
//   }

//   }
// }, [inputValues]);
// const handlePrint = (question) => {
//   const newWindow = window.open('', '_blank');
//   newWindow.document.write('<html><head><title>Print</title>');
//     newWindow.document.write(`
//       <style>
//         @media print {
//           .page-break {
//             page-break-after: always;
//           }
//         }
//         table {
//           border-collapse: collapse;
//           border: 1px solid black;
//           margin-bottom: 20px; /* Add some spacing between tables */
//         }
//         th, td {
//           border: 1px solid black;
//           padding: 10px;
//         }
//       </style>
//     `);
//     newWindow.document.write('</head><body>');
 
// console.log(readingVocabToggle,readingQuizToggle,studyVocabToggle);

//   newWindow.document.write('</tr>');

 
 

//   if(quiz && quiz.data && quiz.data.Students_Quiz){
    
//     quiz.data.Students_Quiz.forEach(student => {
//       if(student.quiz.vocabulary && studyVocabToggle){
//         var i = 1;
//         student.quiz.vocabulary.forEach(vocab => {
//     newWindow.document.write(`<h1>${vocab.title} ${quiz.data.Class} ${student.name}</h1>`);
//     newWindow.document.write('<table style="border-collapse: collapse; border: 1px solid black;">');
//     newWindow.document.write('<tr><th style="border: 1px solid black; padding: 10px;">#</th>');
//     newWindow.document.write('<th style="border: 1px solid black; padding: 10px;">Word</th><th style="border: 1px solid black; padding: 10px;">Meaning</th></tr>');
//     if(randomOrder){
//       var quizData = shuffleArray([...vocab.quiz]);
//      }else{
//        var quizData = vocab.quiz;
//      }
//      quizData.forEach(item => {
//       newWindow.document.write(`<tr><td style="border: 1px solid black; padding: 10px; width: 20%;">${i++}</td>`);
      
//       if(question == 'Questions'){
//       if (!wordMeaningToggle) {
//         newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; width: 40%;">${item.word}</td><td style="border: 1px solid black; padding: 10px; width: 40%;"></td>`);
//       } else {
//         newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; width: 40%;"></td><td style="border: 1px solid black; padding: 10px; width: 40%;">${item.meaning}</td>`);
//       }
//     }
//     if(question == 'Questions and Answers'){
     
//         newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; width: 40%;">${item.word}</td><td style="border: 1px solid black; padding: 10px; width: 40%;">${item.meaning}</td>`);
      
//     }
//       newWindow.document.write('</tr>');
//     });
//   });

//   }else{
//     newWindow.document.write(`<tr><td style="border: 1px solid black; padding: 10px; width: 20%;"></td>`);
//     newWindow.document.write(`<tr><td style="border: 1px solid black; padding: 10px; width: 20%;"></td>`);
//     newWindow.document.write(`<tr><td style="border: 1px solid black; padding: 10px; width: 20%;"></td>`);
//   }

//   newWindow.document.write('</table>');
//   newWindow.document.write('<div class="page-break"></div>'); 

//   if(student.quiz.Speaking_quiz && readingQuizToggle){
//     var i = 1;
//     student.quiz.Speaking_quiz.forEach(speaking => {
//     newWindow.document.write(`<h1>${speaking.title} ${quiz.data.Class} ${student.name}</h1>`);
//     newWindow.document.write('<table style="border-collapse: collapse; border: 1px solid black;">');
    
//     if(randomOrder){
//       var quizData = shuffleArray([...speaking.quiz]);
//      }else{
//        var quizData = speaking.quiz;
//      }
//      quizData.forEach(item => {
//       newWindow.document.write(`<tr>`);
      
//       if(question == 'Questions'){
//      // if (!wordMeaningToggle) {
//         newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; width: 40%;">Q${i++} ${item.sentence_question}</td>`);
//       // } else {
//       //   newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; width: 40%;"></td><td style="border: 1px solid black; padding: 10px; width: 40%;">${item.meaning}</td>`);
//       // }
//     }
//     if(question == 'Questions and Answers'){
     
//         newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; width: 40%;">Q${i++} ${item.sentence_question}</td>`);
      
//     }
//       newWindow.document.write('</tr>');
//     });
//   });
//   }

//   newWindow.document.write('</table>');
//   newWindow.document.write('<div class="page-break"></div>'); 
//   if(student.quiz.book_quiz && readingVocabToggle){
//     var i = 1;
//     student.quiz.book_quiz.forEach(bookQuiz => {
//     newWindow.document.write(`<h1>${bookQuiz.title} ${quiz.data.Class} ${student.name}</h1>`);
//     newWindow.document.write('<table style="border-collapse: collapse; border: 1px solid black;">');
//     newWindow.document.write('<tr><th style="border: 1px solid black; padding: 10px;">#</th>');
//     newWindow.document.write('<th style="border: 1px solid black; padding: 10px;">Word</th><th style="border: 1px solid black; padding: 10px;">Meaning</th></tr>');
//     if(randomOrder){
//       var quizData = shuffleArray([...bookQuiz.quiz]);
//      }else{
//        var quizData = bookQuiz.quiz;
//      }
//      quizData.forEach(item => {
//       newWindow.document.write(`<tr><td style="border: 1px solid black; padding: 10px; width: 20%;">${i++}</td>`);
      
//       if(question == 'Questions'){
//       if (!wordMeaningToggle) {
//         newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; width: 40%;">${item.words}</td><td style="border: 1px solid black; padding: 10px; width: 40%;"></td>`);
//       } else {
//         newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; width: 40%;"></td><td style="border: 1px solid black; padding: 10px; width: 40%;">${item.meaning}</td>`);
//       }
//     }
//     if(question == 'Questions and Answers'){
     
//         newWindow.document.write(`<td style="border: 1px solid black; padding: 10px; width: 40%;">${item.words}</td><td style="border: 1px solid black; padding: 10px; width: 40%;">${item.meaning}</td>`);
      
//     }
//       newWindow.document.write('</tr>');
//     });
//   });
//   }


// });
// }




//   newWindow.document.write('</table>');
//   newWindow.document.write('<div class="page-break"></div>'); 
//   newWindow.document.write('</body></html>');
//   newWindow.document.close();
//   newWindow.print();
// };



const checkForX = (attend) => {
  const variables = [
    attend.txtHWRead,
    attend.txtHWList,
    attend.txtHWSpk,
    attend.txtHWWrt,
    attend.txtHWGram,
    attend.txtHWVocab,
    attend.txtQZRead,
    attend.txtQZList,
    attend.txtQZSpk,
    attend.txtQZWrt,
    attend.txtQZGram,
    attend.txtQZVocab,
  ];
 // console.log(variables.includes(null),'get variables123');
  if(!variables.includes(null)){
    if (variables.includes('X')) {
      return 'X';
    }
    return 'O';
  }else{
    return '';
  }
  
};
const checkForXHW = (attend) => {
  const variables = [
    attend.txtHWRead,
    attend.txtHWList,
    attend.txtHWSpk,
    attend.txtHWWrt,
    attend.txtHWGram,
    attend.txtHWVocab,
  ];
  
  if(!variables.includes(null)){
    if (variables.includes('X') || variables.includes('X-red')) {
      return 'X';
    }
    return 'O';
  }else{
    return '';
  }
  
};
const checkForXQZ = (attend) => {
  const variables = [
    attend.txtQZRead,
    attend.txtQZList,
    attend.txtQZSpk,
    attend.txtQZWrt,
    attend.txtQZGram,
    attend.txtQZVocab,
  ];
  if(!variables.includes(null)){
  if (variables.includes('X') || variables.includes('X-red')) {
    return 'X';
  }
  return 'O';
}else{
  return '';
}
};

const handleGradeChange = (studentId, subject,grade) => {
  console.log(subject,'get type');
  
  if(subject == 'txtread'){
    setReadGrade(grade);
  }
  if(subject =='txtlist'){
    setListGrade(grade);
  }
  if(subject =='txtspek'){
    setSpekGrade(grade);
  }
  if(subject =='txtwrit'){
    setWritGrade(grade);
  }
  if(subject =='txtgramer'){
    setGramGrade(grade);
  }
  if(subject =='txtvocab'){
    setVocabGrade(grade);
  }
  
  // if(subject == 'txtHWRead'){
  //   setStudentHWGrades(prevGrades => ({
  //     ...prevGrades,
  //     [studentId]: grade,
  //   }));
  //   setSelectedStudentForHW(studentId);
  //   setTxtHWRead(grade);
  // }
  if (subject === 'txtHWRead') {
    setStudentHWGrades((prevGrades) => ({
      ...prevGrades,
      [studentId]: grade,
    }));

    // Update totalHW only for this student
    setStudentTotalHW((prevTotalHW) => ({
      ...prevTotalHW,
      [studentId]: grade,
    }));
  }

  if(subject == 'txtQZRead'){
    setTxtQZRead(grade);
  }
 setStudentGrades
  (prev => prev.map(student => {
    if (student.id === studentId) {
      return {
        ...student,
        attendances: {
          ...student.attendances ,
          [subject]: (grade? grade : ''),
          hdnClassIds: classid,
          hdnAttendeDate: selectedDate,
          WhichColumn: 'All',
        },
      };
    }
    return student;
  }));
};

const openHwAssign = (content) => {
  
  setShowContentDialog(true);
  var type = '';
  var value = '';
  if(content  == 'RC'){
    type = 'Reading';
    value = 'R';
  }else if(content == 'LC'){
    type = 'Listening';
    value = 'L';
  }else if(content == 'SP'){
    type = 'Speaking';
    value = 'S';
  }else if(content == 'WR'){
    type = 'Writing';
    value = 'W';
  }else if(content == 'GR'){
    type = 'Grammar';
    value = 'G';
  }else if(content == 'VC'){
    type = 'Vocabulary';
    value = 'V';
  }
  setContentType(type);
  setModalContent(value);
};
const handleContentCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowContentDialog(false);
};

const [libraryHomeworkText, setLibraryHomeworkText] = useState([]);
const [readingHomeworkText, setReadingHomeworkText] = useState('');
const [listeningHomeworkText, setListeningHomeworkText] = useState('');
const [writingHomeworkText, setWritingHomeworkText] = useState('');
const [speakingHomeworkText, setSpeakingHomeworkText] = useState('');
const [grammarHomeworkText, setGrammarHomeworkText] = useState('');
const [vocabularyHomeworkText, setVocabularyHomeworkText] = useState('');
const [otherHomeworkText, setOtherHomeworkText] = useState('');
const [hasStudyHomework, setHasStudyHomework] = useState(false);
const [hasLibraryHomework, setHasLibraryHomework] = useState(false);
const [readHWId, setReadHWId] = useState('');
const [writeHWId, setWriteHWId] = useState('');
const [speakHWId, setSpeakHWId] = useState('');
const [listenHWId, setListenHWId] = useState('');
const [vocabHWId, setVocabHWId] = useState('');
const [grammarHWId, setGrammarHWId] = useState('');
const [readingCommentText, setReadingCommentText] = useState('');
const [listeningCommentText, setListeningCommentText] = useState('');
const [writingCommentText, setWritingCommentText] = useState('');
const [speakingCommentText, setSpeakingCommentText] = useState('');
const [grammarCommentText, setGrammarCommentText] = useState('');
const [vocabularyCommentText, setVocabularyCommentText] = useState('');
const [otherCommentText, setOtherCommentText] = useState('');
const [classDates, setClassDates] = useState();
const [homeworkClassDates, setHomeworkClassDates] = useState();

const generateLinks = (homeworkArray) => {
  return homeworkArray.map((dayHome) => {
    return `https://likestudy.webtracktechnologies.com.au/HomeWorkVocabularyType/${dayHome.id}/${dayHome.program_id}`;
  });
};
useEffect(() => {
  // Assuming your response is stored in classData
  if(classes && classes.data && classes.data.classdates.length > 0){
      setClassDates(classes.data.classdates);
  }
  if(classes && classes.data && classes.data.classhomeworkdates.length > 0){
    setHomeworkClassDates(classes.data.classhomeworkdates);
}
  if(classes && classes.data && classes.data.homeworks.length > 0){
    const libraryHomework = classes.data.homeworks.filter(homework => homework.type === 'Library');
   // console.log(classes.data.homeworks,'libraryHomework',libraryHomework);
    const links = generateLinks(libraryHomework);
    setLibraryHomeworkText(links);
    if (libraryHomework) {
     // setLibraryHomeworkText(`https://likestudy.webtracktechnologies.com.au/HomeWorkVocabularyType/${libraryHomework.id}/${libraryHomework.program_id}`);
    }
  
    const readingHomework = classes.data.homeworks.find(homework => homework.type === 'Reading');
    console.log(readingHomework,'get read');
    if (readingHomework) {
      setReadHWId(readingHomework.id);
      setReadingHomeworkText((readingHomework.homework_email_text) ? readingHomework.homework_email_text: readingHomework.homework_text);
    }

    const listeningHomework = classes.data.homeworks.find(homework => homework.type === 'Listening');
    if (listeningHomework) {
      setListenHWId(listeningHomework.id);
      setListeningHomeworkText((listeningHomework.homework_email_text) ? listeningHomework.homework_email_text: listeningHomework.homework_text);
    }

    const writingHomework = classes.data.homeworks.find(homework => homework.type === 'Writing');
    if (writingHomework) {
      setWriteHWId(writingHomework.id);
      setWritingHomeworkText((writingHomework.homework_email_text) ? writingHomework.homework_email_text: writingHomework.homework_text);
    }

    const speakingHomework = classes.data.homeworks.find(homework => homework.type === 'Speaking');
    if (speakingHomework) {
      setSpeakHWId(speakingHomework.id);
      setSpeakingHomeworkText((speakingHomework.homework_email_text) ? speakingHomework.homework_email_text:speakingHomework.homework_text);
    }

    const grammarHomework = classes.data.homeworks.find(homework => homework.type === 'Grammar');
    if (grammarHomework) {
      setGrammarHWId(grammarHomework.id);
      setGrammarHomeworkText((grammarHomework.homework_email_text) ? grammarHomework.homework_email_text: grammarHomework.homework_text);
    }

    const vocabularyHomework = classes.data.homeworks.find(homework => homework.type === 'Vocabulary');
    if (vocabularyHomework) {
      setVocabHWId(vocabularyHomework.id);
      setVocabularyHomeworkText((vocabularyHomework.homework_email_text) ? vocabularyHomework.homework_email_text: vocabularyHomework.homework_text);
    }
  }else{
  
    setReadingHomeworkText('');
    setListeningHomeworkText('');
    setWritingHomeworkText('');
    setSpeakingHomeworkText('');
    setGrammarHomeworkText('');
    setVocabularyHomeworkText('');
  }
  if(classes && classes.data && classes.data.students){
    setStudentGrades(classes.data.students);
    setClassData(classes.data.students);
  }
  const updateColors = () => {
    let colors = {};
    if(classes && classes.data && classes.data.students){
      classes.data.students.forEach(student => {
      ['RC', 'LC', 'WR', 'SP', 'VC', 'GR'].forEach(type => {
        if (student[type]) {
          const scoreType = type + '-' + student.id;
          colors[scoreType] = typeColors[type];
        }
      });
    });
    setClickedTd(colors);
  }
  };
  const updateRewards = () => {
    let colors = {};
    if (classes && classes.data && classes.data.students) {
      let rewardsArray = [];
      classes.data.students.forEach(student => {
        if (student.rewards) {
          student.rewards.forEach(reward => {
            const scoreType = reward.sub_type + '-' + reward.student_id;
            colors[scoreType] = typeColors[reward.sub_type];
            //rewardsArray.push({ student_id: student.id, sub_type: reward.sub_type });
          });
        }
      });
     // console.log(colors,'get rewards color');
      setClickedTd1(colors);
    }
  };
  updateColors();
 updateRewards();

}, [classes]);
//console.log(classes,'get links');
// const handleArrowClick = async (direction) => {
//   try {
//     // Increment the selectedDate by 1 day
//     const newDate = new Date(selectedDate);
   
//     // If direction is "right" or forward, increase date by 1
//     if (direction === 'right') {
//       newDate.setDate(newDate.getDate() + 1); // Increment date by 1 day
//     }
    
//     // If direction is "left" or backward, decrease date by 1
//     if (direction === 'left') {
//       newDate.setDate(newDate.getDate() - 1); // Decrease date by 1 day
//     }
    
//     const updatedDate = newDate.toISOString().slice(0, 10);
//     setSelectedDate(updatedDate); // Update the state with the new date
//     console.log(updatedDate,'get new date');
//     var classId = classid;
//     var date = updatedDate;  // Use the updated date
//     var arrow = direction;
   
//     // Dispatch action and wait for the result
//     var result = await dispatch(classesActions.getClassById({ classId, date, arrow }));
//     console.log(result, 'get result');
//     if(result && result.payload && result.payload.data && result.payload.data.homeworks.length > 0){
//       const libraryHomework = result.payload.data.homeworks.filter(homework => homework.type === 'Library');
//      // console.log(classes.data.homeworks,'libraryHomework',libraryHomework);
//       // const links = generateLinks(libraryHomework);
//       // setLibraryHomeworkText(links);
//       if (libraryHomework) {
//         const links = generateLinks(libraryHomework);
//         setLibraryHomeworkText(links);
//        // setLibraryHomeworkText(`https://likestudy.webtracktechnologies.com.au/HomeWorkVocabularyType/${libraryHomework.id}/${libraryHomework.program_id}`);
//       }else{
//         setLibraryHomeworkText('');
//       }
    
//       const readingHomework = result.payload.data.homeworks.find(homework => homework.type === 'Reading');
//       console.log(readingHomework,'get read');
//       if (readingHomework) {
//         setReadHWId(readingHomework.id);
//         setReadingHomeworkText((readingHomework.homework_email_text) ? readingHomework.homework_email_text: readingHomework.homework_text);
//       }else{
//         setReadHWId('')
//         setReadingHomeworkText('');
//       }
  
//       const listeningHomework = result.payload.data.homeworks.find(homework => homework.type === 'Listening');
//       if (listeningHomework) {
//         setListenHWId(listeningHomework.id);
//         setListeningHomeworkText((listeningHomework.homework_email_text) ? listeningHomework.homework_email_text: listeningHomework.homework_text);
//       }else{
//         setListenHWId('')
//         setListeningHomeworkText('');
//       }
  
//       const writingHomework = result.payload.data.homeworks.find(homework => homework.type === 'Writing');
//       if (writingHomework) {
//         setWriteHWId(writingHomework.id);
//         setWritingHomeworkText((writingHomework.homework_email_text) ? writingHomework.homework_email_text: writingHomework.homework_text);
//       }else{
//         setWriteHWId('')
//         setWritingHomeworkText('');
//       }
  
//       const speakingHomework = result.payload.data.homeworks.find(homework => homework.type === 'Speaking');
//       if (speakingHomework) {
//         setSpeakHWId(speakingHomework.id);
//         setSpeakingHomeworkText((speakingHomework.homework_email_text) ? speakingHomework.homework_email_text:speakingHomework.homework_text);
//       }else{
//         setSpeakHWId('')
//         setSpeakingHomeworkText('');
//       }
  
//       const grammarHomework = result.payload.data.homeworks.find(homework => homework.type === 'Grammar');
//       if (grammarHomework) {
//         setGrammarHWId(grammarHomework.id);
//         setGrammarHomeworkText((grammarHomework.homework_email_text) ? grammarHomework.homework_email_text: grammarHomework.homework_text);
//       }else{
//         setGrammarHWId('')
//         setGrammarHomeworkText('');
//       }
  
//       const vocabularyHomework = result.payload.data.homeworks.find(homework => homework.type === 'Vocabulary');
//       if (vocabularyHomework) {
//         setVocabHWId(vocabularyHomework.id);
//         setVocabularyHomeworkText((vocabularyHomework.homework_email_text) ? vocabularyHomework.homework_email_text: vocabularyHomework.homework_text);
//       }else{
//         setVocabHWId('')
//         setVocabularyHomeworkText('');
//       }
//     }else{
//       setLibraryHomeworkText('');
//       setReadingHomeworkText('');
//       setListeningHomeworkText('');
//       setWritingHomeworkText('');
//       setSpeakingHomeworkText('');
//       setGrammarHomeworkText('');
//       setVocabularyHomeworkText('');
//       setReadHWId('');
//       setListenHWId('');
//       setWriteHWId('');
//       setSpeakHWId('');
//       setGrammarHWId('');
//       setVocabHWId('');
//     }
//     // Check if result payload has the "No records found" message
//     if (result?.payload?.message === 'No records found') {
//       const previousDate = getPreviousDate(updatedDate);
//       const today = new Date().toISOString().slice(0, 10);
//       console.log(date,'get date',today);
//       // if (updatedDate > today || updatedDate === today) {
//       // //setSelectedDate(); 
//       // const arrow = 'left';
//       // var result1 = await dispatch(classesActions.getClassById({ classId, date: previousDate, arrow }));
      
//       toast.success(result.payload.message, {
//         position: "top-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
      
//       return false;
//    // }
//     }
//   } catch (error) {
//     toast.error("An error occurred while fetching class data.", {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//     return false;
//   }

//   var class_id = classid;
//   var teacher_id = authUser.user.teacher_id;
//   var random = randomOrder;
  
//   dispatch(quizActions.getPrintQuiz({ date: selectedDate, class_id, teacher_id, random }));
// };
const handleArrowClick = async (direction) => {
  try {
    let newDate;
//alert(direction);
    // Handle Left Arrow (Moving to earlier dates)
    if (direction === 'left') {
      const currentIndex = classDates.indexOf(selectedDate);

      // Check if there's a date earlier than the currently selected date
      if (currentIndex < classDates.length - 1) {
        newDate = classDates[currentIndex + 1]; // Move to the next (earlier) date in the array
      } else {
        // If no earlier date is available, display "No more records"
        toast.info("No more records to display", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return; // Stop further execution, don't hit API
      }
    }

    // Handle Right Arrow (Moving to later dates)
    if (direction === 'right') {
      const currentIndex = classDates.indexOf(selectedDate);

      // Check if there's a date greater than the currently selected date
      if (currentIndex > 0) {
        newDate = classDates[currentIndex - 1]; // Move to the previous (later) date in the array
      } else {
        // If no greater date is available, display "No more records"
        toast.info("No more records to display", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return; // Stop further execution, don't hit API
      }
    }

    // Update the state with the new date
    setSelectedDate(newDate);
    console.log(newDate, 'Selected new date');

    // Now use the newDate for dispatching the class data request
    var classId = classid;
    var date = newDate;  // Use the updated date
    var arrow = direction;

    // Dispatch action and wait for the result
    var result = await dispatch(classesActions.getClassById({ classId, date, arrow }));
    console.log(result, 'get result');

    // Process the result payload
    if (result && result.payload && result.payload.data && result.payload.data.homeworks.length > 0) {
      const homeworks = result.payload.data.homeworks;

      // Filter and set various types of homework
      const libraryHomework = homeworks.filter(homework => homework.type === 'Library');
      const readingHomework = homeworks.find(homework => homework.type === 'Reading');
      const listeningHomework = homeworks.find(homework => homework.type === 'Listening');
      const writingHomework = homeworks.find(homework => homework.type === 'Writing');
      const speakingHomework = homeworks.find(homework => homework.type === 'Speaking');
      const grammarHomework = homeworks.find(homework => homework.type === 'Grammar');
      const vocabularyHomework = homeworks.find(homework => homework.type === 'Vocabulary');

      setLibraryHomeworkText(libraryHomework ? generateLinks(libraryHomework) : '');
      setReadHWId(readingHomework ? readingHomework.id : '');
      setReadingHomeworkText(readingHomework?.homework_email_text || readingHomework?.homework_text || '');
      setListenHWId(listeningHomework ? listeningHomework.id : '');
      setListeningHomeworkText(listeningHomework?.homework_email_text || listeningHomework?.homework_text || '');
      setWriteHWId(writingHomework ? writingHomework.id : '');
      setWritingHomeworkText(writingHomework?.homework_email_text || writingHomework?.homework_text || '');
      setSpeakHWId(speakingHomework ? speakingHomework.id : '');
      setSpeakingHomeworkText(speakingHomework?.homework_email_text || speakingHomework?.homework_text || '');
      setGrammarHWId(grammarHomework ? grammarHomework.id : '');
      setGrammarHomeworkText(grammarHomework?.homework_email_text || grammarHomework?.homework_text || '');
      setVocabHWId(vocabularyHomework ? vocabularyHomework.id : '');
      setVocabularyHomeworkText(vocabularyHomework?.homework_email_text || vocabularyHomework?.homework_text || '');
    } else {
      // Reset all fields if no homeworks are found
      setLibraryHomeworkText('');
      setReadingHomeworkText('');
      setListeningHomeworkText('');
      setWritingHomeworkText('');
      setSpeakingHomeworkText('');
      setGrammarHomeworkText('');
      setVocabularyHomeworkText('');
      setReadHWId('');
      setListenHWId('');
      setWriteHWId('');
      setSpeakHWId('');
      setGrammarHWId('');
      setVocabHWId('');
    }

    // Handle "No records found" case
    if (result?.payload?.message === 'No records found') {
      toast.success(result.payload.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
  } catch (error) {
    toast.error("An error occurred while fetching class data.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return false;
  }

  // Dispatch for quiz data
  var class_id = classid;
  var teacher_id = authUser.user.teacher_id;
  var random = randomOrder;

  dispatch(quizActions.getPrintQuiz({ date: date, class_id, teacher_id, random }));
};


const handleArrowClickFromModal = async (direction, customDate = null) => {
  try {
    let newDate;

    // Determine the base date: use customDate if provided, otherwise use selectedDate
    const baseDate = customDate || selectedDate;
    //const currentIndex = classDates.indexOf(baseDate);
// Find the index of baseDate in classDates, or get the closest previous date if not found
let currentIndex = homeworkClassDates.indexOf(baseDate);
 console.log(currentIndex,'get current');   

    // Handle Left Arrow (Moving to earlier dates)
    if (direction === 'left') {
     
      // Check if there's a date earlier than the custom date or selected date
      if (currentIndex < homeworkClassDates.length - 1) {
        newDate = homeworkClassDates[currentIndex + 1]; // Move to the next (earlier) date in the array
      } else {
        // If no earlier date is available, display "No more records"
        toast.info("No more records to display", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return; // Stop further execution, don't hit API
      }
    }

    // Handle Right Arrow (Moving to later dates)
    if (direction === 'right') {
      // Check if there's a date later than the custom date or selected date
      if (currentIndex > 0) {
        newDate = homeworkClassDates[currentIndex - 1]; // Move to the previous (later) date in the array
      } else {
        // If no later date is available, display "No more records"
        toast.info("No more records to display", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return; // Stop further execution, don't hit API
      }
    }

    // Update the state with the new date
    setSelectedDate(newDate);
    console.log(newDate, 'Selected new date');

    // Now use the newDate for dispatching the class data request
    var classId = classid;
    var date = newDate;  // Use the updated date
    var arrow = direction;

    // Dispatch action and wait for the result
    var result = await dispatch(classesActions.getClassById({ classId, date, arrow }));
    console.log(result, 'get result');

    // Process the result payload
    if (result && result.payload && result.payload.data && result.payload.data.homeworks.length > 0) {
      const homeworks = result.payload.data.homeworks;

      // Filter and set various types of homework
      const libraryHomework = homeworks.filter(homework => homework.type === 'Library');
      const readingHomework = homeworks.find(homework => homework.type === 'Reading');
      const listeningHomework = homeworks.find(homework => homework.type === 'Listening');
      const writingHomework = homeworks.find(homework => homework.type === 'Writing');
      const speakingHomework = homeworks.find(homework => homework.type === 'Speaking');
      const grammarHomework = homeworks.find(homework => homework.type === 'Grammar');
      const vocabularyHomework = homeworks.find(homework => homework.type === 'Vocabulary');

      setLibraryHomeworkText(libraryHomework ? generateLinks(libraryHomework) : '');
      setReadHWId(readingHomework ? readingHomework.id : '');
      setReadingHomeworkText(readingHomework?.homework_email_text || readingHomework?.homework_text || '');
      setListenHWId(listeningHomework ? listeningHomework.id : '');
      setListeningHomeworkText(listeningHomework?.homework_email_text || listeningHomework?.homework_text || '');
      setWriteHWId(writingHomework ? writingHomework.id : '');
      setWritingHomeworkText(writingHomework?.homework_email_text || writingHomework?.homework_text || '');
      setSpeakHWId(speakingHomework ? speakingHomework.id : '');
      setSpeakingHomeworkText(speakingHomework?.homework_email_text || speakingHomework?.homework_text || '');
      setGrammarHWId(grammarHomework ? grammarHomework.id : '');
      setGrammarHomeworkText(grammarHomework?.homework_email_text || grammarHomework?.homework_text || '');
      setVocabHWId(vocabularyHomework ? vocabularyHomework.id : '');
      setVocabularyHomeworkText(vocabularyHomework?.homework_email_text || vocabularyHomework?.homework_text || '');
    } else {
      // Reset all fields if no homeworks are found
      setLibraryHomeworkText('');
      setReadingHomeworkText('');
      setListeningHomeworkText('');
      setWritingHomeworkText('');
      setSpeakingHomeworkText('');
      setGrammarHomeworkText('');
      setVocabularyHomeworkText('');
      setReadHWId('');
      setListenHWId('');
      setWriteHWId('');
      setSpeakHWId('');
      setGrammarHWId('');
      setVocabHWId('');
    }

    // Handle "No records found" case
    if (result?.payload?.message === 'No records found') {
      toast.success(result.payload.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
  } catch (error) {
    toast.error("An error occurred while fetching class data.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return false;
  }

  // Dispatch for quiz data
  var class_id = classid;
  var teacher_id = authUser.user.teacher_id;
  var random = randomOrder;

  dispatch(quizActions.getPrintQuiz({ date: selectedDate, class_id, teacher_id, random }));
};



const predefinedColors = [
  '#ff7f27', '#22b14c', '#00a2e8', '#ed1c24', '#ffc90e', '#ffaec9'
];
const typeColors = {};
let colorIndex = 0;

// Assign a unique color to each type
['RC', 'LC', 'WR', 'SP', 'VC', 'GR'].forEach(type => {
  typeColors[type] = predefinedColors[colorIndex % predefinedColors.length];
  colorIndex++;
});
const usedColors = new Set();
const randomColor = () => {
  let color;
    do {
      color = predefinedColors[Math.floor(Math.random() * predefinedColors.length)];
    } while (usedColors.has(color) && usedColors.size < predefinedColors.length);
    usedColors.add(color);
    return color;
};

function hasStudyTypeForStudent(studentId, array, subtype) { 
 
  return array.some(item => item.student_id === studentId && item.type === 'Study');
    //return array.some(item => item.class_id === classId && item.type === 'Study' && item.subtype == subtype);

}
function hasStudyTypeForClass(classId, array, subtype) { 
  return array.some(item => item.class_id === classId && item.type === 'Study');
  
  //return array.some(item => item.student_id === studentId && item.type === 'Study' && item.subtype == subtype);

}
function hasLibraryTypeForClass(classId, array) {
  //console.log(studentId,array,'get here');
  return array.some(item => item.class_id === classId && item.type === 'Library');
}

function hasLibraryTypeForStudent(studentId, array) {
  //console.log(studentId,array,'get here');
  return array.some(item => item.student_id === studentId && item.type === 'Library');
}

function hasHWTypeForStudent(array,category,type) {
  //console.log(studentId,array,'get here');
  return array.some(item => item.category === category && item.type === type);
}

function completeLibraryHWForStudent(studentId, array) {
  //console.log(studentId,array,'get here');
  if(studentId){
    return array.some(item => item.student_id === studentId && item.type === 'Library');
  }else{
    return array.some(item.type === 'Library');
  }

}

function hasTypeHomework(type, array) {
  return array.some(item => item.type === type);
}
const rewardWithGiftBox = async (student,type1, studentId) =>{
  const types = ['RC', 'LC', 'GR', 'WR', 'SP', 'VC'];
//console.log(student[type1],'get student');
  //for (const student of students) {
   // for (const type of types) {
      if (student[type1]) {
  // Assign a unique color (for simplicity, using random color)
 if(classes && classes.data && classes.data.homeworks.length > 0){
  setClickedTd((prevColors) => ({
    ...prevColors,
    [type1+'-'+studentId]: typeColors[type1],
  }));
  //setClickedTd(type+'-'+studentId);
    var teacher_id = authUser.user.teacher_id;
    const formData = new FormData();
    formData.append("teacher_id", teacher_id);
    formData.append("student_id", studentId);
    formData.append("sub_type", type1);
    
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/games/savebox",
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json', // Make sure to set the content type based on your form data
        },
        }
    );
    if(response){
      toast.success('Congratulations for a giftbox.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    //console.log(response,'get response');
 }
}
    //}
  //}

}
const getTdStyle = (tdId) => {

  if(clickedTd[tdId]){
   // console.log(clickedTd[tdId],'get clickedTd');
    return { backgroundColor: clickedTd[tdId] || 'transparent' };
  }
  
}
const getTdStyle1 = (tdId) => {
  //console.log(clickedTd1[tdId],'get clickedTd1');
  if(clickedTd1[tdId]){
  return { backgroundColor: clickedTd1[tdId] || 'transparent' };
  }
}
const getCommentText = (type,e) => {
  const text = e.target.value;
 // setTextValue(e.target.value);
  switch(type) {
    case 'Reading':
      setReadingCommentText(text);
      break;
    case 'Listening':
      setListeningCommentText(text);
      break;
    case 'Speaking':
      setSpeakingCommentText(text);
      break;
    case 'Writing':
      setWritingCommentText(text);
      break;
    case 'Grammar':
      setGrammarCommentText(text);
      break;
    case 'Vocabulary':
      setVocabularyCommentText(text);
      break;
    case 'Other':
      setOtherCommentText(text);
      break;
    default:
      break;
  }
};
const submitComment = async (recordId, type) => {
  try {
    if(!recordId){
      var teacher_id = authUser.user.teacher_id;
      var class_id = classid;
      var homework_email_text = getTextValue;
      var type = type;
      //var date = assignHomework;
      setShowDialog(false);
        // Await the dispatch and wait for the API call to complete
        const result = await dispatch(homeworkActions.assignHomework({ 
          teacher_id, 
          class_id, 
          homework_email_text, 
          type 
      }));
//console.log(result,'get result');

if(result.payload?.data){
      if(result.payload.data.type == 'Speaking'){
        setSpeakHWId(result.payload.data.id);
      }  
      
      if(result.payload.data.type == 'Writing'){
        setWriteHWId(result.payload.data.id);
      }  

      if(result.payload.data.type == 'Listening'){
        setListenHWId(result.payload.data.id);
      }
      
      if(result.payload.data.type == 'Reading'){
        setReadHWId(result.payload.data.id);
      } 

      if(result.payload.data.type == 'Grammar'){
        setGrammarHWId(result.payload.data.id);
      } 

      if(result.payload.data.type == 'Vocabulary'){
        setVocabHWId(result.payload.data.id);
      } 
}
      // Assuming the result contains a `success` property, you can check for success
      if (result.payload?.message) {

        
        toast.success(result.payload.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      } else {
          alert('Failed to assign homework.');  // Handle any failure case
      }
    }else{
      var homework_email_text = getTextValue;
      var homework_id = recordId;
     // addObjectToArray(recordId,homework_text);
      const formData = new FormData();
      formData.append("homework_email_text", homework_email_text);
      formData.append("type", type);
      
      const response = await axios.put(
        "https://api.webtracktechnologies.com.au:8002/homework/edit/"+homework_id,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json', // Make sure to set the content type based on your form data
          },
          }
      );

      if(response){
        setShowDialog(false);
        toast.success('Homework updated successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
      } catch (error) {
      console.error('Error assigning homework:', error);
      alert('An error occurred while assigning homework.');
      }

};

 //console.log(classes);
// console.log('abc');
    return (

        <div className="grade-table-conatiner">
          <h2>{(classes && classes.data) ? classes.data.class_name: ''}&nbsp;&nbsp;
          <i className="fa fa-arrow-left" onClick={() => handleArrowClick('left')}></i>&nbsp;&nbsp;
          <i className="fa fa-arrow-right" onClick={() => handleArrowClick('right')}></i>
          <a href={`/classroom/test-achievement-new/${classid}`} className="btn green">TEST</a>
          </h2>
          
        <div className="table-head">
          <label htmlFor="birthday">Date:</label>
          <input value={selectedDate} onChange={handleDateChange} type="date" id="birthday" name="birthday" />
          <input type="button" className="btn green" onClick={() => assignHomework()} defaultValue="Assign Homework" />
          <input type="button" className="btn green" onClick={() => homeworkLink()} defaultValue="Homework Link" />
          <input type="button" className="btn green" onClick={() => monthlyStat()} defaultValue="Monthly Report" />
          <button className="btn green" onClick={() => printQuiz()} >Print Quiz</button>
        </div>
        {showLink ? <>
          <span id="qlink">{link}</span>
          <button type="button" className="btn btn-success" onClick={()=>copyToClipboard()}>Copy</button>
          </> : ''}
        <div className="grade-table">
          <table>
            <thead>
              <tr>
                <th>
                  Name <input type="button" onClick={()=> saveGradeAttendence()} className="btn green" defaultValue="S" />
                </th>
                <th>
                  <a href="javascript:void(0)" onClick={()=>openHwAssign('RC')}>RC</a> <input type="button" onClick={()=> saveGradeAttendence()} className="btn green" defaultValue="S" />
                </th>
                <th>
                <a href="javascript:void(0)" onClick={()=>openHwAssign('LC')}>LC</a> <input type="button" onClick={()=> saveGradeAttendence()} className="btn green" defaultValue="S" />
                </th>
                <th>
                <a href="javascript:void(0)" onClick={()=>openHwAssign('SP')}>SP</a> <input type="button" onClick={()=> saveGradeAttendence()} className="btn green" defaultValue="S" />
                </th>
                <th>
                <a href="javascript:void(0)" onClick={()=>openHwAssign('WR')}>WR</a> <input type="button" onClick={()=> saveGradeAttendence()} className="btn green" defaultValue="S" />
                </th>
                <th>
                <a href="javascript:void(0)" onClick={()=>openHwAssign('GR')}>GR</a> <input type="button" onClick={()=> saveGradeAttendence()} className="btn green" defaultValue="S" />
                </th>
                <th>
                <a href="javascript:void(0)" onClick={()=>openHwAssign('VC')}>VC</a> <input type="button" onClick={()=> saveGradeAttendence()} className="btn green" defaultValue="S" />
                </th>
              </tr>
              <tr>
                <th />
                <th style={{ color: "#52b700" }}>Avg: - {readGrade}</th>
                <th style={{ color: "#52b700" }}>Avg: - {listGrade}</th>
                <th style={{ color: "#52b700" }}>Avg: - {spekGrade}</th>
                <th style={{ color: "#52b700" }}>Avg: - {writGrade}</th>
                <th style={{ color: "#52b700" }}>Avg: - {gramGrade}</th>
                <th style={{ color: "#52b700" }}>Avg: - {vocabGrade}</th>
              </tr>
            </thead>

            <tbody>
              {/* {(classes && classes.data && classes.data.students) ? classes.data.students.map((classd, index) => (  */}
              {(classes && classes.data && classData) ? classData.map((classd, index) => {
                console.log(classd.completed_homework?.includes('Library'),'attendance length');
          return(
              <tr>
                <td>
               
                  {index+1}.{classd.englishName} {classd.koreanName} <br />
                  <div>
                    <span>ATT</span>{" "}
                    <GradeButton
              key='attendance'
              subject='attendance'
              grades={attendanceGrade}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'attendance', grade)}
              initialValue={
                classd.attendances?.length > 0
                  ? classd.attendances.map((attendance) => attendance.attendance).join(", ") // Map attendances and join them as a string
                  : ""
              }
            />
                  
                  </div>
                  <div>
                  {(classd.completed_homework && classd.completed_homework.includes("Library")) ? (
    <span style={{ backgroundColor: 'blue' }}>
      <a href={`/homework/${classid}/${classd.id}/${selectedDate}`} style={{ color: 'white' }}>HW</a>
    </span>
  ) : hasLibraryTypeForClass(classes.data.id, classes.data.homeworks) || hasLibraryTypeForStudent(classd.id,classd.homeworks) ? (
    <span style={{ backgroundColor: 'red' }}>
      <a href={`/homework/${classid}/${classd.id}/${selectedDate}`} style={{ color: 'white' }}>HW</a>
    </span>
  ) : (
    <span>
      <a href={`/homework/${classid}/${classd.id}/${selectedDate}`}>HW</a>
    </span>
  )}
                   
                   <input
            type="button"
            name="totalHW"
            value={
              studentTotalHW[classd.id] || // Use the persistent totalHW for each student
              inputValues[classd.id]?.[`txtHWRead`] ||
              (classd.attendances.length ? checkForXHW(classd.attendances[0]) : "")
            }
            className="btn green"
          />

                    {/* <input type="button" name="totalHW" value={inputValues[classd.id]?.[`txtHWRead`]
                     || (classd.attendances.length ? checkForXHW(classd.attendances[0]) : txtHWRead)} className="btn green" /> */}
                      {classd.averagescore}
                  </div>
                  <div>
                    <span>QZ</span>{" "}
                    <input type="button" name="totalQZ" value={inputValues[classd.id]?.[`txtQZRead`] || (classd.attendances.length ? checkForXQZ(classd.attendances[0]) : txtQZRead) } className="btn green" />
                  </div>
                  <div>
                    {hasStudyTypeForClass(classes.data.id, classes.data.homeworks) || hasStudyTypeForStudent(classd.id, classd.homeworks) ?  
                      <span style={{backgroundColor:'red'}}><a href={`/study/1/${classd.id}/${classid}/${selectedDate}`} style={{color:'white'}}>ST</a></span>
                      : (classd?.completed_homework?.includes("Study") || false) ?  <span style={{backgroundColor:'blue'}}><a href={`/study/1/${classd.id}/${classid}/${selectedDate}`} style={{color:'white'}}>ST</a></span> :
                     <span><a href={`/study/1/${classd.id}/${classid}/${selectedDate}`}>ST</a></span>
                    }
                   
                  </div>
                </td>
                <td onClick={()=> rewardWithGiftBox(classd,'RC',classd.id)}
                style={
                 getTdStyle('RC-'+classd.id) || getTdStyle1('RC-'+classd.id)
                }>
                  { (clickedTd1['RC-'+classd.id]) ? <img src='/images/gfimg.png' width={50} height={45} />: ''}
               
                 <div className='reading-div'>
                 <GradeButton
              key='txtread'
              subject='txtread'
              grades={grades}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtread', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtread : '')}
              studentId={classd.id}
              classId={classid}
            />
                  
                  </div>
                  <div>
                  {hasHWTypeForStudent(classes.data.homeworks,'Read Out Loud', 'Reading') ?  
                      <span style={{backgroundColor:'red'}}><a href="javascript:void(0)" style={{color:'white'}}>HW</a></span>:
                     <span>HW</span>
                    }
                   {" "}
                    
                    <GradeButton
              key='txtHWRead'
              subject='txtHWRead'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtHWRead', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtHWRead : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                   
                  </div>
                  <div>
                    <span>QZ</span>{" "}
                    <GradeButton
              key='txtQZRead'
              subject='txtQZRead'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtQZRead', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtQZRead : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                    
                  </div>
             
                </td>
                <td onClick={()=> rewardWithGiftBox(classd,'LC',classd.id)}
                style={
                  getTdStyle('LC-'+classd.id) || getTdStyle1('LC-'+classd.id)
                 }
                  >
                    { (clickedTd1['LC-'+classd.id]) ? <img src='/images/gfimg.png' width={50} height={45} />: ''}
                  <div className='reading-div'>
                  <GradeButton
              key='txtlist'
              subject='txtlist'
              grades={grades}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtlist', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtlist : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                   
                  </div>
                  <div>
                  {hasHWTypeForStudent(classes.data.homeworks, 'Listening') ?  
                      <span style={{backgroundColor:'red'}}><a href="javascript:void(0)" style={{color:'white'}}>HW</a></span>:
                     <span>HW</span>
                    }
                    <GradeButton
              key='txtHWList'
              subject='txtHWList'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtHWList', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtHWList : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                  
                  </div>
                  <div>
                    <span>QZ</span>{" "}
                    <GradeButton
              key='txtQZList'
              subject='txtQZList'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtQZList', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtQZList : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                    
                  </div>
              
                </td>
                <td onClick={()=> rewardWithGiftBox(classd,'SP',classd.id)}  style={getTdStyle('SP-'+classd.id)  || getTdStyle1('SP-'+classd.id)}>
                { (clickedTd1['SP-'+classd.id]) ? <img src='/images/gfimg.png' width={50} height={45} />: ''}
                  <div className='reading-div'>
                  <GradeButton
              key='txtspek'
              subject='txtspek'
              grades={grades}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtspek', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtspek : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                    
                  </div>
                  <div>
                  {hasHWTypeForStudent(classes.data.homeworks,'Free Response', 'Speaking') ?  
                      <span style={{backgroundColor:'red'}}><a href="javascript:void(0)" style={{color:'white'}}>HW</a></span>:
                     <span>HW</span>
                    }
                    <GradeButton
              key='txtHWSpk'
              subject='txtHWSpk'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtHWSpk', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtHWSpk : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                    
                  </div>
                  <div>
                    <span>QZ</span>{" "}
                    <GradeButton
              key='txtQZSpk'
              subject='txtQZSpk'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtQZSpk', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtQZSpk : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                    
                  </div>
         
                </td>
                <td onClick={()=> rewardWithGiftBox(classd,'WR',classd.id)} 
                 style={getTdStyle('WR-'+classd.id)  || getTdStyle1('WR-'+classd.id)}>
                { (clickedTd1['WR-'+classd.id]) ? <img src='/images/gfimg.png' width={50} height={45} />: ''}
                  <div className='reading-div'>
                  <GradeButton
              key='txtwrit'
              subject='txtwrit'
              grades={grades}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtwrit', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtwrit : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                    
                  </div>
                  <div>
                  {hasHWTypeForStudent(classes.data.homeworks,'Writing') ?  
                      <span style={{backgroundColor:'red'}}><a href="javascript:void(0)" style={{color:'white'}}>HW</a></span>:
                     <span>HW</span>
                    }
                    <GradeButton
              key='txtHWWrt'
              subject='txtHWWrt'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtHWWrt', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtHWWrt : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                   
                  </div>
                  <div>
                    <span>QZ</span>{" "}
                    <GradeButton
              key='txtQZWrt'
              subject='txtQZWrt'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtQZWrt', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtQZWrt : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                   
                  </div>
    
                </td>
                <td onClick={()=> rewardWithGiftBox(classd,'GR',classd.id)}  style={getTdStyle('GR-'+classd.id)  || getTdStyle1('GR-'+classd.id)}>
                { (clickedTd1['GR-'+classd.id]) ? <img src='/images/gfimg.png' width={50} height={45} />: ''}
                  <div className='reading-div'>
                  <GradeButton
              key='txtgramer'
              subject='txtgramer'
              grades={grades}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtgramer', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtgramer : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            /> 
                    
                  </div>
                  <div>
                  {hasHWTypeForStudent(classes.data.homeworks, 'Grammar') ?  
                      <span style={{backgroundColor:'red'}}><a href="javascript:void(0)" style={{color:'white'}}>HW</a></span>:
                     <span>HW</span>
                    }
                    <GradeButton
              key='txtHWGram'
              subject='txtHWGram'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtHWGram', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtHWGram : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                  </div>
                  <div>
                    <span>QZ</span>{" "}
                    <GradeButton
              key='txtQZGram'
              subject='txtQZGram'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtQZGram', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtQZGram : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                    
                  </div>
                
                </td>
                <td onClick={()=> rewardWithGiftBox(classd,'VC',classd.id)}  style={getTdStyle('VC-'+classd.id)  || getTdStyle1('VC-'+classd.id)}>
                { (clickedTd1['VC-'+classd.id]) ? <img src='/images/gfimg.png' width={50} height={45} />: ''}
                  <div className='reading-div'>
                  <GradeButton
              key='txtvocab'
              subject='txtvocab'
              grades={grades}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtvocab', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtvocab : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            /> 
                    
                  </div>
                  <div>
                  {hasHWTypeForStudent(classes.data.homeworks,('Reading' || 'Speaking' || 'Listening' || 'Writing' ||'Grammar' || 'Vocabulary'), 'My Own Vocabulary') ?  
                      <span style={{backgroundColor:'red'}}><a href="javascript:void(0)" style={{color:'white'}}>HW</a></span>:
                     <span>HW</span>
                    }
                    <GradeButton
              key='txtHWVocab'
              subject='txtHWVocab'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtHWVocab', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtHWVocab : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                   
                  </div>
                  <div>
                    <span>QZ</span>{" "}
                    <GradeButton
              key='txtQZVocab'
              subject='txtQZVocab'
              grades={gradesOX}
              onGradeChange={(grade) => handleGradeChange(classd.id, 'txtQZVocab', grade)}
              initialValue={(classd.attendances.length > 0 ? classd.attendances[0].txtQZVocab : '')}
              studentId={classd.id}
              classId={classid}
              selectedDate={selectedDate}
            />
                    
                  </div>
                  {(classd.attendances.length && checkForX(classd.attendances[0]) == 'X') 
                  ?   <div className="show-hw-qz" style={{'background':'red'}}>{(classd.attendances.length) ? checkForX(classd.attendances[0]) : ''}</div>
                : <div className="show-hw-qz" style={{'background':'green'}}>{(classd.attendances.length) ? checkForX(classd.attendances[0]) : ''}</div>
                }
          
                </td>
              </tr>
          );
        }) : ''}
            </tbody>
          </table>
          
          <div className="grade-bottom">
            <span>A+= 81-100</span> <span>A= 61-80</span> <span>B+= 41-60</span>{" "}
            <span>B= 21-40</span> <span>C+= 0-20</span> <span>O=Pass</span>{" "}
            <span>X=Fail</span>{" "}
          </div>
        </div>
        <Modal show={showAssignDialog} onHide={handleAssignCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Homework Assignment {getType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <button type="button" className="btn btn-success" onClick={() => speakingHomework(getType,'Vocab HW')}>My Vocab HW</button>&nbsp;
                <button type="button" className="btn btn-success"
                  onClick={() => speakingHomework(getType,'Speaking HW')}>Speaking HW</button>&nbsp;
                <button type="button" className="btn btn-success"
                 onClick={() => speakingHomework(getType,'Writing HW')}>Writing HW</button>&nbsp;
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleAssignCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
              </Modal>
              <Modal show={showPrintQuizDialog} onHide={handlePrintQuizCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Print Quiz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="toggle-group">
      <label>
        <input type="checkbox" name="toggle" value="random-order" checked={randomOrder}
          onChange={handleToggleRandomOrder} />
        <span className="slider round"></span>
        
      </label>
      Random
      <label>
        <input type="checkbox" name="toggle" value="wordmeaning" checked={wordMeaningToggle}
          onChange={handleToggleWordMeaning} />
        <span className="slider round"></span>
       
      </label>
      Word/Meaning   
    </div>
                <button type="button" className="btn btn-success" onClick={()=>handlePrint('Questions and Answers')}>Questions and Answers</button>&nbsp;
                <button type="button" className="btn btn-success" onClick={()=>handlePrint('Questions')}>Questions</button>&nbsp;
                
                </Modal.Body>
                <Modal.Footer className='text-right'>
                <div className="toggle-group">
                    <label>
                      <input type="checkbox" name="reading-vocab" value="reading-vocab" checked={readingVocabToggle}
                        onChange={handleReadingVocab} />
                      <span className="slider round"></span>
                      
                    </label>
                    Reading Vocab
                    <label>
                      <input type="checkbox" name="reading-quiz" value="reading-quiz" checked={readingQuizToggle}
                        onChange={handleReadingQuiz} />
                      <span className="slider round"></span>
                    
                    </label>
                    Reading Quiz
                    <label>
                      <input type="checkbox" name="study-vocab" value="study-vocab" checked={studyVocabToggle}
                        onChange={handleStudyVocab} />
                      <span className="slider round"></span>
                    
                    </label>
                    Study Vocab
                  </div>
                  <Button variant="secondary" onClick={handlePrintQuizCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
              </Modal>
        <Modal show={showDialog} onHide={handleCancel} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Homework Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='row'>
                    <div className='date'>
                    {new Date(selectedDate).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
                    </div>
                  <table striped bordered hover>
  <tr>
    <td onClick={() => assignHomeworkType('Reading')}>R</td>
    <td><textarea style={{ height: '100px' }} onChange={(e) =>getText('Reading',e)} value={readingHomeworkText}>{readingHomeworkText}</textarea></td>
    <td><button onClick={() => submitHomeworkEmail(readHWId,'Reading')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Listening')}>L</td>
    <td><textarea style={{ height: '100px' }} onChange={(e) =>getText('Listening',e)} value={listeningHomeworkText}>{listeningHomeworkText}</textarea></td>
    <td><button onClick={() => submitHomeworkEmail(listenHWId,'Listening')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Speaking')}>S</td>
    <td><textarea style={{ height: '100px' }} onChange={(e) =>getText('Speaking',e)} value={speakingHomeworkText }>{speakingHomeworkText }</textarea></td>
    <td><button onClick={() => submitHomeworkEmail(speakHWId,'Speaking')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Writing')}>W</td>
    <td><textarea style={{ height: '100px' }} onChange={(e) =>getText('Writing',e)} value={writingHomeworkText }>{writingHomeworkText }</textarea></td>
    <td><button onClick={() => submitHomeworkEmail(writeHWId, 'Writing')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Grammar')}>G</td>
    <td><textarea style={{ height: '100px' }} onChange={(e) =>getText('Grammar',e)} value={grammarHomeworkText }>{grammarHomeworkText }</textarea></td>
    <td><button onClick={() => submitHomeworkEmail(grammarHWId, 'Grammar')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Vocabulary')}>V</td>
    <td><textarea style={{ height: '100px' }} onChange={(e) =>getText('Vocabulary',e)} value={vocabularyHomeworkText}>{vocabularyHomeworkText}</textarea></td>
    <td><button onClick={() => submitHomeworkEmail(vocabHWId,'Vocabulary')}>S</button></td>
  </tr>
  <tr>
    <td>O</td>
    <td><textarea style={{ height: '100px' }} onChange={(e) =>getText('Other',e)} value={otherHomeworkText}>{otherHomeworkText}</textarea></td>
    <td><button onClick={() => submitHomeworkEmail('Other')}>S</button></td>
  </tr>
  <tr>
    <td><a href={`/homework/${classid}/${selectedDate}`}>LB</a></td>
    <td>{(libraryHomeworkText) ? libraryHomeworkText.map((link, teamIndex) =>(
      <>
      <a href={link}>{link}</a>
      <br/>
      </>
    )) : ''}
      </td>
    <td></td>
  </tr>
</table>
                  </div>
                </Modal.Body>
                <Modal.Footer className='text-right'>
                <i className="fa fa-arrow-left anjali" onClick={() => handleArrowClickFromModal('left',selectedDate)}></i>&nbsp;&nbsp;
                <i className="fa fa-arrow-right" onClick={() => handleArrowClickFromModal('right',selectedDate)}></i>
                  <Button variant="secondary" onClick={handleCancel}>
                    Close
                  </Button>
        
                </Modal.Footer>
              </Modal>

              <Modal show={showCommentsDialog} onHide={handleCommentsCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>{commentStudentName} - Monthly Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='row'>
                    
                  <table striped bordered hover>
  <tr>
    <td>R</td>
    <td><textarea onChange={(e) =>getCommentText('Reading',e)} value={readingCommentText}>{readingCommentText}</textarea></td>
    <td><button onClick={() => submitComment(readHWId,'Reading')}>S</button></td>
  </tr>
  <tr>
    <td>L</td>
    <td><textarea onChange={(e) =>getCommentText('Listening',e)} value={listeningCommentText}>{listeningCommentText}</textarea></td>
    <td><button onClick={() => submitComment(listenHWId,'Listening')}>S</button></td>
  </tr>
  <tr>
    <td>S</td>
    <td><textarea onChange={(e) =>getCommentText('Speaking',e)} value={speakingCommentText }>{speakingCommentText }</textarea></td>
    <td><button onClick={() => submitComment(speakHWId,'Speaking')}>S</button></td>
  </tr>
  <tr>
    <td>W</td>
    <td><textarea onChange={(e) =>getCommentText('Writing',e)} value={writingCommentText }>{writingCommentText }</textarea></td>
    <td><button onClick={() => submitComment(writeHWId, 'Writing')}>S</button></td>
  </tr>
  <tr>
    <td>G</td>
    <td><textarea onChange={(e) =>getCommentText('Grammar',e)} value={grammarCommentText }>{grammarCommentText }</textarea></td>
    <td><button onClick={() => submitComment(grammarHWId, 'Grammar')}>S</button></td>
  </tr>
  <tr>
    <td>V</td>
    <td><textarea onChange={(e) =>getCommentText('Vocabulary',e)} value={vocabularyCommentText}>{vocabularyCommentText}</textarea></td>
    <td><button onClick={() => submitComment(vocabHWId,'Vocabulary')}>S</button></td>
  </tr>
  <tr>
    <td>O</td>
    <td><textarea onChange={(e) =>getCommentText('Other',e)} value={otherCommentText}>{otherCommentText}</textarea></td>
    <td><button onClick={() => submitComment('Other')}>S</button></td>
  </tr>

</table>
                  </div>
                </Modal.Body>
                <Modal.Footer className='text-right'>
                  <Button variant="secondary" onClick={handleCommentsCancel}>
                    Close
                  </Button>
        
                </Modal.Footer>
              </Modal>

              <Modal show={showMonthlyDialog} onHide={handleMonthlyCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Monthly Report <button type="button" className="btn btn-success">Print All</button></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <table striped bordered hover>
                {(classes && classes.data && classes.data.students) ? classes.data.students.map((studentD, index) => ( 
                    <tr>
                      <td onClick={() => showCommentsModal(studentD.firstName,studentD.koreanName)}>{studentD.firstName} {studentD.koreanName}</td>
                      <td><button type="button" className="btn btn-success"  onClick={()=>copyToClipboardReport(studentD.id)}>Copy</button> </td>
                      <td><a href={showMonthlyStatLink(studentD.id,selectedDate)} target="_blank" id={`reportLink-${studentD.id}`}>{showMonthlyStatLink(studentD.id,selectedDate)}</a></td>
                    </tr>
                )) : ''}
                </table>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleMonthlyCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
              </Modal>
              <Modal show={showContentDialog} onHide={handleContentCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Homework Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='row'>
                    {/* <div className='date'>
                    {new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
                    </div> */}
                  <table striped bordered hover>
                    <tr>
                      <td onClick={() => assignHomeworkType(contentType)}>{modalContent}</td>
                      <td><textarea onChange={getText}></textarea></td>
                      <td><button onClick={() => submitHomeworkEmail(contentType)}>S</button></td>
                    </tr>
                  </table>
                  </div>
                </Modal.Body>
                <Modal.Footer className='text-right'>
                  <Button variant="secondary" onClick={handleContentCancel}>
                    Close
                  </Button>
                  {/* <Button variant="danger" onClick={() => handleConfirmDelete(recording.id)}>
                    Save
                  </Button> */}
                </Modal.Footer>
              </Modal>
      </div>
      
      
    )
}




const GradeButton = ({ subject, grades, onGradeChange, initialValue, studentId, classId, selectedDate }) => {
  const [currentGrade, setCurrentGrade] = useState(
    initialValue ? grades.indexOf(initialValue) : grades.length 
  );
  const [displayGrade, setDisplayGrade] = useState('');
  console.log(displayGrade,'get display grade');
  //grades.indexOf(initialValue)
  const [colorBg, setColorBg] = useState('');
  const [color, setColor] = useState('');
  const [txtQZReadValue, setTxtQZReadValue] = useState('');
  const [txtHWVocabValue, setTxtHWVocabValue] = useState('');
  const [txtQZVocabValue, setTxtQZVocabValue] = useState('');
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const [randomOrder, setRandomOrder] = useState(true);
  // if(grades[currentGrade]){alert('y');
  //   if(grades[currentGrade] === 'O-red' || grades[currentGrade] === 'X-red'){alert('y1');
  //     var displayGrade = (currentGrade === 0) ? '' : grades[currentGrade].replace('-red', '');
  //   }else{alert('y2');
  //     var displayGrade = (currentGrade === 0) ? '' : grades[currentGrade];
  //   }
    
  // }else{
  //   if(initialValue === 'O-red' || initialValue === 'X-red'){

  //   var displayGrade = initialValue.replace('-red', '');
  //   console.log(displayGrade,'han main hi hun');
  //   }else{
  //     var displayGrade = initialValue;
  //   }
  // }

  const handleClick = () => {
    var newGradeIndex = (currentGrade + 1) % (grades.length + 1); // Adding +1 for blank state
    console.log(newGradeIndex,'newGradeIndex',displayGrade);

    setCurrentGrade(newGradeIndex);

    // Update current grade to blank if at the end
    var newGrade = newGradeIndex === grades.length ? '' : grades[newGradeIndex];
    setDisplayGrade(newGrade === 'O-red' || newGrade === 'X-red' ? newGrade.replace('-red', '') : newGrade);
    console.log(newGrade,'newGrade',displayGrade);
    onGradeChange(newGrade);

    if (subject === 'txtQZRead') {
      setTxtQZReadValue(newGrade === 'X' ? 'X' : '');
    } else if (subject === 'txtHWVocab') {
      setTxtHWVocabValue(newGrade === 'X' ? 'X' : '');
    } else if (subject === 'txtQZVocab') {
      setTxtQZVocabValue(newGrade === 'X' ? 'X' : '');
    }

    // Check conditions for red background
    if ((currentGrade === 2 || currentGrade === 3) && (newGrade === 'O-red' || newGrade === 'X-red')) {
      //alert(displayGrade);
      
      setColorBg('red');
      setColor('white');
    } else {
      setColorBg('');
      setColor('');
    }
  };

  useEffect(() => {
    if (initialValue) {
      const sanitizedValue =
        initialValue === 'O-red' || initialValue === 'X-red'
          ? initialValue.replace('-red', '')
          : initialValue;
  
      setDisplayGrade(sanitizedValue); // Update displayGrade whenever initialValue changes
    }
    if (initialValue === 'O-red' || initialValue === 'X-red') {
      console.log(currentGrade,'grades[currentGrade]');
      setColorBg('red');
      setColor('white');
    } else {
      setColorBg('');
      setColor('');
    }
  }, [initialValue]);
    
      //console.log(displayGrade,'get display',initialValue);
  //const displayGrade = currentGrade < grades.length ? (grades[currentGrade] || '').replace('-red', '') : ''; // Display blank if no grade

  const valueArrays = {
    'txtQZRead': [],
    'txtHWVocab': [],
    'txtQZVocab': []
  };

  useEffect(() => {
    if (txtQZReadValue || txtHWVocabValue || txtQZVocabValue) {
      const txtQZReadValue = document.querySelector('input[name="txtQZRead"]').value;
      const txtHWVocabValue = document.querySelector('input[name="txtHWVocab"]').value;
      const txtQZVocabValue = document.querySelector('input[name="txtQZVocab"]').value;

      addToValueArrays('txtQZRead', txtQZReadValue, studentId);
      addToValueArrays('txtHWVocab', txtHWVocabValue, studentId);
      addToValueArrays('txtQZVocab', txtQZVocabValue, studentId);

      const date = selectedDate;
      const teacher_id = authUser.user.teacher_id;
      const random = randomOrder;
      const class_id = classId;
      const optional = valueArrays;
      dispatch(quizActions.getPrintQuiz({ date, class_id, teacher_id, random, optional }));
    }
  }, [txtQZReadValue, txtHWVocabValue, txtQZVocabValue]);

  const addToValueArrays = (subject, value, studentId) => {
    const studentIndex = valueArrays[subject].findIndex(entry => entry.studentId === studentId);

    if (studentIndex !== -1) {
      valueArrays[subject][studentIndex].values.push(value);
    } else {
      valueArrays[subject].push({ studentId, values: [value] });
    }
  };

  return (
    <input
      type="button"
      name={subject}
      style={{ background: colorBg, color: color }}
      value={displayGrade}
      className="btn green"
      onClick={handleClick}
      {...(subject === 'txtQZRead' ? { 'data-qz-read': txtQZReadValue } : {})}
      {...(subject === 'txtHWVocab' ? { 'data-hw-vocab': txtHWVocabValue } : {})}
      {...(subject === 'txtQZVocab' ? { 'data-qz-vocab': txtQZVocabValue } : {})}
    />
  );
};

