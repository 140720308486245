import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {

  studyActions

} from "_store";
import { useParams } from "react-router-dom";
export { TestResultReport };
function TestResultReport() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { passage } = useSelector((x) => x.passage);
  const [date, setDate] = useState('');
  const { studentId } = useParams();
  useEffect(() => {
    var student_id = studentId;
    const today = new Date();

    // Extract year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
  
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    var date = formattedDate;
    setDate(date);
    dispatch(studyActions.getStudyTestResults({student_id,date}));

}, []);

useEffect(() => {
  
  if(passage && passage.data){
    console.log(passage.data['leveltestscore']);
  }

}, [passage]);


  return (
    <section>
<div className="container">
  <div className="stu-detail">
    <h2>LEVEL TEST SCORE REPORT</h2>
    <span>Date: {date}</span>
    <table>
     
        {(passage && passage.data) ? 
         <tbody>
      <tr>
          <th>Korean Name</th>
          <td>{passage.data.student.koreanName}</td>
        </tr>
        <tr>
          <th>Student Name</th>
          <td>{passage.data.student.firstName}</td>
        </tr>
        <tr>
          <th>English Name</th>
          <td>{passage.data.student.englishName}</td>
        </tr>
        <tr>
          <th>School Year</th>
          <td>{passage.data.student.school_year}</td>
        </tr>
      </tbody>
     :'' }
    </table>
  </div>
  <div className="ach-graph">
    <h3>Achievement Graph</h3>
    {/* <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
  <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart> */}
  </div>

{(passage && passage.data && passage.data['leveltestscore']) ? 
<div className="summery-table">
  <h3>Level Test Score</h3>
  <table>
    <thead>
      <tr>
        <th>TEST SECTIONS</th>
        <th>Total # of Questions</th>
        <th>Correct Answers</th>
        <th>%</th>
        <th>Class Average</th>
        <th>Rank</th>
      </tr>
    </thead>
    <tbody>
      {passage.data['leveltestscore'].map((level, index) => (
        <React.Fragment key={index}>
          <tr>
            <th>Total</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>Reading</th>
            <td>{level['Reading'] ? level['Reading']['totalQuestions'] : ''}</td>
            <td>{level['Reading'] ? level['Reading']['correctAnswered'] : ''}</td>
            <td>{level['Reading'] ? level['Reading']['percentage'] : ''}</td>
            <td>{level['Reading'] ? level['Reading']['classaverage'] : ''}</td>
            <td>{level['Reading'] ? level['Reading']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Listening</th>
            <td>{level['Listening'] ? level['Listening']['totalQuestions'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['correctAnswered'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['percentage'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['classaverage'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Grammar</th>
            <td>{level['Grammar'] ? level['Grammar']['totalQuestions'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['correctAnswered'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['percentage'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['classaverage'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Speaking</th>
            <td>{level['Speaking'] ? level['Speaking']['totalQuestions'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['correctAnswered'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['percentage'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['classaverage'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Writing</th>
            <td>{level['Writing'] ? level['Writing']['totalQuestions'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['correctAnswered'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['percentage'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['classaverage'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Vocabulary</th>
            <td>{level['Vocabulary'] ? level['Vocabulary']['totalQuestions'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['correctAnswered'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['percentage'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['classaverage'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['rank'] : ''}</td>
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>
: ''
}
{(passage && passage.data && passage.data['sectionanlysis']) ? 
  <div className="summery-table">
    <h3>
    Section Analysis
    </h3>
    <table>
      <thead>
        <tr>
          <th>TEST SECTIONS</th>
          <th>Raw Scores</th>
          <th>Total</th>
          <th>My Average %</th>
          <th>Class Average</th>
          <th>Rank</th>
        </tr>
      </thead>
      <tbody>
      {passage.data['sectionanlysis'].map((level, index) => (
        <React.Fragment key={index}>
          <tr>
            <th>Reading</th>
            <td></td>
            <td></td>
            <td>{level['Reading'] ? level['Reading']['myaverage'] : ''}</td>
            <td>{level['Reading'] ? level['Reading']['classaverage'] : ''}</td>
            <td>{level['Reading'] ? level['Reading']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Read-out-loud</td>
            <td>{level['Reading']['Read-out-loud'] ? level['Reading']['Read-out-loud']['raw_score'] : ''}</td>
            <td>{level['Reading']['Read-out-loud'] ? level['Reading']['Read-out-loud']['total'] : ''}</td>
            <td>{level['Reading']['Read-out-loud'] ? level['Reading']['Read-out-loud']['myaverage'] : ''}</td>
            <td>{level['Reading']['Read-out-loud'] ? level['Reading']['Read-out-loud']['classaverage'] : ''}</td>
            <td>{level['Reading']['Read-out-loud'] ? level['Reading']['Read-out-loud']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Reading Comprehension</td>
            <td>{level['Reading']['Reading Comprehension'] ? level['Reading']['Reading Comprehension']['raw_score'] : ''}</td>
            <td>{level['Reading']['Reading Comprehension'] ? level['Reading']['Reading Comprehension']['total'] : ''}</td>
            <td>{level['Reading']['Reading Comprehension'] ? level['Reading']['Reading Comprehension']['myaverage'] : ''}</td>
            <td>{level['Reading']['Reading Comprehension'] ? level['Reading']['Reading Comprehension']['classaverage'] : ''}</td>
            <td>{level['Reading']['Reading Comprehension'] ? level['Reading']['Reading Comprehension']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Listening</th>
            <td></td>
            <td></td>
            <td>{level['Listening'] ? level['Listening']['myaverage'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['classaverage'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Listening Comprehension</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['raw_score'] : ''}</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['total'] : ''}</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['myaverage'] : ''}</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['classaverage'] : ''}</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Grammar</th>
            <td></td>
            <td></td>
            <td>{level['Grammar'] ? level['Grammar']['myaverage'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['classaverage'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Grammar Multiple Choice</td>
            <td>{level['Grammar']['Grammar Multiple Choice'] ? level['Grammar']['Grammar Multiple Choice']['raw_score'] : ''}</td>
            <td>{level['Grammar']['Grammar Multiple Choice'] ? level['Grammar']['Grammar Multiple Choice']['total'] : ''}</td>
            <td>{level['Grammar']['Grammar Multiple Choice'] ? level['Grammar']['Grammar Multiple Choice']['myaverage'] : ''}</td>
            <td>{level['Grammar']['Grammar Multiple Choice'] ? level['Grammar']['Grammar Multiple Choice']['classaverage'] : ''}</td>
            <td>{level['Grammar']['Grammar Multiple Choice'] ? level['Grammar']['Grammar Multiple Choice']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Speaking</th>
            <td></td>
            <td></td>
            <td>{level['Speaking'] ? level['Speaking']['myaverage'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['classaverage'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Accuracy</td>
            <td>{level['Speaking']['Accuracy'] ? level['Speaking']['Accuracy']['raw_score'] : ''}</td>
            <td>{level['Speaking']['Accuracy'] ? level['Speaking']['Accuracy']['total'] : ''}</td>
            <td>{level['Speaking']['Accuracy'] ? level['Speaking']['Accuracy']['myaverage'] : ''}</td>
            <td>{level['Speaking']['Accuracy'] ? level['Speaking']['Accuracy']['classaverage'] : ''}</td>
            <td>{level['Speaking']['Accuracy'] ? level['Speaking']['Accuracy']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Fluency</td>
            <td>{level['Speaking']['Fluency'] ? level['Speaking']['Fluency']['raw_score'] : ''}</td>
            <td>{level['Speaking']['Fluency'] ? level['Speaking']['Fluency']['total'] : ''}</td>
            <td>{level['Speaking']['Fluency'] ? level['Speaking']['Fluency']['myaverage'] : ''}</td>
            <td>{level['Speaking']['Fluency'] ? level['Speaking']['Fluency']['classaverage'] : ''}</td>
            <td>{level['Speaking']['Fluency'] ? level['Speaking']['Fluency']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Content</td>
            <td>{level['Speaking']['Content'] ? level['Speaking']['Content']['raw_score'] : ''}</td>
            <td>{level['Speaking']['Content'] ? level['Speaking']['Content']['total'] : ''}</td>
            <td>{level['Speaking']['Content'] ? level['Speaking']['Content']['myaverage'] : ''}</td>
            <td>{level['Speaking']['Content'] ? level['Speaking']['Content']['classaverage'] : ''}</td>
            <td>{level['Speaking']['Content'] ? level['Speaking']['Content']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Writing</th>
            <td></td>
            <td></td>
            <td>{level['Writing'] ? level['Writing']['myaverage'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['classaverage'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Content</td>
            <td>{level['Writing']['Content'] ? level['Writing']['Content']['raw_score'] : ''}</td>
            <td>{level['Writing']['Content'] ? level['Writing']['Content']['total'] : ''}</td>
            <td>{level['Writing']['Content'] ? level['Writing']['Content']['myaverage'] : ''}</td>
            <td>{level['Writing']['Content'] ? level['Writing']['Content']['classaverage'] : ''}</td>
            <td>{level['Writing']['Content'] ? level['Writing']['Content']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Grammar</td>
            <td>{level['Writing']['Grammar'] ? level['Writing']['Grammar']['raw_score'] : ''}</td>
            <td>{level['Writing']['Grammar'] ? level['Writing']['Grammar']['total'] : ''}</td>
            <td>{level['Writing']['Grammar'] ? level['Writing']['Grammar']['myaverage'] : ''}</td>
            <td>{level['Writing']['Grammar'] ? level['Writing']['Grammar']['classaverage'] : ''}</td>
            <td>{level['Writing']['Grammar'] ? level['Writing']['Grammar']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Spelling</td>
            <td>{level['Writing']['Spelling'] ? level['Writing']['Spelling']['raw_score'] : ''}</td>
            <td>{level['Writing']['Spelling'] ? level['Writing']['Spelling']['total'] : ''}</td>
            <td>{level['Writing']['Spelling'] ? level['Writing']['Spelling']['myaverage'] : ''}</td>
            <td>{level['Writing']['Spelling'] ? level['Writing']['Spelling']['classaverage'] : ''}</td>
            <td>{level['Writing']['Spelling'] ? level['Writing']['Spelling']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Punctuation</td>
            <td>{level['Writing']['Punctuation'] ? level['Writing']['Punctuation']['raw_score'] : ''}</td>
            <td>{level['Writing']['Punctuation'] ? level['Writing']['Punctuation']['total'] : ''}</td>
            <td>{level['Writing']['Punctuation'] ? level['Writing']['Punctuation']['myaverage'] : ''}</td>
            <td>{level['Writing']['Punctuation'] ? level['Writing']['Punctuation']['classaverage'] : ''}</td>
            <td>{level['Writing']['Punctuation'] ? level['Writing']['Punctuation']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Readability</td>
            <td>{level['Writing']['Readability'] ? level['Writing']['Readability']['raw_score'] : ''}</td>
            <td>{level['Writing']['Readability'] ? level['Writing']['Readability']['total'] : ''}</td>
            <td>{level['Writing']['Readability'] ? level['Writing']['Readability']['myaverage'] : ''}</td>
            <td>{level['Writing']['Readability'] ? level['Writing']['Readability']['classaverage'] : ''}</td>
            <td>{level['Writing']['Readability'] ? level['Writing']['Readability']['rank'] : ''}</td>
          </tr>
          <tr>
            <td>Style</td>
            <td>{level['Writing']['Style'] ? level['Writing']['Style']['raw_score'] : ''}</td>
            <td>{level['Writing']['Style'] ? level['Writing']['Style']['total'] : ''}</td>
            <td>{level['Writing']['Style'] ? level['Writing']['Style']['myaverage'] : ''}</td>
            <td>{level['Writing']['Style'] ? level['Writing']['Style']['classaverage'] : ''}</td>
            <td>{level['Writing']['Style'] ? level['Writing']['Style']['rank'] : ''}</td>
          </tr>
          <tr>
            <th>Vocabulary</th>
            <td></td>
            <td></td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['myaverage'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['classaverage'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['rank'] : ''}</td>
          </tr>
        </React.Fragment>
      ))}
      </tbody>
    </table>
  </div>
  : ''
}
{(passage && passage.data && passage.data['questiontypeanalysis']) ? 
  <div className="summery-table">
    <h3>
    Question Type Analysis
    </h3>
    <table>
      <thead>
        <tr>
          <th>TEST SECTIONS</th>
          <th>Total # of Questions</th>
          <th>Correct Answers</th>
          <th>%</th>
          <th>Class Average</th>
          <th>Rank</th>
        </tr>
      </thead>
      <tbody>
      {passage.data['questiontypeanalysis'].map((level, index) => (
         <React.Fragment key={index}>
         <tr>
            <th>Reading</th>
            <td>{level['Reading'] ? level['Reading']['Total no of Questions'] : ''}</td>
            <td>{level['Reading']  ? level['Reading']['Correct Answers'] : ''}</td>
            <td>{level['Reading']  ? level['Reading']['%'] : ''}</td>
            <td>{level['Reading']  ? level['Reading']['Class Average'] : ''}</td>
            <td>{level['Reading']  ? level['Reading']['Rank'] : ''}</td>
          </tr>
          <tr>
            <td>Read-out-loud</td>
            <td>{level['Reading']['Read out loud'] ? level['Reading']['Read out loud']['Total no of Questions'] : ''}</td>
            <td>{level['Reading']['Read out loud']  ? level['Reading']['Read out loud']['Correct Answers'] : ''}</td>
            <td>{level['Reading']['Read out loud']  ? level['Reading']['Read out loud']['%'] : ''}</td>
            <td>{level['Reading']['Read out loud']  ? level['Reading']['Read out loud']['Class Average'] : ''}</td>
            <td>{level['Reading']['Read out loud']  ? level['Reading']['Read out loud']['Rank'] : ''}</td>
          </tr>
          <tr>
            <td>Facts</td>
            <td>{level['Reading']['Facts'] ? level['Reading']['Facts']['Total no of Questions'] : ''}</td>
            <td>{level['Reading']['Facts']  ? level['Reading']['Facts']['Correct Answers'] : ''}</td>
            <td>{level['Reading']['Facts']  ? level['Reading']['Facts']['%'] : ''}</td>
            <td>{level['Reading']['Facts']  ? level['Reading']['Facts']['Class Average'] : ''}</td>
            <td>{level['Reading']['Facts']  ? level['Reading']['Facts']['Rank'] : ''}</td>
          </tr>
          <tr>
            <td>Negative facts</td>
            <td>{level['Reading']['Negative facts'] ? level['Reading']['Negative facts']['Total no of Questions'] : ''}</td>
            <td>{level['Reading']['Negative facts']  ? level['Reading']['Negative facts']['Correct Answers'] : ''}</td>
            <td>{level['Reading']['Negative facts']  ? level['Reading']['Negative facts']['%'] : ''}</td>
            <td>{level['Reading']['Negative facts']  ? level['Reading']['Negative facts']['Class Average'] : ''}</td>
            <td>{level['Reading']['Negative facts']  ? level['Reading']['Negative facts']['Rank'] : ''}</td>
          </tr>
          <tr>
            <td>Vocabulary in context</td>
            <td>{level['Reading']['Vocabulary in context'] ? level['Reading']['Vocabulary in context']['Total no of Questions'] : ''}</td>
            <td>{level['Reading']['Vocabulary in context']  ? level['Reading']['Vocabulary in context']['Correct Answers'] : ''}</td>
            <td>{level['Reading']['Vocabulary in context']  ? level['Reading']['Vocabulary in context']['%'] : ''}</td>
            <td>{level['Reading']['Vocabulary in context']  ? level['Reading']['Vocabulary in context']['Class Average'] : ''}</td>
            <td>{level['Reading']['Vocabulary in context']  ? level['Reading']['Vocabulary in context']['Rank'] : ''}</td>
          </tr>
          <tr>
            <th>Listening</th>
            <td>{level['Listening'] ? level['Listening']['Total no of Questions'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['Correct Answers'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['%'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['Class Average'] : ''}</td>
            <td>{level['Listening'] ? level['Listening']['Rank'] : ''}</td>
          </tr>
          <tr>
            <td>Fill in the blanks</td>
            <td>{level['Listening']['Fill in the blanks'] ? level['Listening']['Fill in the blanks']['Total no of Questions'] : ''}</td>
            <td>{level['Listening']['Fill in the blanks'] ? level['Listening']['Fill in the blanks']['Correct Answers'] : ''}</td>
            <td>{level['Listening']['Fill in the blanks'] ? level['Listening']['Fill in the blanks']['%'] : ''}</td>
            <td>{level['Listening']['Fill in the blanks'] ? level['Listening']['Fill in the blanks']['Class Average'] : ''}</td>
            <td>{level['Listening']['Fill in the blanks'] ? level['Listening']['Fill in the blanks']['Rank'] : ''}</td>
          </tr>
          <tr>
            <td>Listening Comprehension</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['Total no of Questions'] : ''}</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['Correct Answers'] : ''}</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['%'] : ''}</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['Class Average'] : ''}</td>
            <td>{level['Listening']['Listening Comprehension'] ? level['Listening']['Listening Comprehension']['Rank'] : ''}</td>
          </tr>
          <tr>
            <th>Grammar</th>
            <td>{level['Grammar'] ? level['Grammar']['Total no of Questions'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['Correct Answers'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['%'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['Class Average'] : ''}</td>
            <td>{level['Grammar'] ? level['Grammar']['Rank'] : ''}</td>
          </tr>
          <tr>
            <th>Speaking</th>
            <td>{level['Speaking'] ? level['Speaking']['Total no of Questions'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['Correct Answers'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['%'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['Class Average'] : ''}</td>
            <td>{level['Speaking'] ? level['Speaking']['Rank'] : ''}</td>
          </tr>
          <tr>
            <th>Writing</th>
            <td>{level['Writing'] ? level['Writing']['Total no of Questions'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['Correct Answers'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['%'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['Class Average'] : ''}</td>
            <td>{level['Writing'] ? level['Writing']['Rank'] : ''}</td>
          </tr>
          <tr>
            <th>Vocabulary</th>
            <td>{level['Vocabulary'] ? level['Vocabulary']['Total no of Questions'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['Correct Answers'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['%'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['Class Average'] : ''}</td>
            <td>{level['Vocabulary'] ? level['Vocabulary']['Rank'] : ''}</td>
          </tr>
        </React.Fragment>
         ))}
      </tbody>
    </table>
  </div>
   : ''
  }

</div>

    </section>
  );
}
