import React, { useEffect } from 'react';
import { useDispatch ,useSelector} from 'react-redux';
import { classesActions} from '_store';
import { useParams } from "react-router-dom";
const Game = () => {

  const dispatch = useDispatch();
  const { classId } = useParams();
  const { classes } = useSelector((x) => x.classes);
  useEffect(() => {
    dispatch(classesActions.getClassAndStudentsById({ classId }));
  }, [dispatch, classId]);

 
  return (
    <div className="game-container">
        <div className='title'>Games</div>
        <div className='sub-title'>{(classes && classes.data) ? classes.data.class_name : ''}</div>
      <div className="row">
      <a href={`/classroom/wheel-of-attendance/${classId}`} target="_blank"><img src="/images/wheelattendce.jpg" /></a>
      <div className='game-name'>
      <a href={`/classroom/wheel-of-attendance/${classId}`} target="_blank">Wheel of Attendance</a>
      </div>
    </div>
    </div>
  );
};

export {Game}
