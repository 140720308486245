import React, { useEffect, useState, useRef } from 'react';
import { gsap, TweenMax } from 'gsap';
import { DrawSVGPlugin } from 'gsap/all';
import Winwheel from 'winwheel';
import confetti from 'canvas-confetti';
import { useTimer } from 'react-timer-hook';
import { useSelector, useDispatch } from 'react-redux';
import { classesActions, gamesActions } from '_store';
import { useParams } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import  ConfettiNew from './ConfettiNew';
gsap.registerPlugin(DrawSVGPlugin);

// gsap.to("#canvas", {
//   drawSVGPlugin: "#canvas", // start the animation when .element scrolls into view
//   x: 100,
//   duration: 2
// });
window.TweenMax = TweenMax;
//console.log(DrawSVGPlugin,'get gsap');
const students = [
  { firstName: "Changgyun", lastName: "Kim", englishName: "Charlie" },
  { firstName: "Jueun", lastName: "Kim", englishName: "Carole" },
  { firstName: "Jua", lastName: "Lim", englishName: "Khatia" },
  { firstName: "Jihyo", lastName: "Kim", englishName: "Elena" },
  { firstName: "Haeun", lastName: "Nam", englishName: "Olivia" }
];

const colors = ['#ee1c24', '#3cb878', '#f6989d', '#f26522', '#fff200', '#00aef0', '#e70697', '#a1b7d1', '#ff6e73', '#8ee35d', '#e35db6', '#6e98e0', '#f6989d', '#f26522'];

const Spinner = () => {
  const [wheel, setWheel] = useState();
  const { classes } = useSelector((x) => x.classes);
  const [soundOn, setSoundOn] = useState(true);
  const [effectOn, setEffectOn] = useState(true);
  const [names, setNames] = useState((classes && classes.data.students) ? classes.data.students.map((student, index) => ({
    fillStyle: colors[index % colors.length],
    text: student.englishName,
    id: index
  })) : []);
  const dispatch = useDispatch();
  const { classId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [wheelSpinning, setWheelSpinning] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const audioRef = useRef(new Audio('/sounds/tick.mp3'));
  const audioClapRef = useRef(new Audio('/images/applause_yells.wav'));
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [numTeams, setNumTeams] = useState(0);
  const [teamMethod, setTeamMethod] = useState('own');
  const [showOwnModal, setShowOwnModal] = useState(false);
  const [pointsForTeam, setPointsForTeam] = useState(new Array(numTeams.length).fill(0));
  
  const [teamSelections, setTeamSelections] = useState([]);
  const [timerEnded, setTimerEnded] = useState(false);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [removeChecked, setRemoveChecked] = useState(false);
  const [studentsPoints, setStudentsPoints] = useState([]);
//console.log(wheel,'get classes data');return false;
const [points, setPoints] = useState([]);
const [selectedSegment, setSelectedSegment] = useState();
const [modalShow, setModalShow] = useState(false);
const debounceTimeout = useRef(null);

const nameListRef = useRef(null);


  useEffect(() => {
    dispatch(classesActions.getClassAndStudentsById({ classId }));
  }, [dispatch, classId]);
  useEffect(() => {
    if (classes && classes.data && classes.data.students.length > 0) {
      var names1 = classes.data.students.map((student, index) => ({
        fillStyle: colors[index % colors.length],
        text: (student.englishName) ? student.englishName : student.firstName ,
        id: student.id,
        index: index + 1  // Start index from 1
      }));
      setNames(names1); 
      const initialPoints = new Array(names1.length).fill(0);
      setPoints(initialPoints);

      const initialSelections = (classes && classes.data) 
      ? classes.data.students.map(student => ({
          studentName: student.englishName || student.firstName,
          team: 'A'
        }))
      : [];
      setTeamSelections(initialSelections);
    }
  }, [classes]);

 //console.log(points,'get names');
  useEffect(() => {
    if (names.length > 0) {
      //console.log("Names array:", names); // Debug log
      const segments = names
  .filter(name => name.text !== null && name.text !== undefined)
  .map((name, index) => ({
    fillStyle: name.fillStyle,
    text: name.text,
    id: name.id,
    segmentIndex: index + 1
  }));

     const wheelInstance = new Winwheel({
        'outerRadius': 200,
        'innerRadius': 75,
        'textFontSize': 15.5,
        'textOrientation': 'vertical',
        'textAlignment': 'outer',
        'numSegments': segments.length,//validSegments.length,
        'segments': segments,
        'pins':true,
        'pins': {
            'number': segments.length,
        },
        // 'animation': {
        //     'type': 'spinOngoing',
        //     'duration': 3600,
        //     'spins': 9999,
        //     'callbackFinished': () => { alert('callback') },
        //     'callbackSound':() =>{ playSound},
        //     'soundTrigger': 'pin'
        //   },
        
      });
     // console.log(wheelInstance,'wheelInstance');
        // Remove null segments
      wheelInstance.segments = wheelInstance.segments.filter(segment => segment !== null);
      var segmentsArray = [];
      wheelInstance.segments.forEach((segment, index) => {

          segmentsArray[index + 1] = segment;

      });
      //console.log(segmentsArray,'segment');
      wheelInstance.segments = segmentsArray;
      wheelInstance.numSegments = wheelInstance.segments.length;
      setWheel(wheelInstance);
    }
  }, [classes,names]);



  const playSoundClap = () => {
    const audioClap = audioClapRef.current;
    audioClap.pause();
    audioClap.currentTime = 0;
    audioClap.play();
  };

  const stopSoundClap = () => {
    audioClapRef.current.pause();
  };

  const alertPrize = (indicatedSegment) => {
    
    if(soundOn){    
      playSoundClap();
    }


    setSelectedSegment(indicatedSegment.text);
    setModalShow(true);

    if(removeChecked){

        const updatedNames = names.filter((name, index) => name.id !== indicatedSegment.id);
     
        setNames(updatedNames);
   
    }
      
  };
  const playSound = () => {alert('yes');
    const audio = audioRef.current;
    audio.pause();
    audio.currentTime = 0;
    audio.play().catch(error => console.log("Error playing sound:", error));
  };
  const startSpin = () => {
    if (!wheelSpinning && wheel) {
    

      
        const wheelInstance = new Winwheel({
          'outerRadius': 200,
          'innerRadius': 75,
          'textFontSize': 15.5,
          'textOrientation': 'vertical',
          'textAlignment': 'outer',
          'numSegments': names.length,//validSegments.length,
          'segments': names,
          'animation': {
            'type': 'spinToStop',
            'duration': 5,
            'spins': 100,
            'callbackFinished': () => { alert('callback') },
            'callbackSound':() =>{ playSound},
            'soundTrigger': 'pin'
          },
          'pins': {
            'number': names.length,
            'fillStyle': 'silver',
            'outerRadius': 4
          }
        });
        try {
          //playSound();
          wheelInstance.startAnimation();
        
          setWheel(wheelInstance);
        setWheelSpinning(true);
      } catch (error) {
        console.error("Error starting animation:", error);
      }
    } else {
      stopWheel();
      setWheelSpinning(false);
    }
  };

  const stopWheel = () => {
    if (wheel) {
      wheel.stopAnimation(false);
      let wheelPrize = wheel.getIndicatedSegment();
      //console.log(wheelPrize.id,'selected');
      setSelectedId(wheelPrize.id);
      alertPrize(wheelPrize);
      
    }
  };

  const shuffleNames = () => {
    const shuffled = [...names].sort(() => Math.random() - 0.5);
    setNames(shuffled);
  };
  
  
  const removeSelected = () => {
    setRemoveChecked(true);
    
  };
  const initialTime = new Date();
  initialTime.setSeconds(initialTime.getSeconds() + 60); // 10 seconds from now
  const [expiryTimestamp, setExpiryTimestamp] = useState(initialTime);
  const {
    seconds,
    minutes,
    hours,
    start,
    pause,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => {
    if(isCountdownActive){
      setTimerEnded(true);
      setIsCountdownActive(false);
      alert('Timer has ended!');
    }
      
    }});

  const startCountdown = () => {
    if (!isCountdownActive) {
      // Calculate the expiry time based on user input
      const hrs = document.getElementById('hrss').value;
      const mins = document.getElementById('minss').value;
      const secs = document.getElementById('secss').value;

      const expiryTimestamp = new Date();
      expiryTimestamp.setHours(expiryTimestamp.getHours() + parseInt(hrs));
      expiryTimestamp.setMinutes(expiryTimestamp.getMinutes() + parseInt(mins));
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + parseInt(secs));
      restart(expiryTimestamp);
      setIsCountdownActive(true);
      setTimerEnded(false);
    } else {
      // If countdown is already active, pause it
      pause();
      setIsCountdownActive(false);
    }
  };
  const debounce = (func, delay) => {
    return (...args) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const namemodified = () => {
    if (nameListRef.current) {
      const newNames = Array.from(nameListRef.current.children)
        .map((child, index) => ({
          fillStyle: colors[index % colors.length],
          text: child.textContent.trim(),
          id: child.getAttribute('data-id') || `name-${index}`, // Assign unique id if not present
        }))
        .filter(name => name.text); // Filter out empty strings
      setNames(newNames);
    }
  };

  const debouncedNamemodified = debounce(namemodified, 500);

  useEffect(() => {
    if (nameListRef.current) {
      const handleInput = () => {
        debouncedNamemodified();
      };

      const currentRef = nameListRef.current;
      currentRef.addEventListener('input', handleInput);

      return () => {
        currentRef.removeEventListener('input', handleInput);
      };
    }
  }, [debouncedNamemodified]);

  useEffect(() => {
    if (nameListRef.current) {
      const currentRef = nameListRef.current;
      // Clear the content and re-render based on the names state
      currentRef.innerHTML = '';
      names.forEach((name) => {
        const div = document.createElement('div');
        div.setAttribute('data-id', name.id);
        div.textContent = name.text;
        currentRef.appendChild(div);
      });
    }
  }, [names]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newDiv = document.createElement('div');
      newDiv.setAttribute('data-id', `name-${Date.now()}`);
      newDiv.textContent = '';
      nameListRef.current.appendChild(newDiv);

      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(newDiv, 0);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const increasePoints = (index, id, type, team) => {
    if (index < 0 || index >= points.length) {
      console.error(`Invalid index: ${index}`);
      return;
    }

    if (typeof points[index] !== 'number') {
      console.error(`Invalid value at index ${index}: ${points[index]}`);
      return;
    }
    if(team){

      const newPointsForTeam = { ...pointsForTeam };
      newPointsForTeam[team] = (newPointsForTeam[team] || 0) + 1;
      setPointsForTeam(newPointsForTeam);
    }
    const newPoints = [...points];
    newPoints[index] += 1;

    //console.log(newPoints, 'updated points array');

    setPoints(newPoints);
    
    const studentName = (classes && classes.data && classes.data.students.length > 0) ? classes.data.students[index] : '';
    //alert(`${studentName}'s points increased!`);
    addOrUpdateStudent(names[index], newPoints[index], type );
    //setStudentsPoints(prevStudents => [...prevStudents, newStudent]);
   // var st = {'students': studentsPoints};
    
     // Display confetti if effect toggle is on
     if (effectOn) {
      confetti({particleCount: 10000, // Increase particle count
        spread: 10000, // Increase spread
        origin: { y: 0.9, x: 0.5 }});
    }
//console.log(names[index],'get st',);return false;
    // Play sound if sound toggle is on
    if (soundOn) {
      playSoundClap();
    }
    
  };
  //console.log(studentsPoints,'get transform');
  const transformResponse = (response, points, type) => {
    return {
        id: response.id,
        point: points,
        pointtype: type
    };
};
useEffect(() => {
  var students = studentsPoints;
    dispatch(gamesActions.savePoints({ students }));
 //console.log(studentsPoints,'get transform');
}, [studentsPoints]);

const addOrUpdateStudent = (response, points, type) => {
  const newStudent = transformResponse(response, points, type);

  setStudentsPoints(prevStudents => {
      const studentIndex = prevStudents.findIndex(student => student.id === newStudent.id);

      if (studentIndex > -1) {
          // Update existing student
          const updatedStudents = [...prevStudents];
          updatedStudents[studentIndex] = newStudent;
          return updatedStudents;
      } else {
          // Add new student
          return [...prevStudents, newStudent];
      }
  });
};
  const decreasePoints = (index,id, type, team) => {
    const newPoints = [...points];
    if (newPoints[index] > 0) {
      newPoints[index] -= 1;
      setPoints(newPoints);
    }
    if(team){
      const newPointsForTeam = { ...pointsForTeam };
      if (newPointsForTeam[team] > 0) {
        newPointsForTeam[team] -= 1;
        setPointsForTeam(newPointsForTeam);
      }
    }

    addOrUpdateStudent(names[index], newPoints[index], type );
  };

  const handleResetPoints = () => {
    setShowModal(true);
  };

  const confirmResetPoints = () => {
    setPoints(new Array(points.length).fill(0));
    setShowModal(false);
  };

  const cancelResetPoints = () => {
    setShowModal(false);
  };

  const handleTeamButtonClick = () => {
    setShowTeamModal(true);
  };

  const confirmCreateTeams = () => {
    if(numTeams == 0){
      alert('Please enter number of teams.');
      return false;
    }
    if (teamMethod === 'own') {
      // Process the team selections
      //console.log('Team selections:', teamSelections);
      setShowOwnModal(true);
    } else if (teamMethod === 'random') {
        // Process random team creation
      const totalStudents = classes.data.students.length;
      const studentsCopy = [...classes.data.students];
      const shuffledStudents = studentsCopy.sort(() => Math.random() - 0.5);
      const newTeamSelections = shuffledStudents.map((student, index) => {
        const teamIndex = index % numTeams;
        const teamName = String.fromCharCode(65 + teamIndex);
        return { studentName: student.englishName, team: teamName };
      });

      setTeamSelections(newTeamSelections);
      //console.log('Teams:', newTeamSelections);
    }
    // For now, just close the modal
    setShowTeamModal(false);
  };

  const cancelCreateTeams = () => {
    setShowTeamModal(false);
  };

  const cancelOwnTeam = () => {
    setShowOwnModal(false);
  };
  const confirmOwnTeam = () => {
    setShowOwnModal(false);
    // Ensure every student has a team, defaulting to team A if none is selecte
    if(teamSelections.length > 0){
      var finalSelections = teamSelections.map(selection => ({
        studentName: selection.studentName,
        team: selection.team || 'A'
      }));
    }else{
      var finalSelections = classes.data.students.map(selection => ({
        studentName: selection.englishName || selection.firstName,
        team: selection.team || 'A'
      }));
    }
   

    setTeamSelections(finalSelections);
   // console.log('Final team selections:', finalSelections);
    //console.log('Team selections:', teamSelections);
    if (teamMethod === 'random') {
      // Process random team creation
    const totalStudents = classes.data.students.length;
    const studentsCopy = [...classes.data.students];
    const shuffledStudents = studentsCopy.sort(() => Math.random() - 0.5);
    const newTeamSelections = shuffledStudents.map((student, index) => {
      const teamIndex = index % numTeams;
      const teamName = String.fromCharCode(65 + teamIndex);
      return { studentName: student.englishName || student.firstName, team: teamName };
    });

    setTeamSelections(newTeamSelections);
    //console.log('Teams:', newTeamSelections);
  }
  };
  const handleTeamSelection = (studentIndex, team) => {
    //console.log(teamSelections,'teamselect 1');
    const updatedSelections = [...teamSelections];
    const studentName = classes.data.students[studentIndex].englishName || classes.data.students[studentIndex].firstName;
    updatedSelections[studentIndex] = { studentName, team };
    setTeamSelections(updatedSelections);
  };

  const groupStudentsByTeam = () => {
    const teams = {};
    teamSelections.forEach(selection => {
      if (selection.team) {
        if (!teams[selection.team]) {
          teams[selection.team] = [];
        }
        teams[selection.team].push(selection);
      }
    });
    return teams;
  };
  const cancelModal = () => {
    setModalShow(false);
  };
  const groupedTeams = groupStudentsByTeam();
  
  useEffect(() => {
    if (nameListRef.current) {
      const handleInput = () => {
        debouncedNamemodified();
      };

      const currentRef = nameListRef.current;
      currentRef.addEventListener('input', handleInput);

      return () => {
        currentRef.removeEventListener('input', handleInput);
      };
    }
  }, [debouncedNamemodified]);

  useEffect(() => {
    if (nameListRef.current) {
      const currentRef = nameListRef.current;
      // Clear the content and re-render based on the names state
      currentRef.innerHTML = '';
      names.forEach((name) => {
        const div = document.createElement('div');
        div.setAttribute('data-id', name.id);
        div.textContent = name.text;
        currentRef.appendChild(div);
      });
    }
  }, [names]);
  return (
    <div className="wheel-game-container">
      <div className="title">
        <h2>{(classes && classes.data) ? classes.data.class_name : ''}</h2>
      </div>
      <div className="row">
      <div className="col-md-6">
      <div className="wheel-wrapper">
        <canvas id="canvas" width="434" height="434" onClick={startSpin}></canvas>
      </div>
      </div>
      <div className='col-md-6'>
        <div className='row wheel-game-name'>
        <div className='col-md-5'>
        <div className="controls">
        <button onClick={shuffleNames}>Shuffle</button>
        <label>
          Remove
          <input type="checkbox" id="chkremove" onChange={removeSelected} />
        </label>
        {/* <div contentEditable="true" className="names-list" ref={nameListRef}
      suppressContentEditableWarning={true}>
          {names.map((name, index) => (
            <div key={name.id} data-id={name.id}>{name.text}</div>
          ))}
        </div> */}

<div
      className="names-list"
      contentEditable="true"
      ref={nameListRef}
      onKeyDown={handleKeyDown}
      suppressContentEditableWarning={true}
      style={{ whiteSpace: 'pre-wrap' }}
    ></div>

            </div>
            {(!isCountdownActive) ?          <div className="timer">
              <div className='input-container'>
              <input type="number" id="hrss" defaultValue="0" /> <label for="hrss">Hrs</label>
              </div>
              <div className='input-container'>
              <input type="number" id="minss" defaultValue="1" /> <label for="minss">Mins</label>
              </div>
              <div className='input-container'>
              <input type="number" id="secss" defaultValue="0" /> <label for="secss">Secs</label>
              </div>
        
        
        
        <button id="start-countdown" onClick={startCountdown}>{!isCountdownActive ? 'Start Countdown' : 'Stop Countdown'}</button>
      </div>: <div className="timer">
            <span className="hours">{hours.toString().padStart(2, '0')}</span>:<span className="minutes">{minutes.toString().padStart(2, '0')}</span>:<span className="seconds">{seconds.toString().padStart(2, '0')}</span>
            <button id="start-countdown" onClick={startCountdown}>Stop Countdown</button>
          </div>}
   
            {/* <div className="timer">
        <input type="number" id="hrss" defaultValue="0" /> <label for="hrss">Hrs</label>
        <input type="number" id="minss" defaultValue="1" /> <label for="minss">Mins</label>
        <input type="number" id="secss" defaultValue="0" /> <label for="secss">Secs</label>
        <button id="start-countdown" onClick={startCountdown}>Start Countdown</button>
      </div> */}
      {/* {isCountdownActive && (
          <div className="time-display">
            <span className="hours">{hours.toString().padStart(2, '0')}</span>:<span className="minutes">{minutes.toString().padStart(2, '0')}</span>:<span className="seconds">{seconds.toString().padStart(2, '0')}</span>
            
          </div>
        )} */}
        </div>
        <div className="col-md-7">
              <div className="btn-container">
                <button className="big"  onClick={handleResetPoints}>Reset Points</button>
                <button className="big" onClick={handleTeamButtonClick}>Team </button>
                <p>Sound</p>
                <label className="switch">
                  <input type="checkbox" checked={soundOn} onChange={() => setSoundOn(!soundOn)} />
                  <span className="slider round" />
                </label>
                <p>Effect</p>
                <label className="switch">
                  <input type="checkbox" checked={effectOn} onChange={() => setEffectOn(!effectOn)} />
                  <span className="slider round" />
                </label>
              </div>
 
              {(numTeams > 0 && (teamMethod === 'own' || teamMethod === 'random' )) && (
           
<table border="1" style={{ border: '1px solid black' }}>
  <tbody>
    <tr>
      <td>Team</td>
      <td>Name</td>
      <td>Points</td>
    </tr>
    {(() => {
      let overallIndex = 0; // Initialize the overall index for uniqueness

      return Object.keys(groupedTeams).map((team, teamIndex) => (
        groupedTeams[team].map((selection, studentIndex) => {
          const currentOverallIndex = overallIndex++;
          return (
            <tr
              key={currentOverallIndex} // Use overallIndex as the key
              id={`trrsx_${currentOverallIndex}`}
              attrgrp={teamIndex}
              style={{
                borderTop: studentIndex === 0 ? '1.5px solid' : 'none',
                borderBottom: studentIndex === groupedTeams[team].length - 1 ? '2px solid' : 'none'
              }}
            >
              {studentIndex === 0 && (
                <td
                  rowSpan={groupedTeams[team].length}
                  style={{ border: '2px solid' }}
                >
                  {team} <br />
                  <span onClick={() => decreasePoints(currentOverallIndex, currentOverallIndex, '-', team)} className={`grps_${teamIndex}`} style={{ fontWeight: 'bold' }}>{pointsForTeam[team]}</span>
                </td>
              )}
              <td>{selection.studentName}</td>
              <td>
                <i
                  className="fa fa-minus"
                  style={{ fontSize: '14px', marginRight: '8px', cursor: 'pointer' }}
                  aria-hidden="true"
                  onClick={() => decreasePoints(currentOverallIndex, currentOverallIndex, '-', team)}
                ></i>
                <span onClick={() => increasePoints(currentOverallIndex, currentOverallIndex, '+', team)} className={`spnscrr spnptss_${currentOverallIndex}`} style={{ fontWeight: 'bold' }}>{points[currentOverallIndex]}</span>
                <i
                  className="fa fa-plus"
                  style={{ fontSize: '14px', marginLeft: '8px', cursor: 'pointer' }}
                  aria-hidden="true"
                  onClick={() => increasePoints(currentOverallIndex, currentOverallIndex, '+', team)}
                ></i>
              </td>
            </tr>
          );
        })
      ));
    })()}
  </tbody>
</table>


            )}
            {(numTeams == 0) && (
              <table>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Points</th>
                  </tr>
                  {(classes && classes.data) ? classes.data.students.map((student, index) => (
                    <tr key={index}>
                      <td>{(student.englishName) ? student.englishName : student.firstName}</td>
                      <td>
                      <span onClick={() => decreasePoints(index,student.id,'-')} className='decrease-points'>-</span> {points[index]} <span className='increase-points' onClick={() => increasePoints(index,student.id,'+')}>+</span>
                      </td>
                    </tr>
                  )) : ''}
                </tbody>
              </table>)}
            </div>
      </div>
      </div>
     
      <Modal show={showModal} onHide={cancelResetPoints}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Points</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                  Are you sure you want to reset all the scores?
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={confirmResetPoints}>
            Yes
          </Button> <Button variant="secondary" onClick={cancelResetPoints}>
            No
          </Button>
        </Modal.Footer>
        </Modal>

        <Modal show={showTeamModal } onHide={cancelCreateTeams}>
        <Modal.Header closeButton>
          <Modal.Title>Create Teams</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
              <label>How many teams?</label>
              <input 
                type="number" 
                value={numTeams} 
                onChange={(e) => setNumTeams(e.target.value)} 
                min="1"
              />
            </div>
            <div>
              <label>
                <input 
                  type="radio" 
                  name="teamMethod" 
                  value="own" 
                  checked={teamMethod === 'own'}
                  onChange={() => setTeamMethod('own')}
                />
                Choose your own members
              </label>
              <label>
                <input 
                  type="radio" 
                  name="teamMethod" 
                  value="random" 
                  checked={teamMethod === 'random'}
                  onChange={() => setTeamMethod('random')}
                />
                Random
              </label>
            </div>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={confirmCreateTeams}>
            Yes
          </Button> <Button variant="secondary" onClick={cancelCreateTeams}>
            No
          </Button>
        </Modal.Footer>
        </Modal>

        <Modal show={showOwnModal} onHide={cancelOwnTeam}>
        <Modal.Header closeButton>
          <Modal.Title>Select Team for Each</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {teamMethod === 'own' && (
              <div>
                <table>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Team</th>
                  </tr>
                  {(classes && classes.data) ? classes.data.students.map((student, index) => (
                    <tr key={index}>
                      <td>{(student.englishName) ? student.englishName : student.firstName}</td>
                      <td>
                      <select
                      value={teamSelections[index] || ''}
                      onChange={(e) => handleTeamSelection(index, e.target.value)}
                    >
                     
                      {Array.from({ length: numTeams }, (_, i) => (
                        <option key={i} value={String.fromCharCode(65 + i)}>
                          {String.fromCharCode(65 + i)}
                        </option>
                      ))}
                    </select>
                      </td>
                    </tr>
                  )) : ''}
                </tbody>
              </table>
     
              </div>
            )}
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={confirmOwnTeam}>
            Create
          </Button> <Button variant="secondary" onClick={cancelOwnTeam}>
            Cancel
          </Button>
        </Modal.Footer>
        </Modal>
        <Modal show={modalShow } onHide={cancelModal}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <h4>{selectedSegment} is selected.</h4>
        </Modal.Body>
        <Modal.Footer>
         
         <Button variant="secondary" onClick={cancelModal}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
       
      </div>
      <div className='row'>
        {(effectOn && modalShow) ? 
      <div className='col-md-12'>
            <ConfettiNew />
          </div>
          : ''}
      </div>
    </div>
  );
};

export {Spinner}
