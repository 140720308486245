import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import { authActions, studentActions } from "_store";
import ReCAPTCHA from "react-google-recaptcha";

export { Settings };

function Settings() {
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth.user);
  const signupError = useSelector((x) => x.signup.error);
  const { student } = useSelector((x) => x.student);

  const [inputValue, setInputValue] = useState("");
  const [inputMsg, setInputMessage] = useState("");
  const [inputShow, setInputShow] = useState(false);

  useEffect(() => {
    if (authUser && authUser.user.student_id) {
      const userId = authUser.user.student_id;
      dispatch(studentActions.getStudentById({ userId }));
    }
  }, [authUser, dispatch]);

  // Validation schema for the password form
  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required("Current Password is required"),
    new_password: Yup.string()
      .required("New Password is required")
      .min(6, "Password must be at least 6 characters"),
    verify_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
      .required("Confirm Password is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({ current_password, new_password }) {
    const userid = (authUser.user.student_id) ? authUser.user.student_id : authUser.user.teacher_id;
    const oldPassword = current_password;
    const newPassword = new_password;
    return dispatch(
      authActions.changepassword({ userid, oldPassword, newPassword })
    );
  }

  function handleInputChange(event) {
    const inputRegex = /^[a-zA-Z0-9]+$/; // regular expression to match alphanumeric input
    const inputValue = event.target.value;

    if (inputRegex.test(inputValue)) {
      setInputValue(inputValue);
      setInputMessage("");
      setInputShow(false);
    } else {
      setInputMessage("ID should be alphanumeric.");
      setInputShow(true);
    }
  }

  return (
    <section className="custom-sign profile">
      <div className="container-fluid">
        <div className="row">
          <div className="my-profile-form">
            <h3>Change Password</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-4">
                  <label>Current Password*</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="password"
                    {...register("current_password")}
                    className={`form-control ${
                      errors.current_password ? "is-invalid" : ""
                    }`}
                    name="current_password"
                    placeholder="Current Password"
                  />
                  <div className="invalid-feedback">
                    {errors.current_password?.message}
                  </div>
                </div>

                <div className="col-md-4">
                  <label>New Password*</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="password"
                    {...register("new_password")}
                    className={`form-control ${
                      errors.new_password ? "is-invalid" : ""
                    }`}
                    name="new_password"
                    placeholder="New Password"
                  />
                  <div className="invalid-feedback">
                    {errors.new_password?.message}
                  </div>
                </div>

                <div className="col-md-4">
                  <label>Confirm Password*</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="password"
                    {...register("verify_password")}
                    className={`form-control ${
                      errors.verify_password ? "is-invalid" : ""
                    }`}
                    name="verify_password"
                    placeholder="Confirm Password"
                  />
                  <div className="invalid-feedback">
                    {errors.verify_password?.message}
                  </div>
                </div>

                <div className="signin-form-btn">
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  <input
                    className="custom-button"
                    type="submit"
                    value="Change Password"
                  />
                </div>
              </div>
            </form>

            {inputShow && <div className="alert alert-danger">{inputMsg}</div>}
          </div>
        </div>
      </div>
    </section>
  );
}
