import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'reports';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const reportsActions = { ...slice.actions, ...extraActions };
export const reportsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        getVocabularyReport: getVocabularyReport(),
    };    

    function getVocabularyReport() {
        return createAsyncThunk(
            `${name}/reports`,
            async ({ studentId, date, school, type }) => await fetchWrapper.get(`${baseUrl}/reports/vocabularyreport/`+studentId+'?date='+date+'&school='+school+'&type='+type)
        );
    }


}

function createExtraReducers() {
    return {
        ...getVocabularyReport(),
    };

    function getVocabularyReport() {
        var { pending, fulfilled, rejected } = extraActions.getVocabularyReport;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const reports = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                state.reports = reports;
                console.log(reports,'sds');
                // toast.success('Check your email to verify the account..', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     });
                // get return url from location state or default to home page
                // const { from } = { from: { pathname: '/verify-email' } };
                // history.navigate(from);
            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }


}
