import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'library';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const libraryActions = { ...slice.actions, ...extraActions };
export const libraryReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        getLibraryHomeWorkBookList: getLibraryHomeWorkBookList(),
        getLibraryHomeWorkBookListByRecordId:getLibraryHomeWorkBookListByRecordId(),
        getLibraryHomeWorkByRecordId:getLibraryHomeWorkByRecordId(),
        textBookVocabQuiz:textBookVocabQuiz(),
        listenVocabQuiz:listenVocabQuiz(),
        getSentencesForLibrary:getSentencesForLibrary(),
        getFillInTheBlankForLibrary:getFillInTheBlankForLibrary(),
        getGrammarQuizForLibrary:getGrammarQuizForLibrary(),
        getReadingComprehensionForLibrary:getReadingComprehensionForLibrary(),
        getListeningComprehensionForLibrary:getListeningComprehensionForLibrary(),
        getLibraryBookList:getLibraryBookList(),
        getLibraryBookData:getLibraryBookData(),
        getLibraryHomeWorkBookListForTeacher: getLibraryHomeWorkBookListForTeacher(),
    };    

    function getLibraryHomeWorkBookList() {
        return createAsyncThunk(
            `${name}/library`,
            async ({class_id, teacher_id, date, student_id}) => await fetchWrapper.get(`${baseUrl}/library/all?class_id=`+class_id+'&teacher_id='+teacher_id+'&date='+date+'&student_id='+student_id)
        );
    }

    function getLibraryHomeWorkBookListForTeacher() {
        return createAsyncThunk(
            `${name}/library`,
            async ({class_id, teacher_id, date, student_id}) => await fetchWrapper.get(`${baseUrl}/library/teacher/all?class_id=`+class_id+'&teacher_id='+teacher_id+'&date='+date+'&student_id='+student_id)
        );
    }

    function getLibraryHomeWorkBookListByRecordId() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ id, student_id, class_id, date }) => await fetchWrapper.get(`${baseUrl}/library/library-books/`+id+'?student_id='+student_id+'&class_id='+class_id+'&date='+date)
        );
    }
    

    function getLibraryHomeWorkByRecordId() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ library_id }) => await fetchWrapper.get(`${baseUrl}/library/homework/`+library_id)
        );
    }

    function textBookVocabQuiz() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ program_id }) => await fetchWrapper.get(`${baseUrl}/library/quiz/`+program_id)
        );
    }

    function listenVocabQuiz() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ program_id }) => await fetchWrapper.get(`${baseUrl}/library/quiz/listening/`+program_id)
        );
    }

    function getSentencesForLibrary() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ program_id, type }) => await fetchWrapper.get(`${baseUrl}/library/program/`+program_id+'?type='+type)
        );
    }

    function getFillInTheBlankForLibrary() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ program_id }) => await fetchWrapper.get(`${baseUrl}/library/getblanks/`+program_id)
        );
    }

    function getGrammarQuizForLibrary() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ program_id }) => await fetchWrapper.get(`${baseUrl}/library/quiz/grammermcq/`+program_id)
        );
    }

    function getReadingComprehensionForLibrary() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ program_id }) => await fetchWrapper.get(`${baseUrl}/library/quiz/reading-comprehension/`+program_id)
        );
    }

    function getListeningComprehensionForLibrary() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ program_id }) => await fetchWrapper.get(`${baseUrl}/library/quiz/listening-comprehension/`+program_id)
        );
    }

    function getLibraryBookList() {
        return createAsyncThunk(
            `${name}/library`,
            async ({book_id}) => await fetchWrapper.get(`${baseUrl}/library/bookprogram/`+book_id)
        );
    }

    function getLibraryBookData() {
        return createAsyncThunk(
            `${name}/library`,
            async ({ program_id }) => await fetchWrapper.get(`${baseUrl}/library/book-data/`+program_id)
        );
    }

}

function createExtraReducers() {
    return {
        ...getLibraryHomeWorkBookList(),
        ...getLibraryHomeWorkBookListByRecordId(),
        ...getLibraryHomeWorkByRecordId(),
        ...textBookVocabQuiz(),
        ...listenVocabQuiz(),
        ...getSentencesForLibrary(),
        ...getFillInTheBlankForLibrary(),
        ...getGrammarQuizForLibrary(),
        ...getReadingComprehensionForLibrary(),
        ...getListeningComprehensionForLibrary(),
        ...getLibraryBookList(),
        ...getLibraryBookData(),
        ...getLibraryHomeWorkBookListForTeacher(),
    };

    function getLibraryHomeWorkBookListForTeacher() {
        var { pending, fulfilled, rejected } = extraActions.getLibraryHomeWorkBookListForTeacher;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getLibraryHomeWorkBookList() {
        var { pending, fulfilled, rejected } = extraActions.getLibraryHomeWorkBookList;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getLibraryHomeWorkBookListByRecordId() {
        var { pending, fulfilled, rejected } = extraActions.getLibraryHomeWorkBookListByRecordId;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getLibraryHomeWorkByRecordId() {
        var { pending, fulfilled, rejected } = extraActions.getLibraryHomeWorkByRecordId;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
             // console.log(user,'get register user');  return false;
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function textBookVocabQuiz() {
        var { pending, fulfilled, rejected } = extraActions.textBookVocabQuiz;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
             // console.log(user,'get register user');  return false;
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function listenVocabQuiz() {
        var { pending, fulfilled, rejected } = extraActions.listenVocabQuiz;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
             // console.log(user,'get register user');  return false;
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getSentencesForLibrary() {
        var { pending, fulfilled, rejected } = extraActions.getSentencesForLibrary;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
             // console.log(user,'get register user');  return false;
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getFillInTheBlankForLibrary() {
        var { pending, fulfilled, rejected } = extraActions.getFillInTheBlankForLibrary;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
             // console.log(user,'get register user');  return false;
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getGrammarQuizForLibrary() {
        var { pending, fulfilled, rejected } = extraActions.getGrammarQuizForLibrary;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
             // console.log(user,'get register user');  return false;
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getReadingComprehensionForLibrary() {
        var { pending, fulfilled, rejected } = extraActions.getReadingComprehensionForLibrary;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
             // console.log(user,'get register user');  return false;
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }
    
    function getListeningComprehensionForLibrary() {
        var { pending, fulfilled, rejected } = extraActions.getListeningComprehensionForLibrary;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
             // console.log(user,'get register user');  return false;
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getLibraryBookList() {
        var { pending, fulfilled, rejected } = extraActions.getLibraryBookList;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getLibraryBookData() {
        var { pending, fulfilled, rejected } = extraActions.getLibraryBookData;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const library = action.payload;
             // console.log(user,'get register user');  return false;
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               //localStorage.setItem('library', JSON.stringify(library));
                state.library = library;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

}
