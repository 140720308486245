import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { quizActions, quizresultActions } from "_store";
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
// import { BarChart } from '@mui/x-charts/BarChart';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

export { Quiztestpage };

function Quiztestpage() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [myState, setMyState] = useState('initialValue');
  const { quizresult, quizresultLoading } = useSelector((x) => x.quizresult);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [quizCategory, setquizCategory] = useState();
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isResult, setisResult] = useState(false);
  const [prevResult, setprevResult] = useState([]);
  const [quizalready, setQuizAlready] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(location.state.vocbsetting.time);
  const [timer, setTimer] = useState(null);



  const student = JSON.parse(localStorage.getItem('user'));



  const handleOptionSelect = (selected) => {
    setSelectedOption(selected);
    handleNextQuestion(selected);
    setUserAnswers(prevAnswers => [...prevAnswers, selected]);
        console.log(selected,"option------")
  };


  const handleNextQuestion = (selectedOption) => {
    
    if(selectedOption==null){
       setUserAnswers(prevAnswers => [...prevAnswers, 'Not answered']);
    }

    if (timer) {
      clearTimeout(timer);
    }
 
    if (selectedOption === quizData[currentQuestion].answer) {
      setScore(score + 1);
    }

    setSelectedOption(null);

    if (currentQuestion + 1 < quizData.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      console.log("quiz comp");
    }

    if (currentQuestion === quizData.length - 1) {
      console.log('Setting quizCompleted to true');
      setQuizCompleted(true);
    }

    setTimeRemaining(location.state.vocbsetting.time);
  };



  const handleKeyPress = (event) => {
    const selectedNumber = Number(event.key);
    if (!isNaN(selectedNumber) && selectedNumber > 0 && selectedNumber <= quizData[currentQuestion].options.length) {
      const selectedOption = quizData[currentQuestion].options[selectedNumber - 1];
      handleOptionSelect(selectedOption);
    }
  };

  useEffect(() => {
    let timer;
    if (isResult==false && timeRemaining > 0 && currentQuestion < quizData.length && location.state.vocbsetting.istime == 1) {

        

      timer = setTimeout(() => {
        handleNextQuestion(null);
      }, timeRemaining * 1000);

      const countdownInterval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);

      setTimer(timer);

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
        clearInterval(countdownInterval);
      };
    }
  }, [timeRemaining, currentQuestion, quizData]);





  useEffect(() => {
    try {
      if (quizData.length === 0) {
        if (!location.state) {
          window.location.href = '/';
        }
        
        setQuizData(shuffleArray([...location.state.questions]));
        setquizCategory(location.state.vocabulary.filename);
        const results=location?.state?.currentresult;
        console.log(location.state.currentresult,"curre--")
        if(results ){
          const latestresult=JSON.parse(results.result);
              setQuizAlready(true);
              setScore(results.score);
          
              // const answers=latestresult.map(item=>{return item.useranswer});
              // setUserAnswers(answers);
              setisResult(true);
              setprevResult(latestresult);
        }
            console.log(location.state.questions,"quizdata");  
      }


    }
    catch (error) {
      console.error("An error occurred:", error);
    }
  }, [dispatch]);




  useEffect(() => {

    window.addEventListener('keydown', handleKeyPress);


    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (quizCompleted === true) {
      console.log(userAnswers, "quizcompleted");
      const result = []
      {
        quizData.map((question, index) => (
          result.push({ id:question.id,word: question.word, answer: question.answer, useranswer: (index < userAnswers.length) ? userAnswers[index] : "Not answered" })
        ))
      }

      console.log(student, "student");
      const notanswered=result.filter(item=>item.useranswer==="Not answered");

      if (student) {
        const option = {
          category: location.state.vocabulary.id,
          score,
          student: student.user.student_id,
          totalQuestions: quizData.length,
          type: "Quiz",
          set: location.state.setindex,
          result: JSON.stringify(result),
          notanswered:notanswered
        }
        console.log(option, "optionnnsss")
        dispatch(quizresultActions.savequizresult(option));
      }

    }
  }, [quizCompleted]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const renderOptions = () => {
    return quizData[currentQuestion].options.map((option, index) => (
      <div key={index} className='quiz-options'>
        <label className='option-index'>{index + 1}</label>
        <label className="container" htmlFor={option}>
          {option}
          <input
            type="radio"
            id={option}
            name="options"
            value={option}
            checked={selectedOption === option}
            onChange={() => handleOptionSelect(option)}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    ));
  };

  const handleRetakeQuiz = () => {

    const shuffledQuizData = shuffleArray([...quizData]);

    setQuizCompleted(false);
    setQuizAlready(false);
    setisResult(false);
    setCurrentQuestion(0);
    setSelectedOption(null);
    setUserAnswers([]);
    setScore(0);
    setQuizData(shuffledQuizData);
    console.log(userAnswers,"useranswer--")
  };

  const renderQuizContent = () => {
    if (currentQuestion < quizData.length && quizCompleted === false && quizalready === false) {
      const totalTime = location.state.vocbsetting.time;
      const remainingPercentage = (timeRemaining / totalTime) * 100;
      const precent = (100 - ((currentQuestion - score) / quizData.length * 100)).toFixed(2);
      let bombImage = 1;
      if (timeRemaining < 10) {
        bombImage = 2;
    }
    if (timeRemaining < 2) {
        bombImage = 3;
    }
      return (

        <div className="quiz-content">
          <div className="quiz-header">
            <div className="category-head">
              <h4>{quizCategory}</h4>
            </div>

            {location.state.vocbsetting.istime === 1 && (
               <>
              <ProgressBar stripped style={{
                width: "100%",
                height: "1.3rem",
                fontSize: "0.95rem"
              }}
                animated now={remainingPercentage} label={`${timeRemaining} seconds`} />
               
                <div className="time-bomb">
                <div className="image-fader">
                <img src={`/images/time_bomb_${bombImage}.png`} />
                </div>
            </div>
            </>
            )}
            <div className="quiz-progress">
              <p>Question {currentQuestion + 1} of {quizData.length}</p>
              {/* <p>Percentage: {(currentQuestion === 0) ? 100 : ((score /quizData.length ) * 100).toFixed(2)}%</p> */}
              <p> {currentQuestion === 0 ? "" : `Percentage: ${precent} %`} </p>


            </div>
          </div>
          <div className="quiz-question-area">
          <div class="quiz-question-area-part-one">
            <h3 style={{ textTransform: "none" }}>{quizData[currentQuestion].word}</h3>
            </div>
            <div class="quiz-question-area-part-two"> {renderOptions()}</div>
           
          </div>
        </div>
      );
    }
    // else if (quizalready == true && quizresult) {
    //   const percentage = ((quizresult.score / quizData.length) * 100).toFixed(2);
    //   console.log(quizresult, "qiz")
    //   const preresult = JSON.parse(quizresult.result)
    //   return (

    //     <div>
    //       <h2>Quiz Completed!</h2>
    //       <Table style={{ margin: '20px', width: '95%' }}>
    //         <thead>
    //           <th>Your Raw Score</th>
    //           <th>{quizresult.score} / {quizData.length}</th>
    //         </thead>
    //         <thead>
    //           <th>Your Score</th>
    //           <th>{percentage}%</th>
    //         </thead>
    //       </Table>

    //       <div className="quiz-results">
    //         <h3>Quiz Results:</h3>
    //         <Table striped bordered responsive>
    //           <thead>
    //             <tr>
    //               <th></th>
    //               <th>Word</th>
    //               <th>Correct Answer</th>
    //               <th>User Answer</th>
    //               <th></th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {preresult.map((question, index) => (
    //               <tr key={index}>
    //                 <td>{index + 1}</td>
    //                 <td>{question.word}</td>
    //                 <td> {question.answer}</td>
    //                 <td>{question.useranswer}</td>
    //                 <th>{(question.answer == question.useranswer) ? 'O' : 'X'}</th>
    //               </tr>

    //             ))}
    //           </tbody>
    //         </Table>
    //       </div>

    //       <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
    //     </div>
    //   );

    // } 
    else if (quizalready == true && isResult==true ) {
      const percentage = ((score / quizData.length) * 100);
      const remaining=100-percentage;
      const data = [{ name: 'Your score', score: percentage, total: remaining  }];
      return (

        <div>
          <h2>Quiz Result!</h2>
      <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
     <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart>

       
          <span className='score'>
            <div className="score-wrap">
           <span className="stars-active" style={{ width:`${percentage}%`}}>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
        <span className="stars-inactive">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
        </div>
       </span>
        
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {quizData.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results :</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Word</th>
                  <th>Correct Answer</th>
                  <th>User Answer</th>
                  <th>O X</th>
                </tr>
              </thead>
              <tbody>
                {prevResult.map((result, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{result.word}</td>
                    <td> {result.answer}</td>
                    <td>{result.useranswer}</td>
                    <th>{(result.answer == result.useranswer) ? 'O' : 'X'}</th>
                  </tr>

                ))}
              </tbody>
            </Table>
          </div>

          <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
        </div>
      );

    } 
    else {
      const percentage = ((score / quizData.length) * 100);
      const remaining=100-percentage;
 const data = [{ name: 'Your score', score: percentage, total: remaining  }];
      return (

        <div>
          <h2>Quiz Result!</h2>
     <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
      <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
      <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart>

       
       <span className='score'>
          <div className="score-wrap">
         <span className="stars-active" style={{ width:`${percentage}%`}}>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
         </span>
         <span className="stars-inactive">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
        </span>
        </div>
      </span>
        
          <Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {quizData.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>

          <div className="quiz-results">
            <h3>Quiz Results:</h3>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Word</th>
                  <th>Correct Answer</th>
                  <th>User Answer</th>
                  <th>O X</th>
                </tr>
              </thead>
              <tbody>
                {quizData.map((question, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{question.word}</td>
                    <td> {question.answer}</td>
                    <td>{index < userAnswers.length ? userAnswers[index] : "Not answered"}</td>
                    <th>{(question.answer == userAnswers[index]) ? 'O' : 'X'}</th>
                  </tr>

                ))}
              </tbody>
            </Table>
          </div>

          <button className='quiz-button' onClick={handleRetakeQuiz}>Retake Quiz</button>
        </div>
      );
    }
  };



  return (
    <div className="quiz-container">
      <div className='quizs-container'> {renderQuizContent()} </div>
    </div>
  );
}
