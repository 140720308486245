import { useEffect , useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { homeworkActions, classesActions,studyActions  } from '_store';

import { history } from '_helpers';
import { Modal, Button } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from "axios";
const localizer = momentLocalizer(moment);
export { SpeakingHomeWork };

function SpeakingHomeWork() {
  const dispatch = useDispatch();
  const { classid,type } = useParams();
  const [showAssignHWDialog, setShowAssignHWDialog] = useState(false);
  const { classes } = useSelector((x) => x.classes);
  const { homework } = useSelector((x) => x.homework);
  const today = new Date().toISOString().slice(0, 10); 
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedOption, setSelectedOption] = useState('Free Response');
  const [textInput, setTextInput] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const { user: authUser } = useSelector((x) => x.auth);
  const [myEvents, setMyEvents] = useState([]);
  const { passage } = useSelector((x) => x.passage);
  const [showSearchResultDialog, setShowSearchResultDialog] = useState('');
  const [eventEdit, setEventEdit] = useState(false);
  const [eventTitle, setEventTitle] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [recordId, setRecordId] = useState('');
  const [homeworkList, setHomework] = useState([]);
  const [showEventResultDialog, setShowEventResultDialog] = useState('');

const year = new Date(today).getFullYear();
const month = new Date(today).getMonth(); // Months are 0-indexed, so add 1
const day = new Date(today).getDate();
console.log(year,'get today',month,day);
  const addObjectToArray = (id,title) => {
    const newObject = {
      id:id,
      title: title,
      start: new Date(year, month, day),
      end: new Date(year, month, day),
      type:type
    };

  

    setMyEvents(prevArray => [...prevArray, newObject]);
  
   
   
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    let backgroundColor = '';
    switch (event.type) {
      case 'Reading':
        backgroundColor = 'blue';
        break;
      case 'Speaking':
        backgroundColor = 'green';
        break;
      case 'Listening':
      backgroundColor = 'red';
      break;
      case 'Writing':
        backgroundColor = 'gray';
        break;
        case 'Grammar':
        backgroundColor = 'brown';
        break;
        case 'Vocab':
          backgroundColor = 'pink';
          break;
      default:
        backgroundColor = 'gray';
        break;
    }

    return { style: { backgroundColor } };
  };
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  console.log(myEvents);
  const handleOptionChange = (value) => {
console.log(value,'asfsfsf');
    setSelectedOption(value);
  };

  const handleTextareaChange = (e) => {
    setTextInput(e.target.value);
  };
  const handleResultChange = (text) => {
    setTextInput(text);
    setShowSearchResultDialog(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {;
    var classId = classid
    var class_id = classid;
    var type = 'Reading';
    dispatch(classesActions.getClassById({classId}));
    dispatch(homeworkActions.getHomeWorkById({class_id,type}));
    
 }, []);
 useEffect(() => {
if(homework && homework.data){
  const combinedHomeworkArray = homework.data.reduce((acc, item) => {
    const homeworkArrays = item.homeworkArray.map(array => array.homework);
    return acc.concat(...homeworkArrays);
  }, []);
   // Filter out homework with category 'Writing HW'
   const filteredHomeworkArray = combinedHomeworkArray.filter(homework => homework.category !== 'Writing HW');
    setHomework(filteredHomeworkArray);
}
}, [homework]);
useEffect(() => {
  if(homeworkList){
    const newArray = homeworkList.map(homework => {
      const { id, homework_text, type } = homework;
      console.log(year, month,day);
      return {
        id,
        title: homework_text,
        start: new Date(year, month, day),
        end: new Date(year, month, day),
        type
      };
    });
    setMyEvents(newArray);
  }
  }, [homeworkList]);

  console.log(myEvents,'get anja');
//  const combinedHomeworkArray = data.reduce((acc, item) => {
//   return acc.concat(item.homeworkArray[0].homework);
// }, []);

  const assignHomework = () => {
    setShowAssignHWDialog(true);
    setEventEdit(false);
  };
  const handleSubmit = async (e,type) => {
    e.preventDefault();
    var teacher_id = authUser.user.teacher_id;
    var class_id = classid;
    var homework_text = textInput;
    var type = type;
    
    setShowAssignHWDialog(false);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("teacher_id", teacher_id);
    formData.append("class_id", class_id);
    formData.append("homework_text", homework_text);
    if(selectedOption == 'Free Response'){
      formData.append("type", 'Speaking');
    }else{
      formData.append("type", 'Reading');
    }
    
    formData.append("category", selectedOption);
    
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/homework/create",
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json', // Make sure to set the content type based on your form data
        },
        }
    );
    if(response){
      setRecordId(response.data.data.id);
      addObjectToArray(response.data.data.id,homework_text);
    }
    //return dispatch(homeworkActions.assignHomework({ teacher_id, class_id,homework_text,type }));
  };
  const handleAssignHWCancel = () => {
    // Hide the dialog when cancel action is clicked
    setShowAssignHWDialog(false);
  };
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    var search = e.target.value
    return dispatch(studyActions.searchPassageFromDB({search}));
  };

  const handleSearchResultCancel = () => {
    // Hide the dialog when cancel action is clicked
    setShowSearchResultDialog(false);
  };
  const clickToShowResult = () => {
    // Hide the dialog when cancel action is clicked
    setShowSearchResultDialog(true);
  };
  const handleEventResultCancel = () => {
    // Hide the dialog when cancel action is clicked
    setShowEventResultDialog(false);
  };

  const handleEventClick = (event) => {
    setEventTitle('');
    setEventTitle(event.title);
    const eventId = event.id;
    
    setRecordId(eventId);
    const originalDate = new Date(event.start);
    // Format the date
    const formattedDate = moment(originalDate).format('DD-MMM-YYYY hh:mm A');
    setEventDate(formattedDate);
    setEventEdit(true);
    setShowEventResultDialog(true);

  };

  const editEvent = (id, updatedEvent) => {
    setMyEvents(prevEvents => prevEvents.map(event => (event.id === id ? updatedEvent : event)));
  };

 const editEventClick = () => {
  setShowAssignHWDialog(true);
  setShowEventResultDialog(false);
 }
  const editEventRecord = async () => {

    var homework_text = textInput;
    var homework_id = recordId;
   // addObjectToArray(recordId,homework_text);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("homework_text", homework_text);
    formData.append("category", selectedOption);
    
    const response = await axios.put(
      "https://api.webtracktechnologies.com.au:8002/homework/edit/"+homework_id,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json', // Make sure to set the content type based on your form data
        },
        }
    );
    if(response){

      setRecordId(response.data.data.id);
      setShowAssignHWDialog(false);
      const year = new Date(response.data.data.updatedAt).getFullYear();
      const month = new Date(response.data.data.updatedAt).getMonth(); // Months are 0-indexed, so add 1
      const day = new Date(response.data.data.updatedAt).getDate();
      //console.log(year,month,day);
      const newObject = {
        id:response.data.data.id,
        title: response.data.data.homework_text,
        start: new Date(year, month, day),
        end: new Date(year, month, day),
        type: response.data.data.type
      };
      editEvent(response.data.data.id,newObject);

    }

  };
  const removeEvent = async () => {
    const confirmed = window.confirm('Are you sure you want to delete this item?');
    setShowEventResultDialog(false);
    if (confirmed) {
      var id = recordId;
      // Code to delete the item
      const response = await axios.delete(
        "https://api.webtracktechnologies.com.au:8002/homework/delete/"+id,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json', // Make sure to set the content type based on your form data
          },
          }
      );
      if(response){
        setMyEvents(prevEvents => prevEvents.filter(event => event.id !== response.data.data));
      }
    }
  };
    return (

        <div className="grade-table-conatiner">
            <div className='header-title'>
                <h4 className='header-title'>Speaking Homework - {(classes && classes.data) ? classes.data.class_name : ''}</h4>
            </div>
            <button type="button" className="btn btn-success" onClick={() => assignHomework()}>Assign New HW</button>
            <div class="speak-view">
	<p><span class="blue"></span> Reading</p>
	<p><span class="green"></span> Speaking</p>
	<p><span class="red"></span> Listening</p>
	<p><span class="gray"></span> Writing</p>
	<p><span class="brown"></span> Grammar</p>
	<p><span class="pink"></span> Vocab</p>
</div>
<Modal show={showEventResultDialog} onHide={handleEventResultCancel}>
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div class="model-info" bis_skin_checked="1">
                      <span>Date :{eventDate}</span>
                      <span>Topic: {eventTitle}</span> 
                      <span>Subject : {type}</span>
                  </div>
                <div className="model-btn"><button type="button" className="btn btn-primary" onClick={() => editEventClick()}>Edit</button>
                <button type="button" className="btn btn-primary" onClick={() => removeEvent()}>Remove</button>
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleEventResultCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
                </Modal>
<Modal show={showSearchResultDialog} onHide={handleSearchResultCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Search Results</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {(passage && passage.data) ? passage.data.map((pass, index) => (
                <p onClick={()=>handleResultChange(pass.passage)}>{pass.passage}</p>
                )) : 'No Search Found.'}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSearchResultCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
                </Modal>
            <Modal show={showAssignHWDialog} onHide={handleAssignHWCancel}>
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={(e)=>handleSubmit(e,type)}>
                  {!eventEdit ?  <div>
        <label>Date</label>
        <input
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div> : ''}
     

      <div>
        <label>
          <input
            type="radio"
            name="category"
            value="Free Response"
            onClick={()=>handleOptionChange('Free Response')}
            checked
          />
          Free Response
        </label>
        <label>
          <input
            type="radio"
            value="Read Out Loud"
            name="category"
            checked={selectedOption === 'Read Out Loud'}
            onClick={()=>handleOptionChange('Read Out Loud')}
          />
          Read Out Loud
        </label>
        <input type="text" onChange={handleSearchTextChange} placeholder="Search.." name="search"/>
        <a href="#" onClick={clickToShowResult}>View Results</a>
      </div>

      <div>
        <label>Speaking Text</label>
        <textarea
          value={textInput || eventTitle}
          onChange={handleTextareaChange}
          rows="4"
          cols="50"
        />
      </div>

      <div>
        <label>MP3 File </label>
        <input
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={handleFileChange}
        />
      </div>

      {!eventEdit ? <button type="submit">Submit</button> : <button type="button" onClick={()=> editEventRecord() }>Edit</button>}
    </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleAssignHWCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
              </Modal>
  <div style={{ height: 500 }}>
      <Calendar
        localizer={localizer}
        events={myEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ margin: 'auto' }}
        onSelectEvent={handleEventClick}
        eventPropGetter={eventPropGetter}
      />
    </div>
      </div>
      
      
    )
}
