import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { VerifyEmail } from 'verifyemail';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'signup';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const signupActions = { ...slice.actions, ...extraActions };
export const signupReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        signup: signup(),
        verifyemail: verifyemail(),
    };    

    function signup() {
        return createAsyncThunk(
            `${name}/signup`,
            async ({ name, email, username, password }) => await fetchWrapper.post(`${baseUrl}/auth/register`, { name, email, username, password })
        );
    }

    function verifyemail() {
        return createAsyncThunk(
            `${name}/verifyemail`,
            async ({ token }) => await fetchWrapper.get(`${baseUrl}/auth/verify-email/`+token)
        );
    }

}

function createExtraReducers() {
    return {
        ...signup(),
        ...verifyemail(),
    };

    function signup() {
        var { pending, fulfilled, rejected } = extraActions.signup;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
                toast.success('Check your email to verify the account..', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                // get return url from location state or default to home page
                const { from } = { from: { pathname: '/verify-email' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function verifyemail() {
        var { pending, fulfilled, rejected } = extraActions.verifyemail;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                //state.user = user;
                toast.success('Your Email has been verified successfully.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                // get return url from location state or default to home page
                //const { from } = { from: { pathname: '/email-verified' } };
                const { from } = { from: { pathname: '/dashboard' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

}
