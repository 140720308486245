import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'reading';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const readingActions = { ...slice.actions, ...extraActions };
export const readingReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        readingaudiorecording: readingaudiorecording(),
        getReadingResultByRecordId : getReadingResultByRecordId(),
    };    

    function readingaudiorecording() {
        return createAsyncThunk(
            `${name}/reading`,
            async ({ recordId, text, file, type,sub_type,program_id, vocab_id , vocab_start,vocab_end,class_id }) => await fetchWrapper.post(`${baseUrl}/reading/send-file-to-reading-fluency`, { recordId,text,file , type,sub_type, program_id,vocab_id , vocab_start,vocab_end,class_id })
        );
    }

    function getReadingResultByRecordId() {
        return createAsyncThunk(
            `${name}/reading`,
            async ({ recordId }) => await fetchWrapper.get(`${baseUrl}/reading/result/`+recordId)
        );
    }


}

function createExtraReducers() {
    return {
        ...readingaudiorecording(),
        ...getReadingResultByRecordId(),
    };

    function readingaudiorecording() {
        var { pending, fulfilled, rejected } = extraActions.readingaudiorecording;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const reading = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                state.reading = reading;
                console.log(reading,'sds');
                // toast.success('Check your email to verify the account..', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     });
                // get return url from location state or default to home page
                // const { from } = { from: { pathname: '/verify-email' } };
                // history.navigate(from);
            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getReadingResultByRecordId() {
        var { pending, fulfilled, rejected } = extraActions.getReadingResultByRecordId;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const reading = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                state.reading = reading;
                console.log(reading,'sds');
                // toast.success('Check your email to verify the account..', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     });
                // get return url from location state or default to home page
                // const { from } = { from: { pathname: '/verify-email' } };
                // history.navigate(from);
            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }


}
