import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'vocabdiagnostic';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const vocabdiagnosticActions = { ...slice.actions, ...extraActions };
export const vocabdiagnosticReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        getVocabDiagnosticTest:getVocabDiagnosticTest(),
        getVocabDiagnosticResult:getVocabDiagnosticResult(),

    };    

    function getVocabDiagnosticTest() {
        return createAsyncThunk(
            `${name}/study`,
            async ({studentId, school, type, retest}) => await fetchWrapper.get(`${baseUrl}/vocabdiagnostic/quizdata/${studentId}?school=${school}&type=${type}&retest=${retest}`)
        );
    }
    function getVocabDiagnosticResult() {
        return createAsyncThunk(
            `${name}/study`,
            async ({studentId, school, type}) => await fetchWrapper.get(`${baseUrl}/vocabdiagnostic/checkresult/${studentId}?school=${school}&type=${type}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...getVocabDiagnosticTest(),
        ...getVocabDiagnosticResult(),
    };

    function getVocabDiagnosticTest() {
        var { pending, fulfilled, rejected } = extraActions.getVocabDiagnosticTest;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const vocabdiagnos = action.payload;
             
                state.vocabdiagnos = vocabdiagnos;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getVocabDiagnosticResult() {
        var { pending, fulfilled, rejected } = extraActions.getVocabDiagnosticResult;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const vocabdiagnos = action.payload;
             
                state.vocabdiagnos = vocabdiagnos;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

 
}
