import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { signupReducer } from './signup.slice';
import { commonReducer } from './common.slice';
import { studentReducer } from './student.slice';
import { classesReducer } from './classes.slice';
import { audioReducer } from './audio.slice';
import { myrecordingReducer } from './myrecording.slice';
import { fileReducer } from './file.slice';
import { singlefileReducer } from './singlefile.slice';
import { teacherReducer } from './teacher.slice';
import { studentrecordingsReducer } from './studentrecordings.slice';
import { homeworkReducer } from './homework.slice';
import { studyReducer } from './study.slice';
import { readingReducer } from './reading.slice';
import { gradeattendenceReducer } from './gradeattendence.slice';
import { quizReducer } from './quiz.slice';
import { vocabularyquizReducer } from './vocabularyquiz.slice';
import { quizresultReducer } from './quizresult.slice';
import { quizsettingReducer } from './quizsetting.slice';
import { quizcategoryReducer } from './quizcategory.slice';
import { libraryReducer } from './library.slice';
import { booksReducer } from './book.slice';
import { dictionaryReducer } from './dictionary.slice';
import { myvocabReducer } from './myvocab.slice';
import { reportsReducer } from './reports.slice';
import { vocabdiagnosticReducer } from './vocabdiagnostic.slice';
import { gamesReducer } from './games.slice';

export * from './auth.slice';
export * from './signup.slice';
export * from './common.slice';
export * from './student.slice';
export * from './classes.slice';
export * from './audio.slice';
export * from './myrecording.slice';
export * from './file.slice';
export * from './singlefile.slice';
export * from './teacher.slice';
export * from './studentrecordings.slice';
export * from './homework.slice';
export * from './study.slice';
export * from './reading.slice';
export * from './gradeattendence.slice';
export * from './quiz.slice';
export * from './vocabularyquiz.slice';
export * from './quizresult.slice';
export * from './quizsetting.slice';
export * from './quizcategory.slice';
export * from './library.slice';
export * from './book.slice';
export * from './dictionary.slice';
export * from './myvocab.slice';
export * from './reports.slice';
export * from './vocabdiagnostic.slice';
export * from './games.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        signup: signupReducer,
        student: studentReducer,
        classes: classesReducer,
        audio: audioReducer,
        myrecordings:myrecordingReducer,
        files:fileReducer,
        file:singlefileReducer,
        student_teacher_result:teacherReducer,
        studentRecordings:studentrecordingsReducer,
        homework: homeworkReducer,
        passage:studyReducer,
        reading:readingReducer,
        gradeattendence:gradeattendenceReducer,
         quiz: quizReducer,
        vocabularyquiz: vocabularyquizReducer,
         quizresult:quizresultReducer,
         quizsetting: quizsettingReducer,
        quizcategory:quizcategoryReducer,
        library:libraryReducer,
        bookHomework:booksReducer,
        dictionary : dictionaryReducer,
        myvocab : myvocabReducer,
        reports:reportsReducer,
        vocabdiagnos:vocabdiagnosticReducer,
        games:gamesReducer,

    },
});