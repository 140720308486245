import { useEffect , useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { homeworkActions, classesActions , gradeattendenceActions, fileActions} from '_store';
import axios from "axios";
import { history } from '_helpers';
import { Modal, Button } from 'react-bootstrap';

export { StudyTestAchievement };

function StudyTestAchievement() {
  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = useState(false);
  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [showContentDialog, setShowContentDialog] = useState(false);
  const { classid } = useParams();
  const [getType, setType] = useState(false);
  const [getTextValue, setTextValue] = useState('');
  const { user: authUser } = useSelector((x) => x.auth);
  const { files } = useSelector((x) => x.files);
  const [showLink, setShowLink] = useState(false);
  const today = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState(today);
  const [link, setLink] = useState('');
  const [totalQZ, setTotalQZ] = useState('');
  const [monthAttr, setMonthAttr] = useState(false);
  const [base64String, setBase64String] = useState(null);
  const [studentState, setStudentState] = useState({});
  const [showMonthlyDialog, setShowMonthlyDialog] = useState(false);
  const [showPrintQuizDialog, setShowPrintQuizDialog] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [readGrade, setReadGrade] = useState('');
  const [listGrade, setListGrade] = useState('');
  const [spekGrade, setSpekGrade] = useState('');
  const [writGrade, setWritGrade] = useState('');
  const [gramGrade, setGramGrade] = useState('');
  const [vocabGrade, setVocabGrade] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [contentType, setContentType] = useState('');
  const initialInputState = [];
const handleInputChange = (recordId, value) => {
  // Check if the recordId already exists in the array
  const existingRecordIndex = (inputValues.length > 0) ? inputValues.findIndex(item => item.record_id === recordId) : -1;

   // If the recordId exists, update the value; otherwise, add a new object to the array
   setInputValues(prevInputValues => {console.log(prevInputValues,'get previous');
    const newInputValues = [...prevInputValues];

    if (existingRecordIndex !== -1) {
      newInputValues[existingRecordIndex].value = value;
    } else {
      newInputValues.push({ record_id: recordId, value: value });
    }

    return newInputValues;
  });
};
  const showMonthlyStatLink = (studentId, date) => {

    const dateString = new Date(date).toISOString(); // Convert date to string
    
    const base64String = btoa(dateString); // Encode string to Base64

    return 'https://likestudy.webtracktechnologies.com.au/HomeWorkMail/MonthlyStats/'+studentId+'/'+base64String;
  };
  const convertDateToBase64 = (date) => {

    const dateString = new Date(date).toISOString(); // Convert date to string
    
    const base64String = btoa(dateString); // Encode string to Base64
   
    return base64String;
  };
  useEffect(() => {
    var classId = classid;
    var date = selectedDate;
    var teacher_id =authUser.user.teacher_id;
    var type= "Test";
    dispatch(fileActions.getAudioFileByType({ classId, date, teacher_id,type }));
  }, []);
  console.log(files,'sdfdf');

 const handleCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowDialog(false);
};
const handleAssignCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowAssignDialog(false);
};

const handlePrintQuizCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowPrintQuizDialog(false);
};
const printQuiz = () => {
  // Show the dialog when delete action is clicked
  setShowPrintQuizDialog(true);

};

const assignHomeworkType = (type) => {
  setType(type);
  setShowAssignDialog(true);
};

const speakingHomework = (type,buttonType) => {
  setType(type);
  if(buttonType == 'Speaking HW'){
    window.location.href = '/classroom/SpeakingHWList/'+classid+'/'+type;
  }else if(buttonType == 'Writing HW'){
    window.location.href = '/classroom/WritingHWList/'+classid+'/'+type;
  }
  
};

const getText = (e) => {
  setTextValue(e.target.value);
};

const submitHomeworkEmail = async (type) => {
 var teacher_id = authUser.user.teacher_id;
 var class_id = classid;
 var homework_email_text = getTextValue;
 var type = type;
 return dispatch(homeworkActions.assignHomework({ teacher_id, class_id,homework_email_text,type }));

};

const homeworkLink = () => {
  // Show the dialog when delete action is clicked
  setShowLink(true);
  const currentDate = selectedDate; // Get the current date
  const encodedString = convertDateToBase64(currentDate);
  setLink('https://likestudy.webtracktechnologies.com.au/HomeWorkMail/'+classid+'/'+encodedString);
};
const handleDateChange = (e) => {
  setSelectedDate(e.target.value);
  setMonthAttr(true);
  var classId = classid;
  var date = e.target.value;
  var teacher_id =authUser.user.teacher_id;
  var type= "Test";
  return dispatch(fileActions.getAudioFileByType({ classId, date, teacher_id,type }));

};
useEffect(() => {
  // Set initial values based on dynamic data when it changes
  if (files && files.data.Records) {
    setInputValues(prevInputValues => {
      const newInputValues = [...prevInputValues];

      files.data.Records.forEach((file) => {
        if (file.data['Reading Multiple Choice']) {
          const name = `${file.data['Reading Multiple Choice'][0].id}`;
          const existingRecordIndex = newInputValues.findIndex(item => item.record_id === name);

          if (existingRecordIndex !== -1) {
            newInputValues[existingRecordIndex].value = file.data['Reading Multiple Choice'][0].total_score || '0';
          } else {
            newInputValues.push({ record_id: name, value: file.data['Reading Multiple Choice'][0].total_score || '0' });
          }
        } else if (file.data['Listening Multiple Choice']) {
          const name = `${file.data['Listening Multiple Choice'][0].id}`;
          const existingRecordIndex = newInputValues.findIndex(item => item.record_id === name);

          if (existingRecordIndex !== -1) {
            newInputValues[existingRecordIndex].value = file.data['Listening Multiple Choice'][0].total_score || '0';
          } else {
            newInputValues.push({ record_id: name, value: file.data['Listening Multiple Choice'][0].total_score || '0' });
          }
        } else if (file.data['Read Out Loud']) {
          const name = `${file.data['Read Out Loud'][0].id}`;
          const existingRecordIndex = newInputValues.findIndex(item => item.record_id === name);

          if (existingRecordIndex !== -1) {
            newInputValues[existingRecordIndex].value = file.data['Read Out Loud'][0].total_score || '0';
          } else {
            newInputValues.push({ record_id: name, value: file.data['Read Out Loud'][0].total_score || '0' });
          }
        }

        // Add similar code for other sections
      });

      return newInputValues;
    });
  }
}, [files]);

const copyToClipboard = () => {
  const linkElement = document.getElementById('qlink');
  const range = document.createRange();
  range.selectNode(linkElement);
  window.getSelection().addRange(range);

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    alert('Copied!');
  } catch (err) {
    console.error('Unable to copy: ', err);
  }

  window.getSelection().removeAllRanges();
};
const copyToClipboardReport = (studentId) => {
  const linkElement = document.getElementById('reportLink-'+studentId);
  const range = document.createRange();
  range.selectNode(linkElement);
  window.getSelection().addRange(range);

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    alert('Copied!');
  } catch (err) {
    console.error('Unable to copy: ', err);
  }

  window.getSelection().removeAllRanges();
};
const saveScoreForTests = async () => {

 var option = { scores: inputValues};
 const resultRes = await axios.post(
  "https://api.webtracktechnologies.com.au:8002/file/updateScore",
  option,
  {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      // Add other headers if needed
    },
  }
);
if(resultRes){

  alert(resultRes.data.message);
  //window.location.href = '/classroom/study-test';
}
};

const handlePrint = () => {
  const newWindow = window.open('', '_blank');
  newWindow.document.write('<html><head><title>Print</title></head><body>');
  newWindow.document.write('<h1>Content to Print</h1>');
  newWindow.document.write('</body></html>');
  newWindow.document.close();
  newWindow.print();
};


const handleContentCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowContentDialog(false);
};
const getStudentName = (data) => {
    if(data['Listening Multiple Choice'] && data['Listening Multiple Choice'][0].Student)
    {
      //console.log(data['Listening Multiple Choice'],'get list');return false;
      var name = data['Listening Multiple Choice'][0].Student.firstName+' '+data['Listening Multiple Choice'][0].Student.lastName;
    }else if(data['Reading Multiple Choice'] && data['Reading Multiple Choice'][0].Student){
      var name = data['Reading Multiple Choice'][0].Student.firstName+' '+data['Reading Multiple Choice'][0].Student.lastName;
    }else if(data['Read Out Loud'] && data['Read Out Loud'][0].Student){
      var name = data['Read Out Loud'][0].Student.firstName+' '+data['Read Out Loud'][0].Student.lastName;
    }
    return name;
};
const getInputValue = (recordId) => {
  const inputObject = inputValues.find(item => item.record_id === recordId);
  return inputObject ? inputObject.value : '';
};
const resetToDefaults = () => {
  if (files && files.data.Records) {
    setInputValues(prevInputValues => {
      const newInputValues = [...prevInputValues];

      files.data.Records.forEach((file) => {
        if (file.data['Reading Multiple Choice']) {
          const name = `${file.data['Reading Multiple Choice'][0].id}`;
          const existingRecordIndex = newInputValues.findIndex(item => item.record_id === name);

          if (existingRecordIndex !== -1) {
            newInputValues[existingRecordIndex].value = file.data['Reading Multiple Choice'][0].total_score || '0';
          } else {
            newInputValues.push({ record_id: name, value: file.data['Reading Multiple Choice'][0].total_score || '0' });
          }
        } else if (file.data['Listening Multiple Choice']) {
          const name = `${file.data['Listening Multiple Choice'][0].id}`;
          const existingRecordIndex = newInputValues.findIndex(item => item.record_id === name);

          if (existingRecordIndex !== -1) {
            newInputValues[existingRecordIndex].value = file.data['Listening Multiple Choice'][0].total_score || '0';
          } else {
            newInputValues.push({ record_id: name, value: file.data['Listening Multiple Choice'][0].total_score || '0' });
          }
        } else if (file.data['Read Out Loud']) {
          const name = `${file.data['Read Out Loud'][0].id}`;
          const existingRecordIndex = newInputValues.findIndex(item => item.record_id === name);

          if (existingRecordIndex !== -1) {
            newInputValues[existingRecordIndex].value = file.data['Read Out Loud'][0].total_score || '0';
          } else {
            newInputValues.push({ record_id: name, value: file.data['Read Out Loud'][0].total_score || '0' });
          }
        }

        // Add similar code for other sections
      });

      return newInputValues;
    });
  }

};
console.log(files,'get files');
    return (

        <div className="grade-table-conatiner">
            <h2 className="page-header">{(files && files.data.Class) ? files.data.Class.class_name : 'TEST'}</h2>
        <div className="table-head">
          <label htmlFor="birthday">Date:</label>
          <input value={selectedDate} onChange={handleDateChange} type="date" id="birthday" name="birthday" />
        
          <button className="btn green" onClick={() => saveScoreForTests()} >Save</button>
          <a href="/classroom/study-test">TEST</a>
        </div>
        
        <div className="grade-table">
          <table>
            <thead>
              <tr>
                <th>
                  Name
                </th>
                <th>
                  Test Title
                </th>
                <th>
                  Level
                </th>
                <th>
                  RC
                </th>
                <th>
                LC
                </th>
                <th>
                SP
                </th>
                <th>
                WR
                </th>
                <th>
                GR
                </th>
                <th>
                VC
                </th>
                <th>
                Total
                </th>
              </tr>
              
            </thead>
            <tbody>
  {files?.data?.Records ? files.data.Records.map((file, index) => ( 
    <tr key={index}>
      <td>{getStudentName(file.data)}</td>
      <td>
        {(file.data['Reading Multiple Choice']?.length > 0 && file.data['Reading Multiple Choice'][0]?.test?.test_title) 
        ? file.data['Reading Multiple Choice'][0].test.test_title 
        : (file.data['Read Out Loud']?.length > 0 && file.data['Read Out Loud'][0]?.studyprogram?.categorytitle) 
        ? file.data['Read Out Loud'][0].studyprogram.categorytitle 
        : ''}
      </td>
      <td>
        {(file.data['Reading Multiple Choice']?.length > 0 && file.data['Reading Multiple Choice'][0]?.test?.level) 
        ? file.data['Reading Multiple Choice'][0].test.level 
        : ''}
      </td>
      <td>
        {file.data['Reading Multiple Choice']?.length > 0 ? (
          <input
            type="text"
            name={'RC_' + file.data['Reading Multiple Choice'][0].id}
            value={getInputValue(`${file.data['Reading Multiple Choice'][0].id}`)}
            onChange={(e) =>
              handleInputChange(`${file.data['Reading Multiple Choice'][0].id}`, e.target.value)
            }
          />
        ) : 0}
      </td>
      <td>
        {file.data['Listening Multiple Choice']?.length > 0 ? (
          <input
            type="text"
            name={'LC_' + file.data['Listening Multiple Choice'][0].id}
            value={getInputValue(`${file.data['Listening Multiple Choice'][0].id}`)}
            onChange={(e) =>
              handleInputChange(`${file.data['Listening Multiple Choice'][0].id}`, e.target.value)
            }
          />
        ) : 0}
      </td>
      <td>
        {file.data['Read Out Loud']?.length > 0 ? (
          <input
            type="text"
            name={'read_out_loud_' + file.data['Read Out Loud'][0].id}
            value={getInputValue(`${file.data['Read Out Loud'][0].id}`)}
            onChange={(e) =>
              handleInputChange(`${file.data['Read Out Loud'][0].id}`, e.target.value)
            }
          />
        ) : 0}
      </td>
      <td>0</td>
      <td>0</td>
      <td>0</td>
      <td>
  <input
    type="text"
    name="total"
    value={getInputValue(
      `${file.data['Reading Multiple Choice']?.[0]?.id ?? file.data['Read Out Loud']?.[0]?.id ?? ''}`
    )}
    onChange={(e) =>
      handleInputChange(
        `${file.data['Reading Multiple Choice']?.[0]?.id ?? file.data['Read Out Loud']?.[0]?.id ?? ''}`,
        e.target.value
      )
    }
  />
</td>

    </tr>
  )) : ''}
</tbody>

          </table>
        
          <button className="btn green" onClick={() => saveScoreForTests()} >Save</button>
          <button className="btn green" onClick={resetToDefaults}>Reset to Defaults</button>
       
         
        </div>
        <Modal show={showAssignDialog} onHide={handleAssignCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Homework Assignment {getType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <button type="button" className="btn btn-success">My Vocab HW</button>&nbsp;
                <button type="button" className="btn btn-success"
                  onClick={() => speakingHomework(getType,'Speaking HW')}>Speaking HW</button>&nbsp;
                <button type="button" className="btn btn-success"
                 onClick={() => speakingHomework(getType,'Writing HW')}>Writing HW</button>&nbsp;
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleAssignCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
              </Modal>
              <Modal show={showPrintQuizDialog} onHide={handlePrintQuizCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Print Quiz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="toggle-group">
      <label>
        <input type="checkbox" name="toggle" value="random-order" />
        <span className="slider round"></span>
        
      </label>
      Random Order
      <label>
        <input type="checkbox" name="toggle" value="wordmeaning" />
        <span className="slider round"></span>
       
      </label>
      Word/Meaning   
    </div>
                <button type="button" className="btn btn-success" onClick={()=>handlePrint()}>Questions and Answers</button>&nbsp;
                <button type="button" className="btn btn-success" onClick={()=>handlePrint()}>Questions</button>&nbsp;
                
                </Modal.Body>
                <Modal.Footer className='text-right'>
                  <Button variant="secondary" onClick={handlePrintQuizCancel}>
                    Close
                  </Button>
                 
                </Modal.Footer>
              </Modal>
        <Modal show={showDialog} onHide={handleCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Homework Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='row'>
                    <div className='date'>
                    {new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
                    </div>
                  <table striped bordered hover>
  <tr>
    <td onClick={() => assignHomeworkType('Reading')}>R</td>
    <td><textarea onChange={getText}></textarea></td>
    <td><button onClick={() => submitHomeworkEmail('Reading')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Listening')}>L</td>
    <td><textarea onChange={getText}></textarea></td>
    <td><button onClick={() => submitHomeworkEmail('Listening')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Speaking')}>S</td>
    <td><textarea onChange={getText}></textarea></td>
    <td><button onClick={() => submitHomeworkEmail('Speaking')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Writing')}>W</td>
    <td><textarea onChange={getText}></textarea></td>
    <td><button onClick={() => submitHomeworkEmail('Writing')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Grammar')}>G</td>
    <td><textarea onChange={getText}></textarea></td>
    <td><button onClick={() => submitHomeworkEmail('Grammar')}>S</button></td>
  </tr>
  <tr>
    <td onClick={() => assignHomeworkType('Vocabulary')}>V</td>
    <td><textarea></textarea></td>
    <td><button onClick={() => submitHomeworkEmail('Vocabulary')}>S</button></td>
  </tr>
  <tr>
    <td>O</td>
    <td><textarea onChange={getText}></textarea></td>
    <td><button>S</button></td>
  </tr>
  <tr>
    <td><a href={`/homework/${classid}/${selectedDate}`}>LB</a></td>
    <td></td>
    <td></td>
  </tr>
</table>
                  </div>
                </Modal.Body>
                <Modal.Footer className='text-right'>
                  <Button variant="secondary" onClick={handleCancel}>
                    Close
                  </Button>
                  {/* <Button variant="danger" onClick={() => handleConfirmDelete(recording.id)}>
                    Save
                  </Button> */}
                </Modal.Footer>
              </Modal>

    
              <Modal show={showContentDialog} onHide={handleContentCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Homework Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='row'>
                    {/* <div className='date'>
                    {new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
                    </div> */}
                  <table striped bordered hover>
                    <tr>
                      <td onClick={() => assignHomeworkType(contentType)}>{modalContent}</td>
                      <td><textarea onChange={getText}></textarea></td>
                      <td><button onClick={() => submitHomeworkEmail(contentType)}>S</button></td>
                    </tr>
                  </table>
                  </div>
                </Modal.Body>
                <Modal.Footer className='text-right'>
                  <Button variant="secondary" onClick={handleContentCancel}>
                    Close
                  </Button>
                  {/* <Button variant="danger" onClick={() => handleConfirmDelete(recording.id)}>
                    Save
                  </Button> */}
                </Modal.Footer>
              </Modal>
      </div>
      
      
    )
}
const GradeButton = ({ subject, grades, onGradeChange, initialValue }) => {

  const [currentGrade, setCurrentGrade] = useState(grades.indexOf(initialValue));
  const [colorBg, setColorBg] = useState('');
  const [color, setColor] = useState('');

  const handleClick = () => {
    setCurrentGrade(prev => (prev + 1) % grades.length);
    const newGrade = grades[(currentGrade + 1) % grades.length];
    onGradeChange(newGrade);

    // Check conditions for red background
    if ((currentGrade === 2 || currentGrade === 3) && (newGrade === 'O-red' || newGrade === 'X-red')) {
      setColorBg('red');
      setColor('white');
    } else {
      setColorBg(''); // Remove red background
      setColor('');
    }
  };

  useEffect(() => {
    if(initialValue === 'O-red' || initialValue === 'X-red'){
      setColorBg('red');
      setColor('white');
    }else{
      setColorBg(''); // Remove red background
      setColor('');
    }
  }, [initialValue]);

  const displayGrade = grades[currentGrade].replace('-red', '');
  return (

      <input type="button" style={{ background: colorBg,color:color }} value={displayGrade} className="btn green" onClick={handleClick} />

  );
};
