import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { gamesActions } from '_store';
import { useLocation } from 'react-router-dom';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export function BoxGame() {
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);
  const [limit, setLimit] = useState(0);
  const [giftValue, setGiftValue] = useState(0);
  const { user: authUser } = useSelector((x) => x.auth);
  const { games } = useSelector((x) => x.games);
  const [flipping, setFlipping] = useState(false);
  const [giftBoxes, setGiftBoxes] = useState([]);
  const [anyBoxClicked, setAnyBoxClicked] = useState(false);
  const [shakingBox, setShakingBox] = useState(null);
  const query = useQuery();
  const type = query.get('type');
  const [Box, setBox] = useState('S');

  const generateRandomBoxes = () => {
    const minBoxes = 4;
    const maxBoxes = 8;
    const numBoxes = Math.floor(Math.random() * (maxBoxes - minBoxes + 1)) + minBoxes;
    return Array.from({ length: numBoxes }, () => ({ value: null, clicked: false }));
  };

  useEffect(() => {
    
    if (!games) {
      setLimit(1);
      
      if(type){
        setBox(type);
        checkLimit(type);
      }else{
        checkLimit(1);
      }
    } else {
      if (games.data === 0) {
        setLimit(2);
      } else {
      
        if (giftBoxes.length === 0) {
          if(games.data.giftboxesl > 0){
            setBox('L')
          }else if(games.data.giftboxesm > 0){
            setBox('M')
          }else{
            setBox('S');
          }
          setGiftBoxes(generateRandomBoxes());
        }
        setLimit(1);
      }
    }
  }, [games, giftBoxes]);

  const checkLimit = async (type) => {
    if (authUser?.user?.Student) {
      const student_id = authUser.user.Student.id;
      const response = await dispatch(gamesActions.getgiftboxlimit({ student_id ,type}));
      if (response.limitReached) {
        alert('Limit expires');
      }
    }
  };

  const handleBoxClick = (index) => {
    if (anyBoxClicked || giftBoxes[index].clicked) return;

    const loseSound = new Audio('/sounds/lose.wav');
    const wingoldSound = new Audio('/sounds/wingold.wav');
    const winSound = new Audio('/sounds/win.wav');
    const opensound = new Audio('/sounds/openbox.wav');

    setShakingBox(index);
    opensound.play();
    setTimeout(() => {
      setIsOpened(index);
    }, 700);
    setTimeout(() => {

      setFlipping(true);
        
      const values = (Box=='L')?[0,100,300,1000,2000,4000,6000]:(Box=='M')?[0,10,30,100,200,400,600]:[0,1,3,10,20,40,60];
      const probabilities = [0.1951,0.25,0.3198,0.161,0.0593,0.0099,0.005];

      const getRandomValue = () => {
        const cumulativeProbabilities = probabilities.map(
          (sum => value => sum += value)(0)
        );
        const random = Math.random();
        for (let i = 0; i < cumulativeProbabilities.length; i++) {
          if (random < cumulativeProbabilities[i]) {
            return values[i];
          }
        }
      };

      const randomValue = getRandomValue();
      setGiftValue(randomValue);
      setAnyBoxClicked(true);
      setFlipping(false);

      if (randomValue === 0) {
        loseSound.play();
      } else if (randomValue > 6) {
        winSound.play();
      } else {
        wingoldSound.play();
      }

      if (authUser?.user?.Student) {
        const student_id = authUser.user.Student.id;
        const amount = randomValue;
        const type = 'gift box';
        const boxtype=Box;
        dispatch(gamesActions.saveRewards({ student_id, amount, type , boxtype }));
      }

      setShakingBox(null);
    }, 1500);
  };


  const getGiftboxColorValue = (arr) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  };

  const boxColors = ['#1eccc2', '#000ff', '#01212f', '#6ac633d6', '#2a86cbe3', '#d81abcab', '#d88f1acf', '#809468', '#94688c', '#687a94'];

  const handlecloseClick = () => {
    window.location.reload();
  };

  return (
    <div onClick={anyBoxClicked ? () => window.location.reload() : null}>
      <div style={{ display: 'flex', padding: '20px', fontWeight: 600, fontSize: '40px', marginBottom: '90px', color: 'rgb(0 103 255 / 71%)' }}>
        <span>Gift Boxes</span>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', padding: '58px', width: '660px', gap: '50px', border: '2px solid', top: '-70px', position: 'relative', borderRadius: '14px', paddingBottom: '0px', paddingTop: '116px' }} className={`${anyBoxClicked ? 'listboxes' : ''}`}>

        {limit === 1 ? giftBoxes.map((box, index) => {
          const giftBoxColor = getGiftboxColorValue(boxColors);
          const boxStyles = {
            backgroundColor: giftBoxColor,
            ...(Box === 'M' ? { width: '112px', height: '100px' } : Box === 'L' ? { width: '118px', height: '110px' } : {})
          };
          const boxcontainer = { ...(Box === 'M' ? { marginBottom: '40px' } : { marginBottom: '20px' }) }
          const boxlidstyle = { ...(Box === 'M' ? { backgroundColor: giftBoxColor, height: '35px', width: '70px', bottom: '102%' } : { backgroundColor: giftBoxColor, height: '30px', bottom: '102%' }) }
          return (
            <div key={index} className="box-container" style={boxcontainer}>
              <div className={`boxes ${shakingBox === index ? 'shake' : ''}`} style={{ height: '80px', width: '100px', marginBottom: '40px' }} onClick={() => handleBoxClick(index)}>
                <div className={`box-body ${isOpened === index ? 'opened' : ''}`} style={boxStyles}>
                  <div className={`box-lid ${isOpened === index ? 'opened' : ''} boxlid-m`} style={boxlidstyle}>

                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 2048 810" width="200" height="40" style={{ position: 'relative', top: '-39px', right: '38px' }}>
                      <path transform="translate(752,44)" d="m0 0h26l21 3 21 6 21 9 18 11 14 11 12 11 15 15 9 11 12 16 13 20 13 23 10 19 13 29 14 36 13 38 14 49 11 45 10 48 8 44 8 51 2 14 9-59 9-51 12-56 13-51 13-43 11-32 11-28 12-27 10-21 13-23 12-19 10-14 8-10 12-14 7-8 8-7 14-12 15-10 14-8 20-8 13-4 16-3 9-1h26l22 3 20 5 20 7 24 11 18 10 16 11 14 11 15 14 9 11 8 11 9 16 8 20 4 17 2 16v24l-4 27-6 22-8 22-11 24-13 24-15 24-14 20-12 16-11 14-9 11-11 13-7 8-9 10-7 8-12 13-14 14-7 8-12 12-8 7-16 16-8 7-7 7-8 7-12 11-11 9-13 12-11 9-14 12-22 18-16 13-13 10-18 14-21 16-19 14-4 3 12-3 52-17 45-17 38-16 36-16 38-18 44-22 22-12 21-11 23-13 25-14 59-35 60-38 32-21 12-8 28-19 16-11 23-16 17-12 1 2-19 40-13 28-18 38-9 20 90 1 47 1-4 3-40 20-17 9-23 11-22 11-28 13-29 14-33 15-45 20-40 17-44 18-41 16-49 18-56 19-42 13-43 12-49 12-45 9-40 6-30 3-17 1h-55l-31-2-39-5-40-7-49-11-42-11-44-13-55-18-43-15-50-19-50-20-54-23-45-20-54-25-33-16-23-11-86-43v-1l47-1 90-1-13-28-17-36-28-60-1-4 7 4 18 13 32 22 25 17 21 14 23 15 25 16 19 12 26 16 32 19 24 14 28 16 24 13 22 12 35 18 32 16 28 13 40 18 47 19 38 14 36 12 22 6-16-12-21-16-17-13-14-11-16-13-20-16-13-11-11-9-30-26-12-11-8-7-15-14-16-15-49-49-7-8-15-16-9-11-9-10-20-25-14-19-14-20-13-21-14-25-10-21-9-24-6-21-4-21-1-11v-23l3-21 5-18 8-18 9-15 11-14 11-12 10-9 16-12 19-12 21-11 25-10 20-6 21-4zm7 139-15 4-14 7-12 9-9 10-7 12-4 12-2 13v15l3 21 6 21 8 21 12 25 9 17 15 25 10 15 14 20 14 19 14 18 13 16 9 11 13 15 9 10 7 8 22 24 7 8h2l2 4 34 34 8 7 10 10 8 7 13 12 11 9 10 9 14 11 17 13h2l-3-29-6-36-9-43-12-48-13-44-15-46-15-40-13-31-12-26-9-19-12-22-12-20-10-15-14-19-9-10-7-8-8-8-14-11-15-9-15-6-9-2zm561 0-15 4-16 8-12 8-10 9-8 7-12 13-13 17-11 16-13 21-12 22-18 36-15 35-13 34-12 34-11 34-13 46-11 44-9 44-6 38-2 22 5-2 14-11 17-14 14-12 11-10 8-7 12-11 10-10 8-7 28-28 7-8 14-14 7-8 11-12 9-11 11-12 9-11 14-17 13-17 15-20 14-20 15-23 13-22 14-27 9-20 9-27 4-19 1-10v-15l-3-17-6-14-8-11-7-7-14-9-13-6-12-3z" fill={giftBoxColor} />
                    </svg>
                  </div>

                </div>
              </div>
            </div>
          );
        }) : (limit === 2) ? (
          <div className='no-gift-msg'>
            <span>Sorry, no gift boxes available</span>
          </div>
        ) : (
          <div className='spinner'></div>
        )}
      </div>

      {anyBoxClicked && (
        <div className={`rewardshow`} style={giftValue === 0 ? { height: '140px' } : {}}>
          <button className={'rewardshowclosebutton'} onClick={handlecloseClick}></button>
          <div className="coin-message" style={{top:'-80px',position:'relative'}}>
             
            {giftValue === 0 ? (
              <p>
                You won <span style={{ color: 'red' }}>{giftValue}</span>
                {/* <span style={{ color: 'red' }}>&#162;</span> */}
                <span style={{ color: 'red', textTransform: 'lowercase' }}>C</span>
                <br />
                {/* <span>Better luck next time</span> */}
              </p>
            ) : (
              <>
                <p style={{ position: 'relative', top: '-20px', color: 'red', left: '20%' }}>
                  Congratulations! <span style={{ position: 'relative', display: 'flex', left: '11%' }}>You won </span></p>
                <div className="bg">
                  <div className="icon">
                    <img src={`/images/coin${giftValue}.png`} alt="Coin" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
