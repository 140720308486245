import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { signupActions } from '_store';

export { VerifyEmail };

function VerifyEmail() {
    const dispatch = useDispatch();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    //const token = params.get('token');
    const token = localStorage.getItem('token');
console.log(token,'get token');
    useEffect(() => {
        // redirect to home if already logged in
       dispatch(signupActions.verifyemail({token}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (

      <section className="custom-sign up signup-email">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 sign-left-bg email-bg" style={{backgroundImage: 'url(images/signup-2-img.webp)'}}>
              <div className="custom-sign-left" />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="sign-in-content signup-email-right" />
            </div>
            <div className="signin-form signup-form signup-email-content">
              <h3>VERIFY YOUR EMAIL</h3>
              <p className="custom-para">To complete your registration process, please confirm your email address. An
                email verification link has been sent to your registered email. Please click on the link to
                complete the verification process. </p>
            </div>
          </div>
        </div>
      </section>

    )
}
