import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { classesActions , myvocabActions} from '_store';
import { Modal, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useParams } from "react-router-dom";

export { MyOwnVocabList };

function MyOwnVocabList() {
  const dispatch = useDispatch();
  const { classes } = useSelector((x) => x.classes);
  const { myvocab } = useSelector((x) => x.myvocab);
  const { classid , type} = useParams();
  const { user: authUser } = useSelector((x) => x.auth);

  const [inputValue, setInputValue] = useState("15");
  const [radioValue, setRadioValue] = useState(1);
  const [wordsValue, setWordsValue] = useState("EKTE");
  const [showDialog, setShowDialog] = useState(false);
  const [showSettingDialog, setShowSettingDialog] = useState(false);
  const [soundValue, setSoundValue] = useState(1);
  const [selectedVocabId, setSelectedVocabId] = useState(null);
  console.log('get class', myvocab);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    var classId = classid;
    dispatch(classesActions.getClassById({ classId }));
    var teacher_id = authUser.user.teacher_id;
    dispatch(myvocabActions.getAllOwnVocab({teacher_id}));

 }, []);

const handleDelete = () => {
  // Show the dialog when delete action is clicked
  setShowDialog(true);
};

const handleCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowDialog(false);
};

const handleConfirmDelete = async(vocab_id) => {
  // Handle the deletion here
  console.log('Deleting...', vocab_id);
 
   // Hide the dialog after deletion
  setShowDialog(false);
   // Call your deletePlan function here
   const response1 = await axios.delete(
    "https://api.webtracktechnologies.com.au:8002/myvocab/delete/"+vocab_id,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // Add other headers if needed
      },
    }
  );
  
  if(response1){
    toast.success('Vocab deleted successfully.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  
   // window.location.href='/dictionary/MyOwnVocabQuizList';
  }

};
const getRadioValueForTimer = (text) => {
  // Handle form submission here
    setRadioValue(text);
};

const getRadioValueForWords = (text) => {
  // Handle form submission here
  setWordsValue(text);
};
const handleSettingCancel = () => {
  // Hide the dialog when cancel action is clicked
  setShowSettingDialog(false);
};

const handleSetting = (vocab_id) => {
  // Show the dialog when delete action is clicked
  // Find the vocab item by ID
  const vocab = myvocab.data.find(vocab => vocab.id === vocab_id);
console.log(vocab,'get set');
  if (vocab && vocab.myvocabsettings) {
    const settings = vocab.myvocabsettings;
    
    // Populate the modal with these settings
    setSelectedVocabId(vocab_id);
    setInputValue(settings.time.toString()); // Populate timer value
    setRadioValue(settings.istime); // Populate timer on/off
    setWordsValue(settings.option_setting); // Populate word option
    setSoundValue(settings.sound); // Populate sound on/off
  }
  setSelectedVocabId(vocab_id);
  setShowSettingDialog(true);
};
const handleSettingSubmit = async() => {
  // Handle the deletion here
  //console.log('Deleting...', vocab_id);
  const vocab_id = selectedVocabId; 
 
   // Hide the dialog after deletion
   setShowSettingDialog(false);
   const option = {
    my_vocab_id: vocab_id,
    student_id: authUser.user.student_id,
    teacher_id: authUser.user.teacher_id,
    istime: radioValue,
    time: inputValue,
    sound:soundValue,
    option_setting:wordsValue,
  };
const response1 = await axios.post(
  "https://api.webtracktechnologies.com.au:8002/myvocab/createsettings",
  option,
  {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      // Add other headers if needed
    },
  }
);

if(response1){
  toast.success('Vocab settings updated successfully.', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

 // window.location.href='/dictionary/MyOwnVocabQuizList';
}
};



const handleFileChange = async (e) => {
  if (!e.target.files[0]) {
    toast.error('No file selected.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return;
  }
  const today = new Date().toISOString().slice(0, 10);
  const formData = new FormData();
  formData.append('file', e.target.files[0]);
  formData.append('teacher_id', authUser.user.teacher_id);
  formData.append('date', today);
  formData.append('class_id', classid);
  formData.append('type', type);

  try {
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/myvocab/createfilevocab",
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (response) {
      toast.success('File uploaded successfully.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Refresh the vocab list
      var teacher_id = authUser.user.teacher_id;
      dispatch(myvocabActions.getAllOwnVocab({ teacher_id }));
    }
  } catch (error) {
    toast.error('Error uploading file.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};
const getRadioValueForSound = (text) => {
  // Handle form submission here
    setSoundValue(text);
};
    return (

      <section className="custom-sign own-vocab">
      <div className="container-fluid">
      <div className="card-header">
			<p>MY VOCAB - {(classes && classes.data) ? classes.data.class_name: ''}</p>
		  </div>
      <a href={`/classroom/MyOwnVocab/${classid}/${type}`}><div class="v-info-box">
    <p class="title">New File</p>
    <input type="file" onChange={handleFileChange} />
    </div></a>
    {(myvocab && myvocab.data) ? myvocab.data.map((vocab, index) => (
    <div class="v-info-box">
    <p class="title"><a href={`/dictionary/MyOwnVocab/${vocab.id}`}>{vocab.vocabname}</a></p>
    <p class="sub-title">Homework - {type}</p>
    <a href={`/dictionary/MyOwnVocab/${vocab.id}`}><img src="/images/text_book_vac_one.png" style={{width:'15%'}} /></a>
    <p class="sub-title">{vocab.date}</p>
    <p class="edit"><a href={`/dictionary/EditOwnVocabList/${vocab.id}`}><i class="fa fa-edit"></i></a> 
    <a href="#" onClick={() => handleDelete()}><i class="fa fa-trash"></i></a>
      <i class="fa fa-cog"   onClick={() => handleSetting(vocab.id)}></i>
      </p>
      <Modal show={showSettingDialog} onHide={handleSettingCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                <label htmlFor="inputField">Timer:</label>
                  <input type="radio" id="meaning1" name="timer" value="1" onClick={() => getRadioValueForTimer(1)} checked={radioValue === 1}/>
                  <label for="meaning1">Yes</label>
              
                  <input type="radio" id="korean_word1" name="timer" value="0" onClick={() => getRadioValueForTimer(0)} checked={radioValue === 0} />
                  <label for="korean_word1">No</label>
      
         </div>
      <div>
          <label htmlFor="inputField">Enter Timer Value (seconds):</label>
          <input
            type="number"
            id="inputField"
            value={inputValue}
            onChange={handleInputChange}
            required
          />
          </div>
                <div>
           <input type="radio" id="meaning12" name="english_korean" value="English & Korean to English" onClick={() => getRadioValueForWords('EKTE')} checked={wordsValue === 'EKTE'}/>
           <label for="meaning12">English & Korean to English</label>
       
           <input type="radio" id="korean_word12" name="english_korean" value="English to English" onClick={() => getRadioValueForWords('ETE')} checked={wordsValue === 'ETE'}/>
           <label for="korean_word12">English to English</label>
           <input type="radio" id="korean_word123" name="english_korean" value="English to Korean" onClick={() => getRadioValueForWords('ETK')} checked={wordsValue === 'ETK'} />
           <label for="korean_word123">English to Korean</label>
           <input type="radio" id="meaning_and_korean12" name="english_korean" value="Korean to English" onClick={() => getRadioValueForWords('KTE')} checked={wordsValue === 'KTE'} />
           <label for="meaning_and_korean12">Korean to English</label>
         </div>
         <div>
                <label htmlFor="inputField">Sound:</label>
                  <input type="radio" id="sound1" name="sound" value="1" onClick={() => getRadioValueForSound(1)} checked={soundValue === 1}/>
                  <label for="sound1">Yes</label>
              
                  <input type="radio" id="sound2" name="sound" value="0" onClick={() => getRadioValueForSound(0)} checked={soundValue === 0}/>
                  <label for="sound2">No</label>
      
         </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSettingCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleSettingSubmit}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
      <Modal show={showDialog} onHide={handleCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="danger" onClick={() => handleConfirmDelete(vocab.id)}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
    </div>
    )) : 'No Records Found.'}

      </div>
      
    </section>
      
    )
}
