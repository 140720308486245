import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  studentActions,
  audioActions,
  readingActions,
  teacherActions,
  studyActions
} from "_store";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Table } from "react-bootstrap";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
const settings = {
  bars: 7,
  spacing: 3,
  width: 5,
  height: 100
};
import { useParams } from 'react-router-dom';
export { StudyForSpeakingSentenceTranslation };
function StudyForSpeakingSentenceTranslation() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const [highlightedIndex, setHighlightedIndex] = useState(0); // State variable to keep track of highlighted sentence
  const { audio } = useSelector((x) => x.audio);
  const { student } = useSelector((x) => x.student);
  const { myrecordings } = useSelector((x) => x.myrecordings);
  const [textValue, setTextValue] = useState("");
  const initialMinutes = 1;
  const initialSeconds = initialMinutes * 60;
  const [teacherResult, setTeacherResult] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(initialSeconds);
  const audioRef = useRef(null);
  const [audioFile, setAudioFile] = useState("");
  const [filePath, setFilePath] = useState("");
  const [audioQuality, setAudioQuality] = useState("");
  const [recordId, setRecordId] = useState("");
  const recorderControls = useAudioRecorder();
  const [showDialog, setShowDialog] = useState(false);
  const { student_teacher_result } = useSelector(
    (x) => x.student_teacher_result
  );
  const [apiResult, setApiResult] = useState(null);
  const [error, setError] = useState(false);
  const [contentValue, setContent] = useState(0);
  const [fluencyValue, setFluency] = useState(0);
  const refs = useRef([]);
  const volumemeter = useRef(0);
  const volumeRefs = useRef(new Array(settings.bars));
  const [imageSource, setImageSource] = useState('/images/record.png');
  const { program_id, pageType, q_start } = useParams();
  const { passage } = useSelector((x) => x.passage);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { reading } = useSelector((x) => x.reading);
  const [programId, setProgramId] = useState();
  if(pageType == 'Speaking'){
    var sentences = (passage && passage.data) ? passage.data.map(word =>({ word: word.questions, audio: word.audio_file_link
    })): [];
   
  }else{
    var sentences = (passage && passage.data) ? passage.data.map(word => ({ word: word.korean_grammer, audio: word.audio_file_link
    })): [];
    
  }
 
  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex === sentences.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevious = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? sentences.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
 
    dispatch(studyActions.getSubprogramsForStudyGrammar({ program_id,q_start }));
    setProgramId(program_id);
  }, [program_id, q_start, dispatch]);

//   useEffect(() => {
//     if(passage && passage.data){
//         setTextValue(passage.data.data.text);
//         //setAudio(passage.data.data.text_audio);
//       }
//   }, [passage]);

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const addAudioElement = async (blob) => {
    const url = URL.createObjectURL(blob);
    //alert("lalit");

    blobToBase64(blob).then((res) => {
      // do what you wanna do
      console.log(res, "blob"); // res is base64 now
    });
    //
    const formData = new FormData();
    formData.append("audio", blob);
    //formData.append("user", authUser.user.student_id);
    if(authUser.user.student_id){
      formData.append("user", authUser.user.student_id);
      formData.append("class_id", student.class_name);
    }else{
      formData.append("user", authUser.user.teacher_id);
    }
    if(pageType == 'Speaking'){
      var sub_type = 'Speaking: Sentence Read out loud';
    }else {
      var sub_type = 'Speaking: Sentence Translation';
    }
    formData.append("type",'Study');
    formData.append("sub_type",sub_type);
    formData.append("program_id",program_id);
    // formData.append("test_id",test_id);
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/audio-recording",
      formData
    );

    setAudioFile(response.data.data);
    setFilePath(response.data.filepath);
    setRecordId(response.data.audio_record.id);

    //  const audio1 = document.createElement("audio");
    //   audio1.src = url;
    //   audio1.controls = true;
  };

  useEffect(() => {
    if (recorderControls.isRecording) {
      const interval = setInterval(() => {
        if (totalSeconds > 0) {
          setTotalSeconds(totalSeconds - 1);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [recorderControls.isRecording, totalSeconds]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    if (audio) {  console.log(audio.data.result.message.AAResult[0].analysis[0]
      .speakingSummary[0].spkTimeSec[0] );
      if (
        audio.data.result.message.AAResult[0].audioQuality[0].$.status == "18"
      ) {
        setAudioQuality("Audio Quality is soft and noisy.");
      } else if (
        audio.data.result.message.AAResult[0].audioQuality[0].$.status == "8"
      ) {
        setAudioQuality("Audio Quality is soft.");
      } else if (
        audio.data.result.message.AAResult[0].audioQuality[0].$.status == "0"
      ) {
        setAudioQuality("Audio Quality is good.");
      } else if (
        audio.data.result.message.AAResult[0].audioQuality[0].$.status == "10"
      ) {
        setAudioQuality("Audio Quality is noisy.");
      }
    }
  }, [audio]);
  useEffect(() => {
    var userId = authUser.user.student_id;
    var file = filePath;
    dispatch(studentActions.getStudentById({ userId }));
    var student_id = authUser.user.student_id;
    var type= 'Like Recorder';
    dispatch(teacherActions.teacherResult({ student_id ,type }));
    $('.audio-recorder-mic').attr('src',imageSource);
    // const elementImg = document.querySelector('.audio-recorder-mic');
    // console.log(elementImg,'anj');
    // if (elementImg) {
    //   elementImg.innerHTML = `<img src="${imageSource}" alt="Sample" />`;
    // }
  }, []);

  useEffect(() => {
    if (student_teacher_result && student_teacher_result.data[0]) {
      var teacherjsonResult = JSON.parse(
        student_teacher_result.data[0].teacher_result
      );
      var jsonResult = JSON.parse(student_teacher_result.data[0].result);

      setTeacherResult(teacherjsonResult);
      setApiResult(jsonResult);
      if (apiResult) {
        console.log(apiResult.message.AAResult[0].audioQuality[0], "jsob");

        if (apiResult.message.AAResult[0].audioQuality[0].$.status == "18") {
          setAudioQuality("Audio Quality is soft and noisy.");
        } else if (
          apiResult.message.AAResult[0].audioQuality[0].$.status == "8"
        ) {
          setAudioQuality("Audio Quality is soft.");
        } else if (
          apiResult.message.AAResult[0].audioQuality[0].$.status == "0"
        ) {
          setAudioQuality("Audio Quality is good.");
        } else if (
          apiResult.message.AAResult[0].audioQuality[0].$.status == "10"
        ) {
          setAudioQuality("Audio Quality is noisy.");
        }else{
          setAudioQuality("Audio Quality is bad.");
        }
      }
    }
  }, [student_teacher_result]);
  useEffect(() => {
    if (recordId) {
      const string = sentences.map(item => item.word).join(' ');
      console.log(string,'get string');
      var file = filePath;
      var text = string;//JSON.stringify(wordList);
      var type = 'Study';
      
      var program_id = parseInt(programId);
      if(pageType == 'Speaking'){
        var sub_type = 'Speaking: Sentence Read out loud';
      }else {
        var sub_type = 'Speaking: Sentence Translation';
      }
      const studentInfo = JSON.parse(localStorage.getItem('user'));
      var class_id = studentInfo.user.Student.class_name;
     dispatch(readingActions.readingaudiorecording({ recordId,text, file, type,sub_type, program_id,class_id}));
    }
  }, [recordId]);
  useEffect(() => {
    if (reading) {
      var sub_type = (pageType && pageType == 'Speaking') ? 'SSROL' : 'SST';
        window.location.href = '/StudyReadOutLoudGrader/'+recordId+'/'+sub_type;
    }
  }, [reading]);
  const startRecording = () => {

    recorderControls.startRecording;
  }

  const handleCancel = () => {
    // Hide the dialog when cancel action is clicked
    setShowDialog(false);
    window.location.reload();
  };
  const getContentAverageScore = (content) => {
    var value = 0;
    if (content <= 5) {
      
      value = 0;
    } else if (content >= 6 && content <= 10) {
      value = 0.7;
    } else if (content >= 11 && content <= 20) {
      value = 1.5;
    } else if (content >= 21 && content <= 40) {

      value = 2.5;
    } else if (content >= 41 && content <= 80) {

      value = 3.3;
    }else if (content >= 81 && content <= 100) {

      value = 3.7;
    }else if (content >= 101) {

      value = 4;
    }
    //setContent(value);
    return value;
   
  };
  const getFluencyAverageScore = (fluency) => {
    console.log(fluency, "get fluency");
    var value = 0;
    if(Math.round(fluency) <= 30){
      value = 0;
    }else if(Math.round(fluency) >= 30 && Math.round(fluency) <= 50){
      value = 1;
    }
    else if(Math.round(fluency) >= 51 && Math.round(fluency) <= 80){
      value = 2;
    }
    else if(Math.round(fluency) >= 81 && Math.round(fluency) <= 120){
      value = 3;
    }
    else if(Math.round(fluency) >= 121 && Math.round(fluency) <= 150){
      value = 4;
    } else if(Math.round(fluency) >= 151 && Math.round(fluency) <= 180){
      value = 4;
    } else if(Math.round(fluency) >= 180){
      value = 4;
    }
   // setFluency(value);
   return value;
  };
  const getFluencyRemarks = (fluency) => {
    console.log(fluency, "get fluency");
    var text = 'There is no recording or your recording is extremely short.';
    if(fluency == 0){
      text = 'There is no recording or your recording is extremely short.';
    }else if(fluency == 1){
      text = 'Your speech is slow.';
    }
    else if(fluency == 2){
      text = 'Your speech is relatively slow.';
    }
    else if(fluency == 3){
      text = 'Your speech is relatively fast.';
    }
    else if(fluency == 4){
      text = 'Your speech is fast.';
    } 
   // setFluency(value);
   return text;
  };
  const getTotalAverageScore = (content,fluency) => {
    if (!isNaN(content) && !isNaN(fluency)) {
      const avg = (content + fluency) / 2;
      return avg;
    }
  };
  const getContentRemarks = (content) => {
    console.log(content, "get fluency");
    var text = 'There is no recording or your content score is equal to or less than 20%.';
    if(content == 0){
      text = 'There is no recording or your content score is equal to or less than 20%.';
    }else if(content == 0.7){
      text = 'Your content score is 21-40%.';
    }
    else if(content == 1.5){
      text = 'Your content score is 41-60%.';
    }
    else if(content == 2.5 || content == 3.3){
      text = 'Your content score is 61-80%.';
    }
    else if(content == 3.7 || content == 4){
      text = 'Your content score is 81-100%.';
    } 
   // setFluency(value);
   return text;
  };

  const getTotalRemarks = (total) => {
    console.log(total, "get fluency");
    var text = 'Nothing is recorded or no effort taken at all.';
    if(total == 0){
      text = 'Nothing is recorded or no effort taken at all.';
    }else if(total > 0 && total <= 1){
      text = 'Your speech is hardly recognizable. There are a lot of mistakes and errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with too many unnecessary pauses.';
    }
    else if(total > 1 && total <=2){
      text = 'Your speech is intelligible, but it requires much effort for one to understand. There are many noticeable mistakes and consistent errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with many unnecessary pauses.';
    }
    else if(total > 2 && total <= 3){
      text = 'Though it may require some effort, your speech is intelligible, showing relatively high oral proficiency and skills. It contains some errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with some unnecessary pauses.';
    }
    else if(total > 3 && total == 4){
      text = 'Your speech is native like. It is highly intelligible, showing high oral proficiency and skills. Your pronunciation, grammar, vocabulary use, fluency, and style are natural, accurate, and highly proficient.';
    } 
   // setFluency(value);
   return text;
  };
  const getMedia = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
        analyser.smoothingTimeConstant = 0.4;
        analyser.fftSize = 1024;
        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = () => {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          var values = 0;
          var length = array.length;
          for (var i = 0; i < length; i++) {
            values += array[i];
          }
          volumemeter.current = values / length;
        };
      })
      .catch(function(err) {
        /* handle the error */
      });
  };

  useEffect(getMedia, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      volumeRefs.current.unshift(volumemeter.current);
      volumeRefs.current.pop();
      if(refs.current.length > 0){
        //console.log(refs.current,'get reference');return false;
        for (let i = 0; i < refs.current.length; i++) {
if(refs.current[i] != '' && refs.current[i] != null){

  refs.current[i].style.transform = `scaleY(${volumeRefs.current[i] /
    100})`;
}
        }
      }
    }, 20);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const createElements = () => {
    let elements = [];
  

    for (let i = 0; i < settings.bars; i++) {
      elements.push(
        <div
          ref={ref => refs.current.push(ref)}
          key={`vu-${i}`}
          style={{
            position: "absolute",
            background: "#5ac63d",
            borderRadius: settings.width + "px",
            width: settings.width + "px",
            height: Math.sin((i / settings.bars) * 4) * settings.height + "px",
            left: i * (settings.width + settings.spacing) + "px",
            top:
            settings.height / 2 -
            Math.sin((i / settings.bars) * 4) * settings.height / 2 +
            "px"
          }}
        />
      );
    }
    return elements;
  };
  useEffect(() => {
      if(recorderControls.stopRecording){
        $('.audio-recorder-mic').attr('src',imageSource);
      }
  }, [recorderControls.stopRecording]);
  const handleListenClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handlePlayAll = () => {
    // Play audio files for all sentences
    sentences.forEach((word, index) => {
      setTimeout(() => {
        playAudio(index);
      }, index * 3000); // Delay each audio play by 3 seconds
    });
  };

  const playAudio = (index) => {
    const audioElement = document.getElementById(`audio-${index}`);
    audioElement.play();
    setHighlightedIndex(index); // Highlight the currently playing word
  };
  return (
    <section>
      {!audio ? (
        <div className="test">
          <h2>SPEAKING: SENTENCE TRANSLATION</h2>
         
          <div style={{ background: '#0f59a8',border: '1px solid #0f59a8',padding: '50px 10px',margin: '10px' }}>
        <p className="speaking-read-out">{(sentences[currentIndex]) ? sentences[currentIndex].word:''}</p>
      </div>
      <div>
        <button onClick={handlePrevious}>Previous</button>
        <button onClick={handleNext} disabled={currentIndex === sentences.length - 1}>Next</button>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Listen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {sentences.map((sentence, index) => (
              <li key={index} attr-audio={sentence.audio} onClick={() => playAudio(index)} style={{ fontWeight: index === highlightedIndex ? 'bold' : 'normal' ,background: index === highlightedIndex ? 'rgb(15, 89, 168)': '#fff' , color: index === highlightedIndex ? '#fff': '#000'}}>
                {sentence.word}
                <audio id={`audio-${index}`} src={sentence.audio} />
                </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePlayAll}>
            Play All
          </Button>
        </Modal.Footer>
      </Modal>
           <Modal show={showDialog} onHide={handleCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>LIKE RECORDER MONTHLY LIMIT REACHED</Modal.Title>
                </Modal.Header>
                <Modal.Body>You have reached the maximum number. Please contact the administrator.</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
                
    
          <div className="mt-4">
            <div className="block my-recording-micro">
              <i className="fa fa-microphone big" aria-hidden="true" />
              {
        (recorderControls.isRecording ) ? 
        <div className="micro-sound-meter" style={{ position: "relative" }}>{createElements()}</div>: ''
      }
              <p>Countdown Timer</p>

             
              {recorderControls.isRecording ? (

                (!showDialog) ?
                <>
                <p>
                {recorderControls.isRecording
                  ? formatTime(totalSeconds)
                  : "02:00"}
              </p>
                <button
                  onClick={recorderControls.stopRecording}
                  className="test-btn stop"
                >
                  Stop
                </button>
                </>
                :
                ''
              ) : (
                (!showDialog) ? 
                <>
                <button
                  className="test-btn"
                  onClick={() => startRecording()}
                >
  
               
                 <AudioRecorder
                 onRecordingComplete={(blob) => addAudioElement(blob)}
                 recorderControls={recorderControls}
                 audioTrackConstraints={{
                   noiseSuppression: true,
                   echoCancellation: true,
                 }}
                 downloadOnSavePress={true}
                 downloadFileExtension="mp3"
                 showVisualizer={true}
               />
                 </button>
                 <button
                  className="test-btn"
                  onClick={handleListenClick}
                >
                  Listen
  </button>
                  </>
                : ''
              )}

              {audioFile ? (
                <>
                  <audio className="hide" ref={audioRef} controls>
                    <source src={audioFile} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  {/* <button onClick={handleAudioAnalysis}>Submit</button> */}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="container">
        <div className="row audio-result">
          <div className="col-lg-12">
            {audio ? (
              <>
                <audio ref={audioRef} controls>
                  <source src={audioFile} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
                <button
                  onClick={()=>window.location.reload()}
                  className="test-btn"
                >
                  Record Again
                </button>
                {/* <a href={`/study/view-test-details/${test_id}`} className="test-btn">
                 Back To Test
                </a> */}
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th colSpan={5} style={{ textAlign: "center" }}>
                      READ-OUT-LOUD GRADER
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{authUser.user.name}</td>
                      <td rowspan="5" className="result">
                        { getTotalAverageScore(getContentAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        ), getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        )) >= 3  ? (
                          <h4 style={{ color: "green" }}>Pass</h4>
                        ) : (
                          <h4 style={{ color: "red" }}>Fail</h4>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Test Date</td>
                      <td>{new Date().toLocaleString('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short'
})}</td>
                    </tr>
                    <tr>
                      <td>Audio Quality</td>

                      <td>{audioQuality}</td>
                    </tr>
                    <tr>
                      <td>Total Recording Time</td>
                      <td>
                        {(audio.data.result.message) ?  parseInt(
                          audio.data.result.message.AAResult[0].audioLenSec[0]._
                        ) : 0}{" "}
                        Seconds
                      </td>
                    </tr>
                    <tr>
                      <td>Total Speaking Time</td>
                      <td>
                        {(audio.data.result.message) ? 
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].spkTimeSec[0] : 0
                        }{" "}
                        Seconds
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Scores</td>
                      <td colSpan={5}>Comments</td>
                    </tr>
                    <tr>
                      <td>Content</td>
                      <td>
                        {getContentAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        )}
                        /4
                      </td>
                      <td colSpan={5}>
                        {
                          getContentRemarks(getContentAverageScore(audio.data.result.message.AAResult[0].analysis[0].speakingSummary[0].nWord[0]))
                          
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Fluency</td>
                      <td>
                        {getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        )}
                        /4
                      </td>
                      <td colSpan={5}>
                        {getFluencyRemarks(getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        ))}
                      </td>
                    </tr>

                    <tr>
                      <td>Total</td>
                      <td>
                        {
                         getTotalAverageScore(getContentAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        ), getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        ))
                        }/4
                      </td>
                      <td colSpan={5}>{getTotalRemarks(getTotalAverageScore(getContentAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        ), getFluencyAverageScore(
                          audio.data.result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        )))}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
