import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { studentActions,homeworkActions,gradeattendenceActions } from '_store';
import Table from "react-bootstrap/Table";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function StudentHomework() {
  const dispatch = useDispatch();
  const { user:authUser } = useSelector((x) => x.auth);
  const { student } = useSelector((x) => x.student);
  const { homework } = useSelector((x) => x.homework);
  const { gradeattendence } = useSelector((x) => x.gradeattendence);
  const [activeIndex, setActiveIndex] = useState(null);
  const [link, setLink] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
console.log(homework,'get hpme');
  const handleCollapsibleClick = (index,inputDate) => {
    setActiveIndex(index === activeIndex ? null : index);
    const classId = student.Class.id;
    const studentId = authUser.user.student_id;
    const date1 = new Date(inputDate);
    const year = date1.getFullYear();
    const month = String(date1.getMonth() + 1).padStart(2, '0');
    const day = String(date1.getDate()).padStart(2, '0');

    var date = `${year}-${month}-${day}`;
    dispatch(gradeattendenceActions.getGradeAttendanceByStudent({studentId,classId,date}));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!authUser) {
          toast.error('Please login to view this page.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          history.navigate('/login');
        } else {
          const userId = authUser.user.student_id;
          await dispatch(studentActions.getStudentById({ userId }));
        }
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    fetchData();
  }, [authUser, dispatch]);

 useEffect(() => {
  const fetchHomeworkData = async () => {
    try {
      if (student && student.Class) {
        const class_id = student.Class.id;
        const type = '';
        var startdate = '';
        var enddate = '';
        var student_id = student.id;
        await dispatch(homeworkActions.getHomeWorkById({ class_id, type, startdate, enddate, student_id }));
      }
    } catch (error) {
      console.error('Error fetching homework data:', error);
    }
  };

  fetchHomeworkData();
}, [student, dispatch]);

 const [dateRange, setDateRange] = useState([
  {
    startDate: new Date(),
    endDate: null,
    key: 'selection'
  }
]);

const handleSelect = (ranges) => {
  setDateRange([ranges.selection]);
};
const getLinkAccordingType = (type, category, homework_id, program_id,my_vocab_id, subtype,vocab_id, q_start,study_program_id) => {
  const userId = authUser.user.student_id;
  if(type == 'Library'){
   var link =  `/HomeWorkVocabularyType/${homework_id}/${program_id}/${userId}`;
  }
  if(category == 'Free Response'){
    var link =  `/HomeWork/free-response/${homework_id}`;
  }

  if(category == 'Read Out Loud'){
    var link =  `/HomeWork/read-out-loud/${homework_id}`;
  }
 
  if(type == 'My Own Vocabulary'){
    var link =  `/dictionary/MyOwnVocab/${my_vocab_id}`;
  }
  if(type == 'Study' && subtype == 'Vocabulary' && category == 'VQuiz'){
    var link =  `/vocabulary-question/${vocab_id}/${q_start}`;
   }
   if(type == 'Study' && subtype == 'Vocabulary' && category == 'VLCH'){
    var link =  `/vocabulary-listening-question/LCH/${vocab_id}/${q_start}`;
   }
   if(type == 'Study' && subtype == 'Vocabulary' && category == 'VLCM'){
    var link =  `/vocabulary-listening-question/LCM/${vocab_id}/${q_start}`;
   }
   if(type == 'Study' && subtype == 'Vocabulary' && category == 'VROL'){
    var link =  `/vocabulary-listening-read/${vocab_id}/${q_start}/VROL`;
   }
   if(type == 'Study' && subtype == 'LMC' && category == 'Listening Multiple Choice'){
    var link =  `/study/listening-multiple-choice/${study_program_id}/LMC`;
   }
   if(type == 'Study' && subtype == 'RMC' && category == 'Reading Multiple Choice'){
    var link =  `/study/reading-multiple-choice/${study_program_id}/RMC`;
   }
   if(type == 'Study' && subtype == 'ROL' && category == 'Reading Multiple Choice'){
    var link =  `/study/read-out-loud/${study_program_id}/ROL`;
   }
   if(type == 'Study' && subtype == 'RMC' && category == 'Read out Loud'){
    var link =  `/study/reading-multiple-choice/${study_program_id}/RMC`;
   }
   if(type == 'Study' && subtype == 'ROL' && category == 'Read out Loud'){
    var link =  `/study/read-out-loud/${study_program_id}/ROL`;
   }
console.log(link,'get link');
  return link;

};
const getDailyReport = async () => {
 
    const class_id = (student && student.Class) ? student.Class.id : '';
    var student_id = (student) ? student.id : '';
    const type = '';
    const startdate = startDate;
    const enddate = endDate;
   const response =  await dispatch(homeworkActions.getHomeWorkById({ class_id, type, startdate, enddate, student_id }));
console.log(response,'get response');
};
function formatDate(date) {
  if (!date) return null;
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}
const handleDateChange = (dates) => {
  const [start, end] = dates;
  const formattedStartDate = formatDate(start);
  const formattedEndDate = formatDate(end);
  setStartDate(formattedStartDate);
  setEndDate(formattedEndDate);
};

useEffect(() => {
if(homework && homework.data && homework.data.length > 0){
  const endDate = homework.data[0].date;
  const startDate = homework.data[homework.data.length - 1].date;
  setStartDate(startDate);
  setEndDate(endDate);
   // Set the activeIndex to the last homework item
   setActiveIndex(0);
}
}, [homework]);

console.log(homework,'get homework');
    return (
      <div className="container">
      <div className="heading">{(student && student.Class ) ? student.Class.class_name: ''}</div>
      <div className="date-select">
        <label>Date</label>
        <DatePicker
         selected={startDate ? new Date(startDate.split('-').reverse().join('-')) : null}
         onChange={handleDateChange}
         startDate={startDate ? new Date(startDate.split('-').reverse().join('-')) : null}
         endDate={endDate ? new Date(endDate.split('-').reverse().join('-')) : null}
        className='datepicker-input'
        selectsRange
        placeholderText="Select date range"
        isClearable
      />
        <button id="submit" onClick={getDailyReport}>Daily Report</button>
      </div>

      <div className="heading main">Homework and Daily Report</div>
{(homework && homework.data.length > 0) ? 
      <div className="collapse-container">
        <div className="collapse-header">
          <p className="">Student Name: {(student) ? student.firstName+' '+student.lastName : ''}</p>
          <button className="expandbutton">+ Expand All</button>
        </div>
        <div className="collapse-body">
  {(homework && homework.data) ? homework.data.map((work, index) => (
    <div key={index}>
      <button
        type="button"
        className={`collapsible ${index === activeIndex ? 'active' : ''}`}
        onClick={() => handleCollapsibleClick(index, work.date)}
      >
        {work.date}
      </button>

      <div className={`content ${index === activeIndex ? 'active' : ''}`}>
        {work.homeworkArray ? (
          // Group homeworks by type, adding "My Own Vocabulary" and "Writing" to "Speaking"
          Object.entries(work.homeworkArray.reduce((acc, home) => {
            let type = home.type || '';
            // If type is "My Own Vocabulary" or "Writing", group it under "Speaking"
            //if (type === 'My Own Vocabulary' || type === 'Writing') {
              if (type === 'My Own Vocabulary' ) {
              type = 'Speaking';
             
            }
            if (!acc[type]) acc[type] = [];
            acc[type].push(home);
            return acc;
          }, {})).map(([type, homeworks], typeIndex) => (
            <div key={typeIndex}>
              {/* Display each type only once */}
              <p className="bold-txt">{type}</p>
              {homeworks.map((home, homeIndex) => (
                home.homework.map((homeW, index1) => (
                  <>
                    {(home.type === 'My Own Vocabulary' && homeW.My_vocab && homeW.My_vocab.vocabname) ? (
                      // Display vocabname for "My Own Vocabulary" type
                      <p>
                        <span className="user">
                          <a href={getLinkAccordingType(homeW.type, homeW.category, homeW.id, homeW.program_id,homeW.my_vocab_id)}>
                            {index1 + 1}. {homeW.My_vocab.vocabname} <strong>Posted by</strong>{' '}
                            {(homeW.Teacher) ? homeW.Teacher.first_name : ''}
                          </a>
                        </span>
                      </p>
                    ) : (
                      // Display homework_text or homework_email_text for other types
                      (homeW.homework_email_text || homeW.homework_text) && (
                        <p>
                          <span className="user">
                            <a href={getLinkAccordingType(homeW.type, homeW.category, homeW.id, homeW.program_id,'', homeW.subtype,homeW.vocab_id,homeW.q_start, homeW.study_program_id)}>
                              {index1 + 1}. {homeW.homework_email_text || homeW.homework_text} <strong>Posted by</strong>{' '}
                              {(homeW.Teacher) ? homeW.Teacher.first_name : ''}
                            </a>
                          </span>
                        </p>
                      )
                    )}
                    {/* Display attendance information */}
                    {homeW.Attendence && homeW.Attendence.map((attend, attIndex) => (
                      attend.student_id === authUser.user.student_id && (
                        <div key={attIndex} className="attendence-show">
                          Attendance: {attend.attendance === "P" ? 'Present' : 'Absent'}
                          <Table id="scoreTable" striped bordered hover>
                            <tbody>
                              <tr><td></td><th>Grades</th><th>Class Avg.</th><th>Homework</th><th>Quiz</th></tr>
                              <tr><th>Reading</th><td>{attend.txtread}</td><td>{attend.reading_avg}</td><td>{attend.txtHWRead}</td><td>{attend.txtQZRead}</td></tr>
                              <tr><th>Listening</th><td>{attend.txtlist}</td><td>{attend.listening_avg}</td><td>{attend.txtHWList}</td><td>{attend.txtQZList}</td></tr>
                              <tr><th>Speaking</th><td>{attend.txtspek}</td><td>{attend.speaking_avg}</td><td>{attend.txtHWSpk}</td><td>{attend.txtQZSpk}</td></tr>
                              <tr><th>Writing</th><td>{attend.txtwrit}</td><td>{attend.writing_avg}</td><td>{attend.txtHWWrt}</td><td>{attend.txtQZWrt}</td></tr>
                              <tr><th>Grammar</th><td>{attend.txtgramer}</td><td>{attend.grammer_avg}</td><td>{attend.txtHWGram}</td><td>{attend.txtQZGram}</td></tr>
                              <tr><th>Vocabulary</th><td>{attend.txtvocab}</td><td>{attend.vocab_avg}</td><td>{attend.txtHWVocab}</td><td>{attend.txtQZVocab}</td></tr>
                              <tr><th>My Avg</th><td>{attend.speaking_avg}</td><td>{attend.writing_avg}</td><td>{attend.grammer_avg}</td><td>{attend.vocab_avg}</td></tr>
                            </tbody>
                          </Table>
                          <div>
                            <span>A+= 81-100</span> <span>A= 61-80</span> <span>B+= 41-60</span> <span>B= 21-40</span> <span>C+= 0-20</span> <span>O=Pass</span> <span>X=Fail</span>
                          </div>
                        </div>
                      )
                    ))}
                  </>
                ))
              ))}
            </div>
          ))
        ) : (
          <h5>No Homework Found.</h5>
        )}
      </div>
    </div>
  )) : (
    <h5>No Homework Found.</h5>
  )}
</div>


        {/* <div className="collapse-body">
   
          {(homework && homework.data) ? homework.data.map((work, index) => (
            <div key={index}>
              <button
                type="button"
                className={`collapsible ${index === activeIndex ? 'active' : ''}`}
                onClick={() => handleCollapsibleClick(index,work.date)}
              >
                {work.date}
              </button>
             
              <div className={`content ${index === activeIndex ? 'active' : ''}`}>
                
              {(work.homeworkArray) ? work.homeworkArray.map((home, index) => (
                <>
                {(home.homework) ? home.homework.map((homeW, index1) => (
                <>
                {(homeW.homework_email_text || homeW.homework_text) ? 
                <>
                <p>
                <span className="bold-txt">{(home.type) ? home.type : homeW.type}</span>{' '}
                <strong>Posted by</strong> {(homeW && homeW.Teacher) ? homeW.Teacher.first_name : ''}
              </p>
              {(home.type == 'Speaking' || home.type == 'Reading' || home.type == 'Writing' || home.type == 'Listening' || home.type == 'Vocabulary' || home.type == 'Grammar') ? 
              <>
             
            <p>
                <span className="user"> <a href={getLinkAccordingType(homeW.type,homeW.category,homeW.id,homeW.program_id)}> {homeW.homework_email_text || homeW.homework_text}</a></span>
              </p>
              </> :
            
              <p>
              <a href={getLinkAccordingType(homeW.type,homeW.category,homeW.id,homeW.program_id)}>  {homeW.homework_email_text || homeW.homework_text}</a>. Posted by: {(homeW && homeW.Teacher) ?  homeW.Teacher.first_name : ''}
              </p> }
              
               </>: ''}

             
                <div>
             
               
                {(homeW.Attendence) ? homeW.Attendence.map((attend, index) => (
                   <>
                  {(attend.student_id == authUser.user.student_id) ? 
                 <>
                 <div className='attendence-show'>Attendance : {(attend.attendance == "P") ? 'Present' : 'Absent'}</div>
                  <Table id="scoreTable" striped bordered hover>
                  <tr>
                    <td></td>
                    <th>Grades</th>
                    <th>Class Avg.</th>
                    <th>Homework</th>
                    <th>Quiz</th>
                </tr>
                  <tr>
                      <th>Reading</th>
                      <td>{attend.txtread}</td>
                      <td>{attend.reading_avg}</td>
                      <td>{attend.txtHWRead}</td>
                      <td>{attend.txtQZRead}</td>
                  </tr>
    <tr>
        <th>Listening</th>
        <td>{attend.txtlist}</td>
        <td>{attend.listening_avg}</td>
        <td>{attend.txtHWList }</td>
        <td>{attend.txtQZList}</td>
    </tr>
    <tr>
        <th>Speaking</th>
        <td>{attend.txtspek}</td>
        <td>{attend.speaking_avg}</td>
        <td>{attend.txtHWSpk}</td>
        <td>{attend.txtQZSpk}</td>
    </tr>
    <tr>
        <th>Writing</th>
        <td>{attend.txtwrit}</td>
        <td>{attend.writing_avg}</td>
        <td>{attend.txtHWWrt}</td>
        <td>{attend.txtQZWrt}</td>
    </tr>
    <tr>
        <th>Grammar</th>
        <td>{attend.txtgramer}</td>
        <td>{attend.grammer_avg}</td>
        <td>{attend.txtHWGram}</td>
        <td>{attend.txtQZGram}</td>
    </tr>
    <tr>
        <th>Vocabulary</th>
        <td>{attend.txtvocab}</td>
        <td>{attend.vocab_avg}</td>
        <td>{attend.txtHWVocab}</td>
        <td>{attend.txtQZVocab}</td>
    </tr>
    <tr>
        <th>My Avg</th>
        <td>{attend.speaking_avg}</td>
        <td>{attend.writing_avg}</td>
        <td>{attend.grammer_avg}</td>
        <td>{attend.vocab_avg}</td>
    </tr></Table>
    <div className="">
    <span>A+= 81-100</span> <span>A= 61-80</span> <span>B+= 41-60</span>{" "}
    <span>B= 21-40</span> <span>C+= 0-20</span> <span>O=Pass</span>{" "}
    <span>X=Fail</span>{" "}
  </div>
    </> : ''}</>
                )) : ''}
</div>
               </>
               )) : ''}

           </>
)) :''}

              </div>

            </div>
          )) : '<h5>No Homework Found.</h5>'}
        </div> */}
      </div>
: <p className='no-homework'>No Homework yet.</p>}
    </div>

      
    )
}
