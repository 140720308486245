import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  studentActions,
  audioActions,
  myrecordingActions,
  teacherActions,
  studyActions,readingActions

} from "_store";
import { Table } from "react-bootstrap";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
export { TestResult };
function TestResult() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { audio } = useSelector((x) => x.audio);
 
  useEffect(() => {
    var student_id = authUser.user.student_id;
    const today = new Date();

    // Extract year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
  
    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    var date = formattedDate;
    dispatch(studyActions.getStudyTestResults({student_id,date}));

}, []);
  return (
    <section>
<div className="container">
  <div className="stu-detail">
    <h3>Test</h3>
    <table>
      <tbody>
        <tr>
          <th>Student Name</th>
          <td>손태건</td>
        </tr>
        <tr>
          <th>Like ID</th>
          <td>Nangman</td>
        </tr>
        <tr>
          <th>Class</th>
          <td>Pre IS 3</td>
        </tr>
        <tr>
          <th>Home Telephone</th>
          <td>9876543210</td>
        </tr>
        <tr>
          <th>Parent Cell Phone</th>
          <td>9876543210</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div className="summery-table">
    <h3>
      Summery <button>Review</button>
    </h3>
    <table>
      <thead>
        <tr>
          <th>Student Name</th>
          <th>Student Name</th>
          <th>Student Name</th>
          <th>Student Name</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Nangman</td>
          <td>22/40</td>
          <td>55%</td>
          <td>16</td>
        </tr>
        <tr>
          <td>Nangman</td>
          <td>22/40</td>
          <td>55%</td>
          <td>9</td>
        </tr>
        <tr>
          <td>Nangman</td>
          <td>Nangman</td>
          <td>55%</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Nangman</td>
          <td>22/40</td>
          <td>55%</td>
          <td>8</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div className="ach-graph">
    <h3>Achievement Graph</h3>
    <canvas id="myChart" style={{ width: "100%", maxWidth: 600 }} />
  </div>
  <div className="graph-table">
    <h3>Reading</h3>
    <table>
      <tbody>
        <tr>
          <td className="first">
            Reading Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "55%" }} className="color-1">
                55
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "62%" }} className="color-2">
                62
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "68.2%" }} className="color-3">
                68.2
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
      </tbody>
    </table>
    <h3>Listening</h3>
    <table>
      <tbody>
        <tr>
          <td className="first">
            Listening Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "55%" }} className="color-1">
                55
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "62%" }} className="color-2">
                62
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "68.2%" }} className="color-3">
                68.2
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
      </tbody>
    </table>
    <h3>Speaking</h3>
    <table>
      <tbody>
        <tr>
          <td className="first">
            Speaking Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "55%" }} className="color-1">
                55
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "62%" }} className="color-2">
                62
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "68.2%" }} className="color-3">
                68.2
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
        <tr>
          <td className="first">
            Reading Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "55%" }} className="color-1">
                55
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "62%" }} className="color-2">
                62
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "68.2%" }} className="color-3">
                68.2
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
        <tr>
          <td className="first">
            Reading Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "55%" }} className="color-1">
                55
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "62%" }} className="color-2">
                62
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "68.2%" }} className="color-3">
                68.2
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
        <tr>
          <td className="first">
            Reading Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "55%" }} className="color-1">
                55
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "62%" }} className="color-2">
                62
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "68.2%" }} className="color-3">
                68.2
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
        <tr>
          <td className="first">
            Reading Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "55%" }} className="color-1">
                55
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "62%" }} className="color-2">
                62
              </span>
            </p>
            <p className="graph">
              text{" "}
              <span style={{ width: "68.2%" }} className="color-3">
                68.2
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div className="avg-table">
    <h3>
      Reading <span>Average 63%</span>
    </h3>
    <table>
      <tbody>
        <tr>
          <td className="first">
            Reading Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "55%" }} className="color-4">
                55 %
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
        <tr>
          <td className="first">
            Reading Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "60%" }} className="color-4">
                60 %
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
      </tbody>
    </table>
    <h3>
      Listening <span>Average 63%</span>
    </h3>
    <table>
      <tbody>
        <tr>
          <td className="first">
            Listening Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "70%" }} className="color-4">
                70%
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
        <tr>
          <td className="first">
            Listening Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "90%" }} className="color-4">
                90%
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
      </tbody>
    </table>
    <h3>
      Speaking <span>Average 63%</span>
    </h3>
    <table>
      <tbody>
        <tr>
          <td className="first">
            Speaking Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "100%" }} className="color-4">
                100%
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
        <tr>
          <td className="first">
            Speaking Comprehension <span className="score">55/100</span>
          </td>
          <td>
            <p className="graph">
              text{" "}
              <span style={{ width: "90%" }} className="color-4">
                90%
              </span>
            </p>{" "}
          </td>
          <td>초등학교 5-6 학년 수준의 어휘를 공부중입니다</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

    </section>
  );
}
