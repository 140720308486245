import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import { history } from "_helpers";
import { signupActions } from "_store";
import ReCAPTCHA from "react-google-recaptcha";
export { Register };

function Register() {
  const dispatch = useDispatch();
  const signupUser = useSelector((x) => x.signup.user);
  const signupError = useSelector((x) => x.signup.error);
  const [inputValue, setInputValue] = useState("");
  const [inputMsg, setInputMessage] = useState("");
  const [inputShow, setInputShow] = useState();
  useEffect(() => {
    // redirect to home if already logged in
    // if (signupUser) history.navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("First Name is required"),
    email: Yup.string().required("Email Name is required"),
    password: Yup.string().required("Password is required"),
    verify_password: Yup.string().required("Confirm Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  console.log(errors, "get error");
  function onSubmit({ name, email, inputValue, password }) {
    console.log("ggggg");
    var username = inputValue;
    return dispatch(signupActions.signup({ name, email, username, password }));
  }

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  function makealphanumeric(value) {
    console.log("Captcha value:", value);
  }


  function handleInputChange(event) {
    const inputRegex = /^[a-zA-Z0-9]+$/; // regular expression to match alphanumeric input
    const inputValue = event.target.value;
 
    if (inputRegex.test(inputValue)) {console.log('valid print here');
      setInputValue(inputValue);
      setInputMessage('');
      setInputShow();
    }else{console.log('not valid print here');
      setInputMessage('ID should be alphanumeric.');
      console.log(inputMsg,'not valid print here');
      setInputShow(true);
    }
  }
  return (
    <section className="custom-sign up">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 sign-left-bg"
            style={{ backgroundImage: "url(images/sign-up-img.webp)" }}
          >
            <div className="custom-sign-left" />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="sign-in-content">
              <h3>
                Like Study is an immersive learning experience that provides
                comprehensive, English content for Pre-K to College.
              </h3>
            </div>
          </div>
          <div className="signin-form signup-form">
            <h3>Sign Up</h3>
            <p className="custom-para">
              Please tell us who you are &amp; who you want to meet.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <input
                  type="text"
                  {...register("name")}
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  name="name"
                  placeholder="Name"
                  aria-label="First name"
                />
                <div className="mb-3">
                  <input
                    placeholder="Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    type="text"
                    required
                    className="form-control input"
                  />
                  {errors.email?.message && <span>email is not valid</span>}
                </div>
                <div className="mb-3">
                  <input
                    name="username"
                    type="text"
                    className="form-control input"
                    id="exampleInputEmail6"
                    onChange={handleInputChange}
                    aria-describedby="emailHelp"
                    placeholder="ID"
                  />

                  { inputShow && <span className="username-id">{inputMsg}</span>}
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    {...register("password")}
                    className={`form-control ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    name="password"
                    id="exampleInputPassword1"
                    placeholder="Password"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    {...register("verify_password")}
                    className={`form-control ${
                      errors.verify_password ? "is-invalid" : ""
                    }`}
                    name="verify_password"
                    id="exampleInputPassword1"
                    placeholder="Verify Password"
                  />
                </div>
                <div className="signin-form-btn">
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  <input
                    className="custom-button"
                    type="submit"
                    defaultValue="SIGN UP"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
