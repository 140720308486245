import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const name = 'quizresult';

const initialState = createInitialState();

function createInitialState() {
    return {
        user: JSON.parse(localStorage.getItem('user')),
        error: null,
        quizresult: null,
    };
}

const baseUrl = 'https://api.webtracktechnologies.com.au:8002';

const savequizresult = createAsyncThunk(
    `${name}/savequizresult`,
    async (result) => {
        try {
            const response = await fetchWrapper.post(`${baseUrl}/quiz/saveresult`, { result });
            return response.data; // Adjust this based on your API response structure
        } catch (error) {
            throw error;
        }
    }
);

const getquizresult = createAsyncThunk(
    `${name}/getquizresult`,
    async ({ student, category ,type }) => {
        try {
            const response = await fetchWrapper.get(`${baseUrl}/quiz/test-result?student=${student}&category=${category}&type=${type}`);
            return response.data; // Adjust this based on your API response structure
        } catch (error) {
            throw error;
        }
    }
);

const quizresultSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(savequizresult.pending, (state) => {
                state.error = null;
            })
            .addCase(savequizresult.fulfilled, (state, action) => {
                state.quizresult = action.payload;
            })
            .addCase(savequizresult.rejected, (state, action) => {
                state.error = action.error;
                toast.error(action.error.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            })
            .addCase(getquizresult.pending, (state) => {
                state.error = null;
            })
            .addCase(getquizresult.fulfilled, (state, action) => {
                state.quizresult = action.payload;
            })
            .addCase(getquizresult.rejected, (state, action) => {
                state.error = action.error;
                // toast.error(action.error.message, {
                //     position: 'top-right',
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: 'light',
                // });
            });
    },
});

export const quizresultActions = {
    ...quizresultSlice.actions,
    savequizresult,
    getquizresult,
};

export const quizresultReducer = quizresultSlice.reducer;
