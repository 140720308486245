import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import Peer from 'simple-peer';

const socket = io("https://api.webtracktechnologies.com.au:8002"); // replace with your own signaling server
export { VideoChat };

function VideoChat() {
  const [roomId, setRoomId] = useState("anjali");
  const [userId, setUserId] = useState("");
  const [peers, setPeers] = useState({});
  const [stream, setStream] = useState(null);
  const userVideoRef = useRef();
  const partnerVideoRef = useRef(null);
//console.log(roomId,'room');
  useEffect(() => {
    // Get user media (video and audio)
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setStream(stream);
       // console.log(stream,'get stream',userVideoRef);
        if(userVideoRef.current){
          userVideoRef.current.srcObject = stream
        }
        
      });


 // Join a room on the signaling server
 socket.emit("join-room", roomId, userId);
    // Listen for new user connections
    socket.on("user-connected", (userId) => {
      console.log(`User ${userId} connected`);

      // Create a new peer and send our stream
      const peer = new Peer({ initiator: true, trickle: false, stream });
      
      peer.on("signal", (signal) => { console.log(signal,userId,'get on signal');
        socket.emit("offer", signal, userId);
      });
      peer.on("stream", (stream) => {console.log(stream,'get on stream 123');
        partnerVideoRef.current.srcObject = stream;
      });

      setPeers((prevPeers) => ({ ...prevPeers, [userId]: peer }));
      console.log(peers,'get peers');
      // When we receive an offer, create a new peer and send an answer
      socket.on("offer", (offer, userId) => {console.log('I am getting offer');
        console.log(`Received offer from user ${userId}`);

        const peer = new Peer({ initiator: false, trickle: false, stream });
        peer.on("signal", (signal) => {console.log(signal,'signal');
          socket.emit("answer", signal, userId);
        });
        peer.on("stream", (stream) => {
          partnerVideoRef.current.srcObject = stream;
        });
        peer.signal(offer);

        setPeers((prevPeers) => ({ ...prevPeers, [userId]: peer }));
      });

      // When we receive an answer, signal the peer
      socket.on("answer", (answer, userId) => {console.log('I am getting answer');
        console.log(`Received answer from user ${userId}`);

        const peer = peers[userId];
        peer.signal(answer);
      });

      // When a user disconnects, remove the peer
      socket.on("user-disconnected", (userId) => {
        console.log(`User ${userId} disconnected`);

        const peer = peers[userId];
        if (peer) {
          peer.destroy();
          setPeers((prevPeers) => {
            delete prevPeers[userId];
            return { ...prevPeers };
          });
        }
      });
    });
  
  }, [roomId, userId]);

  const handleJoinRoom = () => {
    // Generate a random user ID
    const userId = Math.floor(Math.random() * 1000000).toString();
    setUserId(userId);
       
  };

  const handleStartCall = () => {
    // Generate a random room ID
    const roomId = Math.floor(Math.random() * 1000000).toString();
    setRoomId(roomId);
    console.log(roomId,'room');
  };

  return (
    <div>
      {stream && (
        <video ref={userVideoRef} autoPlay muted style={{ width: 400 }} />
      )}

      {Object.keys(peers).map((userId) => (
        <video
          key={userId}
          ref={partnerVideoRef}
          autoPlay
          style={{ width: 400 }}
          className={userId}
        />
      ))}

      <button onClick={handleJoinRoom}>Join Room</button>
      <button onClick={handleStartCall}>Start Call</button>
    </div>
  );
}
