import { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    libraryActions
  } from "_store";
  import { useParams } from "react-router-dom";

export const Scramble = () => {
    const dispatch = useDispatch();
    const { homework_id, program_id } = useParams();
    const { library } = useSelector((x) => x.library);
    const [showTextArea, setShowTextArea] = useState(false);
    const [sentences, setSentences] = useState([]);
    const [sentenceData, setSentenceData] = useState([]);
    const [wordsArray, setwords] = useState([]);
    const [linkList, setLinkList] = useState([]);
  const [inputText, setInputText] = useState('');
  const [displayedSentenceIndices, setDisplayedSentenceIndices] = useState([]);
 // const [currentSentenceIndex, setCurrentSentenceIndex] = useState(null);
    const [scrambledSentence, setScrambledSentence] = useState('');
    const [userInput, setUserInput] = useState('');
    const [showNextButton, setShowNextButton] = useState(false);
    const [showFinishButton, setShowFinishButton] = useState(false);
  const [remainingSentence, setRemainingSentence] = useState(1);
  const [score, setScore] = useState(0);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const currentSentence = sentences[currentSentenceIndex];
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  useEffect(() => {
    if (library && library.data) {

   const sentenceQuestions = library.data.words.map(word => word.sentence_question);

// Create the sentences array using only the sentence_question in the correct variable
const sentences = sentenceQuestions.map(question => ({ correct: question, scrambled: scrambleSentence(question) }));
  
setSentences(sentences);


    }
  }, [library]);
// console.log(sentences,'get sentences');
  useEffect(() => {console.log('test',sentences.length);
    if(sentences.length > 0){
      const currentSentence = sentences[currentSentenceIndex];
      console.log(currentSentence,'cirrent');
      const wordsArray = currentSentence.scrambled.split(' ');
      
      const initialLinkList = wordsArray.map((word, index) => ({
        text: `${word} `,
        transform: index % 2 === 0 ? 10 : -10,
      }));
      setLinkList(initialLinkList);
    }

  }, [sentences]);

  const getNextTransform = () => {
    if (linkList.length === 0) {
      return 10; // Start with 10 if the list is empty
    }
    const lastTransform = linkList[linkList.length - 1].transform;
    return lastTransform === 10 ? -10 : 10;
  };

  const handleLinkClick = (index) => {
    setInputText((prevText) => prevText + ' ' + linkList[index].text);
    setLinkList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleWordClick = (word) => {
    const nextTransform = getNextTransform();
    setLinkList((prevList) => [
      ...prevList,
      { text: word, transform: nextTransform },
    ]);
    setInputText((prevText) => prevText.replace(word, '').trim());
  };

  useEffect(() => {
    dispatch(libraryActions.getSentencesForLibrary({program_id}));
  }, []);

  const startScramble = () => {
    // const remainingIndices = sentences
    //   .map((_, index) => index)
    //   .filter(index => !displayedSentenceIndices.includes(index));

    // if (remainingIndices.length > 0) {
    //   const randomIndex = remainingIndices[Math.floor(Math.random() * remainingIndices.length)];
    //   setDisplayedSentenceIndices([...displayedSentenceIndices, randomIndex]);
    //   setCurrentSentenceIndex(randomIndex);
    //   const randomSentence = sentences[randomIndex];
    //   setScrambledSentence(scrambleSentence(randomSentence));
    //   setShowTextArea(true);
    //   setShowNextButton(true);
    //   setShowFinishButton(false);
    // } else {
    //   setShowNextButton(false);
    //   setShowFinishButton(true);
    // }

    setShowTextArea(true);
      setShowNextButton(true);
      setShowFinishButton(false);
  };

  const scrambleSentence = (sentence) => {
    // Split the sentence into an array of words
    const words = sentence.split(' ');

    // Unarrange the words by shuffling them
    for (let i = words.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [words[i], words[j]] = [words[j], words[i]];
    }
    setwords(words);
   
    const array = words.map((word, index) => ({
        text: `${word} `,
        transform: null,
      }));
      console.log(array,'get words');
      setLinkList(array);
    // Join the words back into a sentence
    const unarrangedSentence = words.join(' ');
  
    return unarrangedSentence;
  };
  console.log(linkList,'get list');
  const handleNext = () => {

    const remainingIndices = sentences
    .map((_, index) => index)
    .filter(index => !displayedSentenceIndices.includes(index));
    setRemainingSentence(remainingIndices.length);
    console.log(remainingIndices.length);
  if (remainingIndices.length > 0) {
    const randomIndex = remainingIndices[Math.floor(Math.random() * remainingIndices.length)];
    setDisplayedSentenceIndices([...displayedSentenceIndices, randomIndex]);
    setCurrentSentenceIndex(randomIndex);
    const randomSentence = sentences[randomIndex];
    setScrambledSentence(scrambleSentence(randomSentence));
    setUserInput('');
    setShowNextButton(true);
    setShowFinishButton(false);
  } else {
    setShowNextButton(false);
    setShowFinishButton(true);
  }
  };

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
   // setShowNextButton(event.target.value === sentences[currentSentenceIndex]);
  };

  const handleFinish = () => {
    // Handle finish button click
    console.log("Finished!");
  };

  const handleNextSentence = () => {
    // Check if the current sentence matches the corrected sentence
    const correctedSentence = currentSentence.correct;
    if (inputText.trim() === correctedSentence) {
      setCorrectCount((prevCount) => prevCount + 1);
    } else {
      setIncorrectCount((prevCount) => prevCount + 1);
    }

    // Move to the next sentence
    setCurrentSentenceIndex((prevIndex) => prevIndex + 1);

    // Reset input and linkList for the new sentence
    setInputText('');
    setLinkList(wordsArray.map((word, index) => ({
      text: `${word} `,
      transform: index % 2 === 0 ? 10 : -10,
    })));
  };

  const handleBackSentence = () => {
    // Move to the previous sentence if not already at the first sentence
    if (currentSentenceIndex > 0) {
      setCurrentSentenceIndex((prevIndex) => prevIndex - 1);
    }

    // Reset input and linkList for the new sentence
    setInputText('');
    setLinkList(wordsArray.map((word, index) => ({
      text: `${word} `,
      transform: index % 2 === 0 ? 10 : -10,
    })));
  };
  return (
    <section>
    <div className="test sentence">
    <h1 className="sentence-title">Sentence Unscramble</h1>
        {!showTextArea ? sentences.map((sentence, index) => (
        <p key={index}>{`${index + 1}. ${sentence.correct}`}</p>
      )) : ''}
     {!showTextArea ? <button className="btn btn-primary" onClick={startScramble}>Start</button> : ''}
     {showTextArea && (
        <div className="answer-section">
            <p className='correct-answer'>Correct Answers : {correctCount}</p>
            <p className='remaining-sentence'>{`${currentSentenceIndex + 1} out of ${sentences.length}`}</p><br />
            <p className='hear-section'><i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i>&nbsp;
            <i className='fa fa-heart'></i></p>

            <ul style={{ display: 'inline', listStyle: 'none', padding: 0 }}>
        {linkList.map((link, index) => (
          <li
            key={index}
            style={{ display: 'inline', marginRight: '5px' }}
            onClick={() => handleLinkClick(index)}
          >
            <a href="#" style={{ transform: `rotate(${link.transform}deg)`, display :'inline-block' }}>
              {link.text}
            </a>
          </li>
        ))}
      </ul>
      <div>
        {inputText.split(' ').map((word, index) => (
          <span
            key={index}
            onClick={() => handleWordClick(word)}
            style={{ cursor: 'pointer', marginRight: '5px' }}
          >
            {word}
          </span>
        ))}
      </div>
          {/* <textarea value={userInput} onChange={handleInputChange} /> */}
          {showNextButton && <button onClick={handleNextSentence}>Next</button>}
          {showNextButton && <button onClick={handleBackSentence}>Back</button>}
          {showFinishButton && <button onClick={handleFinish}>Finish</button>}
        </div>
      )}

    </div>
    </section>
  );
};
