import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'quiz';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const quizActions = { ...slice.actions, ...extraActions };
export const quizReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
    // const baseUrl = `http://localhost:8001`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        getQuizQuestions : getQuizQuestions(),
        getVocabQuestions:getVocabQuestions(),
        getPrintQuiz:getPrintQuiz(),
        getVocabWritingQuestions:getVocabWritingQuestions(),
    };    

    function getQuizQuestions(cat_id) {
        return createAsyncThunk(
            `${name}/quiz`,
            async (cat_id) => await fetchWrapper.get(`${baseUrl}/quiz/quiz-questions/${cat_id}`)
        );
    }

    function getVocabQuestions() {
        return createAsyncThunk(
            `${name}/quiz`,
            async ({vocab_id,q_start}) => await fetchWrapper.get(`${baseUrl}/quiz/vocab-questions/${vocab_id}?q_start=${q_start}&q_limit=10`)
        );
    }

    function getPrintQuiz() {
        return createAsyncThunk(
            `${name}/quiz`,
            async ({date,class_id,teacher_id,optional}) => await fetchWrapper.post(`${baseUrl}/quiz/printquiz`,{date,class_id,teacher_id,optional})

            //async ({date,classId,teacher_id,optional}) => await fetchWrapper.get(`${baseUrl}/quiz/printquiz?date=${date}&class_id=${classId}&teacher_id=${teacher_id}&optional=${optional}`)
        );
    }

    function getVocabWritingQuestions() {
        return createAsyncThunk(
            `${name}/quiz`,
            async ({vocab_id,q_start}) => await fetchWrapper.get(`${baseUrl}/quiz/vocabwritequestions/${vocab_id}?q_start=${q_start}&q_limit=10`)
        );
    }

   

}



function createExtraReducers() {
    return {
        ...getQuizQuestions(),
        ...getVocabQuestions(),
        ...getPrintQuiz(),
        ...getVocabWritingQuestions(),
    };

    function getQuizQuestions() {
        var { pending, fulfilled, rejected } = extraActions.getQuizQuestions;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const quiz = action.payload;
             
                state.quiz = quiz;
              
            },
            [rejected]: (state, action) => {
                // console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getVocabQuestions() {
        var { pending, fulfilled, rejected } = extraActions.getVocabQuestions;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const quiz = action.payload;
             
                state.quiz = quiz;
              
            },
            [rejected]: (state, action) => {
                // console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }
    function getPrintQuiz() {
        var { pending, fulfilled, rejected } = extraActions.getPrintQuiz;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const quiz = action.payload;
             
                state.quiz = quiz;
              
            },
            [rejected]: (state, action) => {
                // console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    
    function getVocabWritingQuestions() {
        var { pending, fulfilled, rejected } = extraActions.getVocabWritingQuestions;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const quiz = action.payload;
             
                state.quiz = quiz;
              
            },
            [rejected]: (state, action) => {
                // console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }


}
