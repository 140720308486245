import { useEffect , useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { gradeattendenceActions } from '_store';
import Table from "react-bootstrap/Table";
import { history } from '_helpers';
import { Modal, Button } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const localizer = momentLocalizer(moment);
export { HomeWorkMailMonthlyStat };

function HomeWorkMailMonthlyStat() {
  const dispatch = useDispatch();
  const { gradeattendence } = useSelector((x) => x.gradeattendence);
  const { studentId, token } = useParams();
  const [monthName, setMonth] = useState('');
  const [yearName, setYear] = useState('');
console.log(gradeattendence,'hom');
  useEffect(() => {

    var date = token;
    var month = true;
    dispatch(gradeattendenceActions.getGradeAttendanceByTeacher({studentId, date, month}));
    const dateStr = atob(token);
    const dateObj = new Date(dateStr);

      const month1 = dateObj.toLocaleString('en-US', { month: 'long' }).slice(0, 3);
      const year = dateObj.getFullYear();
      setMonth(month1);
      setYear(year);
 }, []);

 const generatePDF = () => {
  const input = document.getElementById('pdf-content');

  // Set a custom canvas size to capture the entire content
  const canvasOptions = {
    width: input.scrollWidth,
    height: input.scrollHeight,
  };

  html2canvas(input, canvasOptions)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the correct width and height for the PDF
      let pdfImgWidth = pdfWidth;
      let pdfImgHeight = pdfWidth / aspectRatio;

      if (pdfImgHeight > pdfHeight) {
        pdfImgHeight = pdfHeight;
        pdfImgWidth = pdfHeight * aspectRatio;
      }

      pdf.addImage(imgData, 'PNG', 0, 0, pdfImgWidth, pdfImgHeight);
      pdf.save('generated-pdf.pdf');
    });
}

const getHWAvg = (attend) => {
  const variables = [
    attend.txtHWRead,
    attend.txtHWList,
    attend.txtHWSpk,
    attend.txtHWWrt,
    attend.txtHWGram,
    attend.txtHWVocab,
  ];

  // Filter out empty variables
  const nonEmptyVariables = variables.filter((variable) => variable !== undefined && variable !== null);

  if (nonEmptyVariables.length === 0) {
    return ''; // All variables were empty, return 'O'
  }

  if (nonEmptyVariables.every((variable) => variable === 'O')) {
    return 'O'; // All non-empty variables are 'O', return 'O'
  }

  return 'X'; // At least one non-empty variable is 'X', return 'X'
};
const getQZAvg = (attend) => {
  const variables = [
    attend.txtQZRead,
    attend.txtQZList,
    attend.txtQZSpk,
    attend.txtQZWrt,
    attend.txtQZGram,
    attend.txtQZVocab,
  ];

  // Filter out empty variables
  const nonEmptyVariables = variables.filter((variable) => variable !== undefined && variable !== null);

  if (nonEmptyVariables.length === 0) {
    return ''; // All variables were empty, return 'O'
  }

  if (nonEmptyVariables.every((variable) => variable === 'O')) {
    return ' O'; // All non-empty variables are 'O', return 'O'
  }

  return 'X'; // At least one non-empty variable is 'X', return 'X'
};

const getHighestGrade = (attend) => {
  const gradeValues = {
    'A+': 5,
    'A': 4,
    'B+': 3,
    'B': 2,
    'C+': 1
  };

  const variables = [
    attend.txtread,
    attend.txtlist,
    attend.txtspek,
    attend.txtwrit,
    attend.txtgramer,
    attend.txtvocab,
  ];

  let highestGradeValue = 0; // Default to 0

  variables.forEach((grade) => {
    if (gradeValues[grade] > highestGradeValue) {
      highestGradeValue = gradeValues[grade];
    }
  });

  // Convert the highest grade value back to the grade string
  let highestGrade = '';
  for (const grade in gradeValues) {
    if (gradeValues[grade] === highestGradeValue) {
      highestGrade = grade;
      break;
    }
  }

  return highestGrade;
};

const getHighestClassAvgGrade = (attend) => {
  const gradeValues = {
    'A+': 5,
    'A': 4,
    'B+': 3,
    'B': 2,
    'C+': 1
  };

  const variables = [
    attend.reading_avg,
    attend.listening_avg,
    attend.speaking_avg,
    attend.writing_avg,
    attend.grammer_avg,
    attend.vocab_avg,
  ];

  let highestGradeValue = 0; // Default to 0

  variables.forEach((grade) => {
    if (gradeValues[grade] > highestGradeValue) {
      highestGradeValue = gradeValues[grade];
    }
  });

  // Convert the highest grade value back to the grade string
  let highestGrade = '';
  for (const grade in gradeValues) {
    if (gradeValues[grade] === highestGradeValue) {
      highestGrade = grade;
      break;
    }
  }

  return highestGrade;
};


    return (

        <div className="grade-table-conatiner"  id="pdf-content">
            <div className='header-title'>
                <h4 className='header-title'>Monthly Report ({monthName} {yearName})</h4>
                <button type="button" onClick={()=> generatePDF()}className="btn btn-success">Download As PDF</button>
               
            </div>
            {(gradeattendence) ? gradeattendence.data.map((attend, index) => (
             
            <div className="col-lg-12" style={{textAlign: 'justify', border: '1px solid'}}>
            <div className='attendence-show'>Student Name : {attend.Student.koreanName} English Name: {attend.Student.firstName}</div>
            <div className='attendence-show'>Date : {attend.hdnAttendeDate}</div>
            <div className='attendence-show'>Attendance : {(attend.attendance == "P") ? 'Present' : 'Absent'}</div>
                  
            <Table id="gradeTable" striped bordered hover>
                  <tr>
                    <td></td>
                    <th>Grades</th>
                    <th>Class Avg.</th>
                    <th>Homework</th>
                    <th>Quiz</th>
                </tr>
                {attend.txtread ? <>
                  <tr>
                      <th>Reading</th>
                      <td>{attend.txtread}</td>
                      <td>{attend.reading_avg}</td>
                      <td>{attend.txtHWRead}</td>
                      <td>{attend.txtQZRead}</td>
                  </tr>
                </> : ''}
                {attend.txtlist ? <>     
    <tr>
        <th>Listening</th>
        <td>{attend.txtlist}</td>
        <td>{attend.listening_avg}</td>
        <td>{attend.txtHWList }</td>
        <td>{attend.txtQZList}</td>
    </tr>
    </> : ''}
    {attend.txtspek ? <> 
    <tr>
        <th>Speaking</th>
        <td>{attend.txtspek}</td>
        <td>{attend.speaking_avg}</td>
        <td>{attend.txtHWSpk}</td>
        <td>{attend.txtQZSpk}</td>
    </tr>
    </> :''}
    {attend.txtwrit ? <> 
    <tr>
        <th>Writing</th>
        <td>{attend.txtwrit}</td>
        <td>{attend.writing_avg}</td>
        <td>{attend.txtHWWrt}</td>
        <td>{attend.txtQZWrt}</td>
    </tr>
    </> :''}
    {attend.txtgramer ? <> 
    <tr>
        <th>Grammar</th>
        <td>{attend.txtgramer}</td>
        <td>{attend.grammer_avg}</td>
        <td>{attend.txtHWGram}</td>
        <td>{attend.txtQZGram}</td>
    </tr>
    </> :''}
    {attend.txtvocab ? <> 
    <tr>
        <th>Vocabulary</th>
        <td>{attend.txtvocab}</td>
        <td>{attend.vocab_avg}</td>
        <td>{attend.txtHWVocab}</td>
        <td>{attend.txtQZVocab}</td>
    </tr>
    </>:''}

    <tr>
        <th>My Avg</th>
        <td>{getHighestGrade(attend)}</td>
        <td>{getHighestClassAvgGrade(attend)}</td>
        <td>{getHWAvg(attend)}</td>
        <td>{getQZAvg(attend)}</td>
    </tr>

    </Table>
    <div className="">
    <span>A+= 81-100</span> <span>A= 61-80</span> <span>B+= 41-60</span>{" "}
    <span>B= 21-40</span> <span>C+= 0-20</span> <span>O=Pass</span>{" "}
    <span>X=Fail</span>{" "}
  </div>
            </div>
            )) : ''}
       
      </div>
      
      
    )
}
