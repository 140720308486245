import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const name = 'quizcategory';

const initialState = createInitialState();

function createInitialState() {
    return {
        user: JSON.parse(localStorage.getItem('user')),
        error: null,
        quizcategory: null,
    };
}

const baseUrl = 'https://api.webtracktechnologies.com.au:8002';


const getquizcategory = createAsyncThunk(
    `${name}/getquizcategory`,
    async ({ student, category }) => {
        try {
            const response = await fetchWrapper.get(`${baseUrl}/quiz/quiz-parts?student=${student}&category=${category}`);
            return response.data; // Adjust this based on your API response structure
        } catch (error) {
            throw error;
        }
    }
);

const quizcategorySlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        
            .addCase(getquizcategory.pending, (state) => {
                state.error = null;
            })
            .addCase(getquizcategory.fulfilled, (state, action) => {
                state.quizcategory = action.payload;
            })
            .addCase(getquizcategory.rejected, (state, action) => {
                state.error = action.error;
                // toast.error(action.error.message, {
                //     position: 'top-right',
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: 'light',
                // });
            });
    },
});

export const quizcategoryActions = {
    ...quizcategorySlice.actions,
    getquizcategory,
};

export const quizcategoryReducer = quizcategorySlice.reducer;
