import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { studyActions } from '_store';
import { useParams } from "react-router-dom";
import { history } from '_helpers';
import axios from "axios";
import $ from 'jquery';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export { AssignQuestions };

function AssignQuestions() {
  const dispatch = useDispatch();
  const { passage } = useSelector((x) => x.passage);
  const [showTestDialog, setShowTestDialog] = useState(false);
  const [testText, setTestText] = useState('');
  const [testLevelText, setTestLevel] = useState('');
  const { user: authUser } = useSelector((x) => x.auth);
  const [selectedTest, setSelectedTest] = useState(null);
  const { record_id, level, pageType } = useParams();
  const [type, setType] = useState('');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [assignedPrograms, setAssignedPrograms] = useState([]);
  const [checkboxTitlesVQ, setCheckboxTitlesForVocabQuiz] = useState([]);
  const [checkboxTitlesVLCH, setCheckboxTitlesForVLCH] = useState([]);
  const [checkboxTitlesVLCM, setCheckboxTitlesForVLCM] = useState([]);
  const [checkboxTitlesVROL, setCheckboxTitlesForVROL] = useState([]);
  const [sortedLevels, setSortedLevels] = useState([]);
useEffect( () => {
  var study_test_id = record_id;
  dispatch(studyActions.getTestListByLevel({ study_test_id, level }));
  setType('Reading');

  async function fetchData() {
    var test_id = study_test_id;
    const resultRes = await axios.get(
      "https://api.webtracktechnologies.com.au:8002/studytest/assigned_programs/" + test_id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          // Add other headers if needed
        },
      }
    );
      if(resultRes){
        setAssignedPrograms(resultRes.data);
      }
    
  }
  fetchData();

}, []);
//console.log(assignedPrograms, 'get res');
const compareLevels = (a, b) => {
  // Check if either of the levels is 'P' or 'K'
  if (a.name === 'P') {
      return -1; // Move 'P' to the beginning
  } else if (b.name === 'P') {
      return 1; // Move 'P' to the beginning
  } else if (a.name === 'K') {
      return a.name === 'P' ? 1 : -1; // Move 'K' to the beginning after 'P'
  } else if (b.name === 'K') {
      return b.name === 'P' ? -1 : 1; // Move 'K' to the beginning after 'P'
  } else {
      // Otherwise, maintain the original order
      return a.id - b.id;
  }
};
useEffect(() => {
  if (passage && passage.data) {
    if (passage.data.assignedlevels) {
      //console.log(passage.data.assignedprograms,'get assigned');return false;
      // Add blink_text class to the corresponding sidebar div
      passage.data.assignedlevels.forEach(level => {
        if (level !== null && level !== 'Level P') {
          const levelMatch = level.match(/\d+/);
          if (levelMatch) {
            const levelNumber = parseInt(levelMatch[0]);
            console.log(levelNumber, 'get level');
            // Find the corresponding sidebar div by matching the level number with the div number
            const sidebarDiv = document.querySelector(`.sidebar-fulldiv-${levelNumber}`);
            if (sidebarDiv) {
              sidebarDiv.classList.add('blink_text');
            }
          }
        }
      });
    }
    if (passage.data.assignedvocabs) {
      let filteredObjects = passage.data.assignedvocabs.filter(item => item.type === 'VocabQuiz').map(item => item.vocabnamess);
      let filteredObjects1 = passage.data.assignedvocabs.filter(item => item.type === 'VocabLISTENING: Listen and choose what you hear').map(item => item.vocabnamess);
      let filteredObjects2 = passage.data.assignedvocabs.filter(item => item.type === 'Vocabulary: Listen and choose the correct meaning').map(item => item.vocabnamess);
      let filteredObjects3 = passage.data.assignedvocabs.filter(item => item.type === 'Vocabulary: Read out loud').map(item => item.vocabnamess);
      setCheckboxTitlesForVocabQuiz(filteredObjects);
      setCheckboxTitlesForVLCH(filteredObjects1);
      setCheckboxTitlesForVLCM(filteredObjects2);
      setCheckboxTitlesForVROL(filteredObjects3);
      //setCheckboxTitlesForVLCH(filteredObjects);
    }

    var levelsVar = [
      {
          "name": "Level 1",
          "id": 1
      },
      {
          "name": "Level 2",
          "id": 2
      },
      {
          "name": "Level 3",
          "id": 3
      },
      {
          "name": "Level 4",
          "id": 4
      },
      {
        "name": "Level 5",
        "id": 5
    },
      {
          "name": "Level 6",
          "id": 6
      },
      {
          "name": "Level 7",
          "id": 8
      },
      {
          "name": "Level 8",
          "id": 9
      },
      {
          "name": "Level 9",
          "id": 10
      },
      {
          "name": "Level 10",
          "id": 11
      },
      {
          "name": "Level P",
          "id": 12
      },
      {
          "name": "Level K",
          "id": 13
      },
      {
          "name": "Level 11",
          "id": 14
      },
      {
          "name": "Level 12",
          "id": 15
      },
      {
          "name": "Level 13",
          "id": 16
      }
  ];
    // Add blink_text class to the corresponding sidebar div
    const modifiedLevels = levelsVar.map(level => ({
      ...level,
      name: level.name.replace('Level ', '') // Remove "Level" from the name
  }));

    const sortedLevels = [...modifiedLevels].sort(compareLevels);
    setSortedLevels(sortedLevels);
  }
}, [passage]);
function numberToWord(number) {
  // Convert the number to words using a library or custom logic
  // For simplicity, let's assume a simple conversion for numbers 0-99
  const units = ['', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE'];
  const teens = ['TEN', 'ELEVEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN'];
  const tens = ['', '', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

  if (number === 0) {
    return 'ZERO';
  }

  // Function to convert a two-digit number to words
  const twoDigitToWord = (num) => {
    if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else {
      return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ` ${units[num % 10]}` : '');
    }
  };

  // Handle numbers up to 99
  if (number < 100) {
    return twoDigitToWord(number);
  } else {
    return 'OUT OF RANGE';
  }
}
const handleCheckboxChange = async (e, id, typeText, title,vocab_q_start,vocab_q_end) => {
  const isChecked = e.target.checked;
//console.log(typeText,'isChecked');return false;
  // Extracting the program ID from the checkbox ID
  var idParam = id.split('-');
console.log(title,'get title');
if(typeText == 'Vocabulary Quiz'){
    setCheckboxTitlesForVocabQuiz([...checkboxTitlesVQ, title]);
  }else if(typeText == 'VocabLISTENING: Listen and choose what you hear'){
    setCheckboxTitlesForVLCH([...checkboxTitlesVLCH, title]);
  }else if(typeText == 'Vocabulary: Listen and choose the correct meaning'){
    setCheckboxTitlesForVLCM([...checkboxTitlesVLCM, title]);
  }else if(typeText == 'Vocabulary: Read out loud'){
    setCheckboxTitlesForVROL([...checkboxTitlesVROL, title]);
  }
//setCheckboxTitles([...checkboxTitles, title]);
  // Find all checkboxes with the same title using jQuery
  const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);

  // Check if any other checkboxes with the same title are selected
  const isOtherCheckboxSelected = selectedCheckboxes.some(
    (checkboxId) => checkboxId !== id && checkboxId.includes(title)
  );

  // If other checkboxes with the same title are selected, prompt the user for confirmation
  if (!isOtherCheckboxSelected) {
    if(isChecked){
      var confirmed = window.confirm(`Do you want to assign all the related programs?`);
    }else{
      var confirmed = window.confirm(`Do you want to unassign all the related programs?`);
    }
 

    // If the user confirms, update the state accordingly
    if (confirmed) {
      const checkboxesToUpdate = checkboxesWithSameTitle.toArray().map((checkbox) => {
        return checkbox.value;
      });
      console.log(checkboxesToUpdate,'get selected');
      // Update selectedCheckboxes based on the checkbox change
      if (isChecked) {
        setSelectedCheckboxes([...selectedCheckboxes, ...checkboxesToUpdate]);
        if(pageType == 'classroom' || (typeText == 'Vocabulary Quiz' || 
          typeText == 'VocabLISTENING: Listen and choose what you hear' || 
          typeText == 'Vocabulary: Listen and choose the correct meaning' || 
          typeText == 'Vocabulary: Read out loud')){
          var option = {
            // category: location.state.vocabulary.id,
            Type: checkboxesToUpdate,
            qId: idParam[0],
            // vocab_id:idParam[0],
            // vocab_q_start:vocab_q_start,
            // vocab_q_end:vocab_q_end,
            TestId:record_id,
           }
           const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
            if(checkboxesWithSameTitle.length > 0){
              checkboxesWithSameTitle.each(function() {
                $(this).parent().addClass('highlight_li_text');
                $(this).prop('checked', true);
              })
            }
        }else{
          var option = {
            // category: location.state.vocabulary.id,
            TestId:record_id,
            Type: checkboxesToUpdate,
            qId: idParam[0],
           }
           const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
            if(checkboxesWithSameTitle.length > 0){
              checkboxesWithSameTitle.each(function() {
                $(this).parent().addClass('highlight_li_text');
                $(this).prop('checked', true);
              })
            }
        }
        
      } else {
        const updatedCheckboxes = selectedCheckboxes.filter(
          (checkboxId) => !checkboxesToUpdate.includes(checkboxId)
        );
        if(pageType == 'classroom' || (typeText == 'Vocabulary Quiz' || 
          typeText == 'VocabLISTENING: Listen and choose what you hear' || 
          typeText == 'Vocabulary: Listen and choose the correct meaning' || 
          typeText == 'Vocabulary: Read out loud')){
        var option = {
          // category: location.state.vocabulary.id,
          Type: checkboxesToUpdate,
          qId: idParam[0],
          delete:true,
          // vocab_id:idParam[0],
          //   vocab_q_start:vocab_q_start,
          //   vocab_q_end:vocab_q_end,
          TestId:record_id,
         }
         const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
            if(checkboxesWithSameTitle.length > 0){
              checkboxesWithSameTitle.each(function() {
                $(this).parent().removeClass('highlight_li_text');
                $(this).prop('checked', false);
              })
            }
        }else{
          var option = {
            // category: location.state.vocabulary.id,
            TestId:record_id,
            Type: checkboxesToUpdate,
            qId: idParam[0],
            delete:true,
           }
           const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
           if(checkboxesWithSameTitle.length > 0){
             checkboxesWithSameTitle.each(function() {
               $(this).parent().removeClass('highlight_li_text');
               $(this).prop('checked', false);
             })
           }
        }
        setSelectedCheckboxes(updatedCheckboxes);
      }
    } else {
      // If the user cancels, revert the checkbox state
     
      //console.log($(`input[type="checkbox"][value="${e.target.value}"]`),'target');return false;
      e.target.checked = isChecked;
      if(pageType == 'classroom' || (typeText == 'Vocabulary Quiz' || 
        typeText == 'VocabLISTENING: Listen and choose what you hear' || 
        typeText == 'Vocabulary: Listen and choose the correct meaning' || 
        typeText == 'Vocabulary: Read out loud')){//alert('you got it');
        const checkboxesWithSameTitle = $(`input[type="checkbox"][value="${e.target.value}"]`);
        if(checkboxesWithSameTitle.length > 0){
          //checkboxesWithSameTitle.each(function() {
            checkboxesWithSameTitle.parent().addClass('highlight_li_text');
            checkboxesWithSameTitle.prop('checked', true);
          //})
        }
      var option = {
        // category: location.state.vocabulary.id,
        Type: id,
        qId: idParam[0],
        // vocab_id:idParam[0],
        // vocab_q_start:vocab_q_start,
        // vocab_q_end:vocab_q_end,
        TestId:record_id,
       }
      }else{
        var option = {
          // category: location.state.vocabulary.id,
          TestId:record_id,
          Type: typeText,
          qId: idParam[0],
         
         }
         const checkboxesWithSameTitle = $(`input[type="checkbox"][value="${e.target.value}"]`);
         if(checkboxesWithSameTitle.length > 0){
           //checkboxesWithSameTitle.each(function() {
             checkboxesWithSameTitle.parent().addClass('highlight_li_text');
             checkboxesWithSameTitle.prop('checked', true);
           //})
         }
      }
    }
  } else {
    // If only one checkbox with the same title, update the state accordingly
    if (isChecked) {
      setSelectedCheckboxes([...selectedCheckboxes, id]);
      if(pageType == 'classroom' || (typeText == 'Vocabulary Quiz' || 
        typeText == 'VocabLISTENING: Listen and choose what you hear' || 
        typeText == 'Vocabulary: Listen and choose the correct meaning' || 
        typeText == 'Vocabulary: Read out loud')){
      var option = {
        // category: location.state.vocabulary.id,
        Type: id,
        qId: idParam[0],
        // vocab_id:idParam[0],
        // vocab_q_start:vocab_q_start,
        // vocab_q_end:vocab_q_end,
        TestId:record_id,
       }
       const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
       if(checkboxesWithSameTitle.length > 0){
         //checkboxesWithSameTitle.each(function() {
           checkboxesWithSameTitle.parent().addClass('highlight_li_text');
           checkboxesWithSameTitle.prop('checked', true);
         //})
       }
      }else{
        const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
        if(checkboxesWithSameTitle.length > 0){
          //checkboxesWithSameTitle.each(function() {
            checkboxesWithSameTitle.parent().addClass('highlight_li_text');
            checkboxesWithSameTitle.prop('checked', true);
          //})
        }
        var option = {
          // category: location.state.vocabulary.id,
          TestId:record_id,
          Type: typeText,
          qId: idParam[0],
         }
      }
    } else {
      const updatedCheckboxes = selectedCheckboxes.filter((checkboxId) => checkboxId !== id);
      setSelectedCheckboxes(updatedCheckboxes);
      if(pageType == 'classroom' || (typeText == 'Vocabulary Quiz' || 
        typeText == 'VocabLISTENING: Listen and choose what you hear' || 
        typeText == 'Vocabulary: Listen and choose the correct meaning' || 
        typeText == 'Vocabulary: Read out loud')){
      var option = {
                  // category: location.state.vocabulary.id,
 
                  Type: id,
                  qId: idParam[0],
                  delete:true,
        //           vocab_id:idParam[0],
        // vocab_q_start:vocab_q_start,
        // vocab_q_end:vocab_q_end,
        TestId:record_id,
                 }
                 
                }else{
                  var option = {
                    // category: location.state.vocabulary.id,
                    TestId:record_id,
                    Type: typeText,
                    qId: idParam[0],
                    delete:true,
                   }
                }
                const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
                if(checkboxesWithSameTitle.length > 0){
                  checkboxesWithSameTitle.each(function() {
                    $(this).parent().removeClass('highlight_li_text');
                    $(this).prop('checked', false);
                  })
                }
    }
  }
  if(pageType == 'classroom' || (typeText == 'Vocabulary Quiz' || 
    typeText == 'VocabLISTENING: Listen and choose what you hear' || 
    typeText == 'Vocabulary: Listen and choose the correct meaning' || 
    typeText == 'Vocabulary: Read out loud')){
    const resultRes = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/studytest/assign/vocabprogram",
      option,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          // Add other headers if needed
        },
      }
    );
    if(resultRes){
    
      if(resultRes.data.message){
        toast.success(resultRes.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }else{
        toast.success('Something went wrong.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
      //window.location.href = '/classroom/study-test';
    }
  }else{

    const resultRes = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/studytest/assign/program",
      option,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          // Add other headers if needed
        },
      }
    );
    if(resultRes){
    
      alert(resultRes.data.message);
      if(resultRes.data.message == 'Study Programs removed Successfuly'){
        const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
      if(checkboxesWithSameTitle.length > 0){
        checkboxesWithSameTitle.each(function() {
          $(this).parent().removeClass('highlight_li_text');
          $(this).prop('checked', false);
        })
      }
    }else if(resultRes.data.message == 'All Study Programs deleted Successfuly'){
        const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
      if(checkboxesWithSameTitle.length > 0){
        checkboxesWithSameTitle.each(function() {
          $(this).parent().removeClass('highlight_li_text');
          $(this).prop('checked', false);
        })
      }
      }
      //window.location.href = '/classroom/study-test';
    }

  }


};
//console.log(checkboxTitles,'get selected');
    return (

      <section className="custom-sign-assign-question">
      <div className="container">
  <div className="row">
    <h2>{(passage && passage.data) ? passage.data.test_title : ''}</h2>
    <a href="/classroom/study-test">Back to Test</a>
    <div className="level-box">
      <div className="leven-box-sidebar">
        {/* <div className="sidebar-fulldiv-1 blink_text">
          <div className="sidebar-box">
            <a href={`/study/assign-questions/${record_id}/1/Test`}>
              <span className="level">1</span>
              <span className="leven-name">LEVEL ONE</span>
            </a>
          </div>
        </div> */}
        {(sortedLevels) ? sortedLevels.map((level, index) => (
        <div className={`sidebar-fulldiv-${level.name}`}>
          <div className="sidebar-box">
            <a href={`/study/assign-questions/${record_id}/${level.name}/Test`}>
              <span className="level">{level.name}</span>
              <span className="leven-name">LEVEL ONE</span>
            </a>
          </div>
        </div>
      )) : ''}

      </div>
      <div className="leven-box-content">
        <h2>LEVEL {numberToWord(level)} ENGLISH</h2>
        <p>
          LIKE Study offers more than 100 LEVEL ONE language arts skills to
          explore and learn! Not sure where to start? Go to your personalized
          Recommendations wall and choose a skill that looks interesting—they’re
          all picked just for you!
        </p>
        <div className="nav-q">
          <a href="#reading">Reading</a>
          <a href="#listing">Listening</a>
          <a href="#vocabulary">Vocabulary</a>
        </div>
        <div className="nav-q">
          <a href="#A">A</a>
          <a href="#A1">A1</a>
          <a href="#A2">A2</a>
        </div>
        <div className="content-div" id="reading">
          <h4>{type}</h4>
          <h4>Reading: Text Read out Loud</h4>
          <h6 id="A">A. Reading: Text Read out Loud</h6>
          
          <ul>
          {(passage && passage.data && passage.data.programs && passage.data.programs["Read out Loud"]) ? passage.data.programs["Read out Loud"].map((pass, index) => (
            <li className={(selectedCheckboxes.includes(pass.id+'-ROL') || (assignedPrograms.data && assignedPrograms.data.programs && assignedPrograms.data.programs["Read out Loud"] &&
              assignedPrograms.data.programs["Read out Loud"].some((program) => program.id === pass.id))) ? 'highlight_li_text' : ''}>
              <input type="checkbox" title={pass.id} name="category" value={pass.id+'-ROL'} onChange={(e) => handleCheckboxChange(e, pass.id+'-ROL', 'Read out Loud',pass.id)}
            checked={selectedCheckboxes.includes(pass.id+'-ROL') || (assignedPrograms.data && assignedPrograms.data.programs && assignedPrograms.data.programs["Read out Loud"] &&
                  assignedPrograms.data.programs["Read out Loud"].some((program) => program.id === pass.id))}
             />{index+1}. {pass.categorytitle}</li>
            )) : <h5>No Records Found.</h5>}
          </ul>
        </div>
        <div className="content-div" id="listing">
          <h4>READING</h4>
          <h4>Reading: Multiple Choice Quiz</h4>
          <h6 id="A1">A1. Reading: Multiple Choice Quiz</h6>
          <ul>
          {(passage && passage.data && passage.data.programs && passage.data.programs["Reading Multiple Choice"]) ? passage.data.programs["Reading Multiple Choice"].map((pass, index) => (
            <li className={(selectedCheckboxes.includes(pass.id+'-RMC') || (assignedPrograms.data && assignedPrograms.data.programs && assignedPrograms.data.programs["Reading Multiple Choice"] &&
              assignedPrograms.data.programs["Reading Multiple Choice"].some((program) => program.id === pass.id))) ? 'highlight_li_text' : ''}><input type="checkbox" title={pass.id} name="category" value={pass.id+'-RMC'} onChange={(e) => handleCheckboxChange(e, pass.id+'-RMC', 'Reading Multiple Choice',pass.id)}
            checked={selectedCheckboxes.includes(pass.id+'-RMC') || (assignedPrograms.data && assignedPrograms.data.programs && assignedPrograms.data.programs["Reading Multiple Choice"] &&
            assignedPrograms.data.programs["Reading Multiple Choice"].some((program) => program.id === pass.id))} />{index+1}. {pass.categorytitle}</li>
            )) : <h5>No Records Found.</h5>}
          </ul>
        </div>
        <div className="content-div" id="listing">
          <h4>LISTENING</h4>
          <h4>Listening Multiple Choice</h4>
          <h6 id="A2">A2. Reading: Multiple Choice Quiz</h6>
          <ul>
          {(passage && passage.data && passage.data.programs && passage.data.programs["Listening Multiple Choice"]) ? passage.data.programs["Listening Multiple Choice"].map((pass, index) => (
            <li className={(selectedCheckboxes.includes(pass.id+'-LMC') || (assignedPrograms.data && assignedPrograms.data.programs && assignedPrograms.data.programs["Listening Multiple Choice"] &&
              assignedPrograms.data.programs["Listening Multiple Choice"].some((program) => program.id === pass.id))) ? 'highlight_li_text' : ''}><input type="checkbox" title={pass.id} name="category" value={pass.id+'-LMC'} onChange={(e) => handleCheckboxChange(e, pass.id+'-LMC', 'Listening Multiple Choice', pass.id)}
            checked={selectedCheckboxes.includes(pass.id+'-LMC') || (assignedPrograms.data && assignedPrograms.data.programs && assignedPrograms.data.programs["Listening Multiple Choice"] &&
            assignedPrograms.data.programs["Listening Multiple Choice"].some((program) => program.id === pass.id))} />{index+1}. {pass.categorytitle}</li>
            )) : <h5>No Records Found.</h5>}
          </ul>
        </div>
        <div className="content-div" id="vocabulary">
          <h4>VOCABULARY</h4>
          <h4>Vocabulary Quiz</h4>
          <h6 id="A3">A3. Vocabulary Quiz</h6>
         
          {(passage && passage.data && passage.data.programs["VocabQuiz"]) ? passage.data.programs["VocabQuiz"].map((dictate, index) => (
           <ul>
             <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {dictate.vocabName} </h4>
          {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
           <li className={(checkboxTitlesVQ.includes(question.title)) ? 'highlight_li_text' : ''}>
            <input type="checkbox" title={question.title} name="category" value={dictate.vocabid+'-VQuiz-'+question.question_start+'-'+question.question_end} onChange={(e) => handleCheckboxChange(e, dictate.vocabid+'-VQuiz-'+question.question_start+'-'+question.question_end, 'Vocabulary Quiz', question.title,question.question_start,question.question_end)}
            checked={checkboxTitlesVQ.includes(question.title) } />
            {index1 +1}. {question.title}</li>
            )) : <h5>No Records Found.</h5>}
            </ul>
            )) : <h5>No Records Found.</h5>}

          <h4>Vocabulary: Listen and choose what you hear</h4>
          <h6 id="A4">A4. Vocabulary: Listen and choose what you hear</h6>
         
          {(passage && passage.data && passage.data.programs["VocabLISTENING: Listen and choose what you hear"]) ? passage.data.programs["VocabLISTENING: Listen and choose what you hear"].map((dictate, index) => (
           <ul>
             <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {dictate.vocabName} </h4>
          {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
           <li className={(checkboxTitlesVLCH.includes(question.title)) ? 'highlight_li_text' : ''}><input type="checkbox" title={question.title} name="category" value={dictate.vocabid+'-VLCH-'+question.question_start+'-'+question.question_end} onChange={(e) => handleCheckboxChange(e, dictate.vocabid+'-VLCH-'+question.question_start+'-'+question.question_end, 'VocabLISTENING: Listen and choose what you hear', question.title,question.question_start,question.question_end)}
            checked={checkboxTitlesVLCH.includes(question.title) } />{index1 +1}. {question.title} </li>
            )) : <h5>No Records Found.</h5>}
            </ul>
            )) : <h5>No Records Found.</h5>}

          <h4>Vocabulary: Listen and choose the correct meaning</h4>
          <h6 id="A5">A5. Vocabulary: Listen and choose the correct meaning</h6>
         
          {(passage && passage.data && passage.data.programs["Vocabulary: Listen and choose the correct meaning"]) ? passage.data.programs["Vocabulary: Listen and choose the correct meaning"].map((dictate, index) => (
           <ul>
             <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {dictate.vocabName} </h4>
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            <li className={(checkboxTitlesVLCM.includes(question.title)) ? 'highlight_li_text' : ''}><input type="checkbox" title={question.title} name="category" value={dictate.vocabid+'-VLCM-'+question.question_start+'-'+question.question_end} onChange={(e) => handleCheckboxChange(e, dictate.vocabid+'-VLCM-'+question.question_start+'-'+question.question_end, 'Vocabulary: Listen and choose the correct meaning', question.title,question.question_start,question.question_end)}
              checked={checkboxTitlesVLCM.includes(question.title) } />{index1 +1}. {question.title}</li>
              )) : <h5>No Records Found.</h5>}
              </ul>
              )) : <h5>No Records Found.</h5>}

<h4>Vocabulary: Read out loud</h4>
          <h6 id="A6">A6. Vocabulary: Read out loud</h6>
         
          {(passage && passage.data && passage.data.programs["Vocabulary: Read out loud"]) ? passage.data.programs["Vocabulary: Read out loud"].map((dictate, index) => (
           <ul>
             <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {dictate.vocabName} </h4>
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            <li className={(checkboxTitlesVROL.includes(question.title)) ? 'highlight_li_text' : ''}><input type="checkbox" title={question.title} name="category" value={dictate.vocabid+'-VROL-'+question.question_start+'-'+question.question_end} onChange={(e) => handleCheckboxChange(e, dictate.vocabid+'-VROL-'+question.question_start+'-'+question.question_end, 'Vocabulary: Read out loud', question.title,question.question_start,question.question_end)}
              checked={checkboxTitlesVROL.includes(question.title) } />{index1 +1}. {question.title}</li>
              )) : <h5>No Records Found.</h5>}
              </ul>
              )) : <h5>No Records Found.</h5>}
          
        </div>
      </div>
    </div>
  </div>
</div>

    </section>
      
    )
}
