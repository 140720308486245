import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { fileActions,studyActions,classesActions } from '_store';
import { useParams } from "react-router-dom";
import { history } from '_helpers';
import axios from "axios";
import $ from 'jquery';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export { StudentStudy };

function StudentStudy() {
  const dispatch = useDispatch();
  const { passage } = useSelector((x) => x.passage);
  const { files } = useSelector((x) => x.files);
  const [showTestDialog, setShowTestDialog] = useState(false);
  const [testText, setTestText] = useState('');
  const [testLevelText, setTestLevel] = useState('');
  const { user: authUser } = useSelector((x) => x.auth);
  const [selectedTest, setSelectedTest] = useState(null);
  const { record_id, level, student_id, class_id, date } = useParams();
  const [type, setType] = useState('');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [assignedPrograms, setAssignedPrograms] = useState([]);
  const [count, setCount] = useState(1);
  const { classes } = useSelector((x) => x.classes);
  const [sortedLevels, setSortedLevels] = useState([]);
  const [checkboxTitles, setCheckboxTitles] = useState([]);
  const [studentId, setStudentId] = useState();
  const [currentLevel, setCurrentLevel] = useState();

useEffect( () => {
  var classId = class_id;
  dispatch(classesActions.getClassById({ classId, date }));
  const type = 'vocab';
 dispatch(studyActions.getSubprogramsForStudy({level,student_id,type,date}));
  setType('Reading');

}, []);

// useEffect(() => {
//   const fetchSubprograms = async () => {
//     try {
//       const student_id = authUser.user.student_id;

//       // First API call
//       const type1 = "vocab";
//       await dispatch(studyActions.getSubprogramsForStudy({ level, student_id, type: type1 }));

//       // Second API call after the first completes
//       // const type2 = "all";
//       // await dispatch(studyActions.getSubprogramsForStudy({ level, student_id, type: type2 }));

//     } catch (error) {
//       console.error("Error fetching subprograms:", error);
//     } finally {
//       setLoading(false); // Hide loader after both API calls finish
//     }
//   };

//   fetchSubprograms();
//   var classId = class_id;
//   dispatch(classesActions.getClassById({ classId, date }));
//   setType("Reading"); // Assuming `setType` is defined
// }, [dispatch, authUser]);

useEffect(() => {
  // Extract the studentId from the URL on component mount
  const urlParts = window.location.pathname.split('/');
  const currentStudentId = urlParts[3]; // Assuming studentId is the 4th segment
  setStudentId(currentStudentId);
  const currentLevel = urlParts[2];
  setCurrentLevel(currentLevel);

}, []);
//console.log(assignedPrograms, 'get res');
console.log(classes,'get class');
//return false;

function numberToWord(number) {
  // Convert the number to words using a library or custom logic
  // For simplicity, let's assume a simple conversion for numbers 0-99
  const units = ['', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE'];
  const teens = ['TEN', 'ELEVEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN'];
  const tens = ['', '', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

  if (number === 0) {
    return 'ZERO';
  }

  // Function to convert a two-digit number to words
  const twoDigitToWord = (num) => {
    if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else {
      return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ` ${units[num % 10]}` : '');
    }
  };

  // Handle numbers up to 99
  if (number < 100) {
    return twoDigitToWord(number);
  } else {
    return 'OUT OF RANGE';
  }
}
const highlightedIds = [];

const findHighlightedIds = () => {

  const listItems = document.querySelectorAll('.content-div li');
  listItems.forEach((item, index) => {
    if (item.classList.contains('highlight_li_text')) {
      let parentElement = item.parentElement;
      const h4Element = parentElement.querySelector('h4');
      let nextH4Id = '';
      if (h4Element) {
        nextH4Id = h4Element.id;
      }else{
        nextH4Id = item.querySelector('h4').id;

      }
      highlightedIds.push(nextH4Id);
  
      // const nextH4Id = item.querySelector('h4').id;
      // highlightedIds.push(nextH4Id);
    }
  });

};
findHighlightedIds();

console.log(highlightedIds,'get ids');

const isHighlighted = (item) => {
  return highlightedIds.includes(item);
};
const handleCheckboxChange = async (e, id, typeText, title, vocab_q_start, vocab_q_end, categoryTitle) => {
  const isChecked = e.target.checked; // Checkbox checked status
  const idParam = id.split('-'); // Extract ID parameters
  const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`); // Related checkboxes

  // Action type: "Assign" or "Unassign"
  const actionType = isChecked ? 'assign' : 'unassign';

  // Confirmation popup
  const confirmed = window.confirm(
    isChecked
      ? `Do you want to assign all the related programs?`
      : `Do you want to unassign all the related programs?`
  );

  if (actionType === 'assign') {
    if (confirmed) {
      const deleteStatus = false;
     
      // Assign all related checkboxes
      const checkboxesToUpdate = checkboxesWithSameTitle.toArray().map((checkbox) => {
        const checkboxData = checkbox.value.split('-');
      if(checkboxData[1] == 'VQuiz' || checkboxData[1] == 'VLCH' || checkboxData[1] == 'VLCM' || checkboxData[1] == 'VROL'){
        var main_type = 'Vocabulary';
      }else if(checkboxData[1] == 'LMC'){
        var main_type = 'LMC';
      }else if(checkboxData[1] == 'RMC'){
        var main_type = 'RMC';
      }else if(checkboxData[1] == 'ROL'){
        var main_type = 'ROL';
      }
        return {
          id: checkboxData[0],
          type: checkboxData[1],
          q_start: checkboxData[2],
          q_end: checkboxData[3],
          main_type: main_type,
        };
      });

         	const formData = new FormData();
				formData.append("teacher_id", authUser.user.teacher_id);
				//formData.append("class_id", class_id);
				formData.append("student_id", student_id);
				
				formData.append("type", 'Study');
				formData.append("category", typeText);
        if(typeText == 'Reading Multiple Choice'){
          formData.append("subtype", 'RMC');
          formData.append("homework_text", categoryTitle);
        }else if(typeText == 'Listening Multiple Choice'){
          formData.append("subtype", 'LMC');
          formData.append("homework_text", categoryTitle);
        }else if(typeText == 'Read out Loud'){
          formData.append("subtype", 'ROL');
          formData.append("homework_text", categoryTitle);
        }else{
          formData.append("homework_text", title);
        }
				formData.append("study_program_id", JSON.stringify(checkboxesToUpdate));
        formData.append("delete", deleteStatus);
        formData.append("level", "Level "+level);
				const responseHomework = await axios.post(
				  "https://api.webtracktechnologies.com.au:8002/homework/study/create",
				  formData,
				  {
					headers: {
					  'Authorization': `Bearer ${localStorage.getItem('token')}`,
					  'Content-Type': 'application/json', // Make sure to set the content type based on your form data
					},
				  }
				);
        if(responseHomework){
          if(responseHomework.data.message){
            
           // Find all checkboxes with the same title using jQuery
           if(!deleteStatus){
            const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
            if(checkboxesWithSameTitle.length > 0){
              checkboxesWithSameTitle.each(function() {
                $(this).parent().addClass('highlight_li_text');
                $(this).prop('checked', true);
                const checkboxCategory = $(this).attr("value");
 // Find matching data in the response based on the category
 const matchingData = responseHomework.data.data.find(
  (item) => (item.vocab_id+'-'+item.category+'-'+item.q_start+'-'+item.q_end) === checkboxCategory
);

if(matchingData){
  $(this).attr('data-homework', matchingData.id);
}
                var getAlpha = $(this).attr('data-tag');
                //alert(getAlpha);
                const linkQ = document.querySelector(`.nav-q a[href="#${getAlpha}"]`);

                // If found, add the class 'tag_highlight'
                if (linkQ) {
                  linkQ.classList.add('tag_highlight');
                }
              });
            
            }

            toast.success('Program assigned successfully.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
           }else{
            const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
if(checkboxesWithSameTitle.length > 0){
  checkboxesWithSameTitle.each(function() {
    $(this).parent().removeClass('highlight_li_text');
    $(this).prop('checked', false);
    var getAlpha = $(this).attr('data-tag');

    const linkQ = document.querySelector(`.nav-q a[href="#${getAlpha}"]`);

    // If found, add the class 'tag_highlight'
    if (linkQ) {
      linkQ.classList.remove('tag_highlight');
    }
  });

}

toast.success('Program unassigned successfully.', {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  });

// const updatedTitles = checkboxTitles.filter(title => title !== title);
//     // Update the state with the new array
//     setCheckboxTitles(updatedTitles);
           }


            
          }else{
            toast.success('Something went wrong.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
          //alert(resultRes.data.message);
        }
    } else {
      // Assign only the clicked checkbox
      const deleteStatus = false;
      const checkboxData = id.split('-');
      if(checkboxData[1] == 'VQuiz' || checkboxData[1] == 'VLCH' || checkboxData[1] == 'VLCM' || checkboxData[1] == 'VROL'){
        var main_type = 'Vocabulary';
      }
      const checkboxesToUpdate = [
        {
          id: checkboxData[0],
          type: checkboxData[1],
          q_start: vocab_q_start,
          q_end: vocab_q_end,
          main_type: main_type,
        },
      ];

      const formData = new FormData();
      formData.append("teacher_id", authUser.user.teacher_id);
     // formData.append("class_id", class_id);
      formData.append("student_id", student_id);
     
      formData.append("type", 'Study');
      formData.append("category", typeText);
      if(typeText == 'Reading Multiple Choice'){
        formData.append("subtype", 'RMC');
        formData.append("homework_text", categoryTitle);
      }else if(typeText == 'Listening Multiple Choice'){
        formData.append("subtype", 'LMC');
        formData.append("homework_text", categoryTitle);
      }else if(typeText == 'Read out Loud'){
        formData.append("subtype", 'ROL');
        formData.append("homework_text", categoryTitle);
      }else{
        formData.append("homework_text", title);
      }
      formData.append("study_program_id", JSON.stringify(checkboxesToUpdate));
      formData.append("delete", deleteStatus);
      formData.append("level", "Level "+level);
      const responseHomework = await axios.post(
        "https://api.webtracktechnologies.com.au:8002/homework/study/create",
        formData,
        {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json', // Make sure to set the content type based on your form data
        },
        }
      );
      if(responseHomework){
        if(responseHomework.data.message){
          
         // Find all checkboxes with the same title using jQuery
         if(!deleteStatus){
          const checkboxesWithSameTitle = $(`input[type="checkbox"][value="${id}"]`);
          if(checkboxesWithSameTitle.length > 0){
            checkboxesWithSameTitle.each(function() {
              $(this).parent().addClass('highlight_li_text');
              $(this).prop('checked', true);
              //console.log(responseHomework);return false;
              $(this).attr('data-homework', responseHomework.data.data[0].id);
              var getAlpha = $(this).attr('data-tag');
              //alert(getAlpha);
              const linkQ = document.querySelector(`.nav-q a[href="#${getAlpha}"]`);

              // If found, add the class 'tag_highlight'
              if (linkQ) {
                linkQ.classList.add('tag_highlight');
              }
            });
          
          }

          toast.success('Program assigned successfully.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
         }else{
          const checkboxesWithSameTitle = $(`input[type="checkbox"][value="${id}"]`);
if(checkboxesWithSameTitle.length > 0){
checkboxesWithSameTitle.each(function() {
  $(this).parent().removeClass('highlight_li_text');
  $(this).prop('checked', false);
  var getAlpha = $(this).attr('data-tag');

  const linkQ = document.querySelector(`.nav-q a[href="#${getAlpha}"]`);

  // If found, add the class 'tag_highlight'
  if (linkQ) {
    linkQ.classList.remove('tag_highlight');
  }
});

}

toast.success('Program unassigned successfully.', {
position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "light",
});

// const updatedTitles = checkboxTitles.filter(title => title !== title);
//     // Update the state with the new array
//     setCheckboxTitles(updatedTitles);
         }


          
        }else{
          toast.success('Something went wrong.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
        //alert(resultRes.data.message);
      }
    }
  } else if (actionType === 'unassign') {
    if (confirmed) {
      let homeworkArray = []; 
      // Unassign all related checkboxes
      const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);
      if(checkboxesWithSameTitle.length > 0){
        checkboxesWithSameTitle.each(function() {
          $(this).parent().removeClass('highlight_li_text');
          $(this).prop('checked', false);
          const checkboxCategory = $(this).attr("data-homework");
          if (checkboxCategory) {
            // Push the data-homework value into the array
            homeworkArray.push(checkboxCategory);
          }
        });
      
      }

  const formData = new FormData();
formData.append("homework_ids", homeworkArray);

try {
  const response = await axios.delete(
    "https://api.webtracktechnologies.com.au:8002/homework/multiple/delete",
    {
      data: {'homework_ids': homeworkArray}, // Pass formData in the 'data' field
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json', 
      },
    }
  );
  console.log("Response:", response.data);
} catch (error) {
  console.error("Error:", error.response?.data || error.message);
}

    } else {
      // Revert checkbox state
      e.target.checked = true; // Revert the state back to "checked"
    }
  }
};


const generateAlphabet = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const result = [];

  for (let i = 0; i < alphabet.length; i++) {
    const letter = alphabet[i];
    result.push(letter);
  }

  result.push('ZA'); // Append 'ZA' after 'Z'

  return result;
};

const getScoreForPrograms = (id,start) => {
  if(files && files.data && files.data["Vocabulary Quiz"]){
    files.data["Vocabulary Quiz"].forEach(program => {console.log(program.program_id,id,program.vocab_q_start,start)
    // Check if program_id is equal to 10 and vocab_q_start is equal to 1
    if (program.program_id === id && program.vocab_q_start === start) {
      // Print the total_score of the matching program
      console.log(program.total_score,'get score');
      return program.total_score;

    }
  })
}
};
const handleStudentHomework = (studentId) => {
  setStudentId(studentId);
  window.location.href = `/study/${currentLevel}/${studentId}/${class_id}/${date}`;
};

const compareLevels = (a, b) => {
  // Check if either of the levels is 'P' or 'K'
  if (a.name === 'P') {
      return -1; // Move 'P' to the beginning
  } else if (b.name === 'P') {
      return 1; // Move 'P' to the beginning
  } else if (a.name === 'K') {
      return a.name === 'P' ? 1 : -1; // Move 'K' to the beginning after 'P'
  } else if (b.name === 'K') {
      return b.name === 'P' ? -1 : 1; // Move 'K' to the beginning after 'P'
  } else {
      // Otherwise, maintain the original order
      return a.id - b.id;
  }
};
useEffect(() => {

  if (passage && passage.data) {
   
    if (passage.data.assignedprograms) {
      //console.log(passage.data.assignedprograms,'get assigned');return false;
      // Add blink_text class to the corresponding sidebar div
      passage.data.assignedprograms.forEach(level => {
        if (level !== null && level !== 'Level P') {
          const levelMatch = level.match(/\d+/);
          if (levelMatch) {
            const levelNumber = parseInt(levelMatch[0]);
            console.log(levelNumber, 'get level');
            // Find the corresponding sidebar div by matching the level number with the div number
            const sidebarDiv = document.querySelector(`.sidebar-fulldiv-${levelNumber}`);
            if (sidebarDiv) {
              sidebarDiv.classList.add('blink_text');
            }
          }
        }
      });
    }

    if((passage && passage.data && passage.data.homework_levels)){
      // Add blink_text class to the corresponding sidebar div
      passage.data.homework_levels.forEach(level => {
        //alert(level);
        if(level !== 'Level P'){
        // Extract the numeric part from the level string
        const levelNumber = parseInt(level.match(/\d+/)[0]);
    console.log(levelNumber,'get level');
        // Find the corresponding sidebar div by matching the level number with the div number
        const sidebarDiv = document.querySelector(`.sidebar-fulldiv-${levelNumber}`);
        if (sidebarDiv) {
          sidebarDiv.classList.add('blink_text');
        }
        }
    
      });
      }

    if (passage.data.levels) {
      // Add blink_text class to the corresponding sidebar div
      const modifiedLevels = passage.data.levels.map(level => ({
        ...level,
        name: level.name.replace('Level ', '') // Remove "Level" from the name
      }));
      const sortedLevels = [...modifiedLevels].sort(compareLevels);
      setSortedLevels(sortedLevels);
    }

    if (passage.data.assignedprogramarray) {
      setCheckboxTitles(passage.data.assignedprogramarray);
    }
  }
}, [passage]);

function getAlphabetFromIndex(index) {
  // Validate the index
  if (typeof index !== 'number' || index < 0) {
    throw new Error('Index must be a non-negative number');
  }

  // Calculate the number of complete cycles through the alphabet
  const cycles = Math.floor(index / 26);

  // Get the remainder after dividing by 26 to determine the position within a cycle
  const remainder = index % 26;

  // Get the Unicode value for 'A' and add the remainder to it
  let charCodeA = 'A'.charCodeAt(0) + remainder;

  // If the Unicode value exceeds 'Z', adjust it to loop back to 'A'
  if (charCodeA > 'Z'.charCodeAt(0)) {
    charCodeA -= 26;
  }

  // Convert the resulting Unicode value to alphabet
  let alphabet = String.fromCharCode(charCodeA);

  // If there are complete cycles, prepend 'Z' for each cycle
  if (cycles > 0) {
    alphabet = 'Z'.repeat(cycles) + alphabet;
  }

  return alphabet;
}
let lastIndex = '';
    return (

      <section className="custom-sign-assign-question">
      <div className="container">
  <div className="row">

    <div className="level-box">
      <div className="leven-box-sidebar">
      {(sortedLevels) ? sortedLevels.map((level, index) => (
        <div className={`sidebar-fulldiv-${level.name}`}>
          <div className="sidebar-box">
            <a href={`/study/${level.name}/${student_id}/${class_id}/${date}`}>
              <span className="level">{level.name}</span>
              <span className="leven-name">LEVEL ONE</span>
            </a>
          </div>
        </div>
      )) : ''}

      </div>
      <div className="leven-box-content">
        <h4>Study Homework Assignment - {(classes && classes.data) ? classes.data.class_name+' - '+date : ''}</h4>
        <select id="selestud" class="form-control col-md-4"  value={studentId || ''}  onChange={(event) => handleStudentHomework(event.target.value)}>
        {(classes && classes.data.students) ? classes.data.students.map((classd, index) => ( 
              <option value={classd.id}>{classd.englishName} {classd.koreanName}</option>
              )) : ''}
        </select>
        <h2>LEVEL {numberToWord(currentLevel)} ENGLISH</h2>
        <p>
          LIKE Study offers more than 100 LEVEL {numberToWord(currentLevel)} language arts skills to
          explore and learn! Not sure where to start? Go to your personalized
          Recommendations wall and choose a skill that looks interesting—they’re
          all picked just for you!
        </p>
        <div className="nav-q">
          <a href="#vocabulary">Vocabulary</a>
          <a href="#speaking">Speaking</a>
          <a href="#writing">Writing</a>
          <a href="#grammar">Grammar</a>
          <a href="#reading">Reading</a>
          <a href="#listening">Listening</a>
        </div>
        <div className="nav-q navbar-div" style={{overflow:'auto'}}>
        {generateAlphabet().map((item, index) => (
          <a href={'#'+item} className={(isHighlighted(item)) ? 'tag_highlight' : ''}>{item}</a>
        ))}
        </div>
        <div className="content-div" id="vocabulary">
          <h4>Vocabulary</h4>
          <div className='vocabulary-quiz'>
          <h6 >Vocabulary Quiz</h6>
          
          
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = index;
           
            return (            
            <ul>
               <h4 id={getAlphabetFromIndex(index)}>
                    <span className="ordering">{getAlphabetFromIndex(index)}. </span>
                    {dictate.vocabName}
                  </h4>
            {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
    
           <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
             <input type="checkbox" data-homework={(passage.data.homeworks) && passage.data.homeworks[dictate.vocabid+'-VQuiz-'+question.question_start+'-'+question.question_end] || ''}  data-tag={getAlphabetFromIndex(index)} title={question.title} name="category" value={dictate.vocabid+'-VQuiz-'+question.question_start+'-'+question.question_end} onChange={(e) => handleCheckboxChange(e, dictate.vocabid+'-VQuiz-'+question.question_start+'-'+question.question_end, 'Vocabulary Quiz', question.title,question.question_start,question.question_end)}
            checked={checkboxTitles.includes(question.title)} />
           {index1+1}.<a href={`/vocabulary-question/${dictate.vocabid}/${question.question_start}`}>{question.title}<span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span> 
           

           </a>
   
       </li>
             )) : <h5>No Records Found.</h5>}
             </ul>  );
      }) : <h5>No Records Found.</h5>}
         
          </div>
          <div className='vocabulary-listen-choose'>
          <h6>Vocabulary: Listen and choose what you hear</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabLISTENING: Listen and choose what you hear"]) ? passage.data.programs["Vocabulary"]["VocabLISTENING: Listen and choose what you hear"].map((dictate, index) => {
           lastIndex = lastIndex + 1;
           return ( 
           <ul>
             <h4 id={getAlphabetFromIndex(lastIndex)}>
                    <span className="ordering">{getAlphabetFromIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
            {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
             
           <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
            <input type="checkbox" data-homework={(passage.data.homeworks) && passage.data.homeworks[dictate.vocabid+'-VLCH-'+question.question_start+'-'+question.question_end] || ''} data-tag={getAlphabetFromIndex(lastIndex)} title={question.title} name="category" value={dictate.vocabid+'-VLCH-'+question.question_start+'-'+question.question_end} onChange={(e) => handleCheckboxChange(e, dictate.vocabid+'-VLCH-'+question.question_start+'-'+question.question_end, 'VocabLISTENING: Listen and choose what you hear', question.title,question.question_start,question.question_end)}
            checked={checkboxTitles.includes(question.title)} />
           {index1+1}.<a href={`/vocabulary-listening-question/LCH/${dictate.vocabid}/${question.question_start}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
             )) : <h5>No Records Found.</h5>}
           </ul>);
      }) : <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-listen-choose-correct-meaning'>
          <h6>Vocabulary: Listen and choose the correct meaning</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] &&  passage.data.programs["Vocabulary"]["VocabLISTENING: Listen and choose what you hear"]) ? passage.data.programs["Vocabulary"]["VocabLISTENING: Listen and choose what you hear"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            return ( 
            <ul>
              <h4 id={getAlphabetFromIndex(lastIndex)}>
                    <span className="ordering">{getAlphabetFromIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
            {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
              
           <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
            <input type="checkbox" data-homework={(passage.data.homeworks) && passage.data.homeworks[dictate.vocabid+'-VLCM-'+question.question_start+'-'+question.question_end] || ''} data-tag={getAlphabetFromIndex(lastIndex)} title={question.title} name="category" value={dictate.vocabid+'-VLCM-'+question.question_start+'-'+question.question_end} onChange={(e) => handleCheckboxChange(e, dictate.vocabid+'-VLCM-'+question.question_start+'-'+question.question_end, 'Vocabulary: Listen and choose the correct meaning', question.title,question.question_start,question.question_end)}
              checked={checkboxTitles.includes(question.title)} />
           {index1+1}.<a href={`/vocabulary-listening-question/LCM/${dictate.vocabid}/${question.question_start}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
             )) : <h5>No Records Found.</h5>}
            </ul>  );
      }) : <h5>No Records Found.</h5>}
         
          </div>
          <div className='vocabulary-read-out-loud'>
          <h6>Vocabulary: Read out loud</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            return ( 
            <ul>
              <h4 id={getAlphabetFromIndex(lastIndex)}>
                    <span className="ordering">{getAlphabetFromIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
            {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
            <input type="checkbox" data-homework={(passage.data.homeworks) && passage.data.homeworks[dictate.vocabid+'-VROL-'+question.question_start+'-'+question.question_end] || ''} data-tag={getAlphabetFromIndex(lastIndex)} title={question.title} name="category" value={dictate.vocabid+'-VROL-'+question.question_start+'-'+question.question_end} onChange={(e) => handleCheckboxChange(e, dictate.vocabid+'-VROL-'+question.question_start+'-'+question.question_end, 'Vocabulary: Read out loud', question.title,question.question_start,question.question_end)}
              checked={checkboxTitles.includes(question.title)} />
           {index1+1}.<a href={`/vocabulary-listening-read/${dictate.vocabid}/${question.question_start}/VROL`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul> );
      }) : <h5>No Records Found.</h5>}
          
          </div>
        </div>
    
        <div className="content-div" id="reading">
          <h4>Reading</h4>
          <div className='reading-read-out-loud'>
          <h6>Reading: Text Read out Loud</h6>
          
         
          {(passage && passage.data && passage.data.programs["Reading"] && passage.data.programs["Reading"]["Read out Loud"]) ? passage.data.programs["Reading"]["Read out Loud"].map((readout, index) => {
              lastIndex = lastIndex + 1;
              return ( 
            <ul>
            
           <li className={(readout.homeworkassigned) ? 'highlight_li_text' : ''}>
           <input type="checkbox" data-homework={(passage.data.studyhomeworks) && passage.data.studyhomeworks[readout.id+'-ROL'] || ''}  data-tag={getAlphabetFromIndex(lastIndex)} title={readout.id} name="category" value={readout.id+'-ROL'} onChange={(e) => handleCheckboxChange(e, readout.id+'-ROL', 'Read out Loud',readout.id,'','',readout.categorytitle)}
            checked={checkboxTitles.includes(readout.id)}/>
           <h4 id={getAlphabetFromIndex(lastIndex)}>
                    <span className="ordering">{getAlphabetFromIndex(lastIndex)}. </span>
   
                  </h4>.<a href={`/study/read-out-loud/${readout.id}/ROL`}>{readout.categorytitle} <span className='numberOf'>{(readout.total_score || readout.total_score == 0) ? ' ('+readout.total_score+') ':''}</span> </a>
       </li>
             
            </ul>);
      }) : <h5>No Records Found.</h5>}
          
          </div>
          <div className='reading-multiple-choice'>
          <h6>Reading: Multiple Choice Quiz</h6>
          
         
          {(passage && passage.data && passage.data.programs["Reading"] && passage.data.programs["Reading"]["Reading Multiple Choice"]) ? passage.data.programs["Reading"]["Reading Multiple Choice"].map((readout, index) =>{
              lastIndex = lastIndex + 1;
              return ( 
             <ul>
            
           <li className={(readout.homeworkassigned) ? 'highlight_li_text' : ''}>
           <input type="checkbox" data-homework={(passage.data.studyhomeworks) && passage.data.studyhomeworks[readout.id+'-RMC'] || ''} data-tag={getAlphabetFromIndex(lastIndex)} title={readout.id} name="category" value={readout.id+'-RMC'} onChange={(e) => handleCheckboxChange(e, readout.id+'-RMC', 'Reading Multiple Choice',readout.id,'','',readout.categorytitle)}
            checked={checkboxTitles.includes(readout.id)} />
           <h4 id={getAlphabetFromIndex(lastIndex)}>
                    <span className="ordering">{getAlphabetFromIndex(lastIndex)}. </span>
                  
                  </h4><a href={`/study/reading-multiple-choice/${readout.id}/RMC`}>{readout.categorytitle} <span className='numberOf'>{(readout.total_score || readout.total_score == 0) ? ' ('+readout.total_score+') ':''}</span></a>
       </li>
             
            </ul>);
      }) : <h5>No Records Found.</h5>}
          
          </div>
        </div>
        <div className="content-div" id="listening">
          <h4>Listening</h4>
          <div className='listening-multiple-choice'>
          <h6>Listening</h6>
          
         
          {(passage && passage.data && passage.data.programs["Listening"] && passage.data.programs["Listening"]["Listening Multiple Choice"]) ? passage.data.programs["Listening"]["Listening Multiple Choice"].map((listen, index) => {
              lastIndex = lastIndex + 1;
              return ( 
             <ul>
            
           <li className={(listen.homeworkassigned) ? 'highlight_li_text' : ''}>
           <input type="checkbox" data-homework={(passage.data.studyhomeworks) && passage.data.studyhomeworks[listen.id+'-LMC'] || ''} data-tag={getAlphabetFromIndex(lastIndex)} title={listen.id} name="category" value={listen.id+'-LMC'} onChange={(e) => handleCheckboxChange(e, listen.id+'-LMC', 'Listening Multiple Choice', listen.id,'','',listen.categorytitle)}
            checked={checkboxTitles.includes(listen.id)} />
           <h4 id={getAlphabetFromIndex(lastIndex)}>
                    <span className="ordering">{getAlphabetFromIndex(lastIndex)}. </span>
                  
                  </h4><a href={`/study/listening-multiple-choice/${listen.id}/LMC`}>{listen.categorytitle} <span className='numberOf'>{(listen.total_score || listen.total_score == 0) ? ' ('+listen.total_score+') ':''}</span></a>
       </li>
             
            </ul>);
      }) : <h5>No Records Found.</h5>}
          
          </div>
  
        </div>
      </div>
    </div>
  </div>
</div>

    </section>
      
    )
}
