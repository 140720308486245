import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import { fileActions, singlefileActions, audioActions } from '_store';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import axios from "axios";
export { MyRecordingSpeakingGrade };

function MyRecordingSpeakingGrade() {
  const dispatch = useDispatch();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [result, setResult] = useState('');
    //const { teacherResult } = useSelector((x) => x.teacherResult);
    const { user: authUser } = useSelector((x) => x.auth);
    const [selectedOption, setSelectedOption] = useState('');
    const { files } = useSelector((x) => x.files);
    const { file } = useSelector((x) => x.file);
    const { audio } = useSelector((x) => x.audio);
    const [audioQuality, setAudioQuality] = useState("");
    const audioRef = useRef(null);
    const [textValue, setTextValue] = useState('');
    const [fileInput, setFile] = useState(null);
    const [myArray, setMyArray] = useState([]);
    const [teacherResult, setTeacherResult] = useState(null);
    const { id } = useParams();
    const handleTextChange = (e) => {
      setTextValue(e.target.value);
    };
  
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    };
  
    const handleSave = async () => {
      // Implement your save logic here
      // You can use 'textValue' and 'file' to save the data
      const formData = new FormData();
    formData.append("result_array", JSON.stringify(myArray));
    formData.append("comment", textValue);
    formData.append("recordId", id);
      console.log(id,textValue,myArray);
      const response = await axios.post(
        "https://api.webtracktechnologies.com.au:8002/audio/teacher-result",
        formData
      );
      console.log(response,'get resp');
      setTeacherResult(response.data.data);
    };
// Use the useParams hook to get the route parameters

    console.log(file,'get file');
    const handleSearch = () => {
      // You can perform your search logic here
      console.log('Start Date:', startDate);
      console.log('End Date:', endDate);
      console.log('Input 1:', input1);
      console.log('Input 2:', input2);
      console.log('Selected Option:', selectedOption);
    };

    const handleClick = (text,event) => {
 
     setMyArray(prevArray => [...prevArray, text]);
     const td = event.target;
    td.classList.add('active');
    };

    useEffect( () => {
       dispatch(fileActions.getAudioFiles());
       dispatch(singlefileActions.getSingleFile({id}));

    }, []);
        useEffect(() => {
            if(file && file.data){
                var jsonResult = JSON.parse(file.data.result);
                var teacherjsonResult = JSON.parse(file.data.teacher_result);
                console.log(jsonResult,'json',teacherjsonResult);
                setResult(jsonResult);
                setTeacherResult(teacherjsonResult);
                if(jsonResult.message.AAResult[0].audioQuality[0].$.status == '18'){
                    setAudioQuality('Audio Quality is soft and noisy.');
                  }else if(jsonResult.message.AAResult[0].audioQuality[0].$.status == '8'){
                    setAudioQuality('Audio Quality is soft.');
                  }else if(jsonResult.message.AAResult[0].audioQuality[0].$.status == '0'){
                    setAudioQuality('Audio Quality is good.');
                  }else if(jsonResult.message.AAResult[0].audioQuality[0].$.status == '10'){
                    setAudioQuality('Audio Quality is noisy.');
                  }
            }

    }, [file]);

    const getContentAverageScore = (content) => {
      var value = 0;
      if (content <= 5) {
        
        value = 0;
      } else if (content >= 6 && content <= 10) {
        value = 0.7;
      } else if (content >= 11 && content <= 20) {
        value = 1.5;
      } else if (content >= 21 && content <= 40) {
  
        value = 2.5;
      } else if (content >= 41 && content <= 80) {
  
        value = 3.3;
      }else if (content >= 81 && content <= 100) {

        value = 3.7;
      }else if (content >= 101) {
  
        value = 4;
      }
      //setContent(value);
      return value;
     
    };

    const getFluencyAverageScore = (fluency) => {
      console.log(fluency, "get fluency");
      var value = 0;
      if(Math.round(fluency) <= 30){
        value = 0;
      }else if(Math.round(fluency) >= 30 && Math.round(fluency) <= 50){
        value = 1;
      }
      else if(Math.round(fluency) >= 51 && Math.round(fluency) <= 80){
        value = 2;
      }
      else if(Math.round(fluency) >= 81 && Math.round(fluency) <= 120){
        value = 3;
      }
      else if(Math.round(fluency) >= 121 && Math.round(fluency) <= 150){
        value = 4;
      }else if(Math.round(fluency) >= 151 && Math.round(fluency) <= 180){
        value = 4;
      } else if(Math.round(fluency) >= 180){
        value = 4;
      }
     // setFluency(value);
     return value;
    };
    // const getTotalAverageScore = (content,fluency) => {
    //   if (!isNaN(content) && !isNaN(fluency)) {
    //     const avg = (content + fluency) / 2;
    //     return avg;
    //   }
    // };
    const getFluencyRemarks = (fluency) => {
      console.log(fluency, "get fluency");
      var text = 'There is no recording or your recording is extremely short.';
      if(fluency == 0){
        text = 'There is no recording or your recording is extremely short.';
      }else if(fluency == 1){
        text = 'Your speech is slow.';
      }
      else if(fluency == 2){
        text = 'Your speech is relatively slow.';
      }
      else if(fluency == 3){
        text = 'Your speech is relatively fast.';
      }
      else if(fluency == 4){
        text = 'Your speech is fast.';
      } 
     // setFluency(value);
     return text;
    };
    const getContentRemarks = (content) => {
      console.log(content, "get fluency");
      var text = 'There is no recording or your content score is equal to or less than 20%.';
      if(content == 0){
        text = 'There is no recording or your content score is equal to or less than 20%.';
      }else if(content == 1){
        text = 'Your content score is 21-40%.';
      }
      else if(content == 2){
        text = 'Your content score is 41-60%.';
      }
      else if(content == 3){
        text = 'Your content score is 61-80%.';
      }
      else if(content == 4){
        text = 'Your content score is 81-100%.';
      } 
     // setFluency(value);
     return text;
    };
    const getTotalRemarks = (total) => {
      console.log(total, "get fluency");
      var text = 'Nothing is recorded or no effort taken at all.';
      if(total == 0){
        text = 'Nothing is recorded or no effort taken at all.';
      }else if(total > 0 && total <= 1){
        text = 'Your speech is hardly recognizable. There are a lot of mistakes and errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with too many unnecessary pauses.';
      }
      else if(total > 1 && total <=2){
        text = 'Your speech is intelligible, but it requires much effort for one to understand. There are many noticeable mistakes and consistent errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with many unnecessary pauses.';
      }
      else if(total > 2 && total <= 3){
        text = 'Though it may require some effort, your speech is intelligible, showing relatively high oral proficiency and skills. It contains some errors in terms of pronunciation, grammar, vocabulary use, fluency, and style with some unnecessary pauses.';
      }
      else if(total > 3 && total == 4){
        text = 'Your speech is native like. It is highly intelligible, showing high oral proficiency and skills. Your pronunciation, grammar, vocabulary use, fluency, and style are natural, accurate, and highly proficient.';
      } 
     // setFluency(value);
     return text;
    };

    const getTotalAverageScore = (...values) => {
      let validVariables = values.filter(value => !isNaN(value));
      console.log(validVariables,'length')
      if (validVariables.length === 0) {
        console.log('No valid variables');
        return null; // Handle the case where there are no valid variables
      } else {
        const avg = validVariables.reduce((sum, value) =>  sum + value, 0) / validVariables.length;
   
        return Math.round(avg);
      }
    };
    return (

        <section className="custom-sign">
          <div className="container-fluid">
            {((file) && (file.data)) ? 
          <audio ref={audioRef} controls>
                 <source src={file.data.audio} type="audio/mp3" />
                 Your browser does not support the audio element.
               </audio>
               : ''}

{
    (result || teacherResult) ? <Table striped bordered responsive>
    <thead>
      <tr>
        <th colSpan={5} style={{ textAlign: "center" }}>
          LIKE Recorder Free Response Score Report
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Name</td>
        <td>{authUser.user.name}</td>
        <td rowspan="5" className="result">
                        { (teacherResult) ? getTotalAverageScore(getContentAverageScore(
                          result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        ), getFluencyAverageScore(
                          result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        ),teacherResult.Pronunciation,teacherResult.Vocabulary,teacherResult.Grammar,teacherResult.Style) >= 3  ? (
                          <h4 style={{ color: "green" }}>Pass</h4>
                        ) : (
                          <h4 style={{ color: "red" }}>Fail</h4>
                        ) : getTotalAverageScore(getContentAverageScore(
                          result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].nWord[0]
                        ), getFluencyAverageScore(
                          result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        )) >= 3  ? (
                          <h4 style={{ color: "green" }}>Pass</h4>
                        ) : (
                          <h4 style={{ color: "red" }}>Fail</h4>
                        )}
                      </td>
      </tr>
      <tr>
        <td>Test Date</td>
        <td>{new Date().toLocaleString('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short'
})}</td>
      </tr>
      <tr>
        <td>Audio Quality</td>

        <td>{audioQuality}</td>
      </tr>
      <tr>
        <td>Total Recording Time</td>
        <td>{
            parseInt(result.message.AAResult[0].audioLenSec[0]._)
          } Seconds</td>
      </tr>
      <tr>
        <td>Total Speaking Time</td>
        <td>
       {
            parseInt(result.message.AAResult[0].analysis[0]
              .speakingSummary[0].spkTimeSec[0])
          }{" "}
          Seconds
        </td>
      </tr>
      <tr>
        <td></td>
        <td>Scores</td>
        <td colSpan={5}>Comments</td>
      </tr>
      <tr>
        <td>Content</td>
        <td>{(teacherResult) ? getContentAverageScore(teacherResult.Content) : getContentAverageScore(result.message.AAResult[0].analysis[0].speakingSummary[0].nWord[0])}/4</td>
        <td colSpan={5}>{(teacherResult && teacherResult.Content) ? getContentRemarks(getContentAverageScore(teacherResult.Content)) : getContentRemarks(getContentAverageScore(result.message.AAResult[0].analysis[0].speakingSummary[0].nWord[0]))}</td>
      </tr>
      <tr>
        <td>Fluency</td>
        <td>{(teacherResult && teacherResult.fluency) ? getFluencyAverageScore(teacherResult.fluency) : getFluencyAverageScore(result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0])}/4</td>
        <td colSpan={5}>{(teacherResult && teacherResult.fluency ) ?  getFluencyRemarks(getFluencyAverageScore(
                         teacherResult.fluency
                        )):  getFluencyRemarks(getFluencyAverageScore(
                          result.message.AAResult[0].analysis[0]
                            .speakingSummary[0].wpm[0]
                        ))}</td>
      </tr>
      {(teacherResult) ? 
      <>
      <tr>
      <td>Pronunciation</td>
      <td>{(teacherResult && teacherResult.Pronunciation) ? teacherResult.Pronunciation : '0'}/4</td>
      <td colSpan={5}>Your pronunciation score is {(teacherResult && teacherResult.Pronunciationwords) ? teacherResult.Pronunciationwords : 0
        }%</td>
    </tr>
    </>:''}
    {(teacherResult) ? 
      <>
    <tr>
      <td>Vocabulary Use</td>
      <td>{(teacherResult && teacherResult.Vocabulary) ? teacherResult.Vocabulary : '0.00'}/4</td>
      <td colSpan={5}>The level of vocabulary used in your speech is advanced {(teacherResult && teacherResult.Vocabularywords) ? teacherResult.Vocabularywords :''}</td>
    </tr>
    </>:''}
    {(teacherResult ) ? 
      <>
    <tr>
      <td>Grammer</td>
      <td>{(teacherResult && teacherResult.Grammar) ? teacherResult.Grammar : '0'}/4</td>
      <td colSpan={5}>Your grammar score is {(teacherResult && teacherResult.Grammarwords) ? teacherResult.Grammarwords : 0
        }%</td>
    </tr>
    </>:''}
    {(teacherResult) ? 
      <>
    <tr>
      <td>Style</td>
      <td>{(teacherResult && teacherResult.Style) ? teacherResult.Style : '0.00'}/4</td>
      <td colSpan={5}>{(teacherResult && teacherResult.Stylewords) ? teacherResult.Stylewords :''}</td>
    </tr>

   
    </>: ''}
    <tr>
        <td>Total</td>
        <td>
          {(teacherResult) ? 
          getTotalAverageScore(getContentAverageScore(
            result.message.AAResult[0].analysis[0].speakingSummary[0].nWord[0]
          ), getFluencyAverageScore(
            result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]
          ),teacherResult.Pronunciation,teacherResult.Vocabulary,teacherResult.Grammar,teacherResult.Style) : getTotalAverageScore(getContentAverageScore(
            result.message.AAResult[0].analysis[0].speakingSummary[0].nWord[0]
          ), getFluencyAverageScore(
            result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]
          ))}/4
        </td>
        <td colSpan={5}>{(teacherResult) ? getTotalRemarks(getContentAverageScore(
            teacherResult.Content
          ), getFluencyAverageScore(
            teacherResult.fluency
          )) : getTotalRemarks(getContentAverageScore(
            result.message.AAResult[0].analysis[0].speakingSummary[0].nWord[0]
          ), getFluencyAverageScore(
            result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]
          ))}</td>
      </tr>
    </tbody>
  </Table> : ''
}

            </div>
            
           
        </section>
   
      
    )
}
