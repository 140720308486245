import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'classes';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const classesActions = { ...slice.actions, ...extraActions };
export const classesReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        getClassById: getClassById(),
        getClasses: getClasses(),
        searchClasses:searchClasses(),
        getClassAndStudentsById:getClassAndStudentsById(),
        getAttendanceStudentByClassId:getAttendanceStudentByClassId(),
    };    

    function getClassById() {
        return createAsyncThunk(
            `${name}/classes`,
            async ({ classId,date,arrow }) => await fetchWrapper.get(`${baseUrl}/class/single/`+classId+'?date='+date+'&arrow='+arrow)
        );
    }

    function getClasses() {
        return createAsyncThunk(
            `${name}/classes`,
            async () => await fetchWrapper.get(`${baseUrl}/class/allclasses`)
        );
    }

    function searchClasses() {
        return createAsyncThunk(
            `${name}/classes`,
            async ({query}) => await fetchWrapper.get(`${baseUrl}/class/search?query=`+query)
        );
    }

    function getClassAndStudentsById() {
        return createAsyncThunk(
            `${name}/classes`,
            async ({ classId }) => await fetchWrapper.get(`${baseUrl}/class/info/`+classId)
        );
    }

    function getAttendanceStudentByClassId() {
        return createAsyncThunk(
            `${name}/classes`,
            async ({ class_id }) => await fetchWrapper.get(`${baseUrl}/class/present/`+class_id)
        );
    }

}

function createExtraReducers() {
    return {
        ...getClassById(),
        ...getClasses(),
        ...searchClasses(),
        ...getClassAndStudentsById(),
        ...getAttendanceStudentByClassId(),
    };

    function getClassById() {
        var { pending, fulfilled, rejected } = extraActions.getClassById;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const classes = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('classes', JSON.stringify(classes));
                state.classes = classes;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getClasses() {
        var { pending, fulfilled, rejected } = extraActions.getClasses;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const classes = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('classes', JSON.stringify(classes));
                state.classes = classes;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function searchClasses() {
        var { pending, fulfilled, rejected } = extraActions.searchClasses;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const classes = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('classes', JSON.stringify(classes));
                state.classes = classes;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getClassAndStudentsById() {
        var { pending, fulfilled, rejected } = extraActions.getClassAndStudentsById;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const classes = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('classes', JSON.stringify(classes));
                state.classes = classes;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getAttendanceStudentByClassId() {
        var { pending, fulfilled, rejected } = extraActions.getAttendanceStudentByClassId;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const classes = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('classes', JSON.stringify(classes));
                state.classes = classes;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }



}
