import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const name = 'quizsetting';

const initialState = createInitialState();

function createInitialState() {
    return {
        user: JSON.parse(localStorage.getItem('user')),
        error: null,
        quizsetting: null,
    };
}

const baseUrl = 'https://api.webtracktechnologies.com.au:8002';

const savequizsetting = createAsyncThunk(
    `${name}/savequizsetting`,
    async (result) => {
        try {
            const response = await fetchWrapper.post(`${baseUrl}/quiz/settings/create`, { result });
            return response.data; // Adjust this based on your API response structure
        } catch (error) {
            throw error;
        }
    }
);

const getquizsetting = createAsyncThunk(
    `${name}/getquizsetting`,
    async ({ student, category }) => {
        try {
            const response = await fetchWrapper.get(`${baseUrl}/quiz/settings?student=${student}&category=${category}`);
            return response.data; // Adjust this based on your API response structure
        } catch (error) {
            throw error;
        }
    }
);

const quizsettingSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(savequizsetting.pending, (state) => {
                state.error = null;
            })
            .addCase(savequizsetting.fulfilled, (state, action) => {
                if(action.payload.data){
                    const data=action.payload.data;
                   state.questions=data.questions;
                   state.ifTime=(data.istime==1)?"yes":"no";
                   state.time=data.time;
                }else{
                    state.quizsetting = action.payload;
                }
               
               
            })
            .addCase(savequizsetting.rejected, (state, action) => {
                state.error = action.error;
                toast.error(action.error.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            })
            .addCase(getquizsetting.pending, (state) => {
                state.error = null;
            })
            .addCase(getquizsetting.fulfilled, (state, action) => {
                state.quizsetting = action.payload;
            })
            .addCase(getquizsetting.rejected, (state, action) => {
                state.error = action.error;
                // toast.error(action.error.message, {
                //     position: 'top-right',
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: 'light',
                // });
            });
    },
});

export const quizsettingActions = {
    ...quizsettingSlice.actions,
    savequizsetting,
    getquizsetting,
};

export const quizsettingReducer = quizsettingSlice.reducer;
